import axios from "axios";
import ConverNumber from "components/ConvertNumber";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const BalanceDetailsComponent = (props) => {
  const { trendROI } = useSelector((state) => state.dashboard);
  const [trend, settrend] = useState(trendROI?.trend || "0");
  const [roi, setroi] = useState(trendROI?.roi || "0");
  const [investment, setinvestment] = useState(trendROI?.investment || "0");
  const [trendRoiLoading, settrendRoiLoading] = useState(false);
  const remainingIds = props?.porfolioByUserIdData
    ?.filter((x) => x?.balance > 0.01)
    ?.filter((item) => props?.isSelected?.includes(item._id));

  const trendROIAPI = async () => {
    settrendRoiLoading(true);
    let ids = [];
    remainingIds &&
      remainingIds.map((i) => {
        ids.push(i._id);
      });
    const authToken = localStorage.getItem("userToken");
    let payload = { ids: ids };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authToken,
        "X-custom-header": "$Ecur!tyHEadEr",
      },
    };
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/getTrendROI`,
        payload,
        config
      );
      settrendRoiLoading(false);
      settrend(
        !(data?.data?.trend === 0) ? data?.data?.trend?.toFixed(2) : "0"
      );
      setroi(!(data?.data?.ROI === "N/A") ? data?.data?.ROI?.toFixed(2) : "0");
    } catch (error) {
      settrendRoiLoading(false);
    }
  };
  useEffect(() => {
    settrend(trendROI?.trend);
    setroi(trendROI?.roi);
    setinvestment(trendROI?.investment);
  }, [trendROI]);

  const totalInvestment = remainingIds?.reduce(
    (sum, item) => Number(sum) + Number(item?.amount_invested),
    0
  );
  const totalBalance = remainingIds?.reduce(
    (sum, item) => Number(sum) + Number(item?.balance),
    0
  );
  return (
    <>
      {localStorage.getItem("role") === "retailer" ? (
        <div className="col-2 row text-center">
          <span className="garphValue">
            {" "}
            {remainingIds ? remainingIds?.length : "0"}/
            {props?.porfolioByUserIdData
              ? props?.porfolioByUserIdData?.filter(
                  (entry) => entry?.balance > 0.01
                )?.length
              : "0"}{" "}
          </span>
          <span className="position-inherit h5-Three">Portfolios</span>
        </div>
      ) : (
        ""
      )}
      <div className="col-2 row text-center">
        <span className="garphValue">
          <ConverNumber
            data={
              localStorage.getItem("role") === "retailer"
                ? totalBalance
                : props?.balance
            }
          />
        </span>
        <span className="position-inherit h5-Three">Available Balance ($)</span>
      </div>

      {
        <div className="col-3 row text-center">
          {trendRoiLoading ? (
            "Loading..."
          ) : (
            <span className="garphValue">{`${trend}%`}</span>
          )}
          <span className="position-inherit h5-Three">Trend</span>
        </div>
      }

      {
        <div className="col-3 row text-center">
          {trendRoiLoading ? (
            "Loading..."
          ) : (
            <span className="garphValue timer">{roi}</span>
          )}
          <span className="position-inherit h5-Three">ROI</span>
        </div>
      }

      <div className="col-2 row text-center">
        <span className="garphValue">
          <ConverNumber data={investment} />
        </span>
        <span className="position-inherit h5-Three">Investment ($)</span>
      </div>
    </>
  );
};

export default BalanceDetailsComponent;
