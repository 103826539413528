import { createSlice } from '@reduxjs/toolkit'
import { contactUs } from './GetInTouchAction'
const initialState = {
  getcontactUsInfo: null,
  isgetcontactUs: false,
}

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    clearisgetcontactUs: (state) => {
      state.isgetcontactUs = false
    },
},
  extraReducers: {
    [contactUs.pending]: (state) => { },
    [contactUs.fulfilled]: (state, { payload }) => {
      state.isgetcontactUs = true
    },
    [contactUs.rejected]: (state, { payload }) => { },

  },
})

export const { clearisgetcontactUs } = contactUsSlice.actions
export default contactUsSlice.reducer
