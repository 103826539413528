import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import changePasswordSchema from "../helper/confirmPasswordSchema";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "features/auth/authActions";
import { useNavigate, Link } from 'react-router-dom';
import eye from "assets/eye.svg";
import eyeOff from "assets/eye-off.svg";
import { useIdleTimer } from "react-idle-timer";
import { clearCurrentLogin } from "features/auth/authSlice";
import PopupModal from "./PopupModal/index";
import Button from "./Button";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { userToken } = useSelector(
    (state) => state.auth
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  function reloadeFunction() { window.location.reload() }
  const onSubmitHandler = (values) => {
    values.userToken = userToken
    setIsFormSubmitted(true)
    dispatch(changePassword(values))
      .then((response) => {
        setIsFormSubmitted(false)
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          navigate("/login");
          setTimeout(reloadeFunction, 2000)
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    return () => {
      clearInterval(interval);
    };
  });
  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 30000_000,
    throttle: 500,
  });
  useEffect(() => {
    if (state === "Idle" || !userToken) {
      navigate("/login");
      dispatch(clearCurrentLogin());
    }
  }, [state, navigate, dispatch, userToken]);
  return (
    <PopupModal id={"ChangePasswordPopup"} backdrop={false} >
      <>
        {userToken &&
          <div className="resetPasswordContainer d-grid justify-content-center right-0 pading-top-15">
            <Link to='' > <img data-bs-dismiss="modal" src={require('assets/companylogo.svg').default} alt="Company_Logo" className="company_logo_ChangePassword d-block mb-4" loading='lazy'/> </Link>
            <Formik
              initialValues={{ old_password: "", new_password: '', confirmnewpassword: '' }}
              validationSchema={changePasswordSchema}
              onSubmit={(values, { resetForm }) => {
                onSubmitHandler(values);
                resetForm();
              }}>
              {({ isValid, errors, touched, resetForm }) => (
                <Form>
                  <div className="input-container">
                    <div className="text-center"> <label htmlFor="" className="resetPasswordHeading">Change Password</label></div>
                    <label htmlFor="" className="mt-3"> Current Password </label>
                    <div className="d-flex position-relative align-items-center">
                      <Field className="custom_input pe-4" type={showOldPassword ? "text" : "password"} name="old_password" />
                      <img loading='lazy' src={showOldPassword ? eye : eyeOff} alt="Show password" onClick={() => setShowOldPassword(!showOldPassword)} className={`password-eye ${showOldPassword ? 'eye-icon' : 'eye-icon-inactive'} `} />
                    </div>
                    {errors.old_password && touched.old_password ? (<div className="text-danger">{errors.old_password}</div>) : null}
                  </div>
                  <div className="input-container my-3">
                    <label htmlFor="" > New Password </label>
                    <div className="d-flex position-relative align-items-center">
                      <Field className="custom_input pe-4" type={showNewPassword ? "text" : "password"} name="new_password" />
                      <img
                        src={showNewPassword ? eye : eyeOff}
                        alt="Show password"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className={`password-eye ${showNewPassword ? 'eye-icon' : 'eye-icon-inactive'} `}
                        loading='lazy'
                      />
                    </div>
                    {errors.new_password && touched.new_password ? (
                      <div className="text-danger">{errors.new_password}</div>
                    ) : null}
                  </div>
                  <div className="input-container my-3">
                    <label htmlFor="" >
                      Confirm New Password
                    </label>
                    <div className="d-flex position-relative align-items-center">
                      <Field
                        className="custom_input pe-4"
                        type={showConfirmNewPassword ? "text" : "password"}
                        name="confirmnewpassword"
                      />
                      <img
                        src={showConfirmNewPassword ? eye : eyeOff}
                        alt="Show password"
                        onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                        className={`password-eye ${showConfirmNewPassword ? 'eye-icon' : 'eye-icon-inactive'} `}
                        loading='lazy'
                      />
                    </div>
                    {errors.confirmnewpassword && touched.confirmnewpassword ? (
                      <div className="text-danger">{errors.confirmnewpassword}</div>
                    ) : null}
                  </div>
                  <div className="resetPasswordButton input-container d-flex">
                    <Button variant="secondary" className='my-3' type="reset" onClick={e => resetForm()} databsdismiss="modal" text="Back" />
                    <button type="submit" className={`my-3 ${isFormSubmitted ? 'btn btn-secondary disabled-button' : 'btn btn-primary'} `} loading={isFormSubmitted} disabled={!isValid} >Change Password</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        }
      </>
    </PopupModal>

  )
}

export default ChangePassword