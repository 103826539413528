import React from "react";

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#0078E7"
        d="M2.5 17V3c0-.283.096-.521.288-.713A.967.967 0 013.5 2h13c.283 0 .52.096.712.287.192.192.288.43.288.713v9c0 .283-.096.52-.288.712A.965.965 0 0116.5 13h-10l-4 4zm5 1a.968.968 0 01-.713-.288A.967.967 0 016.5 17v-2h13V6h2c.283 0 .52.096.712.287.192.192.288.43.288.713v15l-4-4h-11zm8-14h-11v8.175L5.675 11H15.5V4zm-11 0v8.175V4z"
      ></path>
    </svg>
  );
}

export default ChatIcon;
