import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const SellChart = ({ data }) => {
  const [options, setOptions] = useState({
    chart: {
      height: 230,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
        track: {
          strokeWidth: '100%',
          margin: 7,
        },
        dataLabels: {
          show: false,
        },
      },
    },
    labels: ['Sell'],
    colors: ['#0078E7'],
  });

  return (
    <div className='d-flex justify-content-center '>
      <div id="chart" className='buyChart'>
        <ReactApexChart options={options} series={data ? [data] : [0]} type="radialBar" height={230} width={230} />
      </div>
    </div>
  );
};

export default SellChart;
