import React, { useState, useEffect } from "react";
import "../scss/dashboard.scss";
import Header from "components/Header";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import CardContainer from "components/Container";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentLogin } from "features/auth/authSlice";
import NavigationBar from "components/Navbar/NavigationBar";
import { fearIndexPin } from 'features/Dashboard/dashboardAction'

const DashboardScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const { userToken } = useSelector((state) => state.auth);
  const { dashboardGraphInfo } = useSelector((state) => state?.dashboard);
  const [pinnedTabs, setPinnedTabs] = useState(['globalMarketCap'])
  const [addOnTabs, setAddonTabs] = useState(['fearIndex', 'cryptoCurrencies', 'news'])
  const [showActivityLog, setShowActivityLog] = useState(false)

  useEffect(() => {
    if (dashboardGraphInfo?.data?.isFearIndexPinned) {
      setPinnedTabs(['globalMarketCap', 'fearIndex'])
    }
  }, [dashboardGraphInfo?.data?.isFearIndexPinned])
  const onClickPin = (tabId) => {
    const isTabPinned = pinnedTabs.includes(tabId);
    const payload = { userId: localStorage.getItem('userID'), fearIndex: !isTabPinned, };
    dispatch(fearIndexPin({ userToken, payload })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
      }
    });

    if (isTabPinned) {
      setPinnedTabs(pinnedTabs.filter((elem) => elem !== tabId));
    } else {
      setPinnedTabs([...pinnedTabs, tabId]);
    }
  };
  const onClickRemove = (e) => {
    let tabId = e.target.value;
    setAddonTabs([...addOnTabs, tabId])
    setPinnedTabs(pinnedTabs.filter((e) => (e !== tabId)))
  }

  const onClickUpdate = (e) => {
  }

  const onClickExpand = (e) => {
  }

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 30000_000,
    throttle: 500,
  });

  useEffect(() => {
    if (state === "Idle" || !userToken) {
      navigate("/login");
      dispatch(clearCurrentLogin());
    }
  }, [state, navigate, dispatch, userToken]);

  const makeTitlefromPathname = (str) => {
    let data = str.split('/');
    if (data?.length >= 3) {
      return data[data.length - 2].charAt(0).toUpperCase() + data[data.length - 2].slice(1);
    } else {
      return data[data.length - 1].charAt(0).toUpperCase() + data[data.length - 1].slice(1);

    }

  }

  if (!userToken) {
    return <Navigate to="/" replace />;
  }

  return <>
    <div style={{ display: "flex", overflowX: "hidden" }} className="h-100">
      <NavigationBar />
      <CardContainer>
        <Header title={makeTitlefromPathname(location?.pathname)} addOnTabs={addOnTabs} updateAddOnTabs={setAddonTabs} pinnedTabs={pinnedTabs} onClickPin={onClickPin} onClickExpand={onClickExpand} onClickUpdate={onClickUpdate} setShowActivityLog={setShowActivityLog} showActivityLog={showActivityLog} />
        <Outlet context={{ pinnedTabs: { pinnedTabs }, updatePinnedTabs: { setPinnedTabs }, onClickPin: { onClickPin }, onClickExpand: { onClickExpand }, onClickUpdate: { onClickUpdate } }} />
      </CardContainer>
    </div>
  </>;
};

export default DashboardScreen;
