import { ArrowDownIcon } from "assets";
import StocBalanceGraph from "components/Charts/StocBalanceGraph";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getGraphDataRetailer } from "features/Dashboard/dashboardAction";
import { getGraphDataBroker } from "features/Dashboard/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { setTrendROI } from "features/Dashboard/dashboardSlice";

const BalanceGraphComponent = (props) => {
  const { userToken } = useSelector((state) => state.auth);
  const [getBalance, setBalance] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [getDate, setDate] = useState({ text: "24 Hours", day: "day" });
  const [isLoading, setIsLoading] = useState(true);

  const callAPIRetailer = () => {
    setIsLoading(true);
    dispatch(getGraphDataRetailer({ userToken, getDate })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        dispatch(setTrendROI(res?.payload));
        setBalance(res?.payload.graphData);
        setIsLoading(false);
      }
    });
  };

  const callAPIBroker = () => {
    setIsLoading(true);
    dispatch(getGraphDataBroker({ userToken, getDate })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        dispatch(setTrendROI(res?.payload));
        setBalance(res?.payload.graphData);
        setIsLoading(false);
      }
    });
  };

  const currentRole =
    localStorage && localStorage !== undefined && localStorage.getItem("role");

  useEffect(() => {
    setBalance();
    if (currentRole === "retailer") {
      callAPIRetailer();
    } else {
      callAPIBroker();
    }
  }, [getDate]);

  return (
    <div className="singleRow mt-4">
      <div className="d-flex align-items-baseline">
        <h3 className="me-2 mt-1 fw-bold"> {t("balance")} </h3>
        <div className="btn-group dashboard-dropdown ML-5">
          {location?.pathname === "/client-Dashboard-Details" ? (
            ""
          ) : (
            <>
              <button
                className="btn dropdown-toggle d-flex justify-content-between align-items-center p-Three"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
              >
                <div className={`p-Six`}>{getDate?.text}</div>
                <ArrowDownIcon color={"#858585"} height={"18"} />
              </button>
              <ul className="dropdown-menu w_inherit">
                {[
                  { text: "24 Hours", day: "day" },
                  { text: "7 Days", day: "weekly" },
                  { text: "1 Month", day: "monthly" },
                  { text: "12 Months", day: "yearly" },
                ].map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown-item py-3 justify-content-between d-flex${
                      index === 3 ? "" : " border-bottom"
                    }`}
                    onClick={() => {
                      setDate(item);
                    }}
                  >
                    {item?.text}
                    {getDate?.text === item?.text && (
                      <img
                        src={require("assets/rightMark.svg").default}
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      <div className="graph mt-2">
        {" "}
        <StocBalanceGraph
          getBalance={getBalance}
          chartType={getDate?.day}
          isLoading={isLoading}
        />{" "}
      </div>
    </div>
  );
};

export default BalanceGraphComponent;
