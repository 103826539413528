import React from "react";

function Address({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 18 20"
    >
      <path
        fill={color ? color : "#0078E7"}
        fillRule="evenodd"
        d="M6.043 19.908H12.269c1.012 0 1.847 0 2.508-.09.694-.093 1.307-.296 1.799-.787.49-.491.694-1.104.787-1.798.09-.661.09-1.496.09-2.509V9.751c0-1.363 0-2.282-.377-3.103-.378-.82-1.076-1.418-2.11-2.304L13.68 3.242c-1.012-.868-1.825-1.565-2.543-2.038-.744-.492-1.46-.796-2.286-.796-.825 0-1.542.304-2.286.796-.717.473-1.53 1.17-2.543 2.038L2.737 4.344C1.702 5.23 1.004 5.828.627 6.648.248 7.47.248 8.388.25 9.751v4.973c0 1.013 0 1.848.089 2.509.093.694.297 1.307.788 1.798.49.491 1.104.695 1.798.788.661.089 1.496.089 2.508.089h.61zM4.96 4.414c1.06-.908 1.802-1.543 2.432-1.959.612-.404 1.04-.547 1.46-.547.419 0 .846.143 1.459.547.63.416 1.372 1.05 2.431 1.959l1.122.961c1.208 1.036 1.626 1.415 1.849 1.9.223.485.24 1.049.24 2.64v4.756c0 1.079-.002 1.812-.076 2.362-.071.527-.196.771-.362.937-.166.166-.41.291-.938.362-.516.07-1.196.075-2.172.076v-4.98a1.75 1.75 0 00-1.75-1.75H7.047a1.75 1.75 0 00-1.75 1.75v4.98c-.976 0-1.656-.006-2.172-.076-.528-.07-.772-.196-.938-.362-.166-.166-.29-.41-.362-.937-.073-.55-.075-1.283-.075-2.362V9.915c0-1.591.016-2.155.24-2.64.222-.485.64-.864 1.848-1.9l1.122-.961zm5.945 13.994H6.797v-4.98a.25.25 0 01.25-.25h3.608a.25.25 0 01.25.25v4.98z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Address;