// AcceptRequestSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { acceptRequest } from './AcceptRequest'

const initialState = {
    acceptedRequestInfo: null,
    isRequestAccepted: false
}

const acceptRequestSlice = createSlice({
    name: 'acceptRequest',
    initialState,
    reducers: {},
    extraReducers: {
        [acceptRequest.pending]: (state) => {},
        [acceptRequest.fulfilled]: (state, { payload }) => {
            state.isRequestAccepted = true
            state.acceptedRequestInfo = payload
        },
        [acceptRequest.rejected]: (state, { payload }) => {},
    },
})

export default acceptRequestSlice.reducer
