import React from "react";

function EthIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="81"
      fill="none"
      viewBox="0 0 81 81"
    >
      <path
        fill="#ECEFF0"
        d="M78.846 50.151C73.51 71.567 51.818 84.644 30.363 79.303 8.949 73.949-4.107 52.259 1.23 30.843 6.567 9.428 28.244-3.649 49.647 1.691c21.468 5.276 34.524 27.032 29.2 48.46z"
      ></path>
      <path
        fill="#343535"
        d="M40.501 50.2c-.155.053-.244-.083-.35-.145-5.18-3.057-10.354-6.116-15.523-9.178a1.082 1.082 0 00-.155-.064v-.068l.091-.053c.13-.034.259-.076.384-.124l12.207-5.554c1.088-.496 2.176-.984 3.264-1.475h.078v.441c.004 5.407.005 10.814.004 16.22z"
      ></path>
      <path
        fill="#2F3030"
        d="M40.509 33.542h-.078c0-.155.016-.31.016-.474v-18.22a1.3 1.3 0 01.031-.467h.033c.638.943 1.195 1.938 1.782 2.912 4.654 7.72 9.305 15.438 13.954 23.156.045.076.08.155.121.24a2.696 2.696 0 01-.387-.114c-.853-.386-1.71-.777-2.555-1.17a2735.59 2735.59 0 00-3.973-1.816l-6.384-2.892c-.85-.391-1.705-.772-2.56-1.155z"
      ></path>
      <path
        fill="#828384"
        d="M40.483 14.373a1.3 1.3 0 00-.031.466V33.06c0 .156-.01.311-.015.474-1.088.492-2.176.98-3.264 1.475l-12.207 5.554c-.126.05-.254.09-.384.125.353-.734.811-1.399 1.23-2.101 4.843-8.051 9.69-16.1 14.54-24.149a1.34 1.34 0 01.105-.127l.026.062z"
      ></path>
      <path
        fill="#131313"
        d="M40.509 33.542c.855.383 1.71.764 2.563 1.15l6.384 2.891c1.326.603 2.65 1.208 3.974 1.815.852.39 1.71.785 2.555 1.17.126.048.255.086.387.114.155.194-.075.202-.145.244-1.325.793-2.657 1.574-3.986 2.36l-11.438 6.769c-.096.056-.2.097-.302.145V33.984c.002-.148.007-.294.008-.442z"
      ></path>
      <path
        fill="#2F3030"
        d="M40.447 53.27l9.985-5.885c1.906-1.125 3.811-2.251 5.715-3.378l.173-.095c.017.155-.09.237-.155.328A71489.84 71489.84 0 0143.11 62.637c-.823 1.162-1.645 2.33-2.477 3.484-.043.06-.11.345-.244.043.106-.09.06-.211.06-.31 0-4.196 0-8.39-.002-12.584z"
      ></path>
      <path
        fill="#828384"
        d="M40.446 53.271v12.576c0 .107.047.228-.06.31-.221-.133-.318-.373-.465-.57a42212.653 42212.653 0 01-15.13-21.325c-.063-.09-.122-.186-.187-.286.13-.076.2.028.276.073 5.103 3.013 10.204 6.028 15.305 9.046.095.06.175.12.26.176z"
      ></path>
      <path
        fill="#393939"
        d="M40.484 14.373l-.037-.058c.068-.043.059.02.07.053l-.033.005z"
      ></path>
    </svg>
  );
}

export default EthIcon;
