import React from 'react';
import { Chart, ArcElement } from 'chart.js';
import ReactSpeedometer from 'react-d3-speedometer';
Chart.register(ArcElement);

const DoughnutChart = ({ addonsdata }) => {
  const getValue = () => {
    if (addonsdata?.nowData) {
      return { index: 1, value: addonsdata[0]?.yesterday?.value };
    }
    if (addonsdata?.previousData) {
      return { index: 2, value: addonsdata[1]?.week?.value };
    }
    if (addonsdata?.monthAgo) {
      return { index: 3, value: addonsdata[2]?.months?.value }
    }
    if (addonsdata?.yearAgo) {
      return { index: 4, value: addonsdata[3]?.year?.value }
    }
  };
  const segmentColors = ['#FF471A', '#F3A81F', '#D4E725', '#7ADA2C'];
  
  return (
    <div className="h-100">
      <ReactSpeedometer
        width={180}
        segments={4}
        ringWidth={15}
        needleHeightRatio={0.7}
        textColor={'#fff'}
        value={10* Number(getValue()?.value)}
        currentValueText={getValue()?.value ? getValue()?.value : "0"}
        segmentColors={segmentColors}
        customSegmentLabels={[
          {
            position: "INSIDE",
            color: "#555",
          },
          {
            position: "INSIDE",
            color: "#555",
          },
          {
            position: "INSIDE",
            color: "#555",
          },
          {
            position: "INSIDE",
            color: "#555",
          },
        ]}
      />
    </div>
  );
};

export default DoughnutChart;
