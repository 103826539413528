import { ImageIcon, LinkIcon, PinIcon, UndoIcon } from 'assets'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { answerToQuestionAPI, getQuestionsAPI } from 'features/Management/managementAction'
import UplodeImg from 'screens/ProfileScreen/UplodeImg';
import { deleteMessageFromActivity } from "features/ActivityLog/ActivityLogAction";

const Card = (props) => {
    const dispatch = useDispatch();
    const [getAnswerMessages, setAnswerMessages] = useState({ id: '', message: '', delete: '' })
    const { userToken } = useSelector((state) => state.auth);
    const [showUndo, setShowUndo] = useState(false);
    const [getReplyLoading, setReplyLoading] = useState(false);
    const { ActivityLogData, setActivityLogData } = props
    useEffect(() => {
        let timeoutId;
        if (showUndo) {
            timeoutId = setTimeout(() => {
                setShowUndo(false);
                setReplyLoading(true)
                dispatch(answerToQuestionAPI({ userToken, getAnswerMessages })).then((response) => {
                    props?.setLoading(false)
                    if (response.meta && response.meta.requestStatus === "fulfilled") {
                        dispatch(deleteMessageFromActivity({ userToken, id: getAnswerMessages?.delete })).then((response) => {
                            if (response.meta && response.meta.requestStatus === "fulfilled") {
                                if (ActivityLogData?.length > 0) {
                                    const filteredArr = ActivityLogData.filter((data, index) => index !== props.index)
                                    setActivityLogData(filteredArr)

                                }
                                setAnswerMessages({ id: '', message: '', delete: '' })
                                props?.setLoading(true)
                                setReplyLoading(false)
                                dispatch(getQuestionsAPI({ userToken }))
                            }
                        })
                    }
                })
            }, 3000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [showUndo]);

    const [error, setError] = useState(false)
    const handleClick = () => {
        setShowUndo(!showUndo)
    };

    const inputMessage = (e) => {
        const { name, value } = e.target
        const trimmedValue = value.trimStart();
        if (value?.length > 500) {
            setError(true)
        } else {
            setAnswerMessages({
                id: (props?.activityLog ? props?.relationId : props?._id),
                message: trimmedValue,
                delete: props?._id
            });
            setError(false)
        }

    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-star'>
                <p className='d-flex gap-2 message-title text-break mb-2'>{props?.retailerId?.full_name} {props?.brokerId?.full_name}</p>
                {!props?.shadow ? '' : <div onClick={() => props.handleChatPin(props.isPinned, props._id)} className="cursor">
                    <PinIcon pin={props.isPinned} />
                </div>}
            </div>
            {props?.retailerId?.full_name ? <div className='py-2 '>
                <div className='d-flex gap-2 marginBetweenLabels'>
                    <div className={props?.answer ? '' : `card-heading-message`}>Question: {props?.question}</div>
                </div>
            </div> : ''}

            {localStorage.getItem('role') === 'retailer' ? '' :
                props?.answer ?

                    <div className={props?.answer ? '' : `card-heading-message`}>{`Answer: ${props?.answer}`} </div> :
                    <div className={`${(showUndo || getReplyLoading || props?.loadActivityLog) ? 'bg_gray' : ''} d-grid gap-3 answerQustion align-items-center`}>
                        <input name='message' type="text" placeholder="Reply"
                            onClick={() => props?.setShowImg({ id: props?._id })}
                            className={`bg-transparent ACtivityLogInput m-0`}
                            value={getAnswerMessages?.id === (props?.activityLog ? props?.relationId : props?._id) ? getAnswerMessages?.message : ''}
                            onChange={(e) => { inputMessage(e) }}
                        />
                        {props?.getShowImg?.id === props?._id ?
                            <div className={`mx-3 mb-2 d-flex ${!showUndo ? 'justify-content-between' : 'justify-content-end'}`} >
                                {(showUndo || getReplyLoading || props?.loadActivityLog) ? '' : <div className='d-flex align-items-center'> <LinkIcon />
                                    <ImageIcon />
                                </div>}
                                <button className={`bg-transparent ${getAnswerMessages?.message ? 'reply_message' : 'reply_message_not'} bg-white border-0`} disabled={!(getAnswerMessages?.id === (props?.activityLog ? props?.relationId : props?._id) && getAnswerMessages?.message?.length > 0)} onClick={handleClick}>{showUndo ? <UndoIcon /> : <>{(getReplyLoading || props?.loadActivityLog) ? '...' : 'Reply'}</>}</button>
                            </div>
                            : ''}
                    </div>}

            {error ? <div className="text-danger mt-2">Max Message latter 500</div> : ''}
            <UplodeImg />
        </>
    )
}

export default Card