import React, { useEffect, useState, useRef } from "react";
import { getPorfolioByUserId } from "features/Management/managementAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PortfoliosCard from "./PortfoliosCard";
import { getCoinList } from "features/CoinList/CoinListAction";
import { updatePortfolio } from "features/CoinList/CoinListAction";

const filter = [
  { label: "All portfolios" },
  { label: "High risk portfolios" },
  { label: "Medium risk portfolios" },
  { label: "Low risk portfolios" },
];

function ManagementPortfolios() {
  const { userToken } = useSelector((state) => state.auth);
  const { porfolioByUserIdData } = useSelector((state) => state?.management);
  const { getExchangeInfo } = useSelector((state) => state?.Exchange);
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = location.state || {};
  const [getPortfoliosData, setPortfoliosData] = useState(
    [] || porfolioByUserIdData
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [getCoinListData, setCoinListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getFilterDropdown, setFilterDropdown] = useState({
    label: data === undefined ? "All portfolios" : data,
  });

  const parentRef = useRef(null);
  const childRef = useRef(null);

  const handleScroll = () => {
    console.log("Scroll event");
    if (childRef.current) {
      childRef.current.onScroll();
    }
  };

  useEffect(() => {
    const parentElement = parentRef.current;
    if (parentElement) {
      console.log(parentElement, "parent element");

      parentElement.addEventListener("scroll", handleScroll);
    }

    // Cleanup on unmount
    return () => {
      if (parentElement) {
        parentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(updatePortfolio({ userToken })).then(() => {
      setTimeout(async () => {
        await dispatch(getPorfolioByUserId({ userToken })).then((res) => {
          setPortfoliosData(res?.payload);
          setLoading(false);
        });
      }, 1500);
    });

    dispatch(getCoinList({ userToken })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setCoinListData(res?.payload?.coins);
      }
    });
  }, []);

  function capitalizeFirstLetter(str) {
    return str === undefined || str === ""
      ? ""
      : str.substring(0, 1).toUpperCase() + str?.substring(1);
  }

  const filterDataByRisk = () => {
    if (getFilterDropdown?.label === "All portfolios") {
      return (porfolioByUserIdData || getPortfoliosData)
        ?.filter((e) => e?.balance > 0.01)
        ?.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            item?.exchangeName
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
        );
    } else {
      return (porfolioByUserIdData || getPortfoliosData)
        ?.filter((e) => e?.balance > 0.01)
        ?.filter(
          (e) =>
            capitalizeFirstLetter(e?.risk?.trim()) ===
            getFilterDropdown?.label?.split(" ")[0]
        )
        ?.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            item?.exchangeName
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
        );
    }
  };

  const filterDataAll = filterDataByRisk();
  return (
    <>
      <div
        className={`${
          location?.pathname === "/management/clients"
            ? "Client-Search-Sticky"
            : "Client-Search-Sticky"
        } client-search-container d-flex col-12 align-items-center mar-l-32 gap-2`}
      >
        <div className="position-relative">
          <img
            src={require("assets/searchIcon.svg").default}
            alt="search-icon"
            className="position-absolute ps-3 pt-3"
            loading="lazy"
          />
          <input
            type="search"
            placeholder="Search"
            className="ps-5 header_search"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className={`btn-group MultiSelectDropDown z-index-2`}>
          <button
            className="btn dropdown-toggle d-flex justify-content-between align-items-center"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <div className={`me-3`}>
              {`${getFilterDropdown?.label} (${filterDataAll?.length})`}{" "}
            </div>
            <img src={require("assets/arrowDown.svg").default} alt="" />
          </button>
          <ul className="dropdown-menu pt-0 w-max z-index-2">
            {filter?.map((item, index) => (
              <li
                key={index}
                className={`dropdown-item py-2 d-flex gap-2 align-items-center ${
                  filter?.length - 1 === index ? "" : "border-bottom"
                }`}
                onClick={() => setFilterDropdown(item)}
              >
                <input
                  className="checkbox selectContainer"
                  type="checkbox"
                  checked={item?.label === getFilterDropdown?.label}
                  value={item?.label}
                  readOnly
                />
                {item.img} {item?.label}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {filterDataAll?.length > 0 ? (
        <div className=" ps-3">
          <div className="custom-table-res">
            <table
              className="client_Upper_labels-portfolio"
              cellPadding={0}
              cellSpacing={0}
              border={0}
            >
              <tr>
                <td>
                  <table
                    className=" w-100 inner-table"
                    cellPadding={0}
                    cellSpacing={0}
                    border={0}
                  >
                    <tr>
                      {" "}
                      <td>
                        {" "}
                        <h6 className="h6-One-Grey">
                          {" "}
                          Portfolios and Exchange{" "}
                        </h6>{" "}
                      </td>{" "}
                    </tr>
                  </table>
                </td>
                <td>
                  <table
                    className=" w-100 inner-table-portfolio"
                    cellPadding={0}
                    cellSpacing={0}
                    border={0}
                  >
                    <tr>
                      <td>
                        {" "}
                        <h6 className="h6-One-Grey col-lg-2 ML-cuscoin">
                          Coins
                        </h6>{" "}
                      </td>
                      <td>
                        {" "}
                        <h6 className="h6-One-Grey">
                          Balance
                          <img
                            className="ML-5"
                            src={require(`assets/downArrow.svg`).default}
                            alt=""
                          />{" "}
                        </h6>{" "}
                      </td>
                      <td>
                        {" "}
                        <h6 className="h6-One-Grey  ">Invested amount</h6>{" "}
                      </td>
                      <td>
                        {" "}
                        <h6 className="h6-One-Grey  ">Available</h6>
                      </td>
                      <td>
                        <h6 className="h6-One-Grey">Risk</h6>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div className="broker-list-container">
              <div className="broker-list " ref={parentRef}>
                {filterDataAll?.map((item, index) => (
                  <div className=" mb-2" key={index}>
                    <div className={`d-flex`}>
                      <span className="d-flex align-items-center">
                        {" "}
                        {index + 1}{" "}
                      </span>
                      <PortfoliosCard
                        ref={childRef}
                        {...item}
                        number={index}
                        exchanges={getExchangeInfo}
                        getCoinListData={getCoinListData}
                      />
                    </div>
                  </div>
                ))}
                <div className="padd-cus"></div>
              </div>
            </div>
          </div>
        </div>
      ) : loading ? (
        <div className="ClientPageLoading ">
          {" "}
          <div className="loaderProfile" role="status">
            {" "}
          </div>{" "}
        </div>
      ) : (
        <div className="d-flex justify-content-center py-4">
          No portfolios found
        </div>
      )}
    </>
  );
}

export default ManagementPortfolios;
