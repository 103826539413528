import React from "react";

function XrpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="81"
      fill="none"
      viewBox="0 0 81 81"
    >
      <path
        fill="#262C32"
        d="M78.846 49.735C73.51 71.151 51.818 84.228 30.363 78.887 8.949 73.533-4.107 51.843 1.23 30.427 6.567 9.012 28.244-4.065 49.647 1.276c21.468 5.275 34.524 27.03 29.2 48.46z"
      ></path>
      <path
        fill="#fff"
        d="M57.947 19.195h7.22L50.125 34.402a13.82 13.82 0 01-19.72 0l-15.04-15.207h7.22l11.43 11.564c3.376 3.442 8.958 3.476 12.4.1l.1-.1 11.43-11.564zm-35.428 42.28H15.3L30.44 46.166a13.82 13.82 0 0119.72 0L65.3 61.474h-7.22L46.584 49.843c-3.375-3.443-8.957-3.476-12.4-.1l-.1.1L22.52 61.474z"
      ></path>
    </svg>
  );
}

export default XrpIcon;
