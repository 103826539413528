import React from 'react'

const TooltipQuestionMark = ({ toolTipContent, droidType }) => {
    return (
        <div>
            <div className={`${droidType === 'safemodedroid' ? "tooltipp" : "tooltipp"}`}>
                <img src={require('assets/questionMark.svg').default} className='cursor question-Mark-Container' alt='' loading='lazy'/>
                <span className='triangle'></span>
                <span className='tooltipp-text p-Two text-start'>{toolTipContent}</span>
            </div>
        </div>
    )
}

export default TooltipQuestionMark