import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Header({ headerData, active, setActive, handleActive }) {
  let droidList = useSelector((state) => state.droids);
  const location = useLocation();

  console.log(droidList, "droidlist");
  return (
    <>
      {location?.pathname?.split("/")?.length < 4 && (
        <>
          <div className="custom-headspace"></div>
          <div className={`d-flex justify-content-between brokerHeaderRow `}>
            <div className="brokerHeader">
              {headerData.map((item, index) => {
                return (
                  <>
                    {(localStorage.getItem("role") === "retailer" &&
                      item?.text === "Clients") ||
                    (localStorage.getItem("role") === "broker" &&
                      (item?.text === "Portfolios" ||
                        item?.text === "Exchange")) ? (
                      ""
                    ) : (
                      <Link
                        className="py-2"
                        onClick={() => {
                          setActive(item?.link);
                        }}
                        target={item?.link === "discord" ? "_blank" : ""}
                        to={
                          item?.link === "discord"
                            ? "https://discord.com"
                            : item?.link
                        }
                        key={index}
                      >
                        <div
                          className="d-flex align-items-center "
                          onClick={handleActive}
                        >
                          <span
                            className={`${
                              active === item?.link
                                ? "activeTask"
                                : "notActiveTask"
                            }`}
                          >
                            {" "}
                            {item?.imageIcon}{" "}
                          </span>
                          <div className="mx-2  align-items-center d-grid">
                            {/* <span>{item?.number}</span> */}
                            <span
                              className={`${
                                active === item?.link
                                  ? "activeText"
                                  : "notActiveText"
                              }`}
                            >
                              {" "}
                              {item?.text}{" "}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Header;
