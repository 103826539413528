import { createSlice } from '@reduxjs/toolkit'
import { 
  getBroker,
  getBrokerDetail,
  checkConnected,
  sendConnectionRequest,
  editReviewsData,
  getReviewsData,
  postReviewData,
  deleteReviewsData,
  getTypeCompany,
  postaskQuestion,
 } from './communityAction'

const initialState = {
  getBrokerInfo: null,
  getBrokerDetailInfo: null,
  checkConnectedInfo: null,
  getReviewsDataInfo: null,
  postReviewDataInfo: null,
  getpostaskQuestion: null,
  sendConnectionRequestInfo: null,
  deleteReviewDataInfo: null,
  getTypeCompanyInfo: null,
  issendConnectionRequest: false,
  isUserProfileUpdated: false,
  isgetReviewsDataInfo: false,
  ispostReviewData: false,
  isdeleteReviewData: false,
  iseditReviews: false,
  ispostaskQuestion: false,
  getaddMessage : null,
  isaddMessage : false
}

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    clearissendConnectionRequest: (state) => { state.issendConnectionRequest = false },
    clearispostReviewData: (state) => { state.ispostReviewData = false },
    clearisdeleteReviewData: (state) => { state.isdeleteReviewData = false },
    cleariseditReviews: (state) => { state.iseditReviews = false },
    clearispostaskQuestion: (state) => { state.ispostaskQuestion = false },
    clearisaddMessage: (state) => { state.isaddMessage = false },
  },
  extraReducers: {
    [getBroker.pending]: (state) => {},
    [getBroker.fulfilled]: (state, { payload }) => {
      state.getBrokerInfo = payload
    },
    [getBroker.rejected]: (state, { payload }) => {},
    [getBrokerDetail.pending]: (state) => {
    },
    [getBrokerDetail.fulfilled]: (state, { payload }) => {
      state.getBrokerDetailInfo = payload
    },
    [getBrokerDetail.rejected]: (state, { payload }) => {},
    [checkConnected.pending]: (state) => {
    },
    [checkConnected.fulfilled]: (state, { payload }) => {
      state.checkConnectedInfo = payload
    },
    [checkConnected.rejected]: (state, { payload }) => {},
    [sendConnectionRequest.pending]: (state) => {
    },
    [sendConnectionRequest.fulfilled]: (state, { payload }) => {
      state.sendConnectionRequestInfo = payload
      state.issendConnectionRequest = true
    },
    [sendConnectionRequest.rejected]: (state, { payload }) => {},
    [getReviewsData.pending]: (state) => {
    },
    [getReviewsData.fulfilled]: (state, { payload }) => {
      state.getReviewsDataInfo = payload
    },
    [getReviewsData.rejected]: (state, { payload }) => {},
    [postReviewData.pending]: (state) => {
    },
    [postReviewData.fulfilled]: (state, { payload }) => {
      state.postReviewDataInfo = payload
      state.ispostReviewData = true
    },
    [postReviewData.rejected]: (state, { payload }) => {},
    [deleteReviewsData.pending]: (state) => {
    },
    [deleteReviewsData.fulfilled]: (state, { payload }) => {
      state.deleteReviewDataInfo = payload
      state.isdeleteReviewData = true
    },
    [deleteReviewsData.rejected]: (state, { payload }) => {},
    [getTypeCompany.pending]: (state) => { },
    [getTypeCompany.fulfilled]: (state, { payload }) => {
      state.getTypeCompanyInfo = payload
    },
    [getTypeCompany.rejected]: (state, { payload }) => {},
    [editReviewsData.pending]: (state) => {},
    [editReviewsData.fulfilled]: (state, { payload }) => {
      state.iseditReviews = true
    },
    [editReviewsData.rejected]: (state, { payload }) => { },
    [postaskQuestion.pending]: (state) => {
    },
    [postaskQuestion.fulfilled]: (state, { payload }) => {
      if(payload?.messageSend){
        state.ispostaskQuestion = true
      }
      
      state.getpostaskQuestion = payload
    },
    [postaskQuestion.rejected]: (state, { payload }) => { },
  },
})
export const { 
  clearissendConnectionRequest,
  clearispostReviewData,
  clearisdeleteReviewData,
  cleariseditReviews,
  clearispostaskQuestion,clearisaddMessage } = communitySlice.actions
export default communitySlice.reducer
