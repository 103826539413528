import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import eye from "assets/eye.svg";
import eyeOff from "assets/eye-off.svg";
import signUpValidationSchema from "../helper/signUpValidationSchema";
import signUpValidationSchemaBroker from "../helper/signUpValidationSchemaBroker";
import {
  userSignup,
  getLinkedInProfile,
  socialDataStore,
} from "features/auth/authActions";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Button from "./Button";
import Support from "./Support";
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
} from "reactjs-social-login";
import { PublicClientApplication } from "@azure/msal-browser";
import { Google } from "assets";
import { toast } from "react-toastify";
import BetaTesterAgreementModal from "./BetaTesterAgreementModal";

const toastConfiguration = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
};

const initialValues = {
  role: "",
  full_name: "",
  company_name: "",
  phone_number: "",
  email: "",
  password: "",
  confirmpassword: "",
  country_code: "+91",
};

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}/signup`,
    navigateToLoginRequestUrl: true,
  },
  cache: { cacheLocation: "sessionStorage", storeAuthStateInCookie: false },
};
const countryCodes = [
  { value: "+1", label: "US" },
  { value: "+91", label: "IN" },
  { value: "+33", label: "FR" },
  { value: "+44", label: "UK" },
  { value: "+52", label: "MX" },
  { value: "+86", label: "CN" },
  { value: "+64", label: "NZ" },
];

const SignUp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectOption] = useState(countryCodes[0]);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmpassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRetailer, setIsRetailer] = useState(false);
  const [isSignUpScreen, setIsSignUpScreen] = useState(false);
  const [showInputield, setShowInputield] = useState(false);
  const [userType, setUserType] = useState("");
  const isUserInvited = JSON.parse(localStorage.getItem("invitedUser"));
  const [formikValues, setFormikValues] = useState(null);

  const [resetFormFunction, setResetFormFunction] = useState(null);

  useEffect(() => {
    if (isUserInvited) {
      setIsRetailer(true);
      setIsSignUpScreen(true);
      setUserType("retailer");
      initialValues.email = isUserInvited.endUser;
    }
  }, [isUserInvited, navigate]);

  const handleUserType = (resetForm) => {
    resetForm();
    userType === "retailer" ? setIsRetailer(true) : setIsRetailer(false);
    setIsSignUpScreen(!isSignUpScreen);
  };

  function formatPhoneNumber(phoneNumberString) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  let signUpData = {};
  const onSubmitHandler = async (values, resetForm) => {
    setIsFormSubmitted(true);
    console.log(values, "values");
    signUpData = {
      email: values.email.toLowerCase()?.trim(),
      password: values.password,
      full_name: values.full_name?.trim(),
      // IsConscent: 1,
      role: userType.toLowerCase(),
      ...(!isRetailer && { company_name: values.company_name?.trim() }),
    };
    if (userType === "broker") {
      signUpData.phone_number = formatPhoneNumber(values.phoneNumber);
      signUpData.country_code = selectedOption?.value;
    }
    setLoading(true);
    const response = await dispatch(userSignup(signUpData));
    setLoading(false);
    setIsFormSubmitted(false);
    localStorage.setItem("invitedUserRegistered", true);
    if (response.meta?.requestStatus === "fulfilled") {
      navigate("/login");
    }
  };

  const handleChange = (option) => {
    setSelectOption(option);
  };

  const loginMicrosoft = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {})
      .catch((error) => {});
    try {
      const authResponse = await msalInstance.loginPopup();
      if (authResponse) {
        setShowInputield(!showInputield);
      }
      const item = {
        data: {
          role: userType,
          social_id: authResponse?.uniqueId,
          social_type: "microsoft",
          name: authResponse?.account?.name,
          email: authResponse?.account?.idTokenClaims?.email,
        },
      };
      socialDataStoreCall(item);
    } catch (error) {
      toast.error("Authentication failed", {
        toastId: 1,
        ...toastConfiguration,
      });
    }
  };

  const socialDataStoreCall = (item) => {
    dispatch(socialDataStore({ item, userType })).then((res) => {
      setIsFormSubmitted(false);
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        navigate("/authenticate");
      }
    });
  };
  const socialLogin = async (data) => {
    const item = {
      data: {
        role: userType,
        social_id: data?.data?.access_token,
        social_type: data?.provider,
        name: data?.data?.name,
        email: data?.data?.email,
      },
    };
    if (data) {
      socialDataStoreCall(item);
    }
  };
  const linkedinLogin = async (data) => {
    setLoading(true);
    const payload = { accessToken: data?.data?.access_token };
    dispatch(getLinkedInProfile(payload))
      .then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          const item = {
            data: {
              social_id: data?.data?.id,
              social_type: data?.provider,
              access_token: data?.data?.access_token,
              name: `${data?.data?.localizedFirstName} ${data?.data?.localizedLastName}`,
              email: response?.payload?.email,
            },
          };
          setLoading(false);
          socialDataStoreCall(item);
        }
      })
      .catch(() => {
        toast.error("Authentication failed", {
          toastId: 1,
          ...toastConfiguration,
        });
      });
  };

  return (
    <>
      {!isSignUpScreen && (
        <div className="d-flex align-items-center gap-4 ">
          {" "}
          <b className="h4-One d-flex">{"SignUp"}</b>{" "}
        </div>
      )}
      <div className="signUpFormContainer">
        {isModalOpen && (
          <BetaTesterAgreementModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onSubmitHandler={onSubmitHandler}
            formikValues={formikValues}
            resetForm={resetFormFunction}
          />
        )}
        <div className="d-grid justify-content-center right-0 landingPageForm_content">
          {showInputield ? (
            ""
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={
                  userType === "broker"
                    ? signUpValidationSchemaBroker
                    : signUpValidationSchema
                }
                // onSubmit={(values, { resetForm }) =>
                //   onSubmitHandler(values, resetForm)
                // }
                onSubmit={(values, { resetForm }) => {
                  setFormikValues(values);
                  setResetFormFunction(() => resetForm);
                  openModal();
                }}
              >
                {({ errors, touched, resetForm }) => (
                  <Form>
                    {!isSignUpScreen ? (
                      <div className="d-grid input-container">
                        <label className="semi-bold input-container mb-3">
                          What type of user are you?
                        </label>
                        <div
                          className={`role mb-3 d-flex align-items-center py-3  px-3 gap-3 ${
                            userType === "broker" ? "selectedUser" : ""
                          }`}
                          type="button"
                          onClick={() => setUserType("broker")}
                        >
                          <img
                            src={require(`assets/broker.svg`).default}
                            alt=""
                            className="icon-Container"
                            loading="lazy"
                          />
                          <h5>I am a Broker</h5>
                        </div>
                        <div
                          className={`role d-flex align-items-center py-3 px-3  gap-3 ${
                            userType === "retailer" ? "selectedUser" : ""
                          }`}
                          type="button"
                          onClick={() => setUserType("retailer")}
                        >
                          <img
                            src={require(`assets/retailer.svg`).default}
                            alt=""
                            className="icon-Container "
                            loading="lazy"
                          />
                          <h5>I am a Retailer</h5>
                        </div>
                        <Button
                          text="Continue"
                          disabled={userType === ""}
                          className="my-3 m-auto"
                          handleClick={() => handleUserType(resetForm)}
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          className="d-flex align-items-center gap-2 signupRouting"
                          type="button"
                          onClick={() => handleUserType(resetForm)}
                        >
                          <img
                            src={require(`assets/arrow-left.svg`).default}
                            alt=""
                            loading="lazy"
                          />
                          <div className="h4-Two d-flex">
                            {"SignUp / "}{" "}
                            <p
                              className="h4-One"
                              style={{
                                fontSize: "smaller",
                                alignContent: "center",
                              }}
                            >
                              &nbsp;
                              {userType === "retailer" ? "Retailer" : "Broker"}
                            </p>{" "}
                          </div>
                        </div>
                        <div className="d-grid input-container signUpFields">
                          <label htmlFor="">Full Name</label>
                          <Field
                            placeholder="Ex: Jordan Doe"
                            name="full_name"
                            type="text"
                            className="custom_input"
                          />
                          {errors.full_name && touched.full_name ? (
                            <div className="text-danger">
                              {errors.full_name}
                            </div>
                          ) : null}
                        </div>
                        {!isRetailer && (
                          <div className="d-grid input-container">
                            <label htmlFor="">Company Name</label>
                            <Field
                              placeholder="Ex: Jordan Doe"
                              name="company_name"
                              type="text"
                              className="custom_input"
                            />
                            {errors.company_name && touched.company_name ? (
                              <div className="text-danger">
                                {errors.company_name}
                              </div>
                            ) : null}
                          </div>
                        )}

                        {userType === "broker" && (
                          <div className="d-grid input-container ">
                            <label htmlFor="">Phone No</label>
                            <div className="d-flex gap-2 align-items-center">
                              <Select
                                className="select-country-code"
                                options={countryCodes}
                                value={selectedOption}
                                onChange={handleChange}
                              />
                              <Field
                                placeholder="Ex: 555 123 1234"
                                name="phoneNumber"
                                type="number"
                                maxLength={10}
                                className="custom_input inputPhone"
                              />
                            </div>
                            {errors.phoneNumber ? (
                              <div className="text-danger">
                                {errors.phoneNumber}
                              </div>
                            ) : null}
                          </div>
                        )}
                        <div className="d-grid input-container ">
                          <label htmlFor="">
                            {(!isRetailer ? "Company " : "") + "Email"}
                          </label>
                          <Field
                            placeholder="Ex: Jordan@8081.com"
                            name="email"
                            type="email"
                            className="custom_input"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="d-grid input-container ">
                          <label htmlFor="">Password</label>
                          <div className="d-flex position-relative align-items-center">
                            <Field
                              className="custom_input pe-4"
                              type={showPassword.password ? "text" : "password"}
                              name="password"
                            />
                            <img
                              src={showPassword.password ? eye : eyeOff}
                              alt="Show password"
                              onClick={() =>
                                setShowPassword((prevState) => ({
                                  ...prevState,
                                  password: !prevState.password,
                                }))
                              }
                              className={`password-eye ${
                                showPassword.password
                                  ? "eye-icon"
                                  : "eye-icon-inactive"
                              } `}
                              loading="lazy"
                            />
                          </div>
                          {errors.password && touched.password ? (
                            <div className="text-danger">{errors.password}</div>
                          ) : null}
                        </div>
                        <div className="d-grid input-container ">
                          <label htmlFor="">Confirm Password</label>
                          <div className="d-flex position-relative align-items-center">
                            <Field
                              className="custom_input pe-4"
                              type={
                                showPassword.confirmpassword
                                  ? "text"
                                  : "password"
                              }
                              name="confirmpassword"
                            />
                            <img
                              src={showPassword.confirmpassword ? eye : eyeOff}
                              alt="Show password"
                              onClick={() =>
                                setShowPassword((prevState) => ({
                                  ...prevState,
                                  confirmpassword: !prevState.confirmpassword,
                                }))
                              }
                              className={`password-eye ${
                                showPassword.confirmpassword
                                  ? "eye-icon"
                                  : "eye-icon-inactive"
                              } `}
                              loading="lazy"
                            />
                          </div>
                          {errors.confirmpassword && touched.confirmpassword ? (
                            <div className="text-danger">
                              {errors.confirmpassword}
                            </div>
                          ) : null}
                        </div>
                        <div className="termsCondition text-center">
                          <div className="justify-content-center d-grid">
                            {" "}
                            By signing up, you agree to our{" "}
                            <div>
                              {" "}
                              <Link to="/termsofuse" className="terms-text">
                                {" "}
                                Terms of use{" "}
                              </Link>{" "}
                              and{" "}
                              <Link to="/privacypolicy" className="terms-text">
                                {" "}
                                Privacy Policy{" "}
                              </Link>{" "}
                            </div>{" "}
                          </div>
                          <div className="justify-content-center d-flex">
                            <Button
                              className="buttonWidth my-4"
                              type="submit"
                              // onClick={openModal}
                              disabled={
                                Object.keys(errors).length > 0 ||
                                loading ||
                                isFormSubmitted
                              }
                              loading={loading || isFormSubmitted}
                              text="Create an Account"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
              {isSignUpScreen === true && (
                <>
                  <div className="lineSignin d-flex align-items-center mb-4 justify-content-center">
                    <p className="sign-up-with text-center px-2">
                      or sign up with{" "}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-3 justify-content-center mb-3">
                    <LoginSocialMicrosoft
                      isOnlyGetToken
                      client_id={process.env.REACT_APP_MICROSOFT_APP_ID}
                      redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}`}
                      onLoginStart={loginMicrosoft}
                      onResolve={loginMicrosoft}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <div className="button-lg d-flex gap-2">
                        <img
                          src={require(`assets/microsoft.svg`).default}
                          alt="Sign Up with Microsoft"
                          loading="lazy"
                        />
                        <p className="curser-pointer">
                          {" "}
                          <strong>Microsoft</strong>{" "}
                        </p>
                      </div>
                    </LoginSocialMicrosoft>
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}/signup`}
                      onResolve={(response) => {
                        socialLogin(response);
                      }}
                      onReject={(error) => {
                        console.log("Error", error);
                      }}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                    >
                      {" "}
                      <div className="curser-pointer">
                        <Google />{" "}
                      </div>
                    </LoginSocialGoogle>

                    <LoginSocialLinkedin
                      client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                      client_secret={
                        process.env.REACT_APP_LINKEDIN_CLIENT_SECRET
                      }
                      scope="email,openid,profile"
                      redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}/login`}
                      onResolve={(response) => {
                        linkedinLogin(response);
                      }}
                      onReject={(error) => {
                        console.log("Error", error);
                      }}
                    >
                      <img
                        src={require(`assets/Linkdin.svg`).default}
                        alt="Sign Up with Linkdin"
                        className="curser-pointer"
                        loading="lazy"
                      />
                    </LoginSocialLinkedin>

                    <LoginSocialFacebook
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}/signup`}
                      onResolve={(response) => {
                        socialLogin(response);
                      }}
                      onReject={(error) => {
                        console.log("facebookerror", error);
                      }}
                    >
                      <img
                        src={require(`assets/facebook-fill.svg`).default}
                        alt="Sign Up with Facebook"
                        className="curser-pointer"
                        loading="lazy"
                      />
                    </LoginSocialFacebook>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div
        data-bs-toggle="modal"
        data-bs-target="#needSupport"
        className="forgot-password d-flex flex-column justify-content-center gap-1 mt-5 mb-2"
      >
        <h5 className="h5-Three d-flex justify-content-center">Need help? </h5>{" "}
        <div className="d-flex justify-content-center curser-pointer">
          {" "}
          Customer Support{" "}
        </div>{" "}
      </div>
      <Support />
    </>
  );
};

export default SignUp;
