import React, { useCallback, useEffect, useMemo } from "react";
import LandingScreen from "screens/LandingScreen";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DashboardScreen from "screens/DashboardScreen";
import Login from "components/Login";
import SignUp from "components/SignUp";
import TwoFactorAuthenticate from "components/TwoFactorAuthentication";
import ForgotPassword from "components/ForgotPassword";
import Report from "screens/ReportScreen";
import Community from "screens/CommunityScreen";
import CommunityBlog from "screens/CommunityScreen/CommunityBlog";
import CommunityBrokers from "screens/CommunityScreen/CommunityBrokers";
import Management from "screens/ManagementScreen";
import ManagementClients from "screens/ManagementScreen/ManagementClients";
import ManagementDroids from "screens/ManagementScreen/ManagementDroids";
import ManagementPortfolios from "screens/ManagementScreen/ManagementPortfolios";
import ManagementTask from "screens/ManagementScreen/ManagementTask";
import Profile from "screens/ProfileScreen";
import "./App.scss";
import ResetPassword from "components/resetPassword";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "components/ChangePassword";

import {
  clearOTPSentSuccessfullyMessage,
  clearOTPVerifiedSuccessfully,
  clearIsForgotPasswordEmailSent,
  clearOTPSentFailedMessage,
  clearLoginErrorMessage,
  clearSignupErrorMessage,
  clearAuthenticateErrorMessage,
  clearForgotPasswordErrorMessage,
  clearResetPasswordErrorMessage,
  clearSignUpSuccessMessage,
  clearLogoutSuccess,
  clearChangePasswordMessages,
  clearverifyNonAuthPhoneEmail,
  clearLoginMessage,
  clearUserExistStatuse,
} from "features/auth/authSlice";

import {
  clearCreateExchangeStatus,
  clearCreateExchangeError,
  clearDeleteExchange,
} from "features/Exchange/ExchangeSlice";

import { clearisuploadLicense } from "features/Dashboard/dashboardSlice";
import { clearisgetcontactUs } from "features/GetInTouch/GetInTouchSlice";

import {
  clearclientInvite,
  clearreplyMessage,
  clearsendPendingRemainder,
  clearsendMailToDeclinedUser,
  clearDroidApproval,
  clearChangeDroidStatus,
} from "features/Management/managementSlice";

import {
  cleanMessagesError,
  cleanAddMessagesError,
} from "features/UserChat/userChatSlice";

import { clearisUserProfileUpdated } from "features/profile/profileSlice";

import {
  clearispostDroids,
  clearisdeleteDroid,
  clearisupdateDroids,
  clearisupdateDroidsError,
} from "features/Droids/droidsSlice";

import {
  clearispostReviewData,
  clearissendConnectionRequest,
  clearisdeleteReviewData,
  cleariseditReviews,
  clearispostaskQuestion,
  clearisaddMessage,
} from "features/Community/communitySlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHookstate } from "@hookstate/core";
import { STICKY_STATE } from "./globalStore/GlobalStore";
import TermsOfUse from "screens/TermsOfUse";
import Broker from "screens/BrokerProfile";
import UserChat from "components/UserChat";
import Exchange from "components/Exchange";
import ErrorPageDemo from "ErrorPageDemo";
import ErrorBoundary from "ErrorBoundary";
import RejectPage from "components/RejectPage";
import PrivacyPolicy from "screens/PrivacyPolicy";
import DashboardScreenHome from "screens/DashboardScreenHome";
import ClientDashboardDetails from "components/ClientDashboardDetails";
import AddDroidSetting from "screens/ManagementScreen/ManagementDroids/DroidSettings/AddDroidSetting";

function App() {
  const {
    isLoginSuccessful,
    isOTPSentSuccessful,
    isOTPVerifiedSuccessfully,
    isSignUpSuccessful,
    isForgotPasswordEmailSent,
    isOTPSentFailed,
    OTPSentFailedMessage,
    loginErrorMessage,
    signupErrorMessage,
    authenticateErrorMessage,
    forgotPasswordErrorMessage,
    isResetPasswordSuccessful,
    resetPasswordErrorMessage,
    isLoggedoutSuccessfull,
    isChangePasswordSuccessfull,
    changePasswordErrorMessage,
    isuserExistStatus,
    isuserExistStatusMessage,
    isverifyNonAuthPhoneEmail,
    isverifyNonAuthPhoneEmailError,
    verifyNonAuthPhoneEmailErrorMessage,
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    isemailInviteMessageInfo,
    isemailInviteMessageError,
    emailInviteMessageError,
    isanswerToQuestion,
    issendPendingRemainder,
    sendMailToDeclinedUser,
    issendMailToDeclinedUser,
  } = useSelector((state) => state?.management);

  const { isuploadLicense } = useSelector((state) => state?.dashboard);

  const {
    ispostDroids,
    isdeleteDroid,
    isdeleteDroidInfo,
    isupdateDroids,
    isupdateDroidsError,
    getAllDroidsByIdErrorInfo,
  } = useSelector((state) => state?.droids);

  const {
    ispostReviewData,
    iseditReviews,
    ispostaskQuestion,
    isaddMessage,
    issendConnectionRequest,
    isdeleteReviewData,
  } = useSelector((state) => state?.community);

  const {
    isCreateExchangeError,
    isCreateExchangeStatus,
    isdeleteExchangeStatus,
  } = useSelector((state) => state.Exchange);

  const { isgetcontactUs } = useSelector((state) => state?.contactUs);

  const { isgetMessagesError, addMessageError, isaddMessageError } =
    useSelector((state) => state?.userMessage);

  const { isUserProfileUpdated } = useSelector((state) => state?.profile);

  const isSticky = useHookstate(STICKY_STATE);
  const {
    droidApproval,
    isdroidApproval,
    ischangeDroidStatus,
    changeDroidStatus,
  } = useSelector((state) => state?.management);

  useEffect(() => {
    checkIfTokenExpired();
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 49 ? isSticky.set(true) : isSticky.set(false);
    }
  };

  const toastConfiguration = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  let user_type =
    localStorage.getItem("role") === "broker" ? "retailer" : "broker";

  const clearPopupNotification = useCallback(() => {
    isOTPSentSuccessful && dispatch(clearOTPSentSuccessfullyMessage());
    isForgotPasswordEmailSent && dispatch(clearIsForgotPasswordEmailSent());
    isLoginSuccessful && dispatch(clearLoginMessage());
    isOTPVerifiedSuccessfully && dispatch(clearOTPVerifiedSuccessfully());
    isSignUpSuccessful && dispatch(clearSignUpSuccessMessage());
    isOTPSentFailed && dispatch(clearOTPSentFailedMessage());
    loginErrorMessage && dispatch(clearLoginErrorMessage());
    signupErrorMessage && dispatch(clearSignupErrorMessage());
    authenticateErrorMessage && dispatch(clearAuthenticateErrorMessage());
    forgotPasswordErrorMessage && dispatch(clearForgotPasswordErrorMessage());
    isResetPasswordSuccessful && dispatch(clearResetPasswordErrorMessage());
    resetPasswordErrorMessage && dispatch(clearResetPasswordErrorMessage());
    isLoggedoutSuccessfull && dispatch(clearLogoutSuccess());
    isChangePasswordSuccessfull && dispatch(clearChangePasswordMessages());
    changePasswordErrorMessage && dispatch(clearChangePasswordMessages());
    isuploadLicense && dispatch(clearisuploadLicense());
    isemailInviteMessageInfo && dispatch(clearclientInvite());
    isemailInviteMessageError && dispatch(clearclientInvite());
    ispostReviewData && dispatch(clearispostReviewData());
    issendConnectionRequest && dispatch(clearissendConnectionRequest());
    isUserProfileUpdated && dispatch(clearisUserProfileUpdated());
    isdeleteReviewData && dispatch(clearisdeleteReviewData());
    iseditReviews && dispatch(cleariseditReviews());
    ispostaskQuestion && dispatch(clearispostaskQuestion());
    isanswerToQuestion && dispatch(clearreplyMessage());
    isgetMessagesError && dispatch(cleanMessagesError());
    isaddMessageError && dispatch(cleanAddMessagesError());
    ispostDroids && dispatch(clearispostDroids());
    isdeleteDroid && dispatch(clearisdeleteDroid());
    isupdateDroids && dispatch(clearisupdateDroids());
    isupdateDroidsError && dispatch(clearisupdateDroidsError());
    isgetcontactUs && dispatch(clearisgetcontactUs());
    isuserExistStatus && dispatch(clearUserExistStatuse());
    isverifyNonAuthPhoneEmail && dispatch(clearverifyNonAuthPhoneEmail());
    isverifyNonAuthPhoneEmailError && dispatch(clearverifyNonAuthPhoneEmail());
    issendPendingRemainder && dispatch(clearsendPendingRemainder());
    issendMailToDeclinedUser && dispatch(clearsendMailToDeclinedUser());
    isCreateExchangeError && dispatch(clearCreateExchangeError());
    isCreateExchangeStatus && dispatch(clearCreateExchangeStatus());
    isdeleteExchangeStatus && dispatch(clearDeleteExchange());
    isdroidApproval && dispatch(clearDroidApproval());
    ischangeDroidStatus && dispatch(clearChangeDroidStatus());
    isaddMessage && dispatch(clearisaddMessage());
  });

  const popupNotification = useCallback(() => {
    isOTPSentSuccessful &&
      toast.success("OTP Sent Successfully.", {
        toastId: 3,
        ...toastConfiguration,
      });
    isOTPSentFailed &&
      toast.error(OTPSentFailedMessage, { toastId: 2, ...toastConfiguration });
    isForgotPasswordEmailSent &&
      toast.success("Email Sent Successfully.", {
        toastId: 4,
        ...toastConfiguration,
      });
    isSignUpSuccessful &&
      toast.success("User Created Successfully.", {
        toastId: 5,
        ...toastConfiguration,
      });
    loginErrorMessage &&
      toast.error(loginErrorMessage, { toastId: 6, ...toastConfiguration });
    signupErrorMessage &&
      toast.error(signupErrorMessage, { toastId: 7, ...toastConfiguration });
    authenticateErrorMessage &&
      toast.error(authenticateErrorMessage, {
        toastId: 8,
        ...toastConfiguration,
      });
    forgotPasswordErrorMessage &&
      toast.error(forgotPasswordErrorMessage, {
        toastId: 9,
        ...toastConfiguration,
      });
    isResetPasswordSuccessful &&
      toast.success("Password Reset Successful.", {
        toastId: 10,
        ...toastConfiguration,
      });
    resetPasswordErrorMessage &&
      toast.error(resetPasswordErrorMessage, {
        toastId: 11,
        ...toastConfiguration,
      });
    isLoggedoutSuccessfull &&
      toast.success("You have been Successfully Logged out!", {
        toastId: 12,
        ...toastConfiguration,
      });
    isChangePasswordSuccessfull &&
      toast.success("Password Changed Successfully.", {
        toastId: 13,
        ...toastConfiguration,
      });
    changePasswordErrorMessage &&
      toast.error(changePasswordErrorMessage, {
        toastId: 14,
        ...toastConfiguration,
      });
    isemailInviteMessageInfo &&
      toast.success("Client Invitation Sent.", {
        toastId: 15,
        ...toastConfiguration,
      });
    isemailInviteMessageError &&
      toast.error(emailInviteMessageError, {
        toastId: 15,
        ...toastConfiguration,
      });
    isuploadLicense &&
      toast.success("License Uploaded Successfully.", {
        toastId: 16,
        ...toastConfiguration,
      });
    issendConnectionRequest &&
      toast.success("Connection Request Sent Successfully.", {
        toastId: 17,
        ...toastConfiguration,
      });
    ispostReviewData &&
      toast.success("Review Submitted Successfully.", {
        toastId: 18,
        ...toastConfiguration,
      });
    isUserProfileUpdated &&
      toast.success("Profile Updated Successfully.", {
        toastId: 19,
        ...toastConfiguration,
      });
    isLoginSuccessful &&
      toast.success("Successfully logged in.", {
        toastId: 20,
        ...toastConfiguration,
      });
    isdeleteReviewData &&
      toast.success("Review Deleted Successfully.", {
        toastId: 21,
        ...toastConfiguration,
      });
    iseditReviews &&
      toast.success("Review Updated Successfully.", {
        toastId: 22,
        ...toastConfiguration,
      });
    ispostaskQuestion &&
      toast.success("Your message has been sent Successfully.", {
        toastId: 23,
        ...toastConfiguration,
      });
    isanswerToQuestion &&
      toast.success("Answer Sent Successfully.", {
        toastId: 124,
        ...toastConfiguration,
      });
    isgetMessagesError &&
      toast.error(addMessageError, { toastId: 14, ...toastConfiguration });
    isaddMessageError &&
      toast.success("Message Sent Successfully.", {
        toastId: 14,
        ...toastConfiguration,
      });
    ispostDroids &&
      toast.success(
        localStorage.getItem("role") === "retailer"
          ? "Droid Created Successfully."
          : "Wait For Approval.",
        { toastId: 14, ...toastConfiguration }
      );
    isgetcontactUs &&
      toast.success("We will connect Soon.", {
        toastId: 14,
        ...toastConfiguration,
      });
    isverifyNonAuthPhoneEmail &&
      toast.success(verifyNonAuthPhoneEmailErrorMessage, {
        toastId: 14,
        ...toastConfiguration,
      });
    isverifyNonAuthPhoneEmailError &&
      toast.error(verifyNonAuthPhoneEmailErrorMessage, {
        toastId: 14,
        ...toastConfiguration,
      });
    issendPendingRemainder &&
      toast.success("Email Sent Successfully.", {
        toastId: 14,
        ...toastConfiguration,
      });
    issendMailToDeclinedUser &&
      toast.success(sendMailToDeclinedUser?.message, {
        toastId: 14,
        ...toastConfiguration,
      });
    isCreateExchangeError &&
      toast.error("Invalid or expired API keys.", {
        toastId: 14,
        ...toastConfiguration,
      });
    isCreateExchangeStatus &&
      toast.success("Successfully Connected.", {
        toastId: 34,
        ...toastConfiguration,
      });
    isdeleteExchangeStatus &&
      toast.success("Successfully Deleted.", {
        toastId: 35,
        ...toastConfiguration,
      });
    isdeleteDroid &&
      toast.success(isdeleteDroidInfo, { toastId: 14, ...toastConfiguration });
    isupdateDroids &&
      toast.success("Droid Update Successful.", {
        toastId: 14,
        ...toastConfiguration,
      });
    isupdateDroidsError &&
      toast.error(getAllDroidsByIdErrorInfo?.error, {
        toastId: 14,
        ...toastConfiguration,
      });
    isdroidApproval &&
      toast.success(droidApproval, { toastId: 14, ...toastConfiguration });
    ischangeDroidStatus &&
      toast.success(changeDroidStatus, { toastId: 14, ...toastConfiguration });
    isuserExistStatus &&
      toast.success(isuserExistStatusMessage, {
        toastId: 14,
        ...toastConfiguration,
      });
    isaddMessage &&
      toast.success("Your message has been sent successfully.", {
        toastId: 23,
        ...toastConfiguration,
      });
    clearPopupNotification();
  }, [toastConfiguration]);

  useEffect(() => {
    popupNotification();
    setTimeout(() => clearTimeout(clearPopupNotification), 1000);
  }, [
    dispatch,
    isOTPSentSuccessful,
    isForgotPasswordEmailSent,
    isLoginSuccessful,
    isSignUpSuccessful,
    isOTPSentFailed,
    loginErrorMessage,
    signupErrorMessage,
    authenticateErrorMessage,
    forgotPasswordErrorMessage,
    isResetPasswordSuccessful,
    resetPasswordErrorMessage,
    isLoggedoutSuccessfull,
    isChangePasswordSuccessfull,
    changePasswordErrorMessage,
    isuploadLicense,
    isemailInviteMessageInfo,
    isemailInviteMessageError,
    OTPSentFailedMessage,
    issendConnectionRequest,
    ispostReviewData,
    isUserProfileUpdated,
    isdeleteReviewData,
    iseditReviews,
    ispostaskQuestion,
    isanswerToQuestion,
    isgetMessagesError,
    isaddMessageError,
    ispostDroids,
    isgetcontactUs,
    isuserExistStatus,
    isverifyNonAuthPhoneEmail,
    isverifyNonAuthPhoneEmailError,
    issendPendingRemainder,
    issendMailToDeclinedUser,
    isCreateExchangeError,
    isCreateExchangeStatus,
    isdeleteDroid,
    isupdateDroids,
    isdeleteExchangeStatus,
    isdroidApproval,
    ischangeDroidStatus,
    changeDroidStatus,
    isaddMessage,
    popupNotification,
    clearPopupNotification,
  ]);

  const urlParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  useEffect(() => {
    if (urlParams.size > 0) {
      const broker = urlParams.get("broker")?.replace(/\s/g, "+");
      const endUser = urlParams.get("endUser");
      const status = urlParams.get("status");
      const type = urlParams.get("type");
      const session = urlParams.getAll("session");

      const invitedUser = {
        broker,
        endUser,
        status,
        type,
        session,
      };
      if (
        localStorage.getItem("invitedUser") ||
        localStorage.getItem("invitedUserRegistered") ||
        localStorage.getItem("isUserRejected")
      ) {
        localStorage.removeItem("invitedUser");
        localStorage.removeItem("invitedUserRegistered");
        localStorage.removeItem("isUserRejected");
      }
      if (invitedUser.status == "2" || invitedUser.type == "accept") {
        localStorage.setItem("invitedUser", JSON.stringify(invitedUser));
      } else if (invitedUser.status == "0" || invitedUser.type == "reject") {
        localStorage.setItem("isUserRejected", true);
      } else {
        localStorage.removeItem("invitedUser");
        localStorage.removeItem("invitedUserRegistered");
      }
    }
  }, [urlParams]);

  const checkIfTokenExpired = () => {
    const authToken = localStorage.getItem("userToken");
    const authTokenExpirationTime = localStorage.getItem("tokenExpirationTime");
    const currentTime = Date.now();

    if (authToken && currentTime > parseInt(authTokenExpirationTime)) {
      window.location.href = "/login";
      localStorage.clear();
    }
  };

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    if (expireTime < Date.now()) {
      toast.warn("You are logged out due to inactivity.", {
        toastId: 100,
        ...toastConfiguration,
      });
      window.location.href = "/login";
      localStorage.removeItem("tokenExpirationTime");
      localStorage.removeItem("userToken");
      localStorage.removeItem("dashboardDroid");
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 5 * 60000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.addEventListener("click", updateExpireTime);
      window.addEventListener("keypress", updateExpireTime);
      window.addEventListener("scroll", updateExpireTime);
      window.addEventListener("mousemove", updateExpireTime);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                {" "}
                <LandingScreen />
              </ErrorBoundary>
            }
          >
            <Route
              path="/login"
              element={
                <ErrorBoundary>
                  <Login />
                </ErrorBoundary>
              }
            />
            <Route
              path="/forgotpassword"
              element={
                <ErrorBoundary>
                  <ForgotPassword />
                </ErrorBoundary>
              }
            />
            <Route
              path="/signup"
              element={
                <ErrorBoundary>
                  <SignUp />
                </ErrorBoundary>
              }
            />
            <Route
              path="/authenticate"
              element={
                <ErrorBoundary>
                  {" "}
                  <TwoFactorAuthenticate />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            element={
              <ErrorBoundary>
                <DashboardScreen />
              </ErrorBoundary>
            }
          >
            <Route
              path="/dashboard"
              element={
                <ErrorBoundary>
                  {" "}
                  <DashboardScreenHome />
                </ErrorBoundary>
              }
            />
            <Route
              path="/exchange"
              element={
                <ErrorBoundary>
                  <Exchange />
                </ErrorBoundary>
              }
            />
            <Route
              path="report"
              element={
                <ErrorBoundary>
                  {" "}
                  <Report />{" "}
                </ErrorBoundary>
              }
            />
            <Route
              path="/community"
              element={
                <ErrorBoundary>
                  <Community />
                </ErrorBoundary>
              }
            >
              <Route
                path="blog"
                element={
                  <ErrorBoundary>
                    <CommunityBlog />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${user_type}s`}
                element={
                  <ErrorBoundary>
                    <CommunityBrokers />
                  </ErrorBoundary>
                }
              />
              <Route
                index
                element={
                  <ErrorBoundary>
                    {" "}
                    <Navigate to={`${user_type}s`} />
                  </ErrorBoundary>
                }
              ></Route>
            </Route>
            <Route
              path={`/${user_type}sProfile/:id`}
              element={
                <ErrorBoundary>
                  {" "}
                  <Broker />
                </ErrorBoundary>
              }
            />
            <Route
              path="/inviteclientreject"
              element={
                <ErrorBoundary>
                  {" "}
                  <Broker />
                </ErrorBoundary>
              }
            />
            <Route
              path="/management/"
              element={
                <ErrorBoundary>
                  {" "}
                  <Management />
                </ErrorBoundary>
              }
            >
              <Route
                path="droids/:droidName"
                element={
                  <ErrorBoundary>
                    <AddDroidSetting />
                  </ErrorBoundary>
                }
              />
              <Route
                path="droids/:droidName/:id"
                element={
                  <ErrorBoundary>
                    {" "}
                    <AddDroidSetting />
                  </ErrorBoundary>
                }
              />
              <Route
                path="task"
                element={
                  <ErrorBoundary>
                    {" "}
                    <ManagementTask />{" "}
                  </ErrorBoundary>
                }
              />
              {localStorage.getItem("role") === "retailer" ? (
                <Route
                  path="portfolios"
                  element={
                    <ErrorBoundary>
                      <ManagementPortfolios />
                    </ErrorBoundary>
                  }
                />
              ) : (
                <Route
                  path="clients"
                  element={
                    <ErrorBoundary>
                      <ManagementClients />
                    </ErrorBoundary>
                  }
                />
              )}
              <Route
                path="droids"
                element={
                  <ErrorBoundary>
                    <ManagementDroids />
                  </ErrorBoundary>
                }
              />
              <Route
                index
                element={
                  <ErrorBoundary>
                    <Navigate to="task" />
                  </ErrorBoundary>
                }
              ></Route>
            </Route>
            <Route
              path="Client-Dashboard-Details"
              element={
                <ErrorBoundary>
                  {" "}
                  <ClientDashboardDetails />
                </ErrorBoundary>
              }
            />
            <Route
              path="profile"
              element={
                <ErrorBoundary>
                  {" "}
                  <Profile />{" "}
                </ErrorBoundary>
              }
            />
            <Route
              path="/profile"
              element={
                <ErrorBoundary>
                  {" "}
                  <Profile />
                </ErrorBoundary>
              }
            />
            <Route
              path="/userchat"
              element={
                <ErrorBoundary>
                  <UserChat />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            path="/termsofuse"
            element={
              <ErrorBoundary>
                <TermsOfUse />
              </ErrorBoundary>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ErrorBoundary>
                {" "}
                <PrivacyPolicy />
              </ErrorBoundary>
            }
          />
          <Route
            path="/resetPassword"
            element={
              <ErrorBoundary>
                <ResetPassword />
              </ErrorBoundary>
            }
          />
          <Route
            path="/changePassword"
            element={
              <ErrorBoundary>
                {" "}
                <ChangePassword />
              </ErrorBoundary>
            }
          />
          <Route
            path="/ErrorPageDemo"
            element={
              <ErrorBoundary>
                <ErrorPageDemo />
              </ErrorBoundary>
            }
          />
          <Route
            path="/rejectPage"
            element={
              <ErrorBoundary>
                {" "}
                <RejectPage />
              </ErrorBoundary>
            }
          />
          <Route
            path="*"
            element={
              <ErrorBoundary>
                {" "}
                <Navigate to="/" replace />
              </ErrorBoundary>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default React.memo(App);
