import React from 'react'
import construction from 'assets/under_construction.png'


const Report = () => {
  return (
    <div className='d-flex justify-content-center mt-5'>
          <img src={construction} alt="" loading='lazy' />
    </div>
  )
}

export default Report