import React from 'react'
import './index.scss'
import Button from 'components/Button'
import PopupModal from 'components/PopupModal'
import SecondryButton from 'components/Button/SecondryButton'
import JoExchange from './JoeProfile/JoExchange'

const ExchangeProfile = ({getCancle,setCancle, riskSave,setRiskSave,riskDataNew,edit,profileData}) => {
  
  return (
    <div className='portfolio_risk_container'>
      <JoExchange getCancle={getCancle} setCancle={setCancle} riskSave={riskSave} setRiskSave={setRiskSave} riskDataNew={riskDataNew} profileData={profileData} edit={edit} />
      <PopupModal id={"Message"} backdrop={false}>
        <>
          <div className="modal-header border-0  px-4">
            <h3 className="modal-title" id="exampleModalLabel"> Write Jordan a Message </h3>
            <button type="button" className="btn-close btn-sm " data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body  py-1 ">
            <div className="card border-0 w-100 upload-color-bg">
              <textarea disabled className={`form-control w-100 p-4 bg-white p-One`} rows="3" ></textarea>
            </div>
          </div>
          <div className="modal-footer border-0">
            <SecondryButton data-bs-dismiss="modal" text="Cancel" />
            <Button text='Send' />
          </div>
        </>
      </PopupModal>
    </div>
  )
}

export default ExchangeProfile