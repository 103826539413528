import * as Yup from 'yup'

const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid Email Address')
        .max(128, 'Email must be at maximum 128 characters')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
})

export default loginValidationSchema