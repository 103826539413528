import React from "react";

function EyeOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 21 21"
    >
      <g fill="#6B6F80" clipPath="url(#clip0_2936_5243)">
        <path
          fillRule="evenodd"
          d="M3.957 2.566A1 1 0 002.543 3.98l14 14a1 1 0 001.414-1.414l-1.473-1.473a10.014 10.014 0 003.308-4.82c-1.274-4.057-5.064-7-9.542-7a9.958 9.958 0 00-4.512 1.074l-1.78-1.781h-.001zm4.261 4.26L9.732 8.34a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478v-.001z"
          clipRule="evenodd"
        ></path>
        <path d="M12.704 16.97L10 14.265a4 4 0 01-3.742-3.741L2.585 6.85a9.98 9.98 0 00-1.877 3.422c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"></path>
      </g>
      <defs>
        <clipPath id="clip0_2936_5243">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(.25 .273)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeOff;