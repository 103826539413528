import React from "react";

function ManagementIcon({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 18 18"
    >
      <path
        fill={color ? color : "#0078E7"}
        d="M12 6a2 2 0 01-2-2V2a2 2 0 012-2h4a2 2 0 012 2v2a2 2 0 01-2 2h-4zM2 10a2 2 0 01-2-2V2a2 2 0 012-2h4a2 2 0 012 2v6a2 2 0 01-2 2H2zm10 8a2 2 0 01-2-2v-6a2 2 0 012-2h4a2 2 0 012 2v6a2 2 0 01-2 2h-4zM2 18a2 2 0 01-2-2v-2a2 2 0 012-2h4a2 2 0 012 2v2a2 2 0 01-2 2H2zM2 8h4V2H2v6zm10 8h4v-6h-4v6zm0-12h4V2h-4v2zM2 16h4v-2H2v2z"
      ></path>
    </svg>
  );
}

export default ManagementIcon;
