function ThumbIcon({height,width}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#0078E7"
          d="M17.9 20.571H6.8V8.381l6.95-6.857.975.738c.1.08.175.19.225.333.05.143.075.318.075.524v.238L13.9 8.381h7.475c.4 0 .75.143 1.05.429.3.285.45.619.45 1v1.952c0 .111.012.226.038.345a.547.547 0 01-.038.345l-3.15 6.905a2.09 2.09 0 01-.738.857 1.87 1.87 0 01-1.087.357zm-9.6-1.428h9.925l3.15-7.12V9.81H12.05l1.325-5.93L8.3 8.977v10.167zm0-10.167v10.167V8.976zm-1.5-.595V9.81H3.475v9.333H6.8v1.428H1.975V8.381H6.8z"
        ></path>
      </svg>
    );
  }
  
  export default ThumbIcon;
