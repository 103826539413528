import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UploadFile from "components/UploadFile";
import { uploadLicense } from "features/Dashboard/dashboardAction";
import { userProfile } from "features/profile/profileAction";
import { ThumbIcon, UpDown, Doller, Search, Current } from "assets";
import * as Sentry from "@sentry/react";

function SetupAssistent({ setClose, hide, setHide, profilePage }) {
  const [licenseFile, setLicenseFile] = useState();
  const [licenseUploadeLoading, setlicenseUploadeLoading] = useState(false);
  const { userProfileInfo } = useSelector((state) => state?.profile);
  const [checkStatus, setCheckStatus] = useState(
    userProfileInfo?.setup_assistant || []
  );
  const [getProfileData, setProfileData] = useState();
  const hasData = !userProfileInfo?.social_networks
    ? false
    : Object?.values(userProfileInfo?.social_networks)?.some(
        (value) => value !== "" && value !== null
      );
  const limitStatus = !userProfileInfo?.userPreference
    ? false
    : userProfileInfo?.userPreference[0]?.limits;
  const location = useLocation();
  const count = Object?.values(checkStatus || []).filter((arr) => {
    if (arr === true) {
      return true;
    }
    return false;
  }).length;
  const { userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const submitLicense = () => {
    dispatch(uploadLicense({ userToken, licenseFile }))
      .then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setlicenseUploadeLoading(true);
          setCheckStatus(response?.payload[0]?.setup_assistant);
        } else {
          Sentry.captureException(response, {
            extra: {
              customData: "SetupAssistent--> Line 44",
            },
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "SetupAssistent--> Line 52",
          },
        });
      });
    setLicenseFile("");
  };

  useEffect(() => {
    dispatch(userProfile(userToken))
      .then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setCheckStatus(response?.payload?.setup_assistant);
          setlicenseUploadeLoading(false);
          setProfileData(response?.payload);
        } else {
          Sentry.captureException(response, {
            extra: {
              customData: "SetupAssistent--> Line 68",
            },
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "SetupAssistent--> Line 75",
          },
        });
      });
  }, [licenseFile, licenseUploadeLoading]);

  const shouldApplyDashboardActivityLog = location?.pathname === "/dashboard";

  return (
    <>
      {profilePage && (
        <div className="my-4">
          <div className="d-flex justify-content-between mb-1">
            <h3>Setup Assistant</h3>
          </div>
          <div className="progress mb-3">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="20"
              aria-valuemin="10"
              aria-valuemax="100"
              style={{ width: `${100 / ((count > 0 && 7) - count)}%` }}
            />
          </div>
        </div>
      )}
      <div
        className={`assistLinkCard ${profilePage ? "mb-4" : ""}  ${
          shouldApplyDashboardActivityLog ? "DashboardActivityLog" : ""
        } py-0 ${hide ? "" : "height-auto mx-3"} ${
          profilePage ? "" : "assistLinkCard-width py-4 mx-1"
        } `}
      >
        {!profilePage && (
          <>
            <div className="d-flex justify-content-between">
              <h3>Setup Assistant</h3>
              <div className="actionButtons">
                <img
                  className="btnEnlarge"
                  src={require("assets/expandIcon.svg").default}
                  alt=""
                  onClick={() => setHide(!hide)}
                  loading="lazy"
                />
                <img
                  className="btnEnlarge"
                  src={require("assets/closeIcon.svg").default}
                  alt=""
                  onClick={() => setClose(false)}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="progress mb-3">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="20"
                aria-valuemin="10"
                aria-valuemax="100"
                style={{ width: `${100 / ((count > 0 && 7) - count)}%` }}
              />
            </div>
          </>
        )}
        {hide && (
          <>
            {localStorage.getItem("role") === "retailer" ? (
              ""
            ) : (
              <Link
                to=""
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#Certificate"
                className="d-flex py-3 mt-3 justify-content-between"
              >
                <div className="d-flex gap-3 align-items-center">
                  <img
                    src={require("assets/tickIcon.svg").default}
                    alt=""
                    className="assistent-img"
                    loading="lazy"
                  />
                  Verify your license
                </div>
                {checkStatus !== undefined && checkStatus["license"] && (
                  <Current height="20" width="20" />
                )}
              </Link>
            )}

            {hasData ? (
              ""
            ) : (
              <Link
                to={`/profile`}
                className="d-flex py-3 justify-content-between"
              >
                <div
                  className={`${
                    location?.pathname === "/profile" ? "disableColor" : ""
                  } d-flex gap-3 align-items-center`}
                >
                  <div className="assistent-img">
                    {" "}
                    <ThumbIcon height="24" />{" "}
                  </div>
                  Connect to social networks
                </div>
                {checkStatus !== undefined && checkStatus["social_network"] && (
                  <Current height="20" width="20" />
                )}
              </Link>
            )}

            {localStorage.getItem("role") === "retailer" ? (
              <>
                {limitStatus?.buy > 0 || limitStatus?.sell > 0 ? (
                  ""
                ) : (
                  <Link
                    to={`/profile`}
                    className="d-flex py-3 justify-content-between"
                  >
                    <div
                      className={`${
                        location?.pathname === "/profile" ? "disableColor" : ""
                      } d-flex gap-3 align-items-center`}
                    >
                      <div className="assistent-img">
                        {" "}
                        <UpDown height="20" width="20" />{" "}
                      </div>
                      Set your trade limits
                    </div>
                    {checkStatus !== undefined &&
                      checkStatus["company_details"] && (
                        <Current height="20" width="20" />
                      )}
                  </Link>
                )}
                {getProfileData?.exchange?.length < 0 ? (
                  <Link
                    to={`${
                      getProfileData?.exchange?.length > 0
                        ? "/profile"
                        : "/exchange"
                    }`}
                    className={`${
                      getProfileData?.exchange?.length > 0 ? "disableColor" : ""
                    } d-flex py-3 justify-content-between`}
                  >
                    <div className="d-flex gap-3 align-items-center">
                      <div className="assistent-img">
                        {" "}
                        <Doller height="20" width="20" />{" "}
                      </div>
                      Select preferred exchanges
                    </div>
                    {checkStatus !== undefined && checkStatus["droid"] && (
                      <Current height="20" width="20" />
                    )}
                  </Link>
                ) : (
                  ""
                )}
                <Link
                  to="/community/brokers"
                  className="d-flex py-3 justify-content-between"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <div className="assistent-img">
                      {" "}
                      <Search height="20" width="20" />{" "}
                    </div>
                    Search for broker
                  </div>
                  {checkStatus !== undefined &&
                    checkStatus["clients_invite"] && (
                      <Current height="20" width="20" />
                    )}
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/profile"
                  className="d-flex py-3 justify-content-between"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={require("assets/bagIcon.svg").default}
                      alt=""
                      className="assistent-img"
                      loading="lazy"
                    />
                    Add your company details.
                  </div>
                  {checkStatus !== undefined &&
                    checkStatus["company_details"] && (
                      <Current height="20" width="20" />
                    )}
                </Link>
                <Link
                  to="/management/droids"
                  className="d-flex py-3 justify-content-between"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={require("assets/droid24Icon.svg").default}
                      alt=""
                      className="assistent-img"
                      loading="lazy"
                    />
                    Configure your first droid
                  </div>
                  {checkStatus !== undefined && checkStatus["droid"] && (
                    <Current height="20" width="20" />
                  )}
                </Link>
                <Link
                  to="/management/task"
                  className="d-flex py-3 justify-content-between"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src={require("assets/inviteclientsIcon.svg").default}
                      alt=""
                      className="assistent-img"
                      loading="lazy"
                    />
                    Invite your clients
                  </div>
                  {checkStatus !== undefined &&
                    checkStatus["clients_invite"] && (
                      <Current height="20" width="20" />
                    )}
                </Link>
              </>
            )}

            <Link
              to="/userchat"
              className="d-flex py-3 justify-content-between"
            >
              <div className="d-flex gap-3 align-items-center">
                <img
                  src={require("assets/tickIcon.svg").default}
                  alt=""
                  className="assistent-img"
                  loading="lazy"
                />
                Send a message to{" "}
                {localStorage.getItem("role") === "retailer"
                  ? "my broker."
                  : "client."}
              </div>
              {checkStatus !== undefined && checkStatus["message"] && (
                <Current height="20" width="20" />
              )}
            </Link>
          </>
        )}
      </div>
      <UploadFile
        setLicenseFile={setLicenseFile}
        licenseFile={licenseFile}
        submitLicense={submitLicense}
      />
    </>
  );
}

export default SetupAssistent;
