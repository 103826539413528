import React, { useEffect, useState } from "react";
import Button from "./Button";
import { DoubleArrow } from "assets";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { userLogout } from "features/auth/authActions";
import { logoutRejectUser } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const texData = [
  { text: "Technical Difficulties, need technical support" },
  { text: "Don’t understand the platform, need to talk with someone" },
  { text: "Didn’t ask to connect" },
  { text: "Other" },
];
const RejectPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedReason, setSelectedReason] = useState(null);
  const [saved, setSaved] = useState(false);
  const { userToken } = useSelector((state) => state.auth);

  const handleSubmit = () => {
    let data = {
      broker: location?.search?.split("=")[1]?.split("&")[0],
      endUser: location?.search?.split("=")[2]?.split("&")[0],
      reason: selectedReason,
    };
    axios
      .post(process.env.REACT_APP_UPDATE_DECLINE_REASON, data)
      .then((res) => {
        if (res.status) {
          setSaved(true);
          dispatch(userLogout(userToken)).then((response) => {
            if (response.meta && response.meta.requestStatus === "fulfilled") {
              dispatch(logoutRejectUser());
            }
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      })
      .catch((err) => console.log("error", err));
  };
  useEffect(() => {
    if (location?.search?.split("=")[4]?.split("&")[0] === "accept") {
      navigate(
        `/brokersProfile/${location?.search?.split("=")[1]?.split("&")[0]}`
      );
    }
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className="reject-heading">
        <div className="d-flex justify-content-center mb-3 ">
          <img
            src={require(`assets/companylogo.svg`).default}
            alt=""
            loading="lazy"
          />
        </div>
        <div className="border-bottom mb-4"></div>
        <h3 className="mb-2 text-primary">Unsubscribe Successfully!!</h3>
        <h5 className="mb-3 text-secondary">
          You will no longer receive email marketing from this list.
        </h5>
        <h6 className="text-wrap">
          Dear User, <br /> <br /> Thank you for considering using 8081.io as
          your leading automatic crypto trading platform. Upon rejecting the
          invite, can you please help us to specify the root cause and if there
          is something we can help you better understand and connect with your
          licensed advisor broker.
        </h6>
        <h5 className="mt-3 text-secondary">
          If you have a moment, please let us know why you unsubscribed:
        </h5>
        <div className="mt-2">
          {texData?.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-content-start align-items-center gap-2"
              >
                <input
                  type="radio"
                  name="option"
                  className="w-auto"
                  value={index + 1}
                  checked={selectedReason === `${index + 1}`}
                  onChange={(e) => setSelectedReason(e?.target?.value)}
                />
                <h6 className="text-secondary">{item?.text}</h6>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center">
          {saved ? (
            <h4 className="text-primary cursor">Thanks for Submitting</h4>
          ) : (
            <Button
              className="w-25 mt-3"
              text="Submit"
              onClick={handleSubmit}
            />
          )}
        </div>
        <div className="border-bottom my-4"></div>{" "}
        <div className="border-bottom my-4"></div>
        <div
          className="mt-3 gap-1 d-flex align-items-center justify-content-center"
          onClick={() => navigate(`/login`)}
        >
          {[...Array(3)].map((i, idx) => (
            <DoubleArrow fill1={"#0078E7"} fill2={"#0078E7"} key={idx} />
          ))}
          <h4 className="text-primary cursor">Return to our Website</h4>
          {[...Array(3)].map((i, idx) => (
            <DoubleArrow fill1={"#0078E7"} fill2={"#0078E7"} key={idx} />
          ))}
        </div>
        <div className="border-bottom my-4"></div>{" "}
        <div className="border-bottom my-4"></div>
      </div>
    </div>
  );
};

export default RejectPage;
