import React, { useEffect, useState } from 'react'
import CardShadow from 'components/CardShadow'
import MessageCard from './MessageCard'
import { getQuestionsAPI } from 'features/Management/managementAction'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuestionPin } from "features/ActivityLog/ActivityLogAction";



function AskQustionMessage() {
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.auth);
    const getQuestionsAPI = localStorage.getItem("questions")
    const getQuestionsAPIInfo = JSON.parse(getQuestionsAPI)
    const [getAllMessage, setAllMessage] = useState(getQuestionsAPIInfo || [])
    const [getLoading, setLoading] = useState(true)
    const [getFlag, setFlag] = useState(false)
    const [getShowImg, setShowImg] = useState({ id: '' })

    function getUnread() {
        let count = 0
        getQuestionsAPIInfo.forEach(item => {
            if (!item.answer) {
                count++
            }
        });

        return count
    }
    const handleChatPin = (pinned, id) => {
        var mapData = []

        getQuestionsAPIInfo?.map((item) => {
            if (item?._id == id) {
                item = { ...item, 'isPinned': !pinned }
                mapData?.push(item)
            } else {
                mapData?.push(item)
            }
        })

        mapData.sort((a, b) => {
            if (a.isPinned === b.isPinned) {
                return 0;
            } else if (a.isPinned) {
                return -1;
            } else {
                return 1;
            }
        });


        localStorage.setItem("questions", JSON.stringify(mapData))
        setAllMessage(mapData);
        const payload = { "isPinned": !pinned, id: id };
        dispatch(updateQuestionPin({ userToken, payload })).then((res) => {


            if (res.meta?.requestStatus === "fulfilled") {
                setFlag(!getFlag)
            }

        });


    }




    return (
        <>
            <CardShadow className="card-shadow fixed-header gap-1 d-flex align-items-center messages-top-border px-4">
                <h4 className='noWrap'>Messages</h4>
                <div className='client-number rounded-circle'>{getQuestionsAPIInfo?.length || 0} </div>
                <h4 className='noWrap'>Unread</h4>
                <div className='client-number rounded-circle'>{getQuestionsAPIInfo?.length ? getUnread() : 0} </div>
            </CardShadow>
            <div className='client-Message exchange-Cards-bar'>
                {getQuestionsAPIInfo?.length > 0 ? getQuestionsAPIInfo.map((item, index) => {

                    return (<MessageCard getShowImg={getShowImg} setShowImg={setShowImg} {...item} index={index} setLoading={setLoading} handleChatPin={handleChatPin} />)
                }) : null}
            </div>
        </>
    )
}

export default AskQustionMessage