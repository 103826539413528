import React from 'react'
import { useState } from 'react';
import CardSideMenu from '../CardSideMenu'

function AddOnCard({pinnedTabs,dataId, children, onClickPin, onClickUpdate, onClickExpand, isVisible }) {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <div className='position-relative' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <div className='addOnsGraphData d-flex justify-content-between p-2 ' >
        {children}
      </div>
      {isVisible ? <div className='onHoverMenu position-absolute'>
        <div className={isHovering ? 'visible h-100 d-flex flex-column h-100 align-items-center cardSideMenuAddon' : 'invisible '}>
          <CardSideMenu dataId={dataId} pinnedTabs={pinnedTabs} src1={require("assets/pin.svg").default} src2={require("assets/expand.svg").default} src3={require("assets/update.svg").default} onClickPin={onClickPin} onClickExpand={onClickExpand} onClickUpdate={onClickUpdate} />
        </div>
      </div> : ''}
    </div>
  )
}

export default AddOnCard;