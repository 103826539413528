import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    routeHistory: '',
    breadcrum_rdx:false,
    brokerProfile:{
        username:''
    }
}

const headerSlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        addHistory: (state, { payload }) => {
            state.routeHistory = payload
        },
        removeHistory:(state,{payload})=>{
            state.routeHistory = ''
        },
        setBreadcrum_rdx:(state,{payload})=>{
            state.breadcrum_rdx = payload
        },
        setBrokername:(state,{payload})=>{
            state.brokerProfile.username = payload
        },
        resetState:()=> initialState
        ,
       
    },
})

export const {addHistory, removeHistory,setBreadcrum_rdx,setBrokername,resetState} = headerSlice.actions
export default headerSlice.reducer
