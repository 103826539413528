import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//----------------------------Get Coin list------------------------
export const portfolioDataDashboard = createAsyncThunk(
    'portfolioDataDashboard',
    async ({portfolioID}, { rejectWithValue }) => {
        try {
            
            return portfolioID;

        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)