import React from 'react'
import SocialMedia from 'screens/ProfileScreen/SocialMedia';

const SocialMediaData = [
  { id: "linkedIn", placeholder: "http://www.linkedin.com/in/custom", },
  { id: "facebook", placeholder: "http://www.facebook.com/in/custom", },
  { id: "microsoft", placeholder: "http://www.microsoft.com/in/custom", },
  { id: "twitter", placeholder: "http://www.twitter.com/in/custom", },
  { id: "youTube", placeholder: "http://www.youtube.com/in/custom", },
  { id: "tiktok", placeholder: "http://www.tiktok.com/in/custom", },
  { id: "metaVerse", placeholder: "http://www.monster.com/in/custom", },
];

const ProfileSocialNetworks = (props) => {
  const handleSocialMediaClick = (socialMediaId) => {
    const url = props?.social_networks[socialMediaId];
    if (props?.edit) {
    } else {
      if (url) {
        window.open(url, '_blank');
      }
    }
  };

  return (
    <div>
      <h5 className='mt-3'>Social networks</h5>
      <div className='d-flex justify-content-around'>
        {!props?.social_networks ? [] : (Object.keys(props?.social_networks))?.map((item, index) => {
          const { id } = item;
          const url = props?.social_networks[item];
          const formattedUrl = url?.startsWith('http') ? url : `https://${url}`
          return (
            <div type="button" key={index} data-bs-toggle="modal" data-bs-target={`#${item?.id}`} className="d-flex py-3 justify-content-between" onClick={() => handleSocialMediaClick(item?.id)}> <img className={url ? 'cursor' : ''} onClick={() => window.open(formattedUrl, '_blank')} src={require(`assets/socialMedia/${item}_${!url ? 'none' : 'fill'}.svg`)} alt="" loading='lazy'/> </div>
          );
        })}
      </div>
      <hr />
      {props?.edit && SocialMediaData.map((item, index) => <SocialMedia {...item} socialMediaDataURI={!props ? [] : props} setSocialMediaDataURI={props?.setProfileData} />)}
    </div>
  )
}

export default ProfileSocialNetworks