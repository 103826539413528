import React, { useState } from 'react'
import PopupModal from "components/PopupModal/index"
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const NewDroidType = () => {
    const navigate = useNavigate();
    const { droidPopupSelect } = useSelector((state) => state?.defaultState)
    const [selectedDiv, setSelectedDiv] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/management/droids/${selectedDiv?.nav}`, { state: { droidType: selectedDiv } });
    };

    return (
        <PopupModal id={"NDTPopupId"}
            children={
                <>
                    <form onSubmit={handleSubmit}>
                        <h3 className='mx-4 my-3'>New Droid Type</h3>
                        <div className='mx-4'>
                            <div className='row'>
                                {droidPopupSelect?.map((e, index) => {
                                    return (
                                        <div className='col-lg-4 curser-pointer my-2' key={index} onClick={() => setSelectedDiv(e)}>
                                            <div className={`${selectedDiv?.nav === e?.nav ? 'selected-active' : ''} border rounded p-3 h-100`} >
                                                <div className='d-flex align-items-center gap-2 mb-3' >
                                                    <img src={require(`assets/DroidIcon/${e?.nav}Icon.svg`)} alt="" className={selectedDiv?.nav === e?.nav ? 'icon-Container-active' : 'icon-Container'} loading='lazy'/>
                                                    <h5 className='text-break'>{e?.name}</h5>
                                                </div>
                                                <p className='NDT-Paragraph'>{e?.subHeading}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='p-4 justify-content-end d-flex gap-3'>
                            <button className=' btn btn-outline-primary' data-bs-dismiss="modal" type="reset">Cancel</button>
                            {selectedDiv?.nav && <button className='primaryButton' data-bs-dismiss="modal" type='submit'>Add Droid</button>}
                        </div>
                    </form>
                </>
            }
        />

    )
}

export default NewDroidType