import React, { useEffect, useState, memo } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import Support from "../Support";
import { useDispatch, useSelector } from "react-redux";
import { updatePortfolio } from "features/CoinList/CoinListAction";
import CircularProgressBar from "./CircularProgressBar";
import ClientDisplayPhoto from "assets/commonAvtar.svg";
import {
  dashboardGraph,
  updateTrendScoreDashboard,
} from "features/Dashboard/dashboardAction";
import { getPorfolioByUserId } from "features/Management/managementAction";
import { toast } from "react-toastify";
import { getAllDroids } from "features/Droids/droidsAction";
import SidebarTooltip from "./sidebarTooltip";

const toastConfiguration = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
};

function NavigationBar() {
  const { userToken } = useSelector((state) => state.auth);
  let user_type =
    localStorage.getItem("role") === "broker" ? "retailer" : "broker";
  const location = useLocation();
  const [select, setSelect] = useState("dashboard");
  const dispatch = useDispatch();
  const [community, setCommunity] = useState(`${user_type}s`);
  const [showManagement, setShowManagement] = useState(false);
  const [showCommunity, setShowCommunity] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);

  const { image } = useSelector(
    (state) => state?.profile?.userProfileInfo || ""
  );
  const [getInternet, setInternet] = useState(false);

  const communityData = [`${user_type}s`, "discord", "blog", "chat"];
  const managementData = [
    { text: "task", img: "" },
    { text: "portfolios", img: "" },
    { text: "clients", img: "" },
    { text: "droids", img: "" },
    { text: "exchange", img: "" },
  ];

  const getDrodListOnLoading = () => {
    dispatch(getAllDroids({ userToken })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
      }
    });
  };
  useEffect(() => {
    // getDrodListOnLoading()
    const myArray = location?.pathname?.split("/");
    if (myArray.length >= 2) {
      setSelect(myArray[1]);
      if (myArray.length === 3) {
        setCommunity(myArray[2]);
        if (myArray[2]?.toLowerCase() === "management") {
          setShowManagement(true);
          setShowCommunity(false);
        } else if (myArray[1]?.toLowerCase() === "community") {
          setShowCommunity(true);
          setShowManagement(false);
        }
      } else if (myArray.length === 2) {
        if (myArray[1] === "community") {
          setShowCommunity(true);
          setShowManagement(false);
        } else if (myArray[1] === "management") {
          setShowCommunity(false);
          setShowManagement(true);
        }
      }
    }
  }, [location, community]);

  const menuSelect = (item) => {
    setSelect(item);
    if (item === "management") {
      setShowManagement(true);
      setShowCommunity(false);
    } else if (item === "community") {
      setShowCommunity(true);
      setShowManagement(false);
    }
  };

  useState(async () => {
    const [result1, result2, result3, result4] = await Promise.all([
      // dispatch(updatePortfolio({ userToken })).then((res) => { }),
      // dispatch(dashboardGraph({ userToken })).then((res) => { }),
      // dispatch(getPorfolioByUserId({ userToken })).then((res) => { }),
      // dispatch(updateTrendScoreDashboard({ userToken })).then((res) => { }),
    ]);
  }, []);
  const handleNavigate = (item, e) => {
    e.preventDefault();
    setCommunity(item);
    window.open("https://discord.com", "_blank");
  };
  const internetSlow = (id, speed) => {
    if (id === "Down" && speed < 1 / 8) {
      toast.error("Please check your internet connection and try again", {
        toastId: 3,
        ...toastConfiguration,
      });
    } else if (id === "Test" && speed < 10 / 8) {
      toast.error("Please check your internet connection and try again", {
        toastId: 3,
        ...toastConfiguration,
      });
    }
  };

  const handleContainerHover = (event) => {
    const isSidebarOrChild = event.target.closest(".menu");
    setIsOpen(!!isSidebarOrChild);
  };

  return (
    <>
      <div
        className="menu home-text sidebar z-index-999"
        style={{ position: "sticky", top: "0" }}
        onMouseOver={handleContainerHover}
        onMouseOut={handleContainerHover}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      >
        <div className="container h-100">
          <Link to="/dashboard" style={{ marginLeft: "1.25rem" }}>
            <img
              src={require("assets/logo.png")}
              className="w-65px"
              alt=""
              loading="lazy"
            />
          </Link>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "87%" }}
          >
            <ul
              className="d-flex flex-column align-items-start mt-2"
              style={{ overflowY: "auto", height: "80%" }}
            >
              <li style={{ width: "200px" }}>
                <Link
                  to="/dashboard"
                  className="home"
                  onClick={() => menuSelect("dashboard")}
                >
                  <SidebarTooltip
                    popupTitle="Dashboard"
                    popupData="I am shown when someone hovers over the div above."
                    selectState={select}
                    menuName="dashboard"
                    iconName="DashboardIcon"
                    onMouseOver={() => setIsTooltipHovered(true)}
                    onMouseOut={() => setIsTooltipHovered(false)}
                  />
                </Link>
              </li>
              <li style={{ width: "200px" }}>
                <Link to="management">
                  <div
                    className="home"
                    onClick={() => menuSelect("management")}
                  >
                    <SidebarTooltip
                      popupTitle="Management"
                      popupData={`${
                        localStorage.getItem("role") === "retailer"
                          ? "Tasks, Portfolios, Droids & Exchanges. View your portfolios, manage accounts to 8081."
                          : "Tasks, Clients & Droids. View your clients, manage their accounts and invite your existing clients to 8081."
                      }`}
                      selectState={select}
                      menuName="management"
                    />
                  </div>
                  {showManagement &&
                    (select === "management" || select === "Exchange") && (
                      <>
                        <div className="top-border-sub-menu" />
                        <div className="sub-menu-contener">
                          {managementData
                            ?.filter(
                              (item) =>
                                item?.text !==
                                  (localStorage.getItem("role") === "broker"
                                    ? "portfolios"
                                    : "clients") &&
                                item?.text !==
                                  (localStorage.getItem("role") === "broker"
                                    ? "exchange"
                                    : "")
                            )
                            ?.map((item, index) => {
                              return (
                                <Link
                                  to={
                                    item?.text === "exchange"
                                      ? "/Exchange"
                                      : `management/${item?.text}`
                                  }
                                  className="home"
                                  onClick={() => setCommunity(item?.text)}
                                  key={index}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require(`assets/${
                                        community === item?.text
                                          ? `${item?.text}-fill.svg`
                                          : `${item?.text}-none.svg`
                                      }`)}
                                      className="sub-img sidebar-img"
                                      alt=""
                                      loading="lazy"
                                    />
                                    <div className="sidebar-text">
                                      {" "}
                                      <p
                                        className={
                                          community === item?.text
                                            ? "text-p sidebar_animblue"
                                            : "text-p"
                                        }
                                      >
                                        {item?.text?.charAt(0)?.toUpperCase() +
                                          item?.text?.slice(1)}
                                      </p>{" "}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </div>
                      </>
                    )}
                </Link>
              </li>
              <li style={{ width: "200px" }}>
                <Link to="community">
                  <div className="home" onClick={() => menuSelect("community")}>
                    <SidebarTooltip
                      popupTitle="Community"
                      popupData="See all of 8081's brokers and decide if you want to connect to one of them or continue by yourself."
                      popupData2="If you decide to connect, select the one that fits your needs."
                      selectState={select}
                      menuName="community"
                    />
                  </div>
                  {showCommunity &&
                    (select === "community" || select === "userchat") && (
                      <>
                        <div className="top-border-sub-menu" />
                        <div className="sub-menu-contener">
                          {communityData?.map((item, index) => (
                            <Link
                              to={
                                item === "chat"
                                  ? "/userchat"
                                  : `community/${item}`
                              }
                              className="home"
                              key={index}
                              onClick={(e) =>
                                item === "discord"
                                  ? handleNavigate(item, e)
                                  : setCommunity(item)
                              }
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={require(`assets/${
                                    community === item
                                      ? `${item}-fill.svg`
                                      : `${item}-none.svg`
                                  }`)}
                                  className="img sidebar-img"
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="sidebar-text">
                                  {" "}
                                  <p
                                    className={
                                      community === item
                                        ? "text-p pt-0 sidebar_animblue"
                                        : "text-p pt-0"
                                    }
                                  >
                                    {item.charAt(0).toUpperCase() +
                                      item.slice(1)}
                                  </p>{" "}
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </>
                    )}
                </Link>
              </li>
              <li style={{ width: "200px" }}>
                <Link
                  to="report"
                  className="home"
                  onClick={() => menuSelect("report")}
                >
                  <SidebarTooltip
                    popupTitle="Report"
                    popupData="I am shown when someone hovers over the div above."
                    selectState={select}
                    menuName="report"
                  />
                </Link>
              </li>
            </ul>
            <ul className="d-flex flex-column align-items-start">
              <li style={{ width: "200px" }}>
                <Link
                  to="support"
                  className="home "
                  data-bs-toggle="modal"
                  data-bs-target="#needSupport"
                  // onClick={() => menuSelect("support")}
                >
                  <SidebarTooltip
                    popupTitle="Support"
                    popupData="I am shown when someone hovers over the div above."
                    selectState={select}
                    menuName="support"
                  />
                </Link>
              </li>
              <li style={{ width: "200px" }}>
                <Link
                  to="profile"
                  className="home"
                  onClick={() => menuSelect("profile")}
                >
                  <SidebarTooltip
                    popupTitle="Profile"
                    popupData="Set your profile to boost your client base."
                    selectState={select}
                    menuName="profile"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isOpen && <div className="blurbackground"> </div>}
      <Support />
    </>
  );
}

export default memo(NavigationBar);
