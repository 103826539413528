import React, { useState, useEffect } from 'react'
import AddOnCard from '../AddOnCard'
import DoughnutChart from '../Charts/DoughnutChart'
import { useDispatch, useSelector } from 'react-redux';
import './index.scss'
import { addOns } from '../../features/Dashboard/dashboardAction'


function FearIndex({ pinnedTabs, dataId, onClickPin, onClickExpand, isVisible }) {
  const {addOnsData}= useSelector((state)=> state.dashboard)
  const [addonsdata, setaddonsdata] = useState({
    nowData: true,
    previousData: false,
    monthAgo: false,
    yearAgo: false,
    ...addOnsData
  })
  const [Loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const { userToken } = useSelector((state) => state.auth);
  const fetchData = async (id) => {
    setShow(false);
    const res = await dispatch(addOns({ userToken }));
    if (res.meta && res.meta.requestStatus === "fulfilled") {
      const updatedData = {
        ...res.payload,
        nowData: true
      };
      console.log(updatedData,'my addons', addOnsData);
      setaddonsdata(updatedData);
      setLoading(true);
      setShow(true);
    }
  };

  useEffect(()=>{ 
    const updatedData = {
      ...addOnsData,
      nowData: true
    };
    setaddonsdata(updatedData);
    setLoading(true);
    setShow(true);
  },[addOnsData])

  

  const handleClick = (nowData, previousData, monthAgo, yearAgo) => {
    setaddonsdata((prevData) => ({
      ...prevData,
      nowData,
      previousData,
      monthAgo,
      yearAgo
    }));
  };

  const timestamp =
    addonsdata.nowData
      ? addonsdata[0]?.yesterday?.timestamp
      : addonsdata.previousData
        ? addonsdata[1]?.week?.timestamp
        : addonsdata.monthAgo
          ? addonsdata[2]?.months?.timestamp
          : addonsdata.yearAgo
            ? addonsdata[3]?.year?.timestamp
            : '';

  const colorChose = () => {
    let count = 0
    if (addonsdata?.nowData && "fearSelected") {
      count = addonsdata[0]?.yesterday?.value
    } else if (addonsdata?.previousData && "fearSelected") {
      count = addonsdata[1]?.week?.value
    } else if (addonsdata?.monthAgo && "fearSelected") {
      count = addonsdata[2]?.months?.value
    } else if (addonsdata?.yearAgo && "fearSelected") {
      count = addonsdata[3]?.year?.value
    }
    return count <= 25 ? "greed" : count <= 50 ? 'fear' : count <= 75 ? 'extreme-fear' : 'max-fear'
  }
  const colorindicate = colorChose()

  return (
    <div className='circle-graph'>
      <AddOnCard pinnedTabs={pinnedTabs} dataId={dataId} onClickPin={onClickPin} onClickExpand={onClickExpand} onClickUpdate={() => fetchData('true')} isVisible={isVisible}>
        {Loading === true ? <div className='fearIndex'>
          <div className="d-flex flex-column justify-content-between">
            <h6 className="heading">Fear Index</h6>
            {addonsdata && <div>
              {show ? <span className='d-block date-times'>Last market Update:</span> : <span className='d-block date-times'>Loading...</span>}
              <img src={require("../../assets/update-16.svg").default} alt="update" className="me-2 blackToWhite text-secondary" onClick={fetchData} loading='lazy'/>
              <span className="date-time">{timestamp}</span>
            </div>
            }
          </div>
          <DoughnutChart addonsdata={addonsdata} />
          <div className="">
            <div className={`d-flex flex-column ${addonsdata?.nowData && "fearSelected"} cursor`} onClick={() => handleClick(true, false, false, false)}>
              <div className={`right-heading ${addonsdata?.nowData ? "white-color" : ""}`} >Yesterday</div>
              <small className={`${addonsdata?.nowData && "fearSelected" ? colorindicate : 'text-white'} color`}>{addonsdata[0]?.yesterday?.value_classification}</small>
            </div>
            <div className={`d-flex flex-column ${addonsdata?.previousData && "fearSelected"} cursor`} onClick={() => handleClick(false, true, false, false)}>
              <div className={`right-heading ${addonsdata?.previousData ? "white-color" : ""}`}>Last Week</div>
              <span className={`${addonsdata?.previousData && "fearSelected" ? colorindicate : 'text-white'} color`}>{addonsdata[1]?.week?.value_classification}</span>
            </div>
            <div className={`d-flex flex-column ${addonsdata?.monthAgo && "fearSelected"} cursor`} onClick={() => handleClick(false, false, true, false)}>
              <div className={`right-heading ${addonsdata?.monthAgo ? "white-color" : ""}`}>Last Month</div>
              <small className={`${addonsdata?.monthAgo && "fearSelected" ? colorindicate : 'text-white'} color`}>{addonsdata[2]?.months?.value_classification}</small>
            </div>
            <div className={`d-flex flex-column ${addonsdata?.yearAgo && "fearSelected"} cursor`} onClick={() => handleClick(false, false, false, true)}>
              <div className={`right-heading ${addonsdata?.yearAgo ? "white-color" : ""}`}>Last Year</div>
              <small className={`${addonsdata?.yearAgo && "fearSelected" ? colorindicate : 'text-white'} color`}>{addonsdata[3]?.year?.value_classification}</small>
            </div>
          </div>
        </div> : "Loading..."
        }
      </AddOnCard>
    </div>
  )
}

export default FearIndex
