import React from "react";

function SupportIcon({ fill, color, height = 24, width = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6 9.89637C15.6 10.2277 15.3314 10.4964 15 10.4964C14.6687 10.4964 14.4 10.2277 14.4 9.89637V4.5C14.4 4.16865 14.6687 3.9 15 3.9C15.3314 3.9 15.6 4.16865 15.6 4.5V9.89637ZM15.3906 13.6519C15.2979 13.7313 13.0752 15.5964 9.00003 15.5964C4.92484 15.5964 2.70222 13.7313 2.60953 13.6519C2.35798 13.4363 2.32886 13.0574 2.54449 12.8058C2.75964 12.5547 3.13739 12.525 3.38912 12.7396C3.42438 12.7691 5.40912 14.3963 9.00003 14.3964C12.636 14.3964 14.5902 12.7573 14.6095 12.7408C14.8606 12.5252 15.24 12.5542 15.4556 12.8058C15.6713 13.0574 15.6422 13.4363 15.3906 13.6519ZM2.39999 4.5C2.39999 4.16865 2.66864 3.9 2.99999 3.9C3.3314 3.9 3.59999 4.16865 3.59999 4.5V9.89637C3.59999 10.2277 3.3314 10.4963 2.99999 10.4963C2.66864 10.4963 2.39999 10.2277 2.39999 9.89631V4.5ZM5.40004 3.3C5.40004 2.96865 5.66869 2.7 6.00004 2.7C6.33139 2.7 6.60004 2.96865 6.60004 3.3V11.3157C6.60004 11.647 6.33139 11.9157 6.00004 11.9157C5.66869 11.9157 5.40004 11.647 5.40004 11.3157V3.3ZM8.40003 2.9964C8.40003 2.6649 8.66868 2.3964 9.00003 2.3964C9.33138 2.3964 9.60003 2.6649 9.60003 2.9964V11.6964C9.60003 12.0277 9.33138 12.2964 9.00003 12.2964C8.66868 12.2964 8.40003 12.0277 8.40003 11.6964V2.9964ZM11.4 3.3C11.4 2.96865 11.6687 2.7 12 2.7C12.3314 2.7 12.6 2.96865 12.6 3.3V11.3157C12.6 11.647 12.3314 11.9157 12 11.9157C11.6687 11.9157 11.4 11.647 11.4 11.3157V3.3ZM15.75 0H2.24999C1.0074 0 0 1.0074 0 2.25L5.85936e-05 15.75C5.85936e-05 16.9926 1.0074 18 2.24999 18H15.75C16.9926 18 18 16.9926 18 15.75V2.25C18 1.0074 16.9926 0 15.75 0Z"
        fill={color ? color : "#0078E7"}
      />
    </svg>
  );
}

export default SupportIcon;
