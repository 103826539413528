import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ErrorCode from "ErrorCode";

const cache = new Map();

export const getAllDroids = createAsyncThunk(
  "droids/getAllDroids",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      let cachedData = cache.get("allDroidData");
      if (
        localStorage.getItem("droidDeleted") ||
        localStorage.getItem("droidApproved") ||
        localStorage.getItem("hardRefresh")
      ) {
        cachedData = "";
      }
      if (cachedData && Date.now() - cachedData.timestamp < 0.5 * 60 * 1000) {
        return cachedData.data;
      }
      localStorage.setItem("droidDeleted", false);
      localStorage.setItem("droidApproved", false);
      localStorage.setItem("hardRefresh", false);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getAllDroids`,
        config
      );
      if (data.hasOwnProperty("error")) {
        ErrorCode(data);
        return rejectWithValue(data.message);
      } else {
        cache.set("allDroidData", {
          data: data.data.droids,
          timestamp: Date.now(),
        });
        return data.data.droids;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postAddDroid = createAsyncThunk(
  "droids/postAllDroids",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/addDroid`,
        payload,
        config
      );

      if (data.hasOwnProperty("error")) {
        ErrorCode(data);
        return rejectWithValue(data);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllDroidsById = createAsyncThunk(
  "droids/getAllDroidsById",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getDroidDetails/${id}`,
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.droid;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatedroidsById = createAsyncThunk(
  "droid/update_droid",
  async ({ userToken, id, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/updateDroid/${id}`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data);
      } else {
        return data.data.droid;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDroidsList = createAsyncThunk(
  "droid/getDroidsList",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/getDroidsList`,
        { filter: "all" },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data?.droids;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteDroid = createAsyncThunk(
  "droid/deleteDroid",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/changeDroidStatus`,
        { identifier: "delete", droidId: id },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data?.droids;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const filterClientDroid = createAsyncThunk(
  "droid/filterClientDroid",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/filterDroid`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkOrderStatusDroid = createAsyncThunk(
  "droid/checkOrderStatusDroid",
  async ({ userToken, ordersList }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/checkOrderStatus`,
        { ordersList: ordersList },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
