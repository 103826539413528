import { createSlice } from '@reduxjs/toolkit'
import {
  userSignup,
  userLogin,
  userAuthenticate,
  sendOTP,
  forgotPassword,
  resetPassword,
  changePassword,
  socialSignup,
  userExistStatus,
  verifyNonAuthPhoneEmail,
  socialDataStore
} from './authActions'

const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false,
  isSignUpSuccessful: false,
  isOTPSentSuccessful: false,
  isOTPSentFailed: false,
  OTPSentFailedMessage: null,
  isForgotPasswordEmailSent: false,
  isResetPasswordSuccessful: false,
  isLoginSuccessful: false,
  isOTPVerifiedSuccessfully: false,
  loginErrorMessage: null,
  signupErrorMessage: null,
  authenticateErrorMessage: null,
  forgotPasswordErrorMessage: null,
  resetPasswordErrorMessage: null,
  isLoggedoutSuccessfull: false,
  isChangePasswordSuccessfull: false,
  changePasswordErrorMessage: null,
  isemailInviteMessageInfo: false,
  isuserExistStatus: false,
  isuserExistStatusMessage: null,
  isverifyNonAuthPhoneEmail: false,
  socialLogin: null,
  verifyNonAuthPhoneEmailErrorMessage: null,
}

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken')
      localStorage.removeItem('role')
      localStorage.removeItem('routeHistory')
      localStorage.removeItem('brokerName')
      localStorage.removeItem('breadcrum')
      localStorage.removeItem('userID')
      localStorage.removeItem('dashboardDroid')
      state.loading = false
      state.userInfo = null
      state.userToken = null
      state.error = null
      state.socialLogin = null
      state.isLoggedoutSuccessfull = true
    },
    logoutRejectUser: (state) => {
      localStorage.removeItem('userToken')
      localStorage.removeItem('role')
      localStorage.removeItem('routeHistory')
      localStorage.removeItem('brokerName')
      localStorage.removeItem('breadcrum')
      localStorage.removeItem('userID')
      state.loading = false
      state.userInfo = null
      state.userToken = null
      state.error = null
      state.socialLogin = null
      state.isLoggedoutSuccessfull = false
    },
    clearChangePasswordMessages: (state) => {
      state.isChangePasswordSuccessfull = false
      state.changePasswordErrorMessage = null
    },
    clearUploadLicense: (state) => {
      state.isuploadLicense = false
    },
    clearLogoutSuccess: (state) => {
      state.isLoggedoutSuccessfull = false
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload
    },
    clearCurrentLogin: (state) => {
      localStorage.removeItem('userToken')
      state.userInfo = null
      state.userToken = null
    },
    clearSignUpSuccessMessage: (state) => {
      state.isSignUpSuccessful = false
    },
    clearIsForgotPasswordEmailSent: (state) => {
      state.isForgotPasswordEmailSent = false
    },
    clearLoginMessage: (state) => {
      state.isLoginSuccessful = false
    },
    clearOTPVerifiedSuccessfully: (state) => {
      state.isOTPVerifiedSuccessfully = false
    },
    clearOTPSentSuccessfullyMessage: (state) => {
      state.isOTPSentSuccessful = false
    },
    clearOTPSentFailedMessage: (state) => {
      state.isOTPSentFailed = false
      state.OTPSentFailedMessage = null
    },
    clearLoginErrorMessage: (state) => {
      state.loginErrorMessage = null
    },
    clearSignupErrorMessage: (state) => {
      state.signupErrorMessage = null
    },
    clearAuthenticateErrorMessage: (state) => {
      state.authenticateErrorMessage = null
    },
    clearForgotPasswordErrorMessage: (state) => {
      state.forgotPasswordErrorMessage = null
    },
    clearResetPasswordErrorMessage: (state) => {
      state.resetPasswordErrorMessage = null
      state.isResetPasswordSuccessful = false
    },
    clearUserExistStatuse: (state) => {
      state.isuserExistStatus = false
    },
    clearverifyNonAuthPhoneEmail: (state) => {
      state.isverifyNonAuthPhoneEmail = false
      state.isverifyNonAuthPhoneEmailError = false
    },

  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.loginMessage = payload
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.loginErrorMessage = payload
    },
    [userSignup.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userSignup.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.isSignUpSuccessful = true
    },
    [userSignup.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.signupErrorMessage = payload
    },
    [userAuthenticate.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userAuthenticate.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.userInfo = payload
      state.userToken = payload.token
      state.isOTPVerifiedSuccessfully = true
      state.isLoginSuccessful = true
    },
    [userAuthenticate.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.authenticateErrorMessage = payload
    },


    [socialSignup.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [socialSignup.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.userInfo = payload
      state.userToken = payload.token
      state.isOTPVerifiedSuccessfully = true
      state.isLoginSuccessful = true
    },
    [socialSignup.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.authenticateErrorMessage = payload
    },

    [sendOTP.fulfilled]: (state, { payload }) => {
      state.isOTPSentSuccessful = true
      state.userInfo = payload
    },
    [sendOTP.rejected]: (state, { payload }) => {
      state.isOTPSentFailed = true
      state.OTPSentFailedMessage = payload
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isForgotPasswordEmailSent = true
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.forgotPasswordErrorMessage = payload
    },
    [resetPassword.fulfilled]: (state) => {
      state.isResetPasswordSuccessful = true
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.resetPasswordErrorMessage = payload
    },
    [changePassword.fulfilled]: (state) => {
      localStorage.removeItem('userToken')
      state.userInfo = null
      state.userToken = null
      state.isChangePasswordSuccessfull = true
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.changePasswordErrorMessage = payload
    },
    [userExistStatus.fulfilled]: (state, { payload }) => {
      state.isuserExistStatus = false
    },
    [userExistStatus.rejected]: (state, { payload }) => {
      state.isuserExistStatus = true
      state.isuserExistStatusMessage = payload
    },
    [verifyNonAuthPhoneEmail.fulfilled]: (state, { payload }) => {
      state.isverifyNonAuthPhoneEmail = true
      state.isverifyNonAuthPhoneEmailError = false
      state.verifyNonAuthPhoneEmailErrorMessage = payload
    },
    [verifyNonAuthPhoneEmail.rejected]: (state, { payload }) => {
      state.isverifyNonAuthPhoneEmail = false
      state.isverifyNonAuthPhoneEmailError = true
      state.verifyNonAuthPhoneEmailErrorMessage = payload
    },
    [socialDataStore.fulfilled]: (state, { payload }) => {
      state.socialLogin = payload
    },
    [socialDataStore.rejected]: (state, { payload }) => {
    },
  },
})

export const {
  logout,
  setCredentials,
  clearCurrentLogin,
  clearSignUpSuccessMessage,
  clearIsForgotPasswordEmailSent,
  clearLoginMessage,
  clearOTPVerifiedSuccessfully,
  clearOTPSentSuccessfullyMessage,
  clearOTPSentFailedMessage,
  clearLoginErrorMessage,
  clearSignupErrorMessage,
  clearAuthenticateErrorMessage,
  clearForgotPasswordErrorMessage,
  clearResetPasswordErrorMessage,
  clearLogoutSuccess,
  clearChangePasswordMessages,
  clearUploadLicense,
  clearUserExistStatuse,
  clearverifyNonAuthPhoneEmail,
  logoutRejectUser
} = authSlice.actions

export default authSlice.reducer
