import React from 'react'

const IndexV1Legends = ({getGraphData}) => {
  return (
    <div className='d-flex align-items-center justify-content-center gap-2 mt-3'>
      {getGraphData != undefined && getGraphData?.coinsList?.slice(0, 3)?.map((item) => (
        <div className='d-flex gap-1 justify-content-center align-items-center mx-1'>
          <div className='droidCoins' style={{ backgroundColor: item.color }}></div>
          <h6 className='indexV1labels'>{item.value}%</h6>
          <p className='paragraphLedends indexV1labels'>{item.name}</p>
        </div>
      ))}
      <div>{getGraphData?.coinsList?.length > 3 && `+${getGraphData?.coinsList?.length - 3}` }</div>
    </div>
  )
}

export default IndexV1Legends