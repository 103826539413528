import { createSlice } from '@reduxjs/toolkit'
import { getSetupAssistanceStatus } from './SetupAssistanceStatusAction'

const initialState = {
    setupAssistanceStatusInfo: null
}

const getSetupAssistanceStatusSlice = createSlice({
    name: 'getSetupAssistanceStatus',
    initialState,
    reducers: {
      setupAssistanceStatus: (state, { payload }) => {
            state.setupAssistanceStatusInfo = payload
        },
    },
    extraReducers: {
        [getSetupAssistanceStatus.pending]: (state) => {
        },
        [getSetupAssistanceStatus.fulfilled]: (state, { payload }) => {
          state.setupAssistanceStatusInfo = payload
        },
        [getSetupAssistanceStatus.rejected]: (state, { payload }) => {
        },
      },
})

export const {
  logout,
} = getSetupAssistanceStatusSlice.actions

export default getSetupAssistanceStatusSlice.reducer
