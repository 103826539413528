import * as Yup from 'yup';

const validate =  /\d/
const ifEmpty = /^ *$/

const  signUpValidationSchemaBroker = (isRetailer) => Yup.object().shape({
    full_name: Yup.string()
       .max(64, "Full Name can not be more than 64 characters")
       .required('Full Name is required')
       .matches(/^[a-zA-Z ]*$/, 'Full Name should not contain special characters or numbers')
       .test('len', 'Full Name should not contain number', val => !validate.test(val))
       .test('len', 'Full Name should not contain white spaces', val => !ifEmpty.test(val)),
       company_name: Yup.string()
       .matches(/^[a-zA-Z0-9\s.,'"&()-]*$/, 'Company Name cannot contain special characters')
       .max(64, 'Company Name cannot be more than 64 characters'),     
    title: Yup.string()
      .max(64, "Title can not be more than 64 characters"),
    phoneNumber: Yup.string()
      .typeError("That doesn't look like a phone number")
      .matches(/^[+\d]+(?:[\d-.\s()]*)$/,
      'Phone number is not valid')   
      .min(10, "Phone number should be 10 digit")
      .max(10, "Phone number should be 10 digit")   
      .required('Phone Number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid Email Address')
      .max(128, 'Email must be at maximum 128 characters'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(128, 'Password must be at maximum 128 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain, One Uppercase, One Lowercase, One Number and One Special Case Character Ex: Jordan1@"
      ),
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
  });

  export default signUpValidationSchemaBroker;
