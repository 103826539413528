import ConverNumber from 'components/ConvertNumber';
import React from 'react'
import { useLocation } from 'react-router-dom';

const ProfileLimitComponent = (props) => {
  const location = useLocation();

  function calculateNearestNumber(number) {
    let nearest;
    if (number < 100) {
      nearest = 1000;
    } else if (number > 100 && number < 1000) {
      nearest = 10000;
    } else if (number > 1000 && number < 10000) {
      nearest = 100000;
    } else if (number > 10000 && number < 100000) {
      nearest = 1000000;
    } else {
      return nearest;
    }
    return nearest;
  }

  const clientLimit = !props?.userPreference ? {} : props?.userPreference[0]?.limits
  const data = location?.pathname === "/client-Dashboard-Details" ? props?.limitTrade?.limits : clientLimit
  const nearestNumber = calculateNearestNumber(data?.buy > data?.sell ? data?.buy : data?.sell);
  const buyPercentage = (data?.buy / nearestNumber) * 100;
  const sellPercentage = (data?.sell / nearestNumber) * 100;
  return (
    <div className='w-100'>
      <h5>Limit $</h5>
      <div className="helth_card mb-3 helth_card_hover_data risk_heading">
        <h6 className="mt-2">Buy</h6>
        <div className="progress b-0 mt-3 webkit-fill-available" role="progressbar" aria-label="Basic example" aria-valuenow={buyPercentage} aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar progress-bar-buy" style={{ width: `${buyPercentage}%` }} ></div>
        </div>
      </div>
      <div className="helth_card mb-3 helth_card_hover_data risk_heading">
        <h6 className="mt-2">Sell</h6>
        <div className="d-grid webkit-fill-available">
          <div className="d-grid webkit-fill-available">
            <div className="progress b-0 mt-3 webkit-fill-available" role="progressbar" aria-label="Basic example" aria-valuenow={sellPercentage} aria-valuemin="0" aria-valuemax="100">
              <div className="progress-bar progress-bar-shell" style={{ width: `${sellPercentage}%` }} ></div>
            </div>
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <p className="fs-10">0</p>
            <p className="fs-10"><ConverNumber data={calculateNearestNumber(data?.sell >= data?.buy ? data?.sell : data?.buy) * 0.25} /> </p>
            <p className="fs-10"><ConverNumber data={calculateNearestNumber(data?.sell >= data?.buy ? data?.sell : data?.buy) * 0.5} /> </p>
            <p className="fs-10"><ConverNumber data={calculateNearestNumber(data?.sell >= data?.buy ? data?.sell : data?.buy) * 0.75} /> </p>
            <p className="fs-10"> <ConverNumber data={calculateNearestNumber(data?.sell >= data?.buy ? data?.sell : data?.buy)} /> </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileLimitComponent