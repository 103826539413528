import React from "react";

function CardSideMenu({ src1, src2, src3,pinnedTabs,dataId,
  onClickPin = () => {}, onClickExpand = () => {}, onClickUpdate = () => {}
}) {
  return (
    <div className="d-flex flex-column justify-content-evenly h-100 cardSideMenu">
      <div onClick={onClickPin}>
      <img src={!pinnedTabs?.find((i)=>i === 'fearIndex') ? src1 : require('assets/FilledPinWhite.svg').default} alt="pin" className={`me-2 text-secondary ${pinnedTabs?.find((i)=>i === 'fearIndex') ? '' : 'addOnsPin'} `} loading='lazy'/> 
      </div>
      <div onClick={onClickExpand}>
      </div>
      <div onClick={onClickUpdate}>
      <img src={src3} alt="update" className="me-2 text-secondary" type="button" loading='lazy'/>
      </div>
    </div>
  );
}

export default CardSideMenu;
