import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorCode from 'ErrorCode'

export const userProfile = createAsyncThunk(
    'user/profile',
    async (userToken, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/getProfile`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userTypeTrader = createAsyncThunk(
    'user/type_trader',
    async (userToken, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/getTypeTrader`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data.types
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userUpdateProfile = createAsyncThunk(
    'user/update_profile',
    async ({ userToken, formData }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }

            const { data } = await axios.patch(
                `${process.env.REACT_APP_BASEURL}/api/updateProfile`,
                formData,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data.user
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updatePortfolioRisk = createAsyncThunk(
    'user/updatePortfolioRisk',
    async ({ userToken, sendPortfolioRisk }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.patch(
                `${process.env.REACT_APP_BASEURL}/api/updatePortfolioRisk`,
                { data: sendPortfolioRisk },
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updateRiskLimits = createAsyncThunk(
    '/updateRiskLimits',
    async ({ userToken, sendRiskLimits }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASEURL}/api/updateRiskLimits`,
                sendRiskLimits,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data.user
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)