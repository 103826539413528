import React, { useState } from 'react';
import Button from 'components/Button';
import PopupModal from 'components/PopupModal';

function SocialMedia({ id, placeholder, socialMediaDataURI, setSocialMediaDataURI }) {
  const [value, setValue] = useState(
    id === 'facebook' ? socialMediaDataURI?.social_networks?.facebook :
      id === 'linkedIn' ? socialMediaDataURI?.social_networks?.linkedIn :
        id === 'metaVerse' ? socialMediaDataURI?.social_networks?.metaVerse :
          id === 'microsoft' ? socialMediaDataURI?.social_networks?.microsoft :
            id === 'tiktok' ? socialMediaDataURI?.social_networks?.tiktok :
              id === 'twitter' ? socialMediaDataURI?.social_networks?.twitter :
                id === 'youTube' ? socialMediaDataURI?.social_networks?.youTube : ''
  );
  const [disabled, setDisable] = useState(true);
  const [inputData, setInputData] = useState('');

  const Submit = () => {
    setSocialMediaDataURI((prevObject) => ({
      ...prevObject,
      social_networks: { ...socialMediaDataURI?.social_networks, [id]: inputData }
    }));
  }

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/\s+/g, '');
    const position = newValue.search(`${id.toLowerCase()}.com`);
    const position1 = newValue.search('https://youtu.be');
    setValue(newValue);
    setInputData(newValue);

    if (position > -1 || position1 > -1) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }

  const handleClear = () => {
    setValue('');
    setInputData('');
    setDisable(true);
  }

  return (
    <PopupModal id={id} backdrop={false}>
      <div className="modal-header border-0 px-4 pt-4 pb-0">
        <img src={require(`assets/socialMedia/${id === 'linkedIn' ? 'linkedinModal' : id}_fill.svg`)} alt="" loading='lazy'/>
        <img src={require('assets/closeBlueIcon.svg').default} alt="close" title="close" type="button" className="cursurPointer" data-bs-dismiss="modal" aria-label="Close" loading='lazy'/>
      </div>
      <div className="modal-body">
        <label htmlFor="" className="mb-1">Add your Public Profile URL {id}</label>
        <input type="url" pattern="https://.*" required placeholder={placeholder} value={value} onChange={(e) => handleInputChange(e)} />
      </div>
      <div className="modal-footer border-0">
        <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={handleClear}> Cancel </button>
        <Button disabled={disabled} text='Submit' databsdismiss="modal" handleClick={Submit} />
      </div>
    </PopupModal>
  )
}

export default SocialMedia;
