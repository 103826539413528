import React, { useState, useRef, useEffect } from "react";
// import "./BetaTesterAgreementModal.css"; // Import CSS for styling
import logo from "assets/companylogo.svg";
// import styles from "./BetaTesterAgreementModal.module.css"
import styles from "./BetaModule.module.css";

const BetaTesterAgreementModal = ({
    isOpen,
    onClose,
    onSubmitHandler,
    formikValues,
    resetForm,
}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const contentRef = useRef(null);

    const handleCheckboxChange = (event) => setIsChecked(event.target.checked);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            // Add a small buffer
            setIsScrolledToBottom(true);
        } else {
            setIsScrolledToBottom(false);
        }
    };

    const handleSubmit = () => {
        if (isChecked && isScrolledToBottom) {
            onSubmitHandler(formikValues, resetForm);
            onClose();
        }
    };

    useEffect(() => {
        const contentElement = contentRef.current;
        if (contentElement) {
            contentElement.addEventListener("scroll", handleScroll);
            return () => contentElement.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <>
            {isOpen && (
                <div className={styles.modalBeta}>
                    <div className={styles.modalContentBeta}>
                        {/* <span className={styles.closeBeta} onClick={onClose}>
              &times;
            </span> */}
                        <div className="d-flex align-items-center m-2">
                            <span>
                                <img
                                    src={logo}
                                    alt="Company_Logo"
                                    className={styles.companylogoimg}
                                    loading="lazy"
                                />
                            </span>
                            <span>
                                <h1 class={styles.title}>Beta Test Terms</h1>
                            </span>
                        </div>
                        <div className={styles.scrollableContent} ref={contentRef}>
                            <p>
                                {"These BETA TEST TERMS"}{" "}
                                <span className={`fw-bold ${styles.fs16}`}>{"(“Terms”)"}</span>{" "}
                                {`form a binding agreement between you`}{" "}
                                <span className={`fw-bold ${styles.fs16}`}>
                                    {"(“Tester” or “you”)"}
                                </span>{" "}
                                {`and
                8081 Inc., a California corporation`}{" "}
                                <span className={`fw-bold ${styles.fs16}`}>
                                    {"(“Company”)"}
                                </span>
                                .{" "}
                                {`BY CLICKING “I AGREE” OR BY ACCESSING OR USING
                THE APP (AS DEFINED IN SECTION 1 BELOW), YOU (I) ACKNOWLEDGE THAT YOU HAVE READ
                AND UNDERSTAND THESE TERMS, (II) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER
                INTO A BINDING AGREEMENT, AND (III) ACCEPT THESE TERMS AND AGREE THAT YOU ARE
                LEGALLY BOUND BY ITS TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS,
                DO NOT ACCESS OR USE THE APP.`}
                            </p>

                            <div class="section">
                                <p className="fw-bold">
                                    1.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Background{" "}
                                    </span>
                                    <p>
                                        {`Company has developed software for digital asset trading (the `}
                                        <span className={`fw-bold ${styles.fs16}`}>{"“App”"}</span>{" "}
                                        {`). Company offers, or
may offer, access to and use of the App online at`}{" "}
                                        <a
                                            href="https://www.8081.io"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {"https://www.8081.io"}
                                        </a>{" "}
                                        {`, Google Play, and/or the Apple App Store.`}
                                    </p>
                                </p>
                            </div>

                            <div class="section">
                                <p className="fw-bold">
                                    2.
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        License Grant.
                                    </span>
                                </p>
                                <p>
                                    2.1 Subject to the terms and conditions hereof, Company grants
                                    to Tester, for the Beta Test Period identified in Section 3.1
                                    below, a limited, non-exclusive, non-transferable, revocable
                                    right to access and use the App (the “License”) solely for the
                                    purpose of Tester’s testing and evaluation of the App and to
                                    provide Feedback (as defined in Section 6.2 herein) to
                                    Company, and not for general commercial use (the{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        {"“Purpose”"}
                                    </span>
                                    ).
                                </p>
                                <p>
                                    2.2 Upon commencement of the Beta Test Period Company shall
                                    grant to Tester $0 in funds for use exclusively within the App
                                    (the{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        {"“App Funds”"}
                                    </span>
                                    ). The App Funds have no monetary value, and may not be
                                    withdrawn or exchanged for any form of currency. Tester may
                                    only use the App Funds in connection with the Purpose.
                                </p>
                                <p>
                                    2.3 Tester may be required to create an account (
                                    <span className={`fw-bold ${styles.fs16}`}>“Account”</span>)
                                    in order to access or use the App. Tester is solely
                                    responsible for maintaining the secrecy of any Account login
                                    credentials, and is solely responsible for any and all
                                    unauthorized use of Tester’s Account. Tester shall not share
                                    his or her Account, or any Account login credentials, with any
                                    third party.
                                </p>
                            </div>

                            <div class="section">
                                <p className="fw-bold">
                                    3.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Beta Test Period and Termination.
                                    </span>
                                </p>
                                <p>
                                    3.1{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        Beta Test Period
                                    </span>
                                    : This Beta Test Period starts as of the date you first access
                                    or use the App, and ends Dec 31 2024, unless the License is
                                    terminated earlier pursuant to Section 3.2.The License will
                                    terminate upon expiration of the Beta Test Period.
                                </p>
                                <p>
                                    3.2{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        Termination By Company
                                    </span>{" "}
                                    : Company may revoke the License and terminate Tester’s use or
                                    access to the App if, in Company’s sole and absolute, (i)
                                    Tester violates any of the terms or conditions herein, (ii)
                                    Tester in any way violates any applicable law, statute,
                                    ordinance, or regulation or which would involve any unlawful
                                    activity, or (iii) Tester violates any security or
                                    authentication.
                                </p>
                                <p>
                                    3.3{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        Effect Of Termination
                                    </span>{" "}
                                    : Upon the expiration of the Beta Test Period or upon
                                    termination of the License for any reason, Tester shall cease
                                    any and all use of the App. If Company terminates the License
                                    pursuant to Section 3.2 above, Tester forfeits any and all
                                    rights in and to the App Funds. Sections 3 through 9 shall
                                    survive termination of the License.
                                </p>
                            </div>

                            <div class="section">
                                <p className="fw-bold">
                                    4.
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        {" "}
                                        Confidentiality.
                                    </span>
                                </p>
                                <p>
                                    4.1{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        “Confidential Information”
                                    </span>{" "}
                                    shall mean any and all nonpublic, confidential, trade secret
                                    and/or other proprietary information (in whatever form or
                                    media, whether disclosed orally or in writing, and whether or
                                    not marked as confidential) of Company disclosed or made
                                    available to Tester by Company, or otherwise accessed by
                                    Tester pursuant to these Terms and/or Tester’s use or access
                                    of the App, including, without limitation: (a) trade secrets,
                                    formulas, drawings, CAD drawings, technical drawings, works of
                                    authorship, inventions, discoveries, knowhow, processes,
                                    methodologies, techniques, improvements, design details,
                                    sketches, specifications, software, source code, algorithms,
                                    schematics; (b) research, development, manufacturing
                                    procedures, quality control procedures, test results, data,
                                    formats, plans, models; (c) new services, new products,
                                    marketing plans, 4872-4005-2170.2 2 sales plans, strategies,
                                    forecasts, budgets, financial information, licensing and/or
                                    distribution arrangements, prices, costs, tester lists,
                                    supplier lists, sales figures, quotes, invoices, financial
                                    terms of any agreement or proposed agreement; (d) any
                                    information Tester is advised is confidential; (e) any
                                    information that, in light of the nature of such information
                                    and/or the circumstances of disclosure, Tester knows or
                                    reasonably should know is confidential; (f) any and all
                                    Feedback; (g) any documents or copies made in any form now
                                    known or hereafter devised based upon or relating to any
                                    information set forth above in (a) through (f).
                                </p>
                                <p>
                                    4.2 Confidential Information shall not include information
                                    which Tester can prove (a) was rightfully in Tester’s
                                    possession before receipt from Company during the Beta Test
                                    Period; (b) is or becomes a matter of public knowledge through
                                    no fault of Tester and without violation of any
                                    confidentiality obligation; (c) is rightfully received from a
                                    third party without a duty of confidentiality to Company; (d)
                                    is independently developed by Tester or a third party without
                                    use of or reference to any Confidential Information; or (e) is
                                    disclosed by Tester with Company’s prior express written
                                    approval.
                                </p>
                                <p>
                                    4.3. Tester shall exercise at least the same degree of care
                                    and protection with respect to the Confidential Information
                                    that it exercises with respect to its own confidential
                                    information, but in no event shall Tester exercise less than a
                                    reasonable standard of care. Tester shall not copy,
                                    distribute, sell, lease, disclose, sublicense or otherwise
                                    transfer or make available the App, or any portion thereof,
                                    including without limitation any Confidential Information to
                                    any third party without the express prior written consent of
                                    Company. Tester shall protect all disclosed Confidential
                                    Information by using best efforts to prevent the unauthorized
                                    use, dissemination, disclosure or publication of the
                                    Confidential Information. Tester to use Confidential
                                    Information solely for the Purpose, and/or only as expressly
                                    authorized in writing by Company.
                                </p>
                                <p>
                                    4.4. Tester shall immediately notify Company upon discovery of
                                    any threatened or actual unauthorized use or disclosure of
                                    Confidential Information, or any other breach of these Terms,
                                    and Tester shall cooperate in every reasonable way to assist
                                    in regaining possession of the Confidential Information and
                                    prevent its further unauthorized use or disclosure. Tester
                                    agrees to separate all Confidential Information of Tester from
                                    the confidential materials of others to prevent commingling.
                                    In the event that the Confidential Information is required to
                                    be disclosed pursuant to any court or government action or
                                    regulation, Tester shall: (i) unless prohibited by law,
                                    promptly notify Company of such requirement in order to allow
                                    Company to assert whatever exclusions, exemptions, or
                                    protective measures that may be available to it under such
                                    action or regulation, (ii) use diligent efforts to limit
                                    disclosure and to obtain confidential treatment or a
                                    protective order, and (iii) allow Company to participate, at
                                    its sole expense, in the disclosure proceeding.
                                </p>
                            </div>

                            <div class="section">
                                <p className="fw-bold">
                                    5.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Use Restrictions. Tester shall not:{" "}
                                    </span>
                                </p>
                                <p>
                                    5.1 sell, distribute, rent, sub-license, loan, merge, hack,
                                    harm or misuse the App or attempt to do any of the foregoing;
                                </p>
                                <p>
                                    5.2. copy, modify, translate, reproduce, alter, modify,
                                    transfer, exchange, translate, adapt, or otherwise create
                                    derivative works, modification, adaptations, or improvements,
                                    whether or not patentable or copyrightable, of the App or
                                    attempt to do any of the foregoing;
                                </p>
                                <p>
                                    5.3. reverse engineer, disassemble, decompile, decode, or
                                    otherwise attempt to derive or gain access to the source code
                                    of the App or any part thereof;
                                </p>
                                <p>
                                    5.4. remove, delete, alter, or obscure any trademarks or any
                                    copyright, trademark, patent, or other intellectual property
                                    or proprietary rights notices from the App;
                                </p>
                                <p>
                                    5.5. use any robot, spider, or other automatic device,
                                    process, or means to access the App for any purpose, including
                                    monitoring or copying any of the material on the App;
                                </p>
                                <p>
                                    5.6. use any manual process to monitor or copy any of the
                                    material or content on the App, or for any other purpose not
                                    expressly authorized in these Terms;
                                </p>
                                <p>
                                    5.7. frame, mirror, or otherwise incorporate the App or any
                                    portion of the App as part of any other mobile application,
                                    website, product, or service;
                                </p>
                                <p>
                                    5.8. use the App in any manner that could disable, overburden,
                                    damage, or impair the App or interfere with any other party’s
                                    use of the App;
                                </p>
                                <p>
                                    5.9. remove, disable, circumvent, or otherwise create or
                                    implement any workaround to any copy protection, rights
                                    management, or security features in or protecting the App;
                                </p>
                                <p>
                                    5.10. use the App to transmit or upload any material that
                                    contains viruses, worms or any other harmful or deleterious
                                    programs, junk mail, spam, chain letters, unsolicited offers
                                    or ads of any kind and for any purpose.
                                </p>
                            </div>

                            <div class="section">
                                <p className="fw-bold">
                                    6.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Intellectual Property Ownership; Feedback.
                                    </span>
                                </p>
                                <p>
                                    6.1{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>Company IP.</span>{" "}
                                    Tester expressly acknowledges that, as between the parties,
                                    Company owns all right, title, and interest, including all
                                    intellectual property rights, in and to the App (which
                                    includes intellectual property rights in and to other Company
                                    services that may accompany the App), including, without
                                    limitation, all the components of the App , such as visual
                                    interfaces, interactive features, graphics, design,
                                    compilations, computer code, products, and all other elements
                                    and components of the App, and all modifications or derivative
                                    works based upon or incorporating the foregoing (together, the
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        {" "}
                                        “Company IP”
                                    </span>
                                    ). Tester further acknowledges that, as between the parties,
                                    Company owns all trademarks, service marks, and trade names
                                    (the{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        “Company Marks”
                                    </span>
                                    ) throughout the world associated with the App and any
                                    subsequent name of the App the Company may use, and all good
                                    will therein shall inure to Company’s benefit. As such, Tester
                                    shall not use or exploit any of the Company Marks in whole or
                                    in part except as expressly authorized by Company. Tester does
                                    not acquire any ownership interest in the App or the Company
                                    IP or any other rights thereto, other than to access and use
                                    the App in accordance with these Terms. Except as expressly
                                    provided herein, Company does not grant to Tester any express
                                    or implied rights, and all rights in and to the App are
                                    expressly retained by Company.
                                </p>
                                <p>
                                    6.2.{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>Feedback.</span>{" "}
                                    Tester may provide feedback to Company concerning the features
                                    and functionality of the App and the Services (the{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>“Feedback”</span>).
                                    If Tester provides Feedback to Company, all such Feedback
                                    shall be the sole and exclusive property of Company. Tester
                                    hereby irrevocably releases, quitclaims, transfers and assigns
                                    to Company, and agrees to irrevocably assign and transfer to
                                    Company, all of Tester’s right, title, and interest in and to
                                    all Feedback including all intellectual property rights
                                    therein. Tester shall not earn or acquire any rights or
                                    licenses in the App due Tester’s performance under these
                                    Terms, even if Company incorporates any Feedback into the App
                                    , except as expressly provided for in these Terms.
                                </p>
                            </div>

                            <div class="section">
                                <p>
                                    7.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Warranty.
                                    </span>{" "}
                                    THE APP PROVIDED “AS-IS.” COMPANY DISCLAIMS ALL WARRANTIES,
                                    EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY
                                    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                                    NONINFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF
                                    DEALING OR USAGE IN TRADE. TESTER EXPRESSLY AGREES THAT
                                    COMPANY CANNOT GUARANTEE THE SAFETY OR SECURITY OF TESTER’S
                                    COMPUTER SYSTEMS AND THAT TESTER’S USE OF THE APP IS ENTIRELY
                                    AT TESTER’S SOLE RISK.
                                </p>
                            </div>

                            <div class="section">
                                <p>
                                    8.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Disclaimer.
                                    </span>{" "}
                                    Tester understands and acknowledges that Tester’s use of the
                                    App is self-directed and that Tester is solely responsible for
                                    all of Tester’s activities on the App and/or associated with
                                    Tester’s Account. All such activities are unsolicited and
                                    based on Tester’s own decisions. Company is not a registered
                                    broker-dealer under the Securities Exchange Act of 1934, as
                                    amended, is not registered as an investment advisor under the
                                    Investment Advisers Act of 1940, as amended, is not registered
                                    with or regulated or authorized by any financial authority,
                                    and Company will not perform any activities requiring
                                    registration with or membership of the Financial Industry
                                    Regulatory Authority, the Securities and Exchange Commission,
                                    or any financial authority. Tester understands and
                                    acknowledges that Tester has not received, will not receive,
                                    and shall not expect to receive, any investment or financial
                                    advice from Company. Company shall not be liable to Tester in
                                    connection with any of Tester’s activities within the App. Any
                                    information available within the App is solely for
                                    informational and/or 4872-4005-2170.2 4 educational purposes
                                    only, and Tester expressly understands and acknowledges that
                                    such information shall not be construed as investment or
                                    financial advice. By using the App, Tester acknowledge and
                                    agree that: (i) Tester is aware that selling, holding, or
                                    investing in digital currencies, securities, commodities and
                                    other investment options is speculative and involves a high
                                    degree of risk of substantial loss; (ii) it is Tester’s sole
                                    responsibility to carefully assess its trading activities and
                                    decisions; and (iii) Tester assumes all risks related to the
                                    use of the App, and Company shall not be liable for any such
                                    risks and losses or other adverse outcomes.
                                </p>
                            </div>

                            <div class="section">
                                <p>
                                    9.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Limitation of Liability.
                                    </span>{" "}
                                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                                    SHALL COMPANY OR ANY OF ITS AFFILIATES, DIRECTORS, OFFICERS,
                                    DIRECTORS, SHAREHOLDERS, OR AGENTS BE LIABLE FOR (A) INDIRECT,
                                    SPECIAL, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES,
                                    INCLUDING DAMAGES FOR LOSS OF PROFITS, BUSINESS, REVENUE, DATA
                                    OR DATA USE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                                    DAMAGES, ARISING OUT OF THESE TERMS AND/OR TESTER’S USE OF THE
                                    APP; OR (B) DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS
                                    AND/OR TESTER’S USE OR ACCESS OF THE APP THAT, IN THE
                                    AGGREGATE THAT EXCEED $100.
                                </p>
                            </div>

                            <div class="section">
                                <p>
                                    10.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Acknowledgment of Beta Test.
                                    </span>{" "}
                                    Tester acknowledges and explicitly agrees that: (a) the App is
                                    not commercially offered to the public; (b) the App may not be
                                    in final form or fully functional; (c) the App may contain
                                    errors, design flaws or other problems; (d) it may not be
                                    possible to make the App fully functional; (e) the information
                                    obtained by using the App may not be accurate and may not
                                    accurately correspond to information extracted from any
                                    database or other source; (f) use of the App may result in
                                    unexpected results, loss of data or communications, project
                                    delays or other unpredictable damage or loss; and (g) Company
                                    has the unilateral right to abandon development of the App, at
                                    any time and without any obligation or liability to Tester.
                                    Tester acknowledges and agrees that it should not rely on the
                                    App for any reason. Tester is solely responsible for
                                    maintaining and protecting all data and information that is
                                    retrieved, extracted, transformed, loaded, stored or otherwise
                                    processed by the App. Tester will be responsible for all costs
                                    and expenses required to backup and restore any data and
                                    information that is lost or corrupted as a result of Tester’s
                                    use of the App.
                                </p>
                            </div>
                            <div class="section">
                                <p className={`fw-bold `}>
                                    11.{" "}
                                    <span className={`${styles.fs16} ${styles.underline}`}>
                                        Privacy and Information Security.
                                    </span>
                                </p>
                                <p>
                                    11.1.{" "}
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        Tester – Privacy and Security.
                                    </span>{" "}
                                    Tester shall comply with all applicable state and federal laws
                                    of the U.S. and other privacy, security, data protection, and
                                    marketing laws, regulations, and guidelines that apply to its
                                    use of the App.
                                </p>
                                <p>
                                    11.2.
                                    <span className={`fw-bold ${styles.fs16}`}>
                                        {" "}
                                        Company – Privacy and Security.
                                    </span>{" "}
                                    Company shall maintain commercially reasonable administrative,
                                    physical, and technical safeguards to help protect the
                                    security, confidentiality, and integrity of Tester’s data.
                                    Company’s collection and use of information in connection with
                                    the App is governed by the Company’s Privacy Policy (
                                    <a
                                        href="https://www.8081.io/privacy-policy"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        https://www.8081.io/privacy-policy
                                    </a>
                                    ), which is hereby incorporated into these Terms and to which
                                    the parties agree.
                                </p>
                            </div>
                            <div class="section">
                                <p>
                                    12.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        Waiver/Release.
                                    </span>{" "}
                                    Tester hereby releases Company and its directors, officers,
                                    employees, stockholders, partners, agents, attorneys,
                                    predecessors, successors, insurers, and assigns from any and
                                    all known and unknown claims arising from or relating to
                                    Tester’s use of the App.
                                </p>
                            </div>
                            <div class="section">
                                <p>
                                    13.{" "}
                                    <span
                                        className={`${styles.fs16} ${styles.underline} fw-bold`}
                                    >
                                        General.
                                    </span>{" "}
                                    Tester may not assign or otherwise transfer, by operation of
                                    law or otherwise, any of its rights under these Terms without
                                    Company’s prior written consent, and any attempted assignment
                                    without such consent will be void and of no effect. These
                                    Terms constitute the entire agreement between the parties and
                                    supersedes any and all prior agreements, communications and
                                    understandings with respect to the evaluation of the App , and
                                    shall be construed in accordance with the laws of the State of
                                    California (excluding its body of law controlling conflicts of
                                    law). Any legal action or proceeding arising under these Terms
                                    will be brought exclusively in the state courts located in
                                    Contra Costa County, California, or federal courts located in
                                    the Northern District of California, and the parties hereby
                                    irrevocably consent to the personal jurisdiction and venue
                                    therein. If any provision of these Terms is held invalid or
                                    unenforceable by a court of competent jurisdiction, such
                                    provision will be construed so 4872-4005-2170.2 5 as to be
                                    enforceable to the maximum extent permissible by law, and the
                                    remaining provisions of these Terms will remain in full force
                                    and effect. The waiver of any breach or default will not
                                    constitute a waiver of any other right hereunder or of any
                                    subsequent breach or default. All notices required or
                                    permitted under these Terms will be in writing and delivered
                                    in person, by confirmed facsimile transmission or by overnight
                                    delivery service and in each instance will be deemed given
                                    upon receipt. All communications will be sent to the 6101
                                    Bollinger Canyon Rd., Ste 371, San Ramon, CA 94583 if to
                                    Company, and to the address or email address Tester provides
                                    to Company within the App, if to Tester or to such other
                                    address as may be specified by either party to the other in
                                    accordance with this Section.
                                </p>
                            </div>
                        </div>

                        <div className={styles.agreementCheckbox}>
                            <input
                                type="checkbox"
                                id="agree"
                                checked={isChecked}
                                disabled={!isScrolledToBottom}
                                onChange={handleCheckboxChange}
                            />
                            <label
                                htmlFor="agree"
                                className={!isScrolledToBottom ? styles.disabledText : ""}
                            >
                                {" "}
                                By clicking or signing up, you confirm that you have read,
                                understood, and agree to this Beta Tester Agreement.
                            </label>
                        </div>
                        <div className={styles.modalActionsBeta}>
                            <button
                                id={styles.button}
                                onClick={onClose}
                                className={styles.cancelButton}
                            >
                                Cancel
                            </button>
                            <button
                                id={styles.button}
                                onClick={handleSubmit}
                                className={styles.submitButton}
                                disabled={!isChecked || !isScrolledToBottom}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BetaTesterAgreementModal;
