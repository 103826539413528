import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ErrorCode from "ErrorCode";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/login`,
        { email, password },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.user;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userSignup = createAsyncThunk(
  "user/signup",
  async (signUpData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/signup`,
        signUpData,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      }
      return;
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userAuthenticate = createAsyncThunk(
  "user/authenticate",
  async (values, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/verifyOTP`,
        values,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        localStorage.setItem("userToken", data.data.user.token);
        localStorage.setItem(
          "tokenExpirationTime",
          Date.now() + 24 * 60 * 60 * 1000
        );
        localStorage.setItem("role", data?.data?.user?.role);
        localStorage.setItem("userID", data?.data?.user?._id);
        localStorage.setItem("UserIDSender", data?.data?.user?.userId);
        return data.data.user;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendOTP = createAsyncThunk(
  "user/sendOTP",
  async (verifyOTPData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/sendOTP`,
        verifyOTPData,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.user;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (values, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/forgotPassword`,
        values,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (values, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/resetPassword`,
        values,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (userToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/logout`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ old_password, new_password, userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/changePassword`,
        { old_password, new_password },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const socialSignup = createAsyncThunk(
  "user/socialSignup",
  async ({ signUpData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/socialLogin`,
        signUpData,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        localStorage.setItem("userToken", data?.data?.user?.token);
        localStorage.setItem("role", data?.data?.user?.role);
        localStorage.setItem("userID", data?.data?.user?._id);
        return data.data.user;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userExistStatus = createAsyncThunk(
  "user/userExistStatus",
  async (UserEmail, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const payload = {
        identifier: "email",
        email: UserEmail,
      };
      const { data, message } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/userExistStatus`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return { data, message };
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyNonAuthPhoneEmail = createAsyncThunk(
  "verifyNonAuthPhoneEmail",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/verifyNonAuthPhoneEmail`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLinkedInProfile = createAsyncThunk(
  "getLinkedInProfile",
  async (payload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/getLinkedInProfile`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data?.profile;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyOTPByEmail = createAsyncThunk(
  "verifyOTPByEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/verifyOTP`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        window.localStorage.setItem("userToken", data.data.user.token);
        window.localStorage.setItem("userToke", data.data.user.token);
        localStorage.setItem(
          "tokenExpirationTime",
          Date.now() + 30 * 60 * 1000
        );
        localStorage.setItem("role", data?.data?.user?.role);
        localStorage.setItem("userID", data?.data?.user?._id);
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const socialDataStore = createAsyncThunk(
  "socialDataStore",
  async ({ item, userType }, { rejectWithValue }) => {
    try {
      const data = {
        item: item,
        userType: userType,
      };
      if (item.hasOwnProperty("error")) {
        return rejectWithValue(item.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
