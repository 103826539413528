import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ErrorCode from "ErrorCode";
import moment from 'moment-timezone';

const userTimezone = moment.tz.guess();

export const uploadLicense = createAsyncThunk(
  "dashboard/license",
  async ({ userToken, licenseFile }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      let formData = new FormData();
      formData.append("image", licenseFile[0]);
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/uploadCertificate`,
        formData,
        config
      );
      var sendUpdate = "";
      if (data?.status) {
        const configLicense = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: userToken,
            "X-custom-header": "$Ecur!tyHEadEr",
          },
        };
        sendUpdate = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/updateSetupAssitance`,
          { license: true },
          configLicense
        );
      }
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return [data.data.user, sendUpdate?.data?.data];
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addOns = createAsyncThunk(
  "addOns",
  async (userToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/service/getFearGreedIndex`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// -------------------News API in Addons section-------------------//
export const newsAPI = createAsyncThunk(
  "getCryptoNews",
  async (userToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/service/getCryptoNews`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fearIndexPin = createAsyncThunk(
  "community/checkConnected",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/pinAddOn`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const dashboardGraph = createAsyncThunk(
  "dashboardGraph",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/dashboard`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const dashboardHealth = createAsyncThunk(
  "dashboardHealth",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/dashboard-health`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const dashboardDroid = createAsyncThunk(
  "dashboardHDroid",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getDroidProfitLossCount`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const topCoinList = createAsyncThunk(
  "topCoinList",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/topTenList`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//-----------------------client Page Dashboard---------------------------------------//
export const clientPageDashboard = createAsyncThunk(
  "client-dashboard",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/client-dashboard${id !== undefined ? "?period=1years&retailerObjectId=" + id : ""
        }`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const clientPageDashboard2 = createAsyncThunk(
  "client-dashboard",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getDroidProfitLossCount${id !== undefined ? "?userId=" + id : ""
        }`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const cache = JSON.parse(localStorage.getItem("historicGlobalMetric"));

export const historicGlobalMetrics = createAsyncThunk(
  "historicGlobalMetrics",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      if (cache && Date.now() - cache.timestamp < 5 * 60 * 1000) {
        return cache.data;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/service/getPublishingData/historicGlobalMetrics`,
        {
          time_start: "",
          time_end: "",
          count: 30,
          interval: "5m",
          convert: "",
          convert_id: "",
          aux: "",
          format: "chart",
        },
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        localStorage.setItem(
          "historicGlobalMetric",
          JSON.stringify({
            data: data.data,
            timestamp: Date.now(),
          })
        );
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTrendScoreDashboard = createAsyncThunk(
  "updateTrendScore",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/updateTrendScore`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getGraphDataRetailer = createAsyncThunk(
  "getGraphDataRetailer",
  async ({ userToken, getDate }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/getGraphData`,
        { filterBy: getDate?.day, email: getDate?.email, timeZone: userTimezone },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getGraphDataBroker = createAsyncThunk(
  "getGraphDataForBroker",
  async ({ userToken, getDate }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/getGraphDataForBroker`,
        { filterBy: getDate?.day, timeZone: userTimezone },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
