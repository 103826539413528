import React, { useState, useEffect } from "react";
import ClientSubHeader from "../DashboardComponent/ClientSubHeader";
import ProfileDetailsComponent from "../ProfileDetailsComponent";
import Slider from "../Slider";
import "../../scss/dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getGraphDataRetailer } from "features/Dashboard/dashboardAction";
import {
  clientPageDashboard,
  clientPageDashboard2,
} from "features/Dashboard/dashboardAction";
import BalanceDetailsComponent from "components/DashboardComponent/BalanceDetailsComponent";
import DroidComponent from "components/DashboardComponent/DroidComponent";
import HealthComponent from "components/DashboardComponent/HealthComponent";
import { getGraphDataBroker } from "features/Dashboard/dashboardAction";
import StocBalanceGraph from "components/Charts/StocBalanceGraph";
import { ArrowDownIcon } from "assets";
import { setTrendROI } from "features/Dashboard/dashboardSlice";
const ClientDashboardDetails = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const [clientPage, setClientPage] = useState();
  const [droidProfitLoss, setdroidProfitLoss] = useState();
  const { state } = useLocation();
  const { userId } = state;
  const [isLoading, setIsLoading] = useState(true);
  const [getDate, setDate] = useState({
    text: "24 Hours",
    day: "day",
    email: userId,
  });
  const [getBalance, setBalance] = useState();
  useEffect(() => {
    if (userId) {
      dispatch(clientPageDashboard({ userToken, id: userId }))
        .then((res) => {
          if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
            setClientPage(res?.payload);
          }
        })
        .catch((err) => {
          console.log("error occured");
        });
    }
    if (userId) {
      dispatch(clientPageDashboard2({ userToken, id: userId }))
        .then((res) => {
          if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
            setdroidProfitLoss(res?.payload);
          }
        })
        .catch(() => {
          console.log("error occured");
        });
    }
  }, [userId, userToken]);

  const callAPIRetailer = () => {
    setIsLoading(true);
    dispatch(getGraphDataRetailer({ userToken, getDate })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        dispatch(setTrendROI(res?.payload));
        localStorage.setItem("trendROI", JSON.stringify(res?.payload));
        setBalance(res?.payload?.graphData);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    callAPIRetailer();
  }, [getDate]);

  return (
    <>
      <ClientSubHeader {...clientPage?.data} />
      <div className="container-fluid dashboardContentMainBody ">
        <div className="row mt-5 dashboardContentMain flex-container">
          <div className="col-lg-8 row mb-5 dashbord-slider justify-content-evenly">
            <Slider
              data={clientPage?.data?.portfoliosCard?.filter(
                (x) => x?.balance > 0.01
              )}
            />
            <BalanceDetailsComponent {...clientPage?.data} />
            <div className="singleRow mt-4">
              <div className="d-flex align-items-baseline">
                <h3 className="me-2 mt-1 fw-bold"> Balance </h3>
                <div className="btn-group dashboard-dropdown ML-5">
                  <button
                    className="btn dropdown-toggle d-flex justify-content-between align-items-center p-Three"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <div className={`p-Six`}>{getDate?.text}</div>
                    <ArrowDownIcon color={"#858585"} height={"18"} />
                  </button>
                  <ul className="dropdown-menu w_inherit">
                    {[
                      { text: "24 Hours", day: "day", email: userId },
                      { text: "7 Days", day: "weekly", email: userId },
                      { text: "1 Month", day: "monthly", email: userId },
                      { text: "12 Months", day: "yearly", email: userId },
                    ].map((item, index) => (
                      <li
                        key={index}
                        className={`dropdown-item py-3 justify-content-between d-flex${
                          index === 3 ? "" : " border-bottom"
                        }`}
                        onClick={() => {
                          setDate(item);
                        }}
                      >
                        {item?.text}
                        {getDate?.text === item?.text && (
                          <img
                            src={require("assets/rightMark.svg").default}
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="graph mt-2">
                {" "}
                <StocBalanceGraph
                  getBalance={getBalance}
                  chartType={getDate?.day}
                  isLoading={isLoading}
                />{" "}
              </div>
            </div>

            <div className="my-4 row">
              <DroidComponent
                data={droidProfitLoss?.data}
                status={droidProfitLoss?.status}
              />
              <HealthComponent
                data={clientPage?.data}
                status={clientPage?.status}
              />
            </div>
          </div>
          <div className="col-lg-4 client-profile-container profile_scrolling flex-item">
            <ProfileDetailsComponent
              {...clientPage?.data?.user}
              {...clientPage?.data}
              writeAMessage={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDashboardDetails;
