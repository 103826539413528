import React from "react";

function BitcoinWhiteBg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect width="44" height="44" fill="#F7931A" rx="22"></rect>
      <path
        fill="#fff"
        d="M30.703 19.795c.354-2.364-1.446-3.635-3.907-4.483l.798-3.202-1.95-.486-.777 3.118a80.927 80.927 0 00-1.562-.367l.783-3.14-1.948-.485-.799 3.201c-.424-.096-.84-.192-1.245-.292l.003-.01-2.689-.671-.518 2.082s1.446.331 1.415.352c.79.197.933.72.909 1.133l-.91 3.649c.055.014.125.034.203.065l-.206-.051-1.275 5.111c-.096.24-.341.6-.893.463.02.028-1.417-.354-1.417-.354l-.968 2.232 2.537.633c.472.118.934.242 1.39.358l-.807 3.24 1.947.485.8-3.205c.531.145 1.047.278 1.553.403l-.796 3.19 1.95.486.806-3.233c3.324.629 5.824.375 6.876-2.631.848-2.421-.042-3.818-1.791-4.728 1.274-.294 2.232-1.132 2.488-2.863zm-4.454 6.246c-.602 2.42-4.678 1.112-6 .784l1.07-4.291c1.322.33 5.56.982 4.93 3.507zm.603-6.28c-.55 2.201-3.942 1.082-5.043.808l.971-3.892c1.1.274 4.644.786 4.072 3.083z"
      ></path>
    </svg>
  );
}

export default BitcoinWhiteBg;
