import React, { useEffect, useState } from "react";
import AddOnCardDescription from "./AddOnCardDescription";
import "./AddOns.scss";
import Collapsible from "./Collapsible";
import CryptoCurrenciesCard from "./CryptoCurrencies";
import FearIndex from "./FearIndex";
import GlobalMarketCap from "./GlobalMarketCap";
import NewsCard from "./newsCard";
import { ArrowDownIcon, ArrowRightIcon, ArrowUPIcon } from "assets";
import { addOns, newsAPI } from 'features/Dashboard/dashboardAction'
import { useDispatch, useSelector } from "react-redux";

function AddOns({ addOnTabs, pinnedTabs, onClickPin, onClickUpdate, onClickExpand }) {
  const { userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isVisible, setIsVisible] = useState(true)
  const [newsData, setNewsData] = useState([]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  useEffect(() => {
    dispatch(newsAPI(userToken)).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setNewsData(res?.payload);
      }
    });
  }, []);

  const clickToggle = () => {
    setToggle(!toggle);
  };

  const renderAddOnTab = (tabId, index) => {
    switch (tabId) {
      case "globalMarketCap":
        return (
          <GlobalMarketCap />
        );
      case "fearIndex":
        return (
          <div className="graphDataRow" key={index}>
            <FearIndex pinnedTabs={pinnedTabs} onClickPin={() => onClickPin("fearIndex")} dataId='fearIndex' onClickExpand={onClickExpand} onClickUpdate={onClickUpdate} isVisible={isVisible} />
            <h5 className="mt-4">Fear Index</h5>
            <p>explanation of the widget in two lines maximum of the widget in two lines maximum explanation of the... </p>
            <small>Used by 27.3k people</small>
          </div>
        );
      case "cryptoCurrencies":
        return (
          <div className="graphDataRow" key={index}>
            <CryptoCurrenciesCard addonsContainer={true} onClickPin={() => onClickPin("cryptoCurrencies")} dataId='fearIndex1' onClickExpand={onClickExpand} onClickUpdate={onClickUpdate} />
            <AddOnCardDescription>
              <h5 className="mt-4">Crypto Currencies</h5>
              <p> explanation of the widget in two lines maximum of the widget in two lines maximum explanation of the... </p>
              <small>Used by 27.3k people</small>
            </AddOnCardDescription>
          </div>
        );
      case 'news':
        return (

          <div className="graphDataRow" key={index}>
            <NewsCard onClickPin={() => onClickPin("news")} onClickExpand={onClickExpand} dataId='fearIndex2' onClickUpdate={onClickUpdate} newsData={newsData} />
          </div>
        )
      default:
        return null;
    }
  };
  return (
    <>
      <div className={`addOnsContainer`}>
        <div className="d-flex align-items-center gap-2" type="button" data-bs-dismiss="modal" >
          <ArrowRightIcon color="#fff" /> <h3>8081's Add-ons</h3>
        </div>
        <hr />
        <div className="savedAddOns">
          <div className="savedAddOnsHeader">
            <button className="d-flex align-items-end bg-transparent border-0 mt-4  gap-2" onClick={clickToggle}>
              {toggle ? <ArrowDownIcon /> : <ArrowUPIcon />}
              <h4> Saved Add-Ons <span className="h5 text-secondary ms-1">{pinnedTabs?.length}</span> </h4>
            </button>
          </div>
          <Collapsible toggle={toggle}>
            {addOnTabs?.map((tabId, index) => renderAddOnTab(tabId, index))}
          </Collapsible>
        </div>
      </div>
    </>
  );
}

export default AddOns;
