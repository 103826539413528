import { createSlice } from '@reduxjs/toolkit'
import { portfolioDataDashboard } from './DefaultStateAction';
const DefaultStateSlice = createSlice({
  name: 'exchange',
  initialState: {
    portfolioDataDashboardData: null,
    droidPopupSelect: [
      { nav: 'simplemodedroid', name: 'Simple Mode Droid', img: 'assets/SimpleModeDroidIcon.svg', subHeading: 'Launch a droid with smart capabilities DCA, Buy/Sell with AI Built-In Technical Analysis Indicators' },
      { nav: 'timemodedroid', name: 'Time Mode Droid', img: 'assets/timeModeDroidOutline.svg', subHeading: 'Launch a droid that is based on Time Setting/Periods. AI Indicators are the time intervals.' },
      { nav: 'costmodedroid', name: 'Crypto/Dollar Cost Average Mode Droid', img: 'assets/averageModeDroidOutline.svg', subHeading: 'Launch a droid with smart capabilities DCA, Buy/Sell with AI Built-In Technical Analysis Indicators' },
      { nav: 'indexmodedroid', name: 'Index Mode Droid', img: 'assets/indexModeDroid.svg', subHeading: 'Launch your own Index Fund based on Crypto Coins, simply let the Droid balance your assets and index your portfolio to match your needs. The Droid will Buy/Sell and Balance your portfolio.' },
      { nav: 'safemodedroid', name: 'Safe Mode Droid', img: 'assets/safeModeDroidOutline.svg', subHeading: 'Launch a droid with watchdog that has watchdog exposure to volatility. For example buying Coin in price X and moment it drop from your target price sell it, but buy back on your new target price.' },
  ],
  balanceDataPortfoliyo : [{ balance: '0-10K' }, { balance: '10k-50K' }, { balance: '50k-100K' }, { balance: '100k-1M' }, { balance: '1M+' }],
  riskLevelDataPortfoliyo : [{ risk: 'Low' }, { risk: 'Medium' }, { risk: 'High' }],
  takeProfitTypeData : [{ label: 'Percentage from base order', name:'base' }, { label: 'Percentage from total value', name: 'value' }],
  currencyData : [{ label: '$' , name: 'dollar'}, { label: '%', name : 'percent' }],
  closeDeal : [{ label: 'Close Deal' }, { label: 'Close Deal and Stop Droid' }],
  typeoflevel: [ { level: "Level 1", id: 1 }, { level: "Level 2", id: 2 }, { level: "Level 3", id: 3 }, { level: "Level 4", id: 4 }, { level: "Level 5", id: 5 }, { level: "Level 6", id: 6 }, { level: "Level 7", id: 7 }, { level: "Level 8", id: 8 }, { level: "Level 9", id: 9 }, { level: "Level 10", id: 10 }]
  },
  reducers: {
    DefaultState: (state, action) => {
      state.data = action.payload;
    },
  },

  extraReducers: {
    [portfolioDataDashboard.pending]: (state) => { },
    [portfolioDataDashboard.fulfilled]: (state, { payload }) => {
      state.portfolioDataDashboardData = payload
    },
    [portfolioDataDashboard.rejected]: (state, { payload }) => { },
  },  
})

export const { DefaultState } = DefaultStateSlice.actions
export default DefaultStateSlice.reducer
