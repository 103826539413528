import { createSlice } from "@reduxjs/toolkit";
import {
  DroidApproval,
  changeDroidStatus,
  getPorfolioByUserId,
  clientInvite,
  getDeclinedUserList,
  emailInviteMessage,
  getInvitedClientCountAPI,
  getQuestionsAPI,
  answerToQuestionAPI,
  sendPendingRemainder,
  sendMailToDeclinedUser,
  allConnectedUserListClient,
  getConnectedUserListClient,
} from "./managementAction";

const initialState = {
  managementInfo: null,
  emailInviteMessageInfo: null,
  emailInviteMessageError: null,
  getInvitedClientCountInfo: null,
  getQuestionsAPIInfo: null,
  answerToQuestionInfo: null,
  isemailInviteMessageInfo: false,
  isemailInviteMessageError: false,
  isgetQuestionsAPI: false,
  isanswerToQuestion: false,
  issendPendingRemainder: false,
  isgetDeclinedUserList: false,
  issendMailToDeclinedUser: false,
  sendMailToDeclinedUser: null,
  allConnectedUserListClientData: null,
  droidApproval: null,
  isdroidApproval: false,
  changeDroidStatus: null,
  ischangeDroidStatus: false,
  porfolioByUserIdData: null,
  allConnectedUserCount: 0,
};

const clientInviteSlice = createSlice({
  name: "management",
  initialState,
  reducers: {
    clearclientInvite: (state) => {
      state.isemailInviteMessageInfo = false;
      state.isemailInviteMessageError = false;
    },
    clearreplyMessage: (state) => {
      state.isanswerToQuestion = false;
    },
    clearsendPendingRemainder: (state) => {
      state.issendPendingRemainder = false;
    },
    cleargetDeclinedUserList: (state) => {
      state.isgetDeclinedUserList = false;
    },
    clearsendMailToDeclinedUser: (state) => {
      state.issendMailToDeclinedUser = false;
    },
    clearDroidApproval: (state) => {
      state.isdroidApproval = false;
    },
    clearChangeDroidStatus: (state) => {
      state.ischangeDroidStatus = false;
    },
    increaseConnectedUserCount: (state, { payload }) => {
      state.allConnectedUserCount = payload;
    },
    setPorfolioByUserIdData: (state, action) => {
      state.porfolioByUserIdData = action.payload;
    },
  },
  extraReducers: {
    [clientInvite.pending]: (state) => {},
    [clientInvite.fulfilled]: (state, { payload }) => {
      state.managementInfo = payload;
      state.isemailInviteMessageInfo = true;
    },
    [clientInvite.rejected]: (state, { payload }) => {
      state.emailInviteMessageError = payload;
      state.isemailInviteMessageError = true;
    },
    [emailInviteMessage.pending]: (state) => {},
    [emailInviteMessage.fulfilled]: (state, { payload }) => {
      state.emailInviteMessageInfo = payload;
    },
    [emailInviteMessage.rejected]: (state, { payload }) => {},
    [getInvitedClientCountAPI.pending]: (state) => {},
    [getInvitedClientCountAPI.fulfilled]: (state, { payload }) => {
      state.getInvitedClientCountInfo = payload;
    },
    [getInvitedClientCountAPI.rejected]: (state, { payload }) => {},
    [getQuestionsAPI.pending]: (state) => {},
    [getQuestionsAPI.fulfilled]: (state, { payload }) => {
      state.getQuestionsAPIInfo = payload;
    },
    [getQuestionsAPI.rejected]: (state, { payload }) => {},
    [answerToQuestionAPI.pending]: (state) => {},
    [answerToQuestionAPI.fulfilled]: (state, { payload }) => {
      state.answerToQuestionInfo = payload;
      state.isanswerToQuestion = true;
    },
    [answerToQuestionAPI.rejected]: (state, { payload }) => {},
    [sendPendingRemainder.fulfilled]: (state, { payload }) => {
      state.issendPendingRemainder = true;
    },
    [sendPendingRemainder.rejected]: (state, { payload }) => {},
    [getDeclinedUserList.fulfilled]: (state, { payload }) => {
      state.isgetDeclinedUserList = true;
      state.getDeclinedUserList = payload;
    },
    [getDeclinedUserList.rejected]: (state, { payload }) => {},
    [sendMailToDeclinedUser.fulfilled]: (state, { payload }) => {
      state.issendMailToDeclinedUser = true;
      state.sendMailToDeclinedUser = payload;
    },
    [sendMailToDeclinedUser.rejected]: (state, { payload }) => {},

    [allConnectedUserListClient.pending]: (state) => {},
    [allConnectedUserListClient.fulfilled]: (state, { payload }) => {
      state.allConnectedUserListClientData = payload;
    },
    [allConnectedUserListClient.rejected]: (state, { payload }) => {},

    [getConnectedUserListClient.fulfilled]: (state, { payload }) => {
      state.getConnectedUserListClient = payload;
    },
    [getConnectedUserListClient.rejected]: (state, { payload }) => {},

    [DroidApproval.pending]: (state) => {},
    [DroidApproval.fulfilled]: (state, { payload }) => {
      state.droidApproval = payload;
      state.isdroidApproval = true;
    },
    [DroidApproval.rejected]: (state, { payload }) => {},

    [changeDroidStatus.pending]: (state) => {},
    [changeDroidStatus.fulfilled]: (state, { payload }) => {
      state.changeDroidStatus = payload;
      state.ischangeDroidStatus = true;
    },
    [changeDroidStatus.rejected]: (state, { payload }) => {},

    [getPorfolioByUserId.pending]: (state) => {},
    [getPorfolioByUserId.fulfilled]: (state, { payload }) => {
      state.porfolioByUserIdData = payload;
    },
    [getPorfolioByUserId.rejected]: (state, { payload }) => {
      state.porfolioByUserIdData = payload;
    },
  },
});

export const {
  clearChangeDroidStatus,
  clearclientInvite,
  clearreplyMessage,
  clearsendPendingRemainder,
  clearsendMailToDeclinedUser,
  clearDroidApproval,
  increaseConnectedUserCount,
  setPorfolioByUserIdData,
} = clientInviteSlice.actions;
export default clientInviteSlice.reducer;
