import React from "react";

function LikeIcon({color='#0078E7',width=16,height=16}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 11"
    >
      <path
        fill={color}
        d="M8.95 10.286H3.4V4.19L6.875.762l.487.369c.05.04.088.095.113.167a.798.798 0 01.037.262v.119L6.95 4.19h3.737c.2 0 .376.072.526.215a.67.67 0 01.225.5v.976a.84.84 0 00.018.173.274.274 0 01-.018.172L9.861 9.68c-.075.166-.197.31-.368.428a.935.935 0 01-.544.179zm-4.8-.715h4.962l1.575-3.56V4.906H6.026l.662-2.965L4.15 4.488v5.083zm0-5.083v5.083-5.083zM3.4 4.19v.715H1.737V9.57H3.4v.715H.987V4.19H3.4z"
      ></path>
    </svg>
  );
}

export default LikeIcon;
