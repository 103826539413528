import React, { useState, useEffect, memo } from "react";
import { Rate } from 'antd';
import { AddIcon, StarIcon } from "assets";
import Email from "assets/email.svg";
import "../ProfileScreen/index.scss";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import { sendConnectionRequest, checkConnected, getReviewsData, postReviewData, editReviewsData, postaskQuestion, getRetailerDetail } from '../../features/Community/communityAction'
import { userProfile } from "features/profile/profileAction";
import { useLocation, useNavigate } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import Button from "../../components/Button";
import SecondryButton from "../../components/Button/SecondryButton";
import RetailerExchange from "./RetailerExchange";
import ProfileDetailsComponent from "components/ProfileDetailsComponent";
import ProfileRetailerExchangesComponent from "components/ProfileDetailsComponent/ProfileRightComponent";
import ProfileTradingTipsComponent from "components/ProfileDetailsComponent/ProfileTradingTipsComponent";
import RejectPage from "components/RejectPage";
import { useTranslation } from 'react-i18next';

const TimeZone = (item) => {
  let currentDate = new Date().toISOString()

  let reviewTime = new Date(item?.updatedAt).toISOString()
  let d = (new Date(currentDate)) - (new Date(reviewTime));
  let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
  let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
  let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
  let minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60);
  let seconds = Math.floor(d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
  let e = {};
  ['weekdays', 'days', 'hours', 'minutes', 'seconds'].forEach(q => { if (eval(q) > 0) { e[q] = eval(q); } });

  if (e['weekdays'] != undefined) {
    return `${e['weekdays']} weeks ago`
  } else if (e['hours'] != undefined) {
    return `${e['hours']} hours ago`
  } else if (e['minutes'] != undefined) {
    return `${e['minutes']} minutes ago`
  } else if (e['seconds'] != undefined) {
    return `Now`
  }
}
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
function Broker(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate()
  const [getAskLoading, setAskLoading] = useState(false)
  const { userToken } = useSelector((state) => state.auth);
  const [getAstQustionInput, setAstQustionInput] = useState('')
  const [getDetails, setDetails] = useState()
  const [getConnection, setConnection] = useState()
  const [getRating, setRating] = useState(1)
  const [getDescription, setDescription] = useState("")
  const [loading, setLoading] = useState(false)
  const [geteditReviewloading, setEditReviewLoading] = useState(false)
  const [postloading, setPostLoading] = useState(false)
  const [getpostReviewData, setPostReviewData] = useState(false)
  const [sendConnectionloading, setSendConnectionLoading] = useState(false)
  const [getReview, setReview] = useState()
  const [getSendConnection, setSendConnection] = useState([])
  const [name, setName] = useState(null);
  const [userData, setUserdata] = useState([]);
  const [AddNewreview, setAddNewreview] = useState(false)
  const [editReview, setEditReview] = useState(false)
  const [d, setD] = useState('')

  var userID = localStorage?.getItem('userID')

  const starColor = { color: "#0078E7" }

  const handleClose = () => {
    !editReview && setRating(1)
    setDescription("")
    setAddNewreview(false)
    setEditReview(false)
  }

  const handleEditClose = () => {
  }

  useEffect(() => {

    let id = location?.pathname?.includes('Profile') ? location?.pathname?.split('/')[2] : encodeURIComponent(location?.search?.split('&')[0]?.split('=')[1])
    let url = location?.pathname?.includes('Profile') ? 'userId' : 'userIdEn'
    if (location?.search?.includes('reject')) {
      navigate(`/rejectPage?${location?.search}`);
    } else {
      setLoading(true)
      dispatch(getRetailerDetail({ userToken, id, url })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setDetails(res?.payload)
          setLoading(false)
        }
      })
    }


  }, [location?.pathname])

  let userProfileId = getDetails?.userId
  useEffect(() => {
    if (userProfileId) {
      dispatch(checkConnected({ userToken, userProfileId })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setConnection(res?.payload)
          if (res?.payload?.status_id !== '2') {
          }
        }
      })
    }
  }, [getSendConnection, getDetails?.userId])


  var ProfileId = getDetails?._id

  useEffect(() => {
    setPostReviewData(false)
    setEditReviewLoading(false)
    if (ProfileId) {
      dispatch(getReviewsData({ userToken, ProfileId })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setReview(res.payload);
          let rate = res.payload?.response?.filter((x, y) => x.reviewer_info[0]._id === userID)
          setRating(rate[0]?.rating)
          let desc = res.payload?.response?.filter((x, y) => x.reviewer_info[0]._id === userID)
          setD(desc[0]?.description)
          setDescription(d)
        }
      });

    }
  }, [getSendConnection, getpostReviewData, ProfileId, geteditReviewloading, d, editReview])

  // user profile
  useEffect(() => {
    dispatch(userProfile(userToken)).then((response) => {
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        setName(response?.payload?.full_name);
        setUserdata(response?.payload)
      }
    });
  }, []);


  const handleDescription = (e) => {
    const newValue = e?.target?.value?.replace(/^\s+/, '');
    setDescription(newValue)
  }
  const sendConnectionRequestClick = () => {
    setSendConnectionLoading(true)
    dispatch(sendConnectionRequest({ userToken, getDetails, getConnection })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setSendConnection(res.meta)
        setSendConnectionLoading(false)
      }
    })
  }


  const postReviewClick = () => {
    var userProfile_id = getDetails?._id
    setLoading(true)
    if (userProfileId && !!getDescription) {
      dispatch(postReviewData({ userToken, userProfile_id, getRating, getDescription })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setPostLoading(false)
          setPostReviewData(true)
          setEditReview(false)
          setLoading(false)
        }
        setRating(1)
        setDescription('')
        setAddNewreview(false)
      })
    }
  }

  const UpdateReview = (id) => {
    var ProfileId = id?._id
    if (userProfileId && !!getDescription) {
      dispatch(editReviewsData({ userToken, ProfileId, getRating, getDescription })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setLoading(false)
          setEditReviewLoading(true)
        }
        setRating(getRating)
        setDescription(getDescription)
      })
    }
    setEditReview(false)
  }

  const askQuestionSubmit = () => {

    if (getAstQustionInput?.length > 1) {
      let brokerId = getDetails?._id
      if (brokerId) {
        dispatch(postaskQuestion({ userToken, brokerId, getAstQustionInput, name })).then((res) => {
          if (res.meta && res.meta.requestStatus === "fulfilled") {
          }
        });
        setAstQustionInput('')
      }
    }
  }
  const handleAskQuestion = (e) => {
    const newValue = e?.target?.value?.replace(/^\s+/, '');
    setAstQustionInput(newValue)
  }
  return (
    <>
      {!getDetails || loading ? <div className="Broker_Retailer_ProfileLoading"> <div className="loaderProfile" role="status"> </div> </div> :
        <div className="broker-info-page pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 "> <ProfileDetailsComponent {...getDetails} writeAMessage={false} broker={localStorage.getItem('role') === 'retailer'} setAskLoading={setAskLoading} /> </div>
              <div className="col-lg-8">
                <div className="row ms-2">
                  <ProfileRetailerExchangesComponent {...getDetails} />
                  {localStorage.getItem('role') === 'broker' ? <RetailerExchange getDetails={getDetails} /> : <ProfileTradingTipsComponent {...getDetails} />}
                  {localStorage.getItem('role') !== 'broker' && <>
                    <div className="d-flex mt-5 justify-content-between align-items-center">
                      <div className=" d-flex">
                        <h3>Reviews</h3>
                        <h5 className="mt-2 ms-2 text-muted">{getReview?.numberOfReviews || 0} Reviews</h5>
                        <div className="d-flex justify-content align-items-center gap-1 mt-1 ms-2">
                          <StarIcon color={getReview?.averageReview > 0 ? '#0078E7' : 'rgb(240 240 240)'} />
                          <StarIcon color={getReview?.averageReview >= 2 ? '#0078E7' : 'rgb(240 240 240)'} />
                          <StarIcon color={getReview?.averageReview >= 3 ? '#0078E7' : 'rgb(240 240 240)'} />
                          <StarIcon color={getReview?.averageReview >= 4 ? '#0078E7' : 'rgb(240 240 240)'} />
                          <StarIcon color={getReview?.averageReview >= 5 ? '#0078E7' : 'rgb(240 240 240)'} />
                        </div>
                      </div>
                      {getConnection?.status_id > 0 ? (d?.length < 1) && (
                        <button className="d-flex button-secondary border-0 gap-2 px-3 py-2" onClick={() => setAddNewreview(!AddNewreview)} disabled={AddNewreview} >
                          <AddIcon color={AddNewreview ? '#C2C2C2' : '#0078E7'} /> <div className={AddNewreview && 'text-body-tertiary'}> Add Review </div>
                        </button>
                      ) : ''}
                    </div>

                    {AddNewreview || editReview === true ?
                      <div className='popupReviewScreen'>
                        {<div className="modal-body">
                          <div className='addReviewStars'>
                            <div className="d-flex align-items-center">
                              <div className=''>
                                <img src={userData?.image ? userData?.image : require("../../assets/commonAvtar.svg").default} alt="" className="brokerAvatar me-2" loading='lazy' />
                              </div>
                              <h5>{userData?.full_name}</h5>
                            </div>
                            <div className=" d-flex justify-content align-items-center gap-1 mb-2 ">
                              <h5 className='d-flex justify-content-center reviewTextarea p-Five'>How would you rate {getDetails?.full_name}? </h5>
                              <div className="addReviewStars product-review-stars d-flex gap-3 mb-1 mx-3">
                                <div style={{ transform: 'scaleX(-1)' }}>
                                  <Rate tooltips={desc} onChange={setRating} value={getRating} style={starColor} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card border-0 mb-3 reviewTextarea">
                            <textarea rows="3" className={`form-control p-3 bg-white`} value={getDescription} placeholder={editReview ? "Edit Review" : "Add New Review"} onChange={() => handleDescription} />
                          </div>
                          <div className="d-flex justify-content-end gap-3">
                            <SecondryButton text='Cancel' loading={loading} databsdismiss={getDescription?.length > 0 ? 'modal' : ''} onClick={handleClose} />
                            <Button text="Post" disabled={getDescription?.length === 0 || loading} databsdismiss={getDescription?.length > 0 ? 'modal' : ''} arialabel={!postloading && getpostReviewData && "Close"} loading={postloading && getDescription?.length > 0 || loading} handleClick={postReviewClick} />
                          </div>
                        </div>
                        }
                      </div> : ""
                    }
                    <div className="border-bottom mt-3 mb-2"></div>
                    <div className="mt-3">
                      {getReview?.response?.map((item, index) => {
                        return (
                          <>
                            <div className='d-flex justify-content-between align-items-start' key={index}>
                              <div className='d-flex align-items-start'>
                                <img src={item?.reviewer_info[0]?.image ? item?.reviewer_info[0]?.image : require("assets/commonAvtar.svg").default} alt="" className="brokerAvatar me-2" loading='lazy' />
                                <div>
                                  <div className='d-flex gap-2 mt-2 align-items-baseline'>
                                    <h3 className=''>{item?.reviewer_info[0]?.full_name}</h3>
                                    <small className='p-Four d-flex'>{TimeZone(item) || 'Now'}</small>
                                  </div>
                                  <div className="d-flex justify-content align-items-center gap-1 my-2">
                                    {[...Array(5)].map((e, i) => <StarIcon color={item?.rating > i ? '#0078E7' : 'rgb(240 240 240)'} />)}
                                  </div>
                                  <small className=' text-black font-weight-bold text-break'>"{item?.description}"</small>
                                </div>
                              </div>
                              {userID === item?.reviewer_info[0]?._id && <img src={require('../../assets/editIcon.svg').default} alt="" className='curser-pointer' onClick={() => setEditReview(!editReview)} loading='lazy' />}
                            </div>
                            <hr />
                            <PopupModal id={`editReview${item?._id}`} backdrop={false}>
                              <div className='popupReviewScreen'>
                                <div className="modal-header border-0">
                                  <div className='addReviewCompanyLogo'>
                                    <img src={require(`../../assets/companylogo.svg`).default} className="addReviewCompanyLogo " alt="Review" loading='lazy' />
                                  </div>
                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleEditClose} />
                                </div>
                                <div className="modal-body">
                                  <div className='addReviewStars'>
                                    <div>
                                      <h3 className="modal-title addReviewLabels d-flex justify-content-center" id="exampleModalLabel ">Edit Review </h3>
                                      <h5 className='d-flex justify-content-center mb-1'>How was your experience? Click on star to rate {getDetails?.full_name}</h5>
                                    </div>
                                    <div className=" d-flex justify-content align-items-center gap-1 mb-2 justify-content-center">
                                      <div className="addReviewStars product-review-stars d-flex gap-3">
                                        <div style={{ transform: 'scaleX(-1)' }}>
                                          <Rate tooltips={props.desc} onChange={setRating} defaultValue={item?.rating} style={starColor} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card border-0 w-100 mb-3">
                                    <textarea rows="3" className={`form-control w-100 p-3 bg-white`} defaultValue={item?.description} placeholder="Add Review" onChange={() => handleDescription} />
                                  </div>
                                  <div className="d-flex justify-content-end ">
                                    <Button text="Post" databsdismiss={getDescription?.length > 0 ? 'modal' : ''} disabled={getDescription?.length === 0 && true} arialabel={!loading && "Close"} handleClick={() => UpdateReview(item)} />
                                  </div>
                                </div>
                              </div>
                            </PopupModal>
                          </>
                        )
                      }).reverse().slice(editReview ? 1 : 0)}
                    </div>
                  </>}

                  <div className="mt-3 float-end d-flex ask-Qustion-container justify-content-end">
                    {localStorage.getItem('role') === 'retailer' && getDetails?.full_name &&
                      <SecondryButton className="mx-3" disabled={getAskLoading} databstoggle="modal" databstarget="#askqustion" text={<><img src={Email} alt="" className="me-2" loading='lazy' /> <span className="ms-1 font-weight-normal h5-Two">Ask  a Question</span>
                      </>} />
                    }
                    {localStorage.getItem('role') === 'retailer' && getConnection?.status_id && <Button handleClick={getConnection?.status_id == 0 ? sendConnectionRequestClick : ''} disabled={getConnection?.status_id == 1 ? true : false} text={getConnection?.status_id == 0 ?
                      t('sendConnectionRequest') : getConnection?.status_id == 1 ? 'Pending' : 'Connected'} loading={sendConnectionloading} />}
                    {localStorage.getItem('role') === 'broker' && getConnection?.status_id && <Button disabled={getConnection?.status_id == 1 ? true : false} text={getConnection?.status_id == 0 ? 'Not Connected' : getConnection?.status_id == 1 ? 'Pending' : 'Connected'} loading={sendConnectionloading} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopupModal id={'askqustion'} backdrop={false} >
            <div className='popupReviewScreen'>
              <div className="modal-header border-0">
                <div className='addReviewCompanyLogo'>
                  <h3>{`Ask  a Question`}</h3>
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAstQustionInput('')} />
              </div>
              <div className="modal-body">
                <div className="card border-0 w-100 mb-3">
                  <h5 className="">Full Name</h5>
                  <input type="text" placeholder="Name" disabled value={name} onChange={(e) => setName(e.target.value)} className="w-75 mb-4" />
                  <h5 className="mb-2">Message</h5>
                  <textarea rows="3" value={getAstQustionInput} className={`form-control w-100 p-3 bg-white`} placeholder="Type Your Message" onChange={() => handleAskQuestion} />
                </div>
                <div className="d-flex gap-3 justify-content-end ">
                  <SecondryButton text='Cancel' databsdismiss="modal" arialabel={"Close"} />
                  <Button text="Submit" databsdismiss="modal" arialabel={"Close"} handleClick={askQuestionSubmit} disabled={(getAstQustionInput?.length < 1 || name?.length < 1) ? true : false} />
                </div>
              </div>
            </div>
          </PopupModal>
        </div>
      }
      <PopupModal id='moreInformation' >
        <div className='popupReviewScreen m-3'>
          <div className="modal-header border-0">
            <div className='addReviewCompanyLogo'>
              <h3>Description</h3>
            </div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="p-One text-break"> {getDetails && getDetails?.description ? getDetails?.description : 'Description'} </div>
          </div>
        </div>
      </PopupModal>
    </>
  );
}

export default memo(Broker);
