import React from "react";

function EURO({ht=40, wd=40, bg='#FFC843'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      viewBox="0 0 256 256"
    >
      <g
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="1"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      >
        <circle cx="45" cy="45" r="45" fill="#FFC843"></circle>
        <circle cx="45" cy="45" r="35" fill="#D38700"></circle>
        <path
          fill={bg}
          d="M54.002 59.497a15.685 15.685 0 01-6.286 1.303c-5.659 0-10.618-3-13.409-7.483h12.898a3 3 0 100-6h-15.1c-.112-.758-.189-1.528-.189-2.316s.077-1.558.189-2.316h15.1a3 3 0 100-6H34.307c2.791-4.483 7.75-7.483 13.409-7.483 2.181 0 4.296.438 6.286 1.303a3 3 0 102.39-5.503 21.652 21.652 0 00-8.677-1.8c-9.076 0-16.872 5.577-20.148 13.483h-3.193a3 3 0 100 6h1.665a21.88 21.88 0 000 4.632h-1.665a3 3 0 100 6h3.193C30.844 61.223 38.639 66.8 47.716 66.8c3.009 0 5.929-.605 8.677-1.799a3 3 0 10-2.391-5.504z"
        ></path>
      </g>
    </svg>
  );
}

export default EURO;