import React from 'react'
import './index.scss'
function Button({databstoggle, databstarget,arialabel, small, className, variant = 'primary', databsdismiss, icon, text, handleClick, disabled = false, loading, type = "button", ...rest }) {
  if (loading)
    return (
      <button className={`custom-button ${disabled ? `button-${variant}-disabled` : `button-${variant}`} ${className} ${small ? 'sm' : ''}`} type={type} {...rest} disabled={disabled}>
        <span className="spinner-border spinner-grow-sm" role="status" aria-hidden="true"></span> {icon} {text} </button>);

  return (
    <button data-bs-toggle={databstoggle} data-bs-target={databstarget} aria-label={arialabel} disabled={disabled} type={type} data-bs-dismiss={databsdismiss} className={`custom-button ${disabled ? `button-${variant}-disabled` : `button-${variant}`}  ${className}  ${small ? `sm` : ``}`} onClick={handleClick}  {...rest}>{icon} {text}</button>
  )
}

export default Button