import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import {
  clearDroidDcaError,
  setDroidDcaError,
} from "features/ErrorDcaDroid/ErrorSlice";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const CostAverageDroidChild = ({
  droidDataDispatch,
  getbidValue,
  minBalance,
  minAmount,
}) => {
  const [getError, setError] = useState({ name: "", error: "" });
  const [getErrorbaseOrderSizeAmount, setErrorbaseOrderSizeAmount] = useState({
    error: "",
    isValid: false,
  });
  const [getErrororderSizeAmount, setErrororderSizeAmount] = useState({
    error: "",
    isValid: false,
  });
  const [getErrorpriceDeviation, setErrorpriceDeviation] = useState({
    error: "",
    isValid: false,
  });
  const [getErrormaxOrderCount, setErrormaxOrderCount] = useState({
    error: "",
    isValid: false,
  });
  const { currencyData, closeDeal } = useSelector(
    (state) => state?.defaultState
  );
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const valueOfState = useSelector((state) => state?.errorDroidSlice);
  const dispatch = useDispatch();

  const handleChangeStopLoss = (event) => {
    const { name, value, checked } = event.target;
    const twoDecimalPointValidation = /^\d*\.?\d{0,2}$/;

    if (value === "on") {
      if (checked === false && name === "isStopLoss") {
        droidDataDispatch({
          stopLoss: {
            ...getDroidsDataInfo?.stopLoss,
            [name]: checked,
            stopLoss: "",
            stopLossAction: "",
          },
        });
      } else {
        droidDataDispatch({
          stopLoss: { ...getDroidsDataInfo?.stopLoss, [name]: checked },
        });
      }
    } else if (!twoDecimalPointValidation.test(value)) {
      setError({
        name: name,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
    } else {
      const floatValue = parseFloat(value);
      if (floatValue < 0 || floatValue > 100) {
        setError({
          name: name,
          error: "Please input a value between 0.01 and 100.",
        });
      } else if (name === "stopLoss" && value == "00") {
        setError({ name: name, error: "Only zero value is not allowed" });
      } else if (value.startsWith(".")) {
        setError({ name: name, error: "Value cannot start with a dot" });
      } else if (name === "stopLoss" && value.length > 5) {
        setError({
          name: name,
          error:
            "Please input only numbers with a maximum of two decimal places.",
        });
      } else {
        droidDataDispatch({
          stopLoss: { ...getDroidsDataInfo?.stopLoss, [name]: value },
        });
      }
    }
  };

  function calculateLoss() {
    let count = 0;
    let value =
      getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit"
        ? getDroidsDataInfo?.dcaStrategy?.limit
        : getbidValue;
    let totalLoss =
      value - (value * getDroidsDataInfo?.stopLoss?.stopLoss) / 100;
    let lossCurrent = value;
    if (totalLoss == 0) {
      return 0;
    } else {
      while (totalLoss < lossCurrent) {
        if (getDroidsDataInfo?.safetyNetParameters?.priceDeviation === 0) {
          return;
        } else {
          lossCurrent =
            lossCurrent -
            (lossCurrent *
              getDroidsDataInfo?.safetyNetParameters?.priceDeviation) /
              100;
        }
        count++;
      }
      return count;
    }
  }
  const count = calculateLoss();
  const handleChangeSafetyNetParameters = (event) => {
    const { name, value } = event.target;
    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    const disallowedValue = 0;
    if (name === "maxOrderCount") {
      const baseOrderAmount = Number(
        getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount
      );
      const orderSize = Number(
        getDroidsDataInfo?.safetyNetParameters?.orderSizeAmount
      );
      const integerValidation = /^\d+$/;
      if (value !== "" && !integerValidation.test(value)) {
        dispatch(setDroidDcaError({ key: "maxOrderCount" }));
        setErrormaxOrderCount({
          isValid: true,
          error: "Values with decimal are not allowed",
        });
        droidDataDispatch({
          safetyNetParameters: {
            ...getDroidsDataInfo?.safetyNetParameters,
            [name]: "",
          },
        });
        return;
      }
      if (orderSize * value + baseOrderAmount > minBalance) {
        dispatch(setDroidDcaError({ key: "maxOrderCount" }));
        if (value > 100) {
          setErrormaxOrderCount({
            isValid: true,
            error: `Please input a value that is less than or equal to 100${
              name === "maxOrderCount" ? "" : "%"
            }.`,
          });
          return;
        } else {
          setErrormaxOrderCount({
            isValid: true,
            error: `Insufficient funds available balance is  $${Number(
              minBalance
            )?.toFixed(2)}.`,
          });
        }
        droidDataDispatch({
          safetyNetParameters: {
            ...getDroidsDataInfo?.safetyNetParameters,
            [name]: value,
          },
        });
        return;
      }
    }
    if (value === "" || value == 0) {
      setError({
        name: name,
        error: `This field must not remain ${value === "" ? "empty" : 0}.`,
      });
      droidDataDispatch({
        safetyNetParameters: {
          ...getDroidsDataInfo?.safetyNetParameters,
          [name]: "",
        },
      });
      if (name === "orderSizeAmount") {
        droidDataDispatch({
          safetyNetParameters: {
            ...getDroidsDataInfo?.safetyNetParameters,
            [name]: value,
          },
        });
      }
    } else if (minAmount >= value && name !== "maxOrderCount") {
      setError({
        name: name,
        error: `Please input value greater than ${minAmount}`,
      });
      droidDataDispatch({
        safetyNetParameters: {
          ...getDroidsDataInfo?.safetyNetParameters,
          [name]: value,
        },
      });
      if (name === "orderSizeAmount") {
        setErrororderSizeAmount({
          isValid: true,
          error: `Please input value greater than ${minAmount}`,
        });
        dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
        droidDataDispatch({
          safetyNetParameters: {
            ...getDroidsDataInfo?.safetyNetParameters,
            [name]: value,
          },
        });
      }
      // orderSizeAmount
    }
    //  else if (value>= minBalance?.toFixed(4) - +getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount) {
    //   setError({ name: name, error: `Please verify the maximum entered amount ${ minBalance?.toFixed(4) - +getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount}` });
    //   droidDataDispatch({safetyNetParameters: { ...getDroidsDataInfo?.safetyNetParameters, [name]: value } })
    // }
    else if (
      value >
        Number(minBalance)?.toFixed(2) -
          +getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount &&
      name === "orderSizeAmount"
    ) {
      if (name === "orderSizeAmount") {
        if (value?.length > 8) {
          return;
        } else {
          if (
            getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount &&
            getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount < minBalance
          ) {
            setErrororderSizeAmount({
              isValid: true,
              error: `Insufficient funds available balance is  $${(
                Number(minBalance) -
                Number(getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount)
              ).toFixed(2)}.`,
            });
          } else if (
            getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount > minBalance
          ) {
            setErrororderSizeAmount({
              isValid: true,
              error: `Insufficient funds available balance is  $${Number(
                minBalance
              ).toFixed(2)}`,
            });
          } else {
            setErrororderSizeAmount({
              isValid: true,
              error: `Please provide base order amount first.`,
            });
          }
          dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
          droidDataDispatch({
            safetyNetParameters: {
              ...getDroidsDataInfo?.safetyNetParameters,
              [name]: value,
            },
          });
        }
      }
    } else if (!decimalRegex.test(value)) {
      if (name === "orderSizeAmount") {
        setErrororderSizeAmount({
          isValid: true,
          error:
            "Please input only numbers with a maximum of two decimal places.",
        });
        dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
      }
      setError({
        name: name,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
    } else {
      if (
        (name === "maxOrderCount" ||
          name === "maxActiveOrdersCount" ||
          name === "orderStepScale" ||
          (name === "orderSizeAmount" &&
            getDroidsDataInfo?.safetyNetParameters?.orderSizeType ===
              "percent")) &&
        value > 100
      ) {
        if (name === "orderSizeAmount") {
          setErrororderSizeAmount({
            isValid: true,
            error: `Please input a value that is less than or equal to 100${
              name === "maxOrderCount" ? "" : "%"
            }.`,
          });
          dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
        }
        if (name === "maxOrderCount") {
          setErrormaxOrderCount({
            isValid: true,
            error: `Please input a value that is less than or equal to 100${
              name === "maxOrderCount" ? "" : "%"
            }.`,
          });
          dispatch(setDroidDcaError({ key: "maxOrderCount" }));
        }
        setError({
          name: name,
          error: `Please input a value that is less than or equal to 100${
            name === "maxOrderCount" ? "" : "%"
          }.`,
        });
      } else if (
        (name === "orderVolumes" || name === "priceDeviation") &&
        value > 10
      ) {
        if (name === "priceDeviation") {
          setErrorpriceDeviation({ isValid: true, error: "Enter 0.1% - 10%" });
          dispatch(setDroidDcaError({ key: "priceDeviation" }));
        }
        setError({ name: name, error: "Enter 0.1% - 10%" });
      } else if (
        name === "orderSizeAmount" &&
        value.length > 8 &&
        getDroidsDataInfo?.safetyNetParameters?.orderSizeType === "dollar"
      ) {
        if (name === "orderSizeAmount") {
          setErrororderSizeAmount({
            isValid: true,
            error: "The value should not exceed 8 digits.",
          });
          dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
        }
        setError({
          name: name,
          error: "The value should not exceed 8 digits.",
        });
      } else if (
        name === "orderSizeAmount" &&
        parseFloat(value) === disallowedValue
      ) {
        if (name === "orderSizeAmount") {
          setErrororderSizeAmount({
            isValid: true,
            error: "Only zero value is not allowed",
          });
          dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
        }
        setError({ name: name, error: "Only zero value is not allowed" });
      } else {
        setError({ name: "", error: "" });
        if (decimalRegex.test(value) || value === "") {
          if (
            name === "maxOrderCount" &&
            value > count - 1 &&
            getDroidsDataInfo?.stopLoss?.isStopLoss
          ) {
            if (count <= 1) {
              setError({
                name: name,
                error: `Stop loss value should be greater than Price deviation`,
              });
              droidDataDispatch({
                safetyNetParameters: {
                  ...getDroidsDataInfo?.safetyNetParameters,
                  [name]: value,
                },
              });
            } else {
              setError({
                name: name,
                error: `Enter a value that is less than or equal to ${
                  count - 1
                }`,
              });
              droidDataDispatch({
                safetyNetParameters: {
                  ...getDroidsDataInfo?.safetyNetParameters,
                  [name]: value,
                },
              });
            }
          } else {
            setError({ name: "", error: `` });
            setErrororderSizeAmount({ isValid: false, error: `` });
            dispatch(clearDroidDcaError({ key: "orderSizeAmount" }));
            setErrorpriceDeviation({ isValid: false, error: "" });
            dispatch(clearDroidDcaError({ key: "priceDeviation" }));
            setErrormaxOrderCount({ isValid: false, error: "" });
            dispatch(clearDroidDcaError({ key: "maxOrderCount" }));
            droidDataDispatch({
              safetyNetParameters: {
                ...getDroidsDataInfo?.safetyNetParameters,
                [name]: value,
              },
            });
          }
        }
      }
    }
  };
  const handleChangeBaseOrderSize = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "value");
    const onlyNumberRegex = /^\d*\.?\d{0,2}$/;

    setError({ name: "", error: "" });

    if (!onlyNumberRegex.test(value)) {
      if (name === "baseOrderSizeAmount") {
        setErrorbaseOrderSizeAmount({
          error: `Please input only numbers with a maximum of two decimal places.`,
          isValid: true,
        });
      }
      setError({
        name: name,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
      dispatch(setDroidDcaError({ key: "baseOrderSizeAmount" }));
    }

    // else if (getbidValue < value) {
    //   console.log(value, getbidValue, "value");
    //   setError({
    //     name: name,
    //     error: "Please enter a value less than or equal to Coin Current Price",
    //   });
    // }
    else if (minAmount >= value) {
      if (name === "baseOrderSizeAmount") {
        setErrorbaseOrderSizeAmount({
          error: `Please input value greater than ${minAmount}`,
          isValid: true,
        });
        dispatch(setDroidDcaError({ key: "baseOrderSizeAmount" }));
      } else {
        if (name !== "limit") {
          setError({
            name: name,
            error: `Please input value greater than ${minAmount}`,
          });
        }
      }
      droidDataDispatch({
        dcaStrategy: { ...getDroidsDataInfo?.dcaStrategy, [name]: value },
      });
    } else if (value?.length > 1 && value == 0) {
      setError({ name: name, error: "Value must be greater than 0." });
    } else if (value && value?.length > 8) {
      setError({
        name: name,
        error: "The value should not exceed 8 digits",
      });
    } else if (Number(minBalance) == 0) {
      if (name === "baseOrderSizeAmount") {
        setErrorbaseOrderSizeAmount({
          error: `Please choose a portfolio.`,
          isValid: true,
        });
        dispatch(setDroidDcaError({ key: "baseOrderSizeAmount" }));
      }
    } else if (
      value > Number(minBalance) &&
      getDroidsDataInfo?.dcaStrategy?.baseOrderSizeType === "dollar"
    ) {
      if (name === "baseOrderSizeAmount") {
        if (value.length > 8) {
          setErrorbaseOrderSizeAmount({
            error: `The value should not exceed 8 digits.`,
            isValid: true,
          });
          dispatch(setDroidDcaError({ key: "baseOrderSizeAmount" }));
          return;
        } else {
          setErrorbaseOrderSizeAmount({
            error: `Insufficient funds available balance is  $${Number(
              minBalance
            )?.toFixed(2)}.`,
            isValid: true,
          });
          dispatch(setDroidDcaError({ key: "baseOrderSizeAmount" }));
        }
      }
      if (name === "orderSizeAmount") {
        setErrororderSizeAmount({
          isValid: true,
          error: `Insufficient funds available balance is  $${Number(
            minBalance
          )?.toFixed(2)}.`,
        });
        dispatch(setDroidDcaError({ key: "orderSizeAmount" }));
        droidDataDispatch({
          safetyNetParameters: {
            ...getDroidsDataInfo?.safetyNetParameters,
            [name]: value,
          },
        });
      }
      droidDataDispatch({
        dcaStrategy: { ...getDroidsDataInfo?.dcaStrategy, [name]: value },
      });
    } else if (value.startsWith(".")) {
      console.log(value, getbidValue, "value");
      setError({ name: name, error: "Value cannot start with a dot..." });
    } else {
      if (value?.length > 8) {
        setError({
          name: name,
          error: "The value should not exceed 8 digits.",
        });
      } else if (
        value > 100 &&
        getDroidsDataInfo?.dcaStrategy?.baseOrderSizeType === "percent"
      ) {
        if (name === "baseOrderSizeAmount") {
          setErrorbaseOrderSizeAmount({
            error: `Please input a value that is less than or equal to 100%.`,
            isValid: true,
          });
          dispatch(setDroidDcaError({ key: "baseOrderSizeAmount" }));
        }
      } else {
        setError({ name: "", error: "" });
        setErrorbaseOrderSizeAmount({ error: ``, isValid: false });
        dispatch(clearDroidDcaError({ key: "baseOrderSizeAmount" }));
        droidDataDispatch({
          dcaStrategy: { ...getDroidsDataInfo?.dcaStrategy, [name]: value },
        });
      }
    }
  };

  const handleChangedcaStrategy = (event, id) => {
    if (id === "dcaStrategy") {
      droidDataDispatch({
        dcaStrategy: { ...getDroidsDataInfo?.dcaStrategy, dcaStrategy: event },
      });
    } else if (id === "profitInCurrency") {
      droidDataDispatch({
        dcaStrategy: {
          ...getDroidsDataInfo?.dcaStrategy,
          profitInCurrency: event,
        },
      });
    } else if (id === "startOrderType") {
      console.log(id, event, "event");
      droidDataDispatch({
        dcaStrategy: {
          ...getDroidsDataInfo?.dcaStrategy,
          startOrderType: event,
        },
      });
    }
    setError({ name: "", error: `` });
  };

  const PurchaseType = (item, id) => {
    if (id === "baseOrderSizeType") {
      droidDataDispatch({
        dcaStrategy: {
          ...getDroidsDataInfo?.dcaStrategy,
          baseOrderSizeAmount: "",
          [id]: item?.name,
        },
      });
    } else if (id === "orderSizeType") {
      droidDataDispatch({
        safetyNetParameters: {
          ...getDroidsDataInfo?.safetyNetParameters,
          orderSizeAmount: "",
          [id]: item?.name,
        },
      });
    } else if (id === "limitType") {
      droidDataDispatch({
        safetyNetParameters: {
          ...getDroidsDataInfo?.safetyNetParameters,
          limit: "",
          [id]: item?.name,
        },
      });
    }
    setError({ name: "", error: "" });
  };

  const handleSliderChange = (event) => {
    const { name, value } = event.target;
    if (getDroidsDataInfo?.stopLoss?.isStopLoss) {
      droidDataDispatch({
        safetyNetParameters: {
          ...getDroidsDataInfo?.safetyNetParameters,
          maxOrderCount: "",
          [name]: parseFloat(value),
        },
      });
    } else {
      const maxOrderCountValue =
        value == 0 ? "" : getDroidsDataInfo?.safetyNetParameters?.maxOrderCount;
      droidDataDispatch({
        safetyNetParameters: {
          ...getDroidsDataInfo?.safetyNetParameters,
          maxOrderCount: maxOrderCountValue,
          [name]: parseFloat(value),
        },
      });
    }
    setError({ name: "", error: `` });
  };

  const isDisable =
    !getDroidsDataInfo || // Check if getDroidsDataInfo is null or undefined first
    !getDroidsDataInfo.coinsList?.length || // No coins available
    !Object.prototype.hasOwnProperty.call(
      getDroidsDataInfo.safetyNetParameters || {}, // Check if safetyNetParameters is null or undefined
      "priceDeviation"
    ) || // if there is no priceDeviation key in JSON
    getDroidsDataInfo.safetyNetParameters?.priceDeviation === 0 || // if priceDeviation value is 0
    (getDroidsDataInfo.dcaStrategy?.startOrderType === "limit" && // DCA strategy is limit
      !getDroidsDataInfo.dcaStrategy.limit); // Limit is not defined

  return (
    <>
      <h4 className="mb-4">Strategy</h4>
      <div className="row">
        <div className="col-lg-6">
          <h6 className="mb-2">Profit in Currency</h6>
          <div
            className={`DMS-Container-Buttons-container-Usd d-flex mb-3 cursor-pointer`}
          >
            {[{ label: "USD", value: "USD" }].map((option) => (
              <div
                key={option.value}
                className={`DMS-Container-Buttons ${
                  getDroidsDataInfo?.dcaStrategy?.profitInCurrency ===
                  option.value
                    ? "DMS-Container-Buttons-active"
                    : ""
                }`}
                onClick={() =>
                  handleChangedcaStrategy(option.value, "profitInCurrency")
                }
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-6">
          <h6 className="mb-2">Start Order Type</h6>
          <div
            className={`DMS-Container-Buttons-container d-flex mb-3 cursor-pointer`}
          >
            {[
              { label: "Market", value: "market" },
              { label: "Limit", value: "limit" },
            ].map((option) => (
              <div
                key={option.value}
                className={`DMS-Container-Buttons ${
                  getDroidsDataInfo?.dcaStrategy?.startOrderType ===
                  option.value
                    ? "DMS-Container-Buttons-active"
                    : ""
                }`}
                onClick={() =>
                  handleChangedcaStrategy(option.value, "startOrderType")
                }
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-3">
            <div className="d-flex gap-2 align-items-center">
              <div className="d-flex align-items-center">
                <h6>
                  Base Order{" "}
                  {currencyData?.find(
                    (obj) =>
                      obj.name ===
                      getDroidsDataInfo?.dcaStrategy?.baseOrderSizeType
                  )?.label === "$"
                    ? "Amount"
                    : "Percentage"}
                </h6>
                {!getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount ? (
                  <p className="required-field">*</p>
                ) : null}
              </div>
              <TooltipQuestionMark
                toolTipContent={
                  "The Purchase Order is the first order the bot will create when starting a new deal."
                }
              />
            </div>
            <div className="d-flex half-input gap-2">
              <div
                className={`btn-group simpledropdown MT-5 small-dropdown-height`}
              >
                <button
                  className="btn dropdown-toggle d-flex justify-content-between align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  aria-expanded="false"
                >
                  <div>
                    {
                      currencyData?.find(
                        (obj) =>
                          obj.name ===
                          getDroidsDataInfo?.dcaStrategy?.baseOrderSizeType
                      )?.label
                    }
                  </div>
                  <img
                    src={require("assets/arrowDown.svg").default}
                    alt=""
                    className="ML-6"
                  />
                </button>
                <ul className="dropdown-menu">
                  {currencyData?.map((item, index) => (
                    <li
                      key={index}
                      className={`dropdown-item py-3 justify-content-between d-flex ${
                        currencyData?.length - 1 == index ? "" : "border-bottom"
                      }`}
                      onClick={() => PurchaseType(item, "baseOrderSizeType")}
                    >
                      {item?.label}{" "}
                      {getDroidsDataInfo?.dcaStrategy?.baseOrderSizeType ===
                      item?.name ? (
                        <img
                          src={require("assets/rightMark.svg").default}
                          alt=""
                          loading="lazy"
                        />
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <input
                type="text"
                disabled={!minAmount}
                value={
                  !getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount
                    ? ""
                    : getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount
                }
                name="baseOrderSizeAmount"
                onChange={handleChangeBaseOrderSize}
                className="small-input "
                placeholder={
                  currencyData?.find(
                    (obj) =>
                      obj.name ===
                      getDroidsDataInfo?.dcaStrategy?.baseOrderSizeType
                  )?.label === "$"
                    ? "Amount"
                    : "Percentage"
                }
              />
            </div>
            {getErrorbaseOrderSizeAmount?.isValid === true && (
              <div className="text-danger">
                ⚠️ {getErrorbaseOrderSizeAmount?.error}
              </div>
            )}
          </div>
          {getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit" ? (
            <div className="col-lg-6 mt-3">
              <div className="d-flex gap-2 align-items-center">
                <div className="d-flex align-items-center">
                  <h6>Threshold</h6>
                  {!getDroidsDataInfo?.dcaStrategy?.limit ? (
                    <p className="required-field">*</p>
                  ) : null}
                </div>
                <TooltipQuestionMark
                  toolTipContent={
                    "The Purchase Order is the first order the bot will create when starting a new deal."
                  }
                />
              </div>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={`btn-group simpledropdown w-25 small-dropdown-height MT-2`}
                >
                  <button
                    className="btn dropdown-toggle d-flex justify-content-center align-items-center droid-dropdown-height"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <div>
                      {
                        currencyData?.find(
                          (obj) =>
                            obj.name ===
                            getDroidsDataInfo?.dcaStrategy?.limitType
                        )?.label
                      }
                    </div>{" "}
                  </button>
                </div>
                <input
                  type="text"
                  value={
                    !getDroidsDataInfo?.dcaStrategy?.limit
                      ? ""
                      : getDroidsDataInfo?.dcaStrategy?.limit
                  }
                  name="limit"
                  onChange={handleChangeBaseOrderSize}
                  className=""
                  placeholder="Amount"
                />
              </div>
              {getError?.name == "limit" && (
                <div className="text-danger">⚠️ {getError?.error}</div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="border-bottom mt-4 mb-4"></div>
      {/* <div className='d-flex mb-4 mt-4 gap-3'>
        <h4>Stop Loss </h4>
        <label className="switch"> <input type="checkbox" name='isStopLoss' checked={getDroidsDataInfo?.stopLoss?.isStopLoss} onChange={handleChangeStopLoss} /> <span className="slider round"></span></label>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='d-flex gap-2 align-items-center '>
            <div className='d-flex align-items-center'>
              <h6 className=''>Stop Loss %</h6>
              {getDroidsDataInfo?.stopLoss?.isStopLoss && !getDroidsDataInfo?.stopLoss?.stopLoss ? <p className='required-field'>*</p> : null}
            </div>
            <TooltipQuestionMark toolTipContent={'This is the percentage that price needs to move in the opposite direction to your take profit target, at which point the bot will execute a Market Order on the exchange account to close the deal for a smaller loss than keeping the deal open. Please note, the Stop Loss is calculated from the price the initial Base Order was filled at on the exchange account and not the Dollar Cost Average price.'} />
          </div>
          <div className='d-flex position-relative MT-2 flex-column'>
            <input type="text" disabled={!getDroidsDataInfo?.stopLoss?.isStopLoss} value={!getDroidsDataInfo?.stopLoss?.stopLoss ? "" : getDroidsDataInfo?.stopLoss?.stopLoss} name='stopLoss' onChange={handleChangeStopLoss} placeholder='0' className=' pe-4' />
            <h5 className='position-absolute percentage-input'>%</h5>
            {getError?.name == 'stopLoss' && <div className={`text-danger ${getError?.error && 'mt-1 mx-1'}`}>⚠️ {getError?.error}</div>}
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='d-flex gap-2 align-items-center mb-2'>
            <h6>Stop Loss Action</h6>
            {getDroidsDataInfo?.stopLoss?.isStopLoss && !getDroidsDataInfo?.stopLoss?.stopLossAction ? <p className='required-field'>*</p> : null}
            <TooltipQuestionMark toolTipContent={'This is the action the bot should perform if it closed a deal with the Stop Loss setting, for example, if you are trading highly volatile assets with this bot, you may wish the bot to disable itself to prevent further trades being created until you review the market conditions and re-enable the bot manually or by an automated signal.Please note, this setting will only take effect on the next deal the bot opens after the bot settings have been saved. If the bot has already started active deals before this setting was altered, you will need to edit the individual trade on the "My Deals" page.'} />
          </div>
          <div className={`btn-group simpledropdown webkit-fill-available small-dropdown-height`}>
            <button disabled={!getDroidsDataInfo?.stopLoss?.isStopLoss} className="btn dropdown-toggle d-flex justify-content-between align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
              <div className={`${getDroidsDataInfo?.stopLoss?.stopLossAction === '' ? 'p-Three' : ""}`}>{getDroidsDataInfo?.stopLoss?.stopLossAction ? getDroidsDataInfo?.stopLoss?.stopLossAction : "Choose Action"}</div>
              <img src={require('assets/arrowDown.svg').default} alt='' className='ML-6' loading='lazy'/>
            </button>
            <ul className="dropdown-menu w_inherit">
              {closeDeal?.map((item, index) => <li key={index} className={`dropdown-item py-3 justify-content-between d-flex ${closeDeal?.length - 1 == index ? '' : 'border-bottom'}`} onClick={() => droidDataDispatch({ stopLoss: { ...getDroidsDataInfo?.stopLoss, stopLossAction: item?.label } })}>
                {item?.label} {getDroidsDataInfo?.stopLoss?.stopLossAction == item?.label ? <img src={require('assets/rightMark.svg').default} alt='' loading='lazy'/> : ''}</li>)}
            </ul>
          </div>
        </div>
      </div>
      <div className='d-flex mb-4 mt-4 gap-3 align-items-center'>
        <h6>Stop Loss Timeout </h6>
        <label className="switch"> <input type="checkbox" name='stopLossTimeout' checked={getDroidsDataInfo?.stopLoss?.stopLossTimeout} onChange={handleChangeStopLoss} /> <span className="slider round"></span></label>
      </div> */}

      {/* <div className='border-bottom mb-4 mt-4'></div> */}
      <h4 className="mb-4">SafetyNet Parameters</h4>
      <div className="col-lg-12 mb-3">
        <div className="d-flex gap-2 mb-2 align-items-center">
          <div className="d-flex align-items-center">
            <h6 className="">Order Size</h6>
            {!getDroidsDataInfo?.safetyNetParameters?.orderSizeAmount ? (
              <p className="required-field">*</p>
            ) : null}
          </div>
          <TooltipQuestionMark
            toolTipContent={
              "Enter the amount of funds your SafetyNet orders will use to average the cost of the asset being traded. This can help your bot to close deals faster with more profit. Safety orders are also known as Dollar Cost Averaging and help when prices move in the opposite direction to your bots take profit target."
            }
          />
        </div>
        <div className="d-flex gap-2">
          <div
            className={`my-2 btn-group simpledropdown small-dropdown-height`}
          >
            <button
              className="btn dropdown-toggle d-flex justify-content-between align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
            >
              <div>
                {
                  currencyData?.find(
                    (obj) =>
                      obj.name ===
                      getDroidsDataInfo?.safetyNetParameters?.orderSizeType
                  )?.label
                }
              </div>
              <img
                src={require("assets/arrowDown.svg").default}
                alt=""
                className="ML-6"
                loading="lazy"
              />
            </button>
            <ul className="dropdown-menu">
              {currencyData?.map((item, index) => (
                <li
                  key={index}
                  className={`dropdown-item py-3 justify-content-between d-flex ${
                    currencyData?.length - 1 == index ? "" : "border-bottom"
                  }`}
                  onClick={() => PurchaseType(item, "orderSizeType")}
                >
                  {item?.label}{" "}
                  {getDroidsDataInfo?.safetyNetParameters?.orderSizeType ==
                  item?.name ? (
                    <img
                      src={require("assets/rightMark.svg").default}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          </div>
          <input
            type="text"
            className="small-input"
            value={
              getDroidsDataInfo?.safetyNetParameters?.orderSizeAmount ===
              undefined
                ? ""
                : getDroidsDataInfo?.safetyNetParameters?.orderSizeAmount
            }
            name="orderSizeAmount"
            onChange={handleChangeSafetyNetParameters}
            placeholder={
              getDroidsDataInfo?.safetyNetParameters?.orderSizeType === "dollar"
                ? "Amount"
                : "Percent"
            }
          />
        </div>
        {getErrororderSizeAmount?.isValid === true && (
          <div className="text-danger">⚠️ {getErrororderSizeAmount?.error}</div>
        )}
        {}
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex gap-2 align-items-center mb-2">
            <div className="d-flex align-items-center">
              <h6>Price Deviation</h6>
              {!getDroidsDataInfo?.safetyNetParameters?.priceDeviation ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark
              toolTipContent={
                "Enter the percentage difference in price to create the first SafetyNet Order. All Safety Orders are calculated from the price the initial Base Order was filled on the exchange account. 0.2% - 10%"
              }
            />
            {getDroidsDataInfo?.safetyNetParameters?.priceDeviation || 0} %
          </div>
          <div>
            <div className="d-flex w-100 align-items-center mt-3">
              <input
                type="range"
                className="form-range h-100 rangePosition"
                id="customRange1"
                name="priceDeviation"
                min={0}
                max={10}
                step={0.5}
                value={
                  getDroidsDataInfo?.safetyNetParameters?.priceDeviation || 0
                }
                onChange={handleSliderChange}
              />
            </div>
            {getErrorpriceDeviation?.isValid === true && (
              <div className="text-danger">
                ⚠️ {getErrorpriceDeviation?.error}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex gap-2 align-items-center">
            <div className="d-flex align-items-center">
              <h6>Max Orders Count</h6>
              {!getDroidsDataInfo?.safetyNetParameters?.maxOrderCount ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark toolTipContent={"0-100"} />
          </div>
          <input
            type="text"
            style={{ cursor: isDisable ? "not-allowed" : "auto" }}
            disabled={isDisable}
            value={
              !getDroidsDataInfo?.safetyNetParameters?.maxOrderCount
                ? ""
                : getDroidsDataInfo?.safetyNetParameters?.maxOrderCount
            }
            name="maxOrderCount"
            onChange={handleChangeSafetyNetParameters}
            placeholder="Value"
          />
          {}
          {getErrormaxOrderCount?.isValid === true && (
            <div className="text-danger">⚠️ {getErrormaxOrderCount?.error}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CostAverageDroidChild;
