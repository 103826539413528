import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
const CircularProgressBar = ({ progress, strokeWidth, size, imageSrc }) => {
  const [offset, setOffset] = useState(0);

  const center = size / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [progress, circumference]);

  return (
    <div className="circular-progress-container">
      <svg className="circular-progress" width={size} height={size}>
        <circle className="circular-progress-background" cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
        <circle className="circular-progress-bar" cx={center} cy={center} r={radius} strokeWidth={strokeWidth} strokeDasharray={circumference} strokeDashoffset={offset} />
      </svg>
      <div className="image-container">
        <img src={imageSrc} alt="" className="center-image profile_img" loading='lazy'/>
      </div>
    </div>
  );
};
export default CircularProgressBar;
