import React, { useEffect } from 'react';
import ProgressBar from 'components/Charts/ProgressBar';
import Loader from "components/Loader";
import DottedChart from 'components/Charts/DottedChart';
import { useDispatch, useSelector } from 'react-redux';
import CardShadow from '../CardShadow'
import '../../scss/supportScreen.scss';
import ExchangeConnect from './ExchangeConnect';
import ExchangeDetail from './ExchangeDetail';
import { getExchangeList } from 'features/Exchange/ExchangeAction'
import { useState } from 'react';
import { CheckIcon } from 'assets';

function Exchange() {
  const dispatch = useDispatch()
  const { userToken } = useSelector((state) => state.auth);
  const getDroid = useSelector((state) => state);

  const { getExchangeInfo, isExchangeInfo } = useSelector((state) => state?.Exchange)
  const [exchanges, setExchanges] = useState([] || getExchangeInfo);
  const [getLoading, setLoading] = useState(true)
  const [select, setSelect] = useState(null)
  const [getConnect, setConnect] = useState(true)
  const [selectExchangeDetails, setSelectExchangeDetail] = useState()
  const [getExchangeSelected, setExchangeSelected] = useState([])

  const fetchData = async () => {
    const res = await dispatch(getExchangeList({ userToken }));
    if (res.meta && res.meta.requestStatus === "fulfilled") {
      setExchanges(res.payload);
      const desiredExchange = res.payload?.filter(exchange => exchange.isIntegrated === true)
      setExchangeSelected(desiredExchange)
      setTimeout(() => { localStorage.removeItem('connectExchange') }, 10000)
      if (select !== null) {
        setExchangeSelected([res.payload[select]])
      }
      setLoading(false)
      setConnect(false)
    }else{
      setLoading(false)
      setConnect(false)
    }
  };
  useEffect(() => {
    if (getConnect) {
      fetchData();
    }
  }, [getConnect]);

  const handleExchangecard = (item, i) => {
    setSelect(i)
    setSelectExchangeDetail(item)
  }
  const makerPercentage = 11;
  const takerPercentage = 20;

  return (
    <div className='container-fluid exchange_card'>
      <div className='row mt-3 '>
        <div className='col-3 div-1200'> <ExchangeDetail {...selectExchangeDetails} /> </div>
        <div className='col-5 div-1200 exchange-container '>
          <CardShadow className='exchangeCardContainer'>
            {getExchangeInfo?.length > 0 ? (getExchangeInfo?.length > 0 ? getExchangeInfo : exchanges)?.map((item, i) => <div onClick={() => handleExchangecard(item, i)}>
              <div className={`d-flex align-items-center justify-content-between ExchangeChart cursor ${(getExchangeSelected?.some(exchange => exchange._id === item?._id) || selectExchangeDetails?._id === item?._id) || item?.isIntegrated ? 'selectedExchange mb-1' : ''}`} key={i} onClick={() => handleExchangecard(item, i)}>
                <div className='d-flex align-items-center justify-content-start '>
                  <div className='exchangeIcons'>
                    <img src={item?.icon} alt={item?.name} width={'auto'} height={20} loading='lazy'/>
                  </div>
                  <div>
                    <ProgressBar icon={item?.icon} makerPercentage={makerPercentage * item?.fees?.makerFeeRatePercent / 100} />
                    <DottedChart icon={item?.icon} takerPercentage={takerPercentage * item?.fees?.takerFeeRatePercent / 100} />
                  </div>
                </div>
                {item?.isIntegrated ? <CheckIcon /> : ''}
              </div>
            </div>
            ) : getLoading ? <Loader /> : <div className='d-flex justify-content-center'>Data Not Found</div>}
          </CardShadow>
        </div>
        <div className='col-4 div-1200'>
          <ExchangeConnect {...selectExchangeDetails} setConnect={setConnect} />
        </div>
      </div>
    </div>
  )
}

export default Exchange