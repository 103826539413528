import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import * as Sentry from "@sentry/react";

import {
  ArrowRightIcon,
  BTC,
  ETH,
  Tether,
  Cardano,
  LTC,
  XRP,
  SOL,
  BAT,
  EURO,
  USD,
} from "assets";
import UNKC from "assets/unkCoin.png";
import ConverNumber from "components/ConvertNumber";
import { topCoinList } from "features/Dashboard/dashboardAction";

const TopClientAssetsComponent = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const { topCoinListInfo } = useSelector((state) => state.dashboard);
  const [topCoinListData, setTopCoinListData] = useState(topCoinListInfo);

  const filteredTopCoinListData =
    (topCoinListData &&
      topCoinListData.filter((item) => item?.balance > 0.001)) ||
    [];
  const TopClientAssetsData = [
    {
      coin: "BTC",
      image: <BTC text={"#F7931A"} wd={"40"} ht={"40"} />,
      label: "BTC",
    },
    {
      coin: "ETH",
      image: <ETH text={"#393939"} wd={"40"} ht={"40"} />,
      label: "ETH",
    },
    {
      coin: "XRP",
      image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
      label: "XRP",
    },
    {
      coin: "LTC",
      image: <LTC text2={"#F8F9FB"} text={"#D9D9D9"} wd={"40"} ht={"40"} />,
      label: "LTC",
    },
    {
      coin: "SOL",
      image: (
        <SOL text={"url(#paint0_linear_1601_37368)"} wd={"40"} ht={"40"} />
      ),
      label: "SOL",
    },
    {
      coin: "USDT",
      image: <Tether text={"#50AF95"} wd={"40"} ht={"40"} />,
      label: "USDT",
    },
    {
      coin: "ADA",
      image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
      label: "ADA",
    },
    {
      coin: "BAT",
      image: (
        <div className="coinHeightWeight">
          <BAT
            text={"url(#paint0_linear_1601_37368)"}
            wd={"20"}
            ht={"20"}
            className="mb-5"
          />
        </div>
      ),
      label: "BAT",
    },
    {
      coin: "EUR",
      image: (
        <div className="coinHeightWeight">
          <EURO wd={"25"} ht={"25"} />
        </div>
      ),
      label: "EUR",
    },
    {
      coin: "GBP",
      image: (
        <div className="UNKcoinHeightWeight">
          <img
            src={UNKC}
            className="UNKcoinHeightWeight"
            loading="lazy"
            alt=""
          />
        </div>
      ),
      label: "GBP",
    },
    {
      coin: "USD",
      image: (
        <div className="coinHeightWeight">
          <USD wd={"20"} ht={"20"} />
        </div>
      ),
      label: "USD",
    },
    {
      coin: "USDCkkk",
      image: (
        <div className="coinHeightWeight">
          <USD wd={"20"} ht={"20"} />
        </div>
      ),
      label: "USDC",
    },
    {
      coin: "LINK",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img
            src={UNKC}
            className="UNKcoinHeightWeight"
            loading="lazy"
            alt=""
          />
        </div>
      ),
      label: "LINK",
    },
    {
      coin: "ZEC",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img
            src={UNKC}
            className="UNKcoinHeightWeight"
            loading="lazy"
            alt=""
          />
        </div>
      ),
      label: "ZEC",
    },
    {
      coin: "BCH",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img
            src={UNKC}
            className="UNKcoinHeightWeight"
            loading="lazy"
            alt=""
          />
        </div>
      ),
      label: "BCH",
    },
    {
      coin: "UNK",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img
            src={UNKC}
            className="UNKcoinHeightWeight"
            loading="lazy"
            alt=""
          />
        </div>
      ),
      label: "",
    },
  ];

  const handleNavigate = (item) => {
    if (item?.url) {
      window.open(item?.url, "_blank");
    }
  };

  useEffect(() => {
    dispatch(topCoinList({ userToken }))
      .then((res) => {
        if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
          setTopCoinListData(res?.payload);
        } else {
          Sentry.captureException(res, {
            extra: {
              customData: "TopClientAssetsComponent--> Line 197",
            },
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "TopClientAssetsComponent--> Line 203",
          },
        });
      });
  }, []);

  return (
    <div className={`my-4 top_client_assets`}>
      <h3>
        {" "}
        Top Client Assets{" "}
        <small className="h5-Three">
          {!topCoinListData ? 0 : filteredTopCoinListData?.length} Profile
        </small>{" "}
      </h3>
      <div
        className={`topClientAssetsCard graph mt-3 p-4 overflow-auto d-flex gap-4 ${
          topCoinListData
            ? "justify-content-around"
            : filteredTopCoinListData?.length > 0
            ? "justify-content-between"
            : "justify-content-center align-items-center "
        }`}
      >
        {topCoinListData ? (
          topCoinListData?.map(
            (item, index) =>
              item?.balance > 0.001 && (
                <div
                  className="tooltip-container"
                  key={index}
                  onClick={() => handleNavigate(item)}
                >
                  <div className="cursor">
                    {
                      TopClientAssetsData?.find((entry) => entry.coin === "UNK")
                        ?.image
                    }
                    <h5 className="my-3 text-nowrap d-flex align-items-center  justify-content-center">
                      <ConverNumber data={item?.balance} digit={2} />
                    </h5>
                    <p className="d-flex align-items-center  justify-content-center">
                      {item?.currency.toUpperCase()}
                    </p>
                  </div>
                  <div className="custom-tooltip-topClientAsset">
                    <div className="d-flex">
                      {item?.currency} market chart{" "}
                      <div className="ML-6 MB-1">
                        <ArrowRightIcon color={"#0078E7"} />{" "}
                      </div>{" "}
                    </div>
                    <div>{item?.balance?.toFixed(2)} </div>
                  </div>
                </div>
              )
          )
        ) : (
          <Loader />
        )}
        {!topCoinListData
          ? ""
          : filteredTopCoinListData?.length > 0
          ? ""
          : "Data Not Found"}
      </div>
    </div>
  );
};

export default React.memo(TopClientAssetsComponent);
