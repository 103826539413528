import { ArrowRightIcon } from "assets";
import React from "react";

const UniversalTooltip = ({
  icon = <ArrowRightIcon color={"#0078E7"} />,
  content,
  className,
  tooltipRef,
}) => {
  return (
    <small
      ref={tooltipRef}
      tabIndex={-1}
      className={`universal-tooltip-child ${className}`}
    >
      {content}
      {/* <div className="ML-6 MB-1">{icon}</div> */}
    </small>
  );
};

export default UniversalTooltip;
