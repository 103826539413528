import React from "react";

function starIcon({width=12,height=12, color='#0078E7'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        d="M5.055 1.354c.312-.895 1.578-.895 1.89 0l.707 2.037a1 1 0 00.925.671l2.155.044c.948.02 1.34 1.224.584 1.797L9.598 7.205a1 1 0 00-.353 1.087l.624 2.063c.275.908-.75 1.652-1.528 1.11l-1.77-1.23a1 1 0 00-1.142 0l-1.77 1.23c-.778.542-1.803-.202-1.528-1.11l.624-2.063a1 1 0 00-.353-1.087L.684 5.903C-.07 5.33.32 4.125 1.268 4.106l2.155-.044a1 1 0 00.925-.671l.707-2.037z"
      ></path>
    </svg>
  );
}

export default starIcon;
