import React, { useEffect, useState, memo, useRef } from "react";
import uploadImages from "assets/commonAvtar.svg";
import companyDirector from "assets/companydirector.svg";
import License from "assets/license.svg";
import { Address, DeleteIcon, StarIcon, WarningIcon } from "assets";
import Phone from "assets/phone.svg";
import Email from "assets/email.svg";
import { Rate } from "antd";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  userProfile,
  userUpdateProfile,
  updateRiskLimits,
  updatePortfolioRisk,
} from "features/profile/profileAction";
import { userTypeTrader } from "features/typeTrader/typeTraderAction";
import SocialMedia from "./SocialMedia";
import SetupAssistent from "../DashboardScreenHome/SetupAssistent";
import Button from "components/Button";
import ChangePassword from "components/ChangePassword";
import {
  getReviewsData,
  deleteReviewsData,
} from "features/Community/communityAction";
import ExchangeProfile from "./ExchangeProfile";
import { STICKY_STATE } from "./../../globalStore/GlobalStore";
import { useHookstate } from "@hookstate/core";
import PopupModal from "components/PopupModal";
import SecondryButton from "components/Button/SecondryButton";
import ConverNumber from "components/ConvertNumber";
import Logout from "./Logout";
import ProfileDescription from "./ProfileDescription";
import ConvetNumber2Digit from "components/ConvertNumber/ConvetNumber2Digit";
import ProfileUpload from "./ProfileUpload";
import ProfileSocialNetworks from "components/ProfileDetailsComponent/ProfileSocialNetworks";
import ProfileLimitComponent from "components/ProfileDetailsComponent/ProfileLimitComponent";
import KnownSocialUser from "assets/Badges/knownSocialUser.png";
import BestUser from "assets/Badges/BestUser.png";
import CryptoGoal from "assets/Badges/CryptoGoal.png";
import InvestmentDoneRetailer from "assets/Badges/$10InvestmentDone.png";
import DroidsAchievementBroker from "assets/Badges/DroidsAchievementBroker.png";
import StarReviewRetailer from "assets/Badges/StarReviewRetailer.png";
import MegaStart from "assets/Badges/MegaStar.png";
import Invester from "assets/Badges/Invester.png";
import CustomerManager from "assets/Badges/CustomerManager.png";
import BestService from "assets/Badges/BestService.png";

const TimeZone = (item) => {
  let currentDate = new Date().toISOString();
  let reviewTime = new Date(item?.updatedAt).toISOString();
  let d = new Date(currentDate) - new Date(reviewTime);
  let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
  let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
  let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
  let minutes = Math.floor(
    d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
  );
  let seconds = Math.floor(
    d / 1000 -
      weekdays * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60
  );
  let milliseconds = Math.floor(
    d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000
  );
  let t = {};
  ["weekdays", "days", "hours", "minutes", "seconds", "milliseconds"].forEach(
    (q) => {
      if (eval(q) > 0) {
        t[q] = eval(q);
      }
    }
  );

  if (t["weekdays"] != undefined) {
    return `${t["weekdays"]} weeks ago`;
  } else if (t["hours"] != undefined) {
    return `${t["hours"]} hours ago`;
  } else if (t["minutes"] != undefined) {
    return `${t["minutes"]} minutes ago`;
  } else if (t["seconds"] != undefined) {
    return `Now`;
  }
};

const SocialMediaData = [
  { id: "linkedIn", placeholder: "http://www.linkedin.com/in/custom" },
  { id: "facebook", placeholder: "http://www.facebook.com/in/custom" },
  { id: "microsoft", placeholder: "http://www.microsoft.com/in/custom" },
  { id: "twitter", placeholder: "http://www.twitter.com/in/custom" },
  { id: "youTube", placeholder: "http://www.youtube.com/in/custom" },
  { id: "tiktok", placeholder: "http://www.tiktok.com/in/custom" },
  { id: "metaVerse", placeholder: "http://www.monster.com/in/custom" },
];

const userRisk = {
  low: "Low",
  medium: "Medium",
  high: "High",
};

const Profile = () => {
  const { userProfileInfo } = useSelector((state) => state?.profile);
  const [hideAssistent, setHideAssistent] = useState(false);
  const [closeAssistent, setCloseAssistent] = useState(true);
  const [edit, setEdit] = useState(false);
  const [getLoadingProfile, setLoadingProfile] = useState(false);
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState(null || userProfileInfo);
  const [traderTypes, setTraderTypes] = useState([]);
  const [getReview, setReview] = useState();
  const isSticky = useHookstate(STICKY_STATE);
  const { userToken } = useSelector((state) => state.auth);
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [showFullCompanyNumber, setShowFullCompanyNumber] = useState(false);
  const [showFullCompanyDirector, setShowFullCompanyDirector] = useState(false);
  const [showFullLicense, setShowFullLicense] = useState(false);
  const [showFullPhone, setShowFullPhone] = useState(false);
  const [showFullEmail, setShowFullEmail] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [getPersonalGoals, setPersonalGoals] = useState(
    userProfileInfo?.personal_goals
      ? userProfileInfo?.personal_goals
      : { targetClients: "", targetAveragePortfolioRevenue: "" }
  );
  const [getImage, setImage] = useState();
  let inputRef = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [riskSet, setRiskSet] = useState("");
  const [getRisk, setRisk] = useState({ buy: "", sell: "" });
  const [sendPortfolioRisk, setsendPortfolioRisk] = useState({
    portfolioId: profileData?.porfolio?._id,
    risk: "",
  });

  const [getError, setError] = useState({ name: "", status: false });
  const [getCancle, setCancle] = useState("");
  const [riskSave, setRiskSave] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [deleteReview, setDeleteReview] = useState({ id: "", name: "" });
  useEffect(() => {
    dispatch(userProfile(userToken)).then((response) => {
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        setProfileData(response?.payload);
        setRisk(
          response?.payload?.userPreference &&
            response?.payload?.userPreference[0]?.limits !== undefined &&
            response?.payload?.userPreference[0]?.limits
        );
        setRiskSet(
          response?.payload?.userPreference &&
            response?.payload?.userPreference[0]?.risk !== undefined
            ? response?.payload?.userPreference &&
                response?.payload?.userPreference[0]?.risk
            : ""
        );
        setPersonalGoals(response?.payload?.personal_goals);
        setError({ name: "", status: false });
      }
    });
    dispatch(userTypeTrader(userToken)).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setTraderTypes(res.payload);
      }
    });
  }, [profileLoading]);

  const handleEdit = () => {
    setEdit(true);
    setCloseAssistent(false);
    setCancle("");
  };
  const handleBack = () => {
    setProfileData(userProfileInfo);
    setPersonalGoals();
    setRisk(
      profileData?.userPreference && profileData?.userPreference[0]?.limits
    );
    setEdit(false);
    setCloseAssistent(true);
    setCancle("cancle");
    setImage(undefined);
    inputRef = null;
  };

  var ProfileId = userProfileInfo?._id;

  useEffect(() => {
    if (ProfileId) {
      dispatch(getReviewsData({ userToken, ProfileId })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setReview(res.payload);
        }
      });
    }
  }, [ProfileId, deleteLoading]);

  const DeleteReview = () => {
    setDeleteLoading(true);
    const reviewID = deleteReview?.id;
    dispatch(deleteReviewsData({ userToken, reviewID })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setDeleteLoading(false);
        setDeleteReview({ id: "", name: "" });
      }
    });
  };
  function removeNestedObjects(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        removeNestedObjects(obj[key]);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach(removeNestedObjects);
      }
    }
  }

  removeNestedObjects(profileData);

  const SaveProfile = () => {
    const sendRiskLimits = { risk: riskSet, limits: getRisk };
    setCancle("save");
    setRiskSave("save");
    const sendData = {
      ...profileData,
      full_name: profileData?.full_name,
      targetAveragePortfolioRevenue:
        getPersonalGoals?.targetAveragePortfolioRevenue,
      targetClients: getPersonalGoals?.targetClients,
    };
    delete sendData?.personal_goals;
    delete sendData?.type_company;
    const formData = new FormData();
    Object.keys(sendData).forEach((key) => {
      if (
        key === "company" ||
        key === "email" ||
        key === "otp" ||
        key === "personal_goals" ||
        key === "phone" ||
        key === "social_networks"
      ) {
        Object.keys(sendData[key]).forEach((item) => {
          if (item !== "address") {
            if (sendData[key][item] == null) {
              formData.append(item, "");
            } else {
              formData.append(item, sendData[key][item]);
            }
          }
        });
      } else {
        if (sendData[key] == null) {
          formData.append(key, "");
        } else {
          formData.append(key, sendData[key]);
        }
      }
    });
    setProfileLoading(true);
    const promises = [
      dispatch(userUpdateProfile({ userToken, formData })),
      dispatch(updateRiskLimits({ userToken, sendRiskLimits })),
      dispatch(updatePortfolioRisk({ userToken, sendPortfolioRisk })),
    ];

    setLoadingProfile(true);

    Promise.all(promises).then((responses) => {
      responses.forEach((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setProfileData(response.payload);
          setProfileData();
        }
      });
      setProfileLoading(false);
      setProfileData();
      setLoadingProfile(false);
      setEdit(false);
      setCloseAssistent(true);
      localStorage.setItem("reload", true);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevObject) => ({ ...prevObject, [name]: value }));
  };
  const [getErrorRisk, setErrorRisk] = useState();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const regex = /^(?!0)\d*(\.\d{0,2})?$/;
    const numericValue = parseFloat(value);

    if (value === "" || (regex.test(value) && numericValue < 1000000000000)) {
      setRisk((prevObject) => ({ ...prevObject, [name]: value }));
      setErrorRisk({});
    } else {
      let errorMessage;
      if (!regex.test(value)) {
        errorMessage = "Enter a valid number with up to two decimal places";
      } else {
        errorMessage = "Enter a number below 1000000000000";
      }
      setErrorRisk({ name: name, text: errorMessage });
    }
  };

  function calculateNearestNumber(number) {
    let nearest;
    if (number < 100) {
      nearest = 1000;
    } else if (101 < number < 1000) {
      nearest = 10000;
    } else if (1001 < number < 10000) {
      nearest = 100000;
    } else if (10001 < number < 100000) {
      nearest = 1000000;
    } else {
      return nearest;
    }
    return nearest;
  }
  const [getGoalError, setGoalError] = useState({ submit: false });

  const restrictToNumbers = (event) => {
    if (
      !/[0-9]/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault();
    }
  };

  const preventPeriodAtStart = (event) => {
    const { key, target } = event;
    const { value } = target;

    // Allow navigation keys and other control keys
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
    ];

    if (allowedKeys.includes(key)) {
      return; // Allow these keys
    }

    // Prevent period at start
    console.log(key, "key", value);
    if ((key === "." || key === "0") && value <= 0) {
      event.preventDefault();
    }
  };

  const ClickGoals = (event) => {
    const { name, value } = event.target;
    const regex = /^\d*(\.\d{0,2})?$/;
    console.log("name", name, value);

    if (value == 0) {
      setGoalError({ [name]: true, text: `Do Not Enter 0` });
      setPersonalGoals((prevObject) => ({ ...prevObject, [name]: "" }));
    } else if (regex.test(value)) {
      setPersonalGoals((prevObject) => ({ ...prevObject, [name]: value }));
      if (
        name === "targetAveragePortfolioRevenue" &&
        parseFloat(value) <
          parseFloat(userProfileInfo?.averagePortfoliosRevenue)
      ) {
        setGoalError({
          [name]: true,
          text: `Enter value ${userProfileInfo?.averagePortfoliosRevenue} or more than`,
          submit: true,
        });
      } else if (
        parseFloat(value) < parseFloat(userProfileInfo?.totalClients) &&
        name === "targetClients"
      ) {
        setGoalError({
          [name]: true,
          text: `Enter value ${userProfileInfo?.totalClients} or more than`,
          submit: true,
        });
      } else {
        if (value?.toString()?.length > 9) {
          setGoalError({
            [name]: true,
            text: `Max Avg. Portfolio Revenue Length 10 Digit`,
          });
        } else {
          setGoalError({ submit: false });
        }
      }
    }
  };

  const enterName = (e) => {
    const { name, value } = e.target;
    setProfileData((prevObject) => ({
      ...prevObject,
      [name]: value.replace(/^\s+/, ""),
    }));
  };

  const nearestNumber = calculateNearestNumber(
    getRisk?.buy > getRisk?.sell ? getRisk?.buy : getRisk?.sell
  );

  const buyPercentage = (getRisk?.buy / nearestNumber) * 100;
  const sellPercentage = (getRisk?.sell / nearestNumber) * 100;
  const riskDataNew = [];

  useEffect(() => {
    const riskDataNew11 = [];
    riskDataNew11.push(sendPortfolioRisk);
  }, [sendPortfolioRisk]);
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      setUploadedImage(event.target.files[0]);
      setImage(URL.createObjectURL(file));
    }
  };

  const CancleFile = () => {
    setUploadedImage(null);
    getImage && setImage(undefined);
  };

  const handleUpload = () => {
    if (uploadedImage) {
      let imageToBeUploaded = uploadedImage;
      setProfileData((prevObject) => ({
        ...prevObject,
        image: imageToBeUploaded,
      }));
      setUploadedImage(null);
    }
  };
  const addDetails = (e) => {
    const { name, value } = e.target;

    const regex = /^\d*(\.\d{0,2})?$/;

    if (name === "company_number") {
      if (value?.length > 20) {
        setError({ name: name, status: true, text: "Max Length 20" });
      } else if (!regex.test(value)) {
        setError({ name: name, status: true, text: "Enter only Number" });
      } else {
        setProfileData((prevObject) => ({
          ...prevObject,
          company: { ...prevObject?.company, [name]: e.target.value },
        }));
        setError({ name: "", status: false });
      }
    } else if (name === "address") {
      if (value?.length > 100) {
        setError({ name: name, status: true });
      } else {
        setProfileData((prevObject) => ({ ...prevObject, [name]: value }));
        setError({ name: "", status: false });
      }
    }
  };

  const handleSocialMediaClick = (socialMediaId) => {
    const url = profileData?.social_networks[socialMediaId];

    if (edit) {
    } else {
      if (url) {
        window.open(url, "_blank");
      }
    }
  };

  const BadgeIconsBroker = [
    { icon: BestUser, label: "Best Broker" },
    { icon: KnownSocialUser, label: " Social Broker Influencer" },
    { icon: CryptoGoal, label: "Crypto Goal Broker" },
    { icon: DroidsAchievementBroker, label: "10 Droids achievement" },
    { icon: DroidsAchievementBroker, label: "100 Droids achievement" },
    { icon: DroidsAchievementBroker, label: "500 Droids achievement" },
    { icon: DroidsAchievementBroker, label: "1,000 Droids achievement" },
    { icon: DroidsAchievementBroker, label: "5,000 Droids achievement" },
    { icon: CustomerManager, label: "50+ Customer Manager" },
    { icon: CustomerManager, label: "500+ Customer Manager" },
    { icon: CustomerManager, label: "5000+ Customer Manager" },
    { icon: CustomerManager, label: "25,000+ Customer Manager" },
    { icon: BestService, label: "2022 Best Service from Customers" },
  ];
  const BadgeIconsRetailer = [
    { icon: BestUser, label: "Best User" },
    { icon: KnownSocialUser, label: "Known Social User" },
    { icon: CryptoGoal, label: "Crypto Goal User" },
    { icon: InvestmentDoneRetailer, label: "$10 Investment done" },
    { icon: InvestmentDoneRetailer, label: "$1,000 Investment done" },
    { icon: InvestmentDoneRetailer, label: "$10,000 Investment done" },
    { icon: InvestmentDoneRetailer, label: "$25,000 Investment done" },
    { icon: InvestmentDoneRetailer, label: "$100,00 Investment done" },
    { icon: StarReviewRetailer, label: "3 Star Review from another user" },
    { icon: MegaStart, label: "Mega Star review from Broker" },
    { icon: Invester, label: "$1 Million Gold Investor" },
    { icon: Invester, label: "$10 Million Diamond Investor" },
    { icon: Invester, label: "$100 Million Platinum Investor" },
  ];

  return (
    <>
      {!profileData ? (
        <div className="mainProfileLoader ">
          {" "}
          <div className="loaderProfile" role="status">
            {" "}
          </div>{" "}
        </div>
      ) : (
        <>
          <div className="profile-container-main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 profile_scrolling">
                  <div
                    className={
                      isSticky.get()
                        ? "userProfileIcon assistLinkCard profile-sticky"
                        : "userProfileIcon assistLinkCard"
                    }
                  >
                    <div className="d-flex justify-content-center position-relative">
                      <img
                        src={
                          getImage === undefined
                            ? profileData?.image === null
                              ? uploadImages
                              : profileData?.image
                            : getImage
                        }
                        alt=""
                        className={` rounded-circle curser-pointer avtar_image`}
                        loading="lazy"
                      />
                    </div>
                    {edit && (
                      <h5
                        className="mt-3 text-primary d-flex justify-content-center"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#imageUplode"
                      >
                        {" "}
                        Upload Picture{" "}
                      </h5>
                    )}

                    <div className="p-2 text-center">
                      {edit ? (
                        <>
                          <input
                            placeholder="Enter Name"
                            type="text"
                            className="input-edit edit-input2 mb-3"
                            maxlength="30"
                            defaultValue={
                              profileData?.full_name !== null &&
                              profileData?.full_name
                            }
                            name="full_name"
                            onChange={(e) => enterName(e)}
                          />
                          {profileData?.full_name !== null &&
                            profileData?.full_name?.length < 1 && (
                              <div className="text-start text-validation-danger">
                                Name is required
                              </div>
                            )}
                        </>
                      ) : (
                        <h1
                          className="text-break"
                          title={
                            profileData?.full_name &&
                            profileData?.full_name.length > 20
                              ? profileData?.full_name
                              : null
                          }
                        >
                          {profileData?.full_name !== null &&
                          profileData?.full_name.length > 20
                            ? profileData?.full_name.substr(0, 20) + "..."
                            : profileData?.full_name}
                        </h1>
                      )}
                      <span className="subTitle">
                        {" "}
                        {profileData?.company?.company_name === "null"
                          ? "Company name"
                          : profileData?.company?.company_name}
                      </span>
                      <div className="border-bottom mt-3"></div>
                    </div>

                    <div className="profileTextarea mt-2">
                      <h5 className="mb-1">Description</h5>
                      {edit ? (
                        <div className="mb-4">
                          <textarea
                            maxlength="200"
                            value={profileData?.description}
                            className="form-control border-0"
                            name=""
                            id=""
                            cols="48"
                            rows="3"
                            placeholder="Description"
                            onChange={(e) =>
                              setProfileData((prevObject) => ({
                                ...prevObject,
                                description: e.target.value.replace(/^\s+/, ""),
                              }))
                            }
                          />
                          <span className="float-right">
                            {profileData?.description?.length}/200
                          </span>
                        </div>
                      ) : (
                        <>
                          <ul>
                            {" "}
                            <li className="text-break">
                              {(profileData && profileData?.description
                                ? profileData?.description
                                : "No description added"
                              ).substr(0, 200)}{" "}
                            </li>
                          </ul>
                          {profileData?.description?.length > 199 && (
                            <h5
                              className="text-primary"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#moreInformation"
                            >
                              More Information
                            </h5>
                          )}
                        </>
                      )}
                      <div className="border-bottom mt-3"></div>
                    </div>

                    {localStorage.getItem("role") === "broker" && (
                      <div className="mt-3">
                        <h5 className="mb-3">Type of trader</h5>
                        {edit && profileData?.type_trader === null ? (
                          <div className="text-start text-validation-danger mb-4">
                            Type of trader is required
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="gap-3 d-flex">
                          {traderTypes?.map((type, index) => {
                            return edit ? (
                              <span
                                key={index}
                                tooltip={type?.description}
                                className={`${
                                  type?._id == profileData?.type_trader &&
                                  "badgesList2"
                                } `}
                                flow="down"
                                onClick={() => {
                                  setProfileData((prevObject) => ({
                                    ...prevObject,
                                    type_trader: type._id,
                                  }));
                                }}
                              >
                                {type.title}
                              </span>
                            ) : !profileData?.type_trader ? (
                              <span tooltip="" flow="down">
                                {type.title}
                              </span>
                            ) : (
                              type._id === profileData?.type_trader && (
                                <span
                                  className="badgesList2"
                                  tooltip={type?.description}
                                  flow="down"
                                  onClick={() => {
                                    setProfileData((prevObject) => ({
                                      ...prevObject,
                                      type_trader: type._id,
                                    }));
                                  }}
                                >
                                  {type.title}
                                </span>
                              )
                            );
                          })}
                        </div>
                        <hr className="mt-4" />
                      </div>
                    )}
                    <div className="mt-3">
                      <h5 className="">Social networks</h5>
                      <div className="socialContainer d-flex justify-content-between align-items-center mt-2 ">
                        {SocialMediaData?.map((item, index) => (
                          <div
                            type="button"
                            key={index}
                            data-bs-toggle="modal"
                            data-bs-target={`#${item?.id}`}
                            className="d-flex py-3 justify-content-between safari"
                            onClick={() => handleSocialMediaClick(item?.id)}
                          >
                            <img
                              src={require(`assets/socialMedia/${item?.id}_${
                                profileData?.social_networks[item?.id] ===
                                  null ||
                                profileData?.social_networks[item?.id] === ""
                                  ? "none"
                                  : "fill"
                              }.svg`)}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        ))}
                      </div>
                      <div className="border-bottom mt-3"></div>
                    </div>
                    {profileData?.badges?.length > 0 ? (
                      <div className="mt-3" style={{ overflowX: "scroll" }}>
                        <h5 className="badgess mb-3">Badges</h5>
                        <div
                          className="d-flex gap-3"
                          style={{ width: "max-content" }}
                        >
                          <span className="badgesList">
                            Level {profileData?.level || 0}
                          </span>
                          {profileData?.badges?.map((item, index) => (
                            <>
                              {localStorage.getItem("role") === "retailer" ? (
                                <>
                                  {item?.role && (
                                    <span key={index} className="badgesList2">
                                      {BadgeIconsRetailer.map(
                                        (badge, badgeIndex) => {
                                          if (badge.label === item?.title) {
                                            return (
                                              <img
                                                key={badgeIndex}
                                                src={badge.icon}
                                                alt={badge.label}
                                                className="BadgeIconSize"
                                              />
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                      {item?.title}
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.role && (
                                    <span key={index} className="badgesList2">
                                      {BadgeIconsBroker.map(
                                        (badge, badgeIndex) => {
                                          if (badge.label === item?.title) {
                                            return (
                                              <img
                                                key={badgeIndex}
                                                src={badge.icon}
                                                alt={badge.label}
                                                className="BadgeIconSize"
                                              />
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                      {item?.title}
                                    </span>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                        </div>
                        <div className="border-bottom mt-3"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("role") === "broker" ? (
                      ""
                    ) : (
                      <>
                        {edit ? (
                          <div className="mt-3">
                            <h5 className="badgess mb-3">Risk</h5>
                            {riskSet === null ||
                            riskSet === undefined ||
                            riskSet == "" ? (
                              <div className="text-start text-validation-danger">
                                Risk is required
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="d-flex gap-3">
                              {Object.keys(userRisk).map((key) => {
                                return (
                                  <span
                                    className={`${
                                      riskSet === key
                                        ? "badgesList2 cursor"
                                        : "badgesList1"
                                    } `}
                                    onClick={(e) => setRiskSet(key)}
                                  >
                                    {userRisk[key]}
                                  </span>
                                );
                              })}
                            </div>
                            <div className="border-bottom mt-3"></div>
                          </div>
                        ) : (
                          <>
                            {" "}
                            {riskSet && (
                              <>
                                {" "}
                                <h5 className="badgess my-3">Risk</h5>{" "}
                                <span className="badgesList2 mt-3">
                                  {userRisk[riskSet]}
                                </span>{" "}
                                <div className="border-bottom mt-4"> </div>{" "}
                              </>
                            )}{" "}
                          </>
                        )}
                      </>
                    )}
                    {localStorage.getItem("role") === "broker" ? (
                      <div className="mt-3">
                        <h5 className="mb-3">Personal goals</h5>
                        <div className="mb-3">
                          <h6>Total Clients </h6>
                          {edit ? (
                            <>
                              <input
                                type="text"
                                maxLength={10}
                                value={getPersonalGoals?.targetClients}
                                name="targetClients"
                                onKeyDown={restrictToNumbers}
                                onChange={(e) => ClickGoals(e)}
                              />
                              <div className="text-validation-danger">
                                {getGoalError?.targetClients
                                  ? getGoalError?.text
                                  : ""}
                              </div>
                            </>
                          ) : (
                            <div className="d-flex mt-1 justify-content-between">
                              <div className="progress col-md-10">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${
                                      (profileData?.totalClients /
                                        profileData?.personal_goals
                                          ?.targetClients) *
                                      100
                                    }%`,
                                  }}
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                />
                              </div>
                              <span className="personelGoalsSpan col-md-2 text-end d-flex justify-content-end">
                                <ConvetNumber2Digit
                                  data={profileData?.totalClients}
                                />
                                /
                                <ConvetNumber2Digit
                                  data={
                                    profileData?.personal_goals?.targetClients
                                  }
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="personelGoalsRevenue d-flex justify-content align-items-center gap-1">
                          <h6>Avg. Portfolio Revenue </h6>
                          <p className="">30d</p>
                        </div>
                        {edit ? (
                          <>
                            <input
                              type="text"
                              maxLength={10}
                              name="targetAveragePortfolioRevenue"
                              onKeyDown={preventPeriodAtStart}
                              value={
                                getPersonalGoals?.targetAveragePortfolioRevenue
                              }
                              onChange={(e) => ClickGoals(e)}
                            />
                            <div className="text-validation-danger">
                              {" "}
                              {getGoalError?.targetAveragePortfolioRevenue
                                ? getGoalError?.text
                                : ""}
                            </div>
                          </>
                        ) : (
                          <div className="d-flex justify-content-between d-flex flex-row">
                            <div
                              className={`progress ${
                                profileData?.personal_goals?.targetAveragePortfolioRevenue?.toString()
                                  ?.length > 4
                                  ? "flex55"
                                  : "flex63"
                              }  col-md-10`}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${
                                    (profileData?.averagePortfoliosRevenue /
                                      profileData?.personal_goals
                                        ?.targetAveragePortfolioRevenue) *
                                    100
                                  }%`,
                                }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>

                            <span className="flex0 personelGoalsSpan col-md-2 text-end d-flex justify-content-end">
                              {profileData?.averagePortfoliosRevenue ===
                              "NaN" ? (
                                0
                              ) : (
                                <ConvetNumber2Digit
                                  data={profileData?.averagePortfoliosRevenue}
                                />
                              )}
                              /
                              <ConvetNumber2Digit
                                data={
                                  profileData?.personal_goals
                                    ?.targetAveragePortfolioRevenue
                                }
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mt-3 mb-5">
                        <div>
                          <h5>Limit $</h5>
                          <div className="helth_card mb-3 helth_card_hover_data risk_heading">
                            <h6 className="mt-2">Buy</h6>
                            {!edit ? (
                              <div
                                className="progress b-0 mt-3 webkit-fill-available"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow={buyPercentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar progress-bar-buy"
                                  style={{ width: `${buyPercentage}%` }}
                                ></div>
                              </div>
                            ) : (
                              <div className="w-100">
                                <input
                                  className="input-edit edit-input2"
                                  placeholder="$"
                                  name="buy"
                                  onKeyDown={preventPeriodAtStart}
                                  value={
                                    getRisk?.buy !== undefined
                                      ? getRisk?.buy
                                      : ""
                                  }
                                  onChange={handleInputChange}
                                />
                                {getErrorRisk?.name === "buy" ? (
                                  <div className="text-validation-danger">
                                    {getErrorRisk?.text}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {getRisk?.buy === "." ? (
                                  <div className="text-validation-danger">
                                    Enter Number
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                          <div className="helth_card mb-3 helth_card_hover_data risk_heading">
                            <h6 className="mt-2">Sell</h6>
                            {!edit ? (
                              <div className="d-grid webkit-fill-available">
                                <div
                                  className="progress b-0 mt-3 webkit-fill-available"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow={sellPercentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    className="progress-bar progress-bar-shell"
                                    style={{ width: `${sellPercentage}%` }}
                                  ></div>
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                  <p className="fs-10"></p>
                                  <p className="fs-10">
                                    <ConverNumber
                                      data={
                                        calculateNearestNumber(
                                          getRisk?.sell >= getRisk?.buy
                                            ? getRisk?.sell
                                            : getRisk?.buy
                                        ) * 0.25
                                      }
                                    />{" "}
                                  </p>
                                  <p className="fs-10">
                                    <ConverNumber
                                      data={
                                        calculateNearestNumber(
                                          getRisk?.sell >= getRisk?.buy
                                            ? getRisk?.sell
                                            : getRisk?.buy
                                        ) * 0.5
                                      }
                                    />{" "}
                                  </p>
                                  <p className="fs-10">
                                    <ConverNumber
                                      data={
                                        calculateNearestNumber(
                                          getRisk?.sell >= getRisk?.buy
                                            ? getRisk?.sell
                                            : getRisk?.buy
                                        ) * 0.75
                                      }
                                    />{" "}
                                  </p>
                                  <p className="fs-10">
                                    {" "}
                                    <ConverNumber
                                      data={calculateNearestNumber(
                                        getRisk?.sell >= getRisk?.buy
                                          ? getRisk?.sell
                                          : getRisk?.buy
                                      )}
                                    />{" "}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="w-100">
                                <input
                                  placeholder="$"
                                  onKeyDown={preventPeriodAtStart}
                                  className="input-edit edit-input2"
                                  name="sell"
                                  value={
                                    getRisk?.sell !== undefined
                                      ? getRisk?.sell
                                      : ""
                                  }
                                  onChange={handleInputChange}
                                />
                                {getErrorRisk?.name === "sell" ? (
                                  <div className="text-validation-danger">
                                    {getErrorRisk?.text}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {getRisk?.sell === "." ? (
                                  <div className="text-validation-danger">
                                    Enter Number
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-between align-item-center gap-3 mt-4">
                      {localStorage.getItem("role") === "broker" && (
                        <Button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#ChangePasswordPopup"
                          text="Change Password"
                        />
                      )}
                      {!edit && (
                        <Button
                          data-bs-toggle="modal"
                          data-bs-target="#logOut"
                          text="Logout"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 ps-4 profile_scrolling">
                  <div className=" ms-3 ">
                    <div className="row mt-4 d-flex justify-content ">
                      <div
                        className={`profile_inputs mb-3 d-flex align-item-center gap-2 ${
                          edit ? "heightfixEdit" : ""
                        } `}
                      >
                        <div className="align-items-start d-flex">
                          {" "}
                          <img
                            src={Email}
                            alt=""
                            className="company-details-icon"
                            loading="lazy"
                          />{" "}
                        </div>
                        <div>
                          <h5>Email</h5>{" "}
                          <span
                            className="detailsSpan lh-lg partial-address"
                            onMouseOver={() => {
                              setShowFullEmail(!showFullEmail);
                            }}
                            onMouseOut={() => {
                              setShowFullEmail(false);
                            }}
                          >
                            {" "}
                            {profileData?.email === undefined
                              ? "email"
                              : profileData?.email?.value}{" "}
                          </span>
                          {showFullEmail && profileData?.email?.value && (
                            <div className="full-address">
                              {profileData?.email?.value}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`profile_inputs mb-3 d-flex align-item-center gap-2 ${
                          edit ? "heightfixEdit" : ""
                        } `}
                      >
                        <div className="align-items-start d-flex">
                          {" "}
                          <div className="company-details-icon">
                            <Address />
                          </div>{" "}
                        </div>
                        <div>
                          {" "}
                          <h5>Address</h5>
                          {edit ? (
                            <input
                              type={"text"}
                              className="input-edit"
                              name="address"
                              value={profileData?.address}
                              onChange={(e) => {
                                addDetails(e);
                              }}
                            />
                          ) : (
                            <span
                              className="detailsSpan lh-lg partial-address"
                              onMouseOver={() => {
                                setShowFullAddress(!showFullAddress);
                              }}
                              onMouseOut={() => {
                                setShowFullAddress(false);
                              }}
                            >
                              {" "}
                              {profileData?.address === undefined ||
                              !(profileData?.address?.length > 0)
                                ? "address"
                                : profileData?.address?.length > 24
                                ? profileData?.address.substr(0, 25) + "..."
                                : profileData?.address}{" "}
                            </span>
                          )}
                          {edit && getError?.name === "address" ? (
                            <div className="text-validation-danger">
                              Max Length 100
                            </div>
                          ) : (
                            ""
                          )}
                          {showFullAddress &&
                            profileData?.address?.length > 0 && (
                              <div className="full-address">
                                {profileData?.address}
                              </div>
                            )}
                        </div>
                      </div>
                      {localStorage.getItem("role") === "broker" ? (
                        <div
                          className={`profile_inputs mb-2 d-flex align-item-center gap-2 ${
                            edit ? "heightfixEdit" : ""
                          } `}
                        >
                          <div className="align-items-start d-flex">
                            {" "}
                            <img
                              src={Phone}
                              alt=""
                              className="company-details-icon"
                              loading="lazy"
                            />{" "}
                          </div>
                          <div>
                            {" "}
                            <h5>Phone</h5>
                            <span
                              className="detailsSpan lh-lg partial-address"
                              onMouseOver={() => {
                                setShowFullPhone(!showFullPhone);
                              }}
                              onMouseOut={() => {
                                setShowFullPhone(false);
                              }}
                            >
                              {" "}
                              {profileData?.phone?.value === undefined ||
                              profileData?.phone?.value === null
                                ? "phone_number"
                                : profileData?.phone?.value}{" "}
                            </span>
                            {showFullPhone && profileData?.phone?.value && (
                              <div className="full-address">
                                {profileData?.phone?.value}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {localStorage.getItem("role") === "broker" && (
                        <>
                          <div
                            className={`profile_inputs mb-3 d-flex align-item-center gap-2 ${
                              edit ? "heightfixEdit" : ""
                            } `}
                          >
                            <div className="align-items-start d-flex">
                              {" "}
                              <img
                                src={
                                  require("assets/companynumber.svg").default
                                }
                                className="company-details-icon"
                                alt=""
                                loading="lazy"
                              />{" "}
                            </div>
                            <div>
                              {" "}
                              <h5>Company Number</h5>
                              {edit ? (
                                <input
                                  type={"text"}
                                  className="input-edit"
                                  name="company_number"
                                  value={profileData?.company?.company_number}
                                  onChange={(e) => addDetails(e)}
                                />
                              ) : (
                                <span
                                  className="detailsSpan lh-lg partial-address"
                                  onMouseOver={() => {
                                    setShowFullCompanyNumber(
                                      !showFullCompanyNumber
                                    );
                                  }}
                                  onMouseOut={() => {
                                    setShowFullCompanyNumber(false);
                                  }}
                                >
                                  {" "}
                                  {profileData?.company?.company_number ===
                                    undefined ||
                                  profileData?.company?.company_number ===
                                    null ||
                                  profileData?.company?.company_number === ""
                                    ? "company_number"
                                    : profileData?.company?.company_number == ""
                                    ? "company_number"
                                    : profileData?.company?.company_number}{" "}
                                </span>
                              )}
                              {getError?.name === "company_number" ? (
                                <div className="text-validation-danger">
                                  {getError?.text}
                                </div>
                              ) : (
                                ""
                              )}
                              {showFullCompanyNumber &&
                                profileData?.company.company_number && (
                                  <div className="full-address">
                                    {profileData?.company.company_number}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div
                            className={`profile_inputs mb-3 d-flex align-item-center gap-2 `}
                          >
                            <div className="align-items-start d-flex">
                              <img
                                src={companyDirector}
                                className="company-details-icon"
                                alt=""
                                loading="lazy"
                              />
                            </div>
                            <div>
                              <h5>Company Directors</h5>{" "}
                              <span
                                className="detailsSpan lh-lg partial-address"
                                onMouseOver={() => {
                                  setShowFullCompanyDirector(
                                    !showFullCompanyNumber
                                  );
                                }}
                                onMouseOut={() => {
                                  setShowFullCompanyDirector(false);
                                }}
                              >
                                {" "}
                                {profileData?.company === undefined ||
                                profileData?.company?.company_director === null
                                  ? "company_director"
                                  : profileData?.company?.company_director}{" "}
                              </span>
                              {showFullCompanyDirector &&
                                profileData?.company?.company_director && (
                                  <div className="full-address">
                                    {profileData?.company?.company_director}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div
                            className={`profile_inputs mb-3 d-flex align-item-center gap-2 `}
                          >
                            <div className="align-items-start d-flex">
                              <img
                                src={License}
                                alt=""
                                className="company-details-icon"
                                loading="lazy"
                              />
                            </div>
                            <div>
                              <h5>License</h5>{" "}
                              <span
                                className="detailsSpan lh-lg partial-address"
                                onMouseOver={() => {
                                  setShowFullLicense(!showFullLicense);
                                }}
                                onMouseOut={() => {
                                  setShowFullLicense(false);
                                }}
                              >
                                {" "}
                                {profileData?.license_number === undefined ||
                                profileData?.license_number === null ||
                                profileData?.license_number === ""
                                  ? "license"
                                  : profileData?.license_number == ""
                                  ? "license"
                                  : profileData?.license_number}{" "}
                              </span>
                              {showFullLicense &&
                              profileData?.license_number ? (
                                <div className="full-address">
                                  {profileData?.license_number}{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {/*------------------------------------------------------ Retailer's feature------------------------------------------------------------- */}
                      {localStorage.getItem("role") !== "retailer" ? (
                        ""
                      ) : (
                        <>
                          <div
                            className={`profile_inputs mb-3 d-flex align-item-center gap-2 ${
                              edit ? "heightfixEdit" : ""
                            } ${
                              localStorage.getItem("role") === "broker"
                                ? "mt-4"
                                : ""
                            }`}
                          >
                            <div className="align-items-start d-flex">
                              {" "}
                              <img
                                src={
                                  require("assets/companynumber.svg").default
                                }
                                alt=""
                                className="company-details-icon"
                                loading="lazy"
                              />{" "}
                            </div>
                            <div>
                              <div>
                                <h5>Broker name</h5>
                                {profileData?.connectedBroker?.length > 0 ? (
                                  <div className="dropdown">
                                    <span
                                      className="dropdown-toggle detailsSpan"
                                      type="button"
                                      id="dropdownMenu2"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {profileData?.connectedBroker[0]?.name}
                                    </span>
                                    <div
                                      className="dropdown-menu brokerNameDropdown dropdownScroll"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      {profileData?.connectedBroker.map(
                                        (item, index) => (
                                          <button
                                            className="dropdown-item "
                                            type="button"
                                            key={index}
                                          >
                                            {item?.name}
                                          </button>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <span>Broker not added</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={`profile_inputs mb-3 d-flex  gap-2 `}>
                            <div className="align-items-start d-flex">
                              {" "}
                              <img
                                src={companyDirector}
                                alt=""
                                className="company-details-icon"
                                loading="lazy"
                              />{" "}
                            </div>
                            <div>
                              <h5>Broker's Company</h5>
                              {profileData?.connectedBroker?.length > 0 ? (
                                <div className="dropdown ">
                                  <span
                                    className="dropdown-toggle detailsSpan"
                                    type="button"
                                    id="dropdownMenu3"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {profileData?.connectedBroker?.length > 0
                                      ? profileData?.connectedBroker[0]
                                          ?.company == null ||
                                        profileData?.connectedBroker[0]
                                          ?.company == "null"
                                        ? "company not added"
                                        : profileData?.connectedBroker[0]
                                            ?.company
                                      : "company not added"}
                                  </span>
                                  {profileData?.connectedBroker?.length > 0 ? (
                                    <div
                                      className="dropdown-menu brokerNameDropdown dropdownScroll"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      {profileData?.connectedBroker?.map(
                                        (item, index) => (
                                          <button
                                            className="dropdown-item "
                                            type="button"
                                            key={index}
                                          >
                                            {item?.name} -{" "}
                                            {item?.company === "null" ||
                                            !item?.company
                                              ? "Company not added"
                                              : item?.company}
                                          </button>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <span className="dropdown-toggle detailsSpan">
                                      {" "}
                                      company not added{" "}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <span>Company not added</span>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/*------------------------------------------------------ Broker's feature------------------------------------------------------------- */}
                  {localStorage.getItem("role") === "broker" ? (
                    <div className="row mt-5">
                      <div className="col-lg-6 ">
                        <div className=" align-item-center gap-5 justify-content-between">
                          <h3 className="mb-3">Trading Insights</h3>
                          <textarea
                            disabled={!edit}
                            className={`form-control w-100 p-4 bg-white p-One`}
                            rows="10"
                            value={profileData?.trading_insight}
                            name="trading_insight"
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="align-item-center gap-5 justify-content-between">
                          <h3 className="mb-3">Tip of the day</h3>
                          <textarea
                            disabled={!edit}
                            className={`form-control w-100 p-4 bg-white p-One`}
                            rows="10"
                            value={profileData?.day_input}
                            name="day_input"
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ExchangeProfile
                      setCancle={setCancle}
                      getCancle={getCancle}
                      riskSave={riskSave}
                      setRiskSave={setRiskSave}
                      riskDataNew={riskDataNew}
                      edit={edit}
                      profileData={profileData}
                    />
                  )}
                  {localStorage.getItem("role") === "broker" && (
                    <>
                      <div className="mt-5 d-flex align-item-center">
                        <h3>Reviews</h3>
                        <h5 className="mt-2 ms-2 text-muted">
                          {getReview?.numberOfReviews || 0}{" "}
                          {getReview?.numberOfReviews > 1
                            ? "Reviews"
                            : "Review"}
                        </h5>
                        <div className="d-flex justify-content align-items-center gap-1 mt-1 ms-2">
                          <StarIcon
                            color={
                              getReview?.averageReview > 0
                                ? "#0078E7"
                                : "rgb(240 240 240)"
                            }
                          />
                          <StarIcon
                            color={
                              getReview?.averageReview >= 2
                                ? "#0078E7"
                                : "rgb(240 240 240)"
                            }
                          />
                          <StarIcon
                            color={
                              getReview?.averageReview >= 3
                                ? "#0078E7"
                                : "rgb(240 240 240)"
                            }
                          />
                          <StarIcon
                            color={
                              getReview?.averageReview >= 4
                                ? "#0078E7"
                                : "rgb(240 240 240)"
                            }
                          />
                          <StarIcon
                            color={
                              getReview?.averageReview >= 5
                                ? "#0078E7"
                                : "rgb(240 240 240)"
                            }
                          />
                        </div>
                      </div>
                      <div className="border-bottom mt-3 "></div>

                      {getReview?.numberOfReviews > 0 ? (
                        <div className="mt-3">
                          {getReview?.response?.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  key={index}
                                >
                                  <div className="d-flex align-items-start">
                                    <img
                                      src={
                                        item?.reviewer_info[0]?.image
                                          ? item?.reviewer_info[0]?.image
                                          : require("assets/commonAvtar.svg")
                                              .default
                                      }
                                      alt=""
                                      className="brokerAvatar me-2"
                                      loading="lazy"
                                    />
                                    <div>
                                      <div className="d-flex gap-2 mt-2 align-items-baseline">
                                        <h3 className="">
                                          {item?.reviewer_info[0]?.full_name}
                                        </h3>
                                        <small className="p-Four d-flex">
                                          {TimeZone(item)}
                                        </small>
                                      </div>
                                      <div className="d-flex justify-content align-items-center gap-1 my-2">
                                        {[...Array(5)].map((e, i) => (
                                          <StarIcon
                                            color={
                                              item?.rating > i
                                                ? "#0078E7"
                                                : "rgb(240 240 240)"
                                            }
                                          />
                                        ))}
                                      </div>
                                      <small className=" text-black font-weight-bold text-break">
                                        "{item?.description}"
                                      </small>
                                    </div>
                                  </div>
                                  <div
                                    className={`${
                                      deleteLoading ? "" : "curser-pointer"
                                    }`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteReview"
                                    onClick={() => {
                                      deleteLoading
                                        ? setDeleteReview({ id: "", name: "" })
                                        : setDeleteReview({
                                            id: item?._id,
                                            name: item?.reviewer_info[0]
                                              ?.full_name,
                                          });
                                    }}
                                  >
                                    {" "}
                                    <DeleteIcon color="#000" />{" "}
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="mt-3">
                          {" "}
                          <p className="profileReview mx-4 review">
                            No Reviews Yet
                          </p>{" "}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="setUpAssist">
              {localStorage.getItem("role") !== "broker" && closeAssistent && (
                <SetupAssistent
                  data={profileData}
                  profile={true}
                  hide={hideAssistent}
                  setHide={setHideAssistent}
                  setClose={setCloseAssistent}
                />
              )}
              {profileData === null ? (
                ""
              ) : (
                <div className="text-end position-absolute edit-profile">
                  <div className="d-flex align-items-center justify-content-between">
                    {edit ? (
                      <div className="d-flex justify-content-between gap-3 ask-Qustion-container">
                        {!getLoadingProfile && (
                          <button
                            className="custom-button button-secondary"
                            onClick={handleBack}
                          >
                            Cancel
                          </button>
                        )}
                        <Button
                          text="Save"
                          title="Page will refresh automatically"
                          loading={getLoadingProfile}
                          handleClick={SaveProfile}
                          disabled={
                            (localStorage.getItem("role") === "retailer" &&
                              (getRisk?.sell === "." ||
                                getRisk?.buy === ".")) ||
                            (profileData?.full_name !== null &&
                            profileData?.full_name?.length > 1 &&
                            !getLoadingProfile
                              ? false
                              : true) ||
                            (profileData?.type_trader === null &&
                              localStorage.getItem("role") === "broker") ||
                            ((riskSet === null ||
                              riskSet === undefined ||
                              riskSet == "") &&
                              localStorage.getItem("role") === "retailer")
                          }
                        />
                      </div>
                    ) : (
                      <div
                        onClick={handleEdit}
                        className="ask-Qustion-container"
                      >
                        <Button
                          text="Edit Profile"
                          className=" "
                          icon={
                            <img
                              src={require("assets/EditPencilIcon.svg").default}
                              alt=""
                              loading="lazy"
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <PopupModal id={"deleteReview"} backdrop={false}>
            <div>
              <div className="modal-header border-0">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete Review{" "}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body ">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <WarningIcon />
                  <h5>Do you want delete {deleteReview?.name} review ?</h5>
                </div>
              </div>
              <div className="modal-footer border-0">
                <SecondryButton
                  text={"Cancel"}
                  type="button"
                  data-bs-dismiss="modal"
                  handleClick={() => setDeleteReview({ id: "", name: "" })}
                />
                <Button
                  text="Submit"
                  databsdismiss="modal"
                  handleClick={DeleteReview}
                />
              </div>
            </div>
          </PopupModal>

          <ProfileUpload
            CancleFile={CancleFile}
            inputRef={inputRef}
            handleClick={handleClick}
            uploadedImage={uploadedImage}
            getImage={getImage}
            handleUpload={handleUpload}
            handleFileChange={handleFileChange}
          />
          {edit &&
            SocialMediaData.map((item, index) => (
              <SocialMedia
                {...item}
                socialMediaDataURI={profileData == null ? [] : profileData}
                setSocialMediaDataURI={setProfileData}
              />
            ))}
          <ProfileDescription profileData={profileData} />
          <Logout userToken={userToken} />
          <ChangePassword />
        </>
      )}
    </>
  );
};
export default memo(Profile);
