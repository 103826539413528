import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'



const RepeatCounter = ({ number }) => {
  const { t } = useTranslation()
  const [linesToAdd, setLinesToAdd] = useState(4)

  return (
    <div className='d-grid gap-3'>
      <div className='d-flex justify-content-start'>
        <h5 className='me-2'>{t('safetyNetRepeat')}</h5>
        <h5 className='text-muted'>{number || 0}/5</h5>
      </div>

      <div className='d-flex gap-2'>
        {[...Array(5)].map((index, i) => <div className={number > i ? 'repeat-counter-line' : 'repeat-counter-line_deactive'}></div>)}
      </div>
    </div>
  )
}

export default RepeatCounter