const ScatterChartBottom = ({ RefreshIcon, RefreshTime }) => {
    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex gap-1 align-items-center text-dark">
                <span className="label-circle buy"></span> Buy
                <span className="label-circle sell ms-2"></span> Sell
            </div>
        </div>
    )
}

export default ScatterChartBottom