import React from 'react'

const ChartPercentage = ({Percent, PercentName}) => {
  return (
    <div className='d-flex flex-column justify-content-center'>
      <span className='h3'>{Percent}%</span>
<div>
<small className='h6 text-muted indexV1Allocated d-flex  justify-content-center '>{PercentName}</small>

  </div>    </div>
  )
}

export default ChartPercentage