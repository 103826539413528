import { WarningIcon } from 'assets'
import SecondryButton from 'components/Button/SecondryButton'
import PopupModal from 'components/PopupModal'
import React, { useState } from 'react'
import Button from "components/Button";
import { userLogout } from "features/auth/authActions";
import { useDispatch } from "react-redux";
import { logout } from "features/auth/authSlice";

const Logout = ({ userToken }) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    // function reloadeFunction() { window.location.reload() }
    const logOutHandler = () => {
        setLoading(true);
        dispatch(userLogout(userToken)).then((response) => {
            if (response.meta && response.meta.requestStatus === "fulfilled") {
                dispatch(logout());
                setLoading(false);
                // setTimeout(reloadeFunction, 2000)
            }
        });
    };
    return (
        <PopupModal id='logOut' backdrop={false} >
            <div className='popupReviewScreen m-3'>
                <div className="modal-header border-0">
                    <div className='addReviewCompanyLogo'>
                        <h3>Logout</h3>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <WarningIcon />
                        <h5>Are you sure you want to log out of your account?</h5>
                    </div>

                    <div className="d-flex gap-3 mt-4 justify-content-end">
                        <SecondryButton text="Cancel" databsdismiss="modal" />
                        <Button text="Logout" handleClick={logOutHandler} loading={loading} disabled={loading} />
                    </div>

                </div>
            </div>
        </PopupModal>
    )
}

export default Logout