import { createSlice } from '@reduxjs/toolkit'
import {DroidsData } from './DroidsStateAction'

const DroidsStateSlice = createSlice({
  name: 'droidData',
  initialState: { 
    getDroidsDataInfo: null,
  },
  reducers: {
    DroidsStateData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [DroidsData.pending]: (state) => { },
    [DroidsData.fulfilled]: (state, { payload }) => {
      state.getDroidsDataInfo = payload
    },
    [DroidsData.rejected]: (state, { payload }) => { },
 },
})

export const { DroidsStateData } = DroidsStateSlice.actions

export default DroidsStateSlice.reducer
