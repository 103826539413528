import React from "react";

function DroidIcon({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 20 19"
    >
      <path
        fill={color ? color : "#0078E7"}
        fillRule="evenodd"
        d="M3.845.098c.51 0 .924.413.924.923v2.306H15.23V1.021a.923.923 0 111.847 0v2.306h.581c1.209 0 2.188.945 2.188 2.11v10.2a2.462 2.462 0 01-2.462 2.46H2.341c-1.209 0-2.188-.944-2.188-2.11V5.438c0-1.165.98-2.11 2.188-2.11h.581V1.021c0-.51.414-.923.923-.923zM17.66 5.327H2.341a.224.224 0 00-.16.058c-.028.028-.028.045-.028.052v10.55c0 .008 0 .025.029.053.03.03.083.058.159.058h15.044a.462.462 0 00.462-.462V5.437c0-.007 0-.024-.029-.052a.225.225 0 00-.159-.058z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color ? color : "#0078E7"}
        d="M8.769 8.897a1.23 1.23 0 11-2.462 0 1.23 1.23 0 012.462 0zM13.693 8.897a1.23 1.23 0 11-2.462 0 1.23 1.23 0 012.462 0z"
      ></path>
    </svg>
  );
}

export default DroidIcon;
