import React from "react";

function BAT({bg='fff',wd=40,ht=40}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      viewBox="0 0 2000 1719"
    >
      <path
        fill="#662d91"
        d="M2000 1716.64l-995.21-569.86L0 1719l2000-2.36z"
      ></path>
      <path
        fill="#9e1f63"
        d="M1005.11 0l-.32 1146.78L2000 1716.64 1005.11 0z"
      ></path>
      <path fill="#ff5000" d="M0 1719l1004.79-572.22L1005.11 0 0 1719z"></path>
      <path
        fill={bg}
        stroke="#ff5000"
        strokeMiterlimit="10"
        strokeWidth="25"
        d="M1002.75 695.67L591.9 1381.92h823.54l-412.69-686.25z"
      ></path>
    </svg>
  );
}

export default BAT;