import React, { useState, useEffect } from "react";
import { Chart, ArcElement } from "chart.js";
import { useLocation } from "react-router-dom";
import DroidModule from "../../ManagementScreen/ManagementDroids/DroidModule";
import DroidPopupModule from "../ManagementDroids/DroidPopup/index";
import NewDroidType from "./DroidPopup/NewDroidType";
import { getAllDroids } from "features/Droids/droidsAction";
import { changeDroidStatus } from "features/Management/managementAction";
import { useDispatch, useSelector } from "react-redux";
import SecondryButton from "components/Button/SecondryButton";
import compareIcon from "assets/compare.svg";
import deactivateIcon from "assets/deactivate.svg";
import deleteIcon from "assets/deleteIcon.svg";
import warningIcon from "assets/warning.svg";
import axios from "axios";
import Button from "components/Button";
import ErrorCode from "ErrorCode";
import ComapareDroidPopup from "./DroidPopup/CompareDroidPopup";
import PopupModal from "components/PopupModal";
import Loader from "components/Loader";

Chart.register(ArcElement);

const droidFilterOption = [
  { label: "All Droids", symbol: "All Droids", id: "All Droids" },
  { label: "Simple Mode", symbol: "Simple Mode", id: "Simple Mode" },
  { label: "Time Mode", symbol: "Time Mode", id: "Time Mode" },
  { label: "Crypto/Dollar Cost Average", symbol: "Cost Mode", id: "Cost Mode" },
  { label: "Index Mode", symbol: "Index Mode", id: "Index Mode" },
  { label: "Safe Mode", symbol: "Safe Mode", id: "Safe Mode" },
  { label: "Profitable", symbol: "Profitable", id: "Profitable" },
  { label: "At Loss", symbol: "At Loss", id: "At Loss" },
];

const ManagementDroids = () => {
  const dispatch = useDispatch();
  const { ischangeDroidStatus } = useSelector((state) => state?.management);
  const [statusUpdated, setstatusUpdated] = useState(false);
  const { userToken } = useSelector((state) => state.auth);
  let droidList = useSelector((state) => state);
  const { getAllDroidsList, getAllDroidsInfo } = useSelector(
    (state) => state?.droids
  );
  const { isdeleteDroid } = useSelector((state) => state?.droids);
  const [selectFilter, setSelectFilter] = useState({ id: "none" });
  const [selectComapirDrois, setSelectComapirDrois] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [selecteStatus, setSelecteStatus] = useState("active");
  const [checkIfDeleteClicked, setCheckIfDeleteClicked] = useState(false);
  const [filterActivated, setfilterActivated] = useState(false);
  const [getFilterDroid, setFilterDroid] = useState({
    symbol: "All Droids",
    id: "All Droids",
  });
  const location = useLocation();
  const filterData = location.state && location.state.filterData;

  const [selectedDroids, setSelectedDroids] = useState([
    { symbol: "All Droids", id: "All Droids" },
  ]);

  useEffect(() => {
    // this useffect is only running to AutoRefresh the droids after every 1 minutes
    const fetchData = () => {
      localStorage.setItem("hardRefresh", true);

      dispatch(getAllDroids({ userToken }))
        .then((res) => {
          if (res) {
            apiCall(userToken);
          }
        })
        .catch(() => {
          console.log("error occured");
        });
    };

    // Set up interval to call fetchData every 2 minutes
    const intervalId = setInterval(fetchData, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dispatch, userToken]);

  const apiCall = async (userToken) => {
    setstatusUpdated(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getAllDroidStatus`,
        config
      );
      if (data.hasOwnProperty("error")) {
        setstatusUpdated(false);
        return data.message;
      } else {
        setstatusUpdated(false);
        localStorage.setItem("droidStatus", JSON.stringify(data.data));
      }
    } catch (error) {
      setstatusUpdated(false);
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  };

  useEffect(() => {
    // apiCall(userToken);
    if (filterData === "At Loss") {
      handleExchangecard({
        id: "seeAll",
        icon: warningIcon,
        label: "See All",
      });
      setSelectedDroids([{ symbol: "At Loss", id: "At Loss" }]);
    } else if (filterData === "Profitable") {
      handleExchangecard({
        id: "seeAll",
        icon: warningIcon,
        label: "See All",
      });
      setSelectedDroids([{ symbol: "Profitable", id: "Profitable" }]);
    } else {
      setSelectedDroids([{ symbol: "All Droids", id: "All Droids" }]);
    }
  }, []);

  useEffect(() => {
    if (selectedDroids.length === droidFilterOption.length) {
      setDisabledOptions(
        droidFilterOption.filter((option) => option.id !== "All Droids")
      );
    } else {
      setDisabledOptions([]);
    }

    const profitableSelected = selectedDroids.some(
      (droid) => droid.id === "Profitable"
    );
    const lossSelected = selectedDroids.some((droid) => droid.id === "At Loss");
    if (profitableSelected) {
      setDisabledOptions(
        droidFilterOption.filter((option) => option.id === "At Loss")
      );
    } else if (lossSelected) {
      setDisabledOptions(
        droidFilterOption.filter((option) => option.id === "Profitable")
      );
    } else {
      setDisabledOptions([]);
    }
  }, [selectedDroids]);

  const [disabledOptions, setDisabledOptions] = useState([]);

  const handleToggleDroid = (item) => {
    if (
      item.id === "All Droids" ||
      item.id === "Profitable" ||
      item.id === "At Loss"
    ) {
      setSelectedDroids((prevSelected) => {
        if (prevSelected.some((droid) => droid.id === item.id)) {
          return prevSelected.filter((droid) => droid.id !== item.id);
        } else {
          return [item];
        }
      });
    } else {
      setSelectedDroids((prevSelected) => {
        if (
          prevSelected.some(
            (droid) =>
              droid.id === "All Droids" ||
              droid.id === "Profitable" ||
              droid.id === "At Loss"
          )
        ) {
          return [item];
        } else {
          if (prevSelected.some((droid) => droid.id === item.id)) {
            return prevSelected.filter((droid) => droid.id !== item.id);
          } else {
            return [...prevSelected, item];
          }
        }
      });
    }
  };

  const [DroidData, setDroidData] = useState([]);

  const handleExchangecard = (id) => {
    setfilterActivated(true);
    if (id?.id === "delete") {
      setSelecteStatus("active");
      setCheckIfDeleteClicked(true);
    } else {
      setCheckIfDeleteClicked(false);
    }
    setSelectComapirDrois([]);
    if (id?.id === "seeAll") {
      setSelecteStatus("all");
    }
    if (id?.id === "compare") {
      setSelecteStatus("active");
    }
    if (id?.id === "deactive") {
      setSelecteStatus("active");
    }

    setSelectFilter(id);
    var items = document.getElementsByName("acs");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
    }
  };

  const getDrodListOnLoading = () => {
    dispatch(getAllDroids({ userToken }))
      .then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setLoading(false);
          setLoadingDropdown(false);
          setDroidData(res.payload);
          apiCall(userToken);
        }
      })
      .catch(() => {
        setLoading(false);
        setLoadingDropdown(false);
        setDroidData([]);
        console.log("error occured");
      });
  };

  useEffect(() => {
    if (!loadingStatus || ischangeDroidStatus || isdeleteDroid) {
      getDrodListOnLoading();
    }
  }, [isdeleteDroid, loadingStatus, ischangeDroidStatus]);

  const filterDroid = () => {
    let droidStatusList = JSON.parse(localStorage.getItem("droidStatus"));

    if (droidStatusList?.droids?.length) {
      const idToOrderStatusMap = {};
      droidStatusList.droids.forEach((droid) => {
        idToOrderStatusMap[droid._id] = droid.orderStatus;
      });

      // Update the status field of response2 based on the idToOrderStatusMap
      const updatedDroids = droidList?.droids?.getAllDroidsInfo?.map(
        (droid) => {
          const orderStatus = idToOrderStatusMap[droid._id];

          if (orderStatus) {
            return { ...droid, orderStatus: orderStatus };
          }
          return droid;
        }
      );

      let newUpdate = {
        ...droidList,
        droids: { ...droidList.droids, getAllDroidsInfo: updatedDroids },
      };

      // Create a new response object with updated droids
      droidList = newUpdate;
    }
    setTimeout(() => {
      localStorage.setItem("deletedDroidId", null);
    }, 3000);
    let deletedDroid = JSON.parse(
      localStorage.getItem("deletedDroidId") || null
    );

    if (selectedDroids.some((droid) => droid.id === "All Droids")) {
      return Object.values(
        droidList?.droids?.getAllDroidsInfo.filter((i) => i)
      )?.filter((i) =>
        selecteStatus === "all"
          ? i
          : filterActivated
          ? i.status === selecteStatus
          : i
      );
    } else {
      return Object.values(droidList?.droids?.getAllDroidsInfo)?.filter(
        (droid) => {
          return selectedDroids.some((selectedDroid) => {
            if (selectedDroid.id === "Profitable") {
              console.log(droid, selecteStatus, filterActivated, ";profit");
              if (selecteStatus === "active" && filterActivated) {
                return (
                  droid.status === "active" &&
                  droid?.balance?.status === "profit"
                );
              } else if (selecteStatus === "all") {
                return droid?.balance?.status === "profit";
              } else if (selecteStatus === "deactive") {
                return (
                  droid.status === "deactive" &&
                  droid?.balance?.status === "profit"
                );
              } else if (selecteStatus === "delete") {
                return (
                  droid.status === "deleted" &&
                  droid?.balance?.status === "profit"
                );
              } else {
                return droid?.balance?.status === "profit";
              }
            } else if (selectedDroid.id === "At Loss") {
              console.log(droid, "loss");
              if (selecteStatus === "active" && filterActivated) {
                return (
                  droid.status === "active" && droid?.balance?.status === "loss"
                );
              } else if (selecteStatus === "all") {
                return droid?.balance?.status === "loss";
              } else if (selecteStatus === "deactive") {
                return (
                  droid.status === "deactive" &&
                  droid?.balance?.status === "loss"
                );
              } else if (selecteStatus === "delete") {
                return (
                  droid.status === "delete" && droid?.balance?.status === "loss"
                );
              } else {
                return droid?.balance?.status === "loss";
              }
            } else {
              console.log(droid, selecteStatus, "Please select");
              return (
                droid.type === selectedDroid.symbol &&
                (filterActivated
                  ? droid.status ===
                    (selecteStatus === "all"
                      ? droid.status
                      : selecteStatus === "active"
                      ? "active"
                      : selecteStatus === "deactive"
                      ? "deactive"
                      : "delete")
                  : droid)
              );
            }
          });
        }
      );
    }
  };
  const changeStatusDroid = () => {
    if (selectFilter?.id !== "compare") {
      setLoadingStatus(true);
      const payload = {
        identifier: selectFilter?.id,
        droidIds: selectComapirDrois.map((obj) => obj._id),
      };
      dispatch(changeDroidStatus({ userToken, payload })).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setLoadingStatus(false);
          setSelectFilter({ id: "none" });
          setSelectComapirDrois([]);
        }
      });
    } else {
      setSelectFilter({ id: "none" });
    }
  };

  const cancleFilter = () => {
    setfilterActivated(false);
    setSelectFilter({ id: "none" });
    setSelectComapirDrois([]);
    getDrodListOnLoading();
    setSelecteStatus("active");
  };
  const doridFilterList = filterDroid();

  const optionsData = [
    {
      id: "seeAll",
      icon: warningIcon,
      label: "See All",
    },
    ...(DroidData
      ? [
          {
            id: "compare",
            icon: compareIcon,
            label: "Compare",
          },
          {
            id: "deactive",
            icon: deactivateIcon,
            label: "Deactivate",
          },
          {
            id: "delete",
            icon: deleteIcon,
            label: "Delete",
          },
        ]
      : []),
  ];
  const SeeAllSelect = [
    { id: "all" },
    { id: "active" },
    { id: "deactive" },
    { id: "delete" },
  ];
  return (
    <>
      <div className="mt-4">
        <div className={`ps-4 d-flex justify-content-between`}>
          <div className="d-flex gap-4">
            <div
              className={`btn-group MultiSelectDropDown z-index-2 dropdown_select_droid`}
            >
              <button
                className="btn dropdown-toggle d-flex justify-content-between align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <div
                  className={`me-3 ${
                    selectedDroids.length > 1 ? "trimmed-options" : ""
                  }`}
                >
                  {selectedDroids.map((droid) => droid.symbol).join(", ")}
                </div>
                <img
                  src={require("assets/arrowDown.svg").default}
                  alt=""
                  loading="lazy"
                />
              </button>
              <ul className="dropdown-menu pt-0 w-max z-index-2">
                {droidFilterOption.map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown-item py-2 d-flex gap-2 align-items-center ${
                      droidFilterOption?.length - 1 === index
                        ? ""
                        : "border-bottom"
                    }`}
                    onClick={() => handleToggleDroid(item)}
                  >
                    <input
                      className="checkbox selectContainer"
                      type="checkbox"
                      checked={selectedDroids.some(
                        (droid) => droid.id === item.id
                      )}
                      readOnly
                      // disabled={disabledOptions.some((option) => option.id === item.id)}
                    />
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
            <SecondryButton
              databstoggle="modal"
              databstarget="#NDTPopupId"
              text={"New Droids"}
              icon={
                <img
                  src={require(`assets/plusIcon.svg`).default}
                  alt=""
                  loading="lazy"
                />
              }
            />
          </div>
          <div className="dropdown">
            {
              <div
                className=""
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={require("assets/threeDot.svg").default}
                  alt=""
                  className="cursor"
                  loading="lazy"
                />
              </div>
            }
            <div
              className="dropdown-menu r-10"
              aria-labelledby="dropdownMenuButton"
            >
              {optionsData.map((option, index) => (
                <>
                  <div
                    className={`d-flex align-items-center droid-options gap-2 dropdown-item ${
                      selectFilter?.id === option.id ? "selectedExchange" : ""
                    }`}
                    onClick={() => handleExchangecard(option)}
                  >
                    <img
                      src={option.icon}
                      alt={`${option.label} Icon`}
                      loading="lazy"
                    />
                    <h5 className="p-1">{option.label}</h5>
                  </div>
                  {index !== optionsData.length - 1 && <hr />}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      {selectFilter?.id === "seeAll" ? (
        <div className="btn-group mt-3 mx-4" role="group">
          {" "}
          {SeeAllSelect?.map((e, i) => (
            <button
              key={i}
              className={`btn group-btn bordewr-color ${
                selecteStatus === e?.id ? "active" : ""
              }`}
              onClick={() => {
                setSelecteStatus(e?.id);
              }}
            >{`${e?.id?.charAt(0).toUpperCase()}${e?.id?.slice(1)}`}</button>
          ))}{" "}
        </div>
      ) : (
        ""
      )}
      <div className="container-fluid droid-cards-container pb-0">
        <div className="row">
          {doridFilterList?.length > 0 ? (
            doridFilterList?.map((droid) => (
              <DroidModule
                selectComapirDrois={selectComapirDrois}
                setSelectComapirDrois={setSelectComapirDrois}
                droid={droid}
                selectFilter={selectFilter?.id}
                checkIfDeleteClicked={checkIfDeleteClicked}
              />
            ))
          ) : loading ? (
            <div className="DroidLoading ">
              <div className="loaderProfileCommunity" role="status"></div>
            </div>
          ) : (
            <div className="justify-content-center d-flex mt-3">
              {" "}
              Data Not Found{" "}
            </div>
          )}
        </div>
      </div>

      {selectFilter?.id === "none" ? (
        ""
      ) : (
        <div className="d-flex checkbox_filter_button gap-3">
          {loading ? (
            ""
          ) : (
            <SecondryButton
              disabled={loadingStatus}
              handleClick={() => cancleFilter()}
              text={`${selectFilter?.id === "seeAll" ? "Reset" : "Cancel"}`}
            />
          )}
          {selectFilter?.id === "seeAll" || selectFilter?.id === "none" ? (
            ""
          ) : (
            <Button
              databstoggle="modal"
              databstarget={`#${selectFilter?.id}`}
              disabled={
                loadingStatus ||
                !(
                  selectComapirDrois?.length >
                  (selectFilter?.id === "compare" ? 1 : 0)
                )
              }
              loading={loadingStatus}
              text={selectFilter?.label}
              handleClick={changeStatusDroid}
            />
          )}
        </div>
      )}

      <DroidPopupModule />
      <NewDroidType />

      <PopupModal
        backdrop={false}
        id={"compare"}
        children={
          <div className="compare_droid d-flex justify-content-center gap-4 compare-droid">
            <ComapareDroidPopup droid={selectComapirDrois[0]} compare={true} />
            <ComapareDroidPopup
              droid={selectComapirDrois[1]}
              compareDroidsData={selectComapirDrois}
              compare={true}
              cancleBtn={true}
            />
            <div className="d-flex align-items-end">
              <button
                className="primaryButton "
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default React.memo(ManagementDroids);
