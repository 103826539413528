import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SetupAssistent from "./SetupAssistent";
import GlobalMarketCap from "components/GlobalMarketCap";
import AddOnCard from "components/AddOnCard";
import * as Sentry from "@sentry/react";
import FearIndex from "components/FearIndex";
import { getPorfolioByUserId } from "features/Management/managementAction";
import NewsCard from "components/newsCard";
import axios from "axios";
import ErrorCode from "ErrorCode";

import CryptoCurrenciesCard from "components/CryptoCurrencies";
import { useOutletContext } from "react-router-dom";
import CreateDroid from "components/Charts/Accessories/CreateDroid";
import Loader from "components/Loader";
import {
  dashboardGraph,
  dashboardDroid,
  addOns,
} from "features/Dashboard/dashboardAction";

import BalanceGraphComponent from "components/DashboardComponent/BalanceGraphComponent";
import DroidComponent from "components/DashboardComponent/DroidComponent";
import HealthComponent from "components/DashboardComponent/HealthComponent";
import TopClientAssetsComponent from "components/DashboardComponent/TopClientAssetsComponent";
import BalanceDetailsComponent from "components/DashboardComponent/BalanceDetailsComponent";
import Slider from "components/Slider";
import ActivityDetails from "./ActivityDetails";
import { sendConnectionRequestInvitedUser } from "../../features/Community/communityAction";
import "../../scss/dashboard.scss";
import { setPorfolioByUserIdData } from "features/Management/managementSlice";
import { updatePortfolio } from "features/CoinList/CoinListAction";

function DashboardScreenHome() {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const { porfolioByUserIdData } = useSelector((state) => state?.management);
  const { pinnedTabs, onClickPin, onClickUpdate, onClickExpand } =
    useOutletContext();
  const { dashboardGraphInfo } = useSelector((state) => state?.dashboard);
  const [getGraphData, setGraphData] = useState(dashboardGraphInfo);
  const [balancePeriod, setBalancePeriod] = useState("12 Months");
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [getDroid, setDroid] = useState(
    JSON.parse(localStorage.getItem("dashboardDroid"))
  );
  const [refreshKey, setRefreshKey] = useState(0);
  const isUserInvited = JSON.parse(localStorage.getItem("invitedUser"));
  const invitedUserRegistered = localStorage.getItem("invitedUserRegistered");

  const apiCall = async (userToken) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/updateDroidProfitLossStatus`,
        config
      );
      if (data.hasOwnProperty("error")) {
        Sentry.captureException(data, {
          extra: {
            customData: "DashboardScreenHome--> Line 67",
          },
        });
        return data.message;
      } else {
        return data;
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          customData: "DashboardScreenHome--> Line 77",
        },
      });
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  };

  useEffect(() => {
    dispatch(addOns({ userToken }))
      .then((res) => {})
      .catch(() => {
        console.log("error occured");
      });
    dispatch(updatePortfolio({ userToken }))
      .then(() => {
        setTimeout(async () => {
          await dispatch(getPorfolioByUserId({ userToken }))
            .then((res) => {
              setPorfolioByUserIdData(res?.payload);
            })
            .catch((error) => {
              console.log("error occured");
              Sentry.captureException(error, {
                extra: {
                  customData: "DashboardScreenHome--> Line 96",
                },
              });
            });
        }, 1500);
      })
      .catch(() => {
        console.log("error occured");
      });

    apiCall(userToken);
    dispatch(dashboardGraph({ userToken }))
      .then((res) => {
        if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
          setGraphData(res?.payload);
          setRefreshKey(refreshKey + 1);
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "DashboardScreenHome--> Line 109",
          },
        });
      });

    dispatch(dashboardDroid({ userToken }))
      .then((res) => {
        if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
          setDroid(res?.payload);
          localStorage.setItem("dashboardDroid", JSON.stringify(res?.payload));
        } else {
          Sentry.captureException(res, {
            extra: {
              customData: "DashboardScreenHome--> Line 121",
            },
          });
        }
      })
      .catch((error) => {
        console.log("error occured ");
        Sentry.captureException(error, {
          extra: {
            customData: "DashboardScreenHome--> Line 128",
          },
        });
      });

    if (isUserInvited && invitedUserRegistered) {
      dispatch(sendConnectionRequestInvitedUser({ userToken }))
        .then((res) => {
          if (res.meta && res.meta.requestStatus === "fulfilled") {
            localStorage.removeItem("invitedUser");
            localStorage.removeItem("invitedUserRegistered");
          } else {
            Sentry.captureException(res, {
              extra: {
                customData: "DashboardScreenHome--> Line 145",
              },
            });
          }
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: {
              customData: "DashboardScreenHome--> Line 146",
            },
          });
        });
    }
  }, []);

  const renderPinnedTab = (tabId) => {
    switch (tabId) {
      case "globalMarketCap":
        return <GlobalMarketCap label={true} />;
      case "addOnCard":
        return <AddOnCard />;
      case "fearIndex":
        return (
          <FearIndex
            onClickPin={() => onClickPin.onClickPin("fearIndex")}
            onClickExpand={onClickExpand}
            onClickUpdate={onClickUpdate}
          />
        );
      case "news":
        return (
          <NewsCard
            onClickPin={() => onClickPin.onClickPin("news")}
            onClickExpand={onClickExpand}
            onClickUpdate={onClickUpdate}
          />
        );
      case "cryptoCurrencies":
        return (
          <CryptoCurrenciesCard
            onClickPin={() => onClickPin.onClickPin("cryptoCurrencies")}
            onClickExpand={onClickExpand}
            onClickUpdate={onClickUpdate}
          />
        );
      default:
        return null;
    }
  };

  const handleItemClick = (index) => {
    if (selectedIndices.includes(index)) {
      setSelectedIndices(selectedIndices.filter((idx) => idx !== index));
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
  };

  useEffect(() => {
    if (porfolioByUserIdData) {
      setSelectedIndices(
        porfolioByUserIdData
          ?.filter((x) => x?.balance > 0.01)
          ?.map((item) => item._id)
      );
    }
  }, [porfolioByUserIdData]);
  return (
    <div className="container-fluid dashboardContentMainBody ">
      <div className="row mt-5 dashboardContentMain flex-container">
        <div className=" dashbord-slider flex-item">
          {localStorage.getItem("role") === "retailer" &&
            (porfolioByUserIdData && porfolioByUserIdData.length === 0 ? (
              <div className="form-control d-flex justify-content-center py-3 text-dark">
                No portfolios found.
              </div>
            ) : (
              <Slider
                data={porfolioByUserIdData?.filter((x) => x?.balance > 0.01)}
                selectedIndices={selectedIndices}
                handleItemClick={handleItemClick}
              />
            ))}
          <div
            className={`row justify-content-between ${
              localStorage.getItem("role") === "retailer" && "mt-3"
            }`}
          >
            {!porfolioByUserIdData ? (
              <Loader />
            ) : (
              <BalanceDetailsComponent
                porfolioByUserIdData={porfolioByUserIdData}
                {...getGraphData?.data}
                isSelected={selectedIndices}
              />
            )}
          </div>
          <BalanceGraphComponent
            trend={getGraphData?.data?.trend}
            roi={getGraphData?.data?.roi}
            balancePeriod={balancePeriod}
            setBalancePeriod={setBalancePeriod}
          />
          <div className="my-4 row">
            {getDroid?.data?.totalDroids > 0 ? (
              <DroidComponent
                data={getDroid?.data}
                status={getDroid?.status}
                satatus={false}
              />
            ) : (
              <CreateDroid status={getDroid?.status} data={getDroid?.data} />
            )}
            <HealthComponent />
          </div>
          <TopClientAssetsComponent key="topClientUnique" />
        </div>
        <div className=" flex-item">
          <div className="dash-graph-custom">
            {pinnedTabs.pinnedTabs?.map((tabId) => renderPinnedTab(tabId))}
            <div className="my-3">
              <h3 className="mb-3">Activity Log</h3>
              <div className="assistLinkCard mb-4  DashboardActivityLog pt-4 pb-0">
                <ActivityDetails />
              </div>
            </div>
            <SetupAssistent profilePage={true} hide={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(DashboardScreenHome);
