import ConvertGraphValue from "components/ConvertNumber/ConvertGraphValue";
import React from "react";

const SMDChartPercentage = ({ PercentName, getGraphData }) => {
  const takeProfitType = getGraphData?.takeProfit?.takeProfitType;
  const SMDProfitType =
    takeProfitType === "base" ? "%" : takeProfitType === "value" ? "%" : "";
  const pricePurchaseAmount =
    getGraphData?.buySellSettings?.pricePurchaseAmount;
  const targetProfitPercent = getGraphData?.takeProfit?.targetProfitPercent;
  let dollarPercentageCalculation =
    ((targetProfitPercent - pricePurchaseAmount) * 100) / pricePurchaseAmount;
  if (!isFinite(dollarPercentageCalculation)) {
    dollarPercentageCalculation = 0;
  }

  return (
    <div className="d-block justify-content-center">
      <div className="d-flex justify-content-center">
        <h3
          className={`d-flex ${
            getGraphData?.takeProfit?.targetProfitPercent?.length > 3
              ? "font-18"
              : ""
          }`}
        >
          {getGraphData?.takeProfit?.targetProfitPercent ? (
            <ConvertGraphValue
              data={
                takeProfitType === "base"
                  ? getGraphData?.takeProfit?.targetProfitPercent
                  : dollarPercentageCalculation.toFixed(2)
              }
              valid={getGraphData?.takeProfit?.takeProfitType}
            />
          ) : (
            "0"
          )}
          {SMDProfitType}
        </h3>
      </div>
      <div>
        <small className="d-flex justify-content-center ">{PercentName}</small>
      </div>
    </div>
  );
};

export default SMDChartPercentage;
