import React from "react";
import ReactApexChart from "react-apexcharts";
import DroidADAChartLabels from "./Accessories/DroidADAChartLabels";
import ADAChartOrderSize from "./Accessories/ADAChartOrderSize";

const DroidADAChart = ({ getGraphData }) => {
  let priceDeviation =
    getGraphData?.safetyNetParameters?.priceDeviation * 10 || 1;
  let maxOrderCount = getGraphData?.safetyNetParameters?.maxOrderCount || 1;
  let orderVolumes = getGraphData?.safetyNetParameters?.orderVolumes * 10 || 1;
  let maxActiveOrdersCount =
    getGraphData?.safetyNetParameters?.maxActiveOrdersCount || 1;
  let orderStepScale = getGraphData?.safetyNetParameters?.orderStepScale || 1;

  const options = {
    series: [
      orderStepScale,
      maxActiveOrdersCount,
      orderVolumes,
      maxOrderCount,
      priceDeviation,
    ],
    chart: {
      height: 0,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        track: {
          strokeWidth: "100%",
          margin: 11,
        },
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 0,
          size: "2%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },

    colors: ["#002F60", "#2C248A", "#0078E7", "#95C6FF", "#727EEE"],
    legend: {
      show: false,
      floating: false,
      fontSize: "16px",
      position: "right",
      offsetX: 270,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
  };
  return (
    <div className="position-relative py-3">
      <div className="ChartADA rotate-chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="radialBar"
          height="295"
          className=" mr-2"
        />
      </div>
      <div className="ChartADALabels position-absolute">
        <DroidADAChartLabels getGraphData={getGraphData} />
      </div>
      <div className="ChartADAOrderSize position-absolute">
        <ADAChartOrderSize getGraphData={getGraphData} />
      </div>
    </div>
  );
};

export default DroidADAChart;
