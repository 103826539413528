import React from "react";

function UndoIcon({width=17, height=17}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 17 16" >
      <path stroke="#0078E7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.333" d="M6.837 9.333L3.503 6l3.334-3.333" ></path>
      <path stroke="#0078E7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.333" d="M3.503 6h7a3.666 3.666 0 110 7.333H8.17" ></path>
    </svg>
  );
}

export default UndoIcon;
