import React, { useState, useEffect } from "react";
import "../DroidSettings/dropdown.scss";
import SingleSelectIconDropdown from "components/Dropdown/SingleSelectIconDropdown";
import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const DroidModuleSettings = ({
  hasFailed,
  minAmount,
  getSingleCoinData,
  setSingleCoinData,
  droidDataDispatch,
  checkBalance,
  getCoinListData,
  children,
  getbidValue,
  selectedPortfolios,
}) => {
  const location = useLocation();
  const { state } = useLocation();
  const droidType = state;
  const [error, setError] = useState({ name: "", text: "" });
  const [percentValue, setPercentValue] = useState("");
  const [getErrorCoin, setErrorCoin] = useState(false);
  const [percentageErrorMessage, setPercentageErrorMessage] = useState("");
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const [test, setTest] = useState("");
  const { getcoinList } = useSelector((state) => state?.coinList);
  const whichdroid = location?.pathname?.split("/").pop().includes("droid")
    ? location?.pathname?.split("/").pop().toLowerCase()
    : location?.pathname?.split("/").slice(-2, -1)[0];
  const [refreshKey, setRefreshKey] = useState(0);

  document.addEventListener("DOMContentLoaded", () => {
    const dropdown = document.getElementById("coin_dropdown");
    const items = dropdown.querySelectorAll("li");
    let focusedIndex = -1;

    dropdown.addEventListener("keydown", (event) => {
      if (event.key === "ArrowDown" && focusedIndex < items.length - 1) {
        focusedIndex++;
        items[focusedIndex].focus();
        event.preventDefault();
      } else if (event.key === "ArrowUp" && focusedIndex > 0) {
        focusedIndex--;
        items[focusedIndex].focus();
        event.preventDefault();
      }
    });

    items.forEach((item, index) => {
      item.addEventListener("focus", () => {
        focusedIndex = index;
      });
    });
  });

  useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, [getCoinListData]);

  useEffect(() => {
    setTest("");
    if (state?.Duplicate === "Duplicate") {
      setTest(getDroidsDataInfo?.name);
    }
  }, [location.pathname]);

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const [isDropdownDisableNonIndex, setIsDropdownDisableNonIndex] = useState(
    Object.keys(getSingleCoinData).length === 0 &&
      checkBalance?.length === 0 &&
      whichdroid !== "indexmodedroid"
  );

  const getAvailablePercentage = () => {
    let percentValue = 0;
    getDroidsDataInfo?.coinsList.length > 0 &&
      getDroidsDataInfo?.coinsList.map((data) => {
        percentValue += Number(data.value);
      });
    return 100 - percentValue;
  };

  const handleCoinChange = (item) => {
    if (getDroidsDataInfo?.type === "Simple Mode") {
      droidDataDispatch({
        buySellSettings: {
          ...getDroidsDataInfo?.buySellSettings,
          pricePurchaseAmount: "",
          buyPurchaseAmount: "",
        },
        coinsList: [
          {
            name: item?.name,
            iconUrl: getIconUrl(item?.name?.split("/")[0]),
            symbol: item?.symbol,
            price: item?.price,
          },
        ],
      });
    } else if (getDroidsDataInfo?.type === "Time Mode") {
      droidDataDispatch({
        timeSettings: {
          ...getDroidsDataInfo?.timeSettings,
          pricePurchaseAmount: "",
          purchaseAmount: "",
        },
        coinsList: [
          {
            name: item?.name,
            iconUrl: getIconUrl(item?.name?.split("/")[0]),
            symbol: item?.symbol,
            price: item?.price,
          },
        ],
      });
    } else if (getDroidsDataInfo?.type === "Cost Mode") {
      droidDataDispatch({
        dcaStrategy: {
          ...getDroidsDataInfo?.dcaStrategy,
          baseOrderSizeAmount: "",
          limit: "",
        },
        coinsList: [
          {
            name: item?.name,
            iconUrl: getIconUrl(item?.name?.split("/")[0]),
            symbol: item?.symbol,
            price: item?.price,
          },
        ],
      });
    } else if (getDroidsDataInfo?.type === "Safe Mode") {
      droidDataDispatch({
        coinsList: [
          {
            name: item?.name,
            iconUrl: getIconUrl(item?.name?.split("/")[0]),
            symbol: item?.symbol,
            price: item?.price,
          },
        ],
      });
    }
  };

  const [isDropdownValueSelected, setIsDropdownValueSelected] = useState(false);
  const [errorCoinName, setErrorCoinName] = useState({ status: false });
  const handleDropdownChange = (item) => {
    if (item) setIsDropdownValueSelected(true);
    if (getDroidsDataInfo?.coinsList?.some((i) => i.symbol === item?.symbol)) {
      setErrorCoinName({ status: true, name: item?.symbol });
    } else {
      setSingleCoinData((p) => ({
        iconUrl: item?.iconUrl,
        symbol: item?.symbol,
        name: item?.name,
        uuid: item?.uuid,
        color: getRandomColor(),
        minAmount: minAmount,
      }));
      setErrorCoinName({ status: false });
    }
  };

  const setPercent = (e) => {
    const { name, value } = e?.target;
    const sum =
      Number(
        getDroidsDataInfo?.coinsList?.reduce(
          (sum, item) => sum + (Number(item.value) || 0),
          0
        )
      ) + Number(value);
    const twoDecimalPointValidation = /^\d*\.?\d{0,2}$/;
    if (sum > 100) {
      const availablePercent = getAvailablePercentage();
      setError({
        name: name,
        text: `Available allocation value is ${availablePercent}`,
      });
    } else if (value > 99) {
      setError({
        name: name,
        text: "Add atleast two coins with combined value of 100",
      });
    } else if (!twoDecimalPointValidation.test(value)) {
      setError({
        name: name,
        text: "Enter a valid number with up to two decimal places",
      });
    } else if (value?.length > 2 && value == 0) {
      setError({ name: name, text: "Value must be greater than 0." });
    } else {
      setPercentValue(e.target.value);
    }
  };

  const changeValue = (data, e) => {
    const { name, value } = e.target;
    const decimalRegex = /^\d*\.?\d{0,2}$/;
    const newData = { ...data, value: value };
    const updatedData = getDroidsDataInfo?.coinsList?.map((item) =>
      item.name === newData.name ? newData : item
    );
    const sum = updatedData?.reduce(
      (sum, item) => sum + (Number(item.value) || 0),
      0
    );

    if (sum > 100) {
      const availablePercent = getAvailablePercentage();
      setError({
        name: name,
        text: `Available allocation value is ${availablePercent}`,
      });
    } else if (value > 99) {
      setError({
        name: name,
        text: "Add atleast two coins with combined value of 100",
      });
    } else if (!decimalRegex.test(value)) {
      setError({
        name: name,
        text: "Enter a valid number with up to two decimal places",
      });
    } else if (value?.length > 2 && value == 0) {
      setError({ name: name, text: "Value must be greater than 0." });
    } else {
      droidDataDispatch({ coinsList: updatedData });
      setError({ name: "", text: "" });
    }
  };

  const deleteFunc = (data) => {
    if (state?.Duplicate === "edit") {
    } else {
      droidDataDispatch({
        coinsList: getDroidsDataInfo?.coinsList?.filter(
          (item) => item.name !== data?.name
        ),
      });
    }
    setPercentageErrorMessage("");
  };

  const addItem = (item) => {
    if (
      getDroidsDataInfo?.coinsList?.some(
        (item) => item.symbol === getSingleCoinData?.symbol
      )
    ) {
      return;
    } else if (
      getDroidsDataInfo?.coinsList?.some(
        (item) => item.value === "0" || item?.value === ""
      )
    ) {
      const filteredData = getDroidsDataInfo?.coinsList?.filter(
        (item) => item.value !== "0" && item?.value !== ""
      );
      droidDataDispatch({ coinsList: filteredData });
    } else if (percentValue == 0) {
      setError({ name: "value", text: "Enter Value" });
    } else {
      droidDataDispatch({
        coinsList: [
          ...getDroidsDataInfo?.coinsList,
          { ...getSingleCoinData, value: percentValue, minAmount: minAmount },
        ],
      });
    }
    setSingleCoinData({});
    setPercentValue("");
    setErrorCoin(false);
    setError({ name: "", text: "" });
    setIsDropdownValueSelected(false);
  };

  const enterName = (e) => {
    const { name, value } = e?.target;
    const format = /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\-]*$/;
    if (!format.test(value)) {
      setError({ name: name, text: "Please enter a valid Droid name" });
    } else if (value.trim() === "") {
      setError({ name: name, text: "Please enter a Droid name" });
      droidDataDispatch({ name: value });
    } else if (value.length < 3) {
      setError({ name: name, text: "Name must be at least 3 characters long" });
      droidDataDispatch({ name: value });
    } else if (value.length > 16) {
      setError({
        name: name,
        text: "Name is too long (maximum 12 characters)",
      });
    } else {
      setError({ name: "", text: "" });
      droidDataDispatch({ name: value });
    }
    setTest(value);
  };

  function getIconUrl(symbol) {
    const item = getcoinList?.coins?.find((item) => item?.symbol === symbol);
    return item ? item.iconUrl : "null";
  }
  const [getWait, setWait] = useState(false);
  const waiting = () => {
    if (getDroidsDataInfo?.portfoliosIds?.length) {
      setTimeout(() => {
        setWait(true);
      }, 3000);
    }
  };
  const waiting1 = waiting();

  const today = moment()?.format("DD/MM/YYYY");
  const disabledInput =
    state?.Duplicate === "edit" &&
    getDroidsDataInfo?.timeSettings?.firstBuyDate === today;

  useEffect(() => {
    setIsDropdownDisableNonIndex(
      Object.keys(getSingleCoinData).length === 0 &&
        checkBalance?.length === 0 &&
        whichdroid !== "indexmodedroid"
    );
  }, [getSingleCoinData, checkBalance, whichdroid]);
  return (
    <>
      <h4 className="mb-3">Settings</h4>
      <div className="DMS-Container-Main">
        <div className="d-flex align-items-center">
          {" "}
          <h6>Name</h6>{" "}
          {!getDroidsDataInfo?.name ? <p className="required-field">*</p> : ""}{" "}
        </div>
        <input
          type="text"
          value={test}
          onChange={(e) => enterName(e)}
          className="DMSInputSize"
          placeholder="Droid Name"
          minLength="2"
          name="name"
          maxlength="12"
        />
        {error.name === "name" && (
          <div className="error-message"> {error?.text}</div>
        )}
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-center gap-2 mt-3 mb-1">
              <div className="d-flex align-items-center">
                <h6>Coins List</h6>
                {getDroidsDataInfo?.coinsList?.length > 0 ? (
                  ""
                ) : (
                  <p className="required-field">*</p>
                )}
              </div>
              {(whichdroid === "indexmodedroid" ||
                state?.droidType?.name === "Index Mode Droid") && (
                <TooltipQuestionMark
                  toolTipContent={
                    "Define which Coins/Tokens to be included in the Index Fund/List. It is required to match 100%"
                  }
                />
              )}
            </div>
          </div>
        </div>
        {whichdroid === "indexmodedroid" ||
        state?.droidType?.name === "Index Mode Droid" ? (
          <>
            {getDroidsDataInfo?.coinsList?.map((item, i) => {
              const word = item?.name + "(" + item.symbol + ")";
              return (
                <div key={i} className="mb-3">
                  <div className="row" key={item.id}>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className={`btn-group simpledropdown webkit-fill-available`}
                      >
                        <button
                          className="btn dropdown-toggle d-flex justify-content-between align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                        >
                          <div className="d-flex gap-3">
                            {" "}
                            <img
                              src={item?.iconUrl}
                              width="20"
                              height="20"
                              alt=""
                              loading="lazy"
                            />
                            {word?.length > 16
                              ? `${word?.substring(0, 16)}...`
                              : `${item?.name} (${item.symbol})`}{" "}
                          </div>
                          <img
                            src={require("assets/arrowDown.svg").default}
                            alt=""
                            className="ML-6"
                            loading="lazy"
                          />
                        </button>
                        <ul
                          className="dropdown-menu coin_list"
                          id="coin_dropdown"
                        >
                          {getCoinListData?.map((item, index) => (
                            <li
                              key={index}
                              tabIndex={"0"}
                              className={`dropdown-item py-3 gap-2 d-flex ${
                                getCoinListData?.length - 1 === index
                                  ? ""
                                  : "border-bottom"
                              }`}
                            >
                              <img
                                src={getIconUrl(item?.name?.split("/")[0])}
                                width="20"
                                height="20"
                                alt=""
                                loading="lazy"
                              />
                              <p>$ {Number(item?.price)?.toFixed(2)}</p>{" "}
                              {item?.name} ({item?.symbol})
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 percentfield">
                      {" "}
                      <input
                        name="value"
                        type="text"
                        value={item?.value == "" ? "" : item?.value}
                        onBlur={(e) => addItem(e, item)}
                        onChange={(e) => changeValue(item, e)}
                      />{" "}
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <img
                        onClick={() => deleteFunc(item)}
                        src={require("assets/deleteIcon.svg").default}
                        alt=""
                        className="curser-pointer py-2"
                        loading="lazy"
                      />{" "}
                    </div>
                  </div>
                  {/* {((getDroidsDataInfo?.totalBuyAmount * item?.value) / 100) <= item?.minAmount ? <div className='text-danger'>
                                    Minnimum Amount ${item?.minAmount} <br />
                                    Investment Amount ${(getDroidsDataInfo?.totalBuyAmount * item?.value) / 100}
                                </div> : ''} */}
                </div>
              );
            })}

            {getDroidsDataInfo?.coinsList?.reduce(
              (sum, item) => sum + (Number(item.value) || 0),
              0
            ) >= 100 ? (
              ""
            ) : (
              <>
                <div className="row mt-2">
                  <div className="col-lg-6 col-md-6 ">
                    <SingleSelectIconDropdown
                      className="coin-list-dropdown webkit-fill-available"
                      title={
                        !getSingleCoinData?.symbol
                          ? checkBalance?.length > 0
                            ? getCoinListData?.length > 0
                              ? "New Coin"
                              : "Loading Coin..."
                            : "Select Coin"
                          : `${
                              getSingleCoinData?.name?.length > 15
                                ? getSingleCoinData?.name?.substring(0, 16) +
                                  "..."
                                : getSingleCoinData?.name
                            }`
                      }
                      disableDropdown={
                        Object.keys(getSingleCoinData).length === 0 &&
                        checkBalance?.length === 0
                      }
                      key={refreshKey}
                      dropdown={getCoinListData}
                      getClickDropdown=""
                      setClickDropdown={handleDropdownChange}
                      selectedPortfolios={selectedPortfolios[0]?.exchangeId}
                      img={
                        <img
                          src={
                            !getSingleCoinData?.iconUrl
                              ? require(`assets/plusIcon.svg`).default
                              : getSingleCoinData?.iconUrl
                          }
                          alt="test1"
                          className="smallIcon "
                          loading="lazy"
                        />
                      }
                    />
                  </div>
                  <div className="col-lg-3 percentfield grayText mx-1">
                    <input
                      placeholder="0"
                      name="value"
                      type="text"
                      value={percentValue}
                      onBlur={(e) => addItem(e)}
                      onChange={(e) => setPercent(e)}
                      disabled={
                        getbidValue === undefined || !isDropdownValueSelected
                      }
                      style={{
                        cursor:
                          getbidValue === undefined || !isDropdownValueSelected
                            ? "not-allowed"
                            : "auto",
                      }}
                    />
                  </div>
                </div>

                {checkBalance?.length > 0 && (
                  <p className="mt-2 ms-1 d-flex">
                    {!getSingleCoinData?.name ? "" : `Current Price : `}
                    {getbidValue === undefined ? (
                      !getSingleCoinData?.name ? (
                        ""
                      ) : (
                        <div className="loading">...</div>
                      )
                    ) : (
                      `${
                        getbidValue === undefined || !getSingleCoinData?.name
                          ? ""
                          : "$ " + Number(getbidValue)?.toFixed(2)
                      }`
                    )}
                  </p>
                )}
              </>
            )}

            {errorCoinName?.status ? (
              <div className="error-message">
                Already selected {errorCoinName?.name}
              </div>
            ) : (
              ""
            )}
            {error.name === "value" && (
              <div className="error-message">{error?.text}</div>
            )}
          </>
        ) : (
          <div
            key={refreshKey}
            className={`btn-group simpledropdown droid-dropdown-height DMSInputSize`}
          >
            {/* NON INDEX DROPDOWN */}
            <button
              disabled={
                hasFailed ||
                (droidType?.droidType?.nav === "timemodedroid" &&
                  disabledInput) ||
                isDropdownDisableNonIndex
              }
              className=" btn dropdown-toggle d-flex justify-content-between align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
            >
              <div>
                {getDroidsDataInfo?.coinsList?.length > 0 ? (
                  <div className="d-flex gap-2 align-items-center">
                    {getDroidsDataInfo?.coinsList?.map((item) => (
                      <>
                        <img
                          src={item?.iconUrl}
                          alt="demo"
                          className="select_icon"
                          loading="lazy"
                        />{" "}
                        {item?.name}
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="h5-Four-lite">
                    {checkBalance?.length > 0
                      ? getCoinListData?.length > 0
                        ? "Select Coin"
                        : "Loading Coin..."
                      : "Select Coin"}
                  </div>
                )}
              </div>
              <img
                src={require("assets/arrowDown.svg").default}
                alt=""
                className="ML-6"
                loading="lazy"
              />
            </button>
            <ul className="dropdown-menu coin_list mb-2" id="coin_dropdown">
              {getCoinListData?.map((item, index) => (
                <li
                  key={index}
                  tabIndex={"0"}
                  className={`dropdown-item py-3 gap-2 d-flex ${
                    getCoinListData.length - 1 === index ? "" : "border-bottom"
                  }`}
                  onClick={() =>
                    droidType?.droidType?.nav === "timemodedroid" &&
                    disabledInput
                      ? ""
                      : handleCoinChange(item)
                  }
                >
                  <img
                    src={getIconUrl(item?.name?.split("/")[0])}
                    width="20"
                    height="20"
                    alt=""
                    loading="lazy"
                  />
                  {item?.name?.split("/")[0]} ({item?.symbol}){" "}
                  <p className="dropDown_price ">
                    {" "}
                    $ {Number(item?.price)?.toFixed(2)}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}

        {isDropdownDisableNonIndex && (
          <p className="error-message">Please select portfolio first</p>
        )}

        {state?.droidType?.name === "Index Mode Droid" ? (
          ""
        ) : (
          <p className="mt-2 ms-1">
            {getDroidsDataInfo?.coinsList?.length ? "Current Price : " : ""}{" "}
            {getbidValue === undefined ? (
              getDroidsDataInfo?.coinsList?.length > 0 ? (
                <div className="loading">...</div>
              ) : (
                ""
              )
            ) : getDroidsDataInfo?.coinsList?.length > 0 ? (
              "$ " + Number(getbidValue)?.toFixed(2)
            ) : (
              ""
            )}
          </p>
        )}
        {getDroidsDataInfo?.portfoliosIds?.length > 0 ? (
          getCoinListData?.length > 0 && !getWait ? (
            <p className="mt-2 ms-1">Wait for few seconds data loading</p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {(getErrorCoin || percentageErrorMessage) && (
          <div className="text-danger mt-2 ms-1">{percentageErrorMessage}</div>
        )}
        <div className="border-bottom my-4"></div>
        {children}
      </div>
    </>
  );
};

export default DroidModuleSettings;
