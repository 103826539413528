import React, { memo } from "react";

let Location = window.location.href;

function PopupModal({ children, id, key = '', backdrop, value }) {
  return (
    <div>
      <div className="modal popupModel fade" id={id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false" data-bs-backdrop={backdrop}>
        <div className={Location === 'http://localhost:3000/management/droids' ? "droidPopup modal-dialog modal-dialog-centered" : "modal-dialog modal-dialog-centered"}>
          <div className="modal-content modal-content-alignment">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupModal;
