import React, { useEffect, useState } from 'react'
import './carousel.scss'
import ArrowRightIcon from 'assets/JsImage/ArrowRightIcon'
import { ArrowLeftIcon } from 'assets'

const Carousel = (props) => {
    const { children, show } = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children?.length)
    const [touchPosition, setTouchPosition] = useState(null)

    useEffect(() => {
        setLength(children?.length)
    }, [children])

    const next = () => {
        if (currentIndex < (length - 2)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }
        const currentTouch = e.touches[0]?.clientX
        const diff = touchDown - currentTouch
        if (diff > 5) {
            next()
        }
        if (diff < -5) {
            prev()
        }
        setTouchPosition(null)
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {currentIndex > 0 &&
                    <div className='left-arrow-bg'>
                        <div onClick={prev} className="left-arrow d-flex justify-content-center align-items-center cursor">
                            <ArrowLeftIcon color='#0078E7' />
                        </div>
                    </div>}
                <div className="carousel-content-wrapper" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div className={`carousel-content show-${show}`} style={{ transform: `translateX(-${currentIndex * (140 / show)}%)` }}> {children} </div>
                </div>
                {currentIndex < (length - 2 - 1) &&
                    <>
                        {props?.children?.length < 3 ? '' : <div className='right-arrow-bg'>
                            <div onClick={next} className="right-arrow d-flex justify-content-center align-items-center cursor">
                                <ArrowRightIcon color='#0078E7' />
                            </div>
                        </div>}
                    </>
                }
            </div>
        </div>
    )
}

export default Carousel