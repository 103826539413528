import React from "react";

function Avtar({ height='150', width='150' }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 144.773 144.773"
        xmlSpace="preserve"
        height={height}
        width={width}
    >
      <circle cx="72.387" cy="72.386" r="72.386" fill="#13AEC0"></circle>
      <defs>
        <circle id="SVGID_1_" cx="72.387" cy="72.386" r="72.386"></circle>
      </defs>
      <clipPath id="SVGID_2_">
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <path
          fill="#F1C9A5"
          d="M107.053 116.94c-4.666-8.833-34.666-14.376-34.666-14.376s-30 5.543-34.666 14.376c-3.449 12.258-6.334 27.833-6.334 27.833h82s-1.949-16.7-6.334-27.833z"
        ></path>
        <path
          fill="#E4B692"
          d="M72.387 102.564s30 5.543 34.666 14.376c4.386 11.133 6.334 27.833 6.334 27.833h-41v-42.209z"
        ></path>
        <path fill="#F1C9A5" d="M64.22 84.607H80.554V111.943H64.22z"></path>
        <path fill="#E4B692" d="M72.387 84.607H80.554V111.943H72.387z"></path>
        <path
          fill="#DDAC8C"
          d="M64.22 97.273c1.469 4.217 7.397 6.634 11.751 6.634 1.575 0 3.107-.264 4.583-.747V84.606H64.22v12.667z"
          opacity="0.1"
        ></path>
        <path
          fill="#F1C9A5"
          d="M93.387 67.357c0-17.074-9.402-26.783-21-26.783s-21 9.709-21 26.783c0 22.966 9.402 30.917 21 30.917 11.597 0 21-8.908 21-30.917z"
        ></path>
        <path
          fill="#E4B692"
          d="M90.19 79.197c-3.807-.399-6.377-4.5-5.733-9.156.637-4.66 4.242-8.12 8.051-7.724 3.805.396 6.371 4.496 5.729 9.156-.638 4.661-4.24 8.118-8.047 7.724z"
        ></path>
        <path
          fill="#F1C9A5"
          d="M46.685 71.474c-.643-4.66 1.924-8.76 5.727-9.156 3.811-.397 7.416 3.063 8.055 7.724.642 4.656-1.93 8.758-5.734 9.156-3.808.393-7.41-3.064-8.048-7.724z"
        ></path>
        <path
          fill="#E4B692"
          d="M93.387 67.357c0-17.074-9.402-26.783-21-26.783v57.7c11.597 0 21-8.908 21-30.917z"
        ></path>
        <path
          fill="#265A68"
          d="M52.188 73.014s2.888-5.551 4.12-16.22c8.713 8.279 18.978 9.604 32.546 0 .334 7.779 2.609 12.631 5.276 16.22 1.641-13.441 5.266-20.066 4.049-27.13-9.043 1.496-12.227-5.936-25.793-5.936-13.568 0-16.587 7.149-25.018 6.51.001 8.393 4.019 12.865 4.82 26.556z"
        ></path>
        <path
          fill="#2D5972"
          d="M107.053 116.94c-2.99-5.66-16.375-9.966-25.61-12.331-.359 4.691-4.272 8.389-9.056 8.389-4.783 0-8.696-3.697-9.056-8.389-9.235 2.365-22.62 6.671-25.61 12.331-3.449 12.258-6.334 27.833-6.334 27.833h82s-1.949-16.7-6.334-27.833z"
        ></path>
      </g>
    </svg>
  );
}

export default Avtar;