import React from "react";

function BrokersIcon({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 20 22"
    >
      <path
        fill={color ? color : "#0078E7"}
        d="M2 22v-2h16v2H2zM2 2V0h16v2H2zm8 10c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0013 9c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0010 6c-.833 0-1.542.292-2.125.875A2.893 2.893 0 007 9c0 .833.292 1.542.875 2.125A2.893 2.893 0 0010 12zm-8 7c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 010 17V5c0-.55.196-1.02.588-1.412A1.923 1.923 0 012 3h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 19H2zm1.75-2h12.5a8.11 8.11 0 00-2.725-2.2c-1.067-.533-2.242-.8-3.525-.8s-2.458.267-3.525.8A8.11 8.11 0 003.75 17z"
      ></path>
    </svg>
  );
}

export default BrokersIcon;
