import { userTypeTrader } from 'features/profile/profileAction';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const ProfileTrader = (props) => {
    const dispatch = useDispatch()
    const [traderTypes, setTraderTypes] = useState([]);
    const { userToken } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(userTypeTrader(userToken)).then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
                setTraderTypes(res.payload);
            }
        });
    }, [])
    return (
        <div className="profileTextarea mt-2">
            <h5 className="mb-2">Type of trader</h5>
            <div className="gap-3 d-flex">
                <span className="badgesList2" tooltip={traderTypes?.find(item => item?._id === props?.type_trader)?.description} flow="down" >{traderTypes?.find(item => item?._id === props?.type_trader)?.title || 'Not Selected'}</span>
            </div>
            <div className="border-bottom mt-3"></div>
        </div>
    )
}

export default ProfileTrader