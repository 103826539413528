import React from "react";
import SellChart from "./Accessories/SellChart";
import BuyChart from "./Accessories/BuyChart";
import { useLocation } from "react-router-dom";
import ChartPercentage from "./Accessories/ChartPercentage";
import SMDChartPercentage from "./Accessories/SMDChartPercentage";

const SellBuyChart = ({ getGraphData, selectedPortfolios }) => {
  const { state } = useLocation();
  const droidType = state;
  const fetchMaxBalance = (portfolios) => {
    let maxValue = 0;
    portfolios?.forEach((portfolio) => {
      if (maxValue === 0) {
        maxValue = portfolio.balance;
      } else if (portfolio.balance <= maxValue) {
        maxValue = portfolio.balance;
      }
    });
    return maxValue;
  };
  const calculatePercentage = (val) => {
    const maxValue = fetchMaxBalance(selectedPortfolios);
    return val > 0 && maxValue > 0 ? ((val / maxValue) * 100)?.toFixed(2) : 0;
  };
  const takeProfitType = getGraphData?.takeProfit?.takeProfitType;
  const SMDProfitType =
    takeProfitType === "base" ? "%" : takeProfitType === "value" ? "$" : "";

  const pricePurchaseAmount =
    getGraphData?.buySellSettings?.pricePurchaseAmount;
  const targetProfitPercent = getGraphData?.takeProfit?.targetProfitPercent;
  let dollarPercentageCalculation =
    ((targetProfitPercent - pricePurchaseAmount) * 100) / pricePurchaseAmount;

  if (!isFinite(dollarPercentageCalculation)) {
    dollarPercentageCalculation = 0;
  }

  return (
    <div className="sellBuyChartContainer">
      <div className="DoughnutChartSell position-relative">
        <SellChart
          data={
            takeProfitType === "base"
              ? getGraphData?.takeProfit?.targetProfitPercent
              : dollarPercentageCalculation
          }
          getGraphData={getGraphData}
        />
        <div className="SMDChartPerCent position-absolute">
          <SMDChartPercentage getGraphData={getGraphData} PercentName="Sell" />
        </div>
      </div>
      <div className="buyCont">
        <BuyChart
          getGraphData={getGraphData}
          data={
            droidType === null
              ? !isNaN(parseFloat(getGraphData?.buySellSettings?.buyPercent))
                ? parseFloat(getGraphData?.buySellSettings?.buyPercent)
                : 0
              : getGraphData?.buySellSettings?.buyPurchaseAmount
              ? calculatePercentage(
                  getGraphData?.buySellSettings?.buyPurchaseAmount
                )
              : 0
          }
        />
      </div>
    </div>
  );
};

export default SellBuyChart;
