import * as Yup from "yup";

const twoFactorValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .test("no-empty-space", "Empty spaces are not allowed", (val) =>
      val ? !/\s/.test(val) : true
    )
    .test("isNumeric", "OTP must contain only numeric characters", (val) =>
      val ? /^\d+$/.test(val) : true
    )
    .test("len", "OTP must be exactly 4 characters", (val) =>
      val ? val.length === 4 : true
    )
    .trim(),
});

export default twoFactorValidationSchema;
