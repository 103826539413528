import PopupModal from 'components/PopupModal'
import React from 'react'

const ProfileDescription = ({profileData}) => {
    return (
        <PopupModal id='moreInformation' >
            <div className='popupReviewScreen m-3'>
                <div className="modal-header border-0">
                    <div className='addReviewCompanyLogo'>
                        <h3>Description</h3>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <div className="p-One text-break"> {profileData && profileData?.description ? profileData?.description : 'Description'} </div>
                </div>
            </div>
        </PopupModal>
    )
}

export default ProfileDescription