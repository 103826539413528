import React from "react";

function Search({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#0078E7"
        fillRule="evenodd"
        d="M12.924 13.985a7.75 7.75 0 111.06-1.06l3.546 3.545a.75.75 0 11-1.06 1.06l-3.546-3.545zM1.75 8a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Search;
