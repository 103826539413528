import React from "react";

function SOL({ht, wd, bg, text}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect width="44" height="44" fill={bg} rx="22"></rect>
      <path
        fill={text}
        // "url(#paint0_linear_1601_37368)"
        d="M32.392 27.586l-3.467 3.662a.806.806 0 01-.589.252H11.903a.407.407 0 01-.37-.238.39.39 0 01.075-.429l3.47-3.662a.804.804 0 01.587-.252h16.432a.408.408 0 01.37.238.392.392 0 01-.075.429zm-3.467-7.374a.806.806 0 00-.589-.253H11.903a.407.407 0 00-.37.239.39.39 0 00.075.428l3.47 3.662a.805.805 0 00.587.253h16.432a.407.407 0 00.37-.239.392.392 0 00-.075-.428l-3.467-3.662zM11.902 17.58h16.434a.815.815 0 00.59-.252l3.466-3.662a.395.395 0 00-.295-.667H15.665a.815.815 0 00-.587.252l-3.469 3.662a.394.394 0 00.293.667z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1601_37368"
          x1="32.5"
          x2="11.5"
          y1="11.779"
          y2="31.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.151" stopColor="#00FFA3"></stop>
          <stop offset="1" stopColor="#DC1FFF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SOL;