import React from 'react'
import { Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const labels = ["Up", "Down", "Buy", "Sell"];
const labels = ["Up", "Down", "Buy"];
export const options = {
  responsive: false,
  maintainAspectRatio: false,
  height: 400,
  width: 600,
  categoryPercentage: 0.8,
  layout: {
    padding: 15
  },
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        // display: false,
        drawOnChartArea: false,
        drawBorder: false,
        borderColor: "green",
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
        borderColor: "green",
        drawOnChartArea: false,
        drawBorder: false,
        borderWidth: 5,
      },
    },
  },
};


const SAfeV2ChartLabels = ({ getGraphData }) => {
  const { t } = useTranslation()
  const countUp = getGraphData?.safetyNetRules?.filter(item => item.pricetrend === "Up").length;
  const countDown = getGraphData?.safetyNetRules?.filter(item => item.pricetrend === "Down").length;
  const countBuy = getGraphData?.safetyNetRules?.filter(item => item.action === "Buy").length;
  const countSell = getGraphData?.safetyNetRules?.filter(item => item.action === "Sell").length;

  const data = {
    labels,
    datasets: [
      {
        barThickness: 8,
        borderRadius: 3,
        borderSkipped: false,
        label: "Dataset 1",
        data: [100, 60, 60, 100],
        borderColor: [countUp > 0 ? "#727EEE" : '#e0e0e0', countDown > 0 ? "#727EEE" : '#e0e0e0', countBuy > 0 ? "#95C6FF" : '#e0e0e0', countSell > 0 ? "#95C6FF" : '#e0e0e0'],
        backgroundColor: [countUp > 0 ? "#727EEE" : '#e0e0e0', countDown > 0 ? "#727EEE" : '#e0e0e0', countBuy > 0 ? "#95C6FF" : '#e0e0e0', countSell > 0 ? "#95C6FF" : '#e0e0e0'],
      },
    ],
  };
  return (
    <div className=''>
      <div className='safeV2LabelsLeft d-flex justify-content-between'>
        <div className='safeV2Labels'>
          <h6 className='mb-3'>{t('up')}</h6>
          <h6 className='mb-3'>{t('down')}</h6>
          <h6 className='mb-3'>{t('buy')}</h6>
          {/* <h6 className='mb-3'>Sell</h6> */}
        </div>
        <Bar options={options} data={data} className='safeModeBar' />
        <div className='d-flex justify-content-end'>
          <div className='safeV2LabelsRight'>
            <p className='safelabelrightMb'>{countUp}</p>
            <p className='safelabelrightMb'>{countDown}</p>
            <p className='safelabelrightMb'>{countBuy}</p>
            {/* <p className='safelabelrightMb'>{countSell}</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SAfeV2ChartLabels