import React from "react";

function UserIcon({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 22 16"
    >
      <path
        fill={color ? color : "#0078E7"}
        d="M1 16a1 1 0 01-1-1v-1.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 011.6 10.55a14.865 14.865 0 013.15-1.163A13.776 13.776 0 018 9c1.1 0 2.183.129 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563V15a1 1 0 01-1 1H1zm18 0a1 1 0 01-1-1v-2c0-.733-.204-1.438-.612-2.113-.313-.516.087-1.41.662-1.225.75.242 1.45.538 2.1.888.6.333 1.058.704 1.375 1.112.317.409.475.855.475 1.338v2a1 1 0 01-1 1h-2zM8 8c-1.1 0-2.042-.392-2.825-1.175C4.392 6.042 4 5.1 4 4s.392-2.042 1.175-2.825C5.958.392 6.9 0 8 0s2.042.392 2.825 1.175C11.608 1.958 12 2.9 12 4s-.392 2.042-1.175 2.825C10.042 7.608 9.1 8 8 8zm10-4c0 1.1-.392 2.042-1.175 2.825C16.042 7.608 15.1 8 14 8c-.183 0-.417-.02-.7-.062-.33-.049-.456-.431-.273-.71.248-.375.452-.777.61-1.203C13.879 5.375 14 4.7 14 4s-.121-1.375-.363-2.025a5.92 5.92 0 00-.606-1.198c-.192-.292-.078-.702.269-.74.233-.025.467-.037.7-.037 1.1 0 2.042.392 2.825 1.175C17.608 1.958 18 2.9 18 4zM2 13.2a.8.8 0 00.8.8h10.4a.8.8 0 00.8-.8.943.943 0 00-.137-.5.977.977 0 00-.363-.35c-.9-.45-1.808-.788-2.725-1.013a11.615 11.615 0 00-5.55 0c-.917.225-1.825.563-2.725 1.013a.97.97 0 00-.5.85zM8 6c.55 0 1.021-.196 1.413-.588C9.804 5.021 10 4.55 10 4c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 008 2c-.55 0-1.02.196-1.412.588A1.923 1.923 0 006 4c0 .55.196 1.02.588 1.412C6.979 5.804 7.45 6 8 6z"
      ></path>
    </svg>
  );
}

export default UserIcon;
