import React, { useState, useEffect } from "react";
import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {
  setDroidTimeError,
  clearDroidTimeError,
} from "features/ErrorTimeDroid/ErrorTimeDroid";

const TimeModeDroidSettingChild = ({
  droidDataDispatch,
  minBalance,
  minAmount,
  getbidValue,
}) => {
  const { state } = useLocation();
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const [getError, setError] = useState({ name: "", error: "" });
  const [getErrorpurchaseAmount, setErrorpurchaseAmount] = useState({
    isValid: false,
    error: "",
  });
  const droidType = state;
  const { currencyData } = useSelector((state) => state?.defaultState);
  const [showNumbers, setShowNumbers] = useState(false);

  useEffect(() => {
    localStorage.getItem("timeLessThanCurrent", false);
  }, []);
  const dispatch = useDispatch();
  const handleChangeTimeSettings = (event) => {
    const onlyNumberRegex = /^\d*\.?\d{0,2}$/;
    const onlyWholeNumberRegex = /^\d+$/;
    const { name, value } = event.target;
    if (name === "periodAt") {
      droidDataDispatch({
        timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
      });
    } else if (parseFloat(minBalance) === 0) {
      setError({ name: name, error: "Please choose a portfolio." });
      droidDataDispatch({
        timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: "" },
      });
    } else if (getDroidsDataInfo?.coinsList?.length === 0) {
      setError({ name: name, error: "Please choose a coin." });
      droidDataDispatch({
        timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: "" },
      });
    } else if (
      parseFloat(minBalance) < parseFloat(value) &&
      name !== "pricePurchaseAmount" &&
      getDroidsDataInfo?.timeSettings?.purchaseAmountType === "dollar" &&
      name !== "periodEvery"
    ) {
      if (
        name === "purchaseAmount" &&
        getDroidsDataInfo?.timeSettings?.purchaseAmountType === "dollar"
      ) {
        if (value.length > 8) {
          setErrorpurchaseAmount({
            isValid: true,
            error: `The value should not exceed 8 digits.`,
          });
          return;
        } else {
          setErrorpurchaseAmount({
            isValid: true,
            error: `Please verify the maximum entered amount $${parseFloat(
              minBalance
            )?.toFixed(2)}`,
          });
        }
        dispatch(setDroidTimeError({ key: "purchaseAmount" }));
        droidDataDispatch({
          timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
        });
      } else {
        setErrorpurchaseAmount({ isValid: true, error: `` });
        dispatch(clearDroidTimeError({ key: "purchaseAmount" }));
        droidDataDispatch({
          timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
        });
      }
    } else if (
      name === "purchaseAmount" &&
      100 < parseFloat(value) &&
      getDroidsDataInfo?.timeSettings?.purchaseAmountType === "percent"
    ) {
      setErrorpurchaseAmount({ isValid: true, error: `Enter less than 100%` });
      dispatch(setDroidTimeError({ key: "purchaseAmount" }));
      return;
    } else if (
      parseFloat(value) > 0 &&
      parseFloat(value) < 100 &&
      name === "purchaseAmount" &&
      getDroidsDataInfo?.timeSettings?.purchaseAmountType === "percent"
    ) {
      const percentageOfValue =
        (parseFloat(minBalance) * parseFloat(value)) / 100;
      if (percentageOfValue.toFixed(2) < minAmount) {
        setErrorpurchaseAmount({
          isValid: true,
          error: `Amount of the given percentage value must be greater than minimum amount of $${parseFloat(
            minAmount
          )?.toFixed(
            2
          )} current amount for the given input is $${percentageOfValue.toFixed(
            2
          )}`,
        });
        dispatch(setDroidTimeError({ key: "purchaseAmount" }));
        droidDataDispatch({
          timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
        });
      } else {
        setErrorpurchaseAmount({ isValid: true, error: `` });
        dispatch(clearDroidTimeError({ key: "purchaseAmount" }));
        droidDataDispatch({
          timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
        });
      }
    } else if (
      name === "purchaseAmount" &&
      getDroidsDataInfo?.timeSettings?.purchaseAmountType === "dollar"
    ) {
      if (!onlyNumberRegex.test(value)) {
        setErrorpurchaseAmount({
          isValid: true,
          error:
            "Please input only numbers with a maximum of two decimal places.",
        });
        return;
      }
      if (parseFloat(minAmount) >= parseFloat(value)) {
        setErrorpurchaseAmount({
          isValid: true,
          error: `Please input value greater than ${minAmount}`,
        });
        dispatch(setDroidTimeError({ key: "purchaseAmount" }));
        droidDataDispatch({
          timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
        });
      } else if (value.startsWith(".")) {
        setErrorpurchaseAmount({
          isValid: true,
          error: "Value cannot start with a dot",
        });
        return;
      } else {
        setErrorpurchaseAmount({ isValid: true, error: `` });
        dispatch(clearDroidTimeError({ key: "purchaseAmount" }));
        droidDataDispatch({
          timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
        });
      }
    } else {
      if (name === "periodEvery" ? parseFloat(value) === 0 : "") {
        if (parseFloat(value) === 0)
          setError({ name: name, error: "Only zero value is not allowed" });
      } else if (value.startsWith(".")) {
        setError({ name: name, error: "Value cannot start with a dot" });
      } else if (getbidValue < value) {
        console.log(name, value, getbidValue, "name");
        setError({
          name: name,
          error:
            "Please enter a value less than or equal to Coin Current Price",
        });
        return;
      } else if (name === "firstBuyDate") {
        const parts = value.split("-");
        if (parts.length === 3) {
          const [year, month, day] = parts;

          droidDataDispatch({
            timeSettings: {
              ...getDroidsDataInfo?.timeSettings,
              [name]: `${day}/${month}/${year}`,
            },
          });
        }
      } else if (name === "periodEvery") {
        if (value === "") {
          droidDataDispatch({
            timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
          });
        } else if (!onlyWholeNumberRegex.test(value)) {
          setError({ name: name, error: "Please enter only numbers." });
        } else if (parseFloat(value) > 365) {
          setError({
            name: name,
            error: "The value should not exceed 365 days.",
          });
        } else if (value.length > 8) {
          setError({
            name: name,
            error: "The value should not exceed 8 digits.",
          });
        } else {
          setError({ name: "", error: "" });
          droidDataDispatch({
            timeSettings: { ...getDroidsDataInfo?.timeSettings, [name]: value },
          });
        }
      } else {
        if (value.length > 8) {
          setError({
            name: name,
            error: "The value should not exceed 8 digits.",
          });
        } else {
          setError({ name: "", error: "" });
          if (!onlyNumberRegex.test(value)) {
            setError({
              name: name,
              error:
                "Please input only numbers with a maximum of two decimal places.",
            });
          } else {
            droidDataDispatch({
              timeSettings: {
                ...getDroidsDataInfo?.timeSettings,
                [name]: value,
              },
            });
          }
        }
      }
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const formattedDate = getDroidsDataInfo?.timeSettings?.firstBuyDate
      ? formatDateToISO(getDroidsDataInfo?.timeSettings?.firstBuyDate)
      : formatDateToISO(currentDate); // Convert "dd/mm/yy" to ISO format "yyyy-mm-dd"
    document.getElementById("dateInput").value = formattedDate;
  }, [getDroidsDataInfo?.timeSettings?.firstBuyDate]);

  const formatDateToISO = (dateString) => {
    const parts = dateString?.split("/");
    if (parts?.length === 3) {
      const [day, month, year] = parts;
      const fullYear = `${year}`;
      return `${fullYear}-${month}-${day}`;
    }
    return dateString;
  };

  useEffect(() => {
    // if (getDroidsDataInfo?.timeSettings?.purchaseAmount > parseFloat(minBalance)) {
    //   droidDataDispatch({ timeSettings: { ...getDroidsDataInfo?.timeSettings, purchaseAmount: '' } })
    // }
  }, [minBalance]);

  const currentTime = moment();
  const time30MinutesFromNow = moment(
    getDroidsDataInfo?.timeSettings?.periodAt
  ).add(30, "minutes");

  const BuyPurchaseType = (item, id) => {
    setErrorpurchaseAmount({ isValid: false, error: "" });
    droidDataDispatch({
      timeSettings: {
        ...getDroidsDataInfo?.timeSettings,
        purchaseAmount: "",
        purchaseAmountType: item?.name,
      },
    });
  };
  const currentDate1 = new Date();
  const year = currentDate1?.getFullYear();
  const month = String(currentDate1?.getMonth() + 1)?.padStart(2, "0");
  const day = String(currentDate1?.getDate())?.padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [isLessThan30Minutes, setIsLessThan30Minutes] = useState(false);

  const handleTimeChange = (selectedTime) => {
    localStorage.setItem("timeLessThanCurrent", false);
    const formattedTime = moment(selectedTime?._d).format("HH:mm");
    const selected = moment(selectedTime?._d, "HH:mm");

    if (currentTime.isBefore(time30MinutesFromNow)) {
      setIsLessThan30Minutes(true);
    } else {
      setIsLessThan30Minutes(false);
    }

    if (
      state?.Duplicate === "edit" &&
      !selected.isBefore(currentTime) &&
      getDroidsDataInfo?.timeSettings?.nextBuyDate === today
    ) {
      droidDataDispatch({
        timeSettings: {
          ...getDroidsDataInfo?.timeSettings,
          periodAt: formattedTime,
        },
      });
    } else if (
      state?.Duplicate !== "edit" &&
      state?.Duplicate !== "Duplicate"
    ) {
      droidDataDispatch({
        timeSettings: {
          ...getDroidsDataInfo?.timeSettings,
          periodAt: formattedTime,
        },
      });
    } else {
      droidDataDispatch({
        timeSettings: {
          ...getDroidsDataInfo?.timeSettings,
          periodAt: formattedTime,
        },
      });
    }
  };

  const timeGet = () => {
    // Split the string into hours and minutes
    let timeString = !getDroidsDataInfo?.timeSettings?.periodAt
      ? ""
      : getDroidsDataInfo?.timeSettings?.periodAt;
    let timeArray = timeString?.split(":");
    let hours = parseInt(timeArray[0], 10);
    let minutes = parseInt(timeArray[1], 10);

    // Create a new Date object and set hours and minutes
    let currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    return currentDate;
  };

  const time111 = timeGet();
  const today = moment()?.format("DD/MM/YYYY");

  const disabledInput =
    state?.Duplicate === "edit" &&
    getDroidsDataInfo?.timeSettings?.firstBuyDate === today;
  const handleDropdownSelect = (option) => {
    droidDataDispatch({
      timeSettings: {
        ...getDroidsDataInfo?.timeSettings,
        periodEvery: option + 1,
      },
    });
    setShowNumbers(false);
  };

  return (
    <>
      <h4 className="mb-4">Time Settings</h4>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-start align-items-center gap-2">
            <div className="d-flex align-items-center">
              <h6>
                Purchase{" "}
                {currencyData?.find(
                  (obj) =>
                    obj.name ===
                    getDroidsDataInfo?.timeSettings?.purchaseAmountType
                )?.label === "$"
                  ? "Amount"
                  : "Percentage"}
              </h6>
              {!getDroidsDataInfo?.timeSettings?.purchaseAmount ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark
              toolTipContent={
                "The Purchase Order is the first order the bot will create when starting a new deal."
              }
            />
          </div>
          <div className="d-flex gap-2">
            <div
              className={`my-2 btn-group simpledropdown w-25 small-dropdown-height `}
            >
              <button
                disabled={
                  droidType?.droidType?.nav === "timemodedroid" && disabledInput
                }
                className="btn dropdown-toggle d-flex justify-content-between align-items-center "
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
              >
                <div>
                  {
                    currencyData?.find(
                      (obj) =>
                        obj.name ===
                        getDroidsDataInfo?.timeSettings?.purchaseAmountType
                    )?.label
                  }
                </div>
                <img
                  src={require("assets/arrowDown.svg").default}
                  alt=""
                  className="ML-6"
                  loading="lazy"
                />
              </button>
              <ul className="dropdown-menu">
                {currencyData?.map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown-item py-3 justify-content-between d-flex ${
                      currencyData?.length - 1 == index ? "" : "border-bottom"
                    }`}
                    onClick={() => BuyPurchaseType(item, "purchaseAmountType")}
                  >
                    {item?.label}{" "}
                    {getDroidsDataInfo?.timeSettings?.purchaseAmountType ==
                    item?.name ? (
                      <img
                        src={require("assets/rightMark.svg").default}
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <input
              disabled={disabledInput || !minAmount}
              type="text"
              value={
                !getDroidsDataInfo?.timeSettings?.purchaseAmount
                  ? ""
                  : getDroidsDataInfo?.timeSettings?.purchaseAmount
              }
              name="purchaseAmount"
              onChange={handleChangeTimeSettings}
              className="small-input droid-dropdown-height mt-2"
              placeholder={
                currencyData?.find(
                  (obj) =>
                    obj.name ===
                    getDroidsDataInfo?.timeSettings?.purchaseAmountType
                )?.label === "$"
                  ? "Amount"
                  : "Percentage"
              }
            />
          </div>
          {getErrorpurchaseAmount.isValid &&
            getDroidsDataInfo?.timeSettings?.purchaseAmountType ===
              "percent" && (
              <div className="text-danger">{getErrorpurchaseAmount?.error}</div>
            )}
          {getErrorpurchaseAmount.isValid &&
            getDroidsDataInfo?.timeSettings?.purchaseAmountType ===
              "dollar" && (
              <div className="text-danger">{getErrorpurchaseAmount?.error}</div>
            )}
        </div>

        <div className="col-lg-6">
          <div className="d-flex justify-content-start align-items-center gap-2">
            <div className="d-flex align-items-center">
              <h6>Price - Purchase Value</h6>
              {!getDroidsDataInfo?.timeSettings?.pricePurchaseAmount ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark
              toolTipContent={"What is the value of the digital assets to buy."}
            />
          </div>
          <div className="d-flex gap-2">
            <div
              className={`my-2 btn-group simpledropdown w-25 small-dropdown-height `}
            >
              <button
                disabled={
                  droidType?.droidType?.nav === "timemodedroid" && disabledInput
                }
                className="btn dropdown-toggle align-items-center "
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
              >
                <div>
                  {
                    currencyData?.find(
                      (obj) =>
                        obj.name ===
                        getDroidsDataInfo?.timeSettings?.pricePurchaseAmountType
                    )?.label
                  }
                </div>
              </button>
            </div>
            <input
              disabled={disabledInput || !minAmount}
              type="text"
              value={
                getDroidsDataInfo?.timeSettings?.pricePurchaseAmount ===
                undefined
                  ? ""
                  : getDroidsDataInfo?.timeSettings?.pricePurchaseAmount
              }
              name="pricePurchaseAmount"
              onChange={handleChangeTimeSettings}
              className="small-input droid-dropdown-height mt-2"
              placeholder="Amount"
            />
          </div>
          {getError?.name === "pricePurchaseAmount" && (
            <div className="text-danger">⚠️ {getError?.error}</div>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="d-flex align-items-center gap-2 col-6">
            <div className="d-flex align-items-center">
              <h6>Period - Every</h6>
              {!getDroidsDataInfo?.timeSettings?.periodEvery ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark
              toolTipContent={"The Purchase Period of when to buy in days"}
            />
          </div>

          <div
            className=" position-relative"
            onMouseEnter={() => setShowNumbers(true)}
            onMouseLeave={() => setShowNumbers(false)}
          >
            <div className="d-flex position-relative ">
              <input
                disabled={disabledInput}
                type="text"
                value={
                  !getDroidsDataInfo?.timeSettings?.periodEvery
                    ? ""
                    : getDroidsDataInfo?.timeSettings?.periodEvery
                }
                name="periodEvery"
                onChange={handleChangeTimeSettings}
                className=""
              />
              <span className="position-absolute percentage-input-days P5 text-third">
                Days
              </span>
            </div>

            {showNumbers && (
              <div className="dropdown-options position-absolute bg-white w-100">
                {[...Array(30).keys()].map((option, index) => (
                  <div
                    key={index}
                    className="dropdown-day px-3 py-1 border-bottom"
                    onClick={() => handleDropdownSelect(option)}
                  >
                    {option + 1}
                  </div>
                ))}
              </div>
            )}
          </div>
          {getError?.name == "periodEvery" && (
            <div className="text-danger">⚠️ {getError?.error}</div>
          )}
        </div>

        <div className="col-lg-6">
          <div className="d-flex justify-content-start align-items-center gap-2">
            <div className="d-flex align-items-center">
              <h6>Period - At</h6>
              {!getDroidsDataInfo?.timeSettings?.periodAt ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark
              toolTipContent={"The Purchase Time of the Day"}
            />
          </div>
          <Datetime
            className="dateHight"
            value={new Date(time111)}
            onChange={handleTimeChange}
            dateFormat={false}
          />
          {/* {localStorage.getItem('timeLessThanCurrent') === 'true' ?  <div className='text-danger'>⚠️ {"time error"}</div>:''} */}
        </div>
      </div>

      <div className="mt-4">
        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-flex align-items-center">
            <h6>First Buy Date</h6>
          </div>
          <div className="questionMarkContainer d-flex align-items-center justify-content-center">
            <TooltipQuestionMark
              toolTipContent={"First Purchase to start the Buy order"}
            />
          </div>
        </div>
        <input
          min={formattedDate}
          disabled={
            disabledInput
              ? true
              : state?.Duplicate === "Duplicate"
              ? false
              : !getDroidsDataInfo?.dealRules?.list
              ? false
              : getDroidsDataInfo?.dealRules?.list[0]?.isSellTriggered ||
                getDroidsDataInfo?.dealRules?.list[0]?.isBuyTriggered
          }
          type="date"
          onKeyDown={(e) => e.preventDefault()}
          id="dateInput"
          className="dropdownClients half-input"
          name="firstBuyDate"
          onChange={handleChangeTimeSettings}
        />
      </div>
    </>
  );
};

export default TimeModeDroidSettingChild;
