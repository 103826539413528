import React from 'react'
const DroidDoughnutChartLegends = ({ data }) => {
  return (
    <div className='DoughnutLegendContainer align-items-center justify-content-center gap-3 mt-4'>
      <div className='DoughnutLegendContainer d-flex gap-2 justify-content-start align-items-center mb-2'>
        <div className='d-flex gap-2'>
          <div className='droidColor' style={{ backgroundColor: '#14AA39' }}></div>
          <h6 className='droidCountPL'>{data?.profit ? data.profit : '0'}</h6>
        </div>
        <p className='paragraphLedends'>Profitable</p>
      </div>
      <div className='DoughnutLegendContainer d-flex gap-2 justify-content-start align-items-center mb-2'>
        <div className='d-flex gap-2'>
          <div className='droidColor' style={{ backgroundColor: '#DE2A20' }}></div>
          <h6 className='droidCountPL'>{data?.loss ? data.loss : '0'}</h6>
        </div>
        <p className='paragraphLedends'>At Loss</p>
      </div>
    </div>
  )
}

export default DroidDoughnutChartLegends