import React, { useState } from 'react'
import PopupModal from '..'
import Button from 'components/Button'
import SecondryButton from 'components/Button/SecondryButton'
import { useDispatch, useSelector } from 'react-redux'
import { postaskQuestion } from 'features/Community/communityAction'

const AskQustionPopup = (props) => {
    const [getAstQustionInput, setAstQustionInput] = useState('')
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.auth);
    const { userProfileInfo } = useSelector((state) => state?.profile);
    const handleAskQuestion = (e) => {
        const newValue = e.target.value.replace(/^\s+/, '');
        setAstQustionInput(newValue)
    }
    const askQuestionSubmit = () => {
        let messageSend = true
        props?.setLoading(true);
        if (localStorage.getItem('role') === 'retailer') {
            let brokerId = props?._id
            let name = userProfileInfo?.full_name
            dispatch(postaskQuestion({ userToken, brokerId, getAstQustionInput, name, messageSend })).then((res) => {
                if (res.meta && res.meta.requestStatus === "fulfilled") {
                    props?.setLoading(false)
                } else {
                    props?.setLoading(false)
                }
            });
            setAstQustionInput('')
        }
        else {
            let brokerId = localStorage.getItem('userID')
            if (brokerId) {
                let name = userProfileInfo?.full_name
                dispatch(postaskQuestion({ userToken, brokerId, getAstQustionInput, name, messageSend })).then((res) => {
                    if (res.meta && res.meta.requestStatus === "fulfilled") {
                        props?.setLoading(false)
                    } else {
                        props?.setLoading(false)
                    }
                });
                setAstQustionInput('')
            }
        }
    }
    return (
        <PopupModal id={'askqustion'} backdrop={false} >
            <div className='popupReviewScreen'>
                <div className="modal-header border-0">
                    <div className='addReviewCompanyLogo'>
                        <h4 className=''>{`Write a message to ${props?.full_name}`}</h4>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAstQustionInput('')} />
                </div>
                <div className="modal-body">
                    <div className="card border-0 w-100 mb-3">
                        <h5>Full Name</h5>
                        <input type="text" placeholder="Name" disabled value={props?.full_name} className="w-75 mb-4" />
                        <h5>Message</h5>
                        <div className="mb-4"> <textarea rows="3" value={getAstQustionInput} className={`form-control w-100 p-3 bg-white mt-2`}
                            placeholder="Type your message" onChange={handleAskQuestion} maxLength={250} />
                            <span className="float-right">{getAstQustionInput?.length}/250</span>
                        </div>
                    </div>
                    <div className="d-flex gap-3 justify-content-end ">
                        <SecondryButton text='Cancel' databsdismiss="modal" arialabel={"Close"} />
                        <Button text="Submit" disabled={getAstQustionInput?.length < 3} databsdismiss="modal" arialabel={"Close"} handleClick={() => askQuestionSubmit()} />
                    </div>
                </div>
            </div>
        </PopupModal>
    )
}

export default AskQustionPopup