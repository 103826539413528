import React, { useRef, memo } from "react";
import PopupModal from "./PopupModal";
import Button from './Button'

function UploadFile({ setLicenseFile, licenseFile, submitLicense }) {
  let inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  const changeFile = (e) =>{
    setLicenseFile(e.target.files)
  }
  const CancleFile = () =>{
    setLicenseFile('')
    inputRef = null
  }
  return (
    <PopupModal id={"Certificate"} backdrop={false}>
        <div className="modal-header border-0  px-4">
          <h3 className="modal-title" id="exampleModalLabel"> Upload Certificate </h3>
          <button type="button" className="btn-close btn-sm " data-bs-dismiss="modal" aria-label="Close" onClick={()=>CancleFile()}/>
        </div>
        <div className="modal-body  py-1 ">
          <div className="card border-0 w-100 upload-color-bg">
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img accept="image/*" src={require(`assets/upload.svg`).default} className="p-2 curser-pointer" alt="" onClick={handleClick} loading='lazy'/>
            </div>
            <input className="d-none" type="file" value={licenseFile?.length > 0 ? null : licenseFile} ref={inputRef} onChange={(e) => {changeFile(e)}} accept="image/png, application/pdf, image/jpeg" />
            <div className="card-body text-center">
              <span className="h5 P4 fw-600"> Drag & drop files or
                <small className="text-primary curser-pointer" onClick={handleClick}> Browse </small>
              </span>
              <br />
              <span className="text-muted"> Supported formates: JPEG, PNG, PDF </span>
            </div>
          </div>
        </div>
        <p className="px-3 py-0"> {licenseFile && `Upload File Name : ${licenseFile[0]?.name}`}</p>
        <div className="modal-footer border-0">
          <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={()=>CancleFile()}> Cancel </button>
          <Button disabled={licenseFile ? false : true} text="Upload" databsdismiss="modal" handleClick={submitLicense} />
        </div>
    </PopupModal>
  );
}

export default memo(UploadFile);
