import React  from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import numeral from "numeral";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = Array.from({ length: 25 }, (_, i) => String(i + 1));

function LineChartDark({dataChart, addonsContainer }) {  
  const data = {
    labels,
    datasets: [
      {
        label: "Price",
        data: dataChart,
        borderColor: "rgba(255,20,30)",
        borderWidth: 2,
        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 30);
          gradient.addColorStop(0, "rgba(255,20,30)");
          gradient.addColorStop(1, "rgb(27, 26, 29)");
          return gradient;
        },
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: true,
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = numeral(context.parsed.y).format("$0,0");
            const price = dataChart[context.parsed.x];
            const formattedPrice = price ? numeral(price).format("0,0.00") : "N/A";
            return `${label}: ${value} (${formattedPrice})`;
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.1,
      },
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index, values) {
            return numeral(value).format("0,0");
          },
        },
      },
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="d-flex flex-column justify-content-center h-75">
      <div className="card-body w-100 mx-1 mt-4 align-items-center  capitalMarketChart">
          <Line options={options} data={data} style={{ height:"8vh" }} />
      </div>
      {labels && !addonsContainer && (
        <div className="d-flex justify-content-between capitalMarketChartLabels mt-2">
          <span>12:00</span>
          <span>Nov 17 00:00</span>
          <span>15:00</span>
        </div>
      )}
    </div>
  );
}

export default LineChartDark;
