import { createSlice } from "@reduxjs/toolkit";
import {
  uploadLicense,
  dashboardGraph,
  historicGlobalMetrics,
  topCoinList,
  dashboardDroid,
  dashboardHealth,
  addOns,
} from "./dashboardAction";

const initialState = {
  dashboardLicenseInfo: null,
  isuploadLicense: false,
  dashboardGraphInfo: null,
  globalMetrics: null,
  topCoinListInfo: null,
  dashboardDroidInfo: null,
  dashboardHealthInfo: null,
  addOnsData: null,
  trendROI: { trend: 0, roi: 0 },
};

const dashboardSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    clearisuploadLicense: (state) => {
      state.isuploadLicense = false;
    },

    setTrendROI: (state, { payload }) => {
      state.trendROI = payload;
    },
    setDashboardGraphInfo: (state, action) => {
      state.dashboardGraphInfo = action.payload;
    },
  },
  extraReducers: {
    [uploadLicense.pending]: (state) => {},
    [uploadLicense.fulfilled]: (state, { payload }) => {
      state.dashboardLicenseInfo = payload;
      state.isuploadLicense = true;
    },
    [uploadLicense.rejected]: (state, { payload }) => {},

    [dashboardGraph.pending]: (state) => {},
    [dashboardGraph.fulfilled]: (state, { payload }) => {
      state.dashboardGraphInfo = payload;
    },
    [dashboardGraph.rejected]: (state, { payload }) => {},

    [historicGlobalMetrics.pending]: (state) => {},
    [historicGlobalMetrics.fulfilled]: (state, { payload }) => {
      state.globalMetrics = payload;
    },
    [historicGlobalMetrics.rejected]: (state, { payload }) => {},

    [topCoinList.pending]: (state) => {},
    [topCoinList.fulfilled]: (state, { payload }) => {
      state.topCoinListInfo = payload;
    },
    [topCoinList.rejected]: (state, { payload }) => {},

    [dashboardDroid.pending]: (state) => {},
    [dashboardDroid.fulfilled]: (state, { payload }) => {
      state.dashboardDroidInfo = payload;
    },
    [dashboardDroid.rejected]: (state, { payload }) => {},

    [dashboardHealth.pending]: (state) => {},
    [dashboardHealth.fulfilled]: (state, { payload }) => {
      state.dashboardHealthInfo = payload;
    },

    [addOns.pending]: (state) => {},
    [addOns.fulfilled]: (state, { payload }) => {
      state.addOnsData = payload;
    },

    [addOns.rejected]: (state, { payload }) => {},
  },
});

export const { clearisuploadLicense, setTrendROI, setDashboardGraphInfo } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
