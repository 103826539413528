import React from 'react'

function Loader({className}) {
    return (
        <div className={`d-flex justify-content-center`}>
            <div className={`${className} spinner-border`} role="status">
                <span className="sr-only">Loading <div className="loading">...</div></span>
            </div>
        </div>
    )
}

export default Loader