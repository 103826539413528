import React from "react";

function RefreshIcon({width=25,height=25,color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        stroke="#0078E7"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M14.636 8.77l4.5 3.428 3.428-4.5M15.068 18.233a8 8 0 114-6.928"
      ></path>
    </svg>
  );
}

export default RefreshIcon;
