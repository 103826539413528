import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDroidSimpleError,
  setDroidSimpleError,
} from "features/ErrorSimpleDroid/ErrorSimpleDroid";

const SimpleModeDroidChild = ({
  getError,
  setError,
  hasFailed,
  droidDataDispatch,
  minBalance,
  minAmount,
  getbidValue,
  selectedPortfolio,
}) => {
  const { currencyData } = useSelector((state) => state?.defaultState);
  const { porfolioByUserIdData } = useSelector((state) => state?.management);
  const [getErrorbuyPurchaseAmount, setErrorbuyPurchaseAmount] = useState({
    isValid: false,
    error: "",
  });
  const [getErrorpricePurchaseAmount, setErrorpricePurchaseAmount] = useState({
    isValid: false,
    error: "",
  });
  const [portfolioName, setportfolioName] = useState("");
  const [pricePurchaseGreaterThanMarket, setpricePurchaseGreaterThanMarket] =
    useState(false);

  const { state } = useLocation();
  const droidType = state;
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const valueOfSimpleWarning = useSelector((state) => state?.errorDroidSimple);

  useEffect(() => {
    setErrorbuyPurchaseAmount({ isValid: false, error: "" });
    setErrorpricePurchaseAmount({ isValid: false, error: "" });
  }, [minAmount]);

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role !== "broker") {
      if (getDroidsDataInfo?.portfoliosIds?.length > 0) {
        const filteredPortfolio = porfolioByUserIdData?.find(
          (i) => i._id === getDroidsDataInfo.portfoliosIds[0].portfolioId
        );

        if (filteredPortfolio && filteredPortfolio.exchangeName === "gemini") {
          setportfolioName(filteredPortfolio.exchangeName);
          droidDataDispatch({
            buySellSettings: {
              ...getDroidsDataInfo?.buySellSettings,
              pricePurchaseAmount: "",
              startOrderType: "limit",
            },
          });
        } else {
          droidDataDispatch({
            buySellSettings: {
              ...getDroidsDataInfo?.buySellSettings,
              pricePurchaseAmount: "",
              startOrderType: "market",
            },
          });
          setportfolioName("");
        }
      }
    }
  }, [getDroidsDataInfo?.portfoliosIds]);

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "broker") {
      if (selectedPortfolio[0]?.exchangeName === "gemini") {
        setportfolioName(selectedPortfolio[0]?.exchangeName);
        droidDataDispatch({
          buySellSettings: {
            ...getDroidsDataInfo?.buySellSettings,
            pricePurchaseAmount: "",
            startOrderType: "limit",
          },
        });
      } else {
        droidDataDispatch({
          buySellSettings: {
            ...getDroidsDataInfo?.buySellSettings,
            pricePurchaseAmount: "",
            startOrderType: "market",
          },
        });
        setportfolioName("");
      }
    }
  }, [selectedPortfolio]);
  const dispatch = useDispatch();

  const isDisabled = () => {
    if (valueOfSimpleWarning[0]?.buyPurchaseAmount === true) {
      return true;
    }
    if (!getDroidsDataInfo?.dealRules?.list) {
      return false;
    }
    const sellOrderDetails =
      getDroidsDataInfo?.dealRules?.list[0]?.sellOrderDetails;
    return sellOrderDetails && sellOrderDetails.length > 0;
  };
  const handleChangeBuySellSettings = (event) => {
    const { name, value } = event.target;

    console.log("coin value", getbidValue);
    const onlyNumberRegex = /^\d*\.?\d{0,2}$/;
    let newValue = value?.replace(/0{2,}/g, "0");
    if (minBalance === 0 && name !== "pricePurchaseAmount") {
      if (name === "buyPurchaseAmount") {
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
        setErrorbuyPurchaseAmount({
          isValid: true,
          error: `Please choose a portfolio.`,
        });
      }
      droidDataDispatch({
        buySellSettings: { ...getDroidsDataInfo?.buySellSettings, [name]: "" },
      });
    } else if (!onlyNumberRegex.test(value)) {
      if (name === "buyPurchaseAmount") {
        setErrorbuyPurchaseAmount({
          isValid: true,
          error: `Please input only numbers with a maximum of two decimal places.`,
        });
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
      } else if (name === "pricePurchaseAmount") {
        setErrorpricePurchaseAmount({
          isValid: true,
          error: `Please input only numbers with a maximum of two decimal places.`,
        });
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
      }
    } else if (
      parseFloat(value) > parseFloat(minBalance) &&
      name !== "pricePurchaseAmount" &&
      name !== "limit" &&
      getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType === "dollar"
    ) {
      if (name === "buyPurchaseAmount") {
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
        setErrorbuyPurchaseAmount({
          isValid: true,
          error: `Please verify the maximum entered amount $${Number(
            minBalance
          )?.toFixed(2)}.`,
        });
      }
      setError({
        name: name,
        error: `Please verify the maximum entered amount $${Number(
          minBalance
        )?.toFixed(2)}.`,
      });
    } else if (
      parseFloat(value) > 100 &&
      name !== "pricePurchaseAmount" &&
      getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType === "percent"
    ) {
      if (name === "buyPurchaseAmount") {
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
        setErrorbuyPurchaseAmount({
          isValid: true,
          error: `Please input a value that is less than or equal to 100%.`,
        });
      }
      setError({
        name: name,
        error: `Please input a value that is less than or equal to 100%.`,
      });
    } else if (
      parseFloat(value) > 100 &&
      name === "pricePurchaseAmount" &&
      getDroidsDataInfo?.buySellSettings?.pricePurchaseAmountType === "percent"
    ) {
      if (name === "buyPurchaseAmount") {
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
        setErrorbuyPurchaseAmount({
          isValid: true,
          error: `Please input a value that is less than or equal to 100%.`,
        });
      }
      setError({
        name: name,
        error: `Please input a value that is less than or equal to 100%.`,
      });
      dispatch(setDroidSimpleError({ key: "pricePurchaseAmount" }));
    } else if (
      name === "buyPurchaseAmount" &&
      parseFloat(value) <= parseFloat(minAmount) &&
      getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType === "dollar"
    ) {
      droidDataDispatch({
        buySellSettings: {
          ...getDroidsDataInfo?.buySellSettings,
          [name]: value,
        },
      });
      dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
      setErrorbuyPurchaseAmount({
        isValid: true,
        error: `Please input value greater than ${minAmount}`,
      });
    } else if (
      parseFloat(value) <= 100 &&
      name === "buyPurchaseAmount" &&
      getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType === "percent"
    ) {
      const percentageOfValue = (minBalance * parseFloat(value)) / 100;
      if (percentageOfValue.toFixed(2) < minAmount) {
        dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
        setErrorbuyPurchaseAmount({
          isValid: true,
          error: `Amount of the given percentage value must be greater than minimum amount of $${parseFloat(
            minAmount
          )?.toFixed(
            2
          )} current amount for the given input is $${percentageOfValue.toFixed(
            2
          )}`,
        });
        droidDataDispatch({
          buySellSettings: {
            ...getDroidsDataInfo?.buySellSettings,
            [name]: value,
          },
        });
      } else {
        dispatch(clearDroidSimpleError({ key: "buyPurchaseAmount" }));
        setErrorbuyPurchaseAmount({ isValid: false, error: `` });
        droidDataDispatch({
          buySellSettings: {
            ...getDroidsDataInfo?.buySellSettings,
            [name]: value,
          },
        });
      }
    } else {
      if (value?.length > 8) {
        if (name === "buyPurchaseAmount") {
          dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
          setErrorbuyPurchaseAmount({
            isValid: true,
            error: `The value should not exceed 8 digits.`,
          });
        }
        if (name === "pricePurchaseAmount") {
          setErrorpricePurchaseAmount({
            isValid: true,
            error: "The value should not exceed 8 digits.",
          });
          dispatch(setDroidSimpleError({ key: "pricePurchaseAmount" }));
        }
      } else {
        if (value.startsWith(".") && name === "buyPurchaseAmount") {
          dispatch(setDroidSimpleError({ key: "buyPurchaseAmount" }));
          setErrorbuyPurchaseAmount({
            isValid: true,
            error: `Value cannot starts with a dot.`,
          });
        } else if (value.startsWith(".") && name === "pricePurchaseAmount") {
          dispatch(setDroidSimpleError({ key: "pricePurchaseAmount" }));
          setErrorpricePurchaseAmount({
            isValid: true,
            error: `Value cannot starts with a dot.`,
          });
        } else if (
          name === "pricePurchaseAmount" &&
          getbidValue < event.target.value
        ) {
          if (getbidValue < event.target.value) {
            console.log("true coin value");
            // setpricePurchaseGreaterThanMarket(true);
            setErrorpricePurchaseAmount({
              isValid: true,
              error: `Please enter a value less than or equal to Coin Current Price`,
            });
          } else {
            // setpricePurchaseGreaterThanMarket(false);
            setErrorpricePurchaseAmount({
              isValid: false,
              error: ``,
            });
          }
        } else {
          setError({ name: "", error: "" });
          setErrorpricePurchaseAmount({ isValid: false, error: "" });

          setErrorbuyPurchaseAmount({ isValid: false, error: "" });
          dispatch(clearDroidSimpleError({ key: "buyPurchaseAmount" }));
          dispatch(clearDroidSimpleError({ key: "pricePurchaseAmount" }));
          droidDataDispatch({
            buySellSettings: {
              ...getDroidsDataInfo?.buySellSettings,
              [name]: parseFloat(value) > 1 ? value : newValue,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    // if (getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount > minBalance && droidType?.Duplicate !== "edit") {
    //   droidDataDispatch({ buySellSettings: { ...getDroidsDataInfo?.buySellSettings, buyPurchaseAmount: '' } })
    // }
  }, [minBalance]);
  const BuyPurchaseType = (item, id) => {
    let dataEmpty = {};
    if (id === "buyPurchaseAmountType") {
      dataEmpty = { buyPurchaseAmount: "" };
    } else if (id === "pricePurchaseAmountType") {
      dataEmpty = { pricePurchaseAmount: "" };
    }
    setErrorpricePurchaseAmount({ isValid: false, error: "" });
    droidDataDispatch({
      buySellSettings: {
        ...getDroidsDataInfo?.buySellSettings,
        [id]: item?.name,
        ...dataEmpty,
      },
    });
  };

  const handleChangedcaStrategy = (event) => {
    console.log(event, "event");

    if (event === "market") {
      droidDataDispatch({
        buySellSettings: {
          ...getDroidsDataInfo?.buySellSettings,
          pricePurchaseAmount: "",
          startOrderType: "market",
        },
      });
      setErrorpricePurchaseAmount({ isValid: false, error: "" });
      dispatch(clearDroidSimpleError({ key: "pricePurchaseAmount" }));
    } else {
      console.log(getDroidsDataInfo.dealRules, "event2");
      droidDataDispatch({
        buySellSettings: {
          ...getDroidsDataInfo?.buySellSettings,
          startOrderType: event,
        },
        dealRules: {
          ...getDroidsDataInfo.dealRules,
          list: [],
          repeat: "",
        },
      });
    }
  };
  return (
    <>
      <h4>Buy/Sell Settings</h4>
      <div className="row my-4">
        <div className="col-lg-6 col-1400-full">
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center">
              <h6>
                Buy Purchase{" "}
                {getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType ===
                "percent"
                  ? "Percent"
                  : "Amount"}
              </h6>
              {!getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount ? (
                <p className="required-field">*</p>
              ) : null}
            </div>
            <TooltipQuestionMark
              toolTipContent={
                "The Purchase Order is the first order the bot will create when starting a new deal."
              }
            />
          </div>
          <div className="d-flex gap-2 align-items-center ">
            <div
              className={`btn-group simpledropdown w-25 small-dropdown-height`}
            >
              <button
                className="btn dropdown-toggle d-flex justify-content-between align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
              >
                <div>
                  {
                    currencyData?.find(
                      (obj) =>
                        obj.name ===
                        getDroidsDataInfo?.buySellSettings
                          ?.buyPurchaseAmountType
                    )?.label
                  }
                </div>
                <img
                  src={require("assets/arrowDown.svg").default}
                  alt=""
                  className="ML-6"
                  loading="lazy"
                />
              </button>
              <ul className="dropdown-menu">
                {currencyData?.map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown-item py-3 justify-content-between d-flex ${
                      currencyData?.length - 1 == index ? "" : "border-bottom"
                    }`}
                    onClick={() =>
                      BuyPurchaseType(item, "buyPurchaseAmountType")
                    }
                  >
                    {item?.label}{" "}
                    {getDroidsDataInfo?.buySellSettings
                      ?.buyPurchaseAmountType == item?.name ? (
                      <img
                        src={require("assets/rightMark.svg").default}
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <input
              type="text"
              min="0"
              className="small-input"
              disabled={hasFailed || (!getbidValue ? true : "")}
              data-maxlength="6"
              placeholder={
                getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType ===
                "percent"
                  ? "Percent"
                  : "Amount"
              }
              name="buyPurchaseAmount"
              value={getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount}
              onChange={handleChangeBuySellSettings}
            />
          </div>
          {getErrorbuyPurchaseAmount?.isValid === true && (
            <div className="text-danger">
              {" "}
              {getErrorbuyPurchaseAmount?.error}
            </div>
          )}
          {/* {parseFloat(getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount) <= parseFloat(minAmount) && <div className='text-danger'>   Please input value greater than ${minAmount}</div>} */}
        </div>

        <div className={portfolioName === "gemini" ? "col-lg-3" : "col-lg-6"}>
          <h6 className="mb-2 mt-1">Start Order Type</h6>
          {portfolioName === "gemini" ? (
            <div
              className={`DMS-Container-Buttons-container d-flex mb-3 cursor-pointer`}
            >
              {[{ label: "Limit", value: "limit" }].map((option) => (
                <div
                  key={option.value}
                  className={`DMS-Container-Buttons ${
                    getDroidsDataInfo?.buySellSettings?.startOrderType ===
                    option.value
                      ? "DMS-Container-Buttons-active"
                      : ""
                  }`}
                  onClick={() => handleChangedcaStrategy(option.value)}
                >
                  {" "}
                  {option.label}{" "}
                </div>
              ))}
            </div>
          ) : (
            <div
              className={`DMS-Container-Buttons-container d-flex mb-3 cursor-pointer`}
            >
              {[
                { label: "Market", value: "market" },
                { label: "Limit", value: "limit" },
              ].map((option) => (
                <div
                  key={option.value}
                  className={`DMS-Container-Buttons ${
                    getDroidsDataInfo?.buySellSettings?.startOrderType ===
                    option.value
                      ? "DMS-Container-Buttons-active"
                      : ""
                  }`}
                  onClick={() => handleChangedcaStrategy(option.value)}
                >
                  {" "}
                  {option.label}{" "}
                </div>
              ))}
            </div>
          )}
        </div>
        {getDroidsDataInfo?.buySellSettings?.startOrderType !== "limit" ? (
          ""
        ) : (
          <div className="col-lg-6 col-1400-full">
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center">
                <h6>Price-Purchase Value</h6>
                {!getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount ? (
                  <p className="required-field">*</p>
                ) : null}
              </div>
              <TooltipQuestionMark
                toolTipContent={
                  "What is the value of the digital assets to buy."
                }
              />
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="btn-Border-Dropdown-Replace">$ </div>
              <input
                type="text"
                className="small-input"
                placeholder="Amount"
                name="pricePurchaseAmount"
                value={
                  !getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount
                    ? ""
                    : getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount
                }
                onChange={handleChangeBuySellSettings}
              />
            </div>
            {getErrorpricePurchaseAmount?.isValid === true && (
              <div className="text-danger">
                {" "}
                {getErrorpricePurchaseAmount?.error}
              </div>
            )}
          </div>
        )}
        {/* {pricePurchaseGreaterThanMarket && (
          <div className="text-danger">
            {" "}
            {"Please enter a value less than or equal to Coin Market Price"}
          </div>
        )} */}
      </div>

      {/* <div className='row mt-3'>
          <div className="col-lg-6">
            <h6 className='mb-2'>Start order type</h6>
            <div className={`DMS-Container-Buttons-container d-flex mb-3 cursor-pointer`}>
              {[{ label: 'Market', value: 'market' }, { label: 'Limit', value: 'limit' }].map((option) => (
                <div key={option.value} className={`DMS-Container-Buttons ${getDroidsDataInfo?.buySellSettings?.startOrderType === option.value ? 'DMS-Container-Buttons-active' : ''}`} onClick={() => handleChangedcaStrategy(option.value)} > {option.label} </div>
              ))}
            </div>
          </div>
          {getDroidsDataInfo?.buySellSettings?.orderType === 'limit' ? <div className='col-lg-6'>
            <div className='d-flex gap-2 align-items-center'>
              <div className='d-flex align-items-center'>
                <h6>Threshold</h6>
                {!getDroidsDataInfo?.buySellSettings?.limit ? <p className='required-field'>*</p> : null}
              </div>
              <TooltipQuestionMark toolTipContent={'The Purchase Order is the first order the bot will create when starting a new deal.'} />
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <div className={`btn-group simpledropdown w-25 small-dropdown-height MT-2`}>
                <button className="btn dropdown-toggle d-flex justify-content-center align-items-center droid-dropdown-height" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                  <div>$</div> </button>
              </div>
              <input type="text" value={!getDroidsDataInfo?.buySellSettings?.limit ? "" : getDroidsDataInfo?.buySellSettings?.limit} name='limit'
                onChange={handleChangeBuySellSettings}
                className='small-input droid-dropdown-height' placeholder='Amount' />
            </div>
            {getError?.name == 'limit' && <div className='text-danger'>   {getError?.error}</div>}
          </div> : ''}
        </div> */}
    </>
  );
};

export default SimpleModeDroidChild;
