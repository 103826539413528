import React from "react";

function Doller({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 16"
    >
      <path
        stroke="#0078E7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.111 4.303a2.222 2.222 0 00-2-1.11H3.89a2.222 2.222 0 100 4.444H6.11a2.222 2.222 0 010 4.444H3.89a2.223 2.223 0 01-2-1.111M5 .97v2.222m0 8.89v2.221"
      ></path>
    </svg>
  );
}

export default Doller;
