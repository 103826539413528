
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorCode from 'ErrorCode'

export const userTypeTrader = createAsyncThunk(
    'user/type_trader',
    async (userToken, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/getTypeTrader`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data.types
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)