import React from "react";

function LTC({ht, wd, bg, text, text2}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      fill="none"
      viewBox="0 0 44 45"
    >
      <rect width="44" height="44" y="0.922" fill={bg} rx="22"></rect>
      <path
        fill={text}
        d="M11.21 15.514L24.26 9.49l6.022 6.023 4.015 10.037-2.007 7.027-5.02 3.011-9.033 1.004-7.027-4.015V15.513z"
      ></path>
      <path
        fill={text2}
        d="M21.81 7.483a16.06 16.06 0 1016.061 16.06 16.014 16.014 0 00-15.967-16.06h-.093zm.273 16.605l-1.672 5.639h8.944a.45.45 0 01.467.435v.148l-.778 2.683a.58.58 0 01-.583.428H14.772l2.295-7.816-2.567.778.583-1.79 2.567-.777 3.228-10.966a.588.588 0 01.583-.428h3.46a.45.45 0 01.468.435v.148l-2.723 9.255 2.567-.777-.544 1.866-2.606.74z"
      ></path>
    </svg>
  );
}

export default LTC;