import React from "react";

function UpDownArrowIcon({color='#0078E7',width=16,height=16}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.735.735l-2.5 2.5.53.53 1.86-1.86V8.5a.375.375 0 10.75 0V1.905l1.86 1.86.53-.53-2.5-2.5a.375.375 0 00-.53 0zM6.735 11.265l-2.5-2.5.53-.53 1.86 1.86V3.5a.375.375 0 11.75 0v6.595l1.86-1.86.53.53-2.5 2.5a.375.375 0 01-.53 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UpDownArrowIcon;
