import React, { useEffect, useState } from 'react';
import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import IndexV1Legends from './Accessories/IndexV1Legends';
import ChartPercentage from './Accessories/ChartPercentage';
Chart.register(ArcElement);

const IndexV1Chart = ({ getGraphData }) => {
  const [dataValue, setDataValue] = useState([]);

  useEffect(() => {
    const allValues = getGraphData?.coinsList?.map((item) => item?.value && Number(item?.value));
    const allName = getGraphData?.coinsList?.map((item) => item?.name);
    const allColor = getGraphData?.coinsList?.map((item) => item?.color);
    setDataValue({ value: allValues, name: allName, color: allColor });
  }, [getGraphData?.coinsList]);

  const getTotalPercentage = () => {
    let total = 0;
  
    if (getGraphData?.coinsList?.length) {
      getGraphData?.coinsList?.forEach((item) => {
        if (item?.value) {
          total = total + Number(item?.value);
        }
      });
  
      if (/^-?\d*\.?\d+$/.test(total)) {
        return total?.toFixed(1);
      } else {
        return total;
      }
    }
  
    return total;
  }
  
  const dataDoughnutFull = {
    labels: dataValue?.name?.length > 0 ? dataValue?.name : ['Add Coin'],
    datasets: [
      {
        data:
          dataValue?.value?.length > 0
            ? dataValue?.value
            : ['100'],
        backgroundColor:
          dataValue?.value?.length > 0
            ? dataValue?.color
            : ['#e5e5e5'],
        borderRadius: 3,
      },
    ],
  };

  const tooltipBackgroundColor = 'white';
  const tooltipZIndex = 2;

  const optionsDoughnutFull = {
    cutout: '87%',
    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: tooltipBackgroundColor,
        borderColor: '#858585', 
        borderWidth: 2, 
        titleColor: 'black',
        bodyColor: 'black', 
        bodyFontSize: 16,
        cornerRadius: 0,
        displayColors: false, 
        z: tooltipZIndex, 
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `${value}%`;
          },
          title: () => '',
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className='d-flex justify-content-center indexChartPadding'>
      <div className='indexV1Chart my-2 '>
        <div className='position-relative'>
          <Doughnut options={optionsDoughnutFull} data={dataDoughnutFull} />
          <div className='PercentageAllocated position-absolute '>
            <ChartPercentage Percent={getTotalPercentage()} PercentName='Allocated' />
          </div>
        </div>
        <div className='mt-4'>
          <IndexV1Legends getGraphData={getGraphData} />
        </div>
      </div>
    </div>
  );
};

export default IndexV1Chart;
