import React from 'react'
import DroidPopupModule from './DroidPopupModuleDetail'
import PopupModal from "components/PopupModal/index"

const index = () => {
    return (
        <PopupModal backdrop={false} id={""}
            children={
                <>
                    <DroidPopupModule
                        CoinIcon={require(`assets/droid67Icon.svg`).default}
                        CoinHeading={"Buy Me Bitcoin"}
                        ClientsQty={"16"}
                        UPTrendClients="10"
                        DownTrendClientsPercentage="+7.5%"
                        DownTrendClients="6"
                        UPTrendClientsPrecentage="-4.2%"
                        PortfoliosQty="22"
                        Exchange="Coinbase"
                        Funds="10K-50K"
                        TimePeriod="1 Months"
                        RiskLevel="Medium"
                        CoinOne="BTC/USD"
                        CoinTwo="ADA/USD"
                        CoinThree="ETH/USD"
                        CoinFour="BNB/USD"
                        CoinOneIcon={require(`assets/Popbitcoin.svg`).default}
                        CoinTwoIcon={require(`assets/PopCardano.svg`).default}
                        CoinThreeIcon={require(`assets/PopEtherium.svg`).default}
                        CoinFourIcon={require(`assets/PopBinance.svg`).default}
                        BuyPA="$3500"
                        BuyPV="$17,500"
                        TargetProfit="3%"
                        TakeProfitType="USD"
                        TraillingDeviation="0.3%"
                    />
                </>
            }
        />
    )
}

export default index