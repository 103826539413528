import React from "react";

function TaskIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill="#c2c2c2"
        d="M6.243 15.293a1 1 0 001.414 0l4.236-4.236a1 1 0 000-1.414l-.036-.036a1 1 0 00-1.414 0l-2.81 2.81a1 1 0 01-1.415 0l-.686-.685a1.007 1.007 0 00-1.42.006c-.39.39-.395 1.029-.005 1.42l2.136 2.135zM2 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 010 18V2C0 1.45.196.979.588.587A1.925 1.925 0 012 0h7.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0114 20H2zm8-13a1 1 0 01-1-1V2H2v15a1 1 0 001 1h10a1 1 0 001-1V7h-4z"
      ></path>
    </svg>
  );
}

export default TaskIcon;
