// AcceptRequest.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorCode from 'ErrorCode'

export const acceptRequest = createAsyncThunk(
    'user/accept_request',
    async ({ userToken, senderId, status }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': userToken,
                    'X-custom-header': '$Ecur!tyHEadEr'
                }
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASEURL}/api/acceptRequest`,
                { senderId, status }, 
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data 
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
