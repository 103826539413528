import "./SkeletonLoading.modules.scss";

export function UsersSkeletonLoading() {
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-2 mb-3">
      <div className="droid-Container p-4">
        <div className="row justify-content-center mb-3">
          <div className="col-8 skeleton skeleton-image-xl"></div>
        </div>
        <div className="row justify-content-end mb-3">
          <span className="col-3 skeleton skeleton-badge"></span>
        </div>
        <hr />
        <div className="row gap-2 justify-content-start align-items-center mb-3">
          <div className="col-8 skeleton skeleton-title mb-0"></div>
        </div>

        <div className="row gap-2 justify-content-start align-items-center mb-3">
          <div className="col-3 skeleton skeleton-badge"></div>
        </div>
        <hr />
        <div className="row gap-2 justify-content-start align-items-center mb-3">
          <div className="col-3 skeleton skeleton-badge"></div>
        </div>
        <div className="row justify-content-between my-3">
          <div className="col-12 row flex-column">
            <p className=" col-12 skeleton skeleton-text"></p>
            <p className=" col-12 skeleton skeleton-text"></p>
            <p className=" col-12 skeleton skeleton-text"></p>
            <p className=" col-6 skeleton skeleton-text"></p>
          </div>
        </div>
        <div className="row gap-2 justify-content-start align-items-center mb-3">
          <div className="col-3 skeleton skeleton-badge"></div>
          <div className="col-4 skeleton skeleton-title mb-0"></div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
