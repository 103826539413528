// In your Redux slice file (e.g., errorSlice.js)

import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  { totalBuyAmount: false },
  { timePeriod: false },
];

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setDroidIndexError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex((error) => Object.keys(error)[0] === key);
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = true;
      }
    },
    clearDroidIndexError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex((error) => Object.keys(error)[0] === key);
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = false;
      }
    },
  },
});

export const { setDroidIndexError, clearDroidIndexError } = errorSlice.actions;

export default errorSlice.reducer;
