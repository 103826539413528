import React from "react";

function WarningIcon({width=47,height=42}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 47 42"
    >
      <path
        fill="#FFBC39"
        fillRule="evenodd"
        d="M23.5 13.07c.966 0 1.75.783 1.75 1.75v9.333a1.75 1.75 0 11-3.5 0v-9.334c0-.966.783-1.75 1.75-1.75zm0 16.333c.966 0 1.75.783 1.75 1.75v.023a1.75 1.75 0 11-3.5 0v-.023c0-.967.783-1.75 1.75-1.75z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFBC39"
        fillRule="evenodd"
        d="M20.218 1.063a6.417 6.417 0 018.864 2.404l16.559 28.57c.04.07.077.145.108.22a6.415 6.415 0 01-5.904 8.824H7.166c-.042 0-.085-.002-.127-.005a1.777 1.777 0 01-.22.003 6.417 6.417 0 01-5.614-8.823c.03-.076.067-.149.107-.22L17.871 3.468a6.417 6.417 0 012.347-2.404zM7.038 37.586c.042-.003.085-.005.128-.005h32.66a2.917 2.917 0 002.717-3.909L26.046 5.208l-.017-.03a2.916 2.916 0 00-5.105 0l-.017.03L4.41 33.674a2.917 2.917 0 002.629 3.912z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default WarningIcon;
