import React from "react";

function PortfoliosIcon({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 23 21"
    >
      <path
        fill={color ? color : "#0078E7"}
        d="M2.769 21c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 01.769 19V9a1 1 0 112 0v10h16a1 1 0 110 2h-16zm4-4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 014.769 15V4h5V2c0-.55.196-1.021.588-1.413A1.925 1.925 0 0111.769 0h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2h5v11c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 01-1.413.587h-14zm0-2h14V6h-14v9zm5-11h4V2h-4v2z"
      ></path>
    </svg>
  );
}

export default PortfoliosIcon;