import React from 'react'
import construction from 'assets/under_construction.png'

function CommunityBlog() {
  return (
    <div className='d-flex justify-content-center mt-'>
          <img src={construction} alt="" loading='lazy'/>
    </div>
  )
}

export default CommunityBlog