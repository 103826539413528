import React from "react";
import {
  BTC,
  ETH,
  Tether,
  Cardano,
  LTC,
  XRP,
  SOL,
  BAT,
  EURO,
  USD,
} from "assets";
import Carousel from "./Carousel";
import ConverNumber from "components/ConvertNumber";
import DogeIcon from "assets/CoinIcon/Doge";

const Slider = ({ data, handleItemClick, selectedIndices }) => {
  const coinData = [
    {
      coin: "BTC",
      image: <BTC text={"#F7931A"} wd={"40"} ht={"40"} />,
      label: "BTC",
    },
    {
      coin: "ETH",
      image: <ETH text={"#393939"} wd={"40"} ht={"40"} />,
      label: "ETH",
    },
    {
      coin: "XRP",
      image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
      label: "XRP",
    },
    {
      coin: "LTC",
      image: <LTC text2={"#F8F9FB"} text={"#D9D9D9"} wd={"40"} ht={"40"} />,
      label: "LTC",
    },
    {
      coin: "SOL",
      image: (
        <SOL text={"url(#paint0_linear_1601_37368)"} wd={"40"} ht={"40"} />
      ),
      label: "SOL",
    },
    {
      coin: "USDT",
      image: <Tether text={"#50AF95"} wd={"40"} ht={"40"} />,
      label: "USDT",
    },
    {
      coin: "ADA",
      image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
      label: "ADA",
    },
    {
      coin: "BCH",
      image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
      label: "BCH",
    },
    {
      coin: "ZEC",
      image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
      label: "ZEC",
    },
    {
      coin: "BAT",
      image: (
        <div className="coinHeightWeight">
          <BAT
            text={"url(#paint0_linear_1601_37368)"}
            wd={"20"}
            ht={"20"}
            className="mb-5"
          />
        </div>
      ),
      label: "BAT",
    },
    {
      coin: "EUR",
      image: (
        <div className="coinHeightWeight">
          <EURO wd={"25"} ht={"25"} />
        </div>
      ),
      label: "EUR",
    },
    {
      coin: "GBP",
      image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
      label: "GBP",
    },
    {
      coin: "LINK",
      image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
      label: "LINK",
    },
    {
      coin: "USD",
      image: (
        <div className="coinHeightWeight">
          <USD wd={"20"} ht={"20"} />
        </div>
      ),
      label: "USD",
    },
    {
      coin: "USDC",
      image: (
        <div className="coinHeightWeight">
          <USD wd={"20"} ht={"20"} />
        </div>
      ),
      label: "USDC",
    },
    {
      coin: "DOGE",
      image: (
        <div className="coinHeightWeight">
          <DogeIcon width={"20"} height={"20"} />
        </div>
      ),
      label: "DOGE",
    },
  ];
  return (
    <Carousel show={data?.length > 3 ? 4.5 : 2}>
      {data?.map((item, index) => {
        const isSelected = selectedIndices?.includes(item?._id);
        const { id } = item;
        var usdtObject = item?.coinDetails?.find(
          (currency) => currency?.name === "USD" || currency?.name === "USDT"
        )?.value;
        return (
          <div
            key={id}
            className={`card_container mx-2 p-3 ${
              isSelected ? "selectPortfolio" : ""
            }`}
            onClick={() => handleItemClick(item?._id)}
          >
            <div className="p-Two-gray d-flex gap-1 mb-2">
              <img
                src={require(`assets/risk/risk_${
                  item?.risk && item?.risk?.length > 0
                    ? item?.risk[0].toUpperCase() + item?.risk.slice(1)
                    : "Low"
                }.svg`)}
                alt=""
                className="mb-1"
                loading="lazy"
              />
              {item?.risk && item?.risk?.length > 0
                ? item?.risk[0].toUpperCase() + item?.risk.slice(1)
                : "Low"}{" "}
              | <ConverNumber data={item?.balance} />(
              {!usdtObject ? "0" : Number(usdtObject)?.toFixed(1)}){" | "}
              <span className="text-capitalize">{item?.exchangeName}</span>
            </div>
            {item?.name ? (
              <h4>{item?.name}</h4>
            ) : (
              <h4 className="text-secondary">Unknown</h4>
            )}
            <div className="d-flex justify-content-start mt-2">
              {item?.coins?.length > 0 ? (
                item?.coins?.map((item, index) =>
                  coinData?.map((coin, i) => coin.coin === item && coin.image)
                )
              ) : (
                <h6 className="p-Two-gray">No coins added yet</h6>
              )}
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Slider;
