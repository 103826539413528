import React, { useState } from 'react'
import CardShadow from 'components/CardShadow'
import { PinIcon } from 'assets'
import UplodeImg from 'screens/ProfileScreen/UplodeImg';
import Card from './Card';

const MessageCard = (props) => {
    const [getImage, setImage] = useState()
    return (
        <>
            {localStorage.getItem('role') === 'retailer' ? <CardShadow key={props?.index} className='my-3 px-4'>
                <div className='d-flex justify-content-between align-items-star'>
                    <p className='d-flex gap-2 message-title text-break'> {props?.retailerId?.full_name} {props?.brokerId?.full_name} </p>
                    <div onClick={() => props.handleChatPin(props.isPinned, props._id)} className="cursor">
                        <PinIcon pin={props.isPinned} />
                    </div>
                </div>
                <div className='py-2 '>
                    <div className='d-flex gap-2 marginBetweenLabels'>
                        <div className={'message-title'}>Question: {props?.question}</div>
                    </div>
                </div>
                { localStorage.getItem('role')  === "retailer" ? <div className=''>{`Answer: ${!props?.answer ? '---' : props?.answer}`}</div> :
                 <div className='card-heading-message'>{`Answer: ${!props?.answer ? '---' : props?.answer}`}</div>  }
            </CardShadow> :
                <> <CardShadow key={props?.index} className='my-3 px-4'> <Card getShowImg={props?.getShowImg} setShowImg={props?.setShowImg} {...props} shadow={true} /> </CardShadow> </>
            }
            {/* <UplodeImg setImageData={setImage} /> */}
        </>
    )
}

export default MessageCard