import { ImageIcon, LinkIcon, UndoIcon } from 'assets'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { answerToQuestionAPI } from 'features/Management/managementAction'
import UplodeImg from 'screens/ProfileScreen/UplodeImg';
import { deleteMessageFromActivity } from "features/ActivityLog/ActivityLogAction";

const MessageCardContainer = (props) => {
    const dispatch = useDispatch();
    const [getAnswerMessages, setAnswerMessages] = useState({ id: '', message: '', delete: '' })
    const { userToken } = useSelector((state) => state.auth);
    const [showUndo, setShowUndo] = useState(false);
    const [getShowImg, setShowImg] = useState({ id: '' })
    const [getReplyLoading, setReplyLoading] = useState(false)
    useEffect(() => {
        let timeoutId;
        if (showUndo) {
            timeoutId = setTimeout(() => {
                setShowUndo(false);
                setReplyLoading(true)
                dispatch(answerToQuestionAPI({ userToken, getAnswerMessages })).then((response) => {
                    if (response.meta && response.meta.requestStatus === "fulfilled") {
                        props.setDeleteMessage(getAnswerMessages?.id)
                        dispatch(deleteMessageFromActivity({ userToken, id: getAnswerMessages?.delete })).then((response) => {
                            if (response.meta && response.meta.requestStatus === "fulfilled") {
                                setAnswerMessages({ id: '', message: '', delete: '' })
                                setReplyLoading(false)
                                props.setDeleteMessage('')
                            }
                        })
                    }
                })
            }, 3000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [showUndo]);

    const sendMessage = () => {
        if (getAnswerMessages?.message) {
            setShowUndo(!showUndo)
        }
    }
    return (
        <>
            <div className='d-flex justify-content-between align-items-star'>
                <p className='d-flex card-heading-message gap-2 text-break mb-2'>{props?.question}</p>
            </div>
            <div className={`${(showUndo || getReplyLoading || props?.loadActivityLog) ? 'bg_gray' : ''} d-grid gap-3 answerQustion align-items-center`}>
                <input onClick={() => setShowImg({ id: props?._id })} type="" className={`${(showUndo || getReplyLoading || props?.loadActivityLog) ? 'bg_gray' : ''} ACtivityLogInput m-0`} value={getAnswerMessages?.id === (props?.activityLog ? props?.relationId : props?._id) ? getAnswerMessages?.message : ''} placeholder="Reply" onChange={(e) => {
                    const trimmedValue = e.target.value.trimStart();
                    setAnswerMessages({
                        id: props?._id,
                        message: trimmedValue,
                        delete: props?._id
                    });
                }} />
                {getShowImg['id'] === props?._id && (
                    <div className={`mx-3 mb-2 d-flex ${!showUndo ? 'justify-content-between' : 'justify-content-end'}`} >
                        {(showUndo || getReplyLoading) ? '' : <div className='d-flex align-items-center'> <LinkIcon />
                            <div type="button" data-bs-toggle="modal" data-bs-target="#userImage"> <ImageIcon /> </div>
                        </div>}
                        <button className={`${(showUndo || getReplyLoading) ? 'bg_gray' : ''} ${getAnswerMessages?.message ? 'reply_message' : 'reply_message_not'} bg-white border-0`} onClick={sendMessage}>{showUndo ? <UndoIcon /> : <>{getReplyLoading ? '...' : 'Reply'}</>}</button>
                    </div>
                )}
            </div>
            <UplodeImg />
        </>
    )
}

export default MessageCardContainer