import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDroids,
  postAddDroid,
  getAllDroidsById,
  deleteDroid,
  updatedroidsById,
  getDroidsList,
} from "./droidsAction";

const initialState = {
  getAllDroidsInfo: [],
  getAllDroidsByIdInfo: null,
  isgetAllDroids: false,
  postDroidsInfo: null,
  ispostDroids: false,
  postDroidsError: null,
  getAllDroidsList: null,
  isdeleteDroid: false,
  isdeleteDroidInfo: null,
  getAllDroidsByIdErrorInfo: null,
  isupdateDroidsError: false,
};

const droidsSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearispostDroids: (state) => {
      state.ispostDroids = false;
    },
    clearisupdateDroids: (state) => {
      state.isupdateDroids = false;
    },
    clearisupdateDroidsError: (state) => {
      state.isupdateDroidsError = false;
    },
    clearisdeleteDroid: (state) => {
      state.isdeleteDroid = false;
    },
    resetDroid(state) {
      state.getAllDroidsInfo = []; // Reset droid item to an empty object
    },
  },
  extraReducers: {
    [getAllDroids.pending]: (state) => {},
    [getAllDroids.fulfilled]: (state, { payload }) => {
      state.getAllDroidsInfo = payload;
    },
    [getAllDroids.rejected]: (state, { payload }) => {},

    [postAddDroid.pending]: (state) => {},
    [postAddDroid.fulfilled]: (state, { payload }) => {
      state.postDroidsInfo = payload;
      state.ispostDroids = true;
    },
    [postAddDroid.rejected]: (state, { payload }) => {
      state.postDroidsError = payload;
    },
    [getAllDroidsById.pending]: (state) => {},
    [getAllDroidsById.fulfilled]: (state, { payload }) => {
      state.getAllDroidsByIdInfo = payload;
    },
    [getAllDroidsById.rejected]: (state, { payload }) => {},

    [updatedroidsById.pending]: (state) => {},
    [updatedroidsById.fulfilled]: (state, { payload }) => {
      state.getAllDroidsByIdInfo = payload;
      state.isupdateDroids = true;
    },
    [updatedroidsById.rejected]: (state, { payload }) => {
      state.getAllDroidsByIdErrorInfo = payload;
      state.isupdateDroidsError = true;
    },

    [getDroidsList.pending]: (state) => {},
    [getDroidsList.fulfilled]: (state, { payload }) => {
      state.getAllDroidsList = payload;
    },
    [getDroidsList.rejected]: (state, { payload }) => {},

    [deleteDroid.pending]: (state) => {},
    [deleteDroid.fulfilled]: (state, { payload }) => {
      state.isdeleteDroidInfo = payload;
      state.isdeleteDroid = true;
    },
    [deleteDroid.rejected]: (state, { payload }) => {},
  },
});

export const {
  clearispostDroids,
  clearisdeleteDroid,
  clearisupdateDroids,
  clearisupdateDroidsError,
  resetDroid,
} = droidsSlice.actions;
export default droidsSlice.reducer;
