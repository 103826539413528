import React from "react";

function Current({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#14AA39"
        d="M8.6 11.8L6.425 9.625a.89.89 0 00-.687-.262.976.976 0 00-.688.287.948.948 0 00-.275.7c0 .283.092.517.275.7L7.9 13.9a.948.948 0 00.7.275.948.948 0 00.7-.275l5.675-5.675a.894.894 0 00.263-.688.979.979 0 00-.288-.687.948.948 0 00-.7-.275.948.948 0 00-.7.275L8.6 11.8zM10 20a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.732 9.732 0 010 10c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0110 0c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0120 10a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0110 20zm0-2c2.217 0 4.104-.779 5.663-2.337C17.221 14.104 18 12.217 18 10s-.779-4.104-2.337-5.663C14.104 2.779 12.217 2 10 2s-4.104.779-5.662 2.337C2.779 5.896 2 7.783 2 10s.78 4.104 2.338 5.663C5.896 17.221 7.783 18 10 18z"
      ></path>
    </svg>
  );
}

export default Current;
