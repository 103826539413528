import React from 'react'

const ProfileTradingTipsComponent = (props) => {
    return (
        <div className="row mt-5">
            <div className="col-6 ">
                <div className=" align-item-center gap-5 justify-content-between">
                    <h3 className="mb-3">Trading Insights</h3>
                    <textarea disabled className={`form-control w-100 p-4 bg-white p-One`} rows="10" defaultValue={props?.trading_insight} name="trading_insight" ></textarea>
                </div>
            </div>
            <div className="col-6">
                <div className="align-item-center gap-5 justify-content-between">
                    <h3 className="mb-3">Tip of the day</h3>
                    <textarea disabled className={`form-control w-100 p-4 bg-white p-One`} rows="10" defaultValue={props?.day_input} name="day_input"  ></textarea>
                </div>
            </div>
        </div>
    )
}

export default ProfileTradingTipsComponent