import { createSlice } from '@reduxjs/toolkit'
import { getCoinList } from './CoinListAction'
const initialState = {
  getcoinList: null,
}

const coinListSlice = createSlice({
  name: 'CoinList',
  initialState,
  reducers: {},
  extraReducers: {
    [getCoinList.pending]: (state) => { },
    [getCoinList.fulfilled]: (state, { payload }) => {
      payload?.coins?.unshift({
        "uuid": "Qwsogvtv82Usd",
        "symbol": "USD",
        "name": "Bitcoin",
        "color": "#f7931A",
        "iconUrl": "https://cdn.coinranking.com/jkDf8sQbY/usdc.svg",
      })
      state.getcoinList = payload
    },
    [getCoinList.rejected]: (state, { payload }) => { },
  },
})

export default coinListSlice.reducer
