import React from "react";

function ArrowLeftIcon({ fill, color = '#060201', height = 24, width = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M14.47 18.53a.75.75 0 101.06-1.06L10.06 12l5.47-5.47a.75.75 0 00-1.06-1.06l-6 6a.75.75 0 000 1.06l6 6z"
      ></path>
    </svg>
  );
}

export default ArrowLeftIcon;
