import React from "react";

function Email({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        fill="#0078E7"
        fillRule="evenodd"
        d="M17 .25a2.75 2.75 0 012.75 2.737V13A2.75 2.75 0 0117 15.75H3A2.75 2.75 0 01.25 13c0-1.112-.007-2.224-.014-3.337C.222 7.437.21 5.21.25 2.985A2.75 2.75 0 013 .25h14zM2.33 2.986l-.526-.351A1.25 1.25 0 013 1.75h14c.563 0 1.04.373 1.196.885l-.529.352L10 8.099 2.33 2.986zM18.25 13V4.401l-7.834 5.223a.75.75 0 01-.832 0L1.75 4.401V13c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Email;
