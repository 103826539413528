import { createSlice } from '@reduxjs/toolkit'
import { userProfile, userUpdateProfile} from './profileAction'

const initialState = {
    userProfileInfo: null,
    isUserProfileUpdated: false,
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        clearisUserProfileUpdated: (state) => {
          state.isUserProfileUpdated = false
        }
    },
    extraReducers: {
        [userProfile.pending]: (state) => {
        },
        [userProfile.fulfilled]: (state, { payload }) => {
          state.userProfileInfo = payload
        },
        [userProfile.rejected]: (state, { payload }) => {
        },
        [userUpdateProfile.pending]: (state) => {
        },
        [userUpdateProfile.fulfilled]: (state, { payload }) => {
          state.userProfileInfo = payload
          state.isUserProfileUpdated = true
        },
        [userUpdateProfile.rejected]: (state, { payload }) => {
        },
      },
})

export const {
  logout,
} = profileSlice.actions

export const { clearisUserProfileUpdated} = profileSlice.actions
export default profileSlice.reducer
