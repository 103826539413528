// In your Redux slice file (e.g., errorSlice.js)

import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  { baseOrderSizeAmount: false },
  { orderSizeAmount: false },
  { priceDeviation: false },
  { maxOrderCount: false },
  {targetProfitPercent:false},
  {trailling:false}
];

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setDroidDcaError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex((error) => Object.keys(error)[0] === key);
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = true;
      }
    },
    clearDroidDcaError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex((error) => Object.keys(error)[0] === key);
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = false;
      }
    },
  },
});

export const { setDroidDcaError, clearDroidDcaError } = errorSlice.actions;

export default errorSlice.reducer;
