import { createSlice } from '@reduxjs/toolkit'
import { userTypeTrader } from './typeTraderAction'

const initialState = {
    typeTraderInfo: null,
    istypeTraderUpdated: false
}

const typeTraderSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: { },
    extraReducers: {
        [userTypeTrader.pending]: (state) => { },
        [userTypeTrader.fulfilled]: (state, { payload }) => {
          state.istypeTraderUpdated = true
          state.typeTraderInfo = payload
        },
        [userTypeTrader.rejected]: (state, { payload }) => { },
      },
})

export default typeTraderSlice.reducer
