import React, { useEffect, useState } from 'react'
import AddOnCard from '../AddOnCard'
import './index.scss'
import AddOnCardDescription from 'components/AddOnCardDescription'

function formatDateString(dateString) {
    const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
}
function NewsCard({ onClickExpand, onClickPin, onClickUpdate, isVisible, newsData }) {
    const handleNavigate = (url) => {
        window.open(url, '_blank')
    }
    return (
        <>
            {newsData.map((item, index) => {
                return (
                    <div key={index}>
                        <div onClick={() => handleNavigate(item?.news_url)} >
                            <AddOnCard onClickPin={onClickPin} onClickExpand={onClickExpand} onClickUpdate={onClickUpdate} isVisible={isVisible} >
                                <div className="d-flex justify-content-between cursor" title='Click to view full news'>
                                    <img src={item?.image_url} alt="News image" className="NewsImage me-3" loading='lazy'/>
                                    <div>
                                        <div className='heading-FTX'> {item?.title} </div>
                                        <small className='me-2 CoinDesk-text'>{item?.source_name}</small>
                                        <small className='CoinDesk-text'>{formatDateString(item?.date)}</small>
                                    </div>
                                </div>
                            </AddOnCard>
                            <AddOnCardDescription>
                                <h5 className="mt-3">{item?.type}</h5>
                                <p>{item?.text?.length > 110 ? item?.text.substr(0, 110) + '...' : item?.text}</p>
                                <small>Sentiment: {item?.sentiment}</small>
                            </AddOnCardDescription>
                        </div>
                    </div>
                )
            })
            }
        </>
    )
}

export default NewsCard