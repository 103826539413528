import React from 'react'

const ImageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        stroke="#858585"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.57 7.965v4.175a1.193 1.193 0 01-1.193 1.193h-8.35a1.193 1.193 0 01-1.194-1.193V3.79a1.193 1.193 0 011.193-1.194h4.176M10.588 3.79h3.579M12.377 2v3.579"
      ></path>
      <path
        stroke="#858585"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.412 7.368a1.193 1.193 0 100-2.386 1.193 1.193 0 000 2.386zM13.57 9.754l-1.84-1.84a1.193 1.193 0 00-1.688 0l-5.42 5.42"
      ></path>
    </svg>
  )
}

export default ImageIcon