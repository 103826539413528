import { createSlice } from '@reduxjs/toolkit'
import { getExchangeList, createExchangeIntegration,deleteExchange } from './ExchangeAction'
const initialState = {
  getExchangeInfo: null,
  isExchangeInfo: false,
  isgetExchange: false,
  isCreateExchange: null,
  isCreateExchangeStatus: false,
  isCreateExchangeError: false,
  isdeleteExchangeStatus: false,
  isdeleteExchange: null
}

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
 
  reducers: {
    clearCreateExchangeStatus:(state) => {
      state.isCreateExchangeStatus =false
    },
    clearCreateExchangeError:(state) => {
      state.isCreateExchangeError =false
    },
    clearDeleteExchange:(state) => {
      state.isdeleteExchangeStatus =false
    }
  },
  extraReducers: {
    [getExchangeList.pending]: (state) => { },
    [getExchangeList.fulfilled]: (state, { payload }) => {
      state.getExchangeInfo = payload
      state.isExchangeInfo = false
    },
    [getExchangeList.rejected]: (state, { payload }) => {
      state.isExchangeInfo = true
    },
    [getExchangeList.rejected]: (state, { payload }) => { },
    [createExchangeIntegration.pending]: (state) => { },
    [createExchangeIntegration.fulfilled]: (state, { payload }) => {
     state.isCreateExchangeStatus= true
      state.isCreateExchange = payload
    },
    [createExchangeIntegration.rejected]: (state, { payload }) => {
      state.isCreateExchangeError=true
     },

     [deleteExchange.pending]: (state) => { },
    [deleteExchange.fulfilled]: (state, { payload }) => {
     state.isdeleteExchangeStatus= true
      state.isdeleteExchange = payload
    },
    [deleteExchange.rejected]: (state, { payload }) => {
     },
  },
})

export const { clearCreateExchangeStatus, clearCreateExchangeError,clearDeleteExchange } = exchangeSlice.actions

export default exchangeSlice.reducer
