import React from "react"
import { useSelector } from "react-redux"

const ScatterChartHeader = ({headingText}) => {
    const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo)
    return(
        <div className="row">
                <h3 className="half-column">{headingText}</h3>
                <div className="half-column align-right"><strong>Repeat {!getDroidsDataInfo?.dealRules?.repeat || getDroidsDataInfo?.dealRules?.repeat == 0 ? '' : `0/${getDroidsDataInfo?.dealRules?.repeat}`} </strong> {
                    [...Array(!getDroidsDataInfo?.dealRules?.repeat || getDroidsDataInfo?.dealRules?.repeat == 0 ? 1 : Number(getDroidsDataInfo?.dealRules?.repeat))].map((elementInArray, index) => <span className={index > getDroidsDataInfo?.currentRound ? `dots_color` : 'dots'} key={index}> </span> )
                    
                }</div>
            </div>
    )
}

export default ScatterChartHeader