import * as Yup from 'yup';

const  resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be at maximum 16 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain one uppercase letter, one lowercase letter, one number, and one special character. Ex: Jordan1@"      ),
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .min(8, 'Password must be at least 8 characters')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
  });

  export default resetPasswordSchema;
  