// In your Redux slice file (e.g., errorSlice.js)

import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {buyPurchaseAmount: false },
  {pricePurchaseAmount:false},
];

const errorsimple = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setDroidSimpleError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex((error) => Object.keys(error)[0] === key);
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = true;
      }
    },
    clearDroidSimpleError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex((error) => Object.keys(error)[0] === key);
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = false;
      }
    },
  },
});

export const { setDroidSimpleError, clearDroidSimpleError } = errorsimple.actions;

export default errorsimple.reducer;
