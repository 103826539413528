import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userAuthenticate, sendOTP, verifyNonAuthPhoneEmail, socialSignup, userExistStatus } from "features/auth/authActions";
import twoFactorValidationSchema from "../helper/twoFactorValidationSchema";
import { Link, useNavigate } from "react-router-dom";
import { setCredentials } from 'features/auth/authSlice'
import Button from "./Button";
import SessionTimeout from "SessionTimeout";

const TwoFactorAuthentication = () => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [resendCount, setResendCount] = useState(1);
  const { userInfo, socialLogin } = useSelector((state) => state.auth);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getRandomID, setRandomID] = useState()

  function generateAlphaNumeric(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%&*0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  var randomID = generateAlphaNumeric(8)
  const newUserVerifySendOtp = () => {
    setRandomID(randomID)
    const payload = { type: "send", identifier: "email", email: socialLogin?.item?.data?.email, sessionId: randomID }
    dispatch(verifyNonAuthPhoneEmail({ payload }))
      .then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
        }
      });
  }

  const newUserVerify = (values) => {
    const signUpData = {}
    const payload = { type: "verify", identifier: "email", otp: values?.otp, sessionId: getRandomID }
    if (getRandomID) {
      dispatch(verifyNonAuthPhoneEmail({ payload })).then(
        (response) => {
          setIsFormSubmitted(false)
          if (response.meta && response.meta.requestStatus === "fulfilled") {
            signUpData.social_id = socialLogin?.item?.data?.social_id
            signUpData.social_type = socialLogin?.item?.data?.social_type
            signUpData.role = socialLogin?.item?.data?.role
            signUpData.name = socialLogin?.item?.data?.name
            signUpData.email = socialLogin?.item?.data?.email
            dispatch(socialSignup({ signUpData })).then(
              (response) => {
                setIsFormSubmitted(false)
                if (response.meta && response.meta.requestStatus === "fulfilled") {
                  navigate("/dashboard");
                }
              });
          }
        });
    }
  }

  const oldUserVerify = (values) => {
    dispatch(userAuthenticate(values))
      .then((response) => {
        setIsFormSubmitted(false)
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          dispatch(setCredentials(response.payload))
          navigate("/dashboard");
        }
      });
  }

  const userExistStatusCheck = () => {
    var UserEmail = socialLogin?.item?.data?.email
    dispatch(userExistStatus(UserEmail)).then(
      (response) => {
        if (response?.payload === 'User with provided email is already exist.') {
          const verifyOTPData = {
            email: socialLogin?.item?.data?.email
          }
          dispatch(sendOTP(verifyOTPData));
        } else { newUserVerifySendOtp() }
        if (response.meta && response.meta.requestStatus === "fulfilled") {
        }
      });
  }
  const onSubmitHandler = (values) => {
    if (localStorage.getItem('userToken') !== null) {
      window.location.reload()
    } else {
      setIsFormSubmitted(true)
      if (userInfo) {
        oldUserVerify(values)
      } else {
        newUserVerify(values)
      }
    }
  };

  var count = 0
  useEffect(() => {
    if (!userInfo && !socialLogin) {
      navigate("/login")
    }
  }, [userInfo, navigate])

  useEffect(() => {
    if (!userInfo && count == 0) {
      count += 1
      userExistStatusCheck()
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const resendOTP = () => {
    if (resendCount < 3) {
      if (userInfo) {
        const verifyOTPData = {
          email: userInfo.email.value,
        }
        dispatch(sendOTP(verifyOTPData));
      } else {
        newUserVerifySendOtp()
      }
    }
    setMinutes(0);
    setSeconds(30);
    setResendCount(resendCount + 1)
  };
  return (
    <div className="loginFormContainer otpContainer">
      
      {/* <SessionTimeout /> */}
      <div className="d-flex login_back_button otpBox">
        <div className="px-5 h4-One d-flex">OTP Verification</div>
      </div>
      {localStorage.getItem('userToken') !== null ? 'Close another Tab' : <div className="d-flex justify-content-center right-0">
        {userInfo || socialLogin ? (
          <Formik initialValues={{ email: userInfo?.email?.value || socialLogin?.item?.data?.email, otp: "" }} validationSchema={twoFactorValidationSchema} onSubmit={(values) => { onSubmitHandler(values) }} >
            {({ values, errors, touched, isValid }) => (
              <Form className="authenticate">
                <label className="mb-3">We've sent a one-time password to your {userInfo?.phone?.value ? "phone" : ""} <span className="terms-text"> {userInfo?.phone?.value} </span>{userInfo?.phone?.value && userInfo?.email?.value && "&"} {userInfo?.email?.value ? "email" : ""} <span className="terms-text"> {userInfo?.email?.value || socialLogin?.item?.data?.email}</span> for secure access.</label>
                <label>Please enter your OTP</label>
                <Field placeholder="Enter OTP" type="text" name="otp" maxLength="4" />
                {errors.otp && touched.otp && <div className="text-danger">{errors.otp}</div>}
                <div className="mt-2">
                  {resendCount > 3 ?
                    <p>You have exceeded your limit to receive OTP. Please try again after some time.</p> : seconds > 0 || minutes > 0 ?
                      <p>  Resend OTP in {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}</p> :
                      <p>Didn't recieve code? <span className="Resend-count">Resend({resendCount}/3)</span></p>}
                  {!(seconds > 0 || minutes > 0) && resendCount < 4 && <div className="CursorPointer mt-1" onClick={resendOTP}> Resend OTP </div>}
                </div>
                <div className="d-flex justify-content-center">
                <Button className="mt-2 d-flex justify-content-center" type="submit" loading={isFormSubmitted} disabled={isFormSubmitted || !isValid || !values.otp.trim()} text="Verify" />
                </div>
              </Form>
            )}
          </Formik>
        ) : ''}
      </div>}

    </div>
  );
};

export default TwoFactorAuthentication;
