import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../ManagementScreen/ManagementTask/index.scss";
import "../ManagementScreen/index.scss";
import Header from "../Header";
import {
  allConnectedUserListClient,
  getQuestionsAPI,
} from "features/Management/managementAction";
import { useDispatch, useSelector } from "react-redux";
import { TaskIcon, UserIcon, PortfoliosIcon, DroidIcon } from "assets";
import ThreeBarIcon from "assets/JsImage/ThreeBarIcon";
import { getExchangeList } from "features/Exchange/ExchangeAction";
import { getAllDroids } from "features/Droids/droidsAction";

function Management() {
  const location = useLocation();
  const [pageNo, setpageNo] = useState(1);
  const dispatch = useDispatch();
  const droids = useSelector((state) => state);
  const {
    porfolioByUserIdData,
    allConnectedUserListClientData,
    getQuestionsAPIInfo,
    allConnectedUserCount,
  } = useSelector((state) => state?.management);
  const { userToken } = useSelector((state) => state.auth);
  const { getExchangeInfo } = useSelector((state) => state?.Exchange);
  const [active, setActive] = useState(location?.pathname?.split("/")[2]);
  const [getClientData, setClientData] = useState([]);
  const [activeDroidData, setActiveDroidData] = useState([]);
  const { isdeleteDroid, ispostDroids } = useSelector((state) => state?.droids);
  const handleActive = () => {
    setActive(!active);
  };
  useEffect(() => {
    dispatch(getQuestionsAPI({ userToken })).then((response) => {});
    setActive(location?.pathname?.split("/")[2]);
  }, [location?.pathname?.split("/")[2]]);

  // useEffect(() => {
  //   fetchConnectedUserListClient();
  // }, [pageNo]);

  // useEffect(() => {
  //   dispatch(getQuestionsAPI({ userToken })).then((response) => {});
  //   if (localStorage.getItem("role") !== "broker") {
  //     dispatch(getExchangeList({ userToken })).then((res) => {
  //       if (res) {
  //         localStorage.setItem(
  //           "retailerExchangeConnection",
  //           JSON.stringify(res?.payload)
  //         );
  //       }
  //     });
  //   }
  // }, [isdeleteDroid, ispostDroids]);

  // useEffect(() => {
  //   getAllDrodListOnLoading();
  // }, []);

  const fetchConnectedUserListClient = () => {
    const payload = { page: pageNo };
    dispatch(allConnectedUserListClient({ userToken, payload })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setClientData([...getClientData, ...res?.payload?.userData]);
        if (res?.payload?.userData?.length === 5) {
          setpageNo((prev) => prev + 1);
        }
      }
    });
  };

  const getAllDrodListOnLoading = () => {
    dispatch(getAllDroids({ userToken })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        const filteredDroid = res.payload.filter(
          (droidData) => droidData.status === "active"
        );
        setActiveDroidData(filteredDroid);
      }
    });
  };
  const headerData = [
    {
      text: "Task",
      number: getQuestionsAPIInfo?.length || 0,
      imageIcon: <TaskIcon color={active === "task" ? "#fff" : "#0078E7"} />,
      link: "task",
    },

    {
      text: "Clients",
      number: getClientData?.length,
      imageIcon: <UserIcon color={active === "clients" ? "#fff" : "#0078E7"} />,
      link: "clients",
    },
    {
      text: "Portfolios",
      number:
        porfolioByUserIdData?.filter((item) => item?.balance > 0.01)?.length ||
        0,
      imageIcon: (
        <PortfoliosIcon color={active === "portfolios" ? "#fff" : "#0078E7"} />
      ),
      link: "portfolios",
    },
    {
      text: "Droids",
      number: activeDroidData.length,
      imageIcon: <DroidIcon color={active === "droids" ? "#fff" : "#0078E7"} />,
      link: "droids",
    },
    {
      text: "Exchange",
      number: getExchangeInfo?.filter((e) => e?.isIntegrated)?.length || 0,
      imageIcon: (
        <ThreeBarIcon color={active === "exchange" ? "#fff" : "#0078E7"} />
      ),
      link: "/exchange",
    },
  ];

  return (
    <div className="row w-100">
      <Header
        active={active}
        setActive={setActive}
        headerData={headerData}
        handleActive={handleActive}
      />
      <div className="ml-2">
        <Outlet />
      </div>
    </div>
  );
}

export default Management;
