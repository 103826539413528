import React from 'react'
import './Error.scss'

// not using this page
const ErrorPageDemo = () => {
  return (
    <div className="bg-purple">

      <div className="stars">
        <div className="custom-navbar">
          <div className="brand-logo">
            <img src={require('assets/companylogo.svg').default} style={{ width: 130 }} alt='' loading='lazy' />
          </div>
        </div>
        <div className="central-body">
          <img className="image-404" src={require('assets/tranceparentCoin/money-crypto.gif')} style={{ width: 200 }} alt='' loading='lazy' />
          <a href={"https://uat.8081.io"} className="btn-go-home" rel='noreferrer'>GO BACK HOME</a>
        </div>
        <div className="objects">
          <img className="object_rocket" src={require('assets/rocket.svg').default} style={{ width: 40 }} alt='' loading='lazy' />
          <div className="earth-moon">
            <img className="object_earth" src={require('assets/earth.svg').default} style={{ width: 100 }} alt='' loading='lazy' />
            <img className="object_moon" src={require('assets/moon.svg').default} style={{ width: 80 }} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut1">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=ADA.svg').default} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut2">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=BTC.svg').default} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut3">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=ETH.svg').default} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut4">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=LTC.svg').default} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut_left1">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=LTC.svg').default} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut_left2">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=USDT.svg').default} alt='' loading='lazy' />
          </div>
          <div className="box_astronaut_left3">
            <img className="object_astronaut" src={require('assets/tranceparentCoin/TICKER=XRP.svg').default} alt='' loading='lazy' />
          </div>
        </div>
        <div className="glowing_stars">
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPageDemo