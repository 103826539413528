import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SecondryButton from "components/Button/SecondryButton";
import { LikeIcon, ArrowRightIcon, ArrowLeftIcon, Email, BTC, ETH, XRP, LTC, SOL, Tether, Cardano, BAT, EURO, USD } from "assets";
import './index.scss'
import Companyicon from 'assets/JsImage/Companyicon';
import ConverNumber from 'components/ConvertNumber';
import MessageCardContainer from 'components/MessageCardContainer';
import UNKC from "../../../assets/unkCoin.png";
import Tooltip from 'react-bootstrap/Tooltip'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function ClientsCard(props) {
    const [getMessageNumber, setMessageNumber] = useState(1)
    const navigate = useNavigate();
    // const [showTooltip, setShowTooltip] = useState(false);
    const [getMessage, setMessage] = useState(props?.messages)
    const [deleteMessage, setDeleteMessage] = useState('')

    const coinData = [
        { coin: 'BTC', image: <BTC text={'#F7931A'} wd={'40'} ht={'40'} />, label: 'BTC' },
        { coin: 'ETH', image: <ETH text={'#393939'} wd={'40'} ht={'40'} />, label: 'ETH' },
        { coin: 'XRP', image: <XRP text={'#000000'} wd={'40'} ht={'40'} />, label: 'XRP' },
        { coin: 'LTC', image: <LTC text2={'#F8F9FB'} text={'#D9D9D9'} wd={'40'} ht={'40'} />, label: 'LTC' },
        { coin: 'SOL', image: <SOL text={'url(#paint0_linear_1601_37368)'} wd={'40'} ht={'40'} />, label: 'SOL' },
        { coin: 'USDT', image: <Tether text={'#50AF95'} wd={'40'} ht={'40'} />, label: 'USDT' },
        { coin: 'ADA', image: <Cardano text={'#003AC4'} wd={'20'} ht={'20'} bg={'#F8F9FB'} />, label: 'ADA' },
        { coin: 'BAT', image: <div className='coinHeightWeight'><BAT text={'url(#paint0_linear_1601_37368)'} wd={'20'} ht={'20'} className="mb-5" /></div>, label: 'BAT' },
        { coin: 'EUR', image: <div className='coinHeightWeight'><EURO wd={'25'} ht={'25'} /></div>, label: 'EUR' },
        { coin: 'GBP', image: <div className='UNKcoinHeightWeight'><img src={UNKC} className='UNKcoinHeightWeight' loading='lazy'/></div>, label: 'GBP' },
        { coin: 'USD', image: <div className='coinHeightWeight'><USD wd={'20'} ht={'20'} /></div>, label: 'USD' },
        { coin: 'USDCkkk', image: <div className='coinHeightWeight'><USD wd={'20'} ht={'20'} /></div>, label: 'USDC' },
        { coin: 'LINK', image: <div className='UNKcoinHeightWeight my-2 mx-2'><img src={UNKC} className='UNKcoinHeightWeight' loading='lazy'/></div>, label: 'LINK' },
        { coin: 'ZEC', image: <div className='UNKcoinHeightWeight my-2 mx-2'><img src={UNKC} className='UNKcoinHeightWeight' loading='lazy'/></div>, label: 'ZEC' },
        { coin: 'BCH', image: <div className='UNKcoinHeightWeight my-2 mx-2'><img src={UNKC} className='UNKcoinHeightWeight' loading='lazy'/></div>, label: 'BCH' },
        { coin: 'UNK', image: <div className='UNKcoinHeightWeight my-2 mx-2'><img src={UNKC} className='UNKcoinHeightWeight' loading='lazy'/></div>, label: '' },

    ];

    function capitalizeFirstLetter(str) {
        return (str === undefined || str === '') ? '' : (`${str.substring(0, 1).toUpperCase()}${str?.substring(1)}`);
    }
    function hasValueGreaterThanZero(obj) {
        for (const key in obj) {
            if (obj[key] > 0) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        if (deleteMessage !== '') {
            setMessage(getMessage?.filter(item => item._id !== deleteMessage))
        }
    }, [deleteMessage]);

    const balanceSum = (arr)=> {
     let sum = arr.reduce((acc,cur) => {
       acc = acc + cur?.balance
       return acc
     },0)

     return sum
    }

    return (
      <>
        <div className="col-lg-2_5 col-md-2_5 col-sm-2_5">
          <div className="accordion card_item ms-3 p-3 ">
            <div className="align-items-center">
              <button
                className="accordion-button collapsed d-flex gap-2 ps-5"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collaps${props?._id}`}
                aria-expanded="false"
              >
                <img
                  src={
                    props?.profileImage === null
                      ? require("../../../assets/commonAvtar.svg").default
                      : props?.profileImage
                  }
                  alt=""
                  className="portfolioUserIcon"
                  loading="lazy"
                />
                <div className="d-grid">
                  <span title={props?.name} className="ClientName text-break">
                    {props?.name?.length > 10
                      ? props?.name?.slice(0, 10) + "..." || "null"
                      : props?.name || "Retailer"}
                  </span>
                  <span className="clientTags width-max">
                    {props?.clientPlan || "null"}
                  </span>
                </div>
              </button>
              {props?.isRegisterUser ? (
                <div
                  id={`collaps${props?._id}`}
                  className="accordion-collapse collapse mt-3"
                >
                  <div className="d-grid gap-2">
                    {props?.clientBadges?.map((item, i) => (
                      <span className="client-dropdown ms-5">
                        {" "}
                        <LikeIcon /> <img alt="" loading="lazy" /> {item}{" "}
                      </span>
                    ))}
                    <div className="AccordionFooter">
                      <SecondryButton
                        text={
                          props?.isRegisterUser
                            ? "Client Page"
                            : "Deactive User"
                        }
                        disabled={!props?.isRegisterUser}
                        className="webkit-fill-available justify-content-center button"
                        handleClick={() =>
                          navigate("/client-Dashboard-Details", {
                            state: { userId: props?._id },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8_5 col-md-8_5 col-sm-8_5">
          <div className="row card_item mx-3 p-3 align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-4 position-relative p-0">
              <div className="">
                <h5>{props?.portfolio?.length} Portfolio</h5>
                {props?.availableCoins?.length > 0 && (
                    <OverlayTrigger
                      placement="right"
                      delay={{ hide: 1, show: 1 }}
                      overlay={(overlayProps) => (
                        <Tooltip
                          {...overlayProps}
                          className="custom-tooltip-react"
                          style={{
                            backgroundColor: "white",
                            padding: "1rem",
                            color: "white",
                            border: "1px solid #E5E6E9",
                            borderRadius: "12px",
                            opacity:'1',
                            ...overlayProps.style,
                          }}
                        >
                          <div
                            className="row justify-content-start align-items-center bg-white"
                            style={{ color: "black" }}
                          >
                            {props?.availableCoins?.map((item, index) => (
                              <div
                                key={index}
                                className={
                                  props?.availableCoins?.length <= 4
                                    ? "col-6"
                                    : "col-4"
                                }
                              >
                                {coinData.map((coin) => (
                                  <React.Fragment key={coin.coin}>
                                    {item === coin?.coin && (
                                      <div className="d-flex justify-content-center gap-2">
                                        <div className="">
                                          {coin.image}
                                          <div className="d-flex justify-content-center gap-2">
                                            {coin.label}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                                {coinData.every(
                                  (coin) => item !== coin.coin
                                ) && (
                                  <div className="d-flex justify-content-center gap-2">
                                    <div className="">
                                      {
                                        coinData.find(
                                          (coin) => coin.coin === "UNK"
                                        )?.image
                                      }
                                      <div>{item}</div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Tooltip>
                      )}
                    >
                      {/* <Button variant="warning">Tooltip Button</Button> */}
                      <div className="d-flex align-items-center curser-pointer">
                        {props?.availableCoins
                          ?.slice(0, 4)
                          ?.map((item, index) => {
                            return (
                              <>
                                {coinData?.map((coin, i) => {
                                  return (
                                    <>{coin.coin === item && coin.image} </>
                                  );
                                })}
                                {coinData.every(
                                  (coin) => item !== coin.coin
                                ) && (
                                  <div className="d-flex justify-content-center gap-2">
                                    <div className="">
                                      {
                                        coinData.find(
                                          (coin) => coin.coin === "UNK"
                                        )?.image
                                      }
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        {props?.availableCoins?.length > 4 && (
                          <h5 className="d-flex">
                            {props?.availableCoins?.length > 4
                              ? "+" + (props?.availableCoins?.length - 4)
                              : ""}
                          </h5>
                        )}
                      </div>
                    </OverlayTrigger>
                )}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-0">
              <div className="d-flex">
                <div className="clientsBalance mar-l-20 gap-2">
                  <span className="client-balance d-flex">
                    $
                    <ConverNumber
                      data={
                        props?.portfolio.length > 0
                          ? balanceSum(props?.portfolio).toFixed(2)
                          : "0"
                      }
                    />
                  </span>
                </div>
                {/* <div className="clientsBalance gap-2">
                                <small className={`${props?.lossOrProfit < 0 ? "text-danger" : "text-success"}`}>{props?.lossOrProfit < 0 ? '-' : props?.lossOrProfit === 0 ? '' : '+'}{props?.lossOrProfit}</small>
                            </div> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-0">
              <div
                className={`${
                  props?.risk !== "" ||
                  props?.risk !== undefined ||
                  props?.risk !== null
                    ? "risk_width"
                    : ""
                }`}
              >
                {hasValueGreaterThanZero(props?.avarageRisk) ? "" : "---"}
                {Object.keys(props?.avarageRisk)?.map((item) => {
                  return (
                    <>
                      {props?.avarageRisk[item] === 0 ? (
                        ""
                      ) : (
                        <span className="font-width-6">
                          {" "}
                          <img
                            className="ML-5"
                            src={require(`assets/risk/risk_${capitalizeFirstLetter(
                              item?.trim()
                            )}.svg`)}
                            alt=""
                            loading="lazy"
                          />{" "}
                          {props?.avarageRisk[item]}{" "}
                        </span>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-0">
              <strong className="font-width-6 mar-l-25">
                {props?.connectedDroid}
                <small className="small-text">/{props?.allottedDroids}</small>
              </strong>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-0">
              <div className="mar-l-25">
                <span className="d-flex progress_precent font-width-6">
                  <ConverNumber
                    data={
                      props?.healthPercentage === ""
                        ? 0
                        : props?.healthPercentage
                    }
                  />
                  %
                </span>
                <div className="ClientHealth">
                  <div className="progress">
                    <div
                      className={`progress-bar ${
                        props?.healthPercentage < 33
                          ? "bg-danger"
                          : props?.healthPercentage < 66
                          ? "bg-warning"
                          : "bg-success"
                      }`}
                      role="progressbar"
                      style={{ width: `${props?.healthPercentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id={`collaps${props?._id}`}
              className="accordion-collapse p-0 collapse mt-3"
            >
              {props?.portfolio?.map((item, index) => {
                return (
                  <>
                    <div
                      className={`row px-2 ${
                        item?.coins?.length == 0 ? "mb-4" : ""
                      }`}
                    >
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div>
                          <p>{item?.name}</p>
                          <div className="d-flex align-items-center flex-wrap">
                            {item?.coins?.map((item, index) => {
                              return (
                                <>
                                  {coinData?.map((coin, i) => {
                                    return (
                                      <>{coin.coin === item && coin.image} </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 p-0">
                        <div className="clientsBalance mar-l-20">
                          <span className="client-balance d-flex">
                            $
                            {
                              <ConverNumber
                                data={
                                  item?.balance === null
                                    ? "0"
                                    : item?.balance?.toFixed(2)
                                }
                              />
                            }
                          </span>
                          {/* <small className={`${item?.lossOrProfit < 0 ? "text-danger" : "text-success"}`}>{item?.lossOrProfit < 0 ? '-' : item?.lossOrProfit === 0 ? '' : '+'}{item?.lossOrProfit}</small> */}
                        </div>
                      </div>
                      <div
                        className={`col-lg-2 ${
                          props?.risk != "" ||
                          props?.risk != undefined ||
                          props?.risk != null
                            ? "risk_width"
                            : ""
                        }`}
                      >
                        <span>
                          {" "}
                          {item?.risk !== "" ||
                          item?.risk !== undefined ||
                          item?.risk !== null ? (
                            <img
                              className="ML-5 pe-2"
                              src={
                                item?.risk
                                  ? require(`assets/risk${capitalizeFirstLetter(
                                      item?.risk?.trim()
                                    )}.svg`)
                                  : ""
                              }
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            ""
                          )}{" "}
                          {capitalizeFirstLetter(item?.risk?.trim())}{" "}
                        </span>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 p-0">
                        <strong className="mar-l-25">
                          {item?.usedDroids}
                          <small className="small-text ">
                            /{props?.totalDroids}
                          </small>
                        </strong>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 p-0">
                        <div className="mar-l-25">
                          <span className="progress_precent d-flex">
                            <ConverNumber
                              data={
                                item?.healthPercentage === ""
                                  ? 0
                                  : item?.healthPercentage
                              }
                            />
                            %
                          </span>
                          <div className="ClientHealth">
                            <div className="progress">
                              <div
                                className={`progress-bar ${
                                  item?.healthPercentage < 33
                                    ? "bg-danger"
                                    : item?.healthPercentage < 66
                                    ? "bg-warning"
                                    : "bg-success"
                                }`}
                                role="progressbar"
                                style={{ width: `${item?.healthPercentage}%` }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {props?.portfolio?.length - 1 !== index ? <hr /> : ""}
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-2_5 col-md-2_5 col-sm-2_5">
          <div className="card_item  p-3">
            <div className="openActionRow">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <Email height={20} width={20} />
                  <span>
                    Messages <strong>{getMessage?.length || 0}</strong>
                  </span>
                </div>
                {props && getMessage?.length > 0 ? (
                  <div
                    id={`collaps${props?._id}`}
                    className="accordion-collapse collapse"
                  >
                    <div className={`d-flex  align-items-center`}>
                      <div
                        className="ms-2"
                        onClick={() =>
                          setMessageNumber(
                            getMessageNumber > 1 ? getMessageNumber - 1 : 1
                          )
                        }
                      >
                        <ArrowLeftIcon
                          color={getMessageNumber > 1 ? "#1F88EA" : "#E5E6E9"}
                        />
                      </div>
                      <div className="more-message-number d-flex align-items-baseline">
                        <div className="current">{getMessageNumber}</div>/
                        {getMessage?.length || 0}
                      </div>
                      <div
                        className="ms-2"
                        onClick={() =>
                          setMessageNumber(
                            getMessageNumber < getMessage?.length
                              ? getMessageNumber + 1
                              : getMessageNumber
                          )
                        }
                      >
                        <ArrowRightIcon
                          color={
                            getMessageNumber < getMessage?.length
                              ? "#1F88EA"
                              : "#E5E6E9"
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {props && getMessage?.length > 0 ? (
                <div
                  id={`collaps${props?._id}`}
                  className="accordion-collapse collapse mt-4"
                >
                  {" "}
                  <MessageCardContainer
                    setDeleteMessage={setDeleteMessage}
                    {...getMessage[getMessageNumber - 1]}
                  />{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="openActionRow d-flex gap-2 align-items-center">
              <Companyicon height="23" width="24" />
              <span>
                Waiting for Approval{" "}
                <strong>{props?.waitingForApproval || 0}</strong>
              </span>
            </div>
          </div>
        </div>
      </>
    );
}

export default ClientsCard
