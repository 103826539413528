import React from "react";

function FlagIcon({color, height=18, width=18}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 18"
    >
      <path
        fill={color ? color : "#060201"}
        fillRule="evenodd"
        d="M.25 1A.75.75 0 011 .25h14a.75.75 0 01.53 1.28L11.56 5.5l3.97 3.97a.75.75 0 01-.53 1.28H1.75V17a.75.75 0 01-1.5 0V1zm1.5 8.25h11.44L9.97 6.03a.75.75 0 010-1.06l3.22-3.22H1.75v7.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FlagIcon;
