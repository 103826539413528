
import Button from "components/Button";
import SecondryButton from "components/Button/SecondryButton";
import PopupModal from 'components/PopupModal'
import React from 'react'

const ProfileUpload = ({ CancleFile, inputRef, handleClick, uploadedImage, getImage, handleUpload, handleFileChange }) => {
  return (
    <PopupModal id={"imageUplode"} backdrop={false} >
      <div >
        <div className="modal-header border-0" >
          <h3 className="modal-title" id="exampleModalLabel">Upload Image </h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={CancleFile} />
        </div>
        <div className="modal-body ">
          <div className="card border-0 w-100 upload-color-bg">
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img accept="image/*" src={require(`assets/upload.svg`).default} className="p-2 curser-pointer" alt="" onClick={handleClick} loading='lazy' />
            </div>
            <input className="d-none" id="upload" type="file" ref={inputRef} onClick={(e) => { e.target.value = null }} onChange={(e) => handleFileChange(e)} accept="image/png, image/jpg, image/jpeg" />
            <div className="card-body text-center">
              <span className="h5"> Drag & drop files or
                <small className="text-primary curser-pointer" onClick={handleClick}> Browse</small>
              </span>
              <br />
              <span className="text-muted"> Supported Formats: JPEG, PNG, JPG </span>
            </div>
          </div>
        </div>
        <p className="px-3 text-break"> {uploadedImage?.name !== undefined && `Upload File Name : ${uploadedImage?.name}`}</p>
        <div className="modal-footer border-0">
          <SecondryButton text={'Cancel'} type="button" data-bs-dismiss="modal" handleClick={CancleFile} />
          <Button text="Upload" disabled={!getImage?.length} databsdismiss="modal" handleClick={handleUpload} />
        </div>
      </div>
    </PopupModal>
  )
}

export default ProfileUpload