import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import { authApi } from './services/authService'
import profileReducer from '../features/profile/profileSlice'
import getSetupAssistanceStatusSliceReducer from '../features/SetupAssistance/SetupAssistanceStatusSlice'
import dashboardSlice from '../features/Dashboard/dashboardSlice'
import headerSclice from '../features/Header/headerSclice'
import communitySlice from '../features/Community/communitySlice'
import typeTraderSlice from '../features/typeTrader/typeTraderSlice'
import clientInviteSlice from '../features/Management/managementSlice'
import userMessageSlice from '../features/UserChat/userChatSlice'
import droidsSlice from '../features/Droids/droidsSlice'
import activityLogSlice from '../features/ActivityLog/ActivityLogSlice'
import contactUsSlice from '../features/GetInTouch/GetInTouchSlice'
import ExchangeSlice from '../features/Exchange/ExchangeSlice'
import coinListSlice from '../features/CoinList/CoinListSlice'
import DefaultState from '../features/DefaultState/DefaultStateSlice'
import DroidsStateData  from '../features/DroidState/DroidsStateSlice'
import ErrorSlice from 'features/ErrorDcaDroid/ErrorSlice'
import ErrorIndexDroid from 'features/ErrorIndexDroid/ErrorIndexDroid'
import AcceptRquestSlice from 'features/AcceptRequest/AcceptRquestSlice'
import ErrorSimpleDroid from 'features/ErrorSimpleDroid/ErrorSimpleDroid'
import ErrorTimeDroid from 'features/ErrorTimeDroid/ErrorTimeDroid'

const store = configureStore({
    reducer: {
        auth: authReducer,
        [authApi.reducerPath]: authApi.reducer,
        profile: profileReducer,
        setupAssistanceStatus: getSetupAssistanceStatusSliceReducer,
        dashboard: dashboardSlice,
        header:headerSclice,
        community: communitySlice,
        typeTrader:typeTraderSlice,
        management: clientInviteSlice,
        userMessage : userMessageSlice,
        droids: droidsSlice,
        activityLog : activityLogSlice,
        contactUs: contactUsSlice,
        Exchange: ExchangeSlice,
        coinList : coinListSlice,
        defaultState: DefaultState,
        singleDroidInfo: DroidsStateData,
        errorDroidSlice:ErrorSlice,
        errorDroidIndex:ErrorIndexDroid,
        acceptRequest: AcceptRquestSlice,
        errorDroidSimple: ErrorSimpleDroid,
        errorDroidTime: ErrorTimeDroid,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),
})

export default store
