import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorCode from 'ErrorCode'

export const getActivityLog = createAsyncThunk(
    'droids/getActivityLog',
    async ({ userToken, isNotification = false }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/getActivityLog?isNotification=${isNotification}`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const postUpdatePin = createAsyncThunk(
    'updatePin',
    async ({ userToken, payload, read }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.patch(
                `${process.env.REACT_APP_BASEURL}/api/updateActivityLog/${read === 'read' ? 'read' : 'pinned'}`,
                payload,
                config
            )

            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

// Message box in Management> Task Message Pin/Unpin Update API//
export const updateQuestionPin = createAsyncThunk(
    'updateQuestionPin',
    async ({ userToken, payload }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.patch(
                `${process.env.REACT_APP_BASEURL}/api/updateQuestionPin`,
                payload,
                config
            )

            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

//--------------------------------------------Delete message from activity log----------
export const deleteMessageFromActivity = createAsyncThunk(
    'community/deleteMessageFromActivity',
    async ({ userToken, id }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASEURL}/api/deleteActivityLog/${id}`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data.response
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


