import React, { useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function SingleSelectIconDropdown({
  className,
  title,
  dropdown,
  getClickDropdown,
  setClickDropdown,
  img,
  img2,
  disableDropdown,
  selectedPortfolios
}) {
  const { getcoinList } = useSelector((state) => state?.coinList);
  const { t } = useTranslation()

  function getIconUrl(symbol) {
    const item = getcoinList?.coins?.find((item) => item?.symbol === symbol);
    return item ? item.iconUrl : "null";
  }

  useEffect(() => {
    if (!disableDropdown) {
      setClickDropdown({})
    }
  }, [selectedPortfolios, disableDropdown]);

  return (
    <>
      <div
        className={`btn-group simpledropdown droid-dropdown-height ${className}`}
      >
        <button
          className="btn dropdown-toggle d-flex justify-content-between align-items-center"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          disabled={disableDropdown}
        >
          <div className="d-flex gap-3">
            {" "}
            {img}{" "}
            <div className=" d-flex align-items-center ">
              {getClickDropdown === "" || getClickDropdown === undefined
                ? title
                : getClickDropdown}
            </div>{" "}
          </div>
          <img
            src={require("assets/arrowDown.svg").default}
            alt=""
            className="ML-6"
            loading="lazy"
          />
        </button>
        <ul className="dropdown-menu coin_list">
          {dropdown?.map((item, index) => (
            <li
              key={index}
              className={`dropdown-item py-3 gap-2 d-flex ${dropdown.length - 1 === index ? "" : "border-bottom"
                }`}
              onClick={() =>
                setClickDropdown({
                  ...item,
                  iconUrl: getIconUrl(item?.name?.split("/")[0]),
                })
              }
            >
              <img
                src={getIconUrl(item?.name?.split("/")[0])}
                width="20"
                height="20"
                alt=""
                loading="lazy"
              />{" "}
              {item?.name} ({item?.symbol}){" "}
              <p>${parseFloat(item?.price)?.toFixed(2)}</p>{" "}
              {getClickDropdown ===
                <img src={img2} alt="demo" loading="lazy" /> && item.symbol}
            </li>
          ))}
        </ul>
      </div>
      {disableDropdown && <p className="error-message">{t('selectPortfolioFirst')}</p>}
    </>
  );
}

export default SingleSelectIconDropdown;
