import React from 'react'

const ConverNumber = ({ data, digit = 2 }) => {

  function removeTrailingZero(number) {
    const stringWithoutTrailingZero = parseFloat(number).toString();
    return stringWithoutTrailingZero;
  }
  function formatNumber(number) {
    if (number > 1000000000000) {
      return removeTrailingZero(Number((number / 1000000000000))?.toFixed(digit)) + 'T';
    } else if (number > 1000000000) {
      return removeTrailingZero(Number((number / 1000000000))?.toFixed(digit)) + 'B';
    } else if (number > 1000000) {
      return removeTrailingZero(Number((number / 1000000))?.toFixed(digit)) + 'M';
    } else if (number > 1000) {
      return removeTrailingZero(Number((number / 1000))?.toFixed(digit)) + 'K';
    } else {
      return Number?.isInteger(number) ? number : removeTrailingZero(Number(number)?.toFixed(digit));
    }
  }
  return (
    <div>{data === undefined ? 0 : formatNumber(data)}</div>
  )
}

export default ConverNumber