import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "assets";

const labels = ["Very Healthy", "Healthy", "At Low Risk", "At Risk"];

export const data = {
  labels,
  datasets: [
    {
      barThickness: 8,
      borderRadius: 3,
      borderSkipped: false,
      label: "Dataset 1",
      data: [8, 6, 4, 2,],
      borderColor: ["#14AA39", "#D4E13F", "#FFBC39", "#DE2A20"],
      backgroundColor: ["#14AA39", "#D4E13F", "#FFBC39", "#DE2A20"],
    },
  ],
};

export default function HorizontalBar(props) {

  const navigate = useNavigate();
  const navClick = (id) => {
    if (props?.satatus) {
      navigate("/management/clients", { state: { data: id } })
    }
  }
  return (
    <div className="webkit-fill-available py-3 px-3">
      <div onClick={() => { navClick('Excellent Health') }} className={`${localStorage.getItem('role') === 'broker' ? 'cursor' : ''} helth_card  tooltip-containerHealthCard mb-3 helth_card_hover_data`} >
        <p className="w-20">Excellent</p>
        <div className="progress b-0 mt-3 webkit-fill-available" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar bg-success" style={{ width: `${props?.excellent +0}%` }} ></div>
        </div>
        <p>{props?.excellent || 0}</p>
        {localStorage.getItem("role") === "retailer" ? '' : <div className="custom-tooltip-HealthCard mt-5"> Excellent Health <div className="ML-6 MB-1"><ArrowRightIcon color={"#0078E7"} /></div></div>}
      </div>
      <div onClick={() => { navClick('Good Health') }} className={`${localStorage.getItem('role') === 'broker' ? 'cursor' : ''} helth_card tooltip-containerHealthCard mb-3 helth_card_hover_data`} >
        <p className="w-20">Good</p>
        <div className="progress b-0 mt-3 webkit-fill-available" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar helth_card_good" style={{ width: `${props?.good + 0}%` }} ></div>
        </div>
        <p>{props?.good || 0}</p>
        {localStorage.getItem("role") === "retailer" ? '' : <div className="custom-tooltip-HealthCard mt-5"> Good Health <div className="ML-6 MB-1"><ArrowRightIcon color={"#0078E7"} /></div></div>}
      </div>
      <div onClick={() => { navClick('Average Health') }} className={`${localStorage.getItem('role') === 'broker' ? 'cursor' : ''} helth_card tooltip-containerHealthCard mb-3 helth_card_hover_data`} >
        <p className="w-20">Average</p>
        <div className="progress b-0 mt-3 webkit-fill-available" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar bg-warning" style={{ width: `${props?.average + 0}%` }} ></div>
        </div>
        <p>{props?.average || 0}</p>
        {localStorage.getItem("role") === "retailer" ? '' : <div className="custom-tooltip-HealthCard mt-5"> Average Health <div className="ML-6 MB-1"><ArrowRightIcon color={"#0078E7"} /></div></div>}
      </div>
      <div onClick={() => { navClick('Poor Health') }} className={`${localStorage.getItem('role') === 'broker' ? 'cursor' : ''} helth_card tooltip-containerHealthCard mb-3 helth_card_hover_data`} >
        <p className="w-20">Poor</p>
        <div className="progress b-0 mt-3 webkit-fill-available" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar bg-danger" style={{ width: `${props?.poor + 0}%` }} ></div>
        </div>
        <p>{props?.poor || 0}</p>
        {localStorage.getItem("role") === "retailer" ? '' : <div className="custom-tooltip-HealthCard mt-5"> Poor Health <div className="ML-6 MB-1"><ArrowRightIcon color={"#0078E7"} /></div></div>}
      </div>
    </div >
  );
}
