import { Address } from 'assets'
import React, { useState } from 'react'

const ProfileRightComponent = (props) => {
    const [getHoverShow, setHoverShow] = useState({ address: false, email: false, phone: false, companyDirector: false, license: false, companyNumber: false })

    const mouseHover = (name) => { setHoverShow(p => ({ ...p, [name]: true })) }
    const mouseOut = (name) => { setHoverShow(p => ({ ...p, [name]: false })) }

    return (
        <div>
            <div className="row mt-4 ms-4 d-flex justify-content align-items-center">
                <div className={`col-4 d-flex align-item-center gap-2`}>

                    <div className='align-items-start d-flex'> <div className='company-details-icon'><Address /></div> </div>
                    <div> <h5>Address</h5> <span className='detailsSpan lh-lg partial-address' onMouseOver={() => mouseHover('address')} onMouseOut={() => mouseOut('address')} > {(!props?.address || !(props?.address?.length > 0)) ? 'address' : props?.address?.length > 24 ? props?.address.substr(0, 25) + '...' : props?.address} </span>
                        {getHoverShow?.address && props?.address && <div className="full-address">{props?.address}</div>}
                    </div>
                </div>
                <div className={`col-4 d-flex align-item-center gap-2`}>
                    <div className='align-items-start d-flex'> <img src={require('assets/email.svg').default} alt="" className='company-details-icon' loading='lazy'/> </div>
                    <div>
                        <h5>Email</h5> <span className='detailsSpan lh-lg partial-address' onMouseOver={() => mouseHover('email')} onMouseOut={() => mouseOut('email')} > {!props?.email ? 'email' : props?.email?.value} </span>
                        {getHoverShow?.email && props?.email?.value && <div className="full-address">{props?.email?.value}</div>}
                    </div>
                </div>
                {localStorage.getItem('role') === 'retailer' ? <>
                    <div className={`col-4 d-flex align-item-center gap-2`}>
                        <div className='align-items-start d-flex'> <img src={require('assets/phone.svg').default} alt="" className='company-details-icon' loading='lazy'/> </div>
                        <div> <h5>Phone</h5>
                            <span className='detailsSpan lh-lg partial-address' onMouseOver={() => mouseHover('phone')} onMouseOut={() => mouseOut('phone')} > {!props?.phone?.value ? 'phone_number' : props?.phone?.value} </span>
                            {getHoverShow?.phone && props?.phone?.value && <div className="full-address">{props?.phone?.value}</div>}
                        </div>
                    </div>
                    <div className={`col-4 d-flex align-item-center gap-2 mt-3`}>
                        <div className='align-items-start d-flex'> <img src={require("assets/companynumber.svg").default} className='company-details-icon' alt="" loading='lazy'/> </div>
                        <div> <h5>Company Number</h5>
                            <span className='detailsSpan lh-lg partial-address' onMouseOver={() => mouseHover('companyNumber')} onMouseOut={() => mouseOut('companyNumber')} > {(!props?.company || props?.company?.company_number === null) ? 'company_number' : props?.company?.company_number == '' ? 'company_number' : props?.company?.company_number} </span>
                        </div>
                    </div>
                    <div className={`col-4 d-flex align-item-center gap-2 mt-4`}>
                        <div className='align-items-start d-flex'><img src={require('assets/companydirector.svg').default} className='company-details-icon' alt="" loading='lazy'/></div>
                        <div>
                            <h5>Company Directors</h5> <span className='detailsSpan lh-lg partial-address' onMouseOver={() => mouseHover('companyDirector')} onMouseOut={() => mouseOut('companyDirector')} > {(!props?.company || props?.company?.company_director === null) ? 'company_director' : props?.company?.company_director} </span>
                            {getHoverShow?.companyDirector && props?.company?.company_director && <div className="full-address">{props?.company?.company_director}</div>}
                        </div>
                    </div>
                    <div className={`col-4 d-flex align-item-center gap-2 mt-4`}>
                        <div className='align-items-start d-flex'><img src={require('assets/license.svg').default} alt="" className='company-details-icon' loading='lazy'/></div>
                        <div>
                            <h5>License</h5> <span className='detailsSpan lh-lg partial-address' onMouseOver={() => mouseHover('license')} onMouseOut={() => mouseOut('license')} > {(!props?.license_number || props?.license_number === null) ? 'license' : props?.license_number} </span>
                            {getHoverShow?.license && props?.license_number && <div className="full-address">{props?.license_number}</div>}
                        </div>
                    </div>
                </> : ''}
            </div>
        </div>
    )
}

export default ProfileRightComponent