import React from "react";

function USD({ht=40, wd=40, bg='#fff'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      fill="none"
      viewBox="0 0 560 560"
    >
      <g clipPath="url(#clip0_401_2)">
        <path
          fill="#2775CA"
          d="M280 560c155.168 0 280-124.832 280-280S435.168 0 280 0 0 124.832 0 280s124.832 280 280 280z"
        ></path>
        <path
          fill="#fff"
          d="M357 324.332c0-40.832-24.5-54.832-73.5-60.664-35-4.668-42-14-42-30.336 0-16.335 11.668-26.832 35-26.832 21 0 32.668 7 38.5 24.5 1.168 3.5 4.668 5.832 8.168 5.832h18.664c4.668 0 8.168-3.5 8.168-8.164V227.5c-4.668-25.668-25.668-45.5-52.5-47.832v-28c0-4.668-3.5-8.168-9.332-9.336h-17.5c-4.668 0-8.168 3.5-9.336 9.336V178.5c-35 4.668-57.164 28-57.164 57.168 0 38.5 23.332 53.664 72.332 59.5 32.668 5.832 43.168 12.832 43.168 31.5 0 18.667-16.336 31.5-38.5 31.5-30.336 0-40.836-12.836-44.336-30.336-1.164-4.664-4.664-7-8.164-7h-19.836c-4.664 0-8.164 3.5-8.164 8.168v1.168c4.664 29.164 23.332 50.164 61.832 56v28c0 4.664 3.5 8.164 9.332 9.332h17.5c4.668 0 8.168-3.5 9.336-9.332v-28c35-5.836 58.332-30.336 58.332-61.836z"
        ></path>
        <path
          fill={bg}
          d="M220.5 446.832c-91-32.664-137.668-134.164-103.832-224 17.5-49 56-86.332 103.832-103.832 4.668-2.332 7-5.832 7-11.668V91c0-4.668-2.332-8.168-7-9.332-1.168 0-3.5 0-4.668 1.164-110.832 35-171.5 152.836-136.5 263.668 21 65.332 71.168 115.5 136.5 136.5 4.668 2.332 9.336 0 10.5-4.668 1.168-1.164 1.168-2.332 1.168-4.664v-16.336c0-3.5-3.5-8.164-7-10.5zm123.668-364c-4.668-2.332-9.336 0-10.5 4.668-1.168 1.168-1.168 2.332-1.168 4.668V108.5c0 4.668 3.5 9.332 7 11.668 91 32.664 137.668 134.164 103.832 224-17.5 49-56 86.332-103.832 103.832-4.668 2.332-7 5.832-7 11.668V476c0 4.668 2.332 8.168 7 9.332 1.168 0 3.5 0 4.668-1.164 110.832-35 171.5-152.836 136.5-263.668-21-66.5-72.336-116.668-136.5-137.668z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_401_2">
          <path fill="#fff" d="M0 0H560V560H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default USD;