import React, { useEffect, useState } from "react";
import "./index.scss";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DroidsData } from "features/DroidState/DroidsStateAction";
import { useLocation } from "react-router-dom";
import UNKC from "../../assets/unkCoin.png";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function MultilevelDropDown({
  setBrokerExchange,
  selectProtfoliyoData,
  setSelectProtfoliyoData,
  getLoadingPortfolios,
  getClientPortfolios,
  setClientPortfolios,
}) {
  const [showTooltip, setShowTooltip] = useState({ id: "", show: false });
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const [clientPortfolioList, setclientPortfolioList] =
    useState(getClientPortfolios);
  const { getcoinList } = useSelector((state) => state?.coinList);
  const { t } = useTranslation();
  const { state } = useLocation();

  const dispatch = useDispatch();

  const droidDataDispatch = (item) => {
    const payload = { ...getDroidsDataInfo, ...item };
    dispatch(DroidsData({ payload })).then((res) => {});
  };

  const checkIfAllPortfolioSelected = (arrayOfObjects) => {
    for (const obj of arrayOfObjects) {
      if (!obj?.isActive || obj.isActive !== true) {
        return false;
      }
      if (obj?.portfolio && obj?.portfolio.length) {
        checkIfAllPortfolioSelected(obj.portfolio);
      }
    }
    return true;
  };

  const childCheckbox = (event, id, item) => {
    const { checked } = event.target;
    const updatedCheckboxes = clientPortfolioList?.map((item) => {
      if (item?.portfolio?.length > 0) {
        item?.portfolio?.forEach((child) => {
          if (child.id === id) {
            child.isActive = checked;
            item.isActive = checkIfAllPortfolioSelected(item.portfolio);
            droidDataDispatch({
              retailerIds: [item?._id],
              portfoliosIds: [
                {
                  portfolioId: item?.portfolio?.filter((i) => i.isActive)[0]
                    ?.id,
                },
              ],
              coinsList: [],
            });
            setSelectProtfoliyoData(!selectProtfoliyoData);
            setBrokerExchange(
              item?.portfolio
                ?.filter((i) => i.isActive)
                ?.map((activeItem) => activeItem.exchangeName)[0]
            );
          } else {
            child.isActive = false;
          }
        });
      }
      return item;
    });
    setClientPortfolios(updatedCheckboxes);
  };
  const [getSearchBroker, setSearchBroker] = useState("");

  useEffect(() => {
    if (getSearchBroker === "") {
      setclientPortfolioList(getClientPortfolios);
      return;
    } else {
      const filteredData = getClientPortfolios.filter(
        (item) =>
          item.name &&
          getSearchBroker &&
          item.name.toLowerCase().includes(getSearchBroker.toLowerCase())
      );
      setclientPortfolioList(filteredData);
    }

    // Filter the data based on the current search term
  }, [getSearchBroker]); // Run this effect whenever searchTerm changes

  useEffect(() => {
    setclientPortfolioList(getClientPortfolios);
  }, [getClientPortfolios]);

  let disable =
    state?.droidType?.nav === "indexmodedroid" ||
    state?.droidType?.nav === "timemodedroid" ||
    state?.droidType?.nav === "costmodedroid" ||
    state?.droidType?.nav === "safemodedroid";

  useEffect(() => {
    clientPortfolioList.forEach((obj) => {
      obj.portfolio.forEach((item) => {
        item.isActive = false;
      });
    });
    setclientPortfolioList(clientPortfolioList);
    setClientPortfolios(clientPortfolioList);
    setSelectProtfoliyoData(!selectProtfoliyoData);
  }, [state?.droidType?.nav]);

  return (
    <div className="accordion newDroidDropDown mb-2" id="accordionExample">
      {getClientPortfolios?.length && !getLoadingPortfolios ? (
        <div className="accordion-item">
          <h2 className="accordion-header " id="headingOne">
            <button
              className="accordion-button multiSelectHeader p-Three collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {" "}
              {t("selectPortfolio")}
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse dropdownContainer collapse px-2"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="position-relative">
                <img
                  src={require("assets/searchIcon.svg").default}
                  alt="search-icon"
                  className="position-absolute ps-3 pt-2"
                  loading="lazy"
                />
                <input
                  type="search"
                  placeholder={t("searchClients")}
                  className="ps-5 header_search"
                  onChange={(e) => setSearchBroker(e?.target?.value)}
                />
              </div>
              <div className="accordion" id="sub-accordionExample">
                {clientPortfolioList.length > 0 ? (
                  <div className="accordion-item">
                    {clientPortfolioList?.map((item, index) => (
                      <>
                        <h2 className="" id="sub-headingOne">
                          <button
                            className="accordion-button mb-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#sub-Collapse${index + 1}`}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                className="imageBox"
                                src={
                                  require("../../assets/commonAvtar.svg")
                                    .default
                                }
                                alt=""
                                loading="lazy"
                              />
                              <span className="ms-2">{item?.name}</span>
                            </div>
                          </button>
                        </h2>

                        <div
                          id={`sub-Collapse${index + 1}`}
                          className="accordion-collapse collapse "
                          aria-labelledby="sub-headingOne"
                          data-bs-parent="#sub-accordionExample"
                        >
                          <div className="accordion-body pb-2 ">
                            {!item?.portfolio ||
                            !(
                              item.portfolio.reduce(
                                (acc, obj) =>
                                  acc + parseFloat(obj.balance || 0),
                                0
                              ) > 0.01
                            ) ? (
                              item.portfolio ? (
                                <small className="d-flex justify-content-center">
                                  {t("noPortfolioBalance")}
                                </small>
                              ) : (
                                <small className="d-flex justify-content-center">
                                  {t("noPortfolioFound")}
                                </small>
                              )
                            ) : (
                              item?.portfolio
                                ?.filter((e) => e?.balance > 0.01)
                                ?.map(
                                  (element) => (
                                    <div className="d-flex mx-3 justify-content-between align-items-center my-2">
                                      <div className="d-flex gap-3 align-items-center ">
                                        <input
                                          className="checkbox selectContainer curser-pointer"
                                          type="radio"
                                          disabled={
                                            disable &&
                                            element?.exchangeName === "gemini"
                                          }
                                          onChange={(e) => {
                                            childCheckbox(e, element?.id, item);
                                          }}
                                          checked={element?.isActive}
                                          id="flexCheckDefault"
                                        />
                                        <p className="fw-light">
                                          {element?.name}
                                        </p>
                                      </div>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ hide: 1, show: 1 }}
                                        overlay={(overlayProps) => (
                                          <Tooltip
                                            {...overlayProps}
                                            className="custom-tooltip-react1"
                                            style={{
                                              backgroundColor: "white",
                                              padding: ".5rem",
                                              color: "white",
                                              border: "1px solid #E5E6E9",
                                              borderRadius: "12px",
                                              opacity: "1",
                                              ...overlayProps.style,
                                            }}
                                          >
                                            <div style={{ color: "black" }}>
                                              <div className="row">
                                                <div className="col-4">
                                                  {t("balance")}
                                                </div>
                                                <div className="col-4">
                                                  {t("riskLevel")}
                                                </div>
                                                <div className="col-4">
                                                  {t("exchange")}
                                                </div>
                                              </div>
                                              <div className="row mb-1">
                                                <div className="col-4 text-capitalize">
                                                  <strong>
                                                    ${element?.balance}
                                                  </strong>
                                                </div>
                                                <div className="col-4 text-capitalize">
                                                  <strong>
                                                    {element?.risk}
                                                  </strong>
                                                </div>
                                                <div className="col-4 text-capitalize">
                                                  <strong>
                                                    {element?.exchangeName}
                                                  </strong>
                                                </div>
                                              </div>
                                              <div className="row mx-1 justify-content-center">
                                                {element?.coins?.map(
                                                  (symbol, index) => (
                                                    <div
                                                      key={index}
                                                      className="col-lg-2 col-md-3 d-grid justify-content-center"
                                                    >
                                                      <div className="UNKcoinHeightWeight my-2">
                                                        {getcoinList?.coins?.find(
                                                          (coin) =>
                                                            coin.symbol ===
                                                            symbol
                                                        ) ? (
                                                          <img
                                                            src={
                                                              getcoinList?.coins?.find(
                                                                (coin) =>
                                                                  coin.symbol ===
                                                                  symbol
                                                              )?.iconUrl
                                                            }
                                                            width="20"
                                                            height="20"
                                                            alt={symbol}
                                                            loading="lazy"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={UNKC}
                                                            width="24"
                                                            height="20"
                                                            alt=""
                                                            loading="lazy"
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                              {disable &&
                                                element.exchangeName ===
                                                  "gemini" && (
                                                  <small className="fw-bold text-danger">
                                                    {t("droidMsg")}
                                                  </small>
                                                )}
                                            </div>
                                          </Tooltip>
                                        )}
                                      >
                                        <img
                                          src={
                                            require(`assets/questionMark.svg`)
                                              .default
                                          }
                                          className="question-Mark-Container curser-pointer"
                                          alt="info"
                                          loading="lazy"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  )
                                  // </div>
                                )
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    {t("portfolioNotFound")}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          {getLoadingPortfolios ? <Loader /> : t("portfolioNotFound")}{" "}
        </div>
      )}
    </div>
  );
}

export default MultilevelDropDown;
