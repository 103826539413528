import React from "react";

function ArrowUPIcon({fill, color, height=24,width=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? color : "#fff"}
        fillRule="evenodd"
        d="M5.47 15.53a.75.75 0 001.06 0L12 10.06l5.47 5.47a.75.75 0 101.06-1.06l-6-6a.75.75 0 00-1.06 0l-6 6a.75.75 0 000 1.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowUPIcon;
