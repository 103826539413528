import React from 'react';
import CardShadow from 'components/CardShadow';
import Loader from 'components/Loader';

const ExchangeDetail = (props) => {
  const { getLoading } = props;
  const fundsData = [{
    value: props?.totalFundsAllocated || 0,
    label: 'Total Funds Allocated($)'
  }, {
    value: props?.totalFundsDeals || 0,
    label: 'Total Funds in Deals($)'
  }, {
    value: props?.Last30DaysPayments?.toFixed(2) || 0,
    label: 'Payments,Last 30 Days($)'
  }];

  return (
    <div>
      <CardShadow className="d-flex justify-content-center py-3 px-4 py-3">
        {props?.totalFundsAllocated === undefined ?
          <div className='d-flex justify-content-center align-items-center'>
            {getLoading ? <Loader /> : <small className='h6-One-Grey'>Connect to Exchange</small>}
          </div> :
          <div className='EX-Left-container'>
            <div className='EX-derbit d-flex align-items-center justify-content-center'>
              <img src={props?.icon} alt="" width={'auto'} height={20} loading='lazy' />
            </div>
            {fundsData.map((data, index) => <div key={index} className='my-3 p-1'><h2>{data.value}</h2>
              <small className='h6-One-Grey'>{data.label}</small></div>)}
          </div>}
      </CardShadow>
    </div>
  );
}

export default ExchangeDetail;
