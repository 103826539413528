import React, { useState, useEffect } from 'react'
import ReactApexChart from "react-apexcharts";
import ScatterChartBottom from './Accessories/ScatterChartBottom';
import ScatterChartHeader from './Accessories/ScatterChartHeader';

const ScatterChart = ({ RefreshIcon, RefreshTime, getRulesListData }) => {
  const [buyData, setBuyData] = useState([])
  const [sellData, setSellData] = useState([])
  const [series, setSeries] = useState([
    { name: 'Sell', type: 'scatter', data: [[0, 0]] },
  ])
  const options = {
    chart: { height: 200, type: 'line', zoom: { enabled: false, type: 'xy' }, toolbar: { show: false } },
    colors: ['#fff', '#fff', '#000'],
    stroke: { width: [0, 0, 2], curve: 'straight', dashArray: [0, 0, 5] },
    markers: { strokeColors: ['#14AA39', '#DE2A20'], strokeWidth: 2 },
    fill: { type: 'transparent' },
    tooltip: { shared: false, intersect: true },
    legend: { show: false },
    dataLabels: {
      enabled: false,
      formatter: (value) => `${value}`,
    },
    stroke: {
      width: 1,
      curve: 'smooth',
    },
    xaxis: {
      categories: ['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10'],
    },

    grid: {
      yaxis: {
        tickAmount: getRulesListData?.length,
        lines: { categories: [750, 200, 750, 200,] }
      },
    },
  }

  useEffect(() => {
    const buyThresholds = [];
    const sellThresholds = [];
    for (const item in getRulesListData) {
      if (getRulesListData[item]?.buyThreshold) {
        buyThresholds.push([Number(item) + 1, getRulesListData[item]?.buyThreshold]);
      }
      if (getRulesListData[item]?.sellThreshold) {
        sellThresholds.push([Number(item) + 1, getRulesListData[item]?.sellThreshold]);
      }
    }
    setBuyData(buyThresholds)
    setSellData(sellThresholds)

  }, [getRulesListData])

  useEffect(() => {
    setSeries([{
      name: 'Buy',
      type: 'scatter',
      data: buyData
    },
    {
      name: 'Sell',
      type: 'scatter',
      data: sellData
    }
      , {
      name: 'Current Price',
      type: 'line',
    }])
  }, [buyData, sellData])
  return (
    <div className='position-relative scatterChartContainer py-4 mt-5 mb-3'>
      <ScatterChartHeader headingText={'Deal Rules'} />
      <h4 className="my-2 me-2">Buy/Sell Thresholds <span className="dollar-sign">$</span></h4>
      <div className='ChartADA'>
        <ReactApexChart options={options} series={series} type="scatter" height="190" width='350' className=" mr-2" />
      </div>
      <ScatterChartBottom RefreshIcon={RefreshIcon} RefreshTime={RefreshTime} />
    </div>
  )
}

export default ScatterChart