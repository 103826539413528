import React, { useState } from 'react'
import Button from 'components/Button'
import { Avtar, MessageIcon } from 'assets'
import AskQustionPopup from 'components/PopupModal/AskQustionPopup'
import DescriptionPopup from 'components/PopupModal/DescriptionPopup';
import ProfileTrader from './ProfileTrader';
import ProfilePersonalGoals from './ProfilePersonalGoals';
import ProfileSocialNetworks from './ProfileSocialNetworks';
import ProfileLimitComponent from './ProfileLimitComponent';
// import ProfileSocialNetworks from "components/ProfileDetailsComponent/ProfileSocialNetworks";
// import ProfileLimitComponent from "components/ProfileDetailsComponent/ProfileLimitComponent";
import KnownSocialUser from "assets/Badges/knownSocialUser.png"
import BestUser from "assets/Badges/BestUser.png"
import CryptoGoal from "assets/Badges/CryptoGoal.png"
import InvestmentDoneRetailer from "assets/Badges/$10InvestmentDone.png"
import DroidsAchievementBroker from "assets/Badges/DroidsAchievementBroker.png"
import StarReviewRetailer from "assets/Badges/StarReviewRetailer.png"
import MegaStart from "assets/Badges/MegaStar.png"
import Invester from "assets/Badges/Invester.png"
import CustomerManager from "assets/Badges/CustomerManager.png"
import BestService from "assets/Badges/BestService.png"
import Loader from 'components/Loader';
const userRisk = {
  low: "Low",
  medium: "Medium",
  high: "High",
};
const ProfileDetailsComponent = (props) => {


  const BadgeIconsBroker = [
    { icon: BestUser, label: "Best Broker" },
    { icon: KnownSocialUser, label: " Social Broker Influencer" },
    { icon: CryptoGoal, label: 'Crypto Goal Broker' },
    { icon: DroidsAchievementBroker, label: '10 Droids achievement' },
    { icon: DroidsAchievementBroker, label: '100 Droids achievement' },
    { icon: DroidsAchievementBroker, label: '500 Droids achievement' },
    { icon: DroidsAchievementBroker, label: '1,000 Droids achievement' },
    { icon: DroidsAchievementBroker, label: '5,000 Droids achievement' },
    { icon: CustomerManager, label: '50+ Customer Manager' },
    { icon: CustomerManager, label: '500+ Customer Manager' },
    { icon: CustomerManager, label: '5000+ Customer Manager' },
    { icon: CustomerManager, label: '25,000+ Customer Manager' },
    { icon: BestService, label: '2022 Best Service from Customers' },
  ];
  const BadgeIconsRetailer = [
    { icon: BestUser, label: "Best User" },
    { icon: KnownSocialUser, label: "Known Social User" },
    { icon: CryptoGoal, label: 'Crypto Goal User' },
    { icon: InvestmentDoneRetailer, label: '$10 Investment done' },
    { icon: InvestmentDoneRetailer, label: '$1,000 Investment done' },
    { icon: InvestmentDoneRetailer, label: '$10,000 Investment done' },
    { icon: InvestmentDoneRetailer, label: '$25,000 Investment done' },
    { icon: InvestmentDoneRetailer, label: '$100,00 Investment done' },
    { icon: StarReviewRetailer, label: '3 Star Review from another user' },
    { icon: MegaStart, label: 'Mega Star review from Broker' },
    { icon: Invester, label: '$1 Million Gold Investor' },
    { icon: Invester, label: '$10 Million Diamond Investor' },
    { icon: Invester, label: '$100 Million Platinum Investor' },
  ];
  const [getLoading, setLoading] = useState(false)
  const riskValue = props?.limitTrade?.risk;
  const formattedRisk = riskValue ? riskValue.charAt(0).toUpperCase() + riskValue.slice(1) : null;
  return (
    <>
      <div className='profile-container p-4 userProfileIcon assistLinkCard  mb-5'>
        <div className='d-flex justify-content-center'>
          {!props?.image ? <Avtar height="9rem" weight="9rem" /> : <img src={props?.image} alt='' className='img_center rounded-circle curser-pointer avtar_image' loading='lazy' />}
        </div>

        <div className='text-center my-3'>
          <h1>{props?.full_name}</h1>
          {props?.broker ? <span className="subTitle"> {props?.company?.company_name}</span> : ''}
        </div>

        <div className="profileTextarea mt-2">
          <h5 className="mb-2">Description</h5>
          <ul> <li className="text-break">{(props?.description || 'Description').substr(0, 200)} </li>
          </ul>
          {props?.description?.length > 199 && <h5 className="text-primary" type="button" data-bs-toggle="modal" data-bs-target="#moreInformation1">More Information</h5>}
          <div className="border-bottom mt-3"></div>
        </div>
        {!props?.broker ? '' : <ProfileTrader {...props} />}
        <ProfileSocialNetworks {...props} />
        <div style={{ overflowX: 'scroll', position: 'relative', zIndex: '100' }}>

          <h5 className='mb-2'>Badges</h5>
          <div className='d-flex gap-2 mb-2 mt-2' style={{ width: "max-content" }}>
            {!props?.level ? <Loader /> :
              <span className="badgesList">Level {props?.level}</span>}
            {/* {props?.badges && props.badges.length > 0 ? (
            props.badges.map((item, ind) => (
              <span className="badgesList" key={ind}>{item?.title}</span>
            ))
          ) : ("")} */}
            {props?.badges?.map((item, index) => (
              <>
                {localStorage.getItem("role") === "broker" ? <>
                  {item?.role && (
                    <span key={index} className="badgesList2">
                      {BadgeIconsRetailer.map((badge, badgeIndex) => {
                        if (badge.label === item?.title) {
                          return <img key={badgeIndex} src={badge.icon} alt={badge.label} className="BadgeIconSize" />;
                        }
                        return null;
                      })}
                      {item?.title}
                    </span>
                  )}
                </> :
                  <>
                    {item?.role && (
                      <span key={index} className="badgesList2">
                        {BadgeIconsBroker.map((badge, badgeIndex) => {
                          if (badge.label === item?.title) {
                            return <img key={badgeIndex} src={badge.icon} alt={badge.label} className="BadgeIconSize" />;
                          }
                          return null;
                        })}
                        {item?.title}
                      </span>
                    )}
                  </>}
              </>
            ))}
          </div>
        </div>
        <hr />
        {props?.broker ? '' : <> <h5 className="badgess my-3">Risk</h5> <span className="badgesList2 mt-3">{props?.limitTrade?.risk ? formattedRisk : "Not selected yet"}</span> <div className="border-bottom mt-4"> </div> </>}
        <div className="mt-3 mb-5">
          {props?.broker ? <div className="mt-3"> <ProfilePersonalGoals {...props} /> </div> : <ProfileLimitComponent {...props} />}
        </div>
        {props?.full_name && props?.writeAMessage ? <Button databstoggle="modal" databstarget="#askqustion" icon={<MessageIcon />} text={`Write ${props?.full_name} a Message`} className='mt-5 gap-2 w-100' loading={getLoading} disabled={getLoading} /> : ''}
      </div>
      <AskQustionPopup {...props} setLoading={setLoading} />
      <DescriptionPopup {...props} />
    </>
  )
}

export default ProfileDetailsComponent