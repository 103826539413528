import React from 'react'

const Companyicon = ({height,width}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="1 0 25 25"
  >
    <path
      fill="#0078E7"
      d="M12 11.25a.75.75 0 01.75.75v.01a.75.75 0 01-1.5 0V12a.75.75 0 01.75-.75z"
    ></path>
    <path
      fill="#0078E7"
      fillRule="evenodd"
      d="M7.25 6.25V5A2.75 2.75 0 0110 2.25h4A2.75 2.75 0 0116.75 5v1.25H19A2.75 2.75 0 0121.75 9v9A2.75 2.75 0 0119 20.75H5A2.75 2.75 0 012.25 18v-4.986V9A2.75 2.75 0 015 6.25h2.25zm1.866-2.134A1.25 1.25 0 0110 3.75h4A1.25 1.25 0 0115.25 5v1.25h-6.5V5c0-.332.132-.65.366-.884zM3.75 14.18V18c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25v-3.821a20.75 20.75 0 01-16.5 0zm16.5-1.647a19.25 19.25 0 01-16.5 0V9c0-.69.56-1.25 1.25-1.25h14c.69 0 1.25.56 1.25 1.25v3.532z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default Companyicon