import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  DashboardIcon,
  ManagementIcon,
  CommunityIcon,
  ReportIcon,
  SupportIcon,
} from "assets";
import CircularProgressBar from "./CircularProgressBar";
import ClientDisplayPhoto from "assets/commonAvtar.svg";
import { useSelector } from "react-redux";

const SidebarTooltip = (props) => {
  const { image } = useSelector(
    (state) => state?.profile?.userProfileInfo || ""
  );
  return (
    <div>
      <OverlayTrigger
        placement="right"
        delay={{ hide: 1, show: 0 }}
        offset={[0, 50]}
        overlay={(overlayProps) => (
          <Tooltip
            {...overlayProps}
            className="custom-tooltip-react2"
            style={{
              backgroundColor: "white",
              padding: ".5rem",
              color: "white",
              border: "1px solid #E5E6E9",
              borderRadius: "12px",
              opacity: "1",
              zIndex: 9999,
              ...overlayProps.style,
            }}
          >
            <div
              className="row justify-content-start align-items-center bg-white"
              style={{ color: "black" }}
            >
              <div className="text-left" style={{ textAlign: "left" }}>
                <h4 className="mb-1">{props.popupTitle}</h4>
                {props.popupData}
                {props.popupData2 ? (
                  <>
                    <br />
                    <div className="mt-2">{props.popupData2}</div>
                  </>
                ) : null}
              </div>
            </div>
          </Tooltip>
        )}
      >
        <div className="d-flex align-items-center curser-pointer">
          {props.menuName === "dashboard" && (
            <div className="img sidebar-img">
              <DashboardIcon
                color={props.selectState !== props.menuName && "#858585"}
              />
            </div>
          )}
          {props.menuName === "management" && (
            <div className="img sidebar-img">
              <ManagementIcon
                color={
                  props.selectState !== props.menuName &&
                  props.selectState !== "Exchange" &&
                  "#858585"
                }
              />
            </div>
          )}
          {props.menuName === "community" && (
            <div className="img sidebar-img">
              <CommunityIcon
                color={
                  props.selectState !== props.menuName &&
                  props.selectState !== "userchat" &&
                  "#858585"
                }
                selected={props.selectState === props.menuName}
              />
            </div>
          )}
          {props.menuName === "report" && (
            <div className="img sidebar-img">
              <ReportIcon
                color={props.selectState !== props.menuName && "#858585"}
              />
            </div>
          )}
          {props.menuName === "support" && (
            <div className="img sidebar-img">
              <SupportIcon
                color={props.selectState !== props.menuName && "#858585"}
              />
            </div>
          )}
          {props.menuName === "profile" && (
            <div className="img sidebar-img" style={{ padding: "10px" }}>
              <div className="d-flex" style={{ width: "200px" }}>
                <CircularProgressBar
                  progress={56}
                  size={35}
                  strokeWidth={6}
                  imageSrc={
                    image === undefined || image === null
                      ? ClientDisplayPhoto
                      : image
                  }
                />
              </div>
            </div>
          )}
          <div className="sidebar-text">
            {props.selectState === "userchat" && (
              <p
                className={
                  props.menuName !== "community"
                    ? "text-p sidebar_anim"
                    : "text-p sidebar_animblue"
                }
              >
                {props.popupTitle}
              </p>
            )}
            {props.selectState === "Exchange" && (
              <p
                className={
                  props.menuName !== "management"
                    ? "text-p sidebar_anim"
                    : "text-p sidebar_animblue"
                }
              >
                {props.popupTitle}
              </p>
            )}
            {props.selectState !== "userchat" &&
              props.selectState !== "Exchange" && (
                <p
                  className={
                    props.selectState !== props.menuName
                      ? "text-p sidebar_anim"
                      : "text-p sidebar_animblue"
                  }
                >
                  {props.popupTitle}
                </p>
              )}{" "}
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default SidebarTooltip;
