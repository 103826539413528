import React, { useEffect, useState } from "react";
import Button from "components/Button";
import SecondryButton from "components/Button/SecondryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BTC,
  ETH,
  Tether,
  Cardano,
  LTC,
  XRP,
  SOL,
  BAT,
  EURO,
  USD,
  ArrowDownIcon,
  WarningIcon,
  DeleteIcon,
} from "assets";
import { changeDroidStatus } from "features/Management/managementAction";
import moment from "moment";
import { getAllDroids } from "features/Droids/droidsAction";

const DroidPopupModuleDetail = ({ droid, compare, buyStatus }) => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const [droidurl, setdroidurl] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [viewFullList, setViewFullList] = useState(false);
  const { currencyData, takeProfitTypeData } = useSelector(
    (state) => state?.defaultState
  );
  useEffect(() => {
    if (droid?.typeCode === "1") {
      setdroidurl("simplemodedroid");
    } else if (droid?.typeCode === "2") {
      setdroidurl("timemodedroid");
    } else if (droid?.typeCode === "3") {
      setdroidurl("costmodedroid");
    } else if (droid?.typeCode === "4") {
      setdroidurl("indexmodedroid");
    } else if (droid?.typeCode === "5") {
      setdroidurl("safemodedroid");
    }
  }, [droid]);
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(`/management/droids/${droidurl}/${droid?._id}`, {
      state: {
        Duplicate: "edit",
        type: droid?.type,
        droidType: { nav: droidurl },
      },
    });
  };
  const handleDuplicate = () => {
    navigate(`/management/droids/${droidurl}/${droid?._id}`, {
      state: {
        Duplicate: "Duplicate",
        type: droid?.type,
        droidType: { nav: droidurl },
      },
    });
  };

  const DeleteDroidClick = (id) => {
    localStorage.setItem("droidDeleted", true);
    localStorage.setItem("deletedDroidId", JSON.stringify(id));
    const payload = {
      identifier: "delete",
      droidIds: [id],
    };

    dispatch(changeDroidStatus({ userToken, payload })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setDelete(!getDelete);
        dispatch(getAllDroids({ userToken })).then((res) => {});
      } else {
        setDelete(!getDelete);
      }
    });
  };
  const [getDelete, setDelete] = useState(false);
  const deleteClick = () => {
    if (getDelete) {
      setDelete(!getDelete);
    }
  };
  const coinData = [
    {
      coin: "BTC",
      image: <BTC text={"#F7931A"} wd={"30"} ht={"30"} />,
      label: "BTC",
    },
    {
      coin: "ETH",
      image: <ETH text={"#393939"} wd={"30"} ht={"30"} />,
      label: "ETH",
    },
    {
      coin: "XRP",
      image: <XRP text={"#000000"} wd={"30"} ht={"30"} />,
      label: "XRP",
    },
    {
      coin: "LTC",
      image: <LTC text2={"#F8F9FB"} text={"#D9D9D9"} wd={"30"} ht={"30"} />,
      label: "LTC",
    },
    {
      coin: "SOL",
      image: (
        <SOL text={"url(#paint0_linear_1601_37368)"} wd={"30"} ht={"30"} />
      ),
      label: "SOL",
    },
    {
      coin: "USDT",
      image: <Tether text={"#50AF95"} wd={"30"} ht={"30"} />,
      label: "USDT",
    },
    {
      coin: "ADA",
      image: <Cardano text={"#003AC4"} wd={"15"} ht={"15"} bg={"#F8F9FB"} />,
      label: "ADA",
    },
    {
      coin: "BCH",
      image: <Cardano text={"#003AC4"} wd={"15"} ht={"15"} bg={"#F8F9FB"} />,
      label: "BCH",
    },
    {
      coin: "ZEC",
      image: <Cardano text={"#003AC4"} wd={"15"} ht={"15"} bg={"#F8F9FB"} />,
      label: "ZEC",
    },
    {
      coin: "BAT",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <BAT
            text={"url(#paint0_linear_1601_37368)"}
            wd={"15"}
            ht={"15"}
            className="mb-5"
          />
        </div>
      ),
      label: "BAT",
    },
    {
      coin: "EUR",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <EURO wd={"20"} ht={"20"} />
        </div>
      ),
      label: "EUR",
    },
    {
      coin: "GBP",
      image: <XRP text={"#000000"} wd={"30"} ht={"30"} />,
      label: "GBP",
    },
    {
      coin: "LINK",
      image: <XRP text={"#000000"} wd={"30"} ht={"30"} />,
      label: "LINK",
    },
    {
      coin: "USD",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <USD wd={"15"} ht={"15"} />
        </div>
      ),
      label: "USD",
    },
    {
      coin: "USDC",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <USD wd={"15"} ht={"15"} />
        </div>
      ),
      label: "USDC",
    },
  ];

  return (
    <div className="droidPopupContainer px-3">
      <div className="d-flex justify-content-between align-items-center pt-4 px-3 pb-1">
        {getDelete ? (
          <h3 className="pt-2">Delete Droid</h3>
        ) : (
          <div className="d-flex flex-row gap-2 align-items-center">
            <div
              className={`active-droid bg-gradient ${
                droid?.status === "deactive"
                  ? "bg-secondary"
                  : droid?.status === "active"
                  ? "bg-success"
                  : "bg-danger"
              }`}
            ></div>
            <div className="icon-Container ">
              {" "}
              <img
                src={require(`assets/droid67Icon.svg`).default}
                alt=""
                loading="lazy"
              />{" "}
            </div>
            <h3> {droid?.name}</h3>
          </div>
        )}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={deleteClick}
        />
      </div>
      {getDelete ? (
        <div className="mt-4 py-2">
          <div className="d-flex gap-3 align-items-center justify-content-center">
            <WarningIcon />
            <div className="delete_text">
              Do you want to delete{" "}
              <span className={`delete_text`}>
                {" "}
                {droid?.status === "active" ? "Active" : "InActive"}
              </span>{" "}
              status droid{" "}
              <span className={`delete_text font-italic`}>
                {" "}
                {droid?.name} ?{" "}
              </span>{" "}
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center mt-3 ms-6 ps-5"></div>
          <div className="mb-3 d-flex gap-3 justify-content-end pt-2 px-3">
            <SecondryButton text="Cancel" handleClick={deleteClick} />
            <Button
              text="Delete"
              data-bs-dismiss="modal"
              aria-label="Close"
              handleClick={() => DeleteDroidClick(droid?._id)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={`modalContainer ${compare ? "pb-3" : ""}`}>
            <div className="d-grid gap-2">
              <h4 className="d-flex gap-1 my-3">
                {" "}
                Portfolios{" "}
                <h4 className="h4-Three">
                  {droid?.porfolioDetails?.length !== 0
                    ? droid?.porfolioDetails?.length
                    : "N/A"}
                </h4>
              </h4>
              {localStorage.getItem("role") === "broker" ? (
                <>
                  {droid?.clientProfitLoss ? (
                    <div className="gap-5 d-flex align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={require(`assets/uptrend.svg`).default}
                          alt="Downtrend"
                          loading="lazy"
                        />
                        <h6>
                          {droid.clientProfitLoss.clientLossCount || "0"}{" "}
                          clients
                        </h6>
                        <h6 className="greenLabel">
                          +{droid.clientProfitLoss.avgClientLoss || "0.00"}%
                        </h6>
                      </div>
                      <div className="d-flex align-items-center gap-2 ms-4">
                        <img
                          src={require(`assets/downtrend.svg`).default}
                          alt="uptrend"
                          loading="lazy"
                        />
                        <h6>
                          {droid.clientProfitLoss.clientProfitCount || "0"}{" "}
                          clients
                        </h6>
                        <h6 className="redLabel">
                          -{droid.clientProfitLoss.avgClientLoss || "0.00"}%
                        </h6>
                      </div>
                    </div>
                  ) : (
                    "Client data not found"
                  )}
                  {droid?.porfolioDetails?.length > 0 ? (
                    <>
                      {" "}
                      <h6
                        className="my-3 d-flex align-items-center gap-2 cursor"
                        onClick={() => setViewFullList(!viewFullList)}
                      >
                        <img
                          src={require(`assets/threeLines.svg`).default}
                          alt=""
                          loading="lazy"
                        />
                        {viewFullList ? "Hide full list" : "View full list"}
                      </h6>
                      <>
                        {viewFullList && (
                          <>
                            {droid?.porfolioDetails?.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div
                                    className="d-flex align-items-center justify-content-between portfoliyo_table_background"
                                    data-toggle="collapse"
                                    href={`#collapseExample${index}`}
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <img
                                        src={
                                          item?.profileImage
                                            ? item?.profileImage
                                            : require(`assets/commonAvtar.svg`)
                                                .default
                                        }
                                        alt=""
                                        className={`${
                                          item?.profileImage
                                            ? "droidClientsPicture rounded-circle"
                                            : "w-10 rounded-circle"
                                        }`}
                                        loading="lazy"
                                      />
                                      <div className="cursor h6-One">
                                        {" "}
                                        {item?.name}{" "}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <ArrowDownIcon
                                        color="#000"
                                        height="30"
                                        width={30}
                                      />
                                    </div>
                                  </div>
                                  {item?.porfolioDetails?.map(
                                    (portItem, pIndex) => (
                                      <div
                                        key={pIndex}
                                        className="collapse show1"
                                        id={`collapseExample${index}`}
                                      >
                                        <table className="table table-bordered portfoliyo_table">
                                          <tbody>
                                            <tr className="">
                                              <td>{portItem?.name}</td>
                                              <td>
                                                ${portItem?.balance?.toFixed(2)}
                                              </td>
                                              <td className="mx-2">
                                                {portItem?.risk === null
                                                  ? "No"
                                                  : portItem?.risk}{" "}
                                                Risk
                                              </td>
                                              <td
                                                className="gap-2 d-flex justify-content-center"
                                                onMouseEnter={() =>
                                                  setShowTooltip(true)
                                                }
                                                onMouseLeave={() =>
                                                  setShowTooltip(false)
                                                }
                                              >
                                                {portItem?.coins
                                                  ?.slice(0, 4)
                                                  ?.map((item, index) => {
                                                    const matchingCoin =
                                                      coinData.find(
                                                        (coin) =>
                                                          coin.coin === item
                                                      );

                                                    return (
                                                      <>
                                                        {matchingCoin ? (
                                                          matchingCoin.image
                                                        ) : (
                                                          <img
                                                            className="unknownCoin mt-2"
                                                            src={
                                                              require("assets/unknown.svg")
                                                                .default
                                                            }
                                                            alt="unknown coin"
                                                            loading="lazy"
                                                          />
                                                        )}
                                                      </>
                                                    );
                                                  })}

                                                {portItem?.coins?.length >
                                                  4 && (
                                                  <h5 className="d-flex mt-1">
                                                    {portItem?.coins?.length > 4
                                                      ? "+" +
                                                        (portItem?.coins
                                                          ?.length -
                                                          4)
                                                      : ""}
                                                  </h5>
                                                )}
                                                {showTooltip && (
                                                  <div className="customTooltip customTooltipConsition row bg-white z-index-999 justify-content-center">
                                                    {portItem?.coins?.map(
                                                      (item, index) => {
                                                        const matchingCoin =
                                                          coinData.find(
                                                            (coin) =>
                                                              coin.coin === item
                                                          );
                                                        return (
                                                          <div
                                                            key={index}
                                                            className="col-lg-3"
                                                          >
                                                            {matchingCoin && (
                                                              <div
                                                                key={
                                                                  matchingCoin.coin
                                                                }
                                                              >
                                                                <div className="">
                                                                  {
                                                                    matchingCoin.image
                                                                  }
                                                                  <div>
                                                                    {
                                                                      matchingCoin.label
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                            {!matchingCoin && (
                                                              <img
                                                                className="unknownCoin mt-2"
                                                                src={
                                                                  require("assets/unknown.svg")
                                                                    .default
                                                                }
                                                                alt="unknown coin"
                                                                loading="lazy"
                                                              />
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    )
                                  )}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div className="d-flex align-items-center gap-3 flex-wrap">
                  {droid?.porfolioDetails?.length > 0
                    ? droid?.porfolioDetails.map((item, index) => (
                        <div
                          className="d-flex align-items-center gap-2"
                          key={index}
                        >
                          {item?.profitLoss < 0 ? (
                            <img
                              src={require(`assets/downtrend.svg`).default}
                              alt="uptrend"
                              loading="lazy"
                            />
                          ) : (
                            <img
                              src={require(`assets/uptrend.svg`).default}
                              alt="Downtrend"
                              loading="lazy"
                            />
                          )}
                          <h6>{item?.name}</h6>
                          <h6>{item?.risk} Risk</h6>
                          <h6
                            className={`d-flex align-items-center ${
                              item?.profitLoss < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {item?.profitLoss < 0 ? "-" : "+"}
                            {Math.abs(item?.profitLoss)}%
                          </h6>
                        </div>
                      ))
                    : "No portfolios found."}
                </div>
              )}
            </div>
            <hr />
            {!droid?.coinsList ? (
              ""
            ) : (
              <>
                <h4>Coins</h4>
                <div className="d-flex flex-wrap gap-3 mt-2">
                  {" "}
                  {droid?.coinsList?.map((item, index) => (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={item?.iconUrl}
                        width="20"
                        height="20"
                        alt=""
                        key={index}
                        loading="lazy"
                      />{" "}
                      <div style={{ marginLeft: "5px" }}>{item?.symbol}</div>{" "}
                    </span>
                  ))}{" "}
                </div>
              </>
            )}
            {droid?.typeCode == 1 && (
              <>
                <hr />
                <h5 className="d-flex gap-1">Buy/Sell Settings</h5>
                <div className="d-flex gap-4 mt-2">
                  <div className="text_container">
                    {" "}
                    <p>Buy - Purchase Amount</p>{" "}
                    <h6>
                      {
                        currencyData?.find(
                          (obj) =>
                            obj.name ===
                            droid?.buySellSettings?.buyPurchaseAmountType
                        )?.label
                      }{" "}
                      {droid?.buySellSettings?.buyPurchaseAmount}
                    </h6>{" "}
                  </div>
                  <div className="text_container">
                    {" "}
                    <p>Price - Purchase Value</p>{" "}
                    <h6>
                      {droid?.buySellSettings?.pricePurchaseAmount
                        ? currencyData?.find(
                            (obj) =>
                              obj.name ===
                              droid?.buySellSettings?.pricePurchaseAmountType
                          )?.label
                        : ""}{" "}
                      {droid?.buySellSettings?.pricePurchaseAmount
                        ? droid?.buySellSettings?.pricePurchaseAmount
                        : "Market"}
                    </h6>{" "}
                  </div>
                </div>
              </>
            )}
            {droid?.typeCode == 2 && (
              <>
                <hr />
                <h4 className="d-flex gap-1">Time Settings</h4>
                <table className="table table_border">
                  <tbody>
                    <tr>
                      <th>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Purchase Amount</p>{" "}
                          <h6>
                            {
                              currencyData?.find(
                                (obj) =>
                                  obj.name ===
                                  droid?.timeSettings?.purchaseAmountType
                              )?.label
                            }{" "}
                            {droid?.timeSettings?.purchaseAmount}
                          </h6>{" "}
                        </div>{" "}
                      </th>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Period - Every</p>{" "}
                          <h6>{droid?.timeSettings?.periodEvery} days</h6>{" "}
                        </div>{" "}
                      </td>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Period - At</p>{" "}
                          <h6>
                            {moment(
                              droid?.timeSettings?.periodAt,
                              "HH:mm"
                            ).format("h:mm A")}{" "}
                          </h6>{" "}
                        </div>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Market Type</p>
                          <h6>{"Limit"}</h6>{" "}
                        </div>{" "}
                      </td>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">First Buy Date</p>{" "}
                          <h6>{droid?.timeSettings?.firstBuyDate}</h6>{" "}
                        </div>{" "}
                      </td>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Next Buy Date</p>{" "}
                          <h6>
                            {droid?.timeSettings?.nextBuyDate
                              ? droid?.timeSettings?.nextBuyDate
                              : "--"}
                          </h6>
                        </div>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {droid?.typeCode == 3 && (
              <>
                <hr />
                <h4 className="d-flex gap-1">Strategy</h4>
                <table className="table table_border">
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Profit in Currency</p>{" "}
                          <h6>{droid?.dcaStrategy?.profitInCurrency}</h6>{" "}
                        </div>{" "}
                      </td>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Start Order Type</p>{" "}
                          <h6>{droid?.dcaStrategy?.startOrderType}</h6>{" "}
                        </div>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Base Order Size </p>{" "}
                          <h6>
                            {droid?.dcaStrategy?.baseOrderSizeType ===
                              "dollar" && "$"}{" "}
                            {droid?.dcaStrategy?.baseOrderSizeAmount}{" "}
                            {droid?.dcaStrategy?.baseOrderSizeType ===
                              "percent" && "%"}
                          </h6>{" "}
                        </div>{" "}
                      </td>
                      {droid?.dcaStrategy?.limit && (
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Threshold</p>{" "}
                            <h6>
                              {droid?.dcaStrategy?.limit && "$"}{" "}
                              {droid?.dcaStrategy?.limit}
                            </h6>{" "}
                          </div>{" "}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <hr />
                <h4 className="d-flex gap-1">Stop Loss</h4>
                <table className="table table_border">
                  <tbody>
                    <tr>
                      <th>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Stop Loss</p>{" "}
                          <h6>{droid?.stopLoss?.stopLoss}</h6>{" "}
                        </div>{" "}
                      </th>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Stop Loss Action</p>{" "}
                          <h6>{droid?.stopLoss?.stopLossAction}</h6>{" "}
                        </div>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr />
                <h4 className="d-flex gap-1">SafetyNet Parameters</h4>
                <table className="table table_border">
                  <tbody>
                    <tr>
                      <th>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Order Size</p>{" "}
                          <h6>
                            {droid?.safetyNetParameters?.orderSizeType ===
                            "dollar"
                              ? "$"
                              : ""}{" "}
                            {droid?.safetyNetParameters?.orderSizeAmount}{" "}
                            {droid?.safetyNetParameters?.orderSizeType ===
                            "percent"
                              ? "%"
                              : ""}
                          </h6>{" "}
                        </div>{" "}
                      </th>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Price Deviation</p>{" "}
                          <h6>
                            {droid?.safetyNetParameters?.priceDeviation} %
                          </h6>{" "}
                        </div>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <div className="d-grid gap-1">
                          {" "}
                          <p className="time_head">Max Orders Count</p>{" "}
                          <h6>{droid?.safetyNetParameters?.maxOrderCount}</h6>{" "}
                        </div>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {/* {droid?.typeCode == 4 && (
              <>
                <hr />
                <h4>Rebalance Strategy </h4>
                <div className="d-grid gap-1 my-3">
                  {" "}
                  <p className="time_head">Period</p>{" "}
                  <h6>
                    {droid?.rebalanceStrategy?.timePeriod}{" "}
                    {droid?.rebalanceStrategy?.timePeriodDay}
                  </h6>{" "}
                </div>
                <h4>Balancing</h4>
                <div className="d-grid gap-1 mt-3">
                  {" "}
                  <p className="time_head">Stop Loss Time Period</p>{" "}
                  <h6>{droid?.balancing?.stopLossTimePeriod}</h6>{" "}
                </div>
              </>
            )} */}
            {droid?.typeCode == 5 && (
              <>
                <hr />
                <h4>Safetynet Rules </h4>
                <h6 className="mt-3">{`Price Purchase Value : $${droid?.price}`}</h6>
                <div className="table_Scroll my-3">
                  <table className="table table_border">
                    <tbody className="saftynet_rules_table">
                      {droid?.safetyNetRules?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              droid?.safetyNetRules?.length - 1 === index
                                ? ""
                                : "boarder_bottom"
                            }
                          >
                            <th>{index + 1} </th>
                            <td>
                              Action <span>{item?.action}</span>
                            </td>
                            <td>
                              Buy Purchase Amount{" "}
                              <span>
                                {droid?.thresholdType === "dollar" ? "$" : "%"}
                                {droid?.thresholdAmount}
                              </span>
                            </td>
                            <td>
                              Period <span>{droid?.period}</span>
                            </td>
                            <td>
                              Trend <span>{item?.pricetrend}</span>
                            </td>
                            <td>
                              Price Trend Amount{" "}
                              <span>
                                {
                                  currencyData?.find(
                                    (obj) => obj.name === item?.takeProfitType
                                  )?.label
                                }
                                {item?.priceTrendAmount}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <hr />
            <h4 className="d-flex gap-1">Take Profit</h4>
            <div className="d-flex gap-3 mt-2">
              <div className="text_container">
                {" "}
                <p>Target Profit</p>{" "}
                <h6 className="d-flex gap-1">
                  {droid?.takeProfit?.takeProfitType === "value" ? "$" : ""}{" "}
                  {droid?.takeProfit?.targetProfitPercent}{" "}
                  {droid?.takeProfit?.takeProfitType === "value" ? "" : "%"}{" "}
                </h6>{" "}
              </div>
              <div className="text_container">
                {" "}
                <p>Take Profit Type</p>{" "}
                <h6>
                  {
                    takeProfitTypeData?.find(
                      (obj) => obj.name === droid?.takeProfit?.takeProfitType
                    )?.label
                  }
                </h6>{" "}
              </div>
              {droid?.typeCode == "1" ||
              droid?.typeCode == "2" ||
              droid?.typeCode == "3" ? (
                <div className="text_container">
                  {" "}
                  <p>Trailing Deviation</p>{" "}
                  <h6>
                    {droid?.takeProfit?.trailingDeviation == ""
                      ? 0
                      : droid?.takeProfit?.trailingDeviation}{" "}
                    {
                      currencyData?.find(
                        (obj) =>
                          obj.name === droid?.takeProfit?.targetProfitType
                      )?.label
                    }
                  </h6>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />
            {droid && droid?.typeCode == "4" && (
              <>
                <h4 className="mb-2 mt-2">Coins Data</h4>
                <div className="table_Scroll">
                  <table className="table deal-rules mt-3 ">
                    <tbody>
                      {droid?.coinsList?.map((item, index) => {
                        let formattedBuyThreshold = parseFloat(
                          item?.price || "0"
                        ).toFixed(2);
                        let formattedSellThreshold = parseFloat(
                          item?.marketPrice || "0"
                        ).toFixed(2);

                        return (
                          <tr key={index}>
                            <td scope="row ">
                              <h6 className="p-Three MT-1">{index + 1}</h6>
                            </td>
                            <td className="">
                              <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                Coin Name
                                <h6 className="p-Three-Black">
                                  {" "}
                                  {item?.name}
                                </h6>{" "}
                              </h6>
                            </td>
                            <td className="">
                              <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                Coin Percent
                                <h6 className="p-Three-Black">
                                  {" "}
                                  {item?.value}%
                                </h6>{" "}
                              </h6>
                            </td>
                            <td className="">
                              <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                Buy Purchase Amount
                                <h6 className="p-Three-Black">
                                  {" "}
                                  ${formattedBuyThreshold}
                                </h6>{" "}
                              </h6>
                            </td>
                            <td>
                              <h6 className="d-flex p-Three gap-1 MT-2">
                                Buy Price
                                <h6 className="p-Three-Black">
                                  {" "}
                                  ${formattedSellThreshold}
                                </h6>{" "}
                              </h6>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {droid?.typeCode == "5" || droid?.typeCode == "4" ? (
              ""
            ) : (
              <>
                <h4 className="mb-2 mt-2">Deal Rules</h4>
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-grid gap-2 text_container">
                    {" "}
                    <p>Repeat</p>{" "}
                    <h6>
                      {droid?.dealRules?.repeat
                        ? droid?.dealRules?.repeat
                        : "none"}
                    </h6>{" "}
                  </div>
                  <div className="d-grid gap-2 text_container">
                    {" "}
                    <p>Cooldown</p>{" "}
                    <h6>
                      {droid?.dealRules?.cooldownseconds}{" "}
                      {droid?.dealRules?.cooldownseconds ? "Seconds" : "--"}
                    </h6>{" "}
                  </div>
                </div>

                {droid?.dealRules?.list?.length > 0 ? (
                  <div className="table_Scroll">
                    <table className="table deal-rules mt-3 ">
                      <tbody>
                        {droid?.dealRules?.list?.map((item, index) => {
                          let formattedBuyThreshold = parseFloat(
                            item?.buyThreshold || "0"
                          ).toFixed(2);
                          let formattedSellThreshold = parseFloat(
                            item?.sellThreshold || "0"
                          ).toFixed(2);

                          return (
                            <tr key={index}>
                              <td scope="row ">
                                <h6 className="p-Three MT-1">{index + 1}</h6>
                              </td>
                              <td>{item?.takeProfitType}</td>
                              <td className="">
                                <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                  Buy Threshold
                                  <h6 className="p-Three-Black">
                                    {" "}
                                    ${formattedBuyThreshold}
                                  </h6>{" "}
                                </h6>
                              </td>
                              <td>
                                <h6 className="d-flex p-Three gap-1 MT-2">
                                  Sell Threshold
                                  <h6 className="p-Three-Black">
                                    {" "}
                                    ${formattedSellThreshold}
                                  </h6>{" "}
                                </h6>
                              </td>
                              {droid?.typeCode == 1 ? (
                                <td className="">
                                  <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                    Price Purchase
                                    <h6 className="p-Three-Black">
                                      {" "}
                                      $
                                      {Number(
                                        item?.pricePurchaseValue
                                      )?.toFixed(2)}
                                    </h6>{" "}
                                  </h6>
                                </td>
                              ) : (
                                ""
                              )}
                              {droid?.typeCode == 1 ? (
                                <td>
                                  <h6 className="d-flex p-Three gap-1 MT-2">
                                    Sell Purchase
                                    <h6 className="p-Three-Black">
                                      {" "}
                                      $
                                      {Number(item?.sellPurchaseValue)?.toFixed(
                                        2
                                      )}
                                    </h6>{" "}
                                  </h6>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>

          {compare ? (
            ""
          ) : (
            <div className="justify-content-end d-flex gap-3 my-3">
              {droid?.createdByID !== localStorage.getItem("userID") ? (
                ""
              ) : (
                <SecondryButton
                  text="Delete"
                  disabled={buyStatus === "Buy" ? true : false}
                  handleClick={() => setDelete(!getDelete)}
                  icon={
                    buyStatus === "Buy" ? (
                      <DeleteIcon color={"#c2c2c2"} />
                    ) : (
                      <DeleteIcon />
                    )
                  }
                  className={"align-items-center"}
                />
              )}
              <Button
                text="Duplicate"
                handleClick={handleDuplicate}
                data-bs-dismiss="modal"
                aria-label="Close"
                icon={
                  <img
                    src={require(`assets/duplicateIcon.svg`).default}
                    alt=""
                    loading="lazy"
                  />
                }
              />
              {/* <Button text={localStorage.getItem('editDroid') === droid?._id && droid?.typeCode === '1' ? 'View' : 'Edit'}
              handleClick={handleEdit}
              data-bs-dismiss="modal" aria-label="Close" icon={<img src={require(`assets/editIconInButton.svg`).default} alt="" loading='lazy'/>} /> */}
              {/* <Button text='View'
              handleClick={handleEdit}
              data-bs-dismiss="modal" aria-label="Close" icon={<img src={require(`assets/editIconInButton.svg`).default} alt="" loading='lazy'/>} /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DroidPopupModuleDetail;
