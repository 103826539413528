import { createAsyncThunk } from "@reduxjs/toolkit";
import ErrorCode from "ErrorCode";

export const DroidsData = createAsyncThunk(
  "droids/getAllDroidsState",
  async ({ payload }, { rejectWithValue }) => {
    try {
      if (payload.hasOwnProperty("error")) {
        return rejectWithValue(payload.message);
      } else {
        return await payload;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
