import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DroidModule from "../DroidModule";
import SimpleModeDroidSetting from "../DroidModules/SimpleModeDroidSetting";
import { resetDroid } from "features/Droids/droidsSlice";
import {
  postAddDroid,
  getAllDroidsById,
  updatedroidsById,
  filterClientDroid,
  checkOrderStatusDroid,
} from "features/Droids/droidsAction";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import MultiSelectDropDown from "components/Dropdown/MultiSelectDropDown";
import SecondryButton from "components/Button/SecondryButton";
import { getPorfolioByUserId } from "features/Management/managementAction";
import MultilevelDropDown from "components/Dropdown/MultilevelDropDown";
import DroidPortfoliosRetailer from "components/Slider/DroidPortfoliosRetailer";
import { DroidsData } from "features/DroidState/DroidsStateAction";
import socketIO from "socket.io-client";
import moment from "moment";
import { toast } from "react-toastify";
import { updatePortfolio } from "features/CoinList/CoinListAction";
import ErrorCode from "../../../../ErrorCode";
import Loader from "components/Loader";
import { USD } from "assets";

let socket;
const toastConfiguration = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
};

const AddDroidSetting = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const droidType = state;
  const Duplicate = state;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { riskLevelDataPortfoliyo, balanceDataPortfoliyo } = useSelector(
    (state) => state?.defaultState
  );
  const { userToken } = useSelector((state) => state.auth);
  const { allConnectedPortfoliosData } = useSelector(
    (state) => state?.management
  );
  const [coin, setCoin] = useState([]);
  let path = location?.pathname.split("/");
  const [getLoadingData, setLoadingData] = useState(false);
  const [getLoadingPortfolios, setLoadingPortfolios] = useState(false);
  const [getLoding, setLoading] = useState(false);
  const [getCoinListData, setCoinListData] = useState();
  const [getClientPortfolios, setClientPortfolios] = useState([]);
  const [getDefaultRule, setDefaultRule] = useState({});
  const [getBrokerExchange, setBrokerExchange] = useState();
  const [filterPortfoliosIds, setFilterPortfoliosIds] = useState([]);
  const [getbidValue, setbidValue] = useState("");
  const [getSingleCoinData, setSingleCoinData] = useState({});
  const [selectProtfoliyoData, setSelectProtfoliyoData] = useState(false);
  const [getselectedPortfoliosBalance, setselectedPortfoliosBalance] = useState(
    []
  );
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const [getPortfoliosData, setPortfoliosData] = useState(
    allConnectedPortfoliosData || []
  );
  const [getPortfoliosDataLoading, setPortfoliosDataLoading] = useState(false);
  const [getBuySellStatus, setBuySellStatus] = useState([]);
  const [getPortfolios, setPortfolios] = useState({
    coins: coin,
    funds: "",
    risk: "",
  });
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const [getOrderStatus, setOrderStatus] = useState([]);
  const [getError, setError] = useState({ name: "", error: "" });
  const dcaSafetyNetParameters = useSelector((state) => state?.errorDroidSlice);
  const valueOfIndexWarning = useSelector((state) => state?.errorDroidIndex);
  const valueOfSimpleWarning = useSelector((state) => state?.errorDroidSimple);
  const [ruleListError, setruleListError] = useState(false);
  const valueOfTimeWarning = useSelector((state) => state?.errorDroidTime);
  let exchangeNameSocket =
    localStorage.getItem("role") === "broker"
      ? getBrokerExchange
      : !selectedPortfolios
      ? ""
      : selectedPortfolios[0]?.exchangeName;
  let coinNameSocket =
    state?.droidType?.nav === "indexmodedroid"
      ? getSingleCoinData?.name
      : !getDroidsDataInfo?.coinsList
      ? ""
      : getDroidsDataInfo?.coinsList[getDroidsDataInfo?.coinsList?.length - 1]
          ?.name;

  useEffect(() => {
    let coinDataset = [];
    coin?.map((item) => {
      coinDataset.push({ iconUrl: item?.iconUrl, symbol: item?.symbol });
    });
    setPortfolios({ ...getPortfolios, coins: coinDataset });
  }, [coin]);

  // Call Socket
  useEffect(() => {
    socket = socketIO(process.env.REACT_APP_SOCKET, {
      transports: ["websocket"],
    });
    socket?.emit("setup", localStorage?.getItem("userID"));
    socket?.on("bidValue", ({ bidValue, error }) => {
      if (!error) {
        setbidValue(bidValue);
      }
    });
    socket?.on("coinList", ({ coinList, error }) => {
      if (!error) {
        setCoinListData(coinList);
        setbidValue("");
      }
    });
    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    socket?.emit("getCoinList", {
      exchangeName: exchangeNameSocket,
      userId: localStorage.getItem("userID"),
    });
    setbidValue("");
  }, [exchangeNameSocket, selectedPortfolios]);

  const [getCount, setCount] = useState(0);

  useEffect(() => {
    if (state?.Duplicate === "edit") {
      setTimeout(setCount(1), 1000);
    }
    if (socket?.connected && !!coinNameSocket && !!exchangeNameSocket) {
      socket?.emit("subscribeToBid", {
        exchangeName: exchangeNameSocket,
        symbol: coinNameSocket,
        userId: localStorage.getItem("userID"),
      });
      setbidValue("");
      // if (state?.Duplicate === "edit" || state?.Duplicate === "Duplicate") {
      //   setTimeout(socket?.emit('subscribeToBid', { exchangeName: exchangeNameSocket, symbol: coinNameSocket, userId: localStorage.getItem('userID') }), 1000)
      //   setbidValue('')
      // } else {
      //   socket?.emit('subscribeToBid', { exchangeName: exchangeNameSocket, symbol: coinNameSocket, userId: localStorage.getItem('userID') });
      //   setbidValue('')
      // }
    }
  }, [exchangeNameSocket, getCount, coinNameSocket]);

  //   // // Call Porfoliyo API
  useEffect(() => {
    setPortfoliosDataLoading(true);
    dispatch(updatePortfolio({ userToken })).then(() => {
      setTimeout(async () => {
        await dispatch(getPorfolioByUserId({ userToken }))
          .then((res) => {
            setPortfoliosData(res?.payload);
            setPortfoliosDataLoading(false);
          })
          .catch(() => {
            toast.error("Some error occured while fetching data.", {
              toastId: 1,
              ...toastConfiguration,
            });
          });
      }, 1500);
    });
  }, []);
  // Create Droid and Dublicate Droid
  const handleSubmit = () => {
    setruleListError(false);
    setLoading(true);
    let payload = JSON.parse(JSON.stringify(getDroidsDataInfo));
    if (
      droidType?.droidType?.nav === "timemodedroid" ||
      droidType?.droidType?.nav === "simplemodedroid" ||
      droidType?.droidType?.nav === "costmodedroid"
    ) {
      const filterList = getDroidsDataInfo?.dealRules?.list?.slice(1);
      payload = {
        ...payload,
        dealRules: {
          ...payload?.dealRules,
          list:
            state?.Duplicate === "Duplicate"
              ? [getDefaultRule, ...filterList]
              : [getDefaultRule, ...getDroidsDataInfo?.dealRules?.list],
        },
      };
      if (droidType?.droidType?.nav === "timemodedroid") {
        payload.timezone = timeZone;
      }
    }

    if (state?.Duplicate === "Duplicate") {
      delete payload?.porfolioDetails;
      const data111 = payload?.dealRules?.list?.map((e, i) => ({
        buyThreshold: e?.buyThreshold,
        pricePurchaseValue: e?.pricePurchaseValue,
        sellPurchaseValue: e?.sellPurchaseValue,
        sellThreshold: e?.sellPurchaseValue,
        takeProfitType: e?.takeProfitType,
      }));
      payload = {
        ...payload,
        dealRules: {
          ...payload?.dealRules,
          list: data111,
        },
      };
    }

    // if ((droidType?.droidType?.nav === "simplemodedroid" && payload?.buySellSettings?.startOrderType === 'market')
    // || (droidType?.droidType?.nav === "costmodedroid" && payload?.dcaStrategy?.startOrderType === 'market')) {
    //   delete payload?.dealRules
    // }
    if (
      state?.droidType?.nav === "simplemodedroid" &&
      payload?.buySellSettings?.startOrderType === "market"
    ) {
      if (getDroidsDataInfo?.takeProfit?.takeProfitType === "base") {
        let listObj = {};
        listObj["buyThreshold"] = JSON.stringify(getbidValue.bid);
        listObj["pricePurchaseValue"] =
          getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount;
        listObj["sellPurchaseValue"] = (
          +getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount *
          (1 + +getDroidsDataInfo?.takeProfit?.targetProfitPercent / 100)
        )
          .toFixed(2)
          .toString();
        listObj["sellThreshold"] = (
          getbidValue.bid *
          (1 + +getDroidsDataInfo?.takeProfit?.targetProfitPercent / 100)
        )
          .toFixed(2)
          .toString();
        listObj["takeProfitType"] = "Immediate";
        let newDealRules = {
          cooldownseconds: "",
          list: [listObj],
          repeat: "",
        };

        payload = { ...payload, dealRules: newDealRules };
      }

      if (
        state?.droidType?.nav === "simplemodedroid" &&
        getDroidsDataInfo?.takeProfit?.takeProfitType === "value"
      ) {
        let percentageProfit =
          ((+getDroidsDataInfo?.takeProfit?.targetProfitPercent -
            +getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount) *
            100) /
          2;
        let listObj = {};
        listObj["buyThreshold"] = JSON.stringify(getbidValue.bid);
        listObj["pricePurchaseValue"] =
          getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount;
        listObj["sellPurchaseValue"] =
          getDroidsDataInfo?.takeProfit?.targetProfitPercent;
        listObj["sellThreshold"] = (
          getbidValue.bid *
          (1 + percentageProfit / 100)
        ).toString();
        listObj["takeProfitType"] = "Immediate";
        let newDealRules = {
          cooldownseconds: "",
          list: [listObj],
          repeat: "",
        };

        payload = { ...payload, dealRules: newDealRules };
      }
    }

    if (payload?.type === "Time Mode") {
      const currentDate = new Date();
      const currentDateTime = currentDate.getTime(); // Current time in milliseconds since Unix epoch

      const selectedDateStr = payload?.timeSettings?.firstBuyDate; // Format: DD/MM/YYYY
      const selectedTimeStr = payload?.timeSettings?.periodAt; // Format: HH:mm

      const [day, month, year] = selectedDateStr.split("/").map(Number);
      const [hours, minutes] = selectedTimeStr.split(":").map(Number);

      const selectedDateTime = new Date(
        year,
        month - 1,
        day,
        hours,
        minutes
      ).getTime(); // Selected time in milliseconds since Unix epoch

      if (selectedDateTime < currentDateTime) {
        toast.error("Please enter a time greater than current time.");
        localStorage.setItem("timeLessThanCurrent", true);
        setLoading(false);
      } else {
        localStorage.setItem("timeLessThanCurrent", false);
        dispatch(postAddDroid({ userToken, payload }))
          .then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
              dispatch(resetDroid());
              navigate("/management/droids");
              setLoading(false);
              dispatch(updatePortfolio({ userToken }));
            } else {
              setLoading(false);
            }
          })
          .catch(() => {
            toast.error("Some error occured while adding droid.", {
              toastId: 1,
              ...toastConfiguration,
            });
          });
      }
    } else if (payload?.type === "Simple Mode") {
      if (payload?.dealRules?.repeat > 1) {
        let repeatRules = Number(payload?.dealRules?.repeat);
        let providedRepeatList = payload?.dealRules?.list?.length;
        let balance =
          localStorage.getItem("role") === "broker"
            ? getselectedPortfoliosBalance
            : selectedPortfolios;
        let maxValue = 0;
        balance.forEach((portfolio) => {
          if (maxValue === 0) {
            maxValue = portfolio.balance;
          } else if (portfolio.balance <= maxValue) {
            maxValue = portfolio.balance;
          }
        });
        let usedBalance = 0;
        payload?.dealRules?.list?.map((i) => {
          usedBalance += Number(i.pricePurchaseValue);
        });

        let total = maxValue - usedBalance;
        if (total < 0) {
          toast.warning("You are using more than the portfolio balance.");
          setLoading(false);
          return;
        }

        if (repeatRules === providedRepeatList) {
          setruleListError(false);
          dispatch(postAddDroid({ userToken, payload }))
            .then((res) => {
              if (res.meta && res.meta.requestStatus === "fulfilled") {
                dispatch(resetDroid());
                navigate("/management/droids");
                setLoading(false);
                dispatch(updatePortfolio({ userToken }));
              } else {
                setLoading(false);
              }
            })
            .catch(() => {
              toast.error("Some error occured while adding droid.", {
                toastId: 1,
                ...toastConfiguration,
              });
            });
        } else {
          setruleListError(true);
          toast.warning(`Please provide atleast ${repeatRules} Rules List`);
          setLoading(false);
          return;
        }
      } else {
        setruleListError(false);
        dispatch(postAddDroid({ userToken, payload }))
          .then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
              dispatch(resetDroid());
              navigate("/management/droids");
              setLoading(false);
              dispatch(updatePortfolio({ userToken }));
            } else {
              setLoading(false);
            }
          })
          .catch(() => {
            toast.error("Some error occured while adding droid", {
              toastId: 1,
              ...toastConfiguration,
            });
          });
      }
    } else if (payload.type === "Safe Mode") {
      delete payload.priceTrendAmount;
      localStorage.setItem("timeLessThanCurrent", false);
      dispatch(postAddDroid({ userToken, payload }))
        .then((res) => {
          if (res.meta && res.meta.requestStatus === "fulfilled") {
            dispatch(resetDroid());
            navigate("/management/droids");
            setLoading(false);
            dispatch(updatePortfolio({ userToken }));
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          toast.error("Some error occured while adding droid", {
            toastId: 1,
            ...toastConfiguration,
          });
        });
    } else {
      // if (
      //   payload.type === "Cost Mode" &&
      //   payload?.safetyNetParameters?.priceDeviation === 10
      // ) {
      //   payload = {
      //     ...payload,
      //     safetyNetParameters: {
      //       ...payload.safetyNetParameters,
      //       priceDeviation: 0.01,
      //     },
      //   };
      // }
      // if (
      //   payload.type === "Cost Mode" &&
      //   payload?.safetyNetParameters?.priceDeviation === 9
      // ) {
      //   payload = {
      //     ...payload,
      //     safetyNetParameters: {
      //       ...payload.safetyNetParameters,
      //       priceDeviation: 0.02,
      //     },
      //   };
      // }

      /**********************************************************/
      // temporary code untill dca droid changes
      if (
        payload.type === "Cost Mode" &&
        payload?.takeProfit?.takeProfitType === "value"
      ) {
        payload = {
          ...payload,
          takeProfit: {
            ...payload.takeProfit,
            targetProfitPercent: payload.newProfit?.toFixed(2),
            takeProfitType: "base",
          },
        };

        delete payload.newProfit;
      }
      /**************************************************************/

      localStorage.setItem("timeLessThanCurrent", false);
      dispatch(postAddDroid({ userToken, payload }))
        .then((res) => {
          if (res.meta && res.meta.requestStatus === "fulfilled") {
            dispatch(resetDroid());
            navigate("/management/droids");
            setLoading(false);
            dispatch(updatePortfolio({ userToken }));
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          toast.error("Some error occured while adding droid", {
            toastId: 1,
            ...toastConfiguration,
          });
        });
    }
  };

  // // Update Droid by ID
  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    let id = path[4];
    let payload = {
      ...getDroidsDataInfo,
      dealRules: {
        ...getDroidsDataInfo?.dealRules,
        list: [getDefaultRule]?.concat(
          getDroidsDataInfo?.dealRules?.list?.slice(1)
        ),
      },
    };

    // Simple mode Edit remove buyOrderDetails and replace only _id in buyOrderDetails //
    if (
      state?.droidType?.nav === "simplemodedroid" &&
      state?.Duplicate === "edit"
    ) {
      const dd = JSON.parse(JSON.stringify(payload));
      payload?.dealRules.list?.map((item, index) => {
        const dddd = item?.buyOrderDetails?.map((e, i) => e?._id);
        dd.dealRules.list[index].buyOrderDetails = dddd;
      });
      payload = { ...dd };
    }

    // update Call API Edit Droid
    dispatch(updatedroidsById({ userToken, id, payload })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        navigate("/management/droids");

        localStorage.setItem("editDroid", id);
        setLoading(false);
        dispatch(updatePortfolio({ userToken }));
      } else {
        setLoading(false);
      }
    });
  };

  //   // // call API when Edit Droid and Dublicate Droid

  useEffect(() => {
    if (path?.length > 4) {
      let id = path[path?.length - 1];
      let ordersList = [];
      setLoadingData(true);
      if (id) {
        dispatch(getAllDroidsById({ userToken, id }))
          .then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
              setLoadingData(false);

              for (let x = 0; x < res?.payload.dealRules.list?.length; x++) {
                let buyOrderDetails =
                  res?.payload.dealRules.list[x].buyOrderDetails;
                buyOrderDetails.map((item) => ordersList?.push(item._id));
              }
              dispatch(checkOrderStatusDroid({ userToken, ordersList }))
                .then((responce) => {
                  if (
                    responce.meta &&
                    responce.meta.requestStatus === "fulfilled"
                  ) {
                    setBuySellStatus(responce?.payload);
                    res?.payload?.dealRules?.list?.forEach((rule) => {
                      rule?.buyOrderDetails?.forEach((orderDetail) => {
                        setOrderStatus(responce?.payload);
                      });
                    });
                  }
                })
                .catch(() => {
                  toast.error("Some error occured while fetching data.", {
                    toastId: 1,
                    ...toastConfiguration,
                  });
                });

              const portfolioIdValues = res?.payload?.portfoliosIds?.map(
                (item) => item.portfolioId
              );

              setFilterPortfoliosIds(portfolioIdValues);
              const action =
                state?.type === "Safe Mode"
                  ? { action: res?.payload?.safetyNetRules[0]?.action }
                  : {};
              const payload = {
                ...action,
                ...res?.payload,
                name:
                  state?.Duplicate === "Duplicate"
                    ? res?.payload?.name + " Copy"
                    : res?.payload?.name,
              };
              dispatch(DroidsData({ payload }))
                .then((res) => {})
                .catch(() => {
                  toast.error("Some error occured while fetching data.", {
                    toastId: 1,
                    ...toastConfiguration,
                  });
                });
            } else {
              setLoadingData(false);
            }
          })
          .catch(() => {
            toast.error("Some error occured while fetching data.", {
              toastId: 1,
              ...toastConfiguration,
            });
          });
      }
    }
  }, []);

  let coinDatasetFilter = [];
  coin?.map((item) => {
    coinDatasetFilter.push(item?.symbol);
  });

  // const filterclient = () => {
  //   setLoadingPortfolios(true);
  //   const payload = {
  //     risk: getPortfolios?.risk,
  //     minBalance:
  //       getPortfolios?.funds === "0-10K"
  //         ? "0"
  //         : getPortfolios?.funds === "10k-50K"
  //         ? "10000"
  //         : getPortfolios?.funds === "50k-100K"
  //         ? "50000"
  //         : getPortfolios?.funds === "100k-1M"
  //         ? "100000"
  //         : "100000",
  //     maxBalance:
  //       getPortfolios?.funds === "0-10K"
  //         ? "10000"
  //         : getPortfolios?.funds === "10k-50K"
  //         ? "50000"
  //         : getPortfolios?.funds === "50k-100K"
  //         ? "100000"
  //         : getPortfolios?.funds === "100k-1M"
  //         ? "1000000"
  //         : "10000001",
  //     coins: coinDatasetFilter,
  //   };

  //   dispatch(filterClientDroid({ userToken, payload }))
  //     .then((res) => {
  //       if (res.meta && res.meta.requestStatus === "fulfilled") {
  //         setClientPortfolios(res?.payload);
  //         setLoadingPortfolios(false);
  //       }
  //     })
  //     .catch(() => {
  //       toast.error("Some error occured while fetching data", {
  //         toastId: 1,
  //         ...toastConfiguration,
  //       });
  //     });
  // };
  const clickBlance = (item) => {
    setPortfolios({ ...getPortfolios, funds: item });
  };
  useEffect(() => {
    const pushData = [];
    setselectedPortfoliosBalance([]);
    getClientPortfolios.forEach((clientPortfolio) => {
      clientPortfolio?.portfolio.forEach((prt) => {
        if (prt?.isActive) {
          pushData.push(prt);
          setselectedPortfoliosBalance(pushData);
        }
      });
    });
  }, [selectProtfoliyoData]);

  useEffect(() => {
    const matchedData = [];
    getDroidsDataInfo?.portfoliosIds?.forEach((approvedItem) => {
      const matchingExchangeItem = getPortfoliosData?.find(
        (exchangeItem) => exchangeItem?._id === approvedItem?.portfolioId
      );
      if (matchingExchangeItem) {
        matchedData.push({ ...matchingExchangeItem });
      }
    });
    if (matchedData?.length != 0) {
      setSelectedPortfolios(matchedData);
    }
  }, [getPortfoliosData, getDroidsDataInfo?.portfoliosIds]);

  function getExchangeNameById(id) {
    const matchingObject = getPortfoliosData.find((obj) => obj._id === id);
    if (matchingObject) {
      setSelectedPortfolios([matchingObject]);
    }
  }
  useEffect(() => {
    getExchangeNameById(selectedPortfolios[0]?._id);
  }, [selectedPortfolios[0]?._id]);

  // validation for Submit , Edit and Dublicate Button
  const validationButtonDroid = () => {
    const indexCoinSum = getDroidsDataInfo?.coinsList?.reduce(
      (sum, obj) => sum + parseInt(obj?.value),
      0
    );

    const nameAndCoin =
      !(getDroidsDataInfo?.name?.length > 2) ||
      !(getDroidsDataInfo?.coinsList?.length > 0);
    const takeProfitValidation =
      !(getDroidsDataInfo?.takeProfit?.targetProfitPercent >= 0.1) ||
      !getDroidsDataInfo?.takeProfit?.targetProfitPercent >= 0.1 ||
      (getDroidsDataInfo?.takeProfit?.isTrailingDeviationOn
        ? !getDroidsDataInfo?.takeProfit?.trailingDeviation
        : false);
    const ruleListValidation =
      getDroidsDataInfo?.dealRules?.repeat <=
      (state?.Duplicate === "edit" || state?.Duplicate === "Duplicate"
        ? [getDefaultRule]?.concat(getDroidsDataInfo?.dealRules?.list?.slice(1))
        : [getDefaultRule]?.concat(getDroidsDataInfo?.dealRules?.list)
      )?.length;
    const marketLimitValidation =
      getDroidsDataInfo?.buySellSettings?.startOrderType === "limit"
        ? !ruleListValidation
        : false;
    function checkDcaDroidWarning() {
      let hasTrueValue = false;
      dcaSafetyNetParameters.forEach((error) => {
        if (Object.values(error).some((value) => value === true)) {
          hasTrueValue = true;
        }
      });
      return hasTrueValue;
    }

    function checkIndexDroid() {
      let hasTrueValue = false;
      valueOfIndexWarning.forEach((error) => {
        if (Object.values(error).some((value) => value === true)) {
          hasTrueValue = true;
        }
      });
      return hasTrueValue;
    }

    function checkSimpleDroid() {
      let hasTrueValue = false;
      valueOfSimpleWarning.forEach((error) => {
        if (Object.values(error).some((value) => value === true)) {
          hasTrueValue = true;
        }
      });
      return hasTrueValue;
    }
    if (state?.droidType?.nav === "simplemodedroid") {
      if (
        checkSimpleDroid() ||
        dcaSafetyNetParameters[4]?.targetProfitPercent === true ||
        dcaSafetyNetParameters[5]?.trailling === true
      ) {
        return true;
      }

      if (
        (getDroidsDataInfo?.buySellSettings?.startOrderType === "limit" &&
          getDroidsDataInfo?.dealRules?.repeat === "") ||
        !getDroidsDataInfo?.takeProfit?.targetProfitPercent ||
        !getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount
      ) {
        return true;
      }
      if (
        marketLimitValidation ||
        nameAndCoin ||
        takeProfitValidation ||
        parseFloat(getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount) <=
          parseFloat(getbidValue.minAmount) ||
        !getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount ||
        getDroidsDataInfo?.buySellSettings?.startOrderType === "limit"
          ? !getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount
          : false ||
            getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount === "0" ||
            getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount === "0"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (state?.droidType?.nav === "timemodedroid") {
      if (valueOfTimeWarning[0]?.purchaseAmount === true) {
        return true;
      }
      if (
        !ruleListValidation ||
        nameAndCoin ||
        takeProfitValidation ||
        !getDroidsDataInfo?.dealRules?.repeat ||
        !getDroidsDataInfo?.timeSettings?.periodAt ||
        !getDroidsDataInfo?.timeSettings?.periodEvery ||
        !getDroidsDataInfo?.timeSettings?.pricePurchaseAmount ||
        !getDroidsDataInfo?.timeSettings?.purchaseAmount
      ) {
        return true;
      } else {
        return false;
      }
    } else if (state?.droidType?.nav === "safemodedroid") {
      if (
        nameAndCoin ||
        takeProfitValidation ||
        !(getDroidsDataInfo?.safetyNetRules?.length > 0) ||
        !getDroidsDataInfo?.rulesRepeat ||
        !getDroidsDataInfo?.takeProfit?.targetProfitPercent ||
        getDroidsDataInfo?.safetyNetRules[
          getDroidsDataInfo?.safetyNetRules?.length - 1
        ]?.pricetrend !== "Down"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (state?.droidType?.nav === "costmodedroid") {
      /********************************************************/
      // temporary code untill dca fixed
      if (
        getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit" &&
        getDroidsDataInfo?.takeProfit?.takeProfitType === "value" &&
        getDroidsDataInfo?.newProfit <= 0
      ) {
        return true;
      }
      /**********************************************************/

      if (checkDcaDroidWarning()) {
        return true;
      }

      if (
        getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit" &&
        getDroidsDataInfo?.dealRules?.repeat === ""
      ) {
        return true;
      }

      const checkSafetyNetParameters = getDroidsDataInfo?.safetyNetParameters;
      if (
        checkSafetyNetParameters?.hasOwnProperty("orderSizeAmount") === false ||
        checkSafetyNetParameters?.hasOwnProperty("maxOrderCount") === false ||
        checkSafetyNetParameters?.hasOwnProperty("priceDeviation") === false
      ) {
        return true;
      }
      if (
        !checkSafetyNetParameters?.orderSizeAmount ||
        !checkSafetyNetParameters?.maxOrderCount ||
        !checkSafetyNetParameters?.priceDeviation
      ) {
        return true;
      }
      const stopLossValidation = getDroidsDataInfo?.stopLoss?.isStopLoss
        ? !getDroidsDataInfo?.stopLoss?.stopLoss ||
          !getDroidsDataInfo?.stopLoss?.stopLossAction
        : false;
      if (
        !ruleListValidation ||
        nameAndCoin ||
        takeProfitValidation ||
        stopLossValidation ||
        !getDroidsDataInfo?.dcaStrategy?.baseOrderSizeAmount ||
        (getDroidsDataInfo?.stopLoss?.isStopLoss &&
          !getDroidsDataInfo?.safetyNetParameters?.orderSizeAmount) ||
        (getDroidsDataInfo?.stopLoss?.isStopLoss &&
          !getDroidsDataInfo?.safetyNetParameters?.maxOrderCount) ||
        (getDroidsDataInfo?.stopLoss?.isStopLoss &&
          !getDroidsDataInfo?.safetyNetParameters?.priceDeviation) ||
        (getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit"
          ? !getDroidsDataInfo?.dcaStrategy?.limit
          : false)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (state?.droidType?.nav === "indexmodedroid") {
      if (
        checkIndexDroid() ||
        dcaSafetyNetParameters[4]?.targetProfitPercent === true
      ) {
        return true;
      }
      if (
        nameAndCoin ||
        !getDroidsDataInfo?.totalBuyAmount ||
        !indexCoinSum ||
        // !getDroidsDataInfo?.rebalanceStrategy?.timePeriod ||
        takeProfitValidation ||
        // (getDroidsDataInfo?.balancing?.stopLossTimePeriod !== "Never" &&
        //   !getDroidsDataInfo?.balancing?.thresholdBalancing) ||
        indexCoinSum < 100
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getValidationDroid = validationButtonDroid();
  const formattedDate = moment(new Date()).format("DD/MM/YYYY");

  // // Initial set Data when Droid will create
  useEffect(() => {
    let payload = {
      name: "",
      retailerIds: [localStorage.getItem("userID")],
      coinsList: [],
      takeProfit: { isTrailingDeviationOn: false, takeProfitType: "base" },
      dealRules: { cooldownseconds: "", repeat: "", list: [] },
    };
    if (state?.droidType?.nav === "simplemodedroid") {
      payload = {
        ...payload,
        typeCode: "1",
        type: "Simple Mode",
        buySellSettings: {
          buyPurchaseAmountType: "dollar",
          pricePurchaseAmountType: "dollar",
          startOrderType: "market",
          buyPercent: "",
        },
      };
    } else if (state?.droidType?.nav === "timemodedroid") {
      payload = {
        ...payload,
        typeCode: "2",
        type: "Time Mode",
        timeSettings: {
          purchaseAmountType: "dollar",
          pricePurchaseAmountType: "dollar",
          firstBuyDate: formattedDate,
        },
      };
    } else if (state?.droidType?.nav === "costmodedroid") {
      payload = {
        ...payload,
        typeCode: "3",
        type: "Cost Mode",
        dcaStrategy: {
          profitInCurrency: "USD",
          startOrderType: "market",
          baseOrderSizeType: "dollar",
          limitType: "dollar",
        },
        stopLoss: {
          isStopLoss: false,
          stopLossAction: "",
          stopLossTimeout: false,
        },
        safetyNetParameters: { orderSizeType: "dollar" },
      };
    } else if (state?.droidType?.nav === "indexmodedroid") {
      payload = {
        ...payload,
        typeCode: "4",
        type: "Index Mode",
        timezone: timeZone,
        rebalanceStrategy: { timePeriodDay: "", action: "" },
        balancing: { stopLossTimePeriod: "" },
      };
    } else if (state?.droidType?.nav === "safemodedroid") {
      payload = {
        ...payload,
        isBuy: false,
        isSell: false,
        typeCode: "5",
        type: "Safe Mode",
        period: "Never",
        thresholdType: "dollar",
        action: "Buy",
        priceType: "dollar",
        safetyNetRules: [],
      };
    }

    if (state?.Duplicate !== "edit" || state?.Duplicate !== "Duplicate") {
      dispatch(DroidsData({ payload })).then((res) => {});
    }
  }, [state?.droidType?.nav]);

  useEffect(() => {
    setLoadingPortfolios(true);
    dispatch(
      filterClientDroid({
        userToken,
        payload: {
          coins: ["USD"],
        },
      })
    )
      .then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setClientPortfolios(res?.payload);
          setLoadingPortfolios(false);
        }
      })
      .catch(() => {
        toast.error("Some error occured while fetching data", {
          toastId: 1,
          ...toastConfiguration,
        });
      });
  }, []);

  return (
    <>
      {getPortfoliosData?.map((i) => filterPortfoliosIds?.includes(i?._id))}
      <div className="row droid-settings-container">
        <div className="col-lg-3 col-md-12 ml-20 div-1200">
          <h4 className="mb-3">Portfolios</h4>
          {localStorage.getItem("role") === "retailer" &&
          (getLoadingData || getPortfoliosDataLoading) ? (
            <div
              className="d-flex justify-content-center align-items-center form-control"
              style={{ height: "75vh" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {!Duplicate?.Duplicate ? (
                ""
              ) : (
                  localStorage.getItem("role") === "broker"
                    ? getselectedPortfoliosBalance?.length === 0
                    : selectedPortfolios?.length === 0 &&
                      getPortfoliosData?.length
                ) ? (
                <div className="text-danger text-center ">
                  ⚠️ Please choose a portfolio with a balance greater than zero.
                </div>
              ) : (
                <>
                  {Duplicate?.Duplicate === "Duplicate" &&
                  !getPortfoliosData
                    ?.map((i) => filterPortfoliosIds?.includes(i?._id))
                    ?.includes(true) &&
                  (localStorage.getItem("role") === "broker"
                    ? getselectedPortfoliosBalance?.length === 0
                    : selectedPortfolios?.length === 0) ? (
                    <div className="text-danger">Please select portfolio</div>
                  ) : (
                    ""
                  )}
                </>
              )}
              {localStorage.getItem("role") === "broker" ? (
                <div className="DMCContainerMain h-100">
                  <h6 className="mb-2 mt-3">Coins</h6>
                  <label className="form-control p-3 d-flex align-items-center">
                    <USD wd={"20"} ht={"20"} />
                    <span className="h6 ms-2 mb-0">USD</span>
                  </label>

                  {/* <MultiSelectDropDown
                    title="Select Coins"
                    className="webkit-fill-available"
                    getClickDropdoen={coin}
                    setClickDropdoen={setCoin}
                  />
                  <h6 className="mb-2 mt-3">Balance ($)</h6>
                  <div className="DMCFundsParagraph align-items-center justify-content-center">
                    <div className="funds-container mb-2">
                      {balanceDataPortfoliyo?.map((item, i) => (
                        <p
                          key={i}
                          className={`${
                            getPortfolios?.funds === item?.balance
                              ? "selected-active-funds"
                              : ""
                          } my-1`}
                          onClick={() => clickBlance(item?.balance)}
                        >
                          {item?.balance}
                        </p>
                      ))}
                    </div>
                  </div>
                  <h6 className="mb-3 mt-3">Risk Level</h6>
                  <div className="DMCFundsParagraph">
                    <div className="d-flex">
                      {riskLevelDataPortfoliyo?.map((item, index) => (
                        <p
                          key={index}
                          className={` ${
                            getPortfolios?.risk === item?.risk
                              ? "selected-active-funds"
                              : ""
                          }`}
                          onClick={() =>
                            setPortfolios({
                              ...getPortfolios,
                              risk: item?.risk,
                            })
                          }
                        >
                          {" "}
                          {item?.risk}{" "}
                        </p>
                      ))}
                    </div>
                  </div> */}
                  {/* <SecondryButton
                    handleClick={filterclient}
                    disabled={
                      Duplicate?.Duplicate === "edit" ||
                      getPortfolios?.risk === "" ||
                      getPortfolios?.funds === "" ||
                      getPortfolios?.coins?.length === 0
                    }
                    text="Apply Filters"
                    className={`webkit-fill-available mt-4 justify-content-center ${
                      Duplicate?.Duplicate === "edit" ||
                      getPortfolios?.risk === "" ||
                      getPortfolios?.funds === "" ||
                      getPortfolios?.coins?.length === 0
                        ? "btn btn-outline-secondary"
                        : "btn-outline-secondary"
                    }`}
                  /> */}
                  {localStorage.getItem("role") === "broker" ? (
                    <>
                      {" "}
                      <h4 className="my-4 text-center">
                        Clients and Portfolios
                      </h4>
                      <MultilevelDropDown
                        setBrokerExchange={setBrokerExchange}
                        selectProtfoliyoData={selectProtfoliyoData}
                        setSelectProtfoliyoData={setSelectProtfoliyoData}
                        getLoadingPortfolios={getLoadingPortfolios}
                        getClientPortfolios={getClientPortfolios}
                        setClientPortfolios={setClientPortfolios}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <>
                  {getPortfoliosDataLoading ? (
                    <div className="justify-content-center display-flex">
                      {" "}
                      <Button disabled text="Loading ..." />{" "}
                    </div>
                  ) : (
                    <>
                      {getPortfoliosData?.length > 0 ? (
                        ""
                      ) : state?.Duplicate === "edit" ? (
                        <div className="text-danger text-center ">
                          ⚠️ Exchange Not found{" "}
                        </div>
                      ) : getPortfoliosData?.length > 0 ? (
                        ""
                      ) : (
                        <div className="text-danger text-center ">
                          ⚠️ No portfolios found.{" "}
                        </div>
                      )}
                      <DroidPortfoliosRetailer
                        setError={setError}
                        getbidValue={getbidValue?.bid}
                        setCoinListData={setCoinListData}
                        data={getPortfoliosData}
                        setSelectedPortfolios={setSelectedPortfolios}
                        selectedPortfolios={selectedPortfolios}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {/* Section-C ⚙️ Setting */}

        <div className="col-lg-5 col-md-12 ml-20 div-1200">
          <SimpleModeDroidSetting
            getDefaultRule={getDefaultRule}
            ruleListError={ruleListError}
            ruleErrorFun={() => setruleListError(false)}
            setDefaultRule={setDefaultRule}
            getError={getError}
            setError={setError}
            checkBalance={
              localStorage.getItem("role") === "broker"
                ? getselectedPortfoliosBalance
                : selectedPortfolios
            }
            getCoinListData={getCoinListData}
            selectedPortfolios={selectedPortfolios}
            getbidValue={getbidValue?.bid}
            minAmount={getbidValue?.minAmount}
            getOrderStatus={getOrderStatus}
            getSingleCoinData={getSingleCoinData}
            setSingleCoinData={setSingleCoinData}
            getBuySellStatus={getBuySellStatus}
          />
        </div>

        <div className="col-lg-4 col-md-12 ml-20 div-1200 div-md-1000 mt-3 mt-xl-0">
          <h4 className="mb-3 ">Preview</h4>
          <DroidModule
            getDefaultRule={getDefaultRule}
            droid={getDroidsDataInfo}
            DroidIcon={require(`assets/droid67Icon.svg`).default}
            selectedPortfolios={
              localStorage.getItem("role") === "broker"
                ? getselectedPortfoliosBalance
                : selectedPortfolios
            }
            getbidValue={getbidValue.bid}
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-end gap-3 ask-Qustion-container position-relative pb-3 pt-1"
        style={{ bottom: "unset", right: "unset" }}
      >
        <SecondryButton
          text="Cancel"
          handleClick={() => {
            navigate(`/management/droids`);
          }}
        />
        {state?.Duplicate === "edit" ? (
          state?.droidType?.nav === "simplemodedroid" &&
          localStorage.getItem("editDroid") === path[4] ? (
            ""
          ) : (
            <Button
              text="Update"
              loading={getLoding}
              disabled={
                getValidationDroid ||
                ((state?.droidType?.nav === "simplemodedroid" ||
                  state?.droidType?.nav === "timemodedroid" ||
                  state?.droidType?.nav === "costmodedroid") &&
                  !getDroidsDataInfo?.dealRules?.repeat) ||
                getLoding ||
                !getPortfoliosData?.some(
                  (e) => e._id === selectedPortfolios[0]?._id
                )
              }
              handleClick={handleUpdate}
            />
          )
        ) : (
          <Button
            text={"Submit"}
            handleClick={handleSubmit}
            disabled={getValidationDroid || getLoding}
            loading={getLoding}
          />
        )}
      </div>
    </>
  );
};

export default AddDroidSetting;
