import React from "react";

function ArrowDownIcon({fill, color, height=24,width=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? color : "#fff"}
        fillRule="evenodd"
        d="M18.53 8.47a.75.75 0 00-1.06 0L12 13.94 6.53 8.47a.75.75 0 00-1.06 1.06L12 16.06l6.53-6.53a.75.75 0 000-1.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
