import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "components/Button";
import BrokersCard from "./BrokersCard";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getBroker } from "features/Community/communityAction";
import { userTypeTrader } from "features/typeTrader/typeTraderAction";
import "./index.scss";
import { UsersSkeletonLoading } from "./SkeltonLoading";
import { useTranslation } from "react-i18next";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const CommunityBrokers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const containerRef = useRef();
  const { typeoflevel } = useSelector((state) => state?.defaultState);
  const { userToken } = useSelector((state) => state.auth);
  const { typeTraderInfo } = useSelector((state) => state?.typeTrader);
  const [getTypeOfTrader, setTypeOfTrader] = useState(typeTraderInfo || []);
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [totalUsersCount, settotalUsersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [getCheckData, setCheckedData] = useState({
    level: [],
    Trader: [],
    Company: [],
  });
  let userType = localStorage.getItem("role");
  const [searchBroker, setSearchBroker] = useState("");

  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [getstatusUser, setstatusUser] = useState(true);

  const debouncedFetchResults = useCallback(
    debounce((query) => loadMoreData(query, 2, 1), 500),
    []
  );

  const typeOfTraderClick = (item, id) => {
    if (id === "trader") {
      if (getCheckData?.Trader?.includes(item?._id)) {
        setCheckedData((prevObject) => ({
          ...prevObject,
          Trader: getCheckData?.Trader?.filter(
            (selectedLevel) => selectedLevel !== item?._id
          ),
        }));
      } else {
        setCheckedData((prevObject) => ({
          ...prevObject,
          Trader: [...prevObject.Trader, item._id],
        }));
      }
    } else if (id === "Level") {
      if (getCheckData?.level?.includes(item?.id)) {
        setCheckedData((prevObject) => ({
          ...prevObject,
          level: getCheckData?.level?.filter(
            (selectedLevel) => selectedLevel !== item?.id
          ),
        }));
      } else {
        setCheckedData((prevObject) => ({
          ...prevObject,
          level: [...prevObject.level, item.id],
        }));
      }
    } else if (id === "Company") {
      if (getCheckData?.Company?.includes(item?._id)) {
        setCheckedData((prevObject) => ({
          ...prevObject,
          Company: getCheckData?.Company?.filter(
            (selectedLevel) => selectedLevel !== item?._id
          ),
        }));
      } else {
        setCheckedData((prevObject) => ({
          ...prevObject,
          Company: [...prevObject.Company, item._id],
        }));
      }
    }
  };

  const fetchData = async () => {
    try {
      const [result1] = await Promise.all([
        dispatch(userTypeTrader(userToken))
          .then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
              return res.payload;
            }
          })
          .catch((err) => {
            console.log("error", err);
          }),
      ]);

      setTypeOfTrader(result1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const loadMoreData = (search, filterID, pageNo) => {
    setIsLoading(true);
    const filterData =
      filterID == 1
        ? { level: [], Trader: [], Company: [], search: search, page: pageNo }
        : { ...getCheckData, page: pageNo, search: search };
    dispatch(getBroker({ userToken, filterData, userType }))
      .then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          setFilteredBrokers(res?.payload?.user);
          settotalUsersCount(res?.payload.totalUsersCount);
          setIsLoading(false);
          if (res?.payload?.user.length === 0) {
            setstatusUser(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const filter = () => {
    setPage(1);
    loadMoreData(searchBroker, 2, 1);
  };

  const clearFilter = () => {
    let items = document.getElementsByName("acs");
    const filterID = 1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
    }
    setCheckedData({ level: [], Trader: [], Company: [] });
    setSearchBroker("");
    loadMoreData("", filterID, 1);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchBroker(query);
    debouncedFetchResults(query);
    setPage(1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (getstatusUser) {
      loadMoreData("", 1, 1);
    }
  }, []);

  return (
    <div
      className="row broker-cards-container position-relative"
      style={{ height: "70vh", overflowY: "auto" }}
    >
      <div
        className={`col-lg-2 col-xl-2 col-xxl-2 col-md-3 col-sm-2 align-items-center ${
          localStorage.getItem("role") === "retailer"
            ? "filterLeftBarheightBroker"
            : "filterLeftBarheightRetailer"
        } filterLeftBar position-sticky`}
        style={{ top: "0px" }}
      >
        <input
          type="search"
          placeholder={`Search ${
            localStorage.getItem("role") === "broker" ? "Retailer" : "Broker"
          } ...`}
          value={searchBroker}
          onChange={(e) => handleSearchChange(e)}
        />
        {localStorage?.getItem("role") === "broker" ? (
          ""
        ) : (
          <>
            <h6>{t("typeOfTrader")}</h6>
            {getTypeOfTrader?.map((item, index) => {
              return (
                <label className="container-checkmark" key={item?._id}>
                  {" "}
                  {item?.title}
                  <input
                    type="checkbox"
                    id={`custom-checkbox-Trader-${item?._id}`}
                    name="acs"
                    onChange={() => typeOfTraderClick(item, "trader")}
                  />{" "}
                  <span className="checkmark"></span>
                </label>
              );
            })}
          </>
        )}
        <h6>{t("8081Level")} </h6>
        {typeoflevel?.map((item, index) => {
          return (
            <label className="container-checkmark" key={item?._id}>
              {item?.level}
              <input
                type="checkbox"
                id={`custom-checkbox-Level-${index}`}
                name="acs"
                onChange={() => typeOfTraderClick(item, "Level")}
              />{" "}
              <span className="checkmark"></span>
            </label>
          );
        })}
        <hr />
        <div className="row">
          <div className="col-lg-6 col-md-12 d-flex justify-content-start ">
            <Button
              // disabled={
              //   getCheckData?.level?.length === 0 &&
              //   getCheckData?.Trader?.length === 0 &&
              //   getCheckData?.Company?.length === 0 &&
              //   searchBroker.length === 0
              // }
              text="Reset"
              handleClick={clearFilter}
            />
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-end ">
            <Button
              disabled={
                getCheckData?.level?.length === 0 &&
                getCheckData?.Trader?.length === 0 &&
                getCheckData?.Company?.length === 0 &&
                searchBroker.length === 0
              }
              text="Apply"
              handleClick={filter}
            />
          </div>
        </div>
      </div>

      <div
        className="col-lg-10 col-xl-10 col-xxl-10 col-md-9 col-sm-10 px-3 brokers exchange-Cards brokers-container"
        ref={containerRef}
      >
        {isLoading ? (
          <div className="row mb-4">
            {[...Array(3).keys()].map(() => {
              return <UsersSkeletonLoading />;
            })}
          </div>
        ) : filteredBrokers?.length > 0 ? (
          <div className="row">
            {filteredBrokers?.map((item, index) => (
              <BrokersCard {...item} key={index} />
            ))}
            {
              <div className="pagination-container">
                <Pagination
                  color="primary"
                  defaultPage={1}
                  count={Math.ceil(totalUsersCount / itemsPerPage)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                    loadMoreData(searchBroker, {}, value);
                  }}
                />
              </div>
            }
          </div>
        ) : (
          <div className="row w-100 justify-content-center py-4">
            {t("noBrokersFound")}{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityBrokers;
