import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorCode from 'ErrorCode'

//----------------------------Get API to get exchange list------------------------
export const getExchangeList = createAsyncThunk(
    'exchange/getExchangeIntegrationList',
    async ({ userToken }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/getExchangeIntegrationList`,
                config
            )
            if (data.hasOwnProperty('error')) {
                ErrorCode(data)
                return rejectWithValue(data)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
//----------------------------Get API to get exchange Card Detail------------------------
export const exchangeDetail = createAsyncThunk(
    'exchange/getExchangeDetail/',
    async ({ userToken, id }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/getExchangeDetail/${id}`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
//----------------------------POST API Exchange Connect------------------------
export const createExchangeIntegration = createAsyncThunk(
    'createExchangeIntegration',
    async ({ userToken, payload }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASEURL}/api/createExchangeIntegration`,
                payload,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
//----------------------------POST API Exchange Delete------------------------
export const deleteExchange = createAsyncThunk(
    'deleteExchange',
    async ({ userToken, integratedExchangeId }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASEURL}/api/deleteExchange/${integratedExchangeId}`,
                config
            )
            if (data.hasOwnProperty('error')) {
                return rejectWithValue(data.message)
            } else {
                return data.data
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                ErrorCode(error.response.data)
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
