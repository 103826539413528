import React from 'react'

const ConvetNumber2Digit = ({ data }) => {
  function formatNumber(number) {
    if (number > 1000000000000) {
      return Number((number / 1000000000000))?.toFixed(2) + 'T';
    } else if (number > 1000000000) {
      return Number((number / 1000000000))?.toFixed(2) + 'B';
    } else if (number > 1000000) {
      return Number((number / 1000000))?.toFixed(2) + 'M';
    } else if (number > 1000) {
      return Number((number / 1000))?.toFixed(2) + 'K';
    } else {
      return Number?.isInteger(number) ? number?.toString() : Number(number)?.toFixed(2);
    }
  }
  return (
    <div>{data === undefined ? 0 : formatNumber(data)}</div>
  )
}

export default ConvetNumber2Digit