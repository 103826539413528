import React from "react";

function ClockIcon({color='#0078E7',width=16,height=16}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.438"
        d="M13.5 9H9.24A.24.24 0 019 8.76V5.5M17 9A8 8 0 111 9a8 8 0 0116 0z"
      ></path>
    </svg>
  );
}

export default ClockIcon;
