import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import resetPasswordSchema from "../helper/resetPasswordSchema";
import { useDispatch } from "react-redux";
import { resetPassword } from "features/auth/authActions";
import eye from "assets/eye.svg";
import eyeOff from "assets/eye-off.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [expiryTime, setExpiryTime] = useState('');
  const [hashCode, sethashCode] = useState('');

  useEffect(() => {
    const queryString = window.location.search.substring(1);
    const exp = queryString?.split('=')[queryString?.split('=')?.length - 1]
    const queryParams = queryString.split('&');
    const hashCode = queryParams[0].split('=')[1];
    const startTime = new Date(Number(exp));
    const currentTime = new Date();
    const timeDifference = currentTime - startTime;
    const minutesDifference = timeDifference / (1000 * 60);
    setExpiryTime(minutesDifference >= 10);
    sethashCode(hashCode);
  }, []);

  const isLinkExpired = () => {
    const currentTimestamp = Date.now() / 1000;
    return currentTimestamp > parseInt(expiryTime, 10);
  };

  const onSubmitHandler = (values) => {
    const payload = { hash: hashCode, password: values.password }
    setIsFormSubmitted(true)
    dispatch(resetPassword(payload))
      .then((response) => {
        setIsFormSubmitted(false)
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className={`landingpage px-5 pt-5`}>
            <Link to='/login'> <img src={require('assets/companylogo.svg').default} alt="Company_Logo" className="company_logo_img d-block mb-4" loading='lazy'/> </Link>
            <div className="d-flex login_back_button">
              <div className="h4-One d-flex"></div>
              <Link className="d-flex align-items-center " type="button" to="/login">
                <img src={require(`assets/arrow-left.svg`).default} alt="" loading='lazy'/>
                <button className="main_custom_button" >Login</button>
              </Link>
            </div>
            {expiryTime ?
              <div className="d-flex justify-content-center align-items-center h-50 ">
                <div className="w-40 "><h4 className="mb-2 d-flex justify-content-center">Expired reset password link</h4>
                  <span className="">⚠️ The link to reset your password has expired. You can look up your username and password again. If you already reset your password, <a href="/login">Log in.</a></span></div>
              </div> :
              <div className="d-grid justify-content-center right-0 pading-top-15">
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={resetPasswordSchema}
                  onSubmit={(values) => {
                    onSubmitHandler(values);
                  }} >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="input-container d-grid gap-2">
                        <label htmlFor="" className="my-3 h4-Three">Forgot Password</label>
                        <label htmlFor="" > New Password </label>
                        <div className="d-flex position-relative align-items-center">
                          <Field className="custom_input pe-4" type={showPassword ? "text" : "password"} name="password" />
                          <img src={showPassword ? eye : eyeOff} alt="Show password" onClick={() => setShowPassword(!showPassword)} className={`password-eye ${showPassword ? 'eye-icon' : 'eye-icon-inactive'} `} loading='lazy'/>
                        </div>
                        {errors.password && touched.password && <div className="text-danger">{errors.password}</div>}
                      </div>
                      <div className="input-containerd-grid gap-2 my-3">
                        <label htmlFor="" > Confirm New Password </label>
                        <div className="d-flex position-relative align-items-center">
                          <Field className="custom_input pe-4" type={showConfirmPassword ? "text" : "password"} name="confirmpassword" />
                          <img src={showConfirmPassword ? eye : eyeOff} alt="Show password" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className={`password-eye ${showPassword ? 'eye-icon' : 'eye-icon-inactive'} `} loading='lazy'/>
                        </div>
                        {errors.confirmpassword && touched.confirmpassword && <div className="text-danger">{errors.confirmpassword}</div>}
                        <div className="text-center ">
                          <button type="submit" className={`primaryButton my-3 ${isFormSubmitted && `disabled_button`}`} disabled={isFormSubmitted}>Reset</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword