import React, { useEffect, useState, useRef } from "react";
import CardShadow from "components/CardShadow";
import PopupModal from "components/PopupModal";
import {
  createExchangeIntegration,
  deleteExchange,
  exchangeDetail,
} from "features/Exchange/ExchangeAction";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { updatePortfolio } from "features/CoinList/CoinListAction";
import Button from "components/Button";
import SecondryButton from "components/Button/SecondryButton";
import Loader from "components/Loader";
import DottedChart from "components/Charts/DottedChart";
import ProgressBar from "components/Charts/ProgressBar";
import { WarningIcon } from "assets";
import axios from "axios";
import ErrorCode from "ErrorCode";
import { Box, CircularProgress } from "@mui/material";
import styles from "./ExchangeConnect.module.scss";
import { toast } from "react-toastify";

const toastConfiguration = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
};

const baseUrl = `${window.location.protocol}//${window.location.host}`;

const ExchangeConnect = (props) => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const [ShowInformation, setShowInformation] = useState(false);
  const [exchangeMsg, setexchangeMsg] = useState("");
  const [openDeletePopup, setopenDeletePopup] = useState(false);
  const [getExchangeDetail, setExchangeDetail] = useState([] || props);
  const [getLoading, setLoading] = useState(false);
  const [exchangeConnect, setExchangeConnect] = useState({});
  const [revertButton, setRevertButton] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCopied, setisCopied] = useState(false);

  const fetchExchangeDetail = async () => {
    await dispatch(exchangeDetail({ userToken, id: props?._id })).then(
      (response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setExchangeDetail(response.payload);
        }
      }
    );
  };
  useEffect(() => {
    if (props?._id) {
      fetchExchangeDetail();
    }
  }, [props]);

  useEffect(() => {
    if (openDeletePopup) {
      checkIfExchangeDeletable(props?.integratedExchangeId);
    }
  }, [openDeletePopup]);

  const checkIfExchangeDeletable = async (exchangeId) => {
    setopenDeletePopup(false);
    setexchangeMsg("");
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/deleteExchangeWarning/${exchangeId}`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return data.message;
      } else {
        setexchangeMsg(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  };

  function copyToClipboard(e) {
    e.preventDefault();
    e.stopPropagation();
    var copyText = document.getElementById("ipAddress").textContent;
    var textarea = document.createElement("textarea");
    textarea.value = copyText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    navigator.clipboard
      .writeText(copyText)
      .then(function () {
        // toast.success("Copied to clipboard: " + copyText, {
        //   toastId: 1,
        //   ...toastConfiguration,
        // });

        setisCopied(true);

        setTimeout(() => {
          setisCopied(false);
        }, 2000);
      })
      .catch(function (error) {
        console.error("Error copying text: ", error);
      });
  }

  const validateForm = (formElements) => {
    let isValid = true;
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.hasAttribute("required") && element.value.trim() === "") {
        isValid = true;
        break;
      }
    }
    return isValid;
  };

  const handleChange = (name, value, formElements) => {
    setExchangeConnect((prevObject) => {
      const newState = { ...prevObject, [name]: value };
      return newState;
    });
    setIsFormValid(validateForm(formElements));
  };

  const exchangeHandleChange2 = (event) => {
    const { name, value } = event.target;
    handleChange(name, value, event.target.form.elements);
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pastedData = event.clipboardData.getData("text");
    const { name } = event.target;
    console.log("Pasted data:", { eve: pastedData });
    handleChange(name, pastedData, event.target.form.elements);
  };

  const exchangeHandleChange = (event) => {
    const { name, value } = event.target;
    setExchangeConnect((prevObject) => ({ ...prevObject, [name]: value }));

    const formElements = event.target.form.elements;
    let isValid = true;
    for (let i = 0; i < formElements.length; i++) {
      if (
        formElements[i].hasAttribute("required") &&
        formElements[i].value === ""
      ) {
        isValid = false;
        break;
      }
    }
    setIsFormValid(isValid);
    // event.target.reset();
  };

  const handleBack = () => {
    setExchangeConnect({});
  };

  const handleClick = () => {
    window.open(props?.url);
  };

  const handleSubmit = async (e) => {
    e.target.reset();
    setLoading(true);
    e.preventDefault();
    let values = exchangeConnect;
    values.secretKey = values.secretKey.replace(/\\n/g, "\n");
    values.secretKey = values.secretKey.replace(/\\(?!n)/g, "");
    values.APIKey = values.APIKey.replace(/\\n/g, "\n");
    values.APIKey = values.APIKey.replace(/\\(?!n)/g, "");
    let payload = Object.assign(values, {
      exchangeId: props?._id,
      exchangeName: props?.name,
    });
    try {
      await dispatch(createExchangeIntegration({ userToken, payload })).then(
        (response) => {
          if (response.meta && response.meta.requestStatus === "fulfilled") {
            dispatch(updatePortfolio({ userToken })).then((res) => {});
            setLoading(false);
            setShowButton(true);
            setTimeout(() => {
              localStorage.setItem("connectExchange", props?._id);
              window.location.reload();
            }, 2000);
            props?.setConnect(!props?.getConnect);
          } else if (
            response.meta &&
            response.meta.requestStatus === "rejected"
          ) {
            setLoading(false);
            handleBack();
          }
        }
      );
    } catch (error) {}
    const formElements = e.target.elements;
    let isValid = true;
    for (let i = 0; i < formElements.length; i++) {
      if (
        formElements[i].hasAttribute("required") &&
        formElements[i].value === ""
      ) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
    }
    e.target.reset();
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await dispatch(
        deleteExchange({
          userToken,
          integratedExchangeId: props?.integratedExchangeId,
        })
      ).then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setLoading(false);
          props?.setConnect(!props?.getConnect);
          setRevertButton(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    } catch (error) {}
  };

  const capitalize = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  return (
    <>
      <CardShadow className="px-4 py-3">
        {!props?.icon ? (
          <div className="d-flex justify-content-center align-items-center">
            {props?.getLoading ? (
              <Loader />
            ) : (
              <small className="h6-One-Grey ">Exchange Details</small>
            )}
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center mb-2">
              {" "}
              <img
                src={props?.icon}
                alt=""
                width={"auto"}
                height={20}
                loading="lazy"
              />{" "}
            </div>
            <h4 className="text-center my-2">{props?.name}</h4>
            <div className="text-center row">
              <h3>$160</h3>
              <small className="h6-One-Grey mb-3">
                Monthly Saving Potential
              </small>
              <hr />
              <span className="P5 mb-3">
                {" "}
                <b>Risk</b> {capitalize(props?.risk)}
              </span>
              <span className="P5 mb-2">
                {" "}
                <b>Location</b> {props?.location}{" "}
              </span>
              <hr />
              <div className="d-flex justify-content-center my-2 gap-5">
                <div className="d-flex gap-1">
                  {" "}
                  <ProgressBar icon={props?.icon} makerPercentage={3} />{" "}
                  <p className="P5">Maker {props?.fees?.makerFeeRate}%</p>{" "}
                </div>
                <div className="d-flex">
                  {" "}
                  <DottedChart icon={props?.icon} takerPercentage={3} />{" "}
                  <p className="P5">Taker {props?.fees?.takerFeeRate}%</p>{" "}
                </div>
              </div>
              <hr />
              <div>
                {ShowInformation && (
                  <p className="exchangeInformation my-4 p-One">
                    {" "}
                    {props?.detail}{" "}
                  </p>
                )}
                <p
                  className="text-primary mb-2 curser-pointer"
                  onClick={() => setShowInformation(!ShowInformation)}
                >
                  {" "}
                  {ShowInformation
                    ? "Less Information"
                    : "More Information"}{" "}
                </p>
              </div>
              <div className="card-footer1 py-3">
                <p className="P6 my-2">
                  {" "}
                  <b>Special Offers:</b>{" "}
                </p>
                <p className="P5">
                  {" "}
                  <b>Price Went Down in 20%</b>{" "}
                </p>
                <p className="p-Two my-2">Expired in 10 Days</p>
                <div
                  className={`py-2 gap-2 mt-3 d-flex ${
                    props?.isIntegrated
                      ? "justify-content-center"
                      : "justify-content-between"
                  } `}
                >
                  {props?.isIntegrated && !revertButton ? (
                    ""
                  ) : (
                    <SecondryButton
                      text="Create Account"
                      handleClick={handleClick}
                    />
                  )}
                  {props?.isIntegrated && !revertButton ? (
                    <Button
                      text="Delete"
                      databstoggle="modal"
                      onClick={() => setopenDeletePopup(!openDeletePopup)}
                      databstarget="#DeleteExchange"
                      className="px-5"
                    />
                  ) : (
                    <Button
                      databstoggle="modal"
                      loading={getLoading}
                      databstarget="#ConnectExchange"
                      text="Connect"
                      className="px-5"
                      disabled={!Boolean(props?._id) || getLoading}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </CardShadow>
      <PopupModal id={"DeleteExchange"} backdrop={false}>
        <div className="popupReviewScreen m-3">
          <div className="modal-header border-0">
            <div className="addReviewCompanyLogo">
              <h3>Delete Exchange</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          {exchangeMsg === "" ? (
            <div style={{ padding: "100px" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <div className="modal-body">
              <div className="d-flex align-items-center gap-2">
                <WarningIcon />
                <h5 class="text-break">{exchangeMsg}</h5>
              </div>
              <div className="d-flex gap-3 mt-4 justify-content-end">
                {revertButton === false ? (
                  <>
                    <SecondryButton text="Cancel" databsdismiss="modal" />
                    <Button
                      text="Delete"
                      handleClick={handleDelete}
                      loading={getLoading}
                      disabled={getLoading}
                    />
                  </>
                ) : (
                  <SecondryButton
                    text="Auto refreshing..."
                    databsdismiss="modal"
                    disabled={true}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </PopupModal>
      <PopupModal id={"ConnectExchange"} backdrop={false}>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div>
              <h3 className="text-center mb-3">Connect Exchange</h3>
              {/* {props?._id == "65ea4cc9e3924e27cadb19db" && (
                <>
                  <div className={styles.container}>
                    <h5>
                      Connect keys securely{" "}
                      <a
                        href="https://help.3commas.io/en/articles/8228623-coinbase-advanced-how-to-create-api-keys"
                        target="_blank"
                        className={styles.guidelink}
                      >
                        Full Guide
                      </a>
                    </h5>
                    <ol>
                      <li>
                        Access your exchange account and navigate to{" "}
                        <a
                          href="https://portal.cdp.coinbase.com/access/api"
                          style={{ textAlign: "left" }}
                          target="_blank"
                          className={styles.apilink}
                        >
                          API Settings
                        </a>
                      </li>
                      <li>
                        Enable IP whitelisting and copy/paste the following
                        addresses:
                        <div className={styles.ipcontainer}>
                          <span id="ipAddress" className={styles.ipaddress}>
                            {baseUrl == "https://uat.8081.io"
                              ? "54.162.121.206,44.219.85.194"
                              : baseUrl == "https://beta.8081.io"
                              ? "18.215.46.59,18.232.240.185"
                              : "3.228.246.200,107.20.116.103,54.225.223.107"}
                          </span>
                          <button
                            type="button"
                            onClick={copyToClipboard}
                            className={`${styles.copybutton} ${
                              isCopied ? styles.checkmarkButton : ""
                            }`}
                          >
                            {isCopied ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="12" cy="12" r="12" fill="#4CAF50" />
                                <path
                                  d="M10 15.172L7.75736 12.9294L6.34314 14.3436L10 18L18 10L16.5858 8.58579L10 15.172Z"
                                  fill="white"
                                />
                              </svg>
                            ) : (
                              "📋"
                            )}
                          </button>
                        </div>
                      </li>
                      <li> Paste the generated data into the fields below.</li>
                    </ol>
                  </div>
                  <div className="form-group mb-3">
                    <p className="text-dark">
                      {"API key name"}
                      <span className="text-danger"> *</span>
                    </p>
                    <input
                      name="APIKey"
                      type="text"
                      className="form-control"
                      value={exchangeConnect?.APIKey}
                      onChange={exchangeHandleChange2}
                      onPaste={handlePaste}
                      required={true}
                      disabled={showButton}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <p className="text-dark">
                      {"Private key"}
                      <span className="text-danger"> *</span>
                    </p>
                    <input
                      name="secretKey"
                      type="text"
                      className="form-control"
                      value={exchangeConnect?.secretKey}
                      onChange={exchangeHandleChange2}
                      onPaste={handlePaste}
                      required={true}
                      disabled={showButton}
                    />
                  </div>
                </>
              )} */}
              {
                // props?._id != "65ea4cc9e3924e27cadb19db" &&
                <>
                  <div className="form-group mb-3">
                    <p className="text-dark">
                      {"Enter your API key"}
                      <span className="text-danger"> *</span>
                    </p>
                    <input
                      name="APIKey"
                      type="text"
                      className="form-control"
                      value={exchangeConnect?.APIKey}
                      onChange={(event) => exchangeHandleChange(event)}
                      required={true}
                      disabled={showButton}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <p className="text-dark">
                      {"Enter your Secret key"}
                      <span className="text-danger"> *</span>
                    </p>
                    <input
                      name="secretKey"
                      type="text"
                      className="form-control"
                      value={exchangeConnect?.secretKey}
                      onChange={(event) => exchangeHandleChange(event)}
                      required={true}
                      disabled={showButton}
                    />
                  </div>
                </>
              }

              {props?._id === "6478c12980acd86ca38ad8fd" && (
                <div className="mb-3">
                  <h6>
                    Customer Id{" "}
                    {exchangeConnect?.customerId ? (
                      ""
                    ) : (
                      <span className="required-field">*</span>
                    )}
                  </h6>
                  <input
                    name="customerId"
                    type="text"
                    value={exchangeConnect?.customerId}
                    onChange={(event) => exchangeHandleChange(event)}
                    required={props?._id === "6478c12980acd86ca38ad8fd"}
                    disabled={showButton}
                  />
                </div>
              )}
              {(props?._id === "6478c16580acd86ca38ad917" ||
                props?._id === "6478c16180acd86ca38ad915" ||
                props?._id === "655e2d3a503150358d7dec46") && (
                <div className="mb-3">
                  <h6>
                    Passphrase{" "}
                    {exchangeConnect?.passphrase ? (
                      ""
                    ) : (
                      <span className="required-field">*</span>
                    )}
                  </h6>
                  <input
                    name="passphrase"
                    type="text"
                    value={exchangeConnect?.passphrase}
                    onChange={(event) => exchangeHandleChange(event)}
                    required={
                      props?._id === "647dcb3ac166101b2c2cb166" ||
                      props?._id === "6478c16580acd86ca38ad917" ||
                      props?._id === "6478c16180acd86ca38ad915" ||
                      props?._id === "655e2d3a503150358d7dec46"
                    }
                    disabled={showButton}
                  />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-end gap-3">
                {!showButton ? (
                  <>
                    <Button
                      className="custom-button button-secondary"
                      type="reset"
                      databsdismiss="modal"
                      data-bs-dismiss="modal"
                      text="Back"
                      onClick={() => handleBack()}
                    />
                    <Button
                      text="Submit"
                      type="submit"
                      loading={getLoading}
                      disabled={!isFormValid || getLoading}
                    />
                  </>
                ) : (
                  <SecondryButton
                    text="Auto refreshing..."
                    databsdismiss="modal"
                    disabled={true}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </PopupModal>
    </>
  );
};

export default ExchangeConnect;
