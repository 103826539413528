import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';

// Configure i18next instance
i18n
    .use(initReactI18next) // Initialize react-i18next
    .init({
        resources: {
            en: {
                translation: translationEN // Load English translations
            },
            // Add more languages here as needed
        },
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language if translation not found
        interpolation: {
            escapeValue: false // React already escapes values, so no need for HTML escaping
        }
    });

export default i18n;
