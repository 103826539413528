import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";
import ConverNumber from "components/ConvertNumber";
import {
  BTC,
  ETH,
  Tether,
  Cardano,
  LTC,
  XRP,
  SOL,
  BAT,
  EURO,
  USD,
} from "assets";
import UNKC from "../../../assets/unkCoin.png";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const PortfoliosCard = forwardRef((props, ref) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const target = useRef(null);
  const overlayTriggerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    onScroll: () => {
      setShowTooltip(false);
    },
  }));

  const coinData = [
    {
      coin: "BTC",
      image: <BTC text={"#F7931A"} wd={"40"} ht={"40"} />,
      label: "BTC",
    },
    {
      coin: "ETH",
      image: <ETH text={"#393939"} wd={"40"} ht={"40"} />,
      label: "ETH",
    },
    {
      coin: "XRP",
      image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
      label: "XRP",
    },
    {
      coin: "LTC",
      image: <LTC text2={"#F8F9FB"} text={"#D9D9D9"} wd={"40"} ht={"40"} />,
      label: "LTC",
    },
    {
      coin: "SOL",
      image: (
        <SOL text={"url(#paint0_linear_1601_37368)"} wd={"40"} ht={"40"} />
      ),
      label: "SOL",
    },
    {
      coin: "USDT",
      image: <Tether text={"#50AF95"} wd={"40"} ht={"40"} />,
      label: "USDT",
    },
    {
      coin: "ADA",
      image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
      label: "ADA",
    },
    {
      coin: "BAT",
      image: (
        <div className="coinHeightWeight">
          <BAT
            text={"url(#paint0_linear_1601_37368)"}
            wd={"20"}
            ht={"20"}
            className="mb-5"
          />
        </div>
      ),
      label: "BAT",
    },
    {
      coin: "EUR",
      image: (
        <div className="coinHeightWeight">
          <EURO wd={"25"} ht={"25"} />
        </div>
      ),
      label: "EUR",
    },
    {
      coin: "GBP",
      image: (
        <div className="UNKcoinHeightWeight">
          <img src={UNKC} className="UNKcoinHeightWeight" loading="lazy" />
        </div>
      ),
      label: "GBP",
    },
    {
      coin: "USD",
      image: (
        <div className="coinHeightWeight">
          <USD wd={"20"} ht={"20"} />
        </div>
      ),
      label: "USD",
    },
    {
      coin: "USDCkkk",
      image: (
        <div className="coinHeightWeight">
          <USD wd={"20"} ht={"20"} />
        </div>
      ),
      label: "USDC",
    },
    {
      coin: "LINK",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img src={UNKC} className="UNKcoinHeightWeight" />
        </div>
      ),
      label: "LINK",
    },
    {
      coin: "ZEC",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img src={UNKC} className="UNKcoinHeightWeight" />
        </div>
      ),
      label: "ZEC",
    },
    {
      coin: "BCH",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img src={UNKC} className="UNKcoinHeightWeight" />
        </div>
      ),
      label: "BCH",
    },
    {
      coin: "UNK",
      image: (
        <div className="UNKcoinHeightWeight my-2 mx-2">
          <img src={UNKC} className="UNKcoinHeightWeight" />
        </div>
      ),
      label: "",
    },
  ];
  function capitalizeFirstLetter(str) {
    return str === undefined || str === ""
      ? ""
      : `${str.substring(0, 1).toUpperCase()}${str?.substring(1)}`;
  }

  return (
    <>
      <div className="col-lg-3 col-md-3 col-sm-3">
        <div className="card_item ms-3 p-3 d-flex align-items-center flex-wrap ">
          <div className="d-flex align-items-center justify-content-start w-100">
            {props.exchanges?.map((item, index) => (
              <div className="w-50n1st">
                {" "}
                {item.name === props.exchangeName && (
                  <div className="exchangeIconsPortfolio" key={index}>
                    <img
                      src={item.icon}
                      alt=""
                      width="auto"
                      height={15}
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="d-grid">
              <span className="ClientName text-break">
                {props?.name?.length > 10
                  ? props?.name?.slice(0, 10) + "..."
                  : props?.name || "Retailer"}
              </span>
              <span className="width-max">{props?.exchangeName}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-9 col-md-9 col-sm-9">
        <div className="row card_item mx-3 p-3 align-items-center">
          <div className="col-lg-4 col-md-4 col-sm-4 position-relative">
            {props?.coins?.length > 0 && (
              <OverlayTrigger
                placement="right"
                show={showTooltip}
                target={target.current}
                ref={overlayTriggerRef}
                delay={{ hide: 1, show: 1 }}
                overlay={(overlayProps) => (
                  <Tooltip
                    {...overlayProps}
                    className="custom-tooltip-react"
                    style={{
                      backgroundColor: "white",
                      padding: "1rem",
                      color: "white",
                      border: "1px solid #E5E6E9",
                      borderRadius: "12px",
                      opacity: "1",

                      ...overlayProps.style,
                    }}
                  >
                    <div
                      className="row justify-content-start align-items-center bg-white"
                      style={{ color: "black" }}
                    >
                      {props?.coins?.map((item, index) => (
                        <div
                          key={index}
                          className={
                            props?.coins?.length <= 4 ? "col-6" : "col-4"
                          }
                        >
                          {coinData.map((coin) => (
                            <React.Fragment key={coin.coin}>
                              {item === coin?.coin && (
                                <div className="d-flex justify-content-center gap-2">
                                  <div className="">
                                    {coin.image}
                                    <div className="d-flex justify-content-center gap-2">
                                      {coin.label}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                          {coinData.every((coin) => item !== coin.coin) && (
                            <div className="d-flex justify-content-center gap-2">
                              <div className="">
                                {
                                  coinData.find((coin) => coin.coin === "UNK")
                                    ?.image
                                }
                                <div className="d-flex justify-content-center gap-2">
                                  {/* Label for 'UNK' coin, add as needed */}
                                </div>
                                <div>{item}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </Tooltip>
                )}
              >
                <div
                  className="d-flex align-items-center curser-pointer"
                  ref={target}
                  onMouseEnter={() => setShowTooltip(true)} // Show tooltip on hover
                  onMouseLeave={() => setShowTooltip(false)}
                  onScroll={() => setShowTooltip(false)}
                >
                  {props?.coins?.slice(0, 4)?.map((item, index) => {
                    return (
                      <>
                        {coinData?.map((coin, i) => {
                          return <>{coin.coin === item && coin.image} </>;
                        })}
                        {coinData.every((coin) => item !== coin.coin) && (
                          <div className="d-flex justify-content-center gap-2">
                            <div className="">
                              {
                                coinData.find((coin) => coin.coin === "UNK")
                                  ?.image
                              }
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                  {props?.coins?.length > 4 && (
                    <h5 className="d-flex">
                      {props?.coins?.length > 4
                        ? "+" + (props?.coins?.length - 4)
                        : ""}
                    </h5>
                  )}
                </div>
              </OverlayTrigger>
            )}
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="clientsBalance ps-3 gap-2">
              <span className="client-balance d-flex">
                ${props?.balance?.toFixed(2)}{" "}
              </span>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="clientsBalance ps-3 gap-2">
              <span className="client-balance d-flex">
                $
                <ConverNumber
                  data={
                    !props?.amount_invested
                      ? "0"
                      : props?.amount_invested?.toFixed(2)
                  }
                />
              </span>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="clientsBalance ps-3 gap-2">
              <span className="client-balance d-flex">
                $
                <ConverNumber
                  data={!props?.available ? "0" : props?.available?.toFixed(2)}
                />
              </span>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="row align-items-center">
              <div
                className={`col-lg-3 ${
                  props?.risk != "" ||
                  props?.risk != undefined ||
                  props?.risk != null
                    ? "risk_width"
                    : ""
                }`}
              >
                <span className="d-flex ms-2">
                  {" "}
                  {props?.risk != "" ||
                  props?.risk != undefined ||
                  props?.risk != null ? (
                    <img
                      className="ML-5 pe-2"
                      src={
                        props?.risk
                          ? require(`assets/risk${capitalizeFirstLetter(
                              props?.risk?.trim()
                            )}.svg`)
                          : ""
                      }
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}{" "}
                  {capitalizeFirstLetter(props?.risk?.trim())}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PortfoliosCard;
