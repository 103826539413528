import React, { useEffect, useState } from "react";
import "./index.scss";
import ClientsCard from "./ClientsCard";
import { allConnectedUserListClient } from "features/Management/managementAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { increaseConnectedUserCount } from "features/Management/managementSlice";

const filter = [
  { label: "All Clients", name: 'All Clients' },
  { label: "Excellent Health", name: 'Excellent Health' },
  { label: "Good Health", name: 'Good Health' },
  { label: "Average Health", name: 'Average Health' },
  { label: "Poor Health", name: 'Poor Health' },
  { label: 'Very Poor', name: 'Very Poor Health' },
  { label: "High Risk", name: 'High Risk' },
  { label: "Medium Risk", name: 'Medium Risk' },
  { label: "Low Risk", name: 'Low Risk' }
];

function ManagementClients() {
  const { userToken } = useSelector((state) => state.auth);
  const { allConnectedUserListClientData } = useSelector((state) => state?.management);
  const dispatch = useDispatch();
  const location = useLocation()
  const [loadingText, setloadingText] = useState(false)
  const { data } = location.state || {}
  const [pageNo, setpageNo] = useState(1)
  const [getClientData, setClientData] = useState([] );
  const [getFilterData, setFilterData] = useState( []);
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchingData, setFetchingData] = useState(false);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    if(pageNo===1){

      setFetchingData(true);
    }else{
     setloadingText(true) 
    }
    const fetchClientData= ()=>{
      const payload ={ page:pageNo}
      dispatch(allConnectedUserListClient({ userToken ,payload})).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          let data= [...getClientData, ...res?.payload?.userData]
          dispatch(increaseConnectedUserCount(data));
          setClientData([...getClientData, ...res?.payload?.userData]);
          setloadingText(false)
          setFetchingData(false);
          setLoading(false)
          if(res?.payload?.userData?.length===5){
            setpageNo(prev=> prev+1)
          }
        }
      });
    }
    
    fetchClientData()
  }, [pageNo]);

  const [getFilterDropdown, setFilterDropdown] = useState({ label: data === undefined ? "All Clients" : data, name: data === undefined ? "All Clients" : data });
  function capitalizeFirstLetter(str) {
    return (str === undefined || str === '') ? '' : (str.substring(0, 1).toUpperCase() + str?.substring(1));
  }
  useEffect(() => {
    const filteredData = getClientData?.filter((item) => item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
    const data = getClientData?.filter((i) => capitalizeFirstLetter(i?.risk?.trim()) === getFilterDropdown?.label?.split(" ")[0]);
    const data1 = getClientData?.filter((i) => i?.health === getFilterDropdown?.label);
    const data2 = [...data, ...data1]
    setFilterData(getFilterDropdown?.label === "All Clients" ? filteredData : data2.filter((item) => item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())));
  }, [searchQuery, getFilterDropdown, getClientData]);

  const filterDataByRisk = () => {
    if (getFilterDropdown?.label === "All Clients") {
      return ( getClientData)?.filter((item) => item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || item?.exchangeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
    } else {
      return (getClientData)?.filter(e => capitalizeFirstLetter(e?.risk?.trim()) === getFilterDropdown?.label?.split(" ")[0])?.filter((item) => item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || item?.exchangeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
    }
  };

  const filterDataAll = filterDataByRisk()

  return (
    <>
      <div className={`Client-Search-Sticky client-search-container d-flex col-12 align-items-center mar-l-32  gap-2`}>
        <div className="position-relative">
          <img src={require("assets/searchIcon.svg").default} alt="search-icon" className="position-absolute ps-3 pt-3" loading='lazy'/>
          <input type="search" placeholder="Search for clients" className="ps-5 header_search" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div className={`btn-group MultiSelectDropDown z-index-2`}>
          <button className="btn dropdown-toggle d-flex justify-content-between align-items-center" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            <div className={`me-3`}>{`${getFilterDropdown?.name} (${filterDataAll?.length})`} </div>
            <img src={require("assets/arrowDown.svg").default} alt="" loading='lazy'/>
          </button>
          <ul className="dropdown-menu pt-0 w-max z-index-2">
            {filter.map((item, index) => (
              <li key={index} className={`dropdown-item py-2 d-flex gap-2 align-items-center ${filter?.length - 1 === index ? "" : "border-bottom"}`}
                onClick={() => setFilterDropdown(item)} >
                <input className="checkbox selectContainer" type="checkbox" checked={item?.label === getFilterDropdown?.label} value={item?.label} readOnly />
                {item.img} {item?.name}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {filterDataAll?.length > 0 ? <div className="restable">
        <div className="custom-table-res">
          <table className="client_Upper_labels w-100 " cellPadding={0} cellSpacing={0} border={0}>
            <tr>
              <td>
                <table className=" w-100 inner-table" cellPadding={0} cellSpacing={0} border={0}>
                  <tr>
                    <td>
                      <h6 className="h6-One-Grey "> Clients <img className="ML-5" src={require(`assets/downArrow.svg`).default} alt="" loading='lazy'/> </h6>
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table className=" w-100 inner-table" cellPadding={0} cellSpacing={0} border={0}>
                  <tr>
                    <td>
                      <h6 className="h6-One-Grey   ">Portfolio</h6>
                    </td>
                    <td>
                      <h6 className="h6-One-Grey">Balance<img className="ML-5" src={require(`assets/downArrow.svg`).default} alt="" loading='lazy'/> </h6>
                    </td>
                    <td>
                      <h6 className="h6-One-Grey  risk_heading"> <img className=" pe-3" src={require(`assets/riskLow.svg`).default} alt="" loading='lazy'/> Risk <img className="ML-5" src={require(`assets/downArrow.svg`).default} alt="" loading='lazy'/> </h6>
                    </td>
                    <td><h6 className="h6-One-Grey  risk_heading"><div>Droids</div> <img className="ML-5" src={require(`assets/downArrow.svg`).default} alt="" loading='lazy'/> </h6></td>
                    <td><h6 className="h6-One-Grey col-lg-3 risk_heading"> Health <img className="ML-5" src={require(`assets/downArrow.svg`).default} alt="" loading='lazy'/> </h6></td>
                  </tr>
                </table>
              </td>
              <td>
                <table className=" w-100 inner-table" cellPadding={0} cellSpacing={0} border={0}>
                  <tr>
                    <td>
                      <h6 className="h6-One-Grey">Open Actions</h6>
                    </td>
                  </tr>
                </table>
              </td></tr>
          </table>
          <>
            <div className="broker-list-container">
              <div className="broker-list ">
                {filterDataAll?.map((item, index) =>
                  <div className="mb-2" key={index}>
                    <div className={`d-flex  ${index === getFilterData?.length - 1 ? '' : ''}`}>
                      <span className="d-flex" style={{ paddingTop: "40px", width: "10px" }}>
                        {index + 1}
                      </span>
                      <ClientsCard {...item} number={index} />
                    </div>
                  </div>)}
              </div>
            </div>
                  {loadingText && <div style={{display:'flex', justifyContent:"center", fontWeight:'bold'}}>Loading more data ...</div>}

          </>
        </div>
      </div> : loading ? <div className="ClientPageLoading "> <div className="loaderProfile" role="status"> </div> </div> : <div className="d-flex justify-content-center "> Data not found </div>
}
    </>
  );
}

export default ManagementClients;
