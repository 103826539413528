import React from 'react'

const DroidDoughnutChartLabels = ({ Percent, PercentName }) => {
    return (
        <div className='d-flex flex-column'>
            <span className='PercentageProfitablePercent'>
            {typeof Percent === 'number' && !Number.isInteger(Percent) ? Percent.toFixed(2) : Percent}%
            </span>
            <span className='PercentageProfitableLabel text-muted text-center'>{PercentName}</span>
        </div>
    )
}

export default DroidDoughnutChartLabels