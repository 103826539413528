import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { PinIcon } from "assets";
import { useHookstate } from "@hookstate/core";
import { bouceState } from "./../../globalStore/GlobalStore";
import SecondryButton from "components/Button/SecondryButton";
import Card from "screens/ManagementScreen/ManagementTask/Card";
import { useNavigate } from "react-router-dom";
import { getAllDroids } from "features/Droids/droidsAction";
import {
  deleteMessageFromActivity,
  getActivityLog,
  postUpdatePin,
} from "features/ActivityLog/ActivityLogAction";
import { DroidApproval } from "features/Management/managementAction";
import Loader from "components/Loader";
import Button from "components/Button";
import { acceptRequest } from "features/AcceptRequest/AcceptRequest";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";

function ActivityDetails() {
  const toastConfiguration = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  const isBouceState = useHookstate(bouceState);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const [getShowImg, setShowImg] = useState({ id: "" });
  const [approvalLoading, setapprovalLoading] = useState(false);
  const { userToken } = useSelector((state) => state.auth);
  const { getAllActivityLogInfo } = useSelector((state) => state?.activityLog);
  const [ActivityLogData, setActivityLogData] = useState(
    getAllActivityLogInfo?.data?.length > 0 ? getAllActivityLogInfo?.data : []
  );
  const [getFlag, setFlag] = useState(true);
  const [getDriodApprove, setDriodApprove] = useState(false);
  const [getCallActivity, setCallActivity] = useState(true);
  const [getLoading, setLoading] = useState(true);
  const [loadActivityLog, setLoadActivityLog] = useState(false);
  const [getLoadingData, setLoadingData] = useState({ approvel: false });
  const [getRedDot, setRedDot] = useState();
  const [getRead, setRead] = useState(false);
  const [getDataPresent, setDataPresent] = useState(false);
  const [acceptandRejectedUsers, SetAcceptandRejectedUsers] = useState([]);
  const [isUnreadArray, setIsUnreadArray] = useState([]);

  const iconsData = {
    iconMsg: require("assets/mail.svg").default,
    iconNotification: require("assets/notification-bell.svg").default,
    iconDroid: require("assets/droid.svg").default,
    iconInvite: require("assets/inviteclientsIcon.svg").default,
  };

  const handleChatPin = (pinned, id) => {
    const data = [...ActivityLogData];
    setActivityLogData(data?.sort((a) => (a._id !== id ? 1 : -1)));
    let mapData = [];
    ActivityLogData?.map((item) => {
      if (item?._id == id) {
        item = { ...item, isPinned: !pinned };
        mapData?.push(item);
      } else {
        mapData?.push(item);
      }
    });

    mapData.sort((a, b) => {
      if (a.isPinned === b.isPinned) {
        return 0;
      } else if (a.isPinned) {
        return -1;
      } else {
        return 1;
      }
    });

    setActivityLogData(mapData);
    const payload = { isPinned: !pinned, id: id };
    dispatch(postUpdatePin({ userToken, payload })).then((res) => {
      if (res.meta?.requestStatus === "fulfilled") {
        setFlag(!getFlag);
      }
    });
  };

  const fetchActivityLog = () => {
    setLoadActivityLog(true);
    setActivityLogData([]);
    dispatch(getActivityLog({ userToken }))
      .then((res) => {
        if (res.meta?.requestStatus === "fulfilled") {
          setActivityLogData(res.payload?.data);
          setLoadActivityLog(false);
          setRedDot(res.payload?.newNotification);
          setIsUnreadArray(
            res.payload?.data
              .filter((item) => !item.isread)
              .map((item) => item._id)
          );
        } else {
          setLoadActivityLog(false);
          Sentry.captureException(res, {
            extra: {
              customData: "ActivityDetails--> Line 105",
            },
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "ActivityDetails--> Line 112",
          },
        });
      });
  };

  useEffect(() => {
    if (getLoading || getFlag || getCallActivity || getRead) {
      setCallActivity(false);
      setFlag(false);
      setLoading(false);
      setRead(false);
    } else {
      //
    }
  }, [getLoading, getFlag, getCallActivity, getRead]);

  useEffect(() => {
    fetchActivityLog();
  }, []);

  useEffect(() => {
    setDataPresent(ActivityLogData.length > 0);
  }, [ActivityLogData]);

  const acceptHandleClicked = (item) => {
    const { senderId } = item;
    setapprovalLoading(true);
    dispatch(acceptRequest({ userToken, senderId, status: "2" }))
      .then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          toast.success("Request Accepted", {
            toastId: 45,
            ...toastConfiguration,
          });
          dispatch(
            deleteMessageFromActivity({ userToken, id: item?._id })
          ).then((response) => {
            if (response.meta && response.meta.requestStatus === "fulfilled") {
              const arrayOfObjects = ActivityLogData.filter(
                (obj) => obj._id !== item?._id
              );
              setActivityLogData(arrayOfObjects);
              setCallActivity(!getCallActivity);
            }
          });
          setapprovalLoading(false);
          SetAcceptandRejectedUsers([...acceptandRejectedUsers, senderId]);
        } else {
          Sentry.captureException(res, {
            extra: {
              customData: "ActivityDetails--> Line 162",
            },
          });
          setapprovalLoading(false);
          sendApprovalHandle(false);
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "ActivityDetails--> Line 173",
          },
        });
        setapprovalLoading(false);
      });
  };

  const handleChatNotification = (item) => {
    navigate("/userchat", {
      state: { userData: item },
    });
    dispatch(deleteMessageFromActivity({ userToken, id: item?._id }))
      .then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          const arrayOfObjects = ActivityLogData.filter(
            (obj) => obj._id !== item?._id
          );
          setActivityLogData(arrayOfObjects);
          setCallActivity(!getCallActivity);
        } else {
          setDriodApprove({ loading: false });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "ActivityDetails--> Line 266",
          },
        });
      });
  };
  const rejectHandleClicked = (senderId) => {
    dispatch(acceptRequest({ userToken, senderId, status: "0" }))
      .then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          toast.error("Request Rejected", {
            toastId: 46,
            ...toastConfiguration,
          });
          SetAcceptandRejectedUsers([...acceptandRejectedUsers, senderId]);
        } else {
          Sentry.captureException(res, {
            extra: {
              customData: "ActivityDetails--> Line 192",
            },
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "ActivityDetails--> Line 200",
          },
        });
      });
  };

  const sendApprovalHandle = (item) => {
    setDriodApprove({ id: item?._id, loading: true });
    setLoadingData((prevObject) => ({ ...prevObject, approvel: true }));
    const payload = {
      droidId: item?.relationId,
      portfolioId: item?.portfolioRelationId,
      status: "approved",
    };
    dispatch(DroidApproval({ userToken, payload }))
      .then((response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setLoadingData((prevObject) => ({ ...prevObject, approvel: false }));
          setDriodApprove({ id: item?._id, loading: false });
          localStorage.setItem("droidApproved", true);
          dispatch(getAllDroids({ userToken }));
          dispatch(deleteMessageFromActivity({ userToken, id: item?._id }))
            .then((response) => {
              if (
                response.meta &&
                response.meta.requestStatus === "fulfilled"
              ) {
                const arrayOfObjects = ActivityLogData.filter(
                  (obj) => obj._id !== item?._id
                );
                setActivityLogData(arrayOfObjects);
                setCallActivity(!getCallActivity);
              } else {
                Sentry.captureException(response, {
                  extra: {
                    customData: "ActivityDetails--> Line 232",
                  },
                });
                setDriodApprove({ loading: false });
              }
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: {
                  customData: "ActivityDetails--> Line 241",
                },
              });
            });
        } else if (
          response.payload ===
          "The droid has been deleted because date and time has expired."
        ) {
          toast.error(response.payload);
          setLoadingData((prevObject) => ({ ...prevObject, approvel: false }));
          dispatch(deleteMessageFromActivity({ userToken, id: item?._id }))
            .then((response) => {
              if (
                response.meta &&
                response.meta.requestStatus === "fulfilled"
              ) {
                const arrayOfObjects = ActivityLogData.filter(
                  (obj) => obj._id !== item?._id
                );
                setActivityLogData(arrayOfObjects);
                setCallActivity(!getCallActivity);
              } else {
                setDriodApprove({ loading: false });
              }
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: {
                  customData: "ActivityDetails--> Line 266",
                },
              });
            });
        } else {
          toast.error(response.payload);
          setLoadingData((prevObject) => ({ ...prevObject, approvel: false }));
          setDriodApprove({ loading: false });
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            customData: "ActivityDetails--> Line 278",
          },
        });
      });
  };

  const handleMarkAsRead = () => {
    setLoadActivityLog(true);
    const payload = { ids: isUnreadArray };
    if (getRedDot && isUnreadArray?.length > 0) {
      dispatch(postUpdatePin({ userToken, payload, read: "read" }))
        .then((res) => {
          if (res.meta?.requestStatus === "fulfilled") {
            setRedDot(false);
            setActivityLogData([]);
            setIsUnreadArray([]);
          } else
            Sentry.captureException(res, {
              extra: {
                customData: "ActivityLog--> Line 70",
              },
            });
          setLoadActivityLog(false);
        })
        .catch((error) => {
          setLoadActivityLog(false);
          Sentry.captureException(error, {
            extra: {
              customData: "ActivityLog--> Line 77",
            },
          });
        });
    }
  };

  function isAnyUnread(array) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].isread) {
        return true;
      } else if (array[i].isPinned) {
        return true;
      }
    }
    return false;
  }

  let AnyUnread = isAnyUnread(ActivityLogData);

  return (
    <>
      {location?.pathname !== "/dashboard" && (
        <div className="row justify-content-between align-items-center">
          <p className="h4 col-6 fw-bold text-dark">Activity Log</p>
          {!loadActivityLog && isUnreadArray?.length > 0 && (
            <small className="col-6 align-right">
              <span
                className="text-primary text-decoration-underline curser-pointer px-2"
                onClick={handleMarkAsRead}
              >
                Mark all as read
              </span>
            </small>
          )}
        </div>
      )}
      {loadActivityLog && (
        <div className="d-flex justify-content-center align-items-center pb-3">
          <Loader />
        </div>
      )}
      {!loadActivityLog && !getDataPresent && (
        <div className="d-flex justify-content-center align-items-center pb-3">
          Data not found
        </div>
      )}
      {getDataPresent && ActivityLogData && !AnyUnread ? (
        <div className="d-flex justify-content-center align-items-center pb-3">
          No record found
        </div>
      ) : (
        getDataPresent &&
        ActivityLogData?.map(
          (item, index) =>
            (!item.isread || item.isPinned) && (
              <div key={index} className="mb-3">
                {item.type === "question" && (
                  <div className="d-flex gap-2 pb-3 border-bottom">
                    <div className="me-2">
                      <div
                        className={`d-flex icon-Container animated`}
                        id={isBouceState.get() && "bounce"}
                      >
                        {item?.isDayAgo && (
                          <div className="icon-Container_after"></div>
                        )}
                        <img
                          src={iconsData.iconMsg}
                          alt="email"
                          className="svgToBlue icon"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex mb-2 justify-content-between gap-5">
                        <div>
                          <p className="font-weight-bold text-black">
                            {item.content}
                          </p>
                          <span className="activityLogDiscription text-break">
                            {item.subContent}
                          </span>
                        </div>
                        <div
                          onClick={() => handleChatPin(item.isPinned, item._id)}
                          className="cursor"
                        >
                          <PinIcon pin={item.isPinned} />
                        </div>
                      </div>
                      <div className="text-secondary d-flex justify-content-start align-items-center mb-1">
                        <span style={{ marginRight: ".25rem" }}>
                          Last Updated:
                        </span>
                        <span className="fw-bold">
                          {moment
                            .unix(item?.updatedAt)
                            .format("MMM DD, YYYY @ hh:mm A")}
                        </span>
                      </div>
                      <Card
                        getShowImg={getShowImg}
                        setShowImg={setShowImg}
                        {...item}
                        index={index}
                        activityLog={true}
                        loadActivityLog={loadActivityLog}
                        setLoading={setLoading}
                        setActivityLogData={setActivityLogData}
                        ActivityLogData={ActivityLogData}
                      />
                    </div>
                  </div>
                )}
                {item.type === "droid" && (
                  <div className="d-flex gap-2 pb-3 border-bottom">
                    <div className="me-2">
                      <div
                        className={`d-flex icon-Container animated`}
                        id={isBouceState.get() && "bounce"}
                      >
                        {item?.isDayAgo && (
                          <div className="icon-Container_after"></div>
                        )}
                        <img
                          src={iconsData.iconDroid}
                          alt="email"
                          className="svgToBlue icon"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex mb-2 justify-content-between gap-5">
                        <div>
                          <p className="font-weight-bold text-black">
                            {item?.droidName}
                          </p>
                          <span className="activityLogDiscription text-break">
                            {item?.content}
                          </span>
                        </div>
                        <div
                          onClick={() => handleChatPin(item.isPinned, item._id)}
                          className="cursor"
                        >
                          {" "}
                          <PinIcon pin={item.isPinned} />{" "}
                        </div>
                      </div>
                      <div className="text-secondary d-flex justify-content-start align-items-center">
                        <span style={{ marginRight: ".25rem" }}>
                          Last Updated:
                        </span>
                        <span className="fw-bold">
                          {moment
                            .unix(item?.updatedAt)
                            .format("MMM DD, YYYY @ hh:mm A")}
                        </span>
                      </div>
                      {!(item?.senderId === localStorage.getItem("userID")) && (
                        <div className="d-flex justify-content-between">
                          <SecondryButton
                            text={"Approve"}
                            disabled={
                              getDriodApprove?.id === item?._id &&
                              getDriodApprove?.loading
                            }
                            loading={
                              getDriodApprove?.id === item?._id &&
                              getDriodApprove?.loading
                            }
                            handleClick={() => sendApprovalHandle(item)}
                            className="mt-3"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {item.type === "delete_droid" && (
                  <div className="d-flex gap-2 pb-3 border-bottom">
                    <div className="me-2">
                      <div
                        className={`d-flex icon-Container animated`}
                        id={isBouceState.get() && "bounce"}
                      >
                        {item?.isDayAgo && (
                          <div className="icon-Container_after"></div>
                        )}
                        <img
                          src={iconsData.iconMsg}
                          alt="email"
                          className="svgToBlue icon"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex mb-2 justify-content-between gap-5">
                        <div>
                          <p className="font-weight-bold text-black">
                            {item?.droidName}
                          </p>
                          <span className="activityLogDiscription text-break">
                            {item?.content}
                          </span>
                        </div>
                        <div
                          onClick={() => handleChatPin(item.isPinned, item._id)}
                          className="cursor"
                        >
                          <PinIcon pin={item.isPinned} />
                        </div>
                      </div>
                      <div className="text-secondary d-flex justify-content-start align-items-center">
                        <span style={{ marginRight: ".25rem" }}>
                          Last Updated:
                        </span>
                        <span className="fw-bold">
                          {moment
                            .unix(item?.updatedAt)
                            .format("MMM DD, YYYY @ hh:mm A")}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {item.type === "message" && (
                  <div className="d-flex gap-2 pb-3 border-bottom">
                    <div className="me-2">
                      <div
                        className={`d-flex icon-Container animated`}
                        id={isBouceState.get() && "bounce"}
                      >
                        {item?.isDayAgo && (
                          <div className="icon-Container_after"></div>
                        )}
                        <img
                          src={iconsData.iconMsg}
                          alt="email"
                          className="svgToBlue icon"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex mb-2 justify-content-between gap-5">
                        <div>
                          <p className="font-weight-bold text-black">
                            {item?.content}
                          </p>
                          <span className="activityLogDiscription text-break">
                            {item?.subContent}
                          </span>
                        </div>
                        <div
                          onClick={() => handleChatPin(item.isPinned, item._id)}
                          className="cursor"
                        >
                          {" "}
                          <PinIcon pin={item.isPinned} />{" "}
                        </div>
                      </div>
                      <div className="text-secondary d-flex justify-content-start align-items-center mb-2">
                        <span style={{ marginRight: ".25rem" }}>
                          Last Updated:
                        </span>
                        <span className="fw-bold">
                          {moment
                            .unix(item?.updatedAt)
                            .format("MMM DD, YYYY @ hh:mm A")}
                        </span>
                      </div>
                      <SecondryButton
                        handleClick={() => handleChatNotification(item)}
                        text="Go to chat"
                      />
                    </div>
                  </div>
                )}
                {item.type === "connectionRequest" && (
                  <div className="d-flex gap-2 pb-3 border-bottom">
                    <div className="me-2">
                      <div
                        className={`d-flex icon-Container animated`}
                        id={isBouceState.get() && "bounce"}
                      >
                        {item?.isDayAgo && (
                          <div className="icon-Container_after"></div>
                        )}
                        <img
                          src={iconsData.iconInvite}
                          alt="email"
                          className="svgToBlue icon"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex mb-2 justify-content-between gap-5">
                        <div>
                          <p className="font-weight-bold text-black">
                            {item?.content}
                          </p>
                          <span className="activityLogDiscription text-break">
                            {item?.subContent}
                          </span>
                        </div>
                        <div
                          onClick={() => handleChatPin(item.isPinned, item._id)}
                          className="cursor"
                        >
                          <PinIcon pin={item.isPinned} />
                        </div>
                      </div>
                      <div className="text-secondary d-flex justify-content-start align-items-center mb-2">
                        <span style={{ marginRight: ".25rem" }}>
                          Last Updated:
                        </span>
                        <span className="fw-bold">
                          {moment
                            .unix(item?.updatedAt)
                            .format("MMM DD, YYYY @ hh:mm A")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <Button
                          onClick={() => {
                            acceptHandleClicked(item);
                          }}
                          text="Accept"
                          disabled={acceptandRejectedUsers.includes(
                            item.senderId
                          )}
                          loading={approvalLoading}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
        )
      )}
    </>
  );
}

export default ActivityDetails;
