import React from "react";

function UsdtIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="81"
      fill="none"
      viewBox="0 0 81 81"
    >
      <path
        fill="#F6F8FC"
        d="M78.846 50.32C73.51 71.735 51.818 84.812 30.363 79.47 8.949 74.118-4.107 52.427 1.23 31.011 6.567 9.597 28.244-3.48 49.647 1.86c21.468 5.275 34.524 27.031 29.2 48.46z"
      ></path>
      <path
        fill="#009393"
        fillRule="evenodd"
        d="M25.325 23.869h29.951c.715 0 1.375.37 1.732.97l8.726 14.71a1.894 1.894 0 01-.328 2.344L41.388 64.912a2.042 2.042 0 01-2.808 0L14.594 41.924a1.894 1.894 0 01-.299-2.391l9.328-14.74a2.012 2.012 0 011.702-.924zm26.084 6.567v4.127h-8.532v2.861c5.992.306 10.488 1.562 10.521 3.068v3.138c-.033 1.505-4.529 2.761-10.52 3.067v7.023h-5.666v-7.023c-5.993-.306-10.488-1.562-10.522-3.067v-3.138c.034-1.506 4.53-2.762 10.522-3.067v-2.862H28.68v-4.127h22.73zM40.045 44.54c6.394 0 11.74-1.062 13.047-2.48-1.109-1.203-5.121-2.15-10.215-2.41v2.997a55.679 55.679 0 01-5.665 0v-2.996c-5.094.26-9.106 1.206-10.215 2.409 1.308 1.418 6.653 2.48 13.047 2.48z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UsdtIcon;
