import ConvertDollar from "components/ConvertNumber/ConvertDollar";
import Loader from "components/Loader";
import UniversalTooltip from "components/Tooltips/UniversalTooltip";
import React, { useRef } from "react";
import { useLocation, Link } from "react-router-dom";

const ClientSubHeader = (props) => {
  const location = useLocation();
  const shouldNavigate = location?.pathname !== "/client-Dashboard-Details";
  const parentRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleParentFocus = () => {
    // Remove focus from the tooltip when parent receives focus
    tooltipRef.current.blur();
  };

  return (
    <>
      {shouldNavigate ? (
        <div
          className={`d-flex gap-4 align-items-baseline retailer-subHeading `}
        >
          <div className="d-flex gap-2">
            <div className="P6">My Broker</div>
            {!props?.connectedBroker ? (
              <Loader className="header-loader" />
            ) : (
              <div className="dropdown ">
                <div
                  className="header-retailer dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {props?.connectedBroker?.length > 0 &&
                    [...props?.connectedBroker].sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )[0].name}{" "}
                  {props?.connectedBroker?.length > 1 ? (
                    <img
                      src={require("assets/arrowDown.svg").default}
                      alt=""
                      className="mx-1"
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}{" "}
                </div>
                <div
                  className="dropdown-menu brokerNameDropdown dropdownScroll"
                  aria-labelledby="dropdownMenu2"
                >
                  {[...props?.connectedBroker]
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => (
                      <Link to={`/brokersProfile/${item?._id}`}>
                        <div className="dropdown-item" type="button">
                          {item?.name}
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className="P6">Exchange </div>
            {!props?.exchange ? (
              <Loader className="header-loader" />
            ) : (
              <>
                <div className="dropdown ">
                  <div
                    className="header-retailer dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {props?.exchange?.length === 0
                      ? "0"
                      : props?.exchange?.length}
                    {props?.exchange?.length > 0 ? (
                      <img
                        src={require("assets/arrowDown.svg").default}
                        alt=""
                        className="ML-6"
                        loading="lazy"
                      />
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  <div
                    className="dropdown-menu brokerNameDropdown dropdownScroll"
                    aria-labelledby="dropdownMenu2"
                  >
                    {props?.exchange?.map((item, index) => (
                      <Link
                        to="/management/portfolios"
                        className="px-3 dropdown-item"
                        type="button"
                      >
                        {item?.exchangeName}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="tooltip-Exchange">
                  <Link to={"/exchange"}>
                    {" "}
                    <span
                      className="header-retailer text-blue cursor "
                      flow="down"
                    >
                      Connect {props?.exchange?.length > 0 ? "more" : ""}
                    </span>
                  </Link>
                  <div className="custom-tooltip-Exchange">
                    {" "}
                    See the exchange list and select the best exchange for needs{" "}
                  </div>
                </div>
              </>
            )}
          </div>
          <div ref={parentRef} onFocus={handleParentFocus} tabIndex={0}>
            <Link
              tabIndex={-1}
              to={!props?.connectedBroker ? "/dashboard" : "/profile"}
            >
              <div className="d-flex gap-2 align-items-center universal-tooltip-parrent">
                <div className="P6">Limits trade</div>
                {!props?.connectedBroker ? (
                  <Loader className="header-loader" />
                ) : (
                  <>
                    <div className="d-flex gap-1 align-items-center mr-2">
                      <div className="header-retailer-buy">Buy</div>
                      <div className="header-retailer">
                        {!props?.limitTrade?.limits?.buy ? (
                          "0"
                        ) : (
                          <ConvertDollar
                            data={props?.limitTrade?.limits?.buy}
                          />
                        )}
                      </div>
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                      <div className="header-retailer-sell">Sell</div>
                      <div className="header-retailer">
                        {!props?.limitTrade?.limits?.sell ? (
                          "0"
                        ) : (
                          <ConvertDollar
                            data={props?.limitTrade?.limits?.sell}
                          />
                        )}
                      </div>
                    </div>
                    <UniversalTooltip
                      tooltipRef={tooltipRef}
                      content={"Go to Profile"}
                      className={"gotoProfile"}
                    />
                  </>
                )}
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <div
          className={`d-flex gap-4 align-items-baseline retailer-subHeading `}
        >
          <div className="d-flex gap-2">
            <div className="P6">My Broker</div>
            <div className="dropdown ">
              {!props?.connectedBroker ? (
                <Loader className="header-loader" />
              ) : (
                <div
                  className="header-retailer dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {props?.connectedBroker?.length > 0
                    ? [...props?.connectedBroker].sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )[0]?.name
                    : "No broker added"}
                  <img
                    src={require("assets/arrowDown.svg").default}
                    alt=""
                    className="mx-1"
                    loading="lazy"
                  />
                </div>
              )}
              {props?.connectedBroker?.length > 0 ? (
                <div
                  className="dropdown-menu brokerNameDropdown dropdownScroll"
                  aria-labelledby="dropdownMenu2"
                >
                  {[...props?.connectedBroker]
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => (
                      <button className="dropdown-item" type="button">
                        {" "}
                        {item?.name}{" "}
                      </button>
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className="P6">Exchange </div>
            <div className="dropdown ">
              <div
                className="header-retailer dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {props?.exchange?.length > 0 &&
                  props?.exchange[0]?.exchangeName}
                <img
                  src={require("assets/arrowDown.svg").default}
                  alt=""
                  className="mx-1"
                  loading="lazy"
                />
              </div>
              {props?.exchange?.length > 0 && (
                <div
                  className="dropdown-menu brokerNameDropdown dropdownScroll"
                  aria-labelledby="dropdownMenu2"
                >
                  {props?.exchange?.map((item, index) => (
                    <button className="dropdown-item" type="button">
                      {item?.exchangeName}
                    </button>
                  ))}
                </div>
              )}
            </div>
            {!props?.exchange?.length > 0 ? (
              <div className="tooltip-Exchange">
                {" "}
                <span className="header-retailer  " flow="down">
                  Not added
                </span>{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex gap-2 align-items-center universal-tooltip-parrent">
            <div className="P6">Limits trade</div>
            <div className="d-flex gap-1 align-items-center mr-2">
              <div className="header-retailer-buy">Buy</div>
              <div className="header-retailer">
                {!props?.limitTrade?.limits?.buy
                  ? "0"
                  : props?.limitTrade?.limits?.buy}
              </div>
            </div>
            <div className="d-flex gap-1 align-items-center">
              <div className="header-retailer-sell">Sell</div>
              <div className="header-retailer">
                {!props?.limitTrade?.limits?.sell
                  ? "0"
                  : props?.limitTrade?.limits?.sell}
              </div>
            </div>
          </div>
        </div>
      )}
      {localStorage.getItem("role") === "retailer" && (
        <div className="header-retailer-border"></div>
      )}
    </>
  );
};

export default ClientSubHeader;
