import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from './Button';
import PopupModal from 'components/PopupModal/index';
import SecondryButton from './Button/SecondryButton';
import { contactUs } from "features/GetInTouch/GetInTouchAction";

function Support({ close }) {
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    name: Yup.string().trim().required('Full Name is required'),
    email: Yup.string().trim().email('Invalid email address').required('Email is required'),
    message: Yup.string().trim().required('Message is required'),
  });

  const formik = useFormik({
    initialValues: { name: '', email: '', message: '' },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(contactUs(values)).then((response) => {
        if (response.meta && response.meta.requestStatus === 'fulfilled') {
          formik.resetForm();
        }
      });
    },
  });

  return (
    <PopupModal id={'needSupport'} backdrop={false}>
      <div className='col-12 p-4 cross-signn'>
        <div className='btn-crossabs'>
          <button type='button' data-bs-dismiss='modal' className='btn-close ' onClick={formik.resetForm}></button>
        </div>
        <div className='justify-content-center needSupportHeading'>
          <h3 className='d-flex justify-content-start mt-2 mb-4'>Customer Support</h3>
          <h5>Full Name</h5>
          <input name='name' maxLength='50' type='text' value={formik.values.name} placeholder='Ex: Jordan Doe' className={`NeedSupportInput ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
            onChange={formik.handleChange} onBlur={formik.handleBlur} />
          {formik.touched.name && formik.errors.name && <div className='invalid-feedback'>{formik.errors.name}</div>}
          <h5 className='mt-3'>Email</h5>
          <input name='email' type='email' value={formik.values.email} placeholder='email@example.com' className={`NeedSupportInput ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
            onChange={formik.handleChange} onBlur={formik.handleBlur} />
          {formik.touched.email && formik.errors.email && <div className='invalid-feedback'>{formik.errors.email}</div>}
          <h5 className='mt-3 mb-2'>Message</h5>
          <textarea name='message' rows='5' maxLength='500' value={formik.values.message} as='textarea'
            className={`form-control NeedSupportInputMSG ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`} placeholder='Type your Message'
            onChange={formik.handleChange} onBlur={formik.handleBlur} />
          {formik.touched.message && formik.errors.message && <div className='invalid-feedback'>{formik.errors.message}</div>}
          <div className='d-flex justify-content-end needSupportButtons mt-2 gap-2'>
            <SecondryButton className='my-3' text='Cancel' data-bs-dismiss='modal' onClick={formik.resetForm} />
            <Button handleClick={formik.handleSubmit} className='my-3' data-bs-dismiss='modal' type='submit' text='Send' disabled={!formik.isValid || !formik.dirty} />
          </div>
        </div>
      </div>
    </PopupModal>
  );
}

export default Support;

