import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ purchaseAmount: false }, { pricePurchaseAmount: false }];

const errortime = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setDroidTimeError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex(
        (error) => Object.keys(error)[0] === key
      );
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = true;
      }
    },
    clearDroidTimeError: (state, action) => {
      const { key } = action.payload;
      const errorToUpdateIndex = state.findIndex(
        (error) => Object.keys(error)[0] === key
      );
      if (errorToUpdateIndex !== -1) {
        state[errorToUpdateIndex][key] = false;
      }
    },
  },
});

export const { setDroidTimeError, clearDroidTimeError } = errortime.actions;

export default errortime.reducer;
