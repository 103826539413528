import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//----------------------------Get Coin list------------------------
export const getCoinList = createAsyncThunk(
    'coin',
    async ({userToken}, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/service/getcoinsList`,
                config
            )
            if(data.hasOwnProperty('error')){
                return rejectWithValue(data.message)
              }else{
                return data.data?.data
              }
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updatePortfolio = createAsyncThunk(
    'updatePortfolio',
    async ({userToken}, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": userToken,
                    "X-custom-header": "$Ecur!tyHEadEr"
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASEURL}/api/updatePortfolio`,
                config
            )
            if(data.hasOwnProperty('error')){
                return rejectWithValue(data.message)
              }else{
                return data.data?.data
              }
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
