import ConverNumber from 'components/ConvertNumber';
import React from 'react'

const ProfilePersonalGoals = (props) => {

  return (
    <div><h5 className="mb-3">Personal goals</h5>
      <div className="mb-3">
        <h6>Total Clients </h6>
        <div className="d-flex mt-1 justify-content-between">
          <div className="progress col-md-10">
            <div className="progress-bar" role="progressbar" style={{ width: `${(props?.totalClients / props?.personal_goals?.targetClients) * 100}%` }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" />
          </div>
          <span className="personelGoalsSpan col-md-2 text-end d-flex justify-content-end"><ConverNumber data={props?.totalClients} />/<ConverNumber data={props?.personal_goals?.targetClients} /></span>
        </div >
      </div >
      <div className="personelGoalsRevenue d-flex justify-content align-items-center gap-1">
        <h6>Avg. Portfolio Revenue </h6>
        <p className="">30d</p>
      </div>
      <div className="d-flex justify-content-between">
        <div className="progress col-md-10">
          <div className="progress-bar" role="progressbar" style={{ width: `${(props?.averagePortfoliosRevenue / props?.personal_goals?.targetAveragePortfolioRevenue) * 100}%` }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" />
        </div>
        <span className="personelGoalsSpan col-md-2 text-end d-flex justify-content-end"><ConverNumber data={props?.averagePortfoliosRevenue === 'NaN' ? 0 : props?.averagePortfoliosRevenue} />/<ConverNumber data={props?.personal_goals?.targetAveragePortfolioRevenue} /></span>
      </div></div>
  )
}

export default ProfilePersonalGoals