import React, { useState, useEffect } from "react";
import SecondryButton from "components/Button/SecondryButton";
import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import { useSelector } from "react-redux";
import { EditIcon } from "assets";

const timePeriodData = [
  { label: "Never" },
  { label: "Hourly" },
  { label: "Daily" },
  { label: "Weekly" },
];

const SaftynetRulesModeDroidChild = ({
  minBalance,
  minAmount,
  getbidValue,
  droidDataDispatch,
}) => {
  const [getSafetynet, setSafetynet] = useState({ pricetrend: "Up" });
  const [getError, setError] = useState({ name: "", error: "" });
  const [disabled, setdisabled] = useState(false);
  const twoDecimalPointValidation = /^\d*\.?\d{0,2}$/;
  const { currencyData } = useSelector((state) => state?.defaultState);
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const [getSditSafetynetRualMode, setSditSafetynetRualMode] = useState({
    status: false,
  });

  const [getList, setList] = useState({
    pricetrend: "Down",
    takeProfitType: "dollar",
    action: "Buy",
  });

  useEffect(() => {
    setError({ error: "", name: "" });
  }, [minAmount, minBalance]);

  const handleChangetSafetyNetRules = (event) => {
    setdisabled(false);
    const { name, value } = event.target;
    const disallowedValue = 0;

    if (!twoDecimalPointValidation.test(value)) {
      setError({
        name: name,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
    } else if (
      parseFloat(value) > parseFloat(minBalance) &&
      (name === "amount" || name === "thresholdAmount")
    ) {
      setError({
        name: name,
        error: `Please verify the maximum entered amount $${parseFloat(
          minBalance
        )?.toFixed(2)}.`,
      });
    } else if (
      name === "thresholdAmount" &&
      parseFloat(value) <= parseFloat(minAmount)
    ) {
      droidDataDispatch({ [name]: value });
    } else if (parseFloat(value) === disallowedValue) {
      setError({ name: name, error: "Only zero value is not allowed" });
      if (name === "priceTrendAmount") {
        setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
      } else {
        droidDataDispatch({ [name]: value });
      }
    }

    // else if (getbidValue < value) {
    //   setError({
    //     name: name,
    //     error: "Please enter a value less than or equal to Coin Current Price",
    //   });
    // }
    else if (value.startsWith(".")) {
      setError({ name: name, error: "Value cannot start with a dot" });
      if (name === "priceTrendAmount") {
        setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
      } else {
        droidDataDispatch({ [name]: value });
      }
    } else if (
      getList?.pricetrend == "Down" &&
      parseFloat(value) >= getDroidsDataInfo?.price
    ) {
      if (name === "price") {
        if (name === "priceTrendAmount") {
        } else {
          droidDataDispatch({ [name]: value });
        }
      } else if (name === "priceTrendAmount") {
        setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
        if (getDroidsDataInfo?.safetyNetRules?.length > 0) {
          let finalUPTrendVal = Number(
            getDroidsDataInfo?.safetyNetRules[
              getDroidsDataInfo?.safetyNetRules?.length - 1
            ]?.priceTrendAmount
          );

          if (value < finalUPTrendVal) {
            setError({
              name: "",
              error: "",
            });
            droidDataDispatch({ [name]: value });
          } else {
            setdisabled(true);
            setError({
              name: name,
              error:
                "Price trend amount should be less than last up-trend value",
            });
          }
        } else {
          setError({
            name: name,
            error:
              "Price trend amount should be less than price purchase value.",
          });

          if (name === "priceTrendAmount") {
            setList((prevObject) => ({
              ...prevObject,
              priceTrendAmount: value,
            }));
          } else {
            droidDataDispatch({ [name]: value });
          }
        }
      } else {
        setError({
          name: name,
          error: "Price trend amount should be less than price purchase value.",
        });
        if (name === "priceTrendAmount") {
          setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
        } else {
          droidDataDispatch({ [name]: value });
        }
      }
    } else if (
      getList?.pricetrend == "Down" &&
      parseFloat(value) <= getDroidsDataInfo?.price &&
      getDroidsDataInfo?.safetyNetRules?.length > 0
    ) {
      if (name === "price") {
        if (name === "priceTrendAmount") {
        } else {
          droidDataDispatch({ [name]: value });
        }
      } else if (name === "priceTrendAmount") {
        setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
        if (getDroidsDataInfo?.safetyNetRules?.length > 0) {
          let finalUPTrendVal = Number(
            getDroidsDataInfo?.safetyNetRules[
              getDroidsDataInfo?.safetyNetRules?.length - 1
            ]?.priceTrendAmount
          );

          if (value < finalUPTrendVal) {
            setError({
              name: "",
              error: "",
            });
            droidDataDispatch({ [name]: value });
          } else {
            setdisabled(true);
            setError({
              name: name,
              error:
                "Price trend amount should be less than last up-trend value",
            });
          }
        } else {
          setError({
            name: "",
            error:
              "Price trend amount should be less than price purchase value.",
          });

          if (name === "priceTrendAmount") {
            setList((prevObject) => ({
              ...prevObject,
              priceTrendAmount: value,
            }));
          } else {
            droidDataDispatch({ [name]: value });
          }
        }
      } else {
        setError({
          name: name,
          error: "Price trend amount should be less than price purchase value.",
        });
        if (name === "priceTrendAmount") {
          setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
        } else {
          droidDataDispatch({ [name]: value });
        }
      }
    } else {
      if (value.length > 8) {
        setError({
          name: name,
          error: "The value should not exceed 8 digits.",
        });
      } else if (
        value > 100 &&
        ((name === "priceTrendAmount" &&
          getSafetynet?.takeProfitType === "percent") ||
          (name === "thresholdAmount" &&
            getSafetynet?.thresholdType === "percent"))
      ) {
        setError({
          name: name,
          error: "Please input a value that is less than or equal to 100%.",
        });
      } else {
        setError({ name: "", error: "" });
        if (name === "priceTrendAmount") {
          setList((prevObject) => ({ ...prevObject, priceTrendAmount: value }));
        } else {
          droidDataDispatch({ [name]: value });
        }
      }
    }
  };

  const handleSingleCoin = (event, id) => {
    if (id === "action") {
      if (event === "Buy") {
        droidDataDispatch({ price: "" });
      } else {
        droidDataDispatch({ orderSize: "" });
      }
      droidDataDispatch({ action: event });
    } else if (id === "pricetrend") {
      setSafetynet((prevObject) => ({
        ...prevObject.list,
        list: { pricetrend: event },
      }));
    }
  };

  const handlseClickAdd = () => {
    const addNew =
      getList?.pricetrend === "Up"
        ? { isBuyTriggered: false }
        : { isSellTriggered: false };
    const details =
      getList?.pricetrend === "Up"
        ? { buyOrderDetails: [] }
        : { sellOrderDetails: [] };
    const UpDown =
      getDroidsDataInfo?.safetyNetRules[
        getDroidsDataInfo?.safetyNetRules?.length - 1
      ]?.pricetrend;
    if (getSditSafetynetRualMode?.status) {
      const dataChange = [...getDroidsDataInfo?.safetyNetRules];
      dataChange[getSditSafetynetRualMode?.index] = {
        ...getList,
        ...addNew,
        ...details,
        action: getDroidsDataInfo?.action,
      };
      droidDataDispatch({ safetyNetRules: dataChange });
      setSditSafetynetRualMode({ status: false });
    } else {
      droidDataDispatch({
        safetyNetRules: [
          ...getDroidsDataInfo?.safetyNetRules,
          {
            ...getList,
            ...addNew,
            ...details,
            action: getDroidsDataInfo?.action,
          },
        ],
      });
    }
    setList({
      pricetrend: getList?.pricetrend === "Up" ? "Down" : "Up",
      takeProfitType: "dollar",
    });
  };

  const handleCancelClick = () => {
    setSditSafetynetRualMode({ status: false });
    setList({
      pricetrend:
        getDroidsDataInfo?.safetyNetRules[
          getDroidsDataInfo?.safetyNetRules?.length - 1
        ]?.pricetrend === "Up"
          ? "Down"
          : "Up",
      takeProfitType: "dollar",
    });
  };

  const PurchaseType = (item, id) => {
    if (id === "thresholdType") {
      droidDataDispatch({ thresholdAmount: "" });
    } else if (id === "takeProfitType") {
      droidDataDispatch({ priceTrendAmount: "" });
    }
    setError({ name: "", error: "" });
    setSafetynet((prevObject) => ({ ...prevObject, [id]: item?.name }));
  };

  const rulseRepitAdd = (event) => {
    const { name, value } = event.target;
    const intValue = parseInt(value, 10);
    if (intValue <= 0 || intValue > 5) {
      setError({ name: name, error: "Please input a value from 1-5" });
    } else {
      setError({ name: "", error: "" });
      droidDataDispatch({ [name]: intValue });
    }
  };

  const editSafetynetRual = (item, index) => {
    setSditSafetynetRualMode({ status: true, index: index, item: item });
    setList((prevObject) => ({
      ...prevObject,
      pricetrend: item.pricetrend,
      priceTrendAmount: item?.priceTrendAmount,
    }));
  };

  const deleteSafetynetRual = (item, index) => {
    const updatedArray = [...getDroidsDataInfo?.safetyNetRules];
    updatedArray?.splice(index, 1);
    droidDataDispatch({ safetyNetRules: updatedArray });
  };
  const priceTrendClick = (option) => {
    if (
      getDroidsDataInfo?.safetyNetRules?.length == 5 &&
      getSditSafetynetRualMode?.status
    ) {
    } else {
      setList((prevObject) => ({ ...prevObject, pricetrend: option.value }));
    }
  };

  const DownUpValidation = !getDroidsDataInfo?.safetyNetRules
    ? true
    : getDroidsDataInfo?.safetyNetRules[
        getDroidsDataInfo?.safetyNetRules?.length - 1
      ];
  const UpDownClick = (option) => {
    if (!DownUpValidation) {
    } else if (DownUpValidation?.pricetrend === "Down") {
    } else if (getDroidsDataInfo?.safetyNetRules?.length % 2 === 0) {
    } else {
      priceTrendClick(option);
    }
  };

  const BuySellClick = (option) => {
    if (!DownUpValidation) {
    } else if (DownUpValidation?.pricetrend === "Up") {
    } else {
      handleSingleCoin(option.value, "action");
    }
  };

  const deleteAllRules = () => {
    droidDataDispatch({ safetyNetRules: [] });
    priceTrendClick({
      label: "Down",
      value: "Down",
    });
  };

  return (
    <>
      <h4 className="mb-4">Safetynet Rules</h4>
      <div className="row">
        <div className="col-lg-6 mb-3 MT-2">
          <div className="d-flex align-items-center input-container-height">
            <h6 className="mb-1">Rules Repeat</h6>
            {!getDroidsDataInfo?.rulesRepeat ? (
              <p className="required-field">*</p>
            ) : (
              ""
            )}
          </div>
          <input
            type="text"
            value={
              !getDroidsDataInfo?.rulesRepeat
                ? ""
                : getDroidsDataInfo?.rulesRepeat
            }
            name="rulesRepeat"
            onChange={rulseRepitAdd}
            placeholder="Enter Number"
          />
          {getError?.name === "rulesRepeat" && (
            <div className="text-danger">⚠️ {getError?.error}</div>
          )}
        </div>
        <div className="col-lg-6">
          <div className="d-flex gap-2 align-items-center mb-1">
            <h6>Period</h6>
            <TooltipQuestionMark
              toolTipContent={
                "Define the period of until when this Rule should take place. After the date end the rule will stop working and be deleted from the list. Rule can be besides date, until Take Profit happen."
              }
            />
          </div>

          <div
            className={`btn-group simpledropdown w-100 small-dropdown-height mt-1`}
          >
            <button
              className="btn dropdown-toggle d-flex justify-content-between align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
            >
              <div>{getDroidsDataInfo?.period}</div>
              <img
                src={require("assets/arrowDown.svg").default}
                alt=""
                className="ML-6"
                loading="lazy"
              />
            </button>
            <ul className="dropdown-menu">
              {timePeriodData?.map((item, index) => (
                <li
                  key={index}
                  className={`dropdown-item py-3 justify-content-between d-flex ${
                    timePeriodData?.length - 1 == index ? "" : "border-bottom"
                  }`}
                  onClick={() => droidDataDispatch({ period: item?.label })}
                >
                  {item?.label}{" "}
                  {getDroidsDataInfo?.period === item?.label ? (
                    <img
                      src={require("assets/rightMark.svg").default}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="deal-rules">
        <div className="deal-rules-container">
          <h6>Action</h6>
          <div className="col-lg-6 mt-3">
            <div
              className={`bg-white w-50 DMS-Container-Buttons-container d-flex mb-3 cursor-pointer`}
            >
              {[{ label: "Buy", value: "Buy" }].map((option) => (
                <div
                  key={option.value}
                  className={`DMS-Container-Buttons ${
                    getDroidsDataInfo?.action === option.value
                      ? "DMS-Container-Buttons-active"
                      : ""
                  }`}
                  onClick={() => BuySellClick(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="d-flex gap-2 align-items-center">
                <div className="d-flex align-items-center">
                  <h6>
                    {getDroidsDataInfo?.action === "Buy"
                      ? "Buy Purchase Amount"
                      : "Amount"}
                  </h6>
                  {!getDroidsDataInfo?.thresholdAmount ? (
                    <p className="required-field">*</p>
                  ) : null}
                </div>
                <TooltipQuestionMark
                  toolTipContent={
                    "Order is the first order the Droid will create when starting a new deal. It can be based on $ from the overall Folder on your exchange."
                  }
                />
              </div>
              <div className="mb-3 ">
                <div className="d-flex gap-2 align-items-center">
                  {getDroidsDataInfo?.action !== "Buy" ? (
                    ""
                  ) : (
                    <div
                      className={`btn-group simpledropdown w-25 small-dropdown-height MT-2`}
                    >
                      <button
                        className="btn dropdown-toggle d-flex justify-content-center align-items-center droid-dropdown-height"
                        type="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                      >
                        <div>
                          {
                            currencyData?.find(
                              (obj) =>
                                obj.name === getDroidsDataInfo?.thresholdType
                            )?.label
                          }
                        </div>{" "}
                      </button>
                    </div>
                  )}
                  <input
                    type="text"
                    disabled={!minAmount}
                    value={
                      getDroidsDataInfo?.action === "Buy"
                        ? !getDroidsDataInfo?.thresholdAmount
                          ? ""
                          : getDroidsDataInfo?.thresholdAmount
                        : !getDroidsDataInfo?.amount
                        ? ""
                        : getDroidsDataInfo?.amount
                    }
                    name={
                      getDroidsDataInfo?.action === "Buy"
                        ? "thresholdAmount"
                        : "amount"
                    }
                    onChange={handleChangetSafetyNetRules}
                    className={
                      getDroidsDataInfo?.action === "Buy"
                        ? "small-input-right"
                        : ""
                    }
                    placeholder="Amount"
                  />
                </div>
                {(getError?.name === "thresholdAmount" ||
                  getError?.name === "amount") && (
                  <div className="text-danger">⚠️ {getError?.error}</div>
                )}
                {parseFloat(getDroidsDataInfo?.thresholdAmount) <=
                  parseFloat(minAmount) && (
                  <div className="text-danger">
                    ⚠️ Please input value greater than ${minAmount}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="d-flex gap-2 align-items-center">
                <div className="d-flex align-items-center">
                  <h6>
                    {getDroidsDataInfo?.action === "Buy"
                      ? "Price-Purchase Value"
                      : "Order Size"}
                  </h6>
                  {!getDroidsDataInfo?.price ||
                  !getDroidsDataInfo?.orderSize ? (
                    <p className="required-field">*</p>
                  ) : null}
                </div>
                <TooltipQuestionMark
                  toolTipContent={
                    "Order is the first order the Droid will create when starting a new deal. It can be based on $ from the overall Folder on your exchange."
                  }
                />
              </div>
              <div className="mb-3 ">
                <div className="d-flex gap-2 align-items-center">
                  {getDroidsDataInfo?.action !== "Buy" ? (
                    ""
                  ) : (
                    <div
                      className={`btn-group simpledropdown w-25 small-dropdown-height MT-2`}
                    >
                      <button
                        className="btn dropdown-toggle d-flex justify-content-center align-items-center droid-dropdown-height"
                        type="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                      >
                        <div>
                          {
                            currencyData?.find(
                              (obj) => obj.name === getDroidsDataInfo?.priceType
                            )?.label
                          }
                        </div>{" "}
                      </button>
                    </div>
                  )}
                  <input
                    type="text"
                    value={
                      getDroidsDataInfo?.action === "Buy"
                        ? !getDroidsDataInfo?.price
                          ? ""
                          : getDroidsDataInfo?.price
                        : !getDroidsDataInfo?.orderSize
                        ? ""
                        : getDroidsDataInfo?.orderSize
                    }
                    name={
                      getDroidsDataInfo?.action === "Buy"
                        ? "price"
                        : "orderSize"
                    }
                    onChange={handleChangetSafetyNetRules}
                    className={`${
                      getDroidsDataInfo?.action === "Buy"
                        ? "small-input-right"
                        : ""
                    } ${
                      getDroidsDataInfo?.safetyNetRules?.length > 0
                        ? "not-allowed"
                        : ""
                    }`}
                    placeholder={
                      getDroidsDataInfo?.action === "Buy"
                        ? "Price"
                        : "Order Size"
                    }
                    disabled={getDroidsDataInfo?.safetyNetRules?.length > 0}
                  />
                </div>
                {(getError?.name === "price" ||
                  getError?.name === "orderSize") && (
                  <div className="text-danger">⚠️ {getError?.error}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 ">
              <h6 className="">Price Trend</h6>
              <div className="mb-3 mt-2">
                <div
                  className={`bg-white DMS-Container-Buttons-container d-flex mb-3 cursor-pointer`}
                >
                  {[
                    { label: "Up", value: "Up" },
                    { label: "Down", value: "Down" },
                  ].map((option) => (
                    <div
                      key={option.value}
                      className={`DMS-Container-Buttons ${
                        getList?.pricetrend === option.value
                          ? "DMS-Container-Buttons-active"
                          : ""
                      }`}
                      onClick={() => UpDownClick(option)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex gap-2 align-items-center">
                <div className="d-flex align-items-center">
                  <h6>Price Trend Amount</h6>
                  {!getSafetynet?.priceTrendAmount ? (
                    <p className="required-field">*</p>
                  ) : null}
                </div>
                <TooltipQuestionMark
                  toolTipContent={
                    "Configure the percentage Take Profit target the droid will use to close successful trades, the bot will automatically account for exchange fees."
                  }
                />
              </div>
              <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <div
                    className={`btn-group simpledropdown w-25 small-dropdown-height MT-2`}
                  >
                    <button
                      className="btn dropdown-toggle d-flex justify-content-center align-items-center droid-dropdown-height"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                    >
                      <div>
                        {
                          currencyData?.find(
                            (obj) => obj.name === getList?.takeProfitType
                          )?.label
                        }
                      </div>{" "}
                    </button>
                  </div>
                  <input
                    type="text"
                    disabled={
                      getDroidsDataInfo?.safetyNetRules?.length >= 5
                        ? getSditSafetynetRualMode?.status
                          ? false
                          : true
                        : false
                    }
                    value={
                      !getList?.priceTrendAmount
                        ? ""
                        : getList?.priceTrendAmount
                    }
                    name="priceTrendAmount"
                    onChange={handleChangetSafetyNetRules}
                    className="small-input-right"
                    placeholder="Amount"
                  />
                </div>
                {getError?.name == "priceTrendAmount" && (
                  <div className="text-danger">⚠️ {getError?.error}</div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2">
            {getSditSafetynetRualMode?.status ? (
              <SecondryButton text={"Cancel"} handleClick={handleCancelClick} />
            ) : (
              ""
            )}
            {getDroidsDataInfo?.safetyNetRules?.length >= 5 &&
            !getSditSafetynetRualMode?.status ? (
              ""
            ) : (
              <div className="">
                <SecondryButton
                  text={
                    getSditSafetynetRualMode?.status ? "Update" : "Add to List"
                  }
                  handleClick={() => handlseClickAdd()}
                  disabled={
                    getDroidsDataInfo?.action === "Buy"
                      ? !(
                          getDroidsDataInfo?.thresholdAmount > 0 &&
                          getDroidsDataInfo?.price > 0 &&
                          getList?.priceTrendAmount > 0
                        ) ||
                        parseFloat(getDroidsDataInfo?.thresholdAmount) <=
                          parseFloat(minAmount) ||
                        (getList?.pricetrend == "Down" &&
                          getList?.priceTrendAmount >=
                            getDroidsDataInfo?.price &&
                          getDroidsDataInfo?.safetyNetRules.length === 0) ||
                        disabled
                      : !(
                          getDroidsDataInfo?.orderSize > 0 &&
                          getDroidsDataInfo?.amount > 0 &&
                          getList?.priceTrendAmount > 0
                        )
                  }
                />{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      {getDroidsDataInfo?.safetyNetRules?.length > 0 && (
        <>
          <div class="d-flex align-items-center justify-content-between">
            <h3 class="my-2">Rules List</h3>
            <img
              style={{ cursor: "pointer" }}
              src={require(`assets/deleteIcon.svg`).default}
              onClick={() => deleteAllRules()}
              alt=""
            />
          </div>
          <div className="table_Scroll">
            <table className="table">
              <tbody>
                {getDroidsDataInfo?.safetyNetRules?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="table_subheadind">{index + 1} </td>
                      <td>
                        <div className="d-flex table_subheadind gap-1">
                          Action{" "}
                          <div className="table_headind"> {item?.action} </div>{" "}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex table_subheadind gap-1">
                          {item?.action === "Buy"
                            ? "Buy Purchase Amount"
                            : "Amount"}{" "}
                          <div className="table_headind">
                            {
                              currencyData?.find(
                                (obj) =>
                                  obj.name === getDroidsDataInfo?.thresholdType
                              )?.label
                            }
                            {item?.action === "Buy"
                              ? getDroidsDataInfo?.thresholdAmount
                              : getDroidsDataInfo?.amount}{" "}
                          </div>{" "}
                        </div>
                      </td>
                      {/* Disabled Price Column */}
                      {/* <td>
                          <div className="d-flex table_subheadind gap-1">
                            {item?.action === "Buy" ? "Price" : "Order Size"}{" "}
                            <div className="table_headind">
                              {" "}
                              {item?.action === "Buy"
                                ? currencyData?.find(
                                    (obj) =>
                                      obj.name === getDroidsDataInfo?.priceType
                                  )?.label + getDroidsDataInfo?.price
                                : getDroidsDataInfo?.orderSize}{" "}
                            </div>{" "}
                          </div>
                        </td> */}
                      <td>
                        <div className="d-flex table_subheadind gap-1">
                          Period{" "}
                          <div className="table_headind">
                            {getDroidsDataInfo?.rulesRepeat}{" "}
                            {getDroidsDataInfo?.period}
                          </div>{" "}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex table_subheadind gap-1">
                          Trend{" "}
                          <div className="table_headind">
                            {" "}
                            {item?.pricetrend}{" "}
                          </div>{" "}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex table_subheadind gap-1">
                          Price Trend Amount
                          <div className="table_headind">
                            {" "}
                            {
                              currencyData?.find(
                                (obj) => obj.name === item?.priceTrendAmount
                              )?.label
                            }
                            {item?.priceTrendAmount}{" "}
                          </div>{" "}
                        </div>
                      </td>
                      <td>
                        <div
                          className="table_headind cursor"
                          onClick={() =>
                            item?.pricetrend === "Up"
                              ? item?.isBuyTriggered
                                ? ""
                                : editSafetynetRual(item, index)
                              : item?.isSellTriggered
                              ? ""
                              : editSafetynetRual(item, index)
                          }
                        >
                          {" "}
                          <EditIcon color="#035cc5" />{" "}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {getDroidsDataInfo?.safetyNetRules[
              getDroidsDataInfo?.safetyNetRules?.length - 1
            ]?.pricetrend === "Down" ? (
              ""
            ) : (
              <div className="text-danger">
                Price Trend: Last rule should be down trend
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SaftynetRulesModeDroidChild;
