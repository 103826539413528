import { createSlice } from '@reduxjs/toolkit'
import { getActivityLog } from './ActivityLogAction'

const initialState = {
  getAllActivityLogInfo: null,
  getAllDroidsByIdInfo:null,
  isgetAllActivityLog: false,
  ispostActivityLog: false,
  ispostActivityLogError:false
}

const activityLogSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearispostActivityLog: (state) => {
      state.ispostActivityLog = false
      state.ispostActivityLogError = false
    },
    clearisupdateActivityLog:(state) => {
      state.isupdateActivityLog =false
    }

  },
  extraReducers: {
    [getActivityLog.pending]: (state) => { },
    [getActivityLog.fulfilled]: (state, { payload }) => {
      state.getAllActivityLogInfo = payload
    },
    [getActivityLog.rejected]: (state, { payload }) => { },
  },
})

export const { clearispostActivityLog } = activityLogSlice.actions
export default activityLogSlice.reducer
