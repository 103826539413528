import React from "react";

function UpDown({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 22"
    >
      <path
        fill="#0078E7"
        fillRule="evenodd"
        d="M5.47.47l-5 5 1.06 1.06 3.72-3.72V16a.75.75 0 001.5 0V2.81l3.72 3.72 1.06-1.06-5-5a.75.75 0 00-1.06 0zM13.47 21.53l-5-5 1.06-1.06 3.72 3.72V6a.75.75 0 011.5 0v13.19l3.72-3.72 1.06 1.06-5 5a.75.75 0 01-1.06 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UpDown;
