import React, { Component } from "react";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <h1 style={styles.heading}>Oops! Something went wrong.</h1>
          <p style={styles.message}>
            We're sorry, an error occurred while processing your request.
          </p>
          <div className="brand-logo mb-4">
            <img
              src={require("assets/companylogo.svg").default}
              style={{ width: 130 }}
              alt=""
              loading="lazy"
            />
          </div>
          <Link to="/" style={styles.button}>
            Go back to home
          </Link>
        </div>
      );
    }
    return this.props.children;
  }
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f7f7f7",
    padding: "20px",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "#333",
  },
  message: {
    fontSize: "1rem",
    marginBottom: "20px",
    textAlign: "center",
    color: "#666",
  },
  companyName: {
    fontSize: "0.8rem",
    marginBottom: "10px",
    color: "#999",
  },
  button: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "5px",
    textDecoration: "none",
  },
};

export default ErrorBoundary;
