import React, { useState } from 'react'
import DottedChart from 'components/Charts/DottedChart';
import ProgressBar from 'components/Charts/ProgressBar';

const RetailerExchange = ({ getDetails }) => {
    const [showAllData, setShowAllData] = useState(false);
    const makerPercentage = 21;
    const takerPercentage = 21;
    return (
        <>
            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between '>
                    <h3>Exchange</h3>
                </div>
                {getDetails?.exchange?.length === 0 && <div className='mb-4 text-center'><span>Exchange not found</span></div>}
                {getDetails?.exchange?.slice(0, showAllData ? getDetails?.exchange?.length : 1)?.map((item, index) => {
                    const isLastIndex = index === getDetails?.exchange?.length - 1
                    return (
                        <div className='row mt-4' key={index}>
                            <div className='d-flex align-items-center mb-4 justify-content-between row'>
                                <div className='d-flex col-3 '>
                                    <div className='exchangeIcons'>
                                        <img src={item?.exchange?.icon} alt='Exchange Icon' className='' height={20} width={'auto'} loading='lazy'/>
                                    </div>
                                </div>
                                <div className='col-3 d-flex justify-content-center'>
                                    <div>
                                        <h3 className='text-break'>{item?.exchange?.totalFundsAllocated}</h3>
                                        <h6 className='text-break'>Total Funds Allocated ($)</h6>
                                    </div>
                                </div>
                                <div className='col-3 d-flex gap-2 align-items-center justify-content-between'>
                                    <div className='verticle-border' />
                                    <div className=''>
                                        <h3 className='text-break'>{item?.exchange?.totalFundsDeals}</h3>
                                        <h6 className='text-break'>Total Funds in Deals ($)</h6>
                                    </div>
                                    <div className='verticle-border' />
                                </div>
                                <div className='col-3 d-flex justify-content-center'>
                                    <div>
                                        <h3 className='text-break'>{item?.exchange?.Last30DaysPayments?.toFixed(2)}</h3>
                                        <h6 className='text-break'>Payments, Last 30 Days ($)</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex gap-2 mb-2'>
                                <h5>Maker</h5>
                                <p className='p-One'>{item?.fees?.makerFeeRate}%</p>
                                <ProgressBar icon={!item?.exchange?.name ? "" : item?.exchange?.name} makerPercentage={makerPercentage * item?.fees?.makerFeeRatePercent / 100} />
                            </div>
                            <div className='d-flex gap-2 mb-4'>
                                <h5>Taker</h5>
                                <p className='p-One mx-1'>{item?.fees?.takerFeeRate}%</p>
                                <DottedChart icon={item?.exchange?.name === undefined ? "" : item?.exchange?.name} takerPercentage={takerPercentage * item?.fees?.takerFeeRatePercent / 100} />
                            </div>
                            <div className='d-flex gap-2 mb-2'>
                                <h5>Risk</h5>
                                <p className='p-One'>{item?.exchange?.risk}</p>
                            </div>
                            <div className='d-flex gap-2'>
                                <h5>Location</h5>
                                <p className='p-One mx-1'>{item?.exchange?.location}</p>
                            </div>
                            <div className='row'>
                            <h3 className=''>Portfolios Risk</h3>
                                {item?.exchange?.portfolio.map((item, i) =>
                                    <>
                                        <div className='mt-4 col-lg-4' key={i}>
                                            <div className=' d-flex gap-2'>
                                                <img src={require(`assets/risk/risk_${item?.risk?.charAt(0).toUpperCase() + item?.risk?.slice(1)}.svg`)} alt={item?.risk} loading='lazy'/>
                                                <div>
                                                    <h5>{item?.name}</h5> <span classname="h6-One-Grey">{item?.risk}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {(i+1)%3 == 0 ?  <hr className='my-3' /> : ''}
                                    </>
                                )}
                            </div>

                            {!isLastIndex && showAllData ? <hr className='my-3' /> : ""}
                        </div>
                    )
                })}
            </div>
            {getDetails?.exchange?.length > 1 && <div className='d-flex justify-content-end mb-5'>
                <span to='/exchange' className='d-flex align-items-center gap-2 cursor' onClick={() => setShowAllData(!showAllData)}>
                    <img src={require(showAllData ? `assets/threeLinesSlash.svg` : `assets/threeLines.svg`).default} alt='' loading='lazy'/>
                    <h5 className='text-blue'>{showAllData ? 'Minimize' : 'View more'}</h5>
                </span>
            </div>}

        </>
    )
}

export default RetailerExchange