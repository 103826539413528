import React from "react";

function DashboardIcon({fill, color, height=20,width=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 18 18"
    >
      <path
        fill={color ? color : "#0078E7"}
        d="M4 13a1 1 0 102 0v-3a1 1 0 10-2 0v3zm8 0a1 1 0 102 0V5a1 1 0 10-2 0v8zm-4 0a1 1 0 102 0v-1a1 1 0 10-2 0v1zm0-5a1 1 0 102 0 1 1 0 00-2 0zM2 18c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 010 16V2C0 1.45.196.979.587.587A1.928 1.928 0 012 0h14c.55 0 1.021.196 1.413.587C17.804.979 18 1.45 18 2v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0116 18H2zm0-2h14V2H2v14z"
      ></path>
    </svg>
  );
}

export default DashboardIcon;
