import React, { useEffect } from "react";
import {
  BTC,
  ETH,
  Tether,
  Cardano,
  LTC,
  XRP,
  SOL,
  BAT,
  EURO,
  USD,
} from "assets";
import DroidCarousel from "./DroidCarousel";
import { useLocation } from "react-router-dom";
import ConvertDollar from "components/ConvertNumber/ConvertDollar";
import { useDispatch, useSelector } from "react-redux";
import { DroidsData } from "features/DroidState/DroidsStateAction";
import DogeIcon from "assets/CoinIcon/Doge";

const coinData = [
  {
    coin: "BTC",
    image: <BTC text={"#F7931A"} wd={"40"} ht={"40"} />,
    label: "BTC",
  },
  {
    coin: "ETH",
    image: <ETH text={"#393939"} wd={"40"} ht={"40"} />,
    label: "ETH",
  },
  {
    coin: "XRP",
    image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
    label: "XRP",
  },
  {
    coin: "LTC",
    image: <LTC text2={"#F8F9FB"} text={"#D9D9D9"} wd={"40"} ht={"40"} />,
    label: "LTC",
  },
  {
    coin: "SOL",
    image: <SOL text={"url(#paint0_linear_1601_37368)"} wd={"40"} ht={"40"} />,
    label: "SOL",
  },
  {
    coin: "USDT",
    image: <Tether text={"#50AF95"} wd={"40"} ht={"40"} />,
    label: "USDT",
  },
  {
    coin: "ADA",
    image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
    label: "ADA",
  },
  {
    coin: "BCH",
    image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
    label: "BCH",
  },
  {
    coin: "ZEC",
    image: <Cardano text={"#003AC4"} wd={"20"} ht={"20"} bg={"#F8F9FB"} />,
    label: "ZEC",
  },
  {
    coin: "BAT",
    image: (
      <div className="coinHeightWeight">
        <BAT
          text={"url(#paint0_linear_1601_37368)"}
          wd={"20"}
          ht={"20"}
          className="mb-5"
        />
      </div>
    ),
    label: "BAT",
  },
  {
    coin: "EUR",
    image: (
      <div className="coinHeightWeight">
        <EURO wd={"25"} ht={"25"} />
      </div>
    ),
    label: "EUR",
  },
  {
    coin: "GBP",
    image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
    label: "GBP",
  },
  {
    coin: "LINK",
    image: <XRP text={"#000000"} wd={"40"} ht={"40"} />,
    label: "LINK",
  },
  {
    coin: "USD",
    image: (
      <div className="coinHeightWeight">
        <USD wd={"20"} ht={"20"} />
      </div>
    ),
    label: "USD",
  },
  {
    coin: "USDC",
    image: (
      <div className="coinHeightWeight">
        <USD wd={"20"} ht={"20"} />
      </div>
    ),
    label: "USDC",
  },
  {
    coin: "DOGE",
    image: (
      <div className="coinHeightWeight">
        <DogeIcon width={"20"} height={"20"} />
      </div>
    ),
    label: "DOGE",
  },
];

const DroidPortfoliosRetailer = ({
  setError,
  getbidValue,
  setCoinListData,
  data,
  setSelectedPortfolios,
  selectedPortfolios,
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);

  useEffect(() => {
    setCoinListData([]);
    setSelectedPortfolios([]);
  }, [getDroidsDataInfo?.portfoliosIds]);

  const droidDataDispatch = (item) => {
    const payload = { ...getDroidsDataInfo, ...item };
    dispatch(DroidsData({ payload })).then((res) => {});
    setError({ name: "", error: "" });
  };
  const handlePortfolioClick = (item) => {
    if (item?.exchangeName === "coinbase") {
      if (item?.name === "Cash (USD)") {
        setSelectedPortfolios([item]);
        if (state?.droidType?.nav === "simplemodedroid") {
          const dataEmpty = {
            buySellSettings: {
              ...getDroidsDataInfo?.buySellSettings,
              buyPurchaseAmount: "",
            },
          };
          droidDataDispatch({
            portfoliosIds: [{ portfolioId: item?._id }],
            coinsList: [],
            ...dataEmpty,
          });
        } else {
          droidDataDispatch({
            portfoliosIds: [{ portfolioId: item?._id }],
            coinsList: [],
          });
        }
        setCoinListData([]);
        setError({ name: "", error: "" });
      }
    } else {
      setSelectedPortfolios([item]);
      if (state?.droidType?.nav === "simplemodedroid") {
        const dataEmpty = {
          buySellSettings: {
            ...getDroidsDataInfo?.buySellSettings,
            buyPurchaseAmount: "",
          },
        };
        droidDataDispatch({
          portfoliosIds: [{ portfolioId: item?._id }],
          coinsList: [],
          ...dataEmpty,
        });
      } else {
        droidDataDispatch({
          portfoliosIds: [{ portfolioId: item?._id }],
          coinsList: [],
        });
      }
      setCoinListData([]);
      setError({ name: "", error: "" });
    }
  };
  return (
    <DroidCarousel show={1}>
      {data?.length > 0 &&
        data
          ?.filter((e) => e?.balance > 0.01)
          ?.map((item, index) => {
            const disable =
              item.exchangeName === "gemini" &&
              (state?.droidType?.nav === "indexmodedroid" ||
                state?.droidType?.nav === "timemodedroid" ||
                state?.droidType?.nav === "costmodedroid" ||
                state?.droidType?.nav === "safemodedroid");
            const createSelected = selectedPortfolios?.some(
              (e) => e._id === item?._id
            );
            return (
              <div
                key={index}
                disabled={true}
                className={`${
                  data.length > 4 ? "droid_portfolios_margin" : ""
                } card_container-Droid p-3 ${
                  createSelected && getDroidsDataInfo?.portfoliosIds?.length > 0
                    ? "selectPortfolio"
                    : ""
                }`}
                onClick={() => (
                  <>
                    {item?.balance <= 0.009 || createSelected || disable
                      ? ""
                      : handlePortfolioClick(item)}
                  </>
                )}
              >
                <div className="p-Two-gray d-flex gap-1 mb-2">
                  <img
                    src={require(`assets/risk/risk_${
                      item?.risk && item?.risk?.length > 0
                        ? item?.risk[0].toUpperCase() + item?.risk.slice(1)
                        : "Low"
                    }.svg`)}
                    alt=""
                    className="mb-1"
                    loading="lazy"
                  />
                  {item?.risk} | <ConvertDollar data={item?.balance} /> |{" "}
                  {item?.exchangeName}
                </div>
                {item?.name ? (
                  <h4>{item?.name}</h4>
                ) : (
                  <h4 className="text-secondary">Unknown</h4>
                )}
                <div className="d-flex gap-2 mt-2">
                  {item?.coins?.length > 0 ? (
                    item?.coins?.map((item, index) => {
                      return (
                        <>
                          {" "}
                          {coinData?.map((coin, i) => {
                            return <>{coin.coin === item && coin.image} </>;
                          })}{" "}
                        </>
                      );
                    })
                  ) : (
                    <h6 className="p-Two-gray">No coins added yet</h6>
                  )}
                </div>
                {disable && (
                  <small className="fw-bold text-danger">
                    {
                      "Sorry, you cannot select this portfolio for the chosen droid type."
                    }
                  </small>
                )}
              </div>
            );
          })}
    </DroidCarousel>
  );
};

export default DroidPortfoliosRetailer;
