import React from 'react'
import LineChartDark from '../Charts/LineChartDark'
import GlobalMarketCapChart from 'components/Charts/GlobalMarketCapChart'

function GlobalMarketCap({ label }) {
  return (
    <div className='tab-mar'>
      <div className="marketCapGraph">
        <h5>Global Market Cap</h5>
        <GlobalMarketCapChart dataChart={[
          "26321.583434905606",
          "26346.92829007947",
          "26338.404818594296",
          "26340.3429237456",
          "26352.69756688475",
          "26402.766473458345",
          "26430.260388406296",
          "26410.218975927673",
          "26410.354549393138",
          "26324.216060945626",
          "26332.618465265106",
          "26319.88104609204",
          "26335.59141839441",
          "26286.953643227127",
          "26284.606983226728",
          "26261.319597982358",
          "26244.169839737388",
          "26218.473135476353",
          "26175.314165693373",
          "26145.2005115135",
          "26125.063316760148",
          "26099.837653427385",
          "26077.175650374054",
          "26091.220027173913"
        ]} />
      </div>
    </div>
  )
}

export default GlobalMarketCap