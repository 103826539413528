import React, { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '../Header'
import { BrokersIcon, UserIcon, PortfoliosIcon, ChatIcon } from 'assets'
import { useDispatch, useSelector } from "react-redux";
import { UserAPI } from 'features/UserChat/userChatAction';

function Community() {
  const location = useLocation()
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const { totalUsersCount } = useSelector((state) => state?.community?.getBrokerInfo || []);
  const [active, setActive] = useState(location?.pathname?.split("/")[2])
  
  const [getuserList, setUserList] = useState([])

  const handleActive = () => {
    setActive(!active)
  }
  useEffect(() => {
    setActive(location?.pathname?.split("/")[2])
  }, [location?.pathname?.split("/")[2]])

useEffect(()=>{
  const userType = localStorage.getItem('role')
  dispatch(UserAPI({ userToken, userType })).then((res) => {
    if (res.meta && res.meta.requestStatus === "fulfilled") {
      setUserList(res?.payload);
    }
  })
},[])
  var user_type = localStorage.getItem('role') === 'broker' ? 'retailer' : 'broker'
  var user_type_capital = user_type.charAt(0).toUpperCase() + user_type.slice(1);
  
  const headerData = [
    { text: `${user_type_capital}s`, number: totalUsersCount || 0, imageIcon: <BrokersIcon color={active === `${user_type}s` ? '#fff' : '#0078E7'} />, link: `${user_type}s` },
    { text: "Discord", number: 1, imageIcon: <UserIcon color={active === 'discord' ? '#fff' : '#0078E7'} />, link: "discord" },
    { text: "Blog", number: 0, imageIcon: <PortfoliosIcon color={active === 'blog' ? '#fff' : '#0078E7'} />, link: "blog" },
    { text: "Chat", number: getuserList?.length, imageIcon: <ChatIcon color={active === 'blog' ? '#fff' : '#0078E7'} />, link: "/userchat" },
  ]

  return (
    <div>
      <div className="container-fluid">
        <div className="row w-100">
          <Header active={active} setActive={setActive} headerData={headerData} handleActive={handleActive} />
            <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Community