import React from 'react'
import { Search } from 'assets';

const SearchUser = ({ getSearchUser, searchUser }) => {
  return (
    <div className='align-items-center px-3 d-flex justify-content-between py-3 border-bottom ChatHeader' >
      <div className="d-flex align-items-center search-container w-100">
        <div className='position-absolute mx-3'><Search width="18" /></div>
        <input type="search" placeholder="Search Users" value={getSearchUser} className="ps-5" onChange={(e) => searchUser(e?.target?.value)} />
      </div>
    </div>
  )
}

export default SearchUser