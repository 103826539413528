import React from "react";
import DroidPopupModuleDetail from "./DroidPopup/DroidPopupModuleDetail";
import PopupModal from "components/PopupModal/index";
import ScatterChart from "components/Charts/ScatterChart";
import { useLocation } from "react-router-dom";
import DroidADAChart from "components/Charts/DroidADAChart";
import TimeChart from "components/Charts/TimeChart";
import SafeV2Chart from "components/Charts/SafeV2Chart";
import IndexV1Chart from "components/Charts/IndexV1Chart";
import SellBuyChart from "components/Charts/SellBuyChart";
import { useSelector } from "react-redux";
import ConverNumber from "components/ConvertNumber";
import moment from "moment";

const DroidModule = ({
  getDefaultRule,
  droid,
  selectComapirDrois,
  setSelectComapirDrois,
  selectFilter,
  getGraphData,
  getTimeModeDroid,
  selectedPortfolios,
  checkIfDeleteClicked,
}) => {
  const location = useLocation();
  const { state } = useLocation();
  const Duplicate = state;
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const ChartsRouting = () => {
    if (
      Duplicate?.droidType?.nav === "simplemodedroid" ||
      droid?.typeCode == 1
    ) {
      return (
        <SellBuyChart
          getGraphData={!getGraphData ? droid : getGraphData}
          selectedPortfolios={selectedPortfolios}
        />
      );
    } else if (
      Duplicate?.droidType?.nav === "timemodedroid" ||
      droid?.typeCode == 2
    ) {
      return (
        <TimeChart
          getGraphData={!getGraphData ? droid : getGraphData}
          getTimeModeDroid={getTimeModeDroid}
        />
      );
    } else if (
      Duplicate?.droidType?.nav === "costmodedroid" ||
      droid?.typeCode == 3
    ) {
      return (
        <DroidADAChart getGraphData={!getGraphData ? droid : getGraphData} />
      );
    } else if (
      Duplicate?.droidType?.nav === "indexmodedroid" ||
      droid?.typeCode == 4
    ) {
      return (
        <IndexV1Chart getGraphData={!getGraphData ? droid : getGraphData} />
      );
    } else if (
      Duplicate?.droidType?.nav === "safemodedroid" ||
      droid?.typeCode == 5
    ) {
      return (
        <SafeV2Chart getGraphData={!getGraphData ? droid : getGraphData} />
      );
    }
  };

  const compairDroid = (e, item) => {
    if (selectComapirDrois?.length === 0) {
      setSelectComapirDrois((prevObject) => [...prevObject, item]);
    } else if (selectComapirDrois.some((e) => e._id === item?._id)) {
      const filteredData = selectComapirDrois.filter(
        (e) => e._id !== item?._id
      );
      setSelectComapirDrois(filteredData);
    } else if (selectFilter !== "") {
      if (selectComapirDrois?.length < 2 && selectFilter === "compare") {
        setSelectComapirDrois((prevObject) => [...prevObject, item]);
      } else {
        setSelectComapirDrois((prevObject) => [...prevObject, item]);
      }
    }
    e.stopPropagation();
  };

  const formattedTime = moment(droid?.updatedAt).format("h:mm A");
  const currentDate = moment(droid?.updatedAt).format("DD-MM-YYYY");

  const buySellStatus = () => {
    if (droid?.OrderStatus === "Buy") {
      return "Buy";
    } else if (droid?.OrderStatus === "Sell") {
      return "Sell";
    } else if (droid?.OrderStatus === "Cancelled") {
      return "Cancelled";
    } else {
      return "Open";
    }
  };
  const buySellStatusResult = buySellStatus();
  return (
    <div
      style={{
        overflowY: "auto",
        minHeight: "636px",
        marginBottom: "0.75rem",
        marginTop: "0.75rem",
      }}
      className={`col-lg-4 col-md-6 col-sm-6 ${
        location?.pathname?.split("/")?.length > 3
          ? "webkit-fill-available"
          : selectFilter !== "compare"
          ? "cursor"
          : ""
      }`}
    >
      <div
        data-bs-toggle={
          location?.pathname?.split("/")?.length === 4 ? "" : "modal"
        }
        data-bs-target={
          selectFilter === "none" || selectFilter === "seeAll"
            ? `#kk${droid?._id}`
            : ""
        }
        className="h-100"
      >
        <div
          // style={{ height: "38rem" }}
          className={`${
            location?.pathname === "/management/droids/simplemodedroid"
              ? "droid-Container-main p-4"
              : "droid-Container p-4 h-100"
          } ${
            location?.pathname?.split("/")?.length !== 3
              ? "droid-Container-height"
              : ""
          }`}
        >
          {selectFilter === "none" ||
          selectFilter === "seeAll" ||
          location?.pathname?.split("/")?.length !== 3 ? (
            ""
          ) : (
            <div
              className="container_filter cursor"
              onClick={(e) => compairDroid(e, droid)}
            >
              <input
                type="checkbox"
                name="acs"
                id="check1"
                disabled={
                  (selectFilter === "compare" &&
                    selectComapirDrois?.length === 2 &&
                    !selectComapirDrois.some(
                      (item) => item._id === droid?._id
                    )) ||
                  (checkIfDeleteClicked && droid?.OrderStatus === "Buy") ||
                  (checkIfDeleteClicked && droid?.OrderStatus === "Sell") ||
                  (checkIfDeleteClicked && droid?.OrderStatus === "Cancelled")
                }
                style={{
                  cursor:
                    (selectFilter === "compare" &&
                      selectComapirDrois?.length === 2 &&
                      !selectComapirDrois.some(
                        (item) => item._id === droid?._id
                      )) ||
                    (checkIfDeleteClicked && droid?.OrderStatus === "Buy") ||
                    (checkIfDeleteClicked && droid?.OrderStatus === "Sell") ||
                    (checkIfDeleteClicked && droid?.OrderStatus === "Cancelled")
                      ? "not-allowed"
                      : "pointer",
                }}
                className="cursor"
              />
            </div>
          )}

          {location?.pathname === "/management/droids" ? (
            <div className="d-flex justify-content-between mb-3">
              <span className={`Status-${buySellStatusResult} badge`}>
                {buySellStatusResult}
              </span>
              <span
                className={`Status-${
                  droid?.status === "active"
                    ? "Active"
                    : droid?.status === "deactive"
                    ? "DeActive"
                    : droid?.status === "delete"
                    ? "Deleted"
                    : "Unknown"
                } badge`}
              >
                {droid?.status === "active"
                  ? "Active"
                  : droid?.status === "deactive"
                  ? "Deactive"
                  : droid?.status === "delete"
                  ? "Deleted"
                  : "Unknown"}
              </span>
            </div>
          ) : (
            ""
          )}

          <div className="d-flex gap-2 justify-content-center">
            <img
              src={require(`assets/droid67Icon.svg`).default}
              alt=""
              className="icon-Container"
              loading="lazy"
            />
            <h2 className="droidHeading">
              {!droid?.name
                ? "Droid Name"
                : droid?.name?.length > 13
                ? droid?.name?.substr(0, 13) + "..."
                : droid?.name}{" "}
            </h2>
          </div>
          {location?.pathname?.split("/")?.length !== 3 ? (
            <h5 className="text-center mt-3">
              {selectedPortfolios?.length} Portfolios
            </h5>
          ) : (
            <div className="d-flex justify-content-between my-3">
              <div className="text-center">
                {" "}
                <h3>
                  {droid?.porfolioDetails?.length !== 0
                    ? droid?.porfolioDetails?.length
                    : "N/A"}
                </h3>{" "}
                <p>Portfolios</p>{" "}
              </div>
              <div className="text-center">
                {" "}
                <h3
                  className={
                    droid?.balance?.status === "profit"
                      ? "textColorGreen"
                      : "textColorRed"
                  }
                >
                  {droid?.balance?.balance?.toFixed(2) || 0}
                </h3>{" "}
                <p>Balance</p>{" "}
              </div>
              <div className="text-center">
                {" "}
                <h3>
                  {droid?.filter?.risk?.length > 0 ? (
                    droid?.filter?.risk
                  ) : (
                    <span>Not found</span>
                  )}
                </h3>{" "}
                <p>Risk</p>{" "}
              </div>
              <div className="text-center">
                {" "}
                <h3 className="d-flex">
                  {" "}
                  {droid?.takeProfit?.takeProfitType === "base" ? "" : "$"}{" "}
                  <ConverNumber data={droid?.takeProfit?.targetProfitPercent} />{" "}
                  {droid?.takeProfit?.takeProfitType === "base" ? "%" : ""}
                </h3>{" "}
                <p>Take Profit</p>{" "}
              </div>
            </div>
          )}
          <div className="d-flex gap-3 justify-content-center my-4">
            {droid?.coinsList?.slice(0, 8)?.map((item, index) => (
              <img
                src={item?.iconUrl}
                width="20"
                height="20"
                alt=""
                key={index}
                loading="lazy"
              />
            ))}
            {droid?.coinsList?.length > 8 && `+${droid?.coinsList?.length - 8}`}
          </div>

          <div className="droidLineBorder2 order-5 mb-4"></div>
          <div style={{ minHeight: "280px" }}>{ChartsRouting()}</div>
          {location?.pathname === "/management/droids" ? (
            <div className="">
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  {droid?.balance?.status === "profit" ||
                  droid?.balance?.status === "loss" ? (
                    <img
                      src={
                        droid?.balance?.status === "profit"
                          ? require(`assets/uptrend.svg`).default
                          : require(`assets/droid67Downward.svg`).default
                      }
                      alt=""
                      className={
                        droid?.balance?.status === "profit"
                          ? "trendIconContainerProfit"
                          : "trendIconContainer"
                      }
                      loading="lazy"
                    />
                  ) : (
                    "N/A"
                  )}
                  <h5
                    className={
                      droid?.balance?.status === "profit"
                        ? "trendTitleProfit"
                        : droid?.balance?.status === "loss"
                        ? "trendTitle"
                        : ""
                    }
                  >
                    Trend
                  </h5>
                  <span>{"Last 30d"}</span>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={require(`assets/refresh.svg`).default}
                    alt=""
                    loading="lazy"
                  />
                  <p className="refreshTime">
                    {currentDate} {formattedTime}{" "}
                  </p>
                </div>
              </div>
              {droid?.createdByID === localStorage.getItem("userID") ? (
                ""
              ) : (
                <p className="refreshTime"> Created By : Broker </p>
              )}
            </div>
          ) : (
            ""
          )}

          {location?.pathname?.split("/").length !== 3 &&
            (state?.droidType?.nav === "simplemodedroid" ||
              state?.droidType?.nav === "timemodedroid" ||
              state?.droidType?.nav === "costmodedroid") && (
              <ScatterChart
                RefreshIcon={
                  <img src={require(`assets/refresh.svg`).default} alt="" />
                }
                RefreshTime={"02:53 AM"}
                getRulesListData={[getDefaultRule]?.concat(
                  Duplicate?.Duplicate === "edit"
                    ? getDroidsDataInfo?.dealRules?.list?.slice(1)
                    : getDroidsDataInfo?.dealRules?.list
                )}
              />
            )}
        </div>
      </div>

      <PopupModal id={`kk${droid?._id}`} backdrop={false}>
        {" "}
        <DroidPopupModuleDetail
          droid={droid}
          buyStatus={buySellStatusResult}
        />{" "}
      </PopupModal>
    </div>
  );
};

export default DroidModule;
