import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import forgotPasswordSchema from "../helper/forgotPasswordSchema";
import { useDispatch } from "react-redux";
import { forgotPassword } from "features/auth/authActions";
import { Link, useNavigate } from 'react-router-dom';
import Button from "./Button";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const onSubmitHandler = async (values) => {
    values.email = values.email.toLowerCase();
    values.type = "input"
    setIsFormSubmitted(true)
    await dispatch(forgotPassword(values))
      .then((response) => {
        setIsFormSubmitted(false)
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          navigate("/login");
        }
      });
  };

  return (
    <div className="forgot-password-container">
      <div className="d-flex login_back_button">
        <div className="h4-One d-flex">Forgot Password</div>
        <Link className="d-flex align-items-center " type="button" to="/login">
          <img src={require(`assets/arrow-left.svg`).default} alt="" loading='lazy'/>
          <button className="main_custom_button" >Login</button>
        </Link>
      </div>
      <div className="d-grid justify-content-center right-0 landingPageForm_content">
        <Formik initialValues={{ email: "" }} validationSchema={forgotPasswordSchema} onSubmit={(values) => onSubmitHandler(values)}>
          {({ errors, touched }) => (
            <Form>
              <div className="input-container">
                <label htmlFor="" > Email </label>
                <Field name="email" type="email" className="custom_input" placeholder="Email" />
                {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}
              </div>
              <div className="d-flex justify-content-between">
                <button type="reset" className="btn btn-outline-primary">Reset</button>
                <button type="submit" className={`primaryButton primaryButtonForgot ${isFormSubmitted && `disabled_button`}`} disabled={isFormSubmitted}>Send</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ForgotPassword