import * as Yup from 'yup';

const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Current Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(128, 'Password must be at maximum 128 characters'),
  new_password: Yup.string()
    .required('New Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain, One Uppercase, One Lowercase, One Number and One Special Case Character Ex: Jordan1@"
    )
    .test('not-same-as-old-password', 'New password must be different from current password', function (value) {
      const { old_password } = this.parent;
      return value !== old_password;
    }),
  confirmnewpassword: Yup.string()
    .required('Confirm Password is required')
    .min(8, 'Password must be at least 8 characters')
    .oneOf([Yup.ref('new_password'), null], 'Confirm Password does not match'),
});

export default changePasswordSchema;
