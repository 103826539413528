import { createSlice } from '@reduxjs/toolkit'
import { addMessageAPI,UserAPI ,getMessagesAPI} from './userChatAction'

const initialState = {
    addMessageInfo: null,
    addMessageError: null,
    isaddMessage: false,
    getMessagesInfo: null,
    isgetMessages: false,
    isgetMessagesError: false,
    isaddMessageError: false,
}

const userMessageSlice = createSlice({
    name: 'addMessage',
    initialState,
    reducers: {
      cleanMessagesError: (state) => {
        state.isgetMessagesError = false
      },
      cleanAddMessagesError: (state) => {
        state.isaddMessageError = false
      },
    },
    extraReducers: {
        [addMessageAPI.pending]: (state) => { },
        [addMessageAPI.fulfilled]: (state, { payload }) => {
          state.isaddMessageError = payload?.statusSend
          state.addMessageInfo = payload
        },
        [addMessageAPI.rejected]: (state, { payload }) => { 
          state.isgetMessagesError= true
          state.addMessageError = payload
        },
        [getMessagesAPI.pending]: (state) => {
        },
        [getMessagesAPI.fulfilled]: (state, { payload }) => {
          state.getMessagesInfo = payload
        },
        [getMessagesAPI.rejected]: (state, { payload }) => { },
        [UserAPI.pending]: (state) => {
        },
        [UserAPI.fulfilled]: (state, { payload }) => {
          state.isaddMessage = true
          state.addMessageInfo = payload
        },
        [UserAPI.rejected]: (state, { payload }) => { },
      },
})

export const { cleanMessagesError, cleanAddMessagesError } = userMessageSlice.actions
export default userMessageSlice.reducer
