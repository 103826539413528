import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const BuyChart = ({data, getGraphData}) => {
  const [series, setSeries] = useState([91]);
  const [options, setOptions] = useState({
    chart: {
      height: 230,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
        track: {
          strokeWidth: '100%',
          margin: 7,
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 19,
            color: '#858585',
            fontSize: '13px',
            fontWeight: '400', 

          },
          value: {
            offsetY: -15,
            color: '#111',
            fontSize: '24px',
            show: true,
            fontWeight: '600', 

          },
        },
      },
    },
    labels: ['Buy'],
    colors: ['#0078E7'], 
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex }) => {
        return `<div className="custom-tooltip">Sell: ${series[seriesIndex]}%</div>`;
      },
    },
  });

  const buySellSettings = getGraphData?.buySellSettings;

  const chartData = (() => {
    if (buySellSettings?.buyPurchaseAmount) {
      if (buySellSettings.buyPurchaseAmountType === 'dollar') {
        return [data];
      } else if (buySellSettings.buyPurchaseAmountType === 'percent') {
        return [buySellSettings.buyPurchaseAmount];
      }
    }
    return [0];
  })();

  return (
    <div className='d-flex justify-content-center '>
      <div id="chart" className='buyChart' >
        <ReactApexChart options={options} series={chartData ? chartData : [0]} type="radialBar" height={230} width={230}/>
      </div>
    </div>
  );
};

export default BuyChart;
