import React from 'react'
import PopupModal from '..'

const DescriptionPopup = (props) => {
  return (
    <PopupModal id='moreInformation1' >
      <div className='popupReviewScreen m-3'>
        <div className="modal-header border-0">
          <div className='addReviewCompanyLogo'>
            <h3>Description</h3>
          </div>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="p-One text-break"> {props?.description || 'Description'} </div>
        </div>
      </div>
    </PopupModal>
  )
}

export default DescriptionPopup