import React from "react";

function Ethereum({color, background}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect width="44" height="44" fill="#060201" rx="22"></rect>
      <path
        fill={color ? color : "#060201"}
        d="M0 0H24V24H0z"
        transform="translate(10 10)"
      ></path>
      <path
        fill={background ? background : "#fff"}
        fillRule="evenodd"
        d="M15.25 22.208l6.906-11.458v.005l.001-.005 6.905 11.456.003.002-6.905 4.082v-.001l-.003.001-.001-.001v.001l-6.906-4.082zm6.906 11.037v.005l-6.906-9.731 6.906 4.08v.002l.001-.002 6.91-4.08-6.91 9.731-.001-.005z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Ethereum;
