import React from 'react'

const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        stroke="#858585"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.093 8.537a2.685 2.685 0 004.048.29l1.61-1.61A2.684 2.684 0 008.957 3.42l-.924.918"
      ></path>
      <path
        stroke="#858585"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.24 7.463a2.685 2.685 0 00-4.048-.29l-1.61 1.61a2.685 2.685 0 003.795 3.797l.919-.918"
      ></path>
    </svg>
  )
}

export default LinkIcon