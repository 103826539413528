import * as React from "react"
const doubleArrow = ({props, fill1, fill2}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill={fill1}
      d="M.47 12.47a.75.75 0 1 0 1.06 1.06L8.06 7 1.53.47A.75.75 0 1 0 .47 1.53L5.94 7 .47 12.47Z"
    />
    <path
      fill={fill2}
      d="M6.47 12.47a.75.75 0 1 0 1.06 1.06L14.06 7 7.53.47a.75.75 0 0 0-1.06 1.06L11.94 7l-5.47 5.47Z"
    />
  </svg>
)
export default doubleArrow
