import React from "react";

function EditIcon({color="#0078E7",width,height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.172 16.253l-.036.01c-.14.035-.38.095-.6.117-.259.025-.902.042-1.43-.486-.528-.528-.511-1.171-.486-1.43.022-.22.082-.46.118-.6l.009-.036.663-2.654.014-.056c.05-.2.13-.533.303-.838.174-.306.417-.547.563-.692l.041-.04 7.243-7.243c.29-.29.603-.604.904-.833.352-.269.855-.558 1.522-.558s1.17.29 1.522.558c.3.229.614.543.904.833l.27.269c.29.29.603.603.832.904.269.352.558.855.558 1.522s-.29 1.17-.558 1.522c-.229.3-.543.614-.833.904L7.452 14.67l-.04.04c-.145.147-.386.39-.692.564-.305.173-.637.254-.838.303l-.056.014-2.654.663zM9.54 4.461l-6.147 6.147c-.195.195-.292.292-.36.41-.066.119-.1.252-.166.52l-.664 2.654c-.09.36-.135.541-.035.641.1.1.28.055.641-.035l2.655-.664c.267-.066.4-.1.518-.167.119-.067.216-.164.41-.359l6.148-6.147a2.484 2.484 0 01-.304-.211 10.199 10.199 0 01-.763-.718l-1.004-1.004c-.281-.28-.535-.534-.718-.763a2.481 2.481 0 01-.21-.304zm2.857-1.923a.248.248 0 00-.037 0 .543.543 0 00-.115.05c-.12.065-.272.166-.534.34-.372.248-.599.4-.751.529a.687.687 0 00-.13.129.25.25 0 00-.009.09c.006.015.028.06.102.152.124.156.317.35.633.666l.95.95c.317.317.51.51.666.634a.685.685 0 00.152.101.25.25 0 00.091-.009.682.682 0 00.13-.129c.127-.152.28-.379.528-.751.174-.261.275-.414.34-.534a.54.54 0 00.05-.115.251.251 0 00-.007-.076.545.545 0 00-.072-.102 7.631 7.631 0 00-.438-.457l-.95-.95c-.182-.182-.302-.301-.397-.386a2.045 2.045 0 00-.202-.132z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default EditIcon;
