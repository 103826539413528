import React from "react";

function CommunityIcon({ fill, color, height = 24, width = 24, selected }) {
  return (
    <>
      {selected ? (
        <svg
          width={width}
          height={height}
          fill={fill ? fill : "none"}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 12C0.447715 12 0 11.5523 0 11V10.425C0 9.69167 0.371 9.104 1.113 8.662C1.85433 8.22067 2.81667 8 4 8C4.21667 8 4.425 8.004 4.625 8.012C4.825 8.02067 5.01667 8.04167 5.2 8.075C4.96667 8.40833 4.79167 8.76667 4.675 9.15C4.55833 9.53333 4.5 9.94167 4.5 10.375V11C4.5 11.5523 4.05228 12 3.5 12H1ZM7 12C6.44771 12 6 11.5523 6 11V10.375C6 9.29167 6.55433 8.41667 7.663 7.75C8.771 7.08333 10.2167 6.75 12 6.75C13.8 6.75 15.25 7.08333 16.35 7.75C17.45 8.41667 18 9.29167 18 10.375V11C18 11.5523 17.5523 12 17 12H7ZM20.5 12C19.9477 12 19.5 11.5523 19.5 11V10.375C19.5 9.94167 19.4457 9.53333 19.337 9.15C19.229 8.76667 19.0667 8.40833 18.85 8.075C19.0333 8.04167 19.221 8.02067 19.413 8.012C19.6043 8.004 19.8 8 20 8C21.2 8 22.1667 8.22067 22.9 8.662C23.6333 9.104 24 9.69167 24 10.425V11C24 11.5523 23.5523 12 23 12H20.5ZM4 7C3.45 7 2.97933 6.804 2.588 6.412C2.196 6.02067 2 5.55 2 5C2 4.43333 2.196 3.95833 2.588 3.575C2.97933 3.19167 3.45 3 4 3C4.56667 3 5.04167 3.19167 5.425 3.575C5.80833 3.95833 6 4.43333 6 5C6 5.55 5.80833 6.02067 5.425 6.412C5.04167 6.804 4.56667 7 4 7ZM20 7C19.45 7 18.979 6.804 18.587 6.412C18.1957 6.02067 18 5.55 18 5C18 4.43333 18.1957 3.95833 18.587 3.575C18.979 3.19167 19.45 3 20 3C20.5667 3 21.0417 3.19167 21.425 3.575C21.8083 3.95833 22 4.43333 22 5C22 5.55 21.8083 6.02067 21.425 6.412C21.0417 6.804 20.5667 7 20 7ZM12 6C11.1667 6 10.4583 5.70833 9.875 5.125C9.29167 4.54167 9 3.83333 9 3C9 2.15 9.29167 1.43733 9.875 0.862C10.4583 0.287333 11.1667 0 12 0C12.85 0 13.5623 0.287333 14.137 0.862C14.7123 1.43733 15 2.15 15 3C15 3.83333 14.7123 4.54167 14.137 5.125C13.5623 5.70833 12.85 6 12 6Z"
            fill="#0078E7"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill={fill ? fill : "none"}
          viewBox="0 0 24 24"
        >
          <path
            fill={color ? color : "#0078E7"}
            d="M1 18a1 1 0 01-1-1v-.575c0-.733.371-1.321 1.113-1.763C1.854 14.221 2.817 14 4 14c.217 0 .425.004.625.012.2.009.392.03.575.063a3.787 3.787 0 00-.525 1.075 4.186 4.186 0 00-.175 1.225V17a1 1 0 01-1 1H1zm6 0a1 1 0 01-1-1v-.625c0-1.083.554-1.958 1.663-2.625 1.108-.667 2.554-1 4.337-1 1.8 0 3.25.333 4.35 1 1.1.667 1.65 1.542 1.65 2.625V17a1 1 0 01-1 1H7zm13.5 0a1 1 0 01-1-1v-.625c0-.433-.054-.842-.163-1.225a3.903 3.903 0 00-.487-1.075c.183-.033.371-.054.563-.063.191-.008.387-.012.587-.012 1.2 0 2.167.22 2.9.662.733.442 1.1 1.03 1.1 1.763V17a1 1 0 01-1 1h-2.5zM12 14.75c-.95 0-1.8.125-2.55.375-.75.25-1.192.542-1.325.875H15.9c-.15-.333-.596-.625-1.338-.875-.741-.25-1.595-.375-2.562-.375zM4 13c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 012 11c0-.567.196-1.042.588-1.425A1.945 1.945 0 014 9c.567 0 1.042.192 1.425.575.383.383.575.858.575 1.425 0 .55-.192 1.02-.575 1.412A1.913 1.913 0 014 13zm16 0c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0118 11c0-.567.196-1.042.587-1.425A1.949 1.949 0 0120 9c.567 0 1.042.192 1.425.575.383.383.575.858.575 1.425 0 .55-.192 1.02-.575 1.412A1.913 1.913 0 0120 13zm-8-1a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 019 9c0-.85.292-1.563.875-2.138S11.167 6 12 6c.85 0 1.562.287 2.137.862S15 8.15 15 9c0 .833-.288 1.542-.863 2.125S12.85 12 12 12zm0-4a.967.967 0 00-.712.287A.968.968 0 0011 9c0 .283.096.52.288.712A.965.965 0 0012 10a.968.968 0 00.713-.288A.967.967 0 0013 9a.97.97 0 00-.287-.713A.97.97 0 0012 8z"
          ></path>
        </svg>
      )}
    </>
  );
}

export default CommunityIcon;
