import React from "react";

function PinIcon({width=20,height=20,pin=false}) {
  return (
    <>
    {!pin ?  <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0078E7"
        fillRule="evenodd"
        d="M8 2.75a.75.75 0 000 1.5h.25v5.073l-1.92 3.842a.75.75 0 00-.08.335v2c0 .414.336.75.75.75h4.25v4.25a.75.75 0 001.5 0v-4.25H17a.75.75 0 00.75-.75v-2a.75.75 0 00-.08-.335l-1.92-3.842V4.25H16a.75.75 0 000-1.5H8zm6.25 1.5h-4.5V9.5a.75.75 0 01-.08.335l-1.92 3.842v1.073h8.5v-1.073l-1.92-3.842a.75.75 0 01-.08-.335V4.25z"
        clipRule="evenodd"
      ></path>
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 12 20" >
      <path
        fill="#0078E7"
        fillRule="evenodd"
        d="M2 .75a.75.75 0 000 1.5h.25v5.073L.33 11.165a.75.75 0 00-.08.335v2c0 .414.336.75.75.75h4.25v4.25a.75.75 0 001.5 0v-4.25H11a.75.75 0 00.75-.75v-2a.75.75 0 00-.08-.335L9.75 7.323V2.25H10a.75.75 0 000-1.5H2z"
        clipRule="evenodd"
      ></path>
    </svg>}
    </>

  );
}

export default PinIcon;
