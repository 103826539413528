import React from "react";

function ReportIcon({ fill, color, height = 24, width = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill ? fill : "none"}
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16H12V14H4V16ZM4 12H12V10H4V12ZM2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H10L16 6V18C16 18.55 15.8043 19.021 15.413 19.413C15.021 19.8043 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9ZM2 2V7V2V18V2Z"
        fill={color ? color : "#0078E7"}
      />
    </svg>
  );
}

export default ReportIcon;
