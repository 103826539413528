import React, { useEffect, useState } from "react";
import Button from "components/Button";
import SecondryButton from "components/Button/SecondryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BTC,
  ETH,
  Tether,
  Cardano,
  LTC,
  XRP,
  SOL,
  BAT,
  EURO,
  USD,
  ArrowDownIcon,
  WarningIcon,
} from "assets";
import { changeDroidStatus } from "features/Management/managementAction";

const ComapareDroidPopup = ({ droid, compare }) => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const [droidurl, setdroidurl] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [viewFullList, setViewFullList] = useState(false);
  const { currencyData, takeProfitTypeData } = useSelector(
    (state) => state?.defaultState
  );

  useEffect(() => {
    if (droid?.typeCode === "1") {
      setdroidurl("simplemodedroid");
    } else if (droid?.typeCode === "2") {
      setdroidurl("timemodedroid");
    } else if (droid?.typeCode === "3") {
      setdroidurl("costmodedroid");
    } else if (droid?.typeCode === "4") {
      setdroidurl("indexmodedroid");
    } else if (droid?.typeCode === "5") {
      setdroidurl("safemodedroid");
    }
  }, [droid]);
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(`/management/droids/${droidurl}/${droid?._id}`, {
      state: {
        Duplicate: "edit",
        type: droid?.type,
        droidType: { nav: droidurl },
      },
    });
  };
  const handleDuplicate = () => {
    navigate(`/management/droids/${droidurl}/${droid?._id}`, {
      state: {
        Duplicate: "Duplicate",
        type: droid?.type,
        droidType: { nav: droidurl },
      },
    });
  };

  const DeleteDroidClick = (id) => {
    const payload = {
      identifier: "delete",
      droidIds: [id],
    };
    dispatch(changeDroidStatus({ userToken, payload })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setDelete(!getDelete);
      }
    });
  };
  const [getDelete, setDelete] = useState(false);
  const deleteClick = () => {
    if (getDelete) {
      setDelete(!getDelete);
    }
  };
  const coinData = [
    {
      coin: "BTC",
      image: <BTC text={"#F7931A"} wd={"30"} ht={"30"} />,
      label: "BTC",
    },
    {
      coin: "ETH",
      image: <ETH text={"#393939"} wd={"30"} ht={"30"} />,
      label: "ETH",
    },
    {
      coin: "XRP",
      image: <XRP text={"#000000"} wd={"30"} ht={"30"} />,
      label: "XRP",
    },
    {
      coin: "LTC",
      image: <LTC text2={"#F8F9FB"} text={"#D9D9D9"} wd={"30"} ht={"30"} />,
      label: "LTC",
    },
    {
      coin: "SOL",
      image: (
        <SOL text={"url(#paint0_linear_1601_37368)"} wd={"30"} ht={"30"} />
      ),
      label: "SOL",
    },
    {
      coin: "USDT",
      image: <Tether text={"#50AF95"} wd={"30"} ht={"30"} />,
      label: "USDT",
    },
    {
      coin: "ADA",
      image: <Cardano text={"#003AC4"} wd={"15"} ht={"15"} bg={"#F8F9FB"} />,
      label: "ADA",
    },
    {
      coin: "BCH",
      image: <Cardano text={"#003AC4"} wd={"15"} ht={"15"} bg={"#F8F9FB"} />,
      label: "BCH",
    },
    {
      coin: "ZEC",
      image: <Cardano text={"#003AC4"} wd={"15"} ht={"15"} bg={"#F8F9FB"} />,
      label: "ZEC",
    },
    {
      coin: "BAT",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <BAT
            text={"url(#paint0_linear_1601_37368)"}
            wd={"15"}
            ht={"15"}
            className="mb-5"
          />
        </div>
      ),
      label: "BAT",
    },
    {
      coin: "EUR",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <EURO wd={"20"} ht={"20"} />
        </div>
      ),
      label: "EUR",
    },
    {
      coin: "GBP",
      image: <XRP text={"#000000"} wd={"30"} ht={"30"} />,
      label: "GBP",
    },
    {
      coin: "LINK",
      image: <XRP text={"#000000"} wd={"30"} ht={"30"} />,
      label: "LINK",
    },
    {
      coin: "USD",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <USD wd={"15"} ht={"15"} />
        </div>
      ),
      label: "USD",
    },
    {
      coin: "USDC",
      image: (
        <div className="DroidCoinoinHeightWeight">
          <USD wd={"15"} ht={"15"} />
        </div>
      ),
      label: "USDC",
    },
  ];
  return (
    <>
      <div className="droidPopupContainer px-3">
        <div className="d-flex justify-content-between align-items-center pt-4 px-3 pb-1">
          {getDelete ? (
            <h3 className="pt-2">Delete Droid</h3>
          ) : (
            <div className="d-flex flex-row gap-2 align-items-center">
              <div className="icon-Container ">
                {" "}
                <img
                  src={require(`assets/droid67Icon.svg`).default}
                  alt=""
                  loading="lazy"
                />{" "}
              </div>
              <h3> {droid?.name} </h3>
            </div>
          )}
        </div>
        {getDelete ? (
          <div className="mt-4 py-2">
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <WarningIcon />
              <h5>Are you sure you want to delete {droid?.name} droid?</h5>
            </div>
            <div className="d-flex gap-3 align-items-center mt-3 ms-6 ps-5"></div>
            <div className="mb-3 d-flex gap-3 justify-content-end pt-2 px-3">
              <SecondryButton text="Cancel" handleClick={deleteClick} />
              <Button
                text="Delete"
                data-bs-dismiss="modal"
                aria-label="Close"
                handleClick={() => DeleteDroidClick(droid?._id)}
              />
            </div>
          </div>
        ) : (
          <>
            <div className={` compare-modalContainer pb-3`}>
              <div className="d-grid gap-2">
                <h4 className="d-flex gap-1 my-3">
                  {" "}
                  Portfolios{" "}
                  <h4 className="h4-Three">
                    {droid?.porfolioDetails?.length !== 0
                      ? droid?.porfolioDetails?.length
                      : "N/A"}
                  </h4>
                </h4>
                {localStorage.getItem("role") === "broker" ? (
                  <>
                    {droid?.clientProfitLoss ? (
                      <React.Fragment>
                        <div className="gap-5 d-flex align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={require(`assets/uptrend.svg`).default}
                              alt="Downtrend"
                              loading="lazy"
                            />
                            <h6>
                              {droid.clientProfitLoss.clientLossCount || "0"}{" "}
                              clients
                            </h6>
                            <h6 className="greenLabel">
                              +{droid.clientProfitLoss.avgClientLoss || "0.00"}%
                            </h6>
                          </div>
                          <div className="d-flex align-items-center gap-2 ms-4">
                            <img
                              src={require(`assets/downtrend.svg`).default}
                              alt="uptrend"
                              loading="lazy"
                            />
                            <h6>
                              {droid.clientProfitLoss.clientProfitCount || "0"}{" "}
                              clients
                            </h6>
                            <h6 className="redLabel">
                              -{droid.clientProfitLoss.avgClientLoss || "0.00"}%
                            </h6>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      "Client data not found"
                    )}

                    {droid?.porfolioDetails?.length > 0 ? (
                      <>
                        {" "}
                        <h6
                          className="my-3 d-flex align-items-center gap-2 cursor"
                          onClick={() => setViewFullList(!viewFullList)}
                        >
                          <img
                            src={require(`assets/threeLines.svg`).default}
                            alt=""
                            loading="lazy"
                          />
                          {viewFullList ? "Hide full list" : "View full list"}
                        </h6>
                        <>
                          {viewFullList && (
                            <>
                              {droid?.porfolioDetails?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div
                                      className="d-flex align-items-center justify-content-between portfoliyo_table_background"
                                      data-toggle="collapse"
                                      href={`#collapseExample${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <img
                                          src={
                                            item?.profileImage
                                              ? item?.profileImage
                                              : require(`assets/commonAvtar.svg`)
                                                  .default
                                          }
                                          alt=""
                                          className={`${
                                            item?.profileImage
                                              ? "droidClientsPicture rounded-circle"
                                              : "w-10 rounded-circle"
                                          }`}
                                          loading="lazy"
                                        />
                                        <div className="cursor h6-One">
                                          {" "}
                                          {item?.name}{" "}
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <ArrowDownIcon
                                          color="#000"
                                          height="30"
                                          width={30}
                                        />
                                      </div>
                                    </div>
                                    {item?.porfolioDetails?.map(
                                      (portItem, pIndex) => (
                                        <div
                                          key={pIndex}
                                          className="collapse show1"
                                          id={`collapseExample${index}`}
                                        >
                                          <table className="table table-bordered portfoliyo_table">
                                            <tbody>
                                              <tr className="">
                                                <td>{portItem?.name}</td>
                                                <td>
                                                  $
                                                  {portItem?.balance?.toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td className="mx-2">
                                                  {portItem?.risk === null
                                                    ? "No"
                                                    : portItem?.risk}{" "}
                                                  Risk
                                                </td>
                                                <td
                                                  className="gap-2 d-flex justify-content-center"
                                                  onMouseEnter={() =>
                                                    setShowTooltip(true)
                                                  }
                                                  onMouseLeave={() =>
                                                    setShowTooltip(false)
                                                  }
                                                >
                                                  {portItem?.coins
                                                    ?.slice(0, 4)
                                                    ?.map((item, index) => {
                                                      const matchingCoin =
                                                        coinData.find(
                                                          (coin) =>
                                                            coin.coin === item
                                                        );

                                                      return (
                                                        <>
                                                          {matchingCoin ? (
                                                            matchingCoin.image
                                                          ) : (
                                                            <img
                                                              className="unknownCoin mt-2"
                                                              src={
                                                                require("assets/unknown.svg")
                                                                  .default
                                                              }
                                                              alt="unknown coin"
                                                              loading="lazy"
                                                            />
                                                          )}
                                                        </>
                                                      );
                                                    })}

                                                  {portItem?.coins?.length >
                                                    4 && (
                                                    <h5 className="d-flex mt-1">
                                                      {portItem?.coins?.length >
                                                      4
                                                        ? "+" +
                                                          (portItem?.coins
                                                            ?.length -
                                                            4)
                                                        : ""}
                                                    </h5>
                                                  )}
                                                  {showTooltip && (
                                                    <div className="customTooltip customTooltipConsition row bg-white z-index-999 justify-content-center">
                                                      {portItem?.coins?.map(
                                                        (item, index) => {
                                                          const matchingCoin =
                                                            coinData.find(
                                                              (coin) =>
                                                                coin.coin ===
                                                                item
                                                            );

                                                          return (
                                                            <div
                                                              key={index}
                                                              className="col-lg-3"
                                                            >
                                                              {matchingCoin && (
                                                                <div
                                                                  key={
                                                                    matchingCoin.coin
                                                                  }
                                                                >
                                                                  <div className="">
                                                                    {
                                                                      matchingCoin.image
                                                                    }
                                                                    <div>
                                                                      {
                                                                        matchingCoin.label
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                              {!matchingCoin && (
                                                                <img
                                                                  className="unknownCoin mt-2"
                                                                  src={
                                                                    require("assets/unknown.svg")
                                                                      .default
                                                                  }
                                                                  alt="unknown coin"
                                                                  loading="lazy"
                                                                />
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      )
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-center gap-3 flex-wrap">
                    {droid?.porfolioDetails?.length > 0
                      ? droid?.porfolioDetails.map((item, index) => (
                          <div
                            className="d-flex align-items-center gap-2"
                            key={index}
                          >
                            {item?.profitLoss < 0 ? (
                              <img
                                src={require(`assets/downtrend.svg`).default}
                                alt="uptrend"
                                loading="lazy"
                              />
                            ) : (
                              <img
                                src={require(`assets/uptrend.svg`).default}
                                alt="Downtrend"
                                loading="lazy"
                              />
                            )}
                            <h6>{item?.name}</h6>
                            <h6>{item?.risk} Risk</h6>
                            <h6
                              className={`d-flex align-items-center ${
                                item?.profitLoss < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {item?.profitLoss < 0 ? "-" : "+"}
                              {Math.abs(item?.profitLoss)}%
                            </h6>
                          </div>
                        ))
                      : "No portfolios found."}
                  </div>
                )}
              </div>
              <hr />
              {droid?.coinsList[0] == null || droid?.coinsList == undefined ? (
                ""
              ) : (
                <>
                  <h4>Coins</h4>
                  <div className="d-flex flex-wrap gap-3 mt-2">
                    {" "}
                    {droid?.coinsList?.map((item, index) => (
                      <>
                        <img
                          src={item?.iconUrl}
                          width="20"
                          height="20"
                          alt=""
                          key={index}
                          loading="lazy"
                        />{" "}
                        <div>{item?.symbol}</div>{" "}
                      </>
                    ))}{" "}
                  </div>
                </>
              )}
              {droid?.typeCode == 1 && (
                <>
                  <hr />
                  <h5 className="d-flex gap-1">Buy/Sell Settings</h5>
                  <div className="d-flex gap-4 mt-2">
                    <div className="text_container">
                      {" "}
                      <p>Buy - Purchase Amount</p>{" "}
                      <h6>
                        {
                          currencyData?.find(
                            (obj) =>
                              obj.name ===
                              droid?.buySellSettings?.buyPurchaseAmountType
                          )?.label
                        }{" "}
                        {droid?.buySellSettings?.buyPurchaseAmount}
                      </h6>{" "}
                    </div>
                    <div className="text_container">
                      {" "}
                      <p>Price - Purchase Value</p>{" "}
                      <h6>
                        {
                          currencyData?.find(
                            (obj) =>
                              obj.name ===
                              droid?.buySellSettings?.pricePurchaseAmountType
                          )?.label
                        }{" "}
                        {droid?.buySellSettings?.pricePurchaseAmount}
                      </h6>{" "}
                    </div>
                  </div>
                  <hr />
                  <h4 className="d-flex gap-1">Take Profit</h4>
                  <div className="d-flex gap-3 mt-2">
                    <div className="text_container">
                      {" "}
                      <p>Target Profit</p>{" "}
                      <h6>
                        {droid?.takeProfit?.targetProfitPercent}{" "}
                        {
                          currencyData?.find(
                            (obj) =>
                              obj.name === droid?.takeProfit?.targetProfitType
                          )?.label
                        }{" "}
                      </h6>{" "}
                    </div>
                    <div className="text_container">
                      {" "}
                      <p>Take Profit Type</p>{" "}
                      <h6>
                        {
                          takeProfitTypeData?.find(
                            (obj) =>
                              obj.name === droid?.takeProfit?.takeProfitType
                          )?.label
                        }
                      </h6>{" "}
                    </div>
                    <div className="text_container">
                      {" "}
                      <p>Trailing Deviation</p>{" "}
                      <h6>
                        {droid?.takeProfit?.trailingDeviation == ""
                          ? 0
                          : droid?.takeProfit?.trailingDeviation}{" "}
                        {
                          currencyData?.find(
                            (obj) =>
                              obj.name === droid?.takeProfit?.targetProfitType
                          )?.label
                        }
                      </h6>{" "}
                    </div>
                  </div>
                  <hr />
                </>
              )}
              {droid?.typeCode == 2 && (
                <>
                  <hr />
                  <h4 className="d-flex gap-1">Time Settings</h4>
                  <table className="table table_border">
                    <tbody>
                      <tr>
                        <th>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Purchase Amount</p>{" "}
                            <h6>
                              {
                                currencyData?.find(
                                  (obj) =>
                                    obj.name ===
                                    droid?.timeSettings?.purchaseAmountType
                                )?.label
                              }{" "}
                              {droid?.timeSettings?.purchaseAmount}
                            </h6>{" "}
                          </div>{" "}
                        </th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Period - Every</p>{" "}
                            <h6>{droid?.timeSettings?.periodEvery} days</h6>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Period - At</p>{" "}
                            <h6>{droid?.timeSettings?.periodAt}</h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">First Buy Date</p>{" "}
                            <h6>{droid?.timeSettings?.firstBuyDate}</h6>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Next Buy Date</p>{" "}
                            <h6>24/06/2023</h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                </>
              )}
              {droid?.typeCode == 3 && (
                <>
                  <hr />
                  <h4 className="d-flex gap-1">Strategy</h4>
                  <table className="table table_border">
                    <tbody>
                      <tr>
                        <th>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Strategy</p>{" "}
                            <h6>{droid?.strategy?.strategy}</h6>{" "}
                          </div>{" "}
                        </th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Profit in Currency</p>{" "}
                            <h6>{droid?.strategy?.profitInCurrency}</h6>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Start Order Type</p>{" "}
                            <h6>{droid?.strategy?.startOrderType}</h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Base Order Size </p>{" "}
                            <h6>
                              {droid?.strategy?.baseOrderSizeAmount}{" "}
                              {droid?.strategy?.baseOrderSizeType}
                            </h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <h4 className="d-flex gap-1">Take Profit</h4>
                  <table className="table table_border">
                    <tbody>
                      <tr>
                        <th>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Target Profit</p>{" "}
                            <h6>
                              {droid?.takeProfit?.targetProfitPercent}{" "}
                              {
                                currencyData?.find(
                                  (obj) =>
                                    obj.name ===
                                    droid?.takeProfit?.targetProfitType
                                )?.label
                              }{" "}
                            </h6>{" "}
                          </div>{" "}
                        </th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Take Profit Type</p>{" "}
                            <h6>
                              {
                                takeProfitTypeData?.find(
                                  (obj) =>
                                    obj.name ===
                                    droid?.takeProfit?.takeProfitType
                                )?.label
                              }
                            </h6>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Trailing Deviation</p>{" "}
                            <h6>
                              {droid?.takeProfit?.trailingDeviation == ""
                                ? 0
                                : droid?.takeProfit?.trailingDeviation}{" "}
                              {
                                currencyData?.find(
                                  (obj) =>
                                    obj.name ===
                                    droid?.takeProfit?.targetProfitType
                                )?.label
                              }
                            </h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <h4 className="d-flex gap-1">Stop Loss</h4>
                  <table className="table table_border">
                    <tbody>
                      <tr>
                        <th>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Stop Loss</p>{" "}
                            <h6>{droid?.stopLoss?.stopLoss}</h6>{" "}
                          </div>{" "}
                        </th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Stop Loss Action</p>{" "}
                            <h6>{droid?.stopLoss?.stopLossAction}</h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <h4 className="d-flex gap-1">SafetyNet Parameters</h4>
                  <table className="table table_border">
                    <tbody>
                      <tr>
                        <th>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Order Size</p>{" "}
                            <h6>
                              {droid?.safetyNetParameters?.orderSizeAmount}
                              {droid?.safetyNetParameters?.orderSizeType}
                            </h6>{" "}
                          </div>{" "}
                        </th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Price Deviation</p>{" "}
                            <h6>
                              {droid?.safetyNetParameters?.priceDeviation}%
                            </h6>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Max Orders Count</p>{" "}
                            <h6>{droid?.safetyNetParameters?.maxOrderCount}</h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Order Volumes</p>{" "}
                            <h6>{droid?.safetyNetParameters?.orderVolumes}</h6>{" "}
                          </div>{" "}
                        </th>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Max Orders Count</p>{" "}
                            <h6>{droid?.safetyNetParameters?.maxOrderCount}</h6>{" "}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div className="d-grid gap-1">
                            {" "}
                            <p className="time_head">Order Step Scale</p>{" "}
                            <h6>
                              {droid?.safetyNetParameters?.orderStepScale}
                            </h6>{" "}
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              {/* {droid?.typeCode == 4 && (
                <>
                  <hr />
                  <h4>Rebalance Strategy </h4>
                  <div className="d-grid gap-1 my-3"> <p className="time_head">Period</p> <h6>{droid?.rebalanceStrategy?.timePeriod} {droid?.rebalanceStrategy?.timePeriodDay}</h6> </div>
                  <h4>Balancing</h4>
                  <div className="d-grid gap-1 mt-3"> <p className="time_head">Stop Loss Time Period</p> <h6>{droid?.balancing?.stopLossTimePeriod}</h6> </div>
                  <hr />
                </>
              )} */}
              {droid?.typeCode == 5 && (
                <>
                  <hr />
                  <h4>Safetynet Rules </h4>
                  <div className="table_Scroll my-3">
                    <table className="table table_border">
                      <tbody>
                        {droid?.safetyNetRules?.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              className={
                                droid?.safetyNetRules?.length - 1 === index
                                  ? ""
                                  : "boarder_bottom"
                              }
                            >
                              <th>{index + 1} </th>
                              <td>Action </td> <th> {item?.action}</th>
                              <td>Threshold</td>{" "}
                              <th>
                                {droid?.thresholdType === "dollar" ? "$" : "%"}
                                {droid?.thresholdAmount}{" "}
                              </th>
                              <td>Period </td> <th> {droid?.period}</th>
                              <td>Trend</td> <th>{item?.pricetrend}</th>
                              <td>Price Trend Amount</td>{" "}
                              <th>
                                {" "}
                                {
                                  currencyData?.find(
                                    (obj) => obj.name === item?.takeProfitType
                                  )?.label
                                }
                                {item?.priceTrendAmount}
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {(droid?.typeCode === "1" ||
                droid?.typeCode === "2" ||
                droid?.typeCode === "3") && (
                <>
                  <h4 className="mb-2 mt-2">Deal Rules</h4>
                  <div className="d-flex gap-3 align-items-center">
                    <div className="d-grid gap-2 text_container">
                      <p>Repeat</p>
                      <h6>
                        {droid?.dealRules?.repeat
                          ? droid?.dealRules?.repeat
                          : "none"}
                      </h6>
                    </div>
                    <div className="d-grid gap-2 text_container">
                      <p>Cooldown</p>
                      <h6>{droid?.dealRules?.cooldownseconds} seconds</h6>
                    </div>
                  </div>
                </>
              )}
              {droid?.dealRules?.list?.length > 0 ? (
                <div className="table_Scroll">
                  <table className="table deal-rules mt-3 ">
                    <tbody>
                      {droid?.dealRules?.list?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td scope="row ">
                              <h6 className="p-Three MT-1">{index + 1}</h6>
                            </td>
                            <td>{item?.takeProfitType}</td>
                            <td className="">
                              <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                Buy Threshold
                                <h6 className="p-Three-Black">
                                  {" "}
                                  ${item?.buyThreshold || 0}
                                </h6>{" "}
                              </h6>
                            </td>
                            <td>
                              <h6 className="d-flex p-Three gap-1 MT-2">
                                Sell Threshold
                                <h6 className="p-Three-Black">
                                  {" "}
                                  ${item?.sellThreshold}
                                </h6>{" "}
                              </h6>
                            </td>
                            {droid?.typeCode == 1 ? (
                              <td className="">
                                <h6 className="d-flex p-Three mx-3 gap-1 MT-2">
                                  Price Purchase
                                  <h6 className="p-Three-Black">
                                    {" "}
                                    ${item?.pricePurchaseValue}
                                  </h6>{" "}
                                </h6>
                              </td>
                            ) : (
                              ""
                            )}
                            {droid?.typeCode == 1 ? (
                              <td>
                                <h6 className="d-flex p-Three gap-1 MT-2">
                                  Sell Purchase
                                  <h6 className="p-Three-Black">
                                    {" "}
                                    ${item?.sellPurchaseValue}
                                  </h6>{" "}
                                </h6>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
            </div>
            {compare ? (
              ""
            ) : (
              <div className="justify-content-end d-flex gap-3 my-3">
                <SecondryButton
                  text="Delete"
                  handleClick={() => setDelete(!getDelete)}
                  icon={
                    <img
                      src={require(`assets/deleteIcon.svg`).default}
                      alt=""
                      loading="lazy"
                    />
                  }
                  className={"align-items-center"}
                />
                <Button
                  text="Duplicate"
                  handleClick={handleDuplicate}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  icon={
                    <img
                      src={require(`assets/duplicateIcon.svg`).default}
                      alt=""
                      loading="lazy"
                    />
                  }
                />
                <Button
                  text="Edit"
                  handleClick={handleEdit}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  icon={
                    <img
                      src={require(`assets/editIconInButton.svg`).default}
                      alt=""
                      loading="lazy"
                    />
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ComapareDroidPopup;
