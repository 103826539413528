import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBroker = createAsyncThunk(
  "community/getBroker",
  async ({ userToken, filterData, userType }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      let type_trader = filterData?.Trader?.join(",");
      let type_company = filterData?.Company?.join(",");
      let type_level = filterData?.level?.join(",");
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL
        }/api/getUsers?type_trader=${type_trader}&type_company=${type_company}&level=${type_level}&page=${filterData?.page}&search=${filterData?.search}&user_type=${userType === "broker" ? "retailer" : "broker"
        }`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const checkConnected = createAsyncThunk(
  "community/checkConnected",
  async ({ userToken, userProfileId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const request = {
        userProfile: userProfileId,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/checkConnection`,
        request,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const sendConnectionRequest = createAsyncThunk(
  "community/sendConnectionRequest",
  async ({ userToken, getDetails, getConnection }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const request = {
        receiver: getDetails?.userId,
        identifier: (parseInt(getConnection?.status_id) + 1).toString(),
        type: "manual",
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/sendConnectionRequest`,
        request,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.message;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendConnectionRequestInvitedUser = createAsyncThunk(
  "community/sendConnectionRequestInvitedUser",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const isUserInvited = JSON.parse(localStorage.getItem("invitedUser"));
      const invitedUserRegistered = localStorage.getItem(
        "invitedUserRegistered"
      );
      const request = {
        receiver: isUserInvited?.broker,
        identifier: isUserInvited?.status,
        email: isUserInvited?.endUser,
        type: "bulk",
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/sendConnectionRequest`,
        request,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.message;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getTypeCompany = createAsyncThunk(
  "community/getTypeCompany",
  async (userToken, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getTypeCompany`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.types;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getBrokerDetail = createAsyncThunk(
  "community/getBrokerDetail",
  async ({ userToken, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getUserDetails/${id}`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.user;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//-----------------------get Retailer profile------------------------//
export const getRetailerDetail = createAsyncThunk(
  "community/getBrokerDetail",
  async ({ userToken, id, url }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getProfile?${url}=${id}`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postReviewData = createAsyncThunk(
  "community/postReview",
  async (
    { userToken, userProfile_id, getRating, getDescription },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const request = {
        review_to: userProfile_id,
        rating: getRating,
        description: getDescription,
        key: "date",
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/postReview`,
        request,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.messageData;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getReviewsData = createAsyncThunk(
  "community/getReviews",
  async ({ userToken, ProfileId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getReviews/${ProfileId}`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editReviewsData = createAsyncThunk(
  "community/editReviews",
  async (
    { userToken, ProfileId, getRating, getDescription },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const request = {
        rating: getRating,
        description: getDescription,
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/editReview/${ProfileId}`,
        request,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.response;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteReviewsData = createAsyncThunk(
  "community/deleteReviews",
  async ({ userToken, reviewID }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASEURL}/api/deleteReview/${reviewID}`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.response;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postaskQuestion = createAsyncThunk(
  "community/postaskQuestion",
  async (
    { userToken, brokerId, getAstQustionInput, name, messageSend },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const request = {
        brokerId: brokerId,
        question: getAstQustionInput,
        name: name,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/askQuestion`,
        request,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return { ...data?.data?.question, messageSend: messageSend };
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
