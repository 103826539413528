
import { clearCurrentLogin } from "features/auth/authSlice";
import { toast } from "react-toastify";

const toastConfiguration = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
}

const ErrorCode = (props) => {
  if (props?.message === "invalid token" || props?.message === "jwt expired") {
    localStorage.removeItem('userToken')
    clearCurrentLogin()
    toast.error('Session Expired', { toastId: 1, ...toastConfiguration })
    setTimeout(window.location.reload(), 3000)
  } else if (props?.statusCode === 400) {
    toast.error(props?.message?.replace(/[{}"[\]]/g, ''), { toastId: 1, ...toastConfiguration })
  }
}

export default ErrorCode