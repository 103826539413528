import React, { useState } from 'react';
import DottedChart from '../../../components/Charts/DottedChart';
import ProgressBar from '../../../components/Charts/ProgressBar';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updatePortfolioRisk } from "features/profile/profileAction";
import ThreeBarIcon from 'assets/JsImage/ThreeBarIcon';

const dropdownOptions = [
    { img: <img src={require(`assets/riskHigh.svg`).default} alt='' loading='lazy' />, label: 'High' },
    { img: <img src={require(`assets/riskMedium.svg`).default} alt='' loading='lazy' />, label: 'Medium' },
    { img: <img src={require(`assets/riskLow.svg`).default} alt='' loading='lazy' />, label: 'Low' },
];
const JoExchange = ({ getCancle, setCancle, riskSave, setRiskSave, profileData, edit }) => {
    const [showAllData, setShowAllData] = useState(false);
    const makerPercentage = 21;
    const takerPercentage = 21;
    const { userToken } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const capitalize = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1)
    }

    const convertUnixToLocal = (unixTime) => {
        const milliseconds = unixTime * 1000;
        const date = new Date(milliseconds);
        const formattedTime = moment(date.toLocaleString(), 'M/D/YYYY, h:mm:ss A').format("MMMM DD, YYYY");
        return formattedTime;
    };

    const displayData = profileData?.exchange === undefined ? [] : showAllData ? profileData?.exchange : profileData?.exchange?.slice(0, 1);
    const [getRiskNewUpdate, setRiskNewUpdate] = useState([])
    const handleDropdownChange = (element, item, index) => {

        const filterRisk = getRiskNewUpdate.find((e) => e.portfolioId === item?._id);
        if (filterRisk == undefined) {
            setRiskNewUpdate((prevObject) => [...prevObject, { portfolioId: item?._id, risk: element?.label }])
        } else {
            const newArray = getRiskNewUpdate.filter((e) => e.portfolioId !== item?._id);
            setRiskNewUpdate([...newArray, { portfolioId: item?._id, risk: element?.label }])
        }
    }
    useEffect(() => {
        if (riskSave == 'save') {
            setRiskSave()
            dispatch(updatePortfolioRisk({ userToken, sendPortfolioRisk: getRiskNewUpdate })).then((response) => {
                if (response.meta && response.meta.requestStatus === "fulfilled") {

                }
            });
        }
    }, [riskSave])

    useState(() => {
        setCancle('')
        setRiskNewUpdate([])
    }, [getCancle])

    useEffect(() => {
        setRiskNewUpdate([])
    }, [edit])
    return (
        <div className='container-fluid '>
            <div classname='row'>
                <div className='d-flex justify-content-between '>
                    <h3>Exchange</h3>
                    {edit ? (
                        <div className="d-flex align-items-center gap-2">
                            <div className="disabled-link" onClick={() => { }}>
                                <ThreeBarIcon color='#c2c2c2' />
                            </div>
                            <h5 className="text-body-tertiary">View Exchange Fees</h5>
                        </div>
                    ) : (
                        <Link to='/exchange' className="d-flex align-items-center gap-2">
                            <ThreeBarIcon color='#0078E7' />
                            <h5 className="text-blue">View Exchange Fees</h5>
                        </Link>
                    )}
                </div>
                {displayData.length === 0 && <div className='mb-4'><span>Exchange Not Found</span></div>}
                {displayData?.map((item, index) => {
                    const isLastIndex = index === displayData.length - 1
                    return (
                        <div key={index} className='row mt-2'>
                            <div className='d-flex align-items-center mb-4 justify-content-between row'>
                                <div className='d-flex col-3  '>
                                    <div className='exchangeIcons'>
                                        <img src={item?.exchange?.icon} alt='Exchange Icon' className='' height={20} width={'auto'} loading='lazy' />
                                    </div>
                                </div>
                                <div className='col-3 d-flex justify-content-center'>
                                    <div className='text-cards'>
                                        <h3 className='text-break'>{item?.exchange?.totalFundsAllocated}</h3>
                                        <h6 className='text-break'>Total Funds Allocated ($)</h6>
                                    </div>
                                </div>
                                <div className='col-3 d-flex gap-2 align-items-center justify-content-between'>
                                    <div className='verticle-border' />
                                    <div className='text-cards'>
                                        <h3 className='text-break'>{item?.exchange?.totalFundsDeals}</h3>
                                        <h6 className='text-break'>Total Funds in Deals ($)</h6>
                                    </div>
                                    <div className='verticle-border' />
                                </div>
                                <div className='col-3 d-flex justify-content-center'>
                                    <div className='text-cards'>
                                        <h3 className='text-break'>{item?.exchange?.Last30DaysPayments?.toFixed(2)}</h3>
                                        <h6 className='text-break'>Payments, Last 30 Days ($)</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex gap-2 mb-2'>
                                <h5>Maker</h5>
                                <p className='p-One'>{item?.fees?.makerFeeRate}%</p>
                                <ProgressBar icon={item?.exchange?.name == undefined ? "" : item?.exchange?.name} makerPercentage={makerPercentage * item?.fees?.makerFeeRatePercent / 100} />
                            </div>
                            <div className='d-flex gap-2 mb-4'>
                                <h5>Taker</h5>
                                <p className='p-One mx-1'>{item?.fees?.takerFeeRate}%</p>
                                <DottedChart icon={item?.exchange?.name == undefined ? "" : item?.exchange?.name} takerPercentage={takerPercentage * item?.fees?.takerFeeRatePercent / 100} />
                            </div>
                            <div className='d-flex gap-2 mb-2'>
                                <h5>Risk</h5>
                                <p className='p-One'>{capitalize(item?.exchange?.risk)}</p>
                            </div>
                            <div className='d-flex gap-2'>
                                <h5>Location</h5>
                                <p className='p-One mx-1'>{item?.exchange?.location}</p>
                            </div>
                            <div className='mt-4'>
                                <h3 className=''>Portfolios Risk</h3>
                                {item?.exchange?.portfolio?.length === 0 && <span>No portfolios found.</span>}
                                <div className={edit ? 'd-flex  row' : ""}>
                                    {!item?.exchange?.portfolio ? '' : item?.exchange?.portfolio?.map((e, index) => {
                                        const newArray = getRiskNewUpdate.filter((ele) => ele.portfolioId == e?._id);
                                        return (
                                            <>
                                                {(newArray?.length > 0 && newArray[0]?.risk !== undefined ? newArray[0]?.risk : e?.risk) !== null ? <div className={edit ? 'col-lg-4 mb-3 d-flex gap-2 mt-2' : " mt-2 mb-3 d-flex gap-2"} key={index}>
                                                    {edit ? <div>
                                                        <h5>{e?.name}</h5>
                                                        {edit && <div className={`btn-group simpledropdown portfolio_buttons mt-2 `}>
                                                            <button className="btn dropdown-toggle d-flex justify-content-between align-items-center " type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" >
                                                                <div className='d-flex gap-3'>
                                                                    {(newArray?.length > 0 && newArray[0]?.risk !== undefined ? newArray[0]?.risk : e?.risk) && <img src={require(`assets/risk/risk_${newArray?.length > 0 && newArray[0]?.risk !== undefined ? newArray[0]?.risk.charAt(0).toUpperCase() + newArray[0]?.risk.slice(1) : e?.risk?.charAt(0).toUpperCase() + e?.risk?.slice(1)}.svg`)} alt={e?.risk} loading='lazy' />}
                                                                    {newArray?.length > 0 && newArray[0]?.risk !== undefined ? newArray[0]?.risk : e?.risk}
                                                                </div>
                                                                <img src={require('assets/arrowDown.svg').default} alt='' className='ML-6' loading='lazy' />
                                                            </button>
                                                            <ul className="dropdown-menu profile_riskDropdown">
                                                                {dropdownOptions?.map((element, index) => (
                                                                    <li key={index} className={`dropdown-item py-3 gap-2 justify-content-between d-flex ${dropdownOptions.length - 1 === index ? '' : 'border-bottom'}`} onClick={() => handleDropdownChange(element, e, index)}>
                                                                        {element.label} {element.img}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>}
                                                        {!edit && <span> <p className='p-Two-gray'>{e.risk}</p> </span>}
                                                    </div> :
                                                        <>
                                                            {e?.risk && <><img src={require(`assets/risk/risk_${e?.risk?.charAt(0).toUpperCase() + e?.risk?.slice(1)}.svg`)} alt={e?.risk} loading='lazy' /><div><h5>{e?.name}</h5> <span classname="h6-One-Grey">{e?.risk?.charAt(0)?.toUpperCase() + e?.risk?.slice(1)}</span></div> </>}
                                                        </>
                                                    }
                                                </div> : ''}
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            {!isLastIndex && showAllData ? <hr className='my-3' /> : ""}
                        </div>
                    )
                })}
                {profileData?.exchange?.length > 1 && <div className='d-flex justify-content-end'>
                    <span to='/exchange' className='d-flex align-items-center gap-2 cursor' onClick={() => setShowAllData(!showAllData)}>
                        <img src={require(showAllData ? `assets/threeLinesSlash.svg` : `assets/threeLines.svg`).default} alt='' loading='lazy' />
                        <h5 className='text-blue'>{showAllData ? 'Minimize' : 'View more'}</h5>
                    </span>
                </div>}
                <div className='mb-5 mt-3'>
                    <h3>Security</h3>
                    {edit ? <>
                        <p className='h6-One-Grey mt-2 d-flex gap-1 align-items-baseline ' data-bs-toggle="modal" data-bs-target="#ChangePasswordPopup " >Click here to <h5 className='text-primary cursor'>Change Password</h5></p>
                        <p className='h6-One-Grey '>Note : Page will automatically redirect on login page when password has been changed successfully.</p>
                    </> :
                        <h5 className='d-flex gap-2 mt-2'>Password
                            {profileData?.last_updated_password === null ?
                                <p className='p-One'>Last changed : none</p> :
                                <p className='p-One'>{`Last changed 
                                    ${convertUnixToLocal(profileData?.last_updated_password)}`}
                                </p>}
                        </h5>}
                </div>
            </div>
        </div>
    );
};

export default JoExchange;
