import React from "react";
import "./ChartCss/Chart.css";
import RepeatCounter from "./Accessories/RepeatCounter";
import SAfeV2ChartLabels from "./Accessories/SAfeV2ChartLabels";

export default function SafeV2Chart({ getGraphData }) {
  return (
    <div className=" d-flex flex-column my-3  py-2 mb-4 gap-2  ">
      <div className="cotainerSafeV2SubTop d-flex flex-column">
        <div className=" d-flex flex-row gap-2">
          <h5>Safetynet Rules</h5>
          <h5 className="text-muted">
            {getGraphData?.safetyNetRules
              ? getGraphData?.safetyNetRules?.length
              : 0}
          </h5>
        </div>
        <div className=" d-flex flex-row align-items-center d-flex gap-3 order-1">
          <div className="safeV2LabelsContainer d-flex flex-row align-items-start gap-2">
            <SAfeV2ChartLabels getGraphData={getGraphData} />
          </div>
        </div>
      </div>
      <div className="safeV2RepeatCounter">
        <RepeatCounter
          number={
            getGraphData?.safetyNetRules
              ? getGraphData?.safetyNetRules?.length
              : 0
          }
        />
      </div>
    </div>
  );
}
