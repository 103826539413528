import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DroidModuleSettings from "../../ManagementDroids/DroidModules/DroidModuleSettings";
import SimpleModeDroidChild from "../DroidSettings/SimpleModeDroidChild";
import CostAverageDroidChild from "./CostAverageDroidChild";
import IndexModeDroidChild from "./IndexModeDroidChild";
import SaftynetRulesModeDroidChild from "./SaftynetRulesModeDroidChild";
import TimeModeDroidSettingChild from "./TimeModeDroidSettingChild";
import SingleSlelectDropDown from "components/Dropdown/SingleSlelectDropDown";
import SecondryButton from "components/Button/SecondryButton";
import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import { EditIcon } from "assets";
import { useDispatch, useSelector } from "react-redux";
import { DroidsData } from "features/DroidState/DroidsStateAction";
import moment from "moment";
import {
  clearDroidDcaError,
  setDroidDcaError,
} from "features/ErrorDcaDroid/ErrorSlice";

const SimpleModeDroidSetting = ({
  setError,
  getError,
  getSingleCoinData,
  setSingleCoinData,
  getOrderStatus,
  getDefaultRule,
  setDefaultRule,
  getbidValue,
  hasBuyTriggered,
  checkBalance,
  getCoinListData,
  selectedPortfolios,
  minAmount,
  ruleListError,
  ruleErrorFun,
}) => {
  const [takeProfitType, settakeProfitType] = useState("Immediate");
  const { takeProfitTypeData } = useSelector((state) => state?.defaultState);
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const { state } = useLocation();
  let location = useLocation();
  const dispatch = useDispatch();
  const droidType = state;
  const [sellLimit, setsellLimit] = useState(false);
  const [sellLimitMsg, setsellLimitMsg] = useState("");
  const [getEditDealRualMode, getSditDealRualMode] = useState({
    status: false,
  });
  const [sellLessThanBuy, setsellLessThanBuy] = useState(false);
  // const [getError, setError] = useState({ name: '', error: '' })
  const [getErrortargetProfitPercent, setErrortargetProfitPercent] = useState({
    isValid: false,
    error: "",
  });
  const [getErrorTraillingPercent, setErrorTraillingPercent] = useState({
    isValid: false,
    error: "",
  });
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  let path = location?.pathname.split("/");

  const checkRemainingBalance = (portfolios) => {
    const data = getDroidsDataInfo;
    const buyPurchaseAmount = +data?.buySellSettings?.buyPurchaseAmount || 0;
    let maxValue = Math.min(
      ...portfolios.map((portfolio) => portfolio.balance),
      Infinity
    );

    const dealRulesAmount = data?.dealRules?.list.reduce(
      (acc, rule) => acc + Number(rule.pricePurchaseValue),
      0
    );
    const remainingBalance = maxValue - buyPurchaseAmount - dealRulesAmount;
    if (getEditDealRualMode?.status) {
      return (
        remainingBalance +
        +data.dealRules.list[getEditDealRualMode.index].pricePurchaseValue
      );
    }
    if (remainingBalance < 0) {
      return 0;
    }

    return remainingBalance.toFixed(2);
  };

  const checkdroidMin = () => {
    if (getDroidsDataInfo?.type === "Index Mode") {
      setsellLimit(false);
      setsellLimitMsg("");
    }
    if (getDroidsDataInfo?.type === "Time Mode") {
      // this if condition is only for now, upto when rules repeat is not fixed frombackend

      /*************************************************************************************/
      if (
        droidType?.droidType?.nav === "timemodedroid" &&
        getDroidsDataInfo?.dealRules?.repeat !== 1
      ) {
        droidDataDispatch({
          dealRules: { ...getDroidsDataInfo?.dealRules, ["repeat"]: 1 },
        });
      }
      /**************************************************************************************/

      if (
        Number(getDroidsDataInfo?.timeSettings?.pricePurchaseAmount) + 1e-10 >
          Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent) &&
        getDroidsDataInfo?.takeProfit?.takeProfitType !== "base"
      ) {
        setsellLimit(true);
        setsellLimitMsg("Please enter value greater than Price-Purchase Value");
      } else {
        setsellLimit(false);
        setsellLimitMsg("");
      }
    }
    if (getDroidsDataInfo?.type === "Safe Mode") {
      if (
        Number(getDroidsDataInfo?.price) + 1e-10 >
          Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent) &&
        getDroidsDataInfo?.takeProfit?.takeProfitType !== "base"
      ) {
        setsellLimit(true);
        setsellLimitMsg("Please enter value greater than Price-Purchase Value");
      } else {
        setsellLimit(false);
        setsellLimitMsg("");
      }
    }
    if (getDroidsDataInfo?.buySellSettings?.startOrderType === "limit") {
      if (getDroidsDataInfo?.type === "Simple Mode") {
        // this if condition is only for now, upto when rules repeat is not fixed frombackend

        /*************************************************************************************/
        if (
          droidType?.droidType?.nav === "simplemodedroid" &&
          getDroidsDataInfo?.dealRules?.repeat !== 1
        ) {
          droidDataDispatch({
            dealRules: { ...getDroidsDataInfo?.dealRules, ["repeat"]: 1 },
          });
        }
        /**************************************************************************************/

        if (
          Number(getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount) +
            1e-10 >
            Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent) &&
          getDroidsDataInfo?.takeProfit?.takeProfitType !== "base"
        ) {
          setsellLimit(true);
          setsellLimitMsg(
            "Please enter value greater than Price-Purchase value"
          );
        } else {
          setsellLimit(false);
          setsellLimitMsg("");
        }
      }
    } else if (getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit") {
      if (getDroidsDataInfo?.type === "Cost Mode") {
        // this if condition is only for now, upto when rules repeat is not fixed frombackend

        /*************************************************************************************/
        if (
          droidType?.droidType?.nav === "costmodedroid" &&
          getDroidsDataInfo?.dealRules?.repeat !== 1
        ) {
          droidDataDispatch({
            dealRules: { ...getDroidsDataInfo?.dealRules, ["repeat"]: 1 },
          });
        }
        /**************************************************************************************/

        /***************************************************************************************/
        if (getDroidsDataInfo?.takeProfit?.takeProfitType === "value") {
          let sellPrice = getDroidsDataInfo?.takeProfit?.targetProfitPercent;
          let buyPrice = getDroidsDataInfo?.dcaStrategy?.limit;

          let profitPercent = ((sellPrice - buyPrice) / buyPrice) * 100;

          if (getDroidsDataInfo?.newProfit !== profitPercent) {
            droidDataDispatch({
              newProfit: profitPercent,
            });
          }
        }
        /****************************************************************************************/

        if (
          Number(getDroidsDataInfo?.dcaStrategy?.limit) + 1e-10 >
            Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent) &&
          getDroidsDataInfo?.takeProfit?.takeProfitType !== "base"
        ) {
          setsellLimit(true);
          setsellLimitMsg("Please enter value greater than Threshold");
        } else {
          setsellLimit(false);
          setsellLimitMsg("");
        }
      }
    }
  };

  const fetchMaxBalanceToEnter = (portfolios) => {
    let maxValue = 0;
    portfolios.forEach((portfolio) => {
      if (maxValue === 0) {
        maxValue = portfolio.balance;
      } else if (portfolio.balance <= maxValue) {
        maxValue = portfolio.balance;
      }
    });
    return maxValue;
  };

  const droidDataDispatch = (item) => {
    const payload = { ...getDroidsDataInfo, ...item };
    dispatch(DroidsData({ payload })).then((res) => {});
  };

  function hasFailedStatus() {
    return getOrderStatus?.some((item) => item.status === "Filled");
  }
  const hasFailed = hasFailedStatus();

  const childComponent = () => {
    switch (droidType?.droidType?.nav) {
      // Buy/Sell setting from SIMPLE MODE DROID
      case "simplemodedroid":
        return (
          <SimpleModeDroidChild
            setError={setError}
            getError={getError}
            hasFailed={hasFailed}
            droidDataDispatch={droidDataDispatch}
            minBalance={fetchMaxBalanceToEnter(checkBalance)}
            selectedPortfolio={checkBalance}
            minAmount={minAmount}
            getbidValue={getbidValue}
          />
        );
      // Buy/Sell setting from COST AVERAGE MODE DROID
      case "costmodedroid":
        return (
          <CostAverageDroidChild
            droidDataDispatch={droidDataDispatch}
            getbidValue={getbidValue}
            minBalance={fetchMaxBalanceToEnter(checkBalance)}
            minAmount={minAmount}
          />
        );
      // Time Setting setting from Time mode droid
      case "timemodedroid":
        return (
          <TimeModeDroidSettingChild
            getbidValue={getbidValue}
            droidDataDispatch={droidDataDispatch}
            minBalance={fetchMaxBalanceToEnter(checkBalance)}
            minAmount={minAmount}
          />
        );
      case "indexmodedroid":
        return (
          <IndexModeDroidChild
            droidDataDispatch={droidDataDispatch}
            minBalance={fetchMaxBalanceToEnter(checkBalance)}
          />
        );
      case "safemodedroid":
        return (
          <SaftynetRulesModeDroidChild
            droidDataDispatch={droidDataDispatch}
            getbidValue={getbidValue}
            minAmount={minAmount}
            minBalance={fetchMaxBalanceToEnter(checkBalance)}
          />
        );
      default:
        return navigate("/management/droids");
    }
  };

  const [getRulesList, setRulesList] = useState({
    sellThreshold: "",
    buyThreshold: "",
    coin: "",
    takeProfitType: "Immediate",
  });

  const buyThresholdData = (event) => {
    const onlyNumberRegex = /^\d*\.?\d{0,2}$/;
    const { name, value } = event.target;
    if (parseFloat(value) < 0) {
      setError({ name: name, error: "Please input a positive value." });
    } else if (!onlyNumberRegex.test(value)) {
      setError({
        name: name,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
    } else if (
      fetchMaxBalanceToEnter(checkBalance) == 0 &&
      name === "pricePurchaseValue"
    ) {
      setRulesList((prevObject) => ({ ...prevObject, [name]: "" }));
      setError({ name: name, error: `Please choose a portfolio.` });
    } else if (
      name === "pricePurchaseValue" &&
      parseFloat(value) <= parseFloat(minAmount)
    ) {
      setError({
        name: name,
        error: `Please input value greater than $${minAmount}`,
      });
      setRulesList((prevObject) => ({ ...prevObject, [name]: value }));
    } else if (
      Number(value) > checkRemainingBalance(checkBalance) &&
      name === "pricePurchaseValue"
    ) {
      setError({
        name: name,
        error:
          checkRemainingBalance(checkBalance) == 0
            ? "You don't have any remaining balance in your portfolio."
            : `Please verify the maximum entered amount $${checkRemainingBalance(
                checkBalance
              )}.`,
      });
    } else {
      if (value.length > 8) {
        setError({
          name: name,
          error: "The value should not exceed 8 digits.",
        });
      } else {
        setError({ name: "", error: "" });
        setRulesList((prevObject) => ({ ...prevObject, [name]: value }));
      }
    }
  };
  useEffect(() => {
    setError({ name: "", error: "" });
  }, []);

  useEffect(() => {
    const { buyThreshold, sellThreshold } = getRulesList;
    console.log(buyThreshold, typeof sellThreshold, "buysell");
    if (
      sellThreshold &&
      buyThreshold &&
      Number(sellThreshold) < Number(buyThreshold) + 1e-10
    ) {
      setsellLessThanBuy(true);
    } else {
      setsellLessThanBuy(false);
    }
  }, [getRulesList.sellThreshold]);

  useEffect(() => {
    checkdroidMin();
  }, [getDroidsDataInfo]);

  const sellAmoutCalculate = () => {
    const initialInvestment = Number(getRulesList?.pricePurchaseValue);

    // Purchase price and selling price
    const purchasePrice = Number(getRulesList?.buyThreshold);
    const sellingPrice = Number(getRulesList?.sellThreshold);

    const precent =
      ((sellingPrice - purchasePrice) * initialInvestment) / purchasePrice;
    const result = precent + initialInvestment;
    return result;
  };

  const sellAmountCal = sellAmoutCalculate();

  const rulesListAdd = () => {
    let takeProfitTypeData = getRulesList?.takeProfitType;
    const data = [];
    const RualList = { ...getRulesList, sellPurchaseValue: sellAmountCal };

    if (getEditDealRualMode?.status) {
      const indexing = state?.Duplicate === "edit" ? 1 : 0;
      const updatedArray = [...getDroidsDataInfo?.dealRules?.list];
      updatedArray?.splice(getEditDealRualMode?.index + indexing, 1);
      updatedArray.splice(getEditDealRualMode?.index + indexing, 0, RualList);
      droidDataDispatch({
        dealRules: { ...getDroidsDataInfo?.dealRules, list: updatedArray },
      });
      getSditDealRualMode({ status: false, index: "" });
    } else {
      if (!getDroidsDataInfo?.dealRules?.list) {
        data.push(RualList);
      } else {
        data.push(...getDroidsDataInfo?.dealRules?.list);
        data.push(RualList);
      }
      droidDataDispatch({
        dealRules: { ...getDroidsDataInfo?.dealRules, list: data },
      });
    }

    if (droidType?.droidType?.nav === "simplemodedroid") {
      if (
        !(
          getDroidsDataInfo?.dealRules?.repeat ===
          (state?.Duplicate === "edit" || state?.Duplicate === "Duplicate"
            ? [getDefaultRule]?.concat(
                getDroidsDataInfo?.dealRules?.list?.slice(1)
              )
            : [getDefaultRule]?.concat(getDroidsDataInfo?.dealRules?.list)
          )?.length
        )
      ) {
        //
      }
      setRulesList({
        buyThreshold: "",
        sellThreshold: "",
        sellPurchaseValue: "",
        pricePurchaseValue: "",
        takeProfitType: takeProfitTypeData,
        coin: "",
      });
    } else {
      setRulesList({
        buyThreshold: "",
        sellThreshold: "",
        takeProfitType: takeProfitTypeData,
        coin: "",
      });
    }
  };

  useEffect(() => {
    setErrortargetProfitPercent({
      isValid: false,
      error: "",
    });
    droidDataDispatch({
      takeProfit: {
        ...getDroidsDataInfo?.takeProfit,
        targetProfitPercent: "",
      },
    });
  }, [getDroidsDataInfo?.coinsList?.length]);
  useEffect(() => {
    setRulesList({ ...getRulesList, takeProfitType: takeProfitType });
  }, [takeProfitType]);

  const rulesClick = (event) => {
    const { name, value } = event.target;
    const onlyNumberRegex = /^\d*$/;
    if (!onlyNumberRegex.test(value)) {
      setError({ name: name, error: "Please input numerical values only." });
    } else if (parseFloat(value) === 0) {
      setError({ name: name, error: "The value of 0 is prohibited." });
      droidDataDispatch({
        dealRules: { ...getDroidsDataInfo?.dealRules, [name]: "" },
      });
    } else {
      if (name === "repeat" && value > 5) {
        setError({ name: name, error: "Please input a value from 1-5." });
      } else if (name === "cooldownseconds" && value > 60) {
        setError({
          name: name,
          error:
            "The value must be an number and should not exceed 60 seconds.",
        });
      } else {
        setError({ name: "", error: "" });
        droidDataDispatch({
          dealRules: { ...getDroidsDataInfo?.dealRules, [name]: value },
        });
      }
    }
  };

  const editDealRual = (item, index) => {
    setRulesList({ ...item, isEdit: true });
    getSditDealRualMode({ status: true, index: index - 1 });
  };

  const deleteDealRual = (item, index) => {
    const updatedArray = [...getDroidsDataInfo?.dealRules?.list];
    updatedArray?.splice(index - 1, 1);
    droidDataDispatch({
      dealRules: { ...getDroidsDataInfo?.dealRules, list: updatedArray },
    });
  };

  useEffect(() => {
    setError({ name: "", error: "" });
    setRulesList({
      sellThreshold: "",
      buyThreshold: "",
      coin: "",
      takeProfitType: "Immediate",
    });
  }, [path[3]]);

  const rulesListAddCancleEdit = () => {
    getSditDealRualMode({ status: false });
    if (droidType?.droidType?.nav === "simplemodedroid") {
      setRulesList({
        buyThreshold: "",
        buyThreshold: "",
        sellAmountCal: "",
        sellAmoutCalculate: "",

        sellThreshold: "",
        sellPurchaseValue: "",
        pricePurchaseValue: "",
        takeProfitType: takeProfitTypeData,
        coin: "",
      });
    } else {
      setRulesList({
        buyThreshold: "",
        sellThreshold: "",
        takeProfitType: takeProfitTypeData,
        coin: "",
      });
    }
  };

  const fetchMaxBalance = (portfolios) => {
    let maxValue = 0;
    portfolios?.forEach((portfolio) => {
      if (maxValue === 0) {
        maxValue = portfolio.balance;
      } else if (portfolio.balance <= maxValue) {
        maxValue = portfolio.balance;
      }
    });
    return maxValue;
  };

  const calculatePercentage = (val) => {
    let role = localStorage.getItem("role");
    let maxValue;
    if (role === "broker") {
      maxValue = fetchMaxBalance(checkBalance);
    } else {
      maxValue = fetchMaxBalance(selectedPortfolios);
    }

    return val > 0 && maxValue > 0 ? ((val / maxValue) * 100)?.toFixed(2) : 0;
  };

  // Create Auto Deal Rual for All Droid (SimpleMode, TomeMode, )
  useEffect(() => {
    const calculatePercentageValue =
      (fetchMaxBalanceToEnter(selectedPortfolios) *
        getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount) /
      100;
    const changesValue =
      getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType === "percent"
        ? calculatePercentageValue
        : getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount;
    let buyValueCalculateBase =
      (parseFloat(getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount) *
        parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent)) /
        100 +
      parseFloat(getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount);
    let sellThresholdMarket = (
      getbidValue *
      (1 + parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent) / 100)
    ).toFixed(2);
    let sellValueCalculateBase =
      (parseFloat(changesValue) *
        parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent)) /
        100 +
      Number(parseFloat(changesValue));
    let buyValueCalculate =
      getDroidsDataInfo?.takeProfit?.takeProfitType === "base"
        ? buyValueCalculateBase
        : parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent);
    let sellValueCalculate =
      getDroidsDataInfo?.takeProfit?.takeProfitType === "base"
        ? sellValueCalculateBase
        : parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent) +
          parseFloat(getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount);
    let buyValueCalculateBaseTimeMode =
      (parseFloat(getDroidsDataInfo?.timeSettings?.pricePurchaseAmount) *
        parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent)) /
        100 +
      parseFloat(getDroidsDataInfo?.timeSettings?.pricePurchaseAmount);
    let buyValueCalculateTimeMode =
      getDroidsDataInfo?.takeProfit?.takeProfitType === "base"
        ? buyValueCalculateBaseTimeMode
        : parseFloat(getDroidsDataInfo?.takeProfit?.targetProfitPercent);
    if (
      droidType?.droidType?.nav === "simplemodedroid" &&
      getDroidsDataInfo?.buySellSettings?.startOrderType === "limit" &&
      getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount &&
      buyValueCalculate &&
      changesValue &&
      sellValueCalculate
    ) {
      setDefaultRule({
        ...getDroidsDataInfo?.dealRules?.list[0],
        takeProfitType: "Immediate",
        buyThreshold: getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount,
        sellThreshold: buyValueCalculate?.toFixed(2),
        pricePurchaseValue: Number(changesValue)?.toFixed(2),
        sellPurchaseValue: sellValueCalculate?.toFixed(2),
      });
      droidDataDispatch({
        buySellSettings: {
          ...getDroidsDataInfo?.buySellSettings,
          buyPercent: calculatePercentage(
            getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount
          ),
        },
      });
    } else if (
      droidType?.droidType?.nav === "simplemodedroid" &&
      getDroidsDataInfo?.buySellSettings?.startOrderType === "market" &&
      changesValue &&
      getbidValue
    ) {
      setDefaultRule({
        ...getDroidsDataInfo?.dealRules?.list[0],
        takeProfitType: "Immediate",
        buyThreshold: getbidValue,
        sellThreshold: sellThresholdMarket,
        pricePurchaseValue: Number(changesValue)?.toFixed(2),
        sellPurchaseValue: sellValueCalculate?.toFixed(2),
      });
      droidDataDispatch({
        buySellSettings: {
          ...getDroidsDataInfo?.buySellSettings,
          buyPercent: calculatePercentage(
            getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount
          ),
        },
      });
    } else if (
      droidType?.droidType?.nav === "timemodedroid" &&
      getDroidsDataInfo?.timeSettings?.pricePurchaseAmount &&
      buyValueCalculateTimeMode
    ) {
      setDefaultRule({
        ...getDroidsDataInfo?.dealRules?.list[0],
        takeProfitType: "Immediate",
        buyThreshold: getDroidsDataInfo?.timeSettings?.pricePurchaseAmount,
        sellThreshold: buyValueCalculateTimeMode?.toFixed(2),
      });
    }
  }, [
    getDroidsDataInfo?.timeSettings?.pricePurchaseAmount,
    getDroidsDataInfo?.timeSettings?.purchaseAmountType,
    getDroidsDataInfo?.timeSettings?.purchaseAmount,
    getDroidsDataInfo?.takeProfit?.takeProfitType,
    getDroidsDataInfo?.takeProfit?.targetProfitPercent,
    getDroidsDataInfo?.buySellSettings?.buyPurchaseAmountType,
    getDroidsDataInfo?.buySellSettings?.buyPurchaseAmount,
    getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount,
    getbidValue,
  ]);

  // Create Auto Deal Rual for DCA
  useEffect(() => {
    let buyThresholdValue = Number(
      getDroidsDataInfo?.dcaStrategy?.startOrderType === "limit"
        ? getDroidsDataInfo?.dcaStrategy?.limit
        : getbidValue
    );
    let buyValueCalculateBase =
      (Number(buyThresholdValue) *
        Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent)) /
        100 +
      Number(buyThresholdValue);
    let sellThresholdValue =
      getDroidsDataInfo?.takeProfit?.takeProfitType === "base"
        ? buyValueCalculateBase
        : Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent);

    if (droidType?.droidType?.nav === "costmodedroid") {
      if (
        getDroidsDataInfo?.dealRules?.list[0]?.isSellTriggered ||
        getDroidsDataInfo?.dealRules?.list[0]?.isBuyTriggered
      ) {
      } else {
        setDefaultRule({
          ...getDroidsDataInfo?.dealRules?.list[0],
          takeProfitType: "Immediate",
          buyThreshold:
            buyThresholdValue?.toFixed(2) === "NaN"
              ? 0
              : buyThresholdValue?.toFixed(2),
          sellThreshold: sellThresholdValue?.toFixed(2),
        });
      }
    }
  }, [
    getbidValue,
    getDroidsDataInfo?.dcaStrategy?.startOrderType,
    getDroidsDataInfo?.dcaStrategy?.limit,
    getDroidsDataInfo?.takeProfit?.takeProfitType,
    getDroidsDataInfo?.takeProfit?.targetProfitPercent,
  ]);

  const handleChangeTakeProfit = (event) => {
    const { name, value, checked } = event.target;
    const twoDecimalPointValidation = /^\d*\.?\d{0,2}$/;
    if (value === "on" && name === "isTrailingDeviationOn") {
      if (checked === false && name === "isTrailingDeviationOn") {
        droidDataDispatch({
          takeProfit: {
            ...getDroidsDataInfo?.takeProfit,
            [name]: checked,
            trailingDeviation: "",
          },
        });
      } else {
        droidDataDispatch({
          takeProfit: { ...getDroidsDataInfo?.takeProfit, [name]: checked },
        });
      }
      setError({ name: "", error: "" });
      setErrortargetProfitPercent({ isValid: false, error: "" });
      setErrorTraillingPercent({ isValid: false, error: "" });
      dispatch(clearDroidDcaError({ key: "targetProfitPercent" }));
      dispatch(clearDroidDcaError({ key: "trailling" }));
    } else if (!twoDecimalPointValidation.test(value)) {
      setError({
        name: name,
        error: "Enter a valid number with up to two decimal places",
      });
    } else {
      if (!edit && fetchMaxBalanceToEnter(checkBalance) === 0) {
        setError({ name: name, error: `Please choose a portfolio.` });
      } else if (value === "") {
        setError({ name: name, error: "This field must not remain empty." });
        droidDataDispatch({
          takeProfit: { ...getDroidsDataInfo?.takeProfit, [name]: value },
        });
      } else if (Number(value) < -0.9) {
        setError({ name: name, error: "Enter greater than 0.1" });
      } else {
        if (
          ((name === "targetProfitPercent" &&
            getDroidsDataInfo?.takeProfit?.takeProfitType === "base") ||
            name === "trailingDeviation") &&
          value > 100
        ) {
          if (name === "targetProfitPercent") {
            setErrortargetProfitPercent({
              isValid: true,
              error: "Enter a value that is less than or equal to 100%.",
            });
            dispatch(setDroidDcaError({ key: "targetProfitPercent" }));
          }
          if (name === "trailingDeviation") {
            setErrorTraillingPercent({
              isValid: true,
              error: "Please input a value that is less than or equal to 100%.",
            });
            dispatch(setDroidDcaError({ key: "trailling" }));
          }
        } else if (value.length > 8) {
          setError({
            name: name,
            error: "The value should not exceed 8 digits.",
          });
        } else {
          setError({ name: "", error: "" });
          setErrortargetProfitPercent({ isValid: false, error: "" });
          setErrorTraillingPercent({ isValid: false, error: "" });
          dispatch(clearDroidDcaError({ key: "targetProfitPercent" }));
          dispatch(clearDroidDcaError({ key: "trailling" }));
          if (value.startsWith(".")) {
            droidDataDispatch({
              takeProfit: {
                ...getDroidsDataInfo?.takeProfit,
                [name]: "0" + value,
              },
            });
          } else {
            droidDataDispatch({
              takeProfit: { ...getDroidsDataInfo?.takeProfit, [name]: value },
            });
          }
        }
      }
      if (Number(value) === 0) {
        setError({ name: name, error: "Value must be greater than 0." });
      } else if (value.startsWith(".")) {
        setError({ name: name, error: "Value cannot start with a dot" });
      }
    }

    if (
      name === "targetProfitPercent" &&
      getDroidsDataInfo?.takeProfit?.takeProfitType === "base"
    ) {
      if (
        value == "." ||
        (value < 0.1 && getDroidsDataInfo?.coinsList?.length > 0)
      ) {
        setErrortargetProfitPercent({
          isValid: true,
          error: "Profit must be greater than or equal to 0.1%",
        });
      } else {
        setErrortargetProfitPercent({
          isValid: false,
          error: "",
        });
      }
    }
  };

  useEffect(() => {
    if (location?.pathname?.split("/")?.length === 5) {
      setEdit(true);
    }
  }, [location]);

  const changeTakeProfitType = (item) => {
    if (!hasBuyTriggered) {
      droidDataDispatch({
        takeProfit: {
          ...getDroidsDataInfo?.takeProfit,
          takeProfitType: item?.name,
          targetProfitPercent: "",
        },
      });
    }
  };

  function getStatusById(array1, array2) {
    const idToStatusMap = new Map(
      array1?.map((item) => [item?.id, item?.status])
    );
    const matchedStatuses = array2?.map((item) => {
      const status = idToStatusMap.get(item?._id);
      return { id: item?._id, status: status ? status : "" };
    });

    return !matchedStatuses ? "" : matchedStatuses[0]?.status;
  }

  const ADDDealRualValidation =
    (droidType?.droidType?.nav === "simplemodedroid" ||
      droidType?.droidType?.nav === "timemodedroid") &&
    [getDefaultRule]
      ?.concat(
        state?.Duplicate === "edit"
          ? getDroidsDataInfo?.dealRules?.list?.slice(1)
          : getDroidsDataInfo?.dealRules?.list
      )
      ?.filter((item) => item?.buyThreshold == getRulesList?.buyThreshold)
      ?.length > 0
      ? true
      : false;

  const today = moment()?.format("DD/MM/YYYY");

  const disabledInput =
    state?.Duplicate === "edit" &&
    getDroidsDataInfo?.timeSettings?.firstBuyDate === today;

  const Child = () => {
    console.log(getDroidsDataInfo, "simple droid");
    return (
      <>
        {childComponent()}
        <div className="border-bottom mb-4 mt-2"></div>
        <h4>Take Profit</h4>
        <div className="row my-4">
          <div className="col-lg-6 col">
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center MB-2">
                <h6>Target Profit</h6>
                {!(getDroidsDataInfo?.takeProfit?.targetProfitPercent > 0) ||
                !getDroidsDataInfo?.takeProfit?.targetProfitPercent ? (
                  <p className="required-field">*</p>
                ) : null}
              </div>
              <TooltipQuestionMark
                toolTipContent={
                  "Configure the percentage Take Profit target the bot will use to close successful trades, the bot will automatically account for exchange fees."
                }
              />
            </div>
            <div className="d-flex position-relative">
              <input
                disabled={!getDroidsDataInfo?.coinsList?.length > 0}
                type="text"
                className="pe-4"
                name="targetProfitPercent"
                value={
                  !getDroidsDataInfo?.takeProfit?.targetProfitPercent
                    ? ""
                    : getDroidsDataInfo?.takeProfit?.targetProfitPercent
                }
                placeholder="0"
                onChange={handleChangeTakeProfit}
              />
              <h5 className="position-absolute percentage-input">
                {getDroidsDataInfo?.takeProfit?.takeProfitType === "base"
                  ? "%"
                  : "$"}
              </h5>
            </div>
            {getErrortargetProfitPercent.isValid && (
              <div className="text-danger">
                {" "}
                {getErrortargetProfitPercent?.error}
              </div>
            )}
            {sellLimit && <div className="text-danger"> {sellLimitMsg}</div>}

            {/* {!getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount ? '' : Number(getDroidsDataInfo?.takeProfit?.targetProfitPercent) > Number(getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount) ? '' : getDroidsDataInfo?.takeProfit?.takeProfitType === 'value' && <div className='text-danger'>  Minimum Amount should be {getDroidsDataInfo?.buySellSettings?.pricePurchaseAmount}</div>} */}
          </div>
          <div className="col-lg-6 col">
            <div className="d-flex align-items-center gap-2 mb-2">
              <h6 className="">Take Profit Type</h6>
              <TooltipQuestionMark
                toolTipContent={`${
                  state?.droidType?.nav === "safemodedroid" ||
                  state?.droidType?.nav === "indexmodedroid"
                    ? "Set 'percentage from base order' for fixed-profit closure; choose 'percentage from total volume' for dynamic Take Profit, amplifying gains with multiple Safety Orders"
                    : 'If you configure the "percentage from base order" setting, this will close trades faster when Safety Orders are executed but the value of the profit will remain the same and will be calculated from the initial Base Order size, for example: Base Order $10, Take Profit 10% means that the profit will be $1 regardless of the amount of Safety Orders executed. Alternatively, to calculate the Take Profit target percentage using the Dollar Cost Average price of the asset being traded, use the "percentage from total volume" setting, this will increase the value of the amount of profit taken if the deal uses multiple safety orders.'
                }`}
              />
            </div>

            <div
              className={`btn-group simpledropdown D-W-3 small-dropdown-height `}
            >
              <button
                disabled={
                  droidType?.droidType?.nav === "timemodedroid" && disabledInput
                }
                className="btn dropdown-toggle d-flex justify-content-between align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
              >
                {takeProfitTypeData
                  ?.find(
                    (obj) =>
                      obj.name === getDroidsDataInfo?.takeProfit?.takeProfitType
                  )
                  ?.label?.toString()
                  ?.substring(0, 23) + "..."}
                <img
                  src={require("assets/arrowDown.svg").default}
                  alt=""
                  className="ML-6"
                  loading="lazy"
                />
              </button>
              <ul className="dropdown-menu">
                {takeProfitTypeData?.map((item, index) => (
                  <li
                    key={index}
                    className={`dropdown-item py-3 justify-content-between d-flex gap-3 ${
                      ((droidType?.droidType?.nav === "costmodedroid" &&
                        getDroidsDataInfo?.dcaStrategy?.startOrderType ===
                          "market") ||
                        (droidType?.droidType?.nav === "simplemodedroid" &&
                          getDroidsDataInfo?.buySellSettings?.startOrderType ===
                            "market") ||
                        droidType?.droidType?.nav === "indexmodedroid") &&
                      item?.name === "value"
                        ? "disabled"
                        : ""
                    } ${
                      takeProfitTypeData?.length - 1 == index
                        ? ""
                        : "border-bottom"
                    }`}
                    onClick={() => changeTakeProfitType(item)}
                  >
                    {item?.label}{" "}
                    {getDroidsDataInfo?.takeProfit?.takeProfitType ==
                    item?.name ? (
                      <img
                        className="mx-1"
                        src={require("assets/rightMark.svg").default}
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {state?.droidType?.nav === "safemodedroid" ||
        state?.droidType?.nav === "indexmodedroid" ? (
          ""
        ) : (
          <>
            {/* Temoporary disable for index mode */}
            <div className="d-flex gap-4 align-items-center mb-4">
              <h6>Trailing </h6>
              <label className="switch">
                {" "}
                <input
                  disabled={
                    droidType?.droidType?.nav === "timemodedroid" &&
                    disabledInput
                  }
                  type="checkbox"
                  name="isTrailingDeviationOn"
                  checked={getDroidsDataInfo?.takeProfit?.isTrailingDeviationOn}
                  // onChange={handleChangeTakeProfit}
                />{" "}
                <span className="slider round"></span>
              </label>
            </div>

            <div className="d-flex gap-2 ">
              <div className="d-flex align-items-center">
                <h6>Trailing Deviation</h6>
                {getDroidsDataInfo?.takeProfit?.isTrailingDeviationOn && (
                  <p className="required-field">*</p>
                )}
              </div>
              <TooltipQuestionMark
                toolTipContent={
                  "Define what is your Trailing Deviation in %, what is the % drop down after last high to take profit"
                }
              />
            </div>
            <div className="d-flex position-relative half-input">
              <input
                type="text"
                name="trailingDeviation"
                className="pe-4"
                value={
                  !getDroidsDataInfo?.takeProfit?.trailingDeviation
                    ? ""
                    : getDroidsDataInfo?.takeProfit?.trailingDeviation
                }
                onChange={handleChangeTakeProfit}
                disabled={
                  getDroidsDataInfo?.takeProfit?.trailingDeviation > 0
                    ? false
                    : !getDroidsDataInfo?.takeProfit?.isTrailingDeviationOn
                }
              />
              <h5 className="position-absolute percentage-input">%</h5>
            </div>
            {getErrorTraillingPercent?.isValid === true && (
              <div className="text-danger">
                {" "}
                {getErrorTraillingPercent?.error}
              </div>
            )}

            {getDroidsDataInfo?.buySellSettings?.startOrderType === "market" ? (
              ""
            ) : state?.droidType?.nav === "indexmodedroid" ||
              (state?.droidType?.nav === "costmodedroid" &&
                getDroidsDataInfo?.dcaStrategy?.startOrderType === "market") ? (
              ""
            ) : (
              <>
                <div className="border-bottom my-3"></div>
                <h4 className="mt-1">Deal Rules</h4>
                <div className="row my-4">
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center gap-2">
                      <div className="d-flex align-items-center">
                        <h6>Rules List Repeat</h6>
                        {!getDroidsDataInfo?.dealRules?.repeat ? (
                          <p className="required-field">*</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TooltipQuestionMark
                        toolTipContent={
                          "The number (between 1-1000) of times to Repeat this Droid"
                        }
                      />
                    </div>
                    <input
                      // disabled={
                      //   droidType?.droidType?.nav === "costmodedroid" ||
                      //   (droidType?.droidType?.nav === "timemodedroid" &&
                      //     disabledInput)
                      // }

                      disabled
                      placeholder="Number"
                      type="text"
                      name="repeat"
                      value={
                        !getDroidsDataInfo?.dealRules?.repeat
                          ? ""
                          : getDroidsDataInfo?.dealRules?.repeat
                      }
                      onChange={rulesClick}
                    />
                    {getError?.name === "repeat" && (
                      <div className="text-danger"> {getError?.error}</div>
                    )}
                  </div>
                  <div className="col-lg-6 MT-5">
                    <div className="d-flex align-items-center label-container ">
                      <h6>Cooldown Between Rules</h6>
                    </div>
                    <div className="d-flex position-relative ">
                      <input
                        disabled={
                          droidType?.droidType?.nav === "timemodedroid" &&
                          disabledInput
                        }
                        type="text"
                        name="cooldownseconds"
                        value={
                          !getDroidsDataInfo?.dealRules?.cooldownseconds
                            ? ""
                            : getDroidsDataInfo?.dealRules?.cooldownseconds
                        }
                        onChange={rulesClick}
                      />
                      <span className="position-absolute percentage-input-Seconds P5 text-third">
                        Seconds
                      </span>
                    </div>

                    {getError?.name === "cooldownseconds" && (
                      <div className="text-danger"> {getError?.error}</div>
                    )}
                  </div>
                </div>

                <div className="deal-rules">
                  <div className="p-3">
                    <div className="row">
                      <div className={`col-lg-12`}>
                        <div className="d-flex align-items-center gap-2">
                          <h6>
                            Identify the optimal trade conditions for your
                            CryptoDroid.
                          </h6>
                          <TooltipQuestionMark
                            toolTipContent={
                              "Combining standalone indicators and various TA Presets is possible. All conditions link with logical AND, so ALL of them should be TRUE to let the bot open a new deal. All these conditions evaluate their values only when the candle is closed on the chart within a specified time frame."
                            }
                          />
                        </div>
                        <SingleSlelectDropDown
                          disabled={
                            droidType?.droidType?.nav === "timemodedroid" &&
                            state?.Duplicate === "edit"
                          }
                          img2={
                            <img
                              src={require("assets/rightMark.svg").default}
                              alt=""
                              loading="lazy"
                            />
                          }
                          className={"w-100 mt-3 bg-white"}
                          title={"Immediate"}
                          getClickDropdoen={takeProfitType}
                          setClickDropdoen={settakeProfitType}
                          dropdown={[
                            { label: "Immediate" },
                            { label: "Trading view custom signal" },
                          ]}
                        />
                      </div>
                    </div>

                    <div className="row my-4">
                      <div className="col-lg-6">
                        <h6>Buy Threshold $</h6>
                        <input
                          value={getRulesList.buyThreshold}
                          disabled={
                            getDroidsDataInfo?.dealRules?.repeat <= "1" ||
                            droidType?.droidType?.nav === "costmodedroid" ||
                            (droidType?.droidType?.nav === "timemodedroid" &&
                              disabledInput) ||
                            getRulesList.isBuyTriggered === true
                          }
                          name="buyThreshold"
                          onChange={buyThresholdData}
                          placeholder="$"
                          type="number"
                          className={
                            getRulesList.isBuyTriggered === true
                              ? "disable"
                              : ""
                          }
                        />
                        <div className="text-danger">
                          {(droidType?.droidType?.nav === "simplemodedroid" ||
                            droidType?.droidType?.nav === "timemodedroid") &&
                          [getDefaultRule]
                            ?.concat(
                              state?.Duplicate === "edit"
                                ? getDroidsDataInfo?.dealRules?.list?.slice(1)
                                : getDroidsDataInfo?.dealRules?.list
                            )
                            ?.filter(
                              (item) =>
                                item?.buyThreshold ==
                                  getRulesList?.buyThreshold && item.isEdit
                            )?.length > 0
                            ? `Already Deal Rule have created Buy Threshold on ${getRulesList?.buyThreshold} `
                            : ""}{" "}
                          {getError?.name === "buyThreshold" && (
                            <div> {getError?.error}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h6>Sell Threshold $</h6>
                        <input
                          value={getRulesList.sellThreshold}
                          disabled={
                            getDroidsDataInfo?.dealRules?.repeat <= "1" ||
                            droidType?.droidType?.nav === "costmodedroid" ||
                            (droidType?.droidType?.nav === "timemodedroid" &&
                              state?.Duplicate === "edit")
                          }
                          name="sellThreshold"
                          onChange={buyThresholdData}
                          placeholder="$"
                          type="number"
                        />
                        {getError?.name === "sellThreshold" && (
                          <div className="text-danger"> {getError?.error}</div>
                        )}
                        {sellLessThanBuy && (
                          <div className="text-danger">
                            {" "}
                            {"Sell threshold value should be greater than buy."}
                          </div>
                        )}
                      </div>
                    </div>

                    {droidType?.droidType?.nav === "simplemodedroid" ? (
                      <div className="row my-4">
                        <div className="col-lg-6">
                          <h6>Price Purchase $</h6>
                          <input
                            value={getRulesList.pricePurchaseValue}
                            name="pricePurchaseValue"
                            onChange={buyThresholdData}
                            placeholder="$"
                            type="number"
                            disabled={getRulesList.isBuyTriggered === true}
                            className={
                              getRulesList.isBuyTriggered === true
                                ? "disable"
                                : ""
                            }
                          />
                          {getError?.name === "pricePurchaseValue" && (
                            <div className="text-danger">
                              {" "}
                              {getError?.error}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <h6>Sell Purchase $</h6>
                          <input
                            disabled
                            value={
                              !getRulesList?.sellThreshold ||
                              !getRulesList?.pricePurchaseValue ||
                              !getRulesList?.buyThreshold
                                ? ""
                                : sellAmountCal
                            }
                            name="sellPurchaseValue"
                            onChange={buyThresholdData}
                            placeholder="$"
                            type="number"
                          />
                          {getError?.name === "sellPurchaseValue" && (
                            <div className="text-danger">
                              {" "}
                              {getError?.error}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row">
                      {ruleListError && (
                        <div className="text-danger">
                          {" "}
                          Minimum {getDroidsDataInfo?.dealRules?.repeat} Rules
                          List are Required
                        </div>
                      )}
                      {/* {!(getDroidsDataInfo?.dealRules?.repeat <= ((state?.Duplicate === "edit" || state?.Duplicate === "Duplicate") ? [getDefaultRule]?.concat(getDroidsDataInfo?.dealRules?.list?.slice(1)) : [getDefaultRule]?.concat(getDroidsDataInfo?.dealRules?.list))?.length) ? <div className='text-danger'> Minimum {getDroidsDataInfo?.dealRules?.repeat} Rules List are Required</div> : ''} */}
                      <div className="col-lg-12 pt-3 justify-content-end d-grid h-100">
                        <div className="d-flex justify-content-end width-max gap-3">
                          {getEditDealRualMode?.status ? (
                            <SecondryButton
                              text="Cancel"
                              handleClick={rulesListAddCancleEdit}
                            />
                          ) : (
                            ""
                          )}
                          {getDroidsDataInfo?.dealRules?.list?.length < 4 ? (
                            <SecondryButton
                              className={`${
                                (droidType?.droidType?.nav === "simplemodedroid"
                                  ? !getRulesList?.pricePurchaseValue
                                  : false) ||
                                !getRulesList?.sellThreshold ||
                                !getRulesList?.buyThreshold ||
                                !takeProfitType
                                  ? "opacity-50"
                                  : ""
                              } webkit-fill-available`}
                              disabled={
                                sellLessThanBuy ||
                                (droidType?.droidType?.nav ===
                                  "timemodedroid" &&
                                  disabledInput) ||
                                ADDDealRualValidation ||
                                (droidType?.droidType?.nav === "simplemodedroid"
                                  ? parseFloat(
                                      getRulesList?.pricePurchaseValue
                                    ) <= parseFloat(minAmount) ||
                                    !getRulesList?.pricePurchaseValue
                                  : false) ||
                                !getRulesList?.sellThreshold ||
                                !getRulesList?.buyThreshold ||
                                !takeProfitType
                              }
                              text={
                                getEditDealRualMode?.status
                                  ? "Update"
                                  : "Add to List"
                              }
                              handleClick={rulesListAdd}
                              icon={
                                <img
                                  src={require(`assets/plusIcon.svg`).default}
                                  alt=""
                                  loading="lazy"
                                />
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <h6 className="my-3">Rules List</h6>
                    <div className="table_Scroll">
                      <table className="table table_border">
                        {(state?.Duplicate === "edit" ||
                        state?.Duplicate === "Duplicate"
                          ? [getDefaultRule]?.concat(
                              getDroidsDataInfo?.dealRules?.list?.slice(1)
                            )
                          : [getDefaultRule]?.concat(
                              getDroidsDataInfo?.dealRules?.list
                            )
                        )?.map((item, index) => {
                          const datareturn = getStatusById(
                            getOrderStatus,
                            item?.buyOrderDetails
                          );
                          return (
                            <tr key={index}>
                              <td className="table_subheadind">{index + 1} </td>
                              <td className="table_headind">
                                {item?.takeProfitType}
                              </td>
                              <td>
                                <div className="d-flex table_subheadind gap-1">
                                  Buy Threshold{" "}
                                  <div className="table_headind d-flex">
                                    {" "}
                                    $
                                    {item?.buyThreshold
                                      ? parseFloat(item.buyThreshold).toFixed(2)
                                      : "0.00"}{" "}
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex table_subheadind gap-1">
                                  Sell Threshold{" "}
                                  <div className="table_headind">
                                    {" "}
                                    $
                                    {isNaN(Number(item?.sellThreshold))
                                      ? "0.00"
                                      : Number(item?.sellThreshold).toFixed(
                                          2
                                        )}{" "}
                                  </div>{" "}
                                </div>
                              </td>

                              {droidType?.droidType?.nav ===
                              "simplemodedroid" ? (
                                <td>
                                  <div className="d-flex table_subheadind gap-1">
                                    Price Purchase{" "}
                                    <div className="table_headind">
                                      {" "}
                                      $
                                      {isNaN(Number(item?.pricePurchaseValue))
                                        ? "0.00"
                                        : parseFloat(
                                            item?.pricePurchaseValue
                                          ).toFixed(2)}
                                    </div>{" "}
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                              {droidType?.droidType?.nav ===
                              "simplemodedroid" ? (
                                <td>
                                  <div className="d-flex table_subheadind gap-1">
                                    Sell Purchase{" "}
                                    <div className="table_headind">
                                      {" "}
                                      $
                                      {isNaN(Number(item?.sellPurchaseValue))
                                        ? "0.00"
                                        : parseFloat(
                                            item?.sellPurchaseValue
                                          ).toFixed(2)}{" "}
                                    </div>{" "}
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                <div className="d-flex table_subheadind gap-1">
                                  Status{" "}
                                  <div className="table_headind">
                                    {" "}
                                    {!datareturn ||
                                    state?.Duplicate === "Duplicate"
                                      ? "Open"
                                      : datareturn}{" "}
                                  </div>{" "}
                                </div>
                              </td>
                              {(droidType?.droidType?.nav ===
                                "simplemodedroid" ||
                                droidType?.droidType?.nav === "timemodedroid" ||
                                droidType?.droidType?.nav ===
                                  "costmodedroid") &&
                              index !== 0 &&
                              !getEditDealRualMode?.status &&
                              datareturn !== "Filled" ? (
                                <td>
                                  <div
                                    className="table_headind cursor"
                                    onClick={() => editDealRual(item, index)}
                                  >
                                    {" "}
                                    <EditIcon color="#035cc5" />{" "}
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                              {(droidType?.droidType?.nav ===
                                "simplemodedroid" ||
                                droidType?.droidType?.nav === "timemodedroid" ||
                                droidType?.droidType?.nav ===
                                  "costmodedroid") &&
                              index !== 0 &&
                              !getEditDealRualMode?.status &&
                              datareturn !== "Filled" ? (
                                <td>
                                  <div
                                    className="table_headind cursor"
                                    onClick={() => deleteDealRual(item, index)}
                                  >
                                    {" "}
                                    <img
                                      src={
                                        require(`assets/deleteIcon.svg`).default
                                      }
                                      alt=""
                                      loading="lazy"
                                    />{" "}
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };
  return (
    <DroidModuleSettings
      hasFailed={hasFailed}
      getSingleCoinData={getSingleCoinData}
      setSingleCoinData={setSingleCoinData}
      droidDataDispatch={droidDataDispatch}
      checkBalance={checkBalance}
      getCoinListData={getCoinListData}
      getbidValue={getbidValue}
      minAmount={minAmount}
      children={Child()}
      selectedPortfolios={selectedPortfolios}
    />
  );
};

export default SimpleModeDroidSetting;
