import React from 'react'
import { useTranslation } from 'react-i18next'

const DroidADAChartLabels = ({ getGraphData }) => {
    const { t } = useTranslation()
    return (
        <div className='ChartADALabelMainContainer'>
            <div className='d-flex align-items-center gapPriceDevation  ChartADALabelContainer' >
                <h6 className='ChartADALabel'>{getGraphData?.safetyNetParameters?.priceDeviation || 0}%</h6>
                <p className='ChartparagraphSize price-devation'>{t('priceDeviation')}</p>
            </div>
            <div className='d-flex align-items-center gap-2  MT-4 ChartADALabelContainer'>
                <h6 className='ChartADALabel'>{getGraphData?.safetyNetParameters?.maxOrderCount || 0}</h6>
                <p className='ChartparagraphSize'>{t('maxOrdersCount')}</p>
            </div>
            <div className='d-flex align-items-center gap-2 MT-2 ChartADALabelContainer'>
                <h6 className='ChartADALabel'>{getGraphData?.safetyNetParameters?.orderVolumes || 0}</h6>
                <p className='ChartparagraphSize'>{t('orderVolumes')}</p>
            </div>
            <div className='d-flex align-items-center gap-2 MT-3 ChartADALabelContainer'>
                <h6 className='ChartADALabel'>{getGraphData?.safetyNetParameters?.maxActiveOrdersCount || 0}</h6>
                <p className='ChartparagraphSize'>{t("maxActiveOrderCount")}</p>
            </div>
            <div className='d-flex align-items-center gap-2 MT-3 ChartADALabelContainer'>
                <h6 className='ChartADALabel'>{getGraphData?.safetyNetParameters?.orderStepScale || 0}</h6>
                <p className='ChartparagraphSize'>{t("orderSetupScale")}</p>
            </div>
        </div>
    )
}

export default DroidADAChartLabels