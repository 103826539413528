import React, {useState, useEffect} from 'react';

const ProgressBar = ({ icon, makerPercentage }) => {
const [color, setColor ]= useState('')
 
useEffect(() => {
  if (icon.includes('binance')) {
    setColor('#F3CE5A');
  } else if (icon.includes('crypto')) {
    setColor('#03316C');
  } else if (icon.includes('kraken')) {
    setColor('#5741D9');
  } else if (icon.includes('coinbase')) {
    setColor('#0052FE');
  } else if (icon.includes('oks')) {
    setColor('#000000');
  }else if (icon.includes('kucoin')) {
    setColor('#23AF91');
  }else if (icon.includes('ftx')) {
    setColor('#019DB5');
  }else if (icon.includes('ftxus')) {
    setColor('#91D1DD');
  }else if (icon.includes('gateio')) {
    setColor('#DE5959');
  }else if (icon.includes('gemini')) {
    setColor('#26D9F6');
  }else if (icon.includes('deribit')) {
    setColor('#00CFBE');
  }else if (icon.includes('bittrex')) {
    setColor('#0052FE');
  }else if (icon.includes('bitfinex')) {
    setColor('#03CA9B');
  }else if (icon.includes('bitmex')) {
    setColor('#FF0201');
  }else if (icon.includes('bybit')) {
    setColor('#F7A600');
  }else if (icon.includes('bitstamp')) {
    setColor('#003B2F');
  }else if (icon.includes('huobi')) {
    setColor('#2DA9DE');
  }else {
    setColor('gray');
  }
}, [icon]);

  const renderBoxes = () => {
    return Array.from({ length: makerPercentage }, (_, index) => (
        <div className=''
        key={index}
        style={{
          backgroundColor: color,
          width: '13px',
          height: '3px',
          margin: '5px 0px 5px 0px',
          display: 'inline-block',
          alignItems: 'center'
        }}
      ></div>
    ));
  };

  return <div
  className='exchange-bars-height'>{renderBoxes()}</div>;
};

export default ProgressBar;
