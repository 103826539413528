import React from "react";

function ThreeBarIcon({color, width = '24',height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.25 6.263a.75.75 0 01.75-.75h11.5a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75zM4.743 5.263c.549 0 .994.445.994.994v.013a.993.993 0 01-1.987 0v-.013c0-.55.445-.994.993-.994zM7.25 12.263a.75.75 0 01.75-.75h11.5a.75.75 0 110 1.5H8a.75.75 0 01-.75-.75zM4.743 11.263c.549 0 .994.445.994.994v.013a.993.993 0 01-1.987 0v-.013c0-.55.445-.994.993-.994zM7.25 18.263a.75.75 0 01.75-.75h11.5a.75.75 0 110 1.5H8a.75.75 0 01-.75-.75zM4.743 17.263c.549 0 .994.445.994.993v.014a.993.993 0 01-1.987 0v-.014c0-.548.445-.993.993-.993z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ThreeBarIcon;
