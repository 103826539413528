import HorizontalBar from 'components/Charts/HorizontalBar'
import Loader from 'components/Loader'
import { dashboardHealth } from 'features/Dashboard/dashboardAction'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';

const HealthComponent = (props) => {
  const dispatch = useDispatch()
  const { userToken } = useSelector((state) => state.auth);
  const { dashboardHealthInfo } = useSelector((state) => state?.dashboard);
  const [getHealth, setHealth] = useState(dashboardHealthInfo);
  const location = useLocation();

  
  const role = localStorage.getItem('role');

  let textToShow;

  if (role === 'broker' && location.pathname === '/dashboard') {
    textToShow = 'Total Clients';
  } else {
    textToShow = 'Portfolios';
  }
  useEffect(() => {
    if(location.pathname==='/client-Dashboard-Details'){
      setHealth(props)
    }else{

      dispatch(dashboardHealth({ userToken })).then((res) => {
        if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
          setHealth(res?.payload)
        }
      });
    }
  }, [props.status])

  return (
    <div className='col-lg-6 responsiveHealthCard'>
      <div className="d-flex align-items-baseline gap-2 mb-3">
        <h3>Health</h3>
        <h5 className="text-muted">{getHealth?.data?.healthTotalCount || 0} {textToShow}</h5>
      </div>
      <div className="droidContainerDashboard healthCardDashboard d-flex ">
        {!getHealth?.status ? <div className='d-flex justify-content-center align-items-center w-100'><Loader /></div> : <HorizontalBar {...getHealth?.data?.healthCard} satatus={localStorage.getItem('role') === 'broker'} />}
      </div>
    </div>
  )
}

export default HealthComponent