import React from 'react'
import { useTranslation } from 'react-i18next'

const ADAChartOrderSize = ({ getGraphData }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className='ChartADAOrderSize'>
        <h4>{getGraphData?.dcaStrategy?.baseOrderSizeType === 'dollar' && "$"}{getGraphData?.dcaStrategy?.baseOrderSizeAmount ? getGraphData?.dcaStrategy?.baseOrderSizeAmount : "0"}{getGraphData?.dcaStrategy?.baseOrderSizeType === 'percent' && "%"} </h4>
        <p className='ChartparagraphSize text-muted'>{t('baseOrderSize')} </p>
      </div>
    </div>
  )
}

export default ADAChartOrderSize