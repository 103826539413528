import React, { useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import SecondryButton from 'components/Button/SecondryButton';
import ArrowDownIcon from 'assets/JsImage/ArrowDownIcon';
import { useSelector } from "react-redux";

const DroidModuleSearch = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const location = useLocation();
    const { droidPopupSelect } = useSelector((state) => state?.defaultState)
    const Duplicate = state; 
    const [exchange, setExchange] = useState(droidPopupSelect.find((option) => option?.nav === location?.pathname?.split('/')[3]) || '');
    const handleChange = (selectedOption) => {
        setExchange(selectedOption)
        navigate(`/management/droids/${selectedOption?.nav}`, { state: { droidType: selectedOption } });
    }
    const onBack = () => {
        localStorage.removeItem('routeHistory');
        localStorage.removeItem('brokerName');
        localStorage.removeItem('breadcrum');
        navigate(-1);
    }
    return (
        <>
            <div className='d-flex align-items-center gap-3'>
                <h3 className="fw-normal">{location?.pathname?.split('/')?.length === 5 ? <div className='d-flex cursor' onClick={() => onBack()}>
                    <img src={require('assets/arrow-left.svg').default} alt="" loading='lazy'/>
                    <p className="P5 h4-Two">Management / Droids / <span className="P5 h4-One"> {Duplicate?.Duplicate === 'Duplicate' ? 'Duplicate Droid':'Edit Droid'}</span></p>
                </div> : <div className='d-flex cursor' onClick={() => onBack()}>
                    <img src={require('assets/arrow-left.svg').default} alt="" loading='lazy'/>
                    <p className="P5 h4-Two">Management / Droids / <span className="P5 h4-One"> New Droid </span></p>
                </div>}
                </h3>
                {location?.pathname?.split('/')?.length === 5 ? '' : <div className="btn-group droid-dropdown">
                    <SecondryButton text={<div className='d-flex '> <div className='justify-content-between d-flex droidModuleSearch p-One'>{exchange?.name} </div> <ArrowDownIcon color='#a19e9e' /> </div>} className="btn dropdown-toggle d-flex justify-content-between align-items-center droid-dropdown-color" type="button" databstoggle="dropdown" data-bs-auto-close="true" aria-expanded="false" />
                    <ul className="dropdown-menu">
                        {droidPopupSelect?.map((item, index) => <li key={index} className={`dropdown-item py-2 justify-content-between d-flex ${droidPopupSelect?.length - 1 == index ? '' : 'border-bottom'}`} onClick={() => handleChange(item)}>{item?.name} </li>)}
                    </ul>
                </div>}
            </div>
        </>
    )
}

export default DroidModuleSearch