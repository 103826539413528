import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageAPI,
  getMessagesAPI,
  UserAPI,
} from "features/UserChat/userChatAction";
import Alexander from "assets/commonAvtar.svg";
import { CancleIcon } from "assets";
import { useLocation } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import socketIO from "socket.io-client";
import SearchUser from "./SearchUser";

let socket;

const TimeZone = (item) => {
  let reviewTime = new Date(item?.createdAt).toISOString();
  let localDate1 = new Date(reviewTime).toLocaleTimeString("en-US");
  let localDate = new Date(reviewTime).toLocaleDateString("en-US");
  const d1 = new Date();
  let text = d1.toLocaleDateString();

  let hours = localDate1.split(":")[0].padStart(2, "0");
  let minutes = localDate1.split(":")[1];
  let period = localDate1.split(":")[2].split(" ")[1];

  return {
    time: `${hours}:${minutes} ${period}`,
    date: localDate === text ? "Today" : localDate,
  };
};

function UserChat() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const messagesEndRef = useRef(null);
  const { userToken } = useSelector((state) => state.auth);
  const { addMessageInfo } = useSelector((state) => state?.userMessage);
  const [currentChat, setCurrentChat] = useState(
    state?.userData?.relationId === undefined ? 1 : state?.userData?.relationId
  );
  const [msg, setMsg] = useState("");
  const [getuserList, setUserList] = useState(addMessageInfo || []);
  const [imagePreview, setImagePreview] = useState();
  const [loading, setLoading] = useState(false);
  const [getUser, setUser] = useState(location?.state);
  const [getSocketMessage, setSocketMessage] = useState([]);
  const [getSendMessage, setSendMessage] = useState(false);
  const [getSearchUser, setSearchUser] = useState();
  const [getLoadingMessage, setLoadingMessage] = useState(false);
  const [receiverId, setreceiverId] = useState("");
  const [userListSearch, setUserListSearch] = useState([]);

  useEffect(() => {
    const userType = localStorage.getItem("role");
    // dispatch(UserAPI({ userToken, userType })).then((res) => {
    //   if (res.meta && res.meta.requestStatus === "fulfilled") {
    //     setUserList(res?.payload?.data);
    //   }
    // })
  }, [loading]);
  const getMessageCall = (id) => {
    setLoadingMessage(true);
    dispatch(getMessagesAPI({ userToken, id })).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        setLoadingMessage(false);
        setSocketMessage(reverceDataSet(res?.payload));
      }
    });
  };

  useEffect(() => {
    socket = socketIO(process.env.REACT_APP_SOCKET, {
      transports: ["websocket"],
    });

    socket.emit("newSideBar", { userId: localStorage?.getItem("userID") });
    socket.on("newSideBar", (response) => {
      if (response.success) {
        setUserList(reverceDataSet(response?.result));
        setUserListSearch(reverceDataSet(response?.result));
      } else {
        //
      }
    });
    // socket.on("message recieved", (newMessage) => {
    //   setSocketMessage((p) => [...p, newMessage])
    // })

    return () => {
      socket.disconnect();
    };
  }, []);

  const openChat = (id) => {
    setLoadingMessage(true);
    setSocketMessage([]);
    const messageId = id?.messageId;
    socket = socketIO(process.env.REACT_APP_SOCKET, {
      transports: ["websocket"],
    });
    socket?.emit("joinChat", {
      receiverId: id?.connectUSerInformation?.userId,
      senderId: localStorage?.getItem("userID"),
    });
    socket?.emit("markMessageAsRead", {
      messageId: messageId,
      userId: localStorage.getItem("userID"),
    });
    setUser(id);
    // Handle response from the server
    socket.on("markMessageAsRead", (response) => {
      if (response.success) {
        setreceiverId(id?.connectUSerInformation?.userId);
        socket.emit("getMessages", {
          receiverId: id?.connectUSerInformation?.userId,
          senderId: localStorage?.getItem("userID"),
        });

        socket.on("getMessages", (response) => {
          if (response.success) {
            setSocketMessage(reverceDataSet(response?.result));
            // currentChat !== id?.connectUSerInformation?.userId && setSocketMessage([])
            // setCurrentChat(id?.connectUSerInformation?.userId)

            // getMessageCall(id?.connectUSerInformation?.userId)

            // socket.emit("setup", id?.sender ? id?.sender : id?.connectUSerInformation?.userId);
            // socket.on("connected", () => { });
            // socket.emit('join chat', id?.receiver ? id?.receiver : id?.connectUSerInformation?.userId)
            // setLoadingMessage(true)
            // dispatch(getMessagesAPI({ userToken, currentChat })).then((res) => {
            //   if (res.meta && res.meta.requestStatus === "fulfilled") {
            //     setLoadingMessage(false)
            //     setSocketMessage(reverceDataSet(res?.payload))
            //   }
            // });
            setLoadingMessage(false);
          } else {
            setLoadingMessage(false);
            console.error("Failed to mark message as read:", response.error);
          }
        });
      } else {
        setLoadingMessage(false);
        console.error("Failed to mark message as read:", response.error);
      }
    });
  };

  const addConversation = async (e) => {
    socket.on("getMessages", (response) => {
      if (response.success) {
        setSocketMessage(reverceDataSet(response?.result));
      } else {
        //
      }
    });
    // setLoading(true)
    // setSendMessage(true)
    // if (msg.length === 0) {
    //   setLoading(false)
    // } else if (getUser) {
    //   const currentChat = getUser?.connectUSerInformation?.userId
    //   await dispatch(addMessageAPI({ userToken, msg, currentChat }))
    //     .then((res) => {
    //       if (res.meta && res.meta.requestStatus === "fulfilled") {
    //         setImagePreview()
    //         setLoading(false)
    //       }
    //     });
    // }
  };

  const reverceDataSet = (item) => {
    const tempData = [];
    for (let x = item?.length - 1; x >= 0; x--) {
      tempData.push(item[x]);
    }
    return tempData;
  };

  const userMessageInput = (e) => {
    e.preventDefault();
    const data = {
      messageContent: msg,
      receiverId: receiverId,
      senderId: localStorage.getItem("userID"),
    };
    socket.emit("newMessage", data);

    socket.on("newMessage", (response) => {
      if (response?.success) {
        setSocketMessage(reverceDataSet(response?.result));
      } else {
        //
      }
    });
    // addConversation()
    setMsg("");
  };

  const dateArray = [];

  const dateShow = (id) => {
    if (dateArray.find((i) => i == id) == undefined) {
      dateArray.push(id);
      return <div className="P6 text-center dateCghat">{id}</div>;
    }
  };
  const searchUser = (text) => {
    if (text?.length > 0) {
      const result = userListSearch?.filter((item) =>
        item?.connectUSerInformation?.name
          ?.toLowerCase()
          ?.includes(text?.toLowerCase())
      );
      setUserList(result);
      setSearchUser(text);
    } else {
      setSearchUser(text);
      setUserList(userListSearch);
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }); // Customize the format as needed
  };

  const inputMessageSubmit = (e) => {
    const newValue = e.target.value.replace(/^\s+/, "");
    setMsg(newValue);
  };
  console.log(getSocketMessage, "message");
  return (
    <div className="d-flex container-chat rounded mt-3 ms-3">
      <div className="w-50 bg-white user-chat-header">
        <SearchUser searchUser={searchUser} getSearchUser={getSearchUser} />
        <div className="msger-chat msger-user p-0">
          {getuserList.length > 0
            ? getuserList?.map((item, index) => {
                return (
                  <div
                    className={`d-flex py-2 px-3 align-items-center openChat ${
                      !item?.isRead ? "message-bold" : ""
                    }`}
                    key={index}
                    onClick={(e) => openChat(item)}
                  >
                    <div>
                      <img
                        src={
                          item?.connectUSerInformation?.profileImage
                            ? item?.connectUSerInformation?.profileImage
                            : Alexander
                        }
                        alt=""
                        className="msg-img-user"
                        loading="lazy"
                      />
                    </div>
                    <div className="row justify-content-between w-100 ">
                      <p className="P5">{item?.connectUSerInformation?.name}</p>
                      <div className="d-flex justify-content-between w-100 chatLastText">
                        <p>{item?.body}</p>
                        <div className="text-right">
                          {item?.createdAt
                            ? TimeZone(item)?.time
                            : getCurrentTime()}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      {!getUser ? (
        <div className="d-flex w-100 justify-content-center align-items-center">
          {"Start Chat Now"}
        </div>
      ) : (
        <section className="msger">
          <div className="msger-header align-items-center px-2 d-flex justify-content-between pt-3 pb-2">
            <div className="msger-header-title w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={
                    getUser?.connectUSerInformation?.profileImage
                      ? getUser?.connectUSerInformation?.profileImage
                      : Alexander
                  }
                  alt=""
                  className="msg-img-user"
                  loading="lazy"
                />
                <h4 className="d-flex">
                  {getUser?.connectUSerInformation?.name || getUser?.full_name}
                </h4>
              </div>
              {/* <div className='curser-pointer' onClick={() => getMessageCall(getUser?.connectUSerInformation?.userId)} >
                {!getLoadingMessage ? <img src={require("assets/update.svg").default} alt="" name='refresh Button' loading='lazy'/> : <div className="spinner-border" role="status"> <span className="visually-hidden">Loading...</span> </div>}
              </div> */}
            </div>
          </div>
          <ScrollToBottom className="msger-chat msger-chat-text chat-screen ">
            {getSocketMessage.length > 0 ? (
              getSocketMessage?.map((item, index) => {
                let status =
                  item?.senderId === getUser?.connectUSerInformation?.userId;
                return (
                  <>
                    {dateShow(TimeZone(item)?.date)}
                    <div
                      ref={messagesEndRef}
                      key={index}
                      className={`${
                        status ? "left-msg" : "right-msg gap-3 mb-3 mt-3"
                      } msg`}
                    >
                      <div className="position-relative align-items-center d-flex">
                        <div
                          className={`${
                            status ? "triangle-resiver" : "triangle-sender"
                          }`}
                        ></div>
                        <div
                          className={`${
                            status ? "container-resiver" : "container-sender"
                          } text-white text-break`}
                          aria-readonly
                        >
                          {item?.attachment?.images?.map((element, i) => {
                            return (
                              <div className="d-flex" key={i}>
                                <img
                                  src={`https://atit-8081io-test.s3.ap-northeast-1.amazonaws.com/${element}`}
                                  alt=""
                                  className="getImage"
                                  loading="lazy"
                                />
                                <div className="dropdown">
                                  <div
                                    className={`${
                                      status ? "text-dark" : ""
                                    } ms-2`}
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    :{" "}
                                  </div>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a
                                        className="dropdown-item P6"
                                        href={`https://atit-8081io-test.s3.ap-northeast-1.amazonaws.com/${element}`}
                                        download
                                      >
                                        {"Download"}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })}
                          <div className={`${status ? "text-dark" : ""}`}>
                            {" "}
                            {item?.messageContent}
                          </div>
                          <div
                            className={`${
                              status ? "text-dark" : "text-white"
                            } chatTime mt-2`}
                          >
                            {TimeZone(item)?.time}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">
                {getLoadingMessage ? "Loading..." : "No chat history found."}
              </div>
            )}
          </ScrollToBottom>

          <div
            className={`${
              !imagePreview && "msger-inputarea   "
            } inputfieldcontainer1`}
          >
            <div className="d-flex gap-2 px-3 py-1 ">
              <form onSubmit={userMessageInput} className="d-flex w-100">
                <input
                  type="text"
                  className="msger-input me-3"
                  placeholder={"Enter your message..."}
                  rows="4"
                  value={msg}
                  onChange={(e) => inputMessageSubmit(e)}
                />
                <Button
                  className="msgarea-button"
                  disabled={msg?.length === 0 || getLoadingMessage}
                  type='="submit'
                  text="Send"
                />
              </form>
            </div>
          </div>
          {imagePreview && (
            <div className="imagePreviewcontainer">
              <div
                className="curser-pointer close-preview-img m-3"
                onClick={() => {
                  setImagePreview();
                }}
              >
                <CancleIcon color="#fff" height={20} width={20} />
              </div>
              <div className="imgconatinerpreview">
                <img
                  src={imagePreview}
                  className="imagePreview"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          )}
        </section>
      )}
    </div>
  );
}

export default UserChat;
