import { ClockIcon, RefreshIcon } from "assets";
import moment from "moment";
import React from "react";

const TimeChart = ({ getGraphData }) => {
  return (
    <>
      <div className="timeChartConrainer mt-4 d-flex align-items-center justify-content-center">
        <div className="">
          <div className="d-flex gap-5">
            <div className="d-grid ">
              <div className="img_center align-items-center d-flex justify-content-center">
                {" "}
                <RefreshIcon width={20} height={20} />{" "}
              </div>
              <div className="time">
                {" "}
                {getGraphData?.timeSettings?.periodEvery || ""
                  ? `${
                      getGraphData?.timeSettings?.periodEvery?.length > 4
                        ? getGraphData?.timeSettings?.periodEvery?.slice(0, 4) +
                          "..."
                        : getGraphData?.timeSettings?.periodEvery
                    } Days` || ""
                  : "----"}{" "}
              </div>
            </div>
            <div className="d-grid">
              <div className="img_center align-items-center d-flex justify-content-center">
                {" "}
                <ClockIcon width={20} height={20} />{" "}
              </div>
              <div className="time">
                {!getGraphData?.timeSettings?.periodAt
                  ? "----"
                  : moment(
                      getGraphData?.timeSettings?.periodAt,
                      "HH:mm"
                    ).format("h:mm A")}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomContainer gap-3 ">
        <div className="d-flex  justify-content-center mb-2">
          <p className="p-Two-gray">
            {getGraphData?.timeSettings?.periodEvery
              ? `This trade will run for ${getGraphData?.timeSettings?.periodEvery} day`
              : ""}{" "}
          </p>
        </div>
        <div className="blueBorder mb-4"></div>
      </div>
    </>
  );
};

export default TimeChart;
