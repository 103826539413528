import React from "react";

function CheckIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#14AA39"
      fillRule="evenodd"
      d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#14AA39"
      fillRule="evenodd"
      d="M16.48 8.424a.75.75 0 01.096 1.056l-5 6a.75.75 0 01-1.106.05l-3-3a.75.75 0 111.06-1.06l2.42 2.419 4.474-5.37a.75.75 0 011.056-.095z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default CheckIcon;
