import React from "react";

function BtsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="91"
      fill="none"
      viewBox="0 0 90 91"
    >
      <path
        fill="#F7931A"
        d="M88.648 56.386c-6.01 24.107-30.426 38.778-54.536 32.767-24.1-6.01-38.772-30.428-32.759-54.534C7.361 10.509 31.777-4.164 55.88 1.847c24.108 6.01 38.778 30.43 32.768 54.54z"
      ></path>
      <path
        fill="#fff"
        d="M30.198 53.079c-.245.607-.865 1.518-2.263 1.172.05.072-3.588-.895-3.588-.895l-2.452 5.651 6.425 1.602c1.196.3 2.367.613 3.52.908l-2.043 8.205 4.932 1.23 2.023-8.117c1.348.366 2.655.703 3.935 1.021l-2.017 8.079 4.938 1.23 2.043-8.188c8.42 1.593 14.75.95 17.415-6.664 2.147-6.132-.107-9.668-4.537-11.974 3.226-.744 5.656-2.866 6.304-7.25.896-5.987-3.663-9.206-9.897-11.354l2.022-8.11-4.937-1.231-1.969 7.897a206.516 206.516 0 00-3.955-.93l1.982-7.95-4.934-1.23-2.024 8.107a166.134 166.134 0 01-3.152-.74l.005-.026-6.809-1.7-1.313 5.273s3.663.84 3.586.892c2 .499 2.36 1.822 2.3 2.871l-5.54 22.22zm23.355 1.83c-1.526 6.13-11.85 2.816-15.196 1.985l2.711-10.869c3.347.835 14.08 2.489 12.485 8.883zM55.08 39c-1.392 5.577-9.985 2.743-12.772 2.049l2.458-9.858c2.788.695 11.764 1.991 10.314 7.809z"
      ></path>
    </svg>
  );
}

export default BtsIcon;
