import React, { useEffect, useState } from "react";
import "./Header.scss";
import Addons from "assets/addonsbutton.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AddOns from "./AddOns";
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrum_rdx } from "features/Header/headerSclice";
import { ArrowLeftIcon, NotificationImg } from "assets";
import ActivityLog from "screens/DashboardScreenHome/ActivityLog";
import NotificationBellFillImg from "assets/JsImage/NotificationBellFillImg";
import { bouceState } from "./../globalStore/GlobalStore";
import { useHookstate } from "@hookstate/core";
import DroidModuleSearch from "screens/ManagementScreen/ManagementDroids/DroidModules/DroidModuleSearch";
import { toast } from "react-toastify";
import ClientSubHeader from "./DashboardComponent/ClientSubHeader";
import { dashboardGraph } from "features/Dashboard/dashboardAction";
import { setDashboardGraphInfo } from "features/Dashboard/dashboardSlice";

const HeaderComponent = ({
  title,
  addOnTabs,
  pinnedTabs,
  updateAddOnTabs,
  onClickPin,
  onClickUpdate,
  onClickExpand,
  setShowActivityLog,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const [routeHistory, setRouteHistory] = useState("");
  const [breadcrum, setBreadcrum] = useState(false);
  const [brokerName, setBrokername] = useState("");
  const [path, setPath] = useState("");
  const { newNotification } = useSelector(
    (state) => state?.activityLog?.getAllActivityLogInfo || false
  );
  const { dashboardGraphInfo } = useSelector((state) => state?.dashboard);

  const { breadcrum_rdx } = useSelector((state) => state?.header);
  const [notificationMsg, setNotificationMsg] = useState(false);
  const [getDataBySocket, setDataBySocket] = useState();
  const [getRed, setRed] = useState(false);

  const onBack = () => {
    localStorage.removeItem("routeHistory");
    dispatch(setBreadcrum_rdx(false));
    localStorage.removeItem("brokerName");
    localStorage.removeItem("breadcrum");
    setBreadcrum(false);
    setRouteHistory("");
    setBrokername("");
    navigate(-1);
  };
  window.addEventListener("hide.bs.offcanvas", (event) => {
    setNotificationMsg(false);
    setDataBySocket();
    setRed(false);
  });

  useEffect(() => {
    setNotificationMsg(false);
  }, [location?.pathname]);

  useEffect(() => {
    setBreadcrum(
      breadcrum_rdx ? breadcrum_rdx : localStorage.getItem("breadcrum")
    );
    setRouteHistory(
      localStorage.getItem("routeHistory")
        ? localStorage.getItem("routeHistory")
        : ""
    );
    setBrokername(localStorage.getItem("brokerName"));

    let a = routeHistory.split("/").map((e) => {
      if (e.length) {
        return e.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        );
      }
    });
    setPath(a.slice(1, a.length).join(" / "));
  }, [routeHistory, breadcrum, brokerName, breadcrum_rdx, location?.pathname]);

  useEffect(() => {
    setRed(getDataBySocket?.newNotification);
    if (
      getDataBySocket?.newNotification &&
      localStorage.getItem("role") === "broker"
    ) {
      toast.success(
        `${getDataBySocket?.data[0]?.content} send new ${getDataBySocket?.data[0]?.type}`,
        {
          toastId: 14,
          ...{
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          },
        }
      );
    }
  }, [getDataBySocket?.newNotification]);

  useEffect(() => {
    window.onpopstate = () => {
      localStorage.removeItem("routeHistory");
      dispatch(setBreadcrum_rdx(false));
      localStorage.removeItem("brokerName");
      localStorage.removeItem("breadcrum");
      setBreadcrum(false);
      setRouteHistory("");
      setBrokername("");
    };
  }, [routeHistory, breadcrum, brokerName, breadcrum_rdx, location?.pathname]);

  const isBouceState = useHookstate(bouceState);

  //BELOW CODE ARE ONLY FOR SCROLLING //
  const [showBorder, setShowBorder] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowBorder(true);
      } else {
        setShowBorder(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const finalHeaderClassName =
    location?.pathname === "/dashboard" && showBorder
      ? ` ${localStorage.getItem("role") === "broker" ? "show-border" : ""} `
      : "headerStyle";
  const handleCloseTab = () => {
    window.close();
  };

  useEffect(() => {
    if (dashboardGraphInfo == null) {
      dispatch(dashboardGraph({ userToken })).then((res) => {
        if (res?.meta && res?.meta?.requestStatus === "fulfilled") {
          setDashboardGraphInfo(res?.payload);
        }
      });
    }
  }, []);

  return (
    <>
      {!breadcrum ||
      !location?.pathname.includes(
        localStorage.getItem("role") === "broker"
          ? "retailersProfile"
          : "brokersProfile"
      ) ? (
        <>
          <div className={`${finalHeaderClassName}`}>
            {location?.pathname?.split("/")[2] === "droids" &&
            location?.pathname?.split("/")?.length > 3 ? (
              <div className=" ADSParrentContainer ">
                {" "}
                <DroidModuleSearch />{" "}
              </div>
            ) : (
              <label className="header_label">
                {" "}
                {location.search?.includes("broker")
                  ? "Broker"
                  : title === "Userchat"
                  ? "User Chat"
                  : title === "Client-Dashboard-Details"
                  ? "Client Dashboard Details"
                  : title === "BrokersProfile"
                  ? "Broker Profile"
                  : title}
              </label>
            )}
            <div className="w-25">
              <Link to="/profile">
                <span className="d-flex justify-content-end userTypeShow mb-2">
                  {" "}
                  Account type :{" "}
                  <span className="userTypebold mx-1 text-capitalize">
                    {" "}
                    {localStorage.role}
                  </span>{" "}
                </span>
              </Link>
              <div className="d-flex gap-3 align-items-center DashboardHeaderRightBody mx-1">
                {location?.pathname === "/dashboard" ? (
                  <>
                    <span
                      id={isBouceState.get() && "bounce"}
                      onClick={() => {
                        isBouceState.set(!isBouceState.get());
                      }}
                      className={`${
                        notificationMsg ? "" : "animated non-active"
                      } non-active ${
                        getRed || newNotification ? "icon-Container" : ""
                      }`}
                    >
                      <NotificationImg
                        setShowActivityLog={setShowActivityLog}
                      />
                    </span>
                  </>
                ) : (
                  <span
                    id={isBouceState.get() && "bounce"}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                    className={`${
                      notificationMsg || newNotification
                        ? "active"
                        : "animated non-active"
                    } ${getRed || newNotification ? "icon-Container" : ""}`}
                    onClick={() => {
                      setNotificationMsg(true);
                    }}
                  >
                    {notificationMsg && location?.pathname !== "/dashboard" ? (
                      <NotificationBellFillImg />
                    ) : (
                      <NotificationImg
                        setShowActivityLog={setShowActivityLog}
                      />
                    )}
                  </span>
                )}
                {getRed || newNotification ? (
                  <div className="icon_red"></div>
                ) : (
                  ""
                )}
                <div
                  className="offcanvas offcanvas-end custom-style"
                  tabIndex="-1"
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <ActivityLog
                    notificationMsg={notificationMsg}
                    notificationState="true"
                    getDataBySocket={getDataBySocket}
                    setDataBySocket={setDataBySocket}
                  />
                </div>
                <img
                  src={Addons}
                  alt="AddOns"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#Add-ons"
                  data-bs-backdrop="false"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div
            className="modal"
            id="Add-ons"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="false"
          >
            <div
              className="addOnsBackground"
              data-bs-backdrop="true"
              data-bs-dismiss="modal"
            ></div>
            <div className="modal-dialog ">
              <div className="modal-content border-0">
                <AddOns
                  addOnTabs={addOnTabs}
                  pinnedTabs={pinnedTabs}
                  onClickPin={onClickPin}
                  onClickExpand={onClickExpand}
                  onClickUpdate={onClickUpdate}
                />
              </div>
            </div>
          </div>
          {localStorage.getItem("role") === "retailer" && (
            <ClientSubHeader {...dashboardGraphInfo?.data} />
          )}
        </>
      ) : (
        <div className="headerStyle">
          <div className="d-flex align-items-center justify-content-center cursor-pointer">
            <div
              className="curser-pointer"
              onClick={() => {
                onBack();
              }}
            ></div>
            <p
              onClick={handleCloseTab}
              className="P5 h4-Two justify-content-center d-flex cursor"
            >
              <ArrowLeftIcon />
              {`${path} / `}
              <span className="P5 h4-One">{`${brokerName}`}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderComponent;
