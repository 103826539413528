import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActivityDetails from "./ActivityDetails";
import {
  getActivityLog,
  postUpdatePin,
  deleteMessageFromActivity,
} from "features/ActivityLog/ActivityLogAction";
import { DroidApproval } from "features/Management/managementAction";
import * as Sentry from "@sentry/react";
import Loader from "components/Loader";

function ActivityLog({
  notificationMsg,
  notificationState,
  getDataBySocket,
  setDataBySocket,
}) {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const location = useLocation();
  const [ActivityLogData, setActivityLogData] = useState([]);
  const [getFlag, setFlag] = useState(true);
  const [getCallActivity, setCallActivity] = useState(true);
  const [getLoading, setLoading] = useState(true);
  const [loadActivityLog, setLoadActivityLog] = useState(false);
  const [getRedDot, setRedDot] = useState();
  const [getRead, setRead] = useState(false);

  const fetchActivityLog = () => {
    setLoadActivityLog(true);
    dispatch(getActivityLog({ userToken })).then((res) => {
      if (res.meta?.requestStatus === "fulfilled") {
        setActivityLogData(res.payload?.data);
        setLoadActivityLog(false);
        setRedDot(res.payload?.newNotification);
      }
    });
  };

  useEffect(() => {
    if (getLoading || getFlag || getCallActivity || getRead) {
      setCallActivity(false);
      setFlag(false);
      setLoading(false);
      setRead(false);
      fetchActivityLog();
    }
  }, [getLoading, getFlag, getCallActivity, getRead]);

  useEffect(() => {
    if (
      getDataBySocket !== undefined &&
      getDataBySocket?.data[0] !== undefined
    ) {
      const newObject = { ...getDataBySocket?.data[0] };
      const updatedObjects = [newObject, ...ActivityLogData];
      setActivityLogData(updatedObjects);
    }
  }, [getDataBySocket]);

  return (
    <>
      {location?.pathname === "/dashboard" || !notificationMsg ? (
        ""
      ) : !loadActivityLog && ActivityLogData?.length > 0 ? (
        <div
          className={
            notificationState
              ? "w-100 notification-box assistentLink"
              : "w-100 assistentLink"
          }
        >
          <div className="assistLinkCard d-grid gap-4 border-0 dashboard-card">
            <ActivityDetails />
          </div>
        </div>
      ) : (
        <div
          className=" assistLinkCard d-flex justify-content-center"
          style={{ right: "25px" }}
        >
          {loadActivityLog ? (
            <Loader />
          ) : (
            !loadActivityLog &&
            ActivityLogData?.length === 0 &&
            "Data not found"
          )}
        </div>
      )}
    </>
  );
}

export default ActivityLog;
