import React from 'react'
import './index.scss'

function SecondryButton({databstarget, databstoggle,arialabel, small, className, variant = 'secondary', databsdismiss, icon, text, handleClick, disabled, loading, type = "button", ...rest }) {
  if (loading)
    return (
      <button className={`custom-button ${disabled ? `button-${variant}-disabled` : `button-${variant}`} ${className} ${small ? 'sm' : ''}`} type={type} {...rest} disabled={disabled}>
        <span className="spinner-border text-light" role="status" aria-hidden="true"></span>  {icon} {text} </button>);
  return (
    <button data-bs-toggle={databstoggle} data-bs-dismiss={databsdismiss} onClick={handleClick}  data-bs-target={databstarget} disabled={disabled} {...rest} className={`d-flex gap-2 button-secondary px-3 py-2 ${disabled ? `button-${variant}-disabled` : `button-${variant}`} ${className}`}> {icon} {text}</button>
  )
}

export default SecondryButton