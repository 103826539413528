import TooltipQuestionMark from "components/Charts/TooltipQuestionMark";
import {
  clearDroidIndexError,
  setDroidIndexError,
} from "features/ErrorIndexDroid/ErrorIndexDroid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const timePeriodData = [
  { label: "Hourly" },
  { label: "Daily" },
  { label: "Weekly" },
  { label: "Never" },
];
const IndexModeDroidChild = ({ droidDataDispatch, minBalance }) => {
  const [getError, setError] = useState({ name: "", error: "" });
  const [getErrortotalBuyAmount, setErrortotalBuyAmount] = useState({
    isValid: false,
    error: "",
  });
  const [getErrortimePeriod, setErrortimePeriod] = useState({
    isValid: false,
    error: "",
  });
  const twoDecimalPointValidation = /^\d*\.?\d{0,2}$/;
  const [totalPercent, settotalPercent] = useState(0);
  const { getDroidsDataInfo } = useSelector((state) => state?.singleDroidInfo);
  const dispatch = useDispatch();
  const valueOfIndexWarning = useSelector((state) => state?.errorDroidIndex);
  const handleChangeRebalanceStrategy = (event) => {
    const { id, value } = event.target;

    if (value === "") {
      setError({ id, error: "This field must not remain empty." });
      droidDataDispatch({
        rebalanceStrategy: {
          ...getDroidsDataInfo?.rebalanceStrategy,
          [id]: value,
        },
      });
    } else if (!twoDecimalPointValidation.test(value)) {
      setError({
        id: id,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
    } else if (value < 0) {
      setError({ id: id, error: "Please input a positive value." });
    } else {
      const stopLossTimePeriodValue =
        getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay;
      let maxAllowedValue;

      switch (stopLossTimePeriodValue) {
        case "Daily":
          maxAllowedValue = 24;
          break;
        case "Weekly":
          maxAllowedValue = 7;
          break;
        case "Hourly":
          maxAllowedValue = 4;
          break;
        case "Never":
          maxAllowedValue = 7;
          break;
        default:
          maxAllowedValue = 0;
          break;
      }

      if (id === "timePeriod" && value > maxAllowedValue) {
        setErrortimePeriod({
          isValid: true,
          error: `Please input a value that is less than or equal to ${maxAllowedValue}`,
        });
        dispatch(setDroidIndexError({ key: "timePeriod" }));
      } else {
        setErrortimePeriod({ isValid: false, error: "" });
        dispatch(clearDroidIndexError({ key: "timePeriod" }));
        droidDataDispatch({
          rebalanceStrategy: {
            ...getDroidsDataInfo?.rebalanceStrategy,
            [id]: value,
          },
        });
      }
    }
  };

  useEffect(() => {
    let totalPercentage = 0;
    getDroidsDataInfo?.coinsList.forEach((coin) => {
      totalPercentage += Number(coin.value);
    });

    if (totalPercentage !== 100) {
      // setErrortotalBuyAmount({ isValid:false, error:''});
      setError({ name: "", error: "" });
      setErrortotalBuyAmount({ isValid: false, error: "" });
      // droidDataDispatch({ "totalBuyAmount":"" });
    }
  }, [getDroidsDataInfo]);

  const handleChangeBalancing1 = (event) => {
    const { id, value, checked } = event.target;
    if (value === "on") {
      droidDataDispatch({
        balancing: {
          ...getDroidsDataInfo?.balancing,
          dollarCostAverage: checked,
        },
      });
    } else {
      if (!twoDecimalPointValidation.test(value)) {
        setError({
          id: id,
          error:
            "Please input only numbers with a maximum of two decimal places.",
        });
      } else if (value < 0) {
        setError({ id: id, error: "Please input a positive value." });
      } else {
        if (id === "thresholdBalancing" && value > 100) {
          setError({
            id: id,
            error: "Please input a value that is less than or equal to 100%.",
          });
        } else {
          setError({ id: "", error: "" });
          droidDataDispatch({
            balancing: { ...getDroidsDataInfo?.balancing, [id]: value },
          });
        }
      }
    }
  };

  const calculateMinimumInputValue = (coinsData, value = 0) => {
    let totalPercentage = 0;
    let totalMinimumInputValue = 0;
    let amountAllocated = 0;
    let minAmount = 0;
    let adjustedMinAmount = 0;
    let errorMessage = {};

    coinsData.forEach((coin) => {
      totalPercentage += Number(coin.value);
    });

    if (totalPercentage !== 100) {
      errorMessage = {
        status: "failed",
        message: "Total percentage of the above should be equal to 100% first",
      };
    }

    coinsData.forEach((coin) => {
      const percent = Number(coin.value);
      minAmount = Number(coin.minAmount);

      adjustedMinAmount = minAmount * 1.05;

      amountAllocated = (percent / 100) * Number(value);
      const minimumInputValue = Math.max(amountAllocated, minAmount);
      totalMinimumInputValue += minimumInputValue;

      if (amountAllocated < adjustedMinAmount) {
        errorMessage = {
          status: "failed",
          message: `Cannot purchase ${coin.name} with the current percentage. Please increase the amount or the percentage.`,
        };
      }
    });

    return errorMessage;
  };

  const checkdisabled = () => {
    let totalPercentage = 0;
    getDroidsDataInfo?.coinsList.forEach((coin) => {
      totalPercentage += Number(coin.value);
    });

    if (totalPercentage === 100) {
      return false;
    } else {
      return true;
    }
  };

  const totalBuyAmountCall = (e) => {
    const { name, value } = e?.target;
    if (Number(value) > Number(minBalance)) {
      setErrortotalBuyAmount({
        isValid: true,
        error: `Please verify the maximum entered amount. $${Number(
          minBalance
        )?.toFixed(2)}`,
      });
      return;
    }
    const { status, message } = calculateMinimumInputValue(
      getDroidsDataInfo?.coinsList,
      value
    );
    if (
      status === "failed" &&
      message ===
        "Total percentage of the above should be equal to 100% first" &&
      name === "totalBuyAmount"
    ) {
      setErrortotalBuyAmount({ isValid: true, error: `${message}` });
      droidDataDispatch({ [name]: 0 });
      dispatch(setDroidIndexError({ key: "totalBuyAmount" }));
      return;
    } else {
      dispatch(clearDroidIndexError({ key: "totalBuyAmount" }));
    }
    if (status === "failed" && name === "totalBuyAmount") {
      setErrortotalBuyAmount({ isValid: true, error: `${message}` });
      droidDataDispatch({ [name]: value });
      dispatch(setDroidIndexError({ key: "totalBuyAmount" }));
      return;
    } else {
      dispatch(clearDroidIndexError({ key: "totalBuyAmount" }));
    }
    const onlyNumberRegex = /^\d*\.?\d{0,2}$/;

    if (value === "") {
      setError({ name: name, error: "This field must not remain empty." });
      droidDataDispatch({ [name]: value });
    } else if (!onlyNumberRegex.test(value)) {
      setError({
        name: name,
        error:
          "Please input only numbers with a maximum of two decimal places.",
      });
    } else if (value?.length > 2 && value == 0) {
      setError({ name: name, error: "Value must be greater than 0." });
    } else if (minBalance === 0) {
      setError({ name: name, error: `Please choose a portfolio.` });
    } else if (Number(minBalance) < Number(value)) {
      if (name === "totalBuyAmount") {
        setErrortotalBuyAmount({
          isValid: true,
          error: `Please verify the maximum entered amount. $${minBalance?.toFixed(
            2
          )}`,
        });
      }
    } else {
      setError({ name: "", error: "" });
      setErrortotalBuyAmount({ isValid: false, error: "" });
      droidDataDispatch({ [name]: value === "" ? "" : value });
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h6>Total Buy Amount</h6>
        {!getDroidsDataInfo?.totalBuyAmount ? (
          <p className="required-field">*</p>
        ) : null}
      </div>
      <div className="d-flex position-relative MB-2">
        <input
          type="text"
          disabled={checkdisabled()}
          className="D-W-3  pe-4"
          name="totalBuyAmount"
          value={
            !getDroidsDataInfo?.totalBuyAmount
              ? ""
              : getDroidsDataInfo?.totalBuyAmount
          }
          onChange={totalBuyAmountCall}
          placeholder="0"
        />
      </div>
      {getErrortotalBuyAmount?.isValid === true && (
        <div className="text-danger">⚠️ {getErrortotalBuyAmount?.error}</div>
      )}
      {/* <hr></hr>
      <h4 className="mb-4">Rebalance Strategy</h4>
      <div className="row mt-3">
        <div className="col-lg-6">
          <div className="d-flex align-items-center">
            <h6>Time Period</h6>
            {!(
              getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay === "Never"
            ) && !getDroidsDataInfo?.rebalanceStrategy?.timePeriod ? (
              <p className="required-field">*</p>
            ) : null}
          </div>
          <div className="d-flex position-relative MB-2">
            <input
              type="text"
              className="D-W-3  pe-4"
              name="timePeriod"
              id="timePeriod"
              disabled={
                getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay === "Never"
              }
              value={
                getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay === "Never"
                  ? ""
                  : !getDroidsDataInfo?.rebalanceStrategy?.timePeriod
                  ? ""
                  : getDroidsDataInfo?.rebalanceStrategy?.timePeriod
              }
              onChange={handleChangeRebalanceStrategy}
              placeholder="0"
            />
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-end">
          <div
            className={`my-2 btn-group simpledropdown webkit-fill-available MT-8`}
          >
            <button
              className="btn dropdown-toggle d-flex justify-content-between align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
            >
              <div>{getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay}</div>
              <img
                src={require("assets/arrowDown.svg").default}
                alt=""
                className="ML-6"
                loading="lazy"
              />
            </button>
            <ul className="dropdown-menu">
              {timePeriodData?.map((item, index) => (
                <li
                  key={index}
                  className={`dropdown-item py-3 justify-content-between d-flex ${
                    timePeriodData?.length - 1 == index ? "" : "border-bottom"
                  }`}
                  onClick={() =>
                    droidDataDispatch({
                      rebalanceStrategy: {
                        ...getDroidsDataInfo?.rebalanceStrategy,
                        timePeriodDay: item?.label,
                      },
                    })
                  }
                >
                  {item?.label}{" "}
                  {getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay ==
                  item?.label ? (
                    <img
                      src={require("assets/rightMark.svg").default}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {getDroidsDataInfo?.rebalanceStrategy?.timePeriodDay === "Never" ? (
        ""
      ) : (
        <>
          {getErrortimePeriod?.isValid === true && (
            <div className="text-danger">⚠️ {getErrortimePeriod?.error}</div>
          )}
        </>
      )}
      <div className="border-bottom my-4"></div>
      <h4 className="mb-2">Balancing</h4>
      <div className="row my-4">
        <div className="col-lg-6 col">
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center">
              <h6>Threshold</h6>
              {!(
                getDroidsDataInfo?.balancing?.stopLossTimePeriod === "Never"
              ) && !getDroidsDataInfo?.balancing?.thresholdBalancing ? (
                <p className="required-field">*</p>
              ) : null}{" "}
            </div>
            <TooltipQuestionMark
              toolTipContent={
                "The threshold is the percent at which the stop-loss is triggered. It is applied over the time period that is specified in the previous box. A threshold of -5% with a time period of one day would mean the stop-loss will trigger when the value of the portfolio decreases by 5% in one day."
              }
            />
          </div>
          <div className="d-flex position-relative">
            <input
              type="text"
              disabled={
                getDroidsDataInfo?.balancing?.stopLossTimePeriod === "Never"
              }
              value={
                getDroidsDataInfo?.balancing?.stopLossTimePeriod === "Never"
                  ? ""
                  : getDroidsDataInfo?.balancing?.thresholdBalancing ===
                    undefined
                  ? ""
                  : getDroidsDataInfo?.balancing?.thresholdBalancing
              }
              className="pe-4"
              id="thresholdBalancing"
              onChange={handleChangeBalancing1}
              placeholder="0"
            />
            <h5 className="position-absolute percentage-input">%</h5>
          </div>
          {getError?.id === "thresholdBalancing" && (
            <div className="text-danger">⚠️ {getError?.error}</div>
          )}
        </div>
        <div className="col-lg-6 col">
          <div className="d-flex align-items-center gap-2 mb-2">
            <h6 className="">Balancing Time Period</h6>
            <TooltipQuestionMark
              toolTipContent={
                "The time period defines the length of time that is evaluated for the stop-loss. A time period of one day would mean 8081 will evaluate the last day of portfolio performance to determine if the stop-loss should trigger."
              }
            />
          </div>
          <div
            className={`btn-group simpledropdown D-W-3 small-dropdown-height`}
          >
            <button
              className="btn dropdown-toggle d-flex justify-content-between align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
            >
              <div>{getDroidsDataInfo?.balancing?.stopLossTimePeriod}</div>
              <img
                src={require("assets/arrowDown.svg").default}
                alt=""
                className="ML-6"
                loading="lazy"
              />
            </button>
            <ul className="dropdown-menu">
              {timePeriodData?.map((item, index) => (
                <li
                  key={index}
                  className={`dropdown-item py-3 justify-content-between d-flex ${
                    timePeriodData?.length - 1 == index ? "" : "border-bottom"
                  }`}
                  onClick={() =>
                    droidDataDispatch({
                      balancing: {
                        ...getDroidsDataInfo?.balancing,
                        stopLossTimePeriod: item?.label,
                      },
                    })
                  }
                >
                  {item?.label}{" "}
                  {getDroidsDataInfo?.balancing?.stopLossTimePeriod ==
                  item?.label ? (
                    <img
                      src={require("assets/rightMark.svg").default}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default IndexModeDroidChild;
