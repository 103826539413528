import React from 'react'

const Google = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            fill="none"
            viewBox="0 0 25 24"
        >
            <g clipPath="url(#clip0_3304_6374)">
                <path
                    fill="#4285F4"
                    d="M23.06 12.496c0-.886-.072-1.532-.227-2.203H12.715v4h5.938c-.12.993-.766 2.49-2.203 3.495l-.02.134L19.63 20.4l.221.022c2.036-1.88 3.209-4.645 3.209-7.926z"
                ></path>
                <path
                    fill="#34A853"
                    d="M12.716 23.032c2.909 0 5.351-.958 7.135-2.61l-3.4-2.634c-.91.635-2.131 1.078-3.735 1.078-2.85 0-5.268-1.88-6.13-4.478l-.127.01-3.326 2.575-.044.12a10.768 10.768 0 009.627 5.94z"
                ></path>
                <path
                    fill="#FBBC05"
                    d="M6.585 14.388a6.633 6.633 0 01-.359-2.131c0-.743.132-1.461.347-2.131l-.006-.143L3.2 7.367l-.11.053a10.786 10.786 0 00-1.15 4.837c0 1.736.42 3.376 1.15 4.837l3.496-2.706z"
                ></path>
                <path
                    fill="#EB4335"
                    d="M12.716 5.648c2.023 0 3.388.874 4.166 1.604l3.041-2.97c-1.867-1.735-4.298-2.8-7.207-2.8A10.768 10.768 0 003.089 7.42l3.485 2.706c.874-2.599 3.292-4.478 6.142-4.478z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_3304_6374">
                    <path
                        fill="#fff"
                        d="M0 0H21.12V21.613H0z"
                        transform="translate(1.94 1.45)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Google