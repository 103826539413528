import React from "react";

function ArrowRightIcon({color='#060201',width=8,height=14}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        fill={color}
        d="M1.53.47A.75.75 0 00.47 1.53L5.94 7 .47 12.47a.75.75 0 101.06 1.06l6-6a.75.75 0 000-1.06l-6-6z"
      ></path>
    </svg>
  );
}

export default ArrowRightIcon;
