import React from 'react'
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';


const CreateDroid = ({ data, status }) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const handleClick = () => {
    navigate(`/management/droids`);

  }

  return (
    <div className=' col-lg-6'>
      <div className="d-flex align-items-baseline gap-2 mb-3">
        <h3>{t('droids')}</h3>
        <h5 className="text-muted">{data?.totalDroids} {t('active')}</h5>
      </div>
      <div className='droidContainerDashboard healthCardDashboard col-lg-6 d-flex flex-row flex-column  align-items-center justify-content-center'>
        {!status ? <div className='d-flex justify-content-center align-items-center w-100'><Loader /></div> :
          <>
            <div className='d-flex flex-column justify-content-center'>
              <img src={require(`assets/createDroidGray.svg`).default} alt="" loading='lazy' />
            </div>
            <div className='my-3'>
              <h5 className='h5-Three'>{t("noDroidsYet")}</h5>
            </div>
            <Button onClick={handleClick} data-bs-toggle="modal" data-bs-target="#NDTPopupId" text={t('addDroids')} icon={<img src={require(`assets/whitePlusIcon.svg`).default} alt="" loading='lazy' />} />
          </>}
      </div>
    </div>
  )
}

export default CreateDroid