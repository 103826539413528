import React from 'react';
import './index.scss';

function SingleSlelectDropDown({ disabled, className, title, dropdown, getClickDropdoen, setClickDropdoen, img2 }) {
  return (
    <div className={`btn-group simpledropdown ${className}`}>
      <button
        disabled={disabled}
        className="btn dropdown-toggle d-flex justify-content-between align-items-center"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="true"
        aria-expanded="false">
        <div>{getClickDropdoen === '' ? title : getClickDropdoen}</div>
        <img src={require('assets/arrowDown.svg').default} alt='' className='ML-6' loading='lazy'/>
      </button>
      <ul className="dropdown-menu max-hight-dropdown webkit-fill-available">
        {dropdown?.map((item, index) => (
          <li key={index} className={`dropdown-item py-3 justify-content-between d-flex gap-3 ${dropdown?.length - 1 === index ? '' : 'border-bottom'
            } ${item.label === 'Trading view custom signal' ? 'disabled' : ''}`}
            onClick={() => {
              if (item.label !== 'Trading view custom signal') {
                setClickDropdoen(item?.label);
              }
            }} >
            <div className='gap-3 d-flex align-items-center'>
              {item?.img} {item?.label}
            </div>
            {getClickDropdoen === item?.label ? img2 : ''}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SingleSlelectDropDown;
