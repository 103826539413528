import React from "react";

function Tether({ht, wd, bg, text}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect width="44" height="44" fill={bg} rx="22"></rect>
      <path
        fill={text}
        fillRule="evenodd"
        d="M24.136 22.648c-.119.009-.735.046-2.11.046-1.094 0-1.871-.033-2.143-.046-4.227-.186-7.383-.922-7.383-1.803 0-.88 3.155-1.615 7.383-1.804v2.874c.276.02 1.067.067 2.161.067 1.313 0 1.97-.055 2.088-.066v-2.873c4.218.188 7.367.923 7.367 1.802 0 .88-3.147 1.615-7.367 1.802l.004.001zm0-3.903v-2.572h5.887V12.25H13.996v3.923h5.885v2.571c-4.783.22-8.381 1.168-8.381 2.303 0 1.136 3.598 2.082 8.381 2.303v8.243h4.254v-8.246c4.773-.22 8.365-1.166 8.365-2.3 0-1.135-3.589-2.082-8.365-2.303l.001.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Tether;