import DroidDoughnutChart from 'components/Charts/DroidDoughnut';
import Loader from 'components/Loader';
import React from 'react'

const DroidComponent = ({ data, status }) => {
    return (
        <div className='col-lg-6'>
            <div className="d-flex align-items-baseline gap-2 mb-3">
                <h3>Droids</h3>
                <h5 className="text-muted">{data?.totalDroids} Total</h5>
            </div>
            <div className="droidContainerDashboard healthCardDashboard d-flex ">
                {!status ? <div className='d-flex justify-content-center align-items-center w-100'><Loader /></div> : <DroidDoughnutChart {...data} satatus={false} />}
            </div>
        </div>
    )
}

export default DroidComponent