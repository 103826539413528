import React, { useEffect, useState } from "react";
import './index.scss'
import logo from "assets/companylogo.svg";
import { useNavigate, Outlet, useLocation, Link } from "react-router-dom"
import { useSelector } from "react-redux";
import { ClockIcon } from "assets";
const LandingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useSelector((state) => state.auth);
  const [page, setPage] = useState("login");
  const switchHandler = () => {
    if (page === "signup") {
      setPage("login");
      navigate("/login")
      localStorage.removeItem("invitedUser");
    } else if (page === "login") {
      setPage("signup");
      navigate("/signup")
    }
  };

  useEffect(() => {
    if (!userToken) {
      navigate("/login")
    } else {
      navigate("/dashboard");
    }
  }, [])

  useEffect(() => {
    setPage(location?.pathname?.substring(1))
  }, [location?.pathname])

  return (
    <>
      {!userToken && <div className="container-fluid landingpage">
        <div className="row">
          <div className={`col-lg-6 landingpage px-5 pt-5 background-left position-fixed landingPageResponsive`}>
            <Link to='/login'> <img src={logo} alt="Company_Logo" className="company_logo_img" loading='lazy'/> </Link>
            <div className="landing_page_content">
              <h1 className="landing_page_title">Get Started Today</h1>
              <p className="landing_page_text">
                8081 is a desktop and mobile online Financial Application <br />
                for trading Crypto with support of Automatic tools, <br />
                Machine Learning and Artificial Intelligence - fits any <br />
                kind of investors (beginner or advance), no more trading <br />
                in the dark, connect to all major exchanges.
              </p>
            </div>
            <div className="d-flex responsiveLandingPage">
              <div className="d-flex widthImg align-items-center leftText">
                <div className="img-shadow me-3">
                  <img src={require('assets/trading.svg').default} alt="Trading_Image" loading='lazy'/>
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <h5>Trading Automation</h5>
                    <p className="landing_page_text-icon">Create automated trade rules</p>
                  </div>
                </div>
              </div>
              <div className="d-flex widthImg align-items-center leftText">
                <div className="img-shadow me-3 img-shadow-width">
                  <img src={require('assets/fully.svg').default} alt="Trading_Image" loading='lazy'/>
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <h5>Fully Synced </h5>
                    <p className="landing_page_text-icon">Connected to your phone, mail and social platforms.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="topspace d-flex responsiveLandingPage">
              <div className="d-flex widthImg align-items-center leftText">
                <div className="img-shadow me-3">
                  <ClockIcon height={30} width={30} color="#000" />
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <h5>24/7 Market Updates</h5>
                    <p className="landing_page_text-icon">Get the latest stats and market news.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex widthImg align-items-center leftText">
                <div className="img-shadow me-3">
                  <img src={require('assets/fast.svg').default} alt="Trading_Image" loading='lazy'/>
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    <h5>Fast and Reliable</h5>
                    <p className="landing_page_text-icon">Top notch trading performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 px-5 pt-5 right-signup">
            <div className="position-absolute right-0 signupBtnResponsive">
              {page === "signup" || page === "login" ? (
                <button className="main_custom_button" onClick={switchHandler}>
                  {page === "signup" ? "Log In" : "Sign Up"}
                </button>
              ) : null}
            </div>
            <Outlet />
          </div>
        </div>
      </div>}
    </>
  );
};

export default LandingScreen;
