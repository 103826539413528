import React, { useEffect, useState } from "react";
import { historicGlobalMetrics } from 'features/Dashboard/dashboardAction'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

function GlobalMarketCapChart({ dataChart, height }) {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.auth);
  const [getBalance, setBalance] = useState([])
  const [getBalanceDate, setBalanceDate] = useState([])

  useEffect(() => {

    dispatch(historicGlobalMetrics({ userToken })).then((res) => {
      let response = JSON.parse(localStorage.getItem('historicGlobalMetric'))
      if (response) {
        setBalance(Object.entries(response?.data, dataChart).map(([_, values]) => values[0]))
        setBalanceDate(Object.keys(response.data, dataChart))
      }

    });
  }, []);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      filler: {
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            const value = numeral(context.parsed.y).format("$0,0");
            return `${value}`;
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.1,
      },
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index, values) {
            return numeral(value).format("0,0");
          },
        },
      },
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: getBalance,
        borderColor: "rgba(255,20,30)",
        borderWidth: 2,
        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 30);
          gradient.addColorStop(0, "rgba(255,20,30)");
          gradient.addColorStop(1, "rgb(27, 26, 29)");
          return gradient;
        },
      },
    ],
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const shortDateFormat = {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString(undefined, shortDateFormat);
    return formattedDate;
  }

  return (
    <div className="d-flex flex-column justify-content-center h-75">
      <div className="card-body w-100 mx-1 mt-4 align-items-center  capitalMarketChart">
        <Line options={options} data={data} style={{ height: "8vh" }} />
      </div>
      {labels && (
        <div className="d-flex justify-content-between capitalMarketChartLabels mt-2">
          <span>{getBalanceDate?.[0] && formatDate(getBalanceDate[0])}</span>
          <span>{getBalanceDate?.[(getBalanceDate.length - 1) / 2] && formatDate(getBalanceDate[(getBalanceDate.length - 1) / 2])}</span>
          <span>{getBalanceDate?.[getBalanceDate.length - 1] && formatDate(getBalanceDate[getBalanceDate.length - 1])}</span>
        </div>
      )}
    </div>
  );
}

export default GlobalMarketCapChart;
