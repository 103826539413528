import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ErrorCode from "ErrorCode";

export const clientInvite = createAsyncThunk(
  "management/clientInvite",
  async ({ userToken, licenseFile, getMessages }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      let formData = new FormData();
      formData.append("attachment", licenseFile[0]);
      formData.append("message", getMessages);

      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/inviteEndUsers`,
        formData,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.message;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const emailInviteMessage = createAsyncThunk(
  "management/emailInviteMessage",
  async ({ userToken, getTone, informal }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BASEURL
        }/api/getEMailInviteMessage?formality=${informal?.toLowerCase()}&tone=${getTone?.toLowerCase()}`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.emailInviteMessage;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getInvitedClientCountAPI = createAsyncThunk(
  "management/getInvitedClientCount",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getInvitedClientCount`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getQuestionsAPI = createAsyncThunk(
  "management/getQuestions",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getQuestions`,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        localStorage.setItem("questions", JSON.stringify(data.data.questions));
        return data.data.questions;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const answerToQuestionAPI = createAsyncThunk(
  "management/answerToQuestion",
  async ({ userToken, item, getAnswerMessages }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/answerToQuestion/${getAnswerMessages?.id}`,
        { answer: getAnswerMessages?.message },
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.questions;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-----------------------for activity log answer a question----------
export const answerToQuestionActivity = createAsyncThunk(
  "management/answerToQuestionActivity",
  async ({ userToken, relationId, getAnswerMessages }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/answerToQuestion/${relationId}`,
        { answer: getAnswerMessages?.message },
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.data.questions;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//-----------------------for activity log Droid Approval----------
export const DroidApproval = createAsyncThunk(
  "management/approveDroid",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/approveDroid`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.message;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendPendingRemainder = createAsyncThunk(
  "management/sendPendingRemainder",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/sendPendingRemainder`,
        config
      );

      if (data.hasOwnProperty("error")) {
        ErrorCode(data);
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDeclinedUserList = createAsyncThunk(
  "management/getDeclinedUserList",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getDeclinedUserList`,
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendMailToDeclinedUser = createAsyncThunk(
  "management/sendMailToDeclinedUser",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/sendMailToDeclinedUser`,
        payload,
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const connectedUserList = createAsyncThunk(
  "management/connectedUserList",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/connectedUserList`,
        {},
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const allConnectedUserListClient = createAsyncThunk(
  "management/allConnectedUserList",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/allConnectedUserList`,
        {
          filterObj: {
            isNonRegisterUser: false,
            search: "",
            offset: payload.page,
          },
        },
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getConnectedUserListClient = createAsyncThunk(
  "management/getExchanegCountByUSer",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getExchanegCountByUSer`,
        config
      );

      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//-----------------------for Management Portfolios Screen [Navbar > Management > Portfolios]----------//
export const getPorfolioByUserId = createAsyncThunk(
  "management/getPorfolioByUserId",
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/getPorfolioByUserId`,
        config
      );
      if (data.hasOwnProperty("error") && data.error === "No portfolio found") {
        return rejectWithValue([]);
      } else if (
        data.hasOwnProperty("error") &&
        data.error !== "No portfolio found"
      ) {
        return rejectWithValue(data.error);
      } else return data?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changeDroidStatus = createAsyncThunk(
  "management/changeDroidStatus",
  async ({ userToken, payload }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: userToken,
          "X-custom-header": "$Ecur!tyHEadEr",
        },
      };
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/api/changeDroidStatus`,
        payload,
        config
      );
      if (data.hasOwnProperty("error")) {
        return rejectWithValue(data.message);
      } else {
        return data.message;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        ErrorCode(error.response.data);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
