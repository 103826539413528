import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DroidDoughnutChartLabels from "../Charts/DroidDoughnutChartLabels";
import DroidDoughnutChartLegends from "../Charts/DroidDoughnutChartLegends";
import { useLocation, useNavigate } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function DroidDoughnutChart(props) {
  let navigate = useNavigate();
  const location = useLocation()

  const data = {
    labels: ["Droids at Profitable", "Droids at Loss"],
    datasets: [
      {
        data: (props?.data?.length > 0 || props?.data!==undefined) ? props?.data : [props?.profitPercentage===0 && props?.lossPercentage===0 ? 1 : props?.profitPercentage, props?.lossPercentage],
        backgroundColor: [props?.profitPercentage===0 ? "#c2c2c2" : "#14AA39", "#DE2A20"],
        display: true,
        borderColor: "#D1D6DC",
        borderRadius: 0,
        borderWidth: 0,
      },
    ],
  };
  
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      
      const table = document.createElement('div');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const tableHead = document.createElement('div');

      titleLines.forEach(title => {
        const th = document.createElement('div');
        th.style.borderWidth = 0;
        th.setAttribute("class", "tooltip-arrow");
        const text = document.createTextNode(title);
        th.appendChild(text);
        tableHead.appendChild(th);
      });

      const tableRoot = tooltipEl.querySelector('div');
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      tableRoot.appendChild(tableHead);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX - 10 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY - 10 + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = '8px';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.fontWeight = 500;
    tooltipEl.style.background = 'white';
    tooltipEl.style.color = '#0070E4';
    tooltipEl.style.fontSize = '12px';
    tooltipEl.style.boxShadow = '0 0 12px rgba(0,112,228,.3)';
    tooltipEl.style.zIndex = 9999;
    tooltipEl.style.width = '8.75rem';
    // if(location?.pathname === "/client-Dashboard-Details"){

    //   tooltipEl.style.opacity=0;
    // } 

  };

  const options = {
    spacing: 0,
    categoryPercentage: 0.8,
    onClick: (event, elements, chart) => {
      if (elements[0]) {
        const i = elements[0].index;
        const label = chart.data.labels[i];
        if (label === 'Droids at Loss') {
          navigate('/management/droids',{ state: { filterData: 'At Loss' } })
        } else if (label === 'Droids at Profitable') {
          navigate('/management/droids',{ state: { filterData: 'Profitable' } })
        }
      
    }
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          fullSize: true,
          usePointStyle: true,
          font: {
            size: 12
          }
        },
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        callbacks: {
          label: function () {
            return null
          }
        },
        external: externalTooltipHandler,
      }

    },
    radius: 85,
    cutout: "90%",
    maintainAspectRatio: false
  };
  
  return (
    <div className="DoughnutContainerMain position-relative ">
      <div className="DCSubContainer">
        <div className="DCChart">
          <div className="droid_container">
            <Doughnut data={data} options={options}  className="cursor"/>
          </div>
          <div className="PercentageProfitable position-absolute">
            <DroidDoughnutChartLabels Percent={!props?.profitPercentage ? '0' : props?.profitPercentage} PercentName={'profitable'} />
          </div>
        </div>
        <div className="DroidDoughnutLegends position-relative">
          <DroidDoughnutChartLegends data={props}/>
        </div>
      </div>
    </div>

  );
}
