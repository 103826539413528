import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import "./StocBalanceGraph.scss";
import Loader from "components/Loader";

const StocBalanceGraph = ({ getBalance, chartType, isLoading }) => {
  const [chartData, setChartData] = useState({
    totalAmountArray: [],
    filteredDatesAndTime: [],
    chartOptions: {},
    chartSeries: [{ name: "Series", data: [] }],
  });

  useEffect(() => {
    processData();
  }, [getBalance, chartType]);

  const processData = async () => {
    if (!getBalance || getBalance.length === 0) {
      setChartData({
        totalAmountArray: [],
        filteredDatesAndTime: [],
        chartOptions: {},
        chartSeries: [{ name: "Series", data: [] }],
      });
      return;
    }

    let totalAmountArray = [];
    let filteredDatesAndTime = [];
    let readableTime = [];
    let readableDates = [];

    if (chartType !== "day") {
      totalAmountArray =
        getBalance?.map((item) => item.totalAmount.toFixed(2)) || [];
      readableDates = getBalance?.map((item) => item.interval) || [];
      if (readableDates.length && chartType === "day") {
        readableDates = readableDates.map((date) => moment(date).format("HH"));
      }
      if (readableDates.length) {
        readableDates.sort((a, b) => new Date(a) - new Date(b));
      }
    }

    if (chartType === "day") {
      totalAmountArray =
        getBalance?.map((item) => item.totalAmount.toFixed(2)) || [];
      readableTime = await processTimeData(getBalance);
    }

    filteredDatesAndTime = chartType === "day" ? readableTime : readableDates;

    const options = {
      chart: {
        id: "area-datetime",
        height: 200,
        type: "area",
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          autoScaleXaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          tools: {
            zoomin: true,
            zoomout: true,
            download: false,
            pan: false,
          },
          autoSelected: "selection",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#149d19"],
      stroke: {
        curve: "smooth",
        width: 2,
      },
      yaxis: {
        labels: {
          rotate: 0,
          rotateAlways: false,
          formatter: function (val) {
            return val?.toFixed(2);
          },
        },
      },
      xaxis: {
        type: chartType === "day" ? "datetime" : "category",
        categories: filteredDatesAndTime || [],
        type: "category",
        tickAmount: 10,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let amount =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return `<div style="padding: 10px">
                   <div class="time-graph-hover mt-2">
                   ${
                     chartType == "day"
                       ? w?.globals?.categoryLabels?.[dataPointIndex]
                       : chartType === "yearly"
                       ? moment(
                           w?.globals?.categoryLabels?.[dataPointIndex]
                         ).format("MMM YYYY")
                       : moment(
                           w?.globals?.categoryLabels?.[dataPointIndex]
                         ).format("MMM DD YYYY")
                   }
                   </div>
                   <div class="line_hr my-2"> </div>
                   <h4 style="padding-bottom: 5px">$${Number(amount)?.toFixed(
                     2
                   )}</h4>
                   Aggregated Balance
                 </div>`;
        },
      },
    };

    setChartData({
      totalAmountArray,
      filteredDatesAndTime,
      chartOptions: options,
      chartSeries: [{ name: "Series", data: totalAmountArray || [] }],
    });
  };

  const processTimeData = async (data) => {
    const timeData = await Promise.all(
      data.map(async (item) => {
        const [dateString, hourString] = item.interval.split(" ");
        const date = new Date(`${dateString}T${hourString}:00`);
        return {
          originalDate: item.interval,
          time: date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit",
          }),
        };
      })
    );

    return timeData
      .sort((a, b) => new Date(a.originalDate) - new Date(b.originalDate))
      .map((item) => item.time);
  };

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center w-100 my-5 py-5">
          <Loader />
        </div>
      ) : (
        <ReactApexChart
          options={chartData.chartOptions}
          series={chartData.chartSeries}
          type="area"
          height={300}
          className="chartDiv"
        />
      )}
    </div>
  );
};

export default StocBalanceGraph;
