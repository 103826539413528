import React, { useEffect, useRef, useState, memo } from "react";
import CardShadow from "components/CardShadow";
import PopupModal from "components/PopupModal";
import "./index.scss";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clientInvite,
  emailInviteMessage,
  getInvitedClientCountAPI,
  sendMailToDeclinedUser,
  sendPendingRemainder,
  getDeclinedUserList,
  getConnectedUserListClient,
} from "features/Management/managementAction";
import AskQustionMessage from "./AskQustionMessage";
import { DeleteIcon, NotificationImg } from "assets";
import SecondryButton from "components/Button/SecondryButton";
import { data } from "components/Charts/HorizontalBar";
import { includes } from "lodash";

function ManagementTask() {
  let inputRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [licenseFile, setLicenseFile] = useState("");
  const [informal, setInformal] = useState("Informal");
  const [getTone, setTone] = useState("Natural");
  const [getMessages, setMessages] = useState("");
  const [getCancle, setCancle] = useState("null");
  const { userToken } = useSelector((state) => state.auth);
  const [getDeclined, setDeclined] = useState();
  const [isCheckedTechnical, setIsCheckedTechnical] = useState(false);
  const [isCheckedUnderstand, setIsCheckedUnderstand] = useState(false);
  const [isCheckedAsk, setIsCheckedAsk] = useState(false);
  const [isCheckedOther, setIsCheckedOther] = useState(false);

  const [isCheckedTechnicalChild, setIsCheckedTechnicalChild] = useState({});
  const [isCheckedUnderstandChild, setIsCheckedUnderstandChild] = useState({});
  const [isCheckedAskChild, setIsCheckedAskChild] = useState({});
  const [isCheckedOtherChild, setIsCheckedOtherChild] = useState({});
  const { getExchangeInfo } = useSelector((state) => state?.Exchange)



  const formaityData = [{ text: "Informal" }, { text: "Formal" }];
  const toneData = [
    { text: "Natural" },
    { text: "Confident" },
    { text: "Urgent" },
    { text: "Friendly" },
  ];
  const handleClick = () => {
    inputRef.current.click();
  };
  const [getConnect, setConnect] = useState([]);
  const [getDescription, setDescription] = useState("");
  const [getClientCoun, setClientCount] = useState();
  const { allConnectedUserListClientData } = useSelector(
    (state) => state?.management
  );
  const check = useSelector(
    (state) => state?.management
  );

  const [getError, setError] = useState(false);
  const [countBrokerExchange, setCountBrokerExchange] = useState(JSON.parse(localStorage.getItem('brokerExchangeConnections')) || [])
  const totalRetailer =
    useSelector(
      (state) => state?.dashboard?.dashboardGraphInfo?.data?.exchange
    ) || [];


  const submitLicense = () => {
    dispatch(clientInvite({ userToken, licenseFile, getMessages })).then(
      (response) => {
        dispatch(getInvitedClientCountAPI({ userToken })).then((response) => {
          if (response.meta && response.meta.requestStatus === "fulfilled") {
            setClientCount(response?.payload[0]);
          }
        });
      }
    );
    setLicenseFile("");
  };

  const emailInviteMessageByTone = () => {
    dispatch(emailInviteMessage({ userToken, informal, getTone })).then(
      (response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setMessages(response?.payload?.message);
        } else {
          setMessages(response?.payload);
        }
      }
    );
    dispatch(getInvitedClientCountAPI({ userToken })).then((response) => {
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        setClientCount(response?.payload[0]);
      }
    });
  };

  useEffect(() => {
    emailInviteMessageByTone();
  }, [getTone, informal, getCancle]);

  const changeFile = (e) => {
    switch (e.target.files[0]?.name?.split(".")[1]?.toLowerCase()) {
      case "csv":
      case "txt":
      case "xls":
      case "xlsx":
      case "ods":
        setLicenseFile(e.target.files);
        setError(false);
        break;
      default:
        setError(true);
    }
  };
  const cancleClick = () => {
    setInformal("Informal");
    setTone("Natural");
    setLicenseFile("");
    setCancle("");
    setDescription("");
    inputRef = null;
  };

  const cancleClickDeclined = () => {
    setInformal("Informal");
    setTone("Natural");
    setLicenseFile("");
    setCancle("");
    setDescription("");
    inputRef = null;
    setDescription("");
    setConnect([]);
    setIsCheckedTechnical(false);
    setIsCheckedUnderstand(false);
    setIsCheckedAsk(false);
    setIsCheckedOther(false);

    setIsCheckedTechnicalChild({});
    setIsCheckedUnderstandChild({});
    setIsCheckedAskChild({});
    setIsCheckedOtherChild({});

  };

  const sendPendingRemainderClick = () => {
    setLoading(true);
    dispatch(sendPendingRemainder({ userToken })).then((response) => {
      setLoading(false);
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        setMessages(response?.payload?.message);
      } else {
        setMessages(response?.payload);
      }
    });
  };

  useEffect(() => {
    let tempObj
    dispatch(getDeclinedUserList({ userToken })).then((response) => {
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        setDeclined(response?.payload);
        tempObj = response?.payload
        let objForTechnicalChild = tempObj?.data[1]?.length > 0 && tempObj?.data[1].reduce((acc, item) => {
          acc[item.email] = false;
          return acc;
        }, {});
        let objForUnderstandChild = tempObj?.data[2]?.length > 0 && tempObj?.data[2].reduce((acc, item) => {
          acc[item.email] = false;
          return acc;
        }, {});
        let objForAskChild = tempObj?.data[3]?.length > 0 && tempObj?.data[3].reduce((acc, item) => {
          acc[item.email] = false;
          return acc;
        }, {});
        let objForOtherChild = tempObj?.data[4]?.length > 0 && tempObj?.data[4].reduce((acc, item) => {
          acc[item.email] = false;
          return acc;
        }, {});
        setIsCheckedTechnicalChild(objForTechnicalChild)
        setIsCheckedUnderstandChild(objForUnderstandChild)
        setIsCheckedAskChild(objForAskChild)
        setIsCheckedOtherChild(objForOtherChild)
      }
    });

    dispatch(getConnectedUserListClient({ userToken })).then((response) => {
      if (response.meta && response.meta.requestStatus === "fulfilled") {
        localStorage.setItem('brokerExchangeConnections', JSON.stringify(response?.payload?.data))
        setCountBrokerExchange(response?.payload.data)
      }
    });
  }, []);

  const returnForTrueObj = (arr) => {
    let toReturn = arr.reduce((acc, item) => {
      acc[item.email] = true;
      return acc;
    }, {});
    return toReturn
  }

  const returnForFalseObj = (arr) => {
    let toReturn = arr.reduce((acc, item) => {
      acc[item.email] = false;
      return acc;
    }, {});
    return toReturn
  }

  const setParentVal = (obj) => {
    let arr = Object.values(obj);
    if (!arr.includes(true)) {
      return false
    }
    else {
      return true
    }
  }
  const contactMailClick = (item, indexValue) => {

    switch (indexValue) {
      case 1:
        setIsCheckedTechnicalChild({ ...isCheckedTechnicalChild, [item]: !isCheckedTechnicalChild[item] });
        setParentVal({ ...isCheckedTechnicalChild, [item]: !isCheckedTechnicalChild[item] }) ? setIsCheckedTechnical(true) : setIsCheckedTechnical(false)
        break
      case 2:
        setIsCheckedUnderstandChild({ ...isCheckedUnderstandChild, [item]: !isCheckedUnderstandChild[item] });
        setParentVal({ ...isCheckedUnderstandChild, [item]: !isCheckedUnderstandChild[item] }) ? setIsCheckedUnderstand(true) : setIsCheckedUnderstand(false)
        break;
      //  case 3:
      //  setIsCheckedAskChild({...isCheckedAskChild , [item]: !isCheckedAskChild[item]});
      //  setParentVal({...isCheckedAskChild , [item]: !isCheckedAskChild[item]}) ? setIsCheckedAsk(true) : setIsCheckedAsk(false)
      //  break;
      case 4:
        setIsCheckedOtherChild({ ...isCheckedOtherChild, [item]: !isCheckedOtherChild[item] });
        setParentVal({ ...isCheckedOtherChild, [item]: !isCheckedOtherChild[item] }) ? setIsCheckedOther(true) : setIsCheckedOther(false)
        break;

    }
    let removeData = getConnect.filter((i) => i !== item);
    if (!getConnect.find((p) => p === item)) {
      setConnect([...getConnect, item]);
    } else {
      setConnect(removeData);
    }
  };
  const contactMailClickMulti = (item, divClicked) => {

    switch (divClicked) {
      case 1:
        setIsCheckedTechnical(!isCheckedTechnical);
        setIsCheckedTechnicalChild(isCheckedTechnical === false ? returnForTrueObj(getDeclined?.data[1]) : returnForFalseObj(getDeclined?.data[1]))
        break
      case 2:
        setIsCheckedUnderstand(!isCheckedUnderstand);
        setIsCheckedUnderstandChild(isCheckedUnderstand === false ? returnForTrueObj(getDeclined?.data[2]) : returnForFalseObj(getDeclined?.data[2]))
        break;
      // case 3:
      // setIsCheckedAsk(!isCheckedAsk);
      // break;
      case 4:
        setIsCheckedOther(!isCheckedOther);
        setIsCheckedOtherChild(isCheckedOther === false ? returnForTrueObj(getDeclined?.data[4]) : returnForFalseObj(getDeclined?.data[4]))
        break;
    }

    let storeData = [];
    let removeData = [];
    item?.map((data) => {
      if (!getConnect.find((p) => p === data?.email)) {
        storeData.push(data?.email);
      } else {
        removeData.push(data?.email);
      }
    });
    if (storeData && removeData?.length == 0) {
      setConnect([...getConnect, ...storeData]);
    } else {
      let finalRemove2 = [...getConnect];
      removeData?.map((data) => {
        finalRemove2 = finalRemove2.filter((i) => i != data);
      });
      setConnect(finalRemove2);
    }
  };
  const submitContact = () => {
    setLoading(true);
    const payload = { emailContent: getDescription, emailList: getConnect };

    dispatch(sendMailToDeclinedUser({ userToken, payload })).then(
      (response) => {
        setLoading(false);
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          setDescription("");
          setConnect([]);
          setIsCheckedTechnical(false);
          setIsCheckedUnderstand(false);
          setIsCheckedAsk(false);
          setIsCheckedOther(false);

          setIsCheckedTechnicalChild({});
          setIsCheckedUnderstandChild({});
          setIsCheckedAskChild({});
          setIsCheckedOtherChild({});

        }
      }
    );
  };

  // const countExchangeConnected = allConnectedUserListClientData
  //   ?.map((e) => [...new Set(e?.portfolio?.map((i) => i?.exchangeName))])
  //   ?.reduce((acc, subArray) => {
  //     subArray.forEach((item) => {
  //       if (!acc[item]) {
  //         acc[item] = 1;
  //       } else {
  //         acc[item]++;
  //       }
  //     });

  //     return acc;
  //   }, {});

  const total = countBrokerExchange && countBrokerExchange?.length && countBrokerExchange.reduce((acc, cur) => {
    acc = cur.count + acc
    return acc
  }, 0);


  return (
    <>
      <div className="row task_cards pt-2">
        <div className="tast_container">
          <div className="fixed-header mb-3 card-shadow px-4 py-3 gap-3 d-flex align-items-center exchange-connection-top-border ">
            <h4 className="noWrap">Exchange Connection</h4>
            <div className="client-number rounded-circle">{localStorage.getItem("role") === "retailer" ? totalRetailer?.length || 0 : total || 0}</div>
          </div>

          {localStorage.getItem("role") === "retailer" ? (
            <CardShadow className="mb-3 px-4">
              {totalRetailer?.length > 0
                ? totalRetailer.map(
                  (retailer, index) => (
                    <div
                      className="d-flex gap-2 marginBetweenLabels"
                      key={retailer.exchangeId}
                    >
                      <div className="message-number mb-1">
                        {`01`}
                      </div>
                      <div className="text-capitalize">{retailer.exchangeName}</div>
                    </div>
                  )
                )
                : "No Data Found"}
            </CardShadow>
          ) : (
            <CardShadow className="mb-3 px-4">
              {countBrokerExchange?.length > 0
                ? countBrokerExchange.map(
                  (data, index) => (
                    <div
                      className="d-flex gap-2 marginBetweenLabels"
                      key={index}
                    >
                      <div className="message-number mb-1">
                        {`0${data?.count}`}
                      </div>
                      <div> {data?.name} </div>
                    </div>
                  )
                )
                : "No Data Found"}
            </CardShadow>
          )}
        </div>
        <div className="tast_container">
          <AskQustionMessage />
        </div>
        {localStorage.getItem("role") === "broker" && (
          <div className="tast_container">
            <CardShadow className="fixed-header mb-3 gap-3 d-flex align-items-center client-nvitation-top-border px-4">
              <h4 className="noWrap">Client Invitation </h4>
              <div className="client-number rounded-circle">
                {getClientCoun?.total || 0}
              </div>
            </CardShadow>
            <div className="exchange-Cards-bar">
              <CardShadow className="mb-3 px-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="d-flex card-heading-message gap-2">
                    Declined
                    <div className="text-danger">
                      {getClientCoun?.declined || 0}
                    </div>
                  </p>
                </div>
                <div className="py-2 ">
                  <div className="d-flex gap-2 marginBetweenLabels">
                    <div className="message-number mb-1">
                      {getDeclined?.data[1]?.length || 0}
                    </div>
                    <div className="message-title">Technical Difficulties</div>
                  </div>
                  <div className="d-flex gap-2 marginBetweenLabels">
                    <div className="message-number mb-1">
                      {getDeclined?.data[2]?.length || 0}
                    </div>
                    <div className="message-title">
                      Didn’t understand the platform
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className="message-number mb-1">
                      {getDeclined?.data[3]?.length || 0}
                    </div>{" "}
                    <div className="message-title">Didn't ask to connect</div>
                  </div>
                  <div className="d-flex gap-2 marginBetweenLabels ">
                    <div className="message-number mb-1">
                      {getDeclined?.data[4]?.length || 0}
                    </div>
                    <div className="message-title">Other</div>
                  </div>
                </div>
                <SecondryButton
                  text="Contact"
                  className="webkit-fill-available justify-content-center"
                  databstoggle="modal"
                  databstarget="#getDeclined"
                />
              </CardShadow>
              <CardShadow className="mb-3 px-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="d-flex card-heading-message gap-2">
                    Pending{" "}
                    <div className="text-warning">
                      {getClientCoun?.pending || 0}
                    </div>
                  </p>
                </div>
                <div className="py-2">
                  <div className="d-flex gap-2">
                    <div className="message-number mb-1">00</div>{" "}
                    <div className="message-title">Send a question</div>
                  </div>

                  <div className="d-flex gap-2 mb-2">
                    <div className="message-number mb-1">
                      {getClientCoun?.pending < 9 ? "0" : ""}
                      {getClientCoun?.pending || 0}
                    </div>{" "}
                    <div className="message-title">Didn't respond</div>
                  </div>
                </div>
                <SecondryButton
                  loading={loading}
                  disabled={loading || getClientCoun?.pending == 0}
                  variant={loading ? " btn btn-outline-secondary" : ""}
                  handleClick={sendPendingRemainderClick}
                  text="Remind Non-Responders"
                  icon={loading ? "" : <NotificationImg />}
                  className="webkit-fill-available justify-content-center"
                />
              </CardShadow>
              <CardShadow className="mb-3 px-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="d-flex card-heading-message gap-2">Email</p>
                </div>
                <div className="message-title py-2 mb-2">
                  Invite clients from your contact list
                </div>
                <SecondryButton
                  text="Invite"
                  databstoggle="modal"
                  databstarget="#Email"
                  className="webkit-fill-available justify-content-center"
                />
              </CardShadow>
            </div>
          </div>
        )}
      </div>
      <PopupModal id={"Email"} backdrop={false}>
        <>
          <div className="modal-header border-0 px-4">
            <h3 className="modal-title" id="exampleModalLabel">
              {" "}
              Send Invitations <span className="P3">Email</span>{" "}
            </h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={cancleClick}
            />
          </div>
          <div className="modal-body py-0 " onClick={handleClick}>
            <div className="card border-0 w-100 upload-color-bg">
              {!licenseFile && (
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img
                    accept="image/*"
                    src={require(`assets/upload.svg`).default}
                    className="p-2 curser-pointer"
                    alt=""
                    loading="lazy"
                  />
                </div>
              )}
              <input
                className="d-none"
                type="file"
                value={licenseFile?.length > 0 ? null : licenseFile}
                ref={inputRef}
                onChange={(e) => {
                  changeFile(e);
                }}
                accept=".csv, .txt, .xlsx, .xls, .ods"
              />
              <div className="card-body text-center uploadCardBG">
                <span className="h5-One">
                  {" "}
                  Drag & drop files or
                  <small className="text-primary curser-pointer">
                    {" "}
                    Browse Email List{" "}
                  </small>
                </span>
                <br />
                <p className="p-Four text-muted d-flex justify-content-center ">
                  {" "}
                  Supported formates: CSV, TXT, XLS, XLSX, ODS
                </p>
              </div>
            </div>
            {getError ? (
              <h6 className="text-danger mt-2">
                File Supported formates: CSV, TXT, XLS, XLSX, ODS{" "}
              </h6>
            ) : (
              ""
            )}
          </div>

          {licenseFile && (
            <>
              <h4 className="px-3 mt-3 mb-1 h6-One">Uploaded</h4>
              <div className="d-flex justify-content-between px-3 py-2 mx-3 uploadInputBox">
                <p className="p-Two d-flex align-items-center">
                  {" "}
                  {licenseFile && licenseFile[0]?.name}
                </p>
                <div
                  className="curser-pointer d-flex justify-content-end"
                  onClick={() => setLicenseFile("")}
                >
                  {" "}
                  <DeleteIcon color="#000" />{" "}
                </div>
              </div>
            </>
          )}

          <div className="modal-body py-0">
            <div className="my-3 mt-4 gap-1 d-grid">
              <h5 className="h6-One">Formality</h5>
              <div className="d-flex gap-2 gopup-button">
                {formaityData?.map((item, index) => (
                  <div
                    className={`gopup-button-content curser-pointer ${informal === item?.text && "active"
                      } fw-600`}
                    key={index}
                    onClick={() => setInformal(item?.text)}
                  >
                    {item?.text}
                  </div>
                ))}
              </div>
            </div>
            <div className="my-3 mt-4 gap-1 d-grid">
              <h5 className="h6-One">Tone</h5>
              <div className="d-flex gap-2 gopup-button">
                {toneData?.map((item, index) => (
                  <div
                    className={`gopup-button-content curser-pointer ${getTone === item?.text && "active"
                      } fw-600`}
                    key={index}
                    onClick={() => setTone(item?.text)}
                  >
                    {item?.text}
                  </div>
                ))}
              </div>
            </div>

            <div className="my-3 mt-4 gap-1 d-grid">
              <h5 className="h6-One">Message</h5>
              <textarea
                className=" text-area"
                rows="7"
                cols="50"
                value={getMessages}
                onChange={(e) => setMessages(e.target.value)}
                placeholder="Write Email or Wait for Data"
              />
            </div>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-bs-dismiss="modal"
              onClick={cancleClick}
            >
              {" "}
              Cancel{" "}
            </button>
            <Button
              disabled={
                licenseFile?.length > 0 && getMessages?.length > 0
                  ? false
                  : true
              }
              text="Send"
              databsdismiss="modal"
              handleClick={submitLicense}
            />
          </div>
        </>
      </PopupModal>
      <PopupModal id={"getDeclined"} backdrop={false}>
        {getDeclined !== undefined ?
          <>
            <div className="modal-header border-0">
              <h3 className="modal-title" id="exampleModalLabel">
                {" "}
                Contact{" "}
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={cancleClickDeclined}
              />
            </div>
            <div className="px-3">
              <h5 className="mb-3">Contact with</h5>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item width-down">
                  <h2 className="accordion-header">
                    <button
                      className="contact_accordion accordion-button collapsed container_dropdown"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Technical Difficulties clients
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse mt-3"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion  container_dropdown"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item p-0">
                        <h2 className="accordion-header">
                          <h2
                            className="contact_accordion accordion-button  px-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            <input
                              className="selectContainer width-auto me-2"
                              type="checkbox"
                              checked={isCheckedTechnical}
                              onClick={() =>
                                contactMailClickMulti(getDeclined?.data[1], 1)
                              }
                            />{" "}
                            Technical Difficulties (
                            {getDeclined?.data[1]?.length || 0})
                          </h2>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body px-3 py-0 P4">
                            {getDeclined?.data[1]?.map((item, index) => (
                              <level
                                key={index}
                                className={`dropdown-item d-flex gap-2 align-items-center`}
                                onClick={() => contactMailClick(item?.email, 1)}
                              >
                                <input
                                  className="selectContainer width-auto me-2"
                                  type="checkbox"
                                  checked={isCheckedTechnicalChild ? isCheckedTechnicalChild[item?.email] : false}
                                />
                                {item?.email}
                              </level>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item p-0">
                        <h2 className="accordion-header">
                          <h2
                            className="contact_accordion accordion-button collapsed px-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            <input
                              className="selectContainer width-auto me-2"
                              type="checkbox"
                              checked={isCheckedUnderstand}
                              onClick={() =>
                                contactMailClickMulti(getDeclined?.data[2], 2)
                              }
                            />{" "}
                            Didn’t understand the platform (
                            {getDeclined?.data[2]?.length || 0})
                          </h2>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body px-3 py-0 P4">
                            {getDeclined?.data[2]?.map((item, index) => (
                              <level
                                key={index}
                                className={`dropdown-item d-flex gap-2 align-items-center`}
                                onClick={() => contactMailClick(item?.email, 2)}
                              >
                                <input
                                  className="selectContainer width-auto me-2"
                                  type="checkbox"
                                  checked={isCheckedUnderstandChild ? isCheckedUnderstandChild[item?.email] : false}
                                />
                                {item?.email}
                              </level>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item p-0">
                        <h2 className="accordion-header">
                          <h2
                            className="contact_accordion accordion-button collapsed px-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseThree"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseThree"
                          >
                            <input
                              className="selectContainer width-auto me-2"
                              type="checkbox"
                              checked={isCheckedAsk}
                              onClick={() =>
                                contactMailClickMulti(getDeclined?.data[3], 3)
                              }
                            />{" "}
                            Didn't ask to connect (
                            {getDeclined?.data[3]?.length || 0})
                          </h2>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseThree"
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body px-3 py-0 P4">
                            {getDeclined?.data[3]?.map((item, index) => (
                              <level
                                key={index}
                                className={`dropdown-item d-flex gap-2 align-items-center`}
                                onClick={() => contactMailClick(item?.email, 3)}
                              >
                                <input
                                  className="selectContainer width-auto me-2"
                                  type="checkbox"
                                  checked={isCheckedAskChild ? isCheckedAskChild[item?.email] : false}
                                />
                                {item?.email}
                              </level>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item p-0">
                        <h2 className="accordion-header">
                          <h2
                            className="contact_accordion accordion-button collapsed px-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseThree-other"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseThree-other"
                          >
                            <input
                              className="selectContainer width-auto me-2"
                              type="checkbox"
                              checked={isCheckedOther}
                              onClick={() =>
                                contactMailClickMulti(getDeclined?.data[4], 4)
                              }
                            />{" "}
                            Other ({getDeclined?.data[4]?.length || 0})
                          </h2>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseThree-other"
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body px-3 py-0 P4">
                            {getDeclined?.data[4]?.map((item, index) => (
                              <level
                                key={index}
                                className={`dropdown-item d-flex gap-2 align-items-center`}
                                onClick={() => contactMailClick(item?.email, 4)}
                              >
                                <input
                                  className="selectContainer width-auto me-2"
                                  type="checkbox"
                                  checked={isCheckedOtherChild ? isCheckedOtherChild[item?.email] : false}
                                />
                                {item?.email}
                              </level>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <textarea
                rows="3"
                className={`form-control p-3 bg-white mt-3`}
                value={getDescription}
                placeholder={"Type message here"}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                onClick={cancleClickDeclined}
              >
                {" "}
                Cancel{" "}
              </button>
              <Button
                text="Send"
                databsdismiss="modal"
                disabled={!(getDescription !== "" && getConnect?.length > 0)}
                handleClick={submitContact}
              />
            </div>
          </> : null}
      </PopupModal>
    </>
  );
}

export default memo(ManagementTask);
