import React, { useRef, useState } from 'react'
import PopupModal from "components/PopupModal";
import Button from 'components/Button';
import 'react-image-crop/dist/ReactCrop.css';
import SecondryButton from 'components/Button/SecondryButton';

function UplodeImg({ setImgUplode, setUserImage,setImageData }) {
  const [getImage,setImage] = useState()
  let inputRef = useRef(null);
  const handleClick = () => { inputRef.current.click() };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(event.target.files)
      setImgUplode(event.target.files[0])
    }
  };
  
  const CancleFile = () => {
    setImage('')
    inputRef = null
    setImgUplode('')
  }

  const SaveProfileImageCrop = () => {
    setImageData(getImage[0])
    setUserImage((prevObject) => ({ ...prevObject, image : getImage[0]})) 
  };
  return (
    <>
      <PopupModal id={"userImageUpload"} backdrop={false} >
        <div >
          <div className="modal-header border-0" >
            <h3 className="modal-title" id="exampleModalLabel">Upload Image </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={CancleFile} />
          </div>
          <div className="modal-body ">
            <div className="card border-0 w-100 upload-color-bg">
              <div className="d-flex justify-content-center align-items-center mt-3">
                <img accept="image/*" src={require(`assets/upload.svg`).default} className="p-2 curser-pointer" alt="" onClick={handleClick} loading='lazy'/>
              </div>
              <input className="d-none" type="file" ref={inputRef} onChange={(e)=>handleFileChange(e)} accept="image/png, image/jpg, image/jpeg" />
              <div className="card-body text-center">
                <span className="h5"> Drag & drop files or
                  <small className="text-primary curser-pointer" onClick={handleClick}> Browse</small>
                </span>
                <br />
                <span className="text-muted"> Supported formates: JPEG, PNG, JPG </span>
              </div>
            </div>
          </div>
          <p className="px-3 text-break"> {getImage && `Upload File Name : ${getImage[0]?.name}`}</p>
          <div className="modal-footer border-0">
            <SecondryButton text={'Cancel'} type="button" data-bs-dismiss="modal" handleClick={CancleFile} />
            <Button text="Upload" disabled={!getImage?.length} databsdismiss="modal" handleClick={SaveProfileImageCrop} />
          </div>
        </div>
      </PopupModal>
    </>
  )
}

export default UplodeImg