import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import eye from "assets/eye.svg";
import eyeOff from "assets/eye-off.svg";
import loginValidationSchema from "../helper/loginValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, sendOTP, getLinkedInProfile } from "features/auth/authActions";
import { useNavigate, Link } from 'react-router-dom';
import { clearIsForgotPasswordEmailSent } from 'features/auth/authSlice'
import 'react-notifications/lib/notifications.css';
import Button from 'components/Button'
import Support from "./Support";

import { LoginSocialFacebook, LoginSocialLinkedin, LoginSocialGoogle, LoginSocialMicrosoft } from "reactjs-social-login";
import { UserAgentApplication } from 'msal';
import { Google } from "assets";
const msalConfig = {
  auth: {
    clientId: '9ef62186-0c97-40f1-a74e-c37e390a2058',
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}/signup`,
  }
};
const msalInstance = new UserAgentApplication(msalConfig);
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const { userToken } = useSelector((state) => state.auth);
  const isUserInvited = JSON.parse(localStorage.getItem("invitedUser"));
  const invitedUserRegistered = localStorage.getItem("invitedUserRegistered");
  const isUserRejected = localStorage.getItem("isUserRejected");

  useEffect(() => {
    if (isUserInvited && isUserInvited.status == "2" && isUserInvited.type == "accept") {
      if (invitedUserRegistered === null) {
        navigate("/signup");
      }
    }
    if (isUserRejected) {
      navigate("/rejectPage");
    }
  }, [isUserInvited, navigate])

  useEffect(() => {
    const storedUserToken = localStorage.getItem('userToken')
    if (storedUserToken === userToken) {
      navigate("/dashboard");
    }
    asyncEncryptDecryptFunc();
  }, [userToken, navigate]);

  const asyncEncryptDecryptFunc = async () => {
  }

  const onSubmitHandler = async (values) => {
    if (localStorage.getItem('userToken') !== null) {
      window.location.reload()
    } else {
      setLoading(true)
      setIsFormSubmitted(true)
      values.email = values.email.toLowerCase();
      await dispatch(userLogin(values)).then((response) => {
        setLoading(false)
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          const verifyOTPData = {
            email: values.email,
          };
          if (response.payload.phone.value) {
            verifyOTPData.phone_number = response.payload.phone.value
            verifyOTPData.country_code = response.payload.phone.country_code
          }
          dispatch(sendOTP(verifyOTPData)).then((res) => {
            setIsFormSubmitted(false)
            if (res.meta && res.meta.requestStatus === "fulfilled") {
              navigate("/authenticate");
            }
          });
        } else {
          setIsFormSubmitted(false)
          setLoading(false)
        }
      });
    }
  };

  const socialLogin = async (item) => {
    setIsFormSubmitted(true)
    const signUpData = {}
    signUpData.email = item?.data?.email
    signUpData.social_id = item?.data?.userID || item?.data?.id || item?.data?.sub
    signUpData.social_type = item?.provider
    const verifyOTPData = { email: item?.data?.email };
    dispatch(sendOTP(verifyOTPData)).then((res) => {
      if (res.meta && res.meta.requestStatus === "fulfilled") {
        navigate("/authenticate");
        setIsFormSubmitted(false)
      }
    });
  }
  const linkedinLogin = async (item) => {
    setLoading(true)
    setIsFormSubmitted(true)
    const payload = { accessToken: item?.data?.access_token }
    dispatch(getLinkedInProfile(payload)).then(
      (response) => {
        if (response.meta && response.meta.requestStatus === "fulfilled") {
          const verifyOTPData = { email: response?.payload?.email };
          dispatch(sendOTP(verifyOTPData)).then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
              setIsFormSubmitted(false)
              navigate("/authenticate");
            }
          });
        }
      })
  }
  const signUpData = {}
  const loginMicrosoft = async () => {
    setIsFormSubmitted(true)
    try {
      const authResponse = await msalInstance.loginPopup();
      signUpData.email = authResponse?.account?.idTokenClaims?.email
      signUpData.social_id = authResponse?.uniqueId
      signUpData.social_type = 'microsoft'
      const verifyOTPData = { email: authResponse?.account?.idTokenClaims?.email }

      dispatch(sendOTP(verifyOTPData)).then((res) => {
        if (res.meta && res.meta.requestStatus === "fulfilled") {
          navigate("/authenticate");
          setIsFormSubmitted(false)
        }
      });
    } catch (error) {
      setIsFormSubmitted(false)
    }
  };

  const linkedinClickStart = () => {
  }

  return (
    <>
      <div className="loginFormContainer">
        <div className="d-grid justify-content-center right-0">
          <Formik initialValues={{ email: "", password: "" }} validationSchema={loginValidationSchema} onSubmit={(values) => onSubmitHandler(values)} >
            {({ errors, touched }) => (
              <Form>
                <div className="input-container">
                  <label htmlFor=""> Email </label>
                  <Field name="email" type="email" placeholder="Email" />
                  {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}
                </div>
                <div className="input-container lastchildInput mb-1">
                  <label htmlFor=""> Password </label>
                  <div className="d-flex position-relative align-items-center">
                    <Field type={showPassword ? "text" : "password"} name="password" className='pe-4' />
                    <img src={showPassword ? eye : eyeOff} alt="Show password" onClick={() => setShowPassword(!showPassword)} className={`password-eye ${showPassword ? "eye-icon" : "eye-icon-inactive"} `} loading='lazy' />
                  </div>
                  {errors.password && touched.password ? <div className="text-danger">{errors.password}</div> : null}
                </div>
                <div className="mb-4">
                  <Link className="forgot-password" to="/forgotpassword" onClick={() => dispatch(clearIsForgotPasswordEmailSent())}> Forgot password?</Link>
                </div>
                <div className="text-center justify-content-center d-grid">
                  <Button className="mb-4 buttonWidth" loading={loading || isFormSubmitted} disabled={loading || isFormSubmitted} type="submit" text="Login" />
                </div>
              </Form>
            )}
          </Formik>
          <div className="social-container mt-3">
            <div className="lineSignin d-flex align-items-center mb-4 justify-content-center">
              <p className="sign-up-with text-center px-2"> Or log in with </p>
            </div>
            <div className="social-container-img d-flex gap-3 justify-content-center align-items-center">
              <LoginSocialMicrosoft
                isOnlyGetToken
                client_id={process.env.REACT_APP_MICROSOFT_APP_ID}
                redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}`}
                onLoginStart={loginMicrosoft}
                onResolve={loginMicrosoft}
                onReject={(err) => {

                }} >
                <div className="button-lg d-flex gap-2">
                  <img src={require(`assets/microsoft.svg`).default} alt="Sign Up with Microsoft" loading='lazy' />
                  <p className="curser-pointer">  <strong>Microsoft</strong> </p>
                </div>
              </LoginSocialMicrosoft>
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}/login`}
                onResolve={(response) => { socialLogin(response) }}
                onReject={(error) => { setIsFormSubmitted(false) }}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"><div className="curser-pointer"><Google /> </div></LoginSocialGoogle>
              <LoginSocialLinkedin
                client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                client_secret={process.env.REACT_APP_LINKEDIN_CLIENT_SECRET}
                scope="email,openid,profile"
                onLoginStart={linkedinClickStart}
                redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}/login`}
                onResolve={(response) => { linkedinLogin(response) }}
                onReject={(error) => { setIsFormSubmitted(false) }} >
                <img src={require(`assets/Linkdin.svg`).default} alt="Sign Up with Linkdin" className="curser-pointer" loading='lazy' />
              </LoginSocialLinkedin>
              <LoginSocialFacebook
                appSecret='deed97d136158ddfc025a82449e99ba7'
                response_type="token"
                state='{st=state123abc,ds=123456789}'
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                redirect_uri={`${process.env.REACT_APP_REDIRECT_URI}/login`}
                onResolve={(response) => { socialLogin(response) }}
                onReject={(error) => { setIsFormSubmitted(false) }} >
                <img src={require(`assets/facebook-fill.svg`).default} alt="Sign Up with Facebook" className="curser-pointer" loading='lazy' />
              </LoginSocialFacebook>
            </div>
          </div>
        </div>
        <Support />
      </div>
      <div data-bs-toggle="modal" data-bs-target="#needSupport" className="forgot-password d-flex justify-content-center gap-1 flex-column"><h5 className="h5-Three d-flex justify-content-center">Need help? </h5> <div className="d-flex justify-content-center curser-pointer"> Customer Support </div> </div>
    </>
  );
};

export default Login;
