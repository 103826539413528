import React from "react";
import star from "assets/star-svgrepo-com.svg";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadcrum_rdx } from "features/Header/headerSclice";
import { Avtar } from "assets";
import ConverNumber from "components/ConvertNumber";

function BrokersCard(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const goToProfile = (id) => {
    dispatch(setBreadcrum_rdx(true))
    localStorage.setItem('routeHistory', location?.pathname)
    localStorage.setItem('breadcrum', true)
    localStorage.setItem('brokerName', props?.full_name)
    const newTab = window.open("", "_blank");
    newTab.location.href = `/${localStorage.getItem('role') === 'broker' ? 'retailer' : 'broker'}sProfile/${id}`
  };
  return (
      <div className="col-lg-4 col-xl-4 col-xxl-4 col-md-6 col-sm-12 mb-2 px-1" onClick={() => goToProfile(props?._id)}>
        <div className="card cardShadow">
          <div className="container-img-card">
          {!props?.image ? <div className="card-img-top avtarCommunity"><Avtar /></div> : <img src={props?.image} className="card-img-top" alt="..." loading='lazy'/>}
            <span className="ranking"> {`Level ${props?.level}`}</span>
          </div>
          <div className="card-body p-3">
            <h3 className="mb-2 ms-1" title={props?.full_name && props?.full_name.length > 20 ? props?.full_name : null}>{props?.full_name ? props?.full_name.length > 20 ? props?.full_name.substr(0, 20) + '...' : props?.full_name : 'Unknown User'}</h3>
            <div className="d-flex gap-2 align-items-center">
              <div className="company-logo">
                <img src={require('../../../assets/brokerCompanyLogo.svg').default} alt="company" loading='lazy'/>
              </div>
              <p className="P5">{props?.company?.company_name}</p>
            </div>
          </div>
          <hr className="hr-card" />
          <div className="card-body pt-1 d-flex communityHeaderBottom align-items-center justify-content-between ">
            {localStorage.getItem('role') === 'retailer' &&
              <>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={star} alt="" loading='lazy'/>
                  <span> {props?.rating}/<small className="h6-One-Grey">5</small> </span>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require("assets/clientsIcn.svg").default} alt="" loading='lazy'/>
                  <span className="h6-One-Grey">{props?.connections}</span>
                </div>
              </>}
            <div className="d-flex justify-content-center align-items-center">
              <img src={require("assets/doller-outline.svg").default} className="MB-2 dollerSignBrokerCard" alt="" loading='lazy'/>
              <span className="h6-One-Grey">{props?.balance}K<small>30d</small></span>
            </div>
          </div>

          <div className="card-body pt-1 card-description">
            <p className="description-broker-profile text-description">{props?.description ? props?.description : `Discover effortless cryptocurrency trading with ${props?.full_name ? props?.full_name.length > 20 ? props?.full_name.substr(0, 20) + '...' : props?.full_name : 'Our Broker'}. Our user-friendly platform, top-notch security, and diverse asset options make us your ideal ally in the crypto space. Join now for a seamless trading experience! ` }</p>
          </div>
        <div className="card-body d-flex align-items-center gap-2 justify-content-between communityCardFooter">
          <div className="px-1" >
            <img src={require("assets/countryIcon.svg").default} alt="" loading='lazy'/>
            <span className="mx-2">San Francisco</span>
          </div>
          <div className="d-flex gap-2">
            {Object.keys(props?.social_networks || {})?.map((item, index) => {
              return (<>{props?.social_networks[item] && <img className="networks-image" src={require(`assets/${item}-fill.svg`)} alt="" key={index} loading='lazy'/>}</>)
            })}
          </div>
        </div>
        </div>
      </div>
  );
}

export default BrokersCard;
