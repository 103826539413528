import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from 'assets'
import AddOnCard from '../AddOnCard'
import LineChartDark from '../Charts/LineChartDark'
import { getCoinList } from 'features/CoinList/CoinListAction'
import { useSelector, useDispatch } from "react-redux";

function CryptoCurrenciesCard({ addonsContainer, onClickPin, onClickUpdate, onClickExpand, isVisible }) {
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.auth);
    const [getCoinListData, setCoinListData] = useState()
    const [currentIndex, setCurrentIndex] = useState(0);
    const [Loading, setLoading] = useState(false)
    const [lastRefreshTime, setLastRefreshTime] = useState(null);

    const handleRefresh = async () => {
        setLoading(true)
        const res = await dispatch(getCoinList({ userToken })).then((res) => {
            if (res.meta && res.meta.requestStatus === "fulfilled") {
                setCoinListData(res?.payload?.coins)
                setLastRefreshTime(new Date());
                setLoading(false);
            }
        });
    }
    useEffect(() => {
        handleRefresh()
    }, []);

    const handleIndexNext = () => {
        if (currentIndex < getCoinListData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };
    const handleIndexPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };
    const formatDateTime = (dateTime) => {
        return dateTime.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };

    return (
        <>
            {getCoinListData && getCoinListData[currentIndex] && (
                <AddOnCard onClickPin={onClickPin} onClickExpand={onClickExpand} onClickUpdate={onClickUpdate} isVisible={isVisible}>
                    <div className='d-flex flex-column w-100 p-2'>
                        <div>
                            <div className='d-flex justify-content-between '>
                                <div className="d-flex ">
                                    <div>
                                        <img src={getCoinListData[currentIndex]?.iconUrl} alt="update" className="mx-2 adons_graph_img" loading='lazy'/>
                                    </div>
                                    <div className="d-flex flex-column position-relative ">
                                        <span title={getCoinListData[currentIndex]?.name.length > 10 && getCoinListData[currentIndex]?.name}>{getCoinListData[currentIndex]?.name.length > 10 ? getCoinListData[currentIndex]?.name.substr(0, 10) + '...' : getCoinListData[currentIndex]?.name}<small className="fw-bold ms-1 pt-3">{getCoinListData[currentIndex]?.symbol}</small></span>
                                        <span>${parseFloat(getCoinListData[currentIndex]?.price)?.toFixed(4)} <small className={getCoinListData[currentIndex]?.change < 0 ? "text-danger ms-1 mt-3" : "text-success ms-1 mt-3"}>{getCoinListData[currentIndex]?.change}%</small></span>
                                    </div>
                                </div>
                                <div className=''>
                                    <img src={require("assets/update-16.svg").default} alt="update" className="me-2 blackToWhite text-secondary" onClick={handleRefresh} loading='lazy'/>
                                    {Loading ? <span className="text-secondary">Refreshing price...</span> :
                                        lastRefreshTime ? <span className="text-secondary">{formatDateTime(lastRefreshTime)}</span> : null}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between z-index-2 mt-1 '>
                            <div onClick={handleIndexPrevious} className='cursor z-index-999'> <ArrowLeftIcon color={'#fff'} /> </div>
                            <div onClick={handleIndexNext} className='cursor z-index-999'><ArrowRightIcon color={'#fff'} /> </div>
                        </div>
                        <div className='pb-4' style={{ height: '50px' }}>
                            <LineChartDark addonsContainer={addonsContainer} dataChart={getCoinListData[currentIndex]?.sparkline} />
                        </div>
                    </div>
                </AddOnCard>
            )}
        </>
    )
}

export default CryptoCurrenciesCard