import React from "react";

function XRP({ht, wd, bg, text}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wd}
      height={ht}
      fill="none"
      viewBox="0 0 44 45"
    >
      <rect width="44" height="44" y="0.817" fill={bg} rx="22"></rect>
      <path
        fill={text}
        fillRule="evenodd"
        d="M33.206 13.817h-3.252l-5.145 5.09a4.004 4.004 0 01-5.621 0l-5.142-5.09h-3.255l6.77 6.7c2.453 2.426 6.427 2.426 8.878 0l6.767-6.7zM10.75 32.45h3.254l5.188-5.132a4.004 4.004 0 015.62 0l5.186 5.132h3.252l-6.811-6.743c-2.453-2.425-6.427-2.425-8.878 0L10.75 32.45z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default XRP;